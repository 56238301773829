import React from "react";
import CustomerInfo from "src/pages/ServiceOrdersDetails/tabs/Summary/components/CustomerInfo";
import Notes from "src/pages/ServiceOrdersDetails/tabs/Summary/components/Notes";
import SpecialInstructions from "src/pages/ServiceOrdersDetails/tabs/Summary/components/SpecialInstructions";
import Description from "src/pages/ServiceOrdersDetails/tabs/Summary/components/Description";
import PartnerInfo from "src/pages/ServiceOrdersDetails/tabs/Summary/components/PartnerInfo";
import DeliveryInfo from "src/pages/ServiceOrdersDetails/tabs/Summary/components/DeliveryInfo";
import Grid from "@material-ui/core/Grid";
import EditSpecialInstructions from "./EditSOSummary/EditSpecialInstructions";
import EditCustomerInfo from "./EditSOSummary/EditCustomerInfo";
import EditInvoiceInfo from "./EditSOSummary/EditInvoiceInfo";
import EditDeliveryAddress from "./EditSOSummary/EditDeliveryAddress";
import EditDescription from "./EditSOSummary/EditDescription";
import EditNotes from "./EditSOSummary/EditNotes";
import EditPartnerInfo from "./EditSOSummary/EditPartnerInfo";
import { CustomerDetailsProvider } from "src/providers/CustomerDetailsStore";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { CustomerProvider } from "src/providers/CustomerStore";

const Summary: React.FC = () => {
  const {
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <CustomerInfo />
        </Grid>
        <Grid item>
          <DeliveryInfo />
        </Grid>
        <Grid item>
          <Description />
        </Grid>
        {serviceOrder?.externalIdType !== 0 && (
          <Grid item>
            <SpecialInstructions />
          </Grid>
        )}
        <Grid item>
          <PartnerInfo />
        </Grid>
        <Grid item>
          <Notes />
        </Grid>
      </Grid>
      <CustomerProvider>
      <EditCustomerInfo />
      </CustomerProvider>
      <EditDescription />
      <EditSpecialInstructions />
      <EditPartnerInfo />
      <EditNotes />
      <CustomerDetailsProvider>
        <>
          <EditDeliveryAddress />
          <EditInvoiceInfo />
        </>
      </CustomerDetailsProvider>
    </>
  );
};

export default React.memo(Summary);
