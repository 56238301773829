import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
  },
  mainContainer: {
    padding: theme.spacing(2),
  },
  search: {
    position: "relative",
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
    width: "100%",
    border: "1px solid rgba(20, 30, 48, 0.3)",
    marginBottom: theme.spacing(1),
  },
  searchIcon: {
    padding: theme.spacing(2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      fontSize: "1rem",
    },
  },
  searchText: {
    width: "100%",
    paddingLeft: theme.spacing(6),
  },
  groupWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  cardSubtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  questionGroup: {
    flex: "0 45%",
    // minWidth: 376,
    display: "inline-block",
  },
  questionItem: {
    color: theme.palette.functionalColors.dark,
    marginBottom: theme.spacing(2),
  },
  sideContent: {
    minWidth: 376,
    maxWidth: 400,
    marginLeft: theme.spacing(2),
  },
  sideCard: {
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    display: "flex",
    padding: theme.spacing(2),
    alignItems: "center",
    background: "rgba(43, 89, 195, 0.1)",
  },
  cardContent: {
    padding: theme.spacing(2),
    whiteSpace: "pre-wrap",
  },
}));
