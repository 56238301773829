import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { File } from "src/types";
import FileTypeIcon from "src/components/FileIcon";
import { getFileUrl } from "src/utils/files";

const useStyles = makeStyles((theme) => ({
  fileIcon: {
    marginRight: theme.spacing(1),
    "& svg": {
      height: 45,
      width: 45,
    },
  },
  listItem: {
    margin: `${theme.spacing(1)}px 0px`,
    border: "1px solid rgba(20, 30, 48, 0.1)",
    borderRadius: 4,
    padding: theme.spacing(1),
    cursor: "pointer",
    width: "auto",
    minWidth: 250,
  },
}));
const MessageFilesList: React.FC<{ fileList: File[] }> = ({ fileList = [] }) => {
  const classes = useStyles();

  const downloadFile = (file: File) => async (e: React.MouseEvent) => {
    const url = await getFileUrl(file.fileServiceId);
    e.persist();
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", file.fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    element.remove();
  };

  return (
    <Grid container alignItems="flex-start" direction="column">
      {fileList.map((file) => (
        <Grid
          item
          container
          key={Math.random()}
          alignItems="center"
          onClick={downloadFile(file)}
          className={classes.listItem}
        >
          <div className={classes.fileIcon}>
            <FileTypeIcon
              type={file.fileName.slice(
                (Math.max(0, file.fileName.lastIndexOf(".")) || Infinity) + 1
              )}
            />
          </div>
          <Typography variant="body1">{file.fileName}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default MessageFilesList;
