import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const IncIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M13.4444 1H2.55556C1.69645 1 1 1.69645 1 2.55556V13.4444C1 14.3036 1.69645 15 2.55556 15H13.4444C14.3036 15 15 14.3036 15 13.4444V2.55556C15 1.69645 14.3036 1 13.4444 1Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 4.88867V11.1109" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.88867 8H11.1109" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </SvgIcon>
);
export default IncIcon;
