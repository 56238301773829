import { useEffect, useState } from "react";

type ResultType = [boolean, () => void, () => void, () => void];

export const useFlag = (value = false): ResultType => {
  const [is, setIs] = useState(value);

  const set = () => setIs(true);
  const unset = () => setIs(false);
  const toggle = () => setIs((prev) => !prev);

  useEffect(() => {
    setIs(value);
  }, [value]);

  return [is, set, unset, toggle];
};
