import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  contentWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "calc(100% - 10px)",
    overflow: "auto"
  },
  caption: {
    display: "block",
    color: "rgba(20, 30, 48, 0.5)",
    marginBottom: theme.spacing(1),
  },
  displayValue: {
    display: "block",
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: 16,
  },
  emailLink: {
    textDecoration: "none",
  },
  outlineBtn: {
    padding: theme.spacing(2),
    color: "#2B59C3",
    fontSize: "14px !important",
  },
}));
