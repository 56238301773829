import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useServiceOrderStatusStyle } from "src/utils/hooks/useServiceOrderStatusStyle";
import { DefaultTheme } from "@material-ui/styles";

const StatusColor: FC<{ color: string }> = ({ color }) => {
  const [name, colorToShow] = useServiceOrderStatusStyle(color);

  const classes = useStyles({ color: colorToShow });
  return <span className={classes.statusWrapper}>{name}</span>;
};

export const useStyles = makeStyles<DefaultTheme, { color: string }>(() => ({
  statusWrapper: ({ color }) => ({
    padding: 3,
    marginTop: 2,
    borderRadius: 4,
    width: "fit-content",
    display: "flex",

    background: color,
  }),
}));

export default StatusColor;
