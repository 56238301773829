import { uniqueId } from "lodash";
import { ReactNode } from "react";
import { FILE_FORMAT_ERRORS } from "src/components/DropZone/constants";
import { FILE_STATUSES } from "src/containers/FileUploadDislog/components/FileInfoStatus/constants";
import { LookupObject, ServiceOrder, Service } from "src/types";
import { PropsOf } from "src/utils/propsOf";
import { FileType } from "src/providers/CommentsStore/types";

export type CallBackSetContext = (name: string, data: any) => void;

export interface Context {
  constants: {
    MAX_FILES_COUNT: number;
  };
  files: UploadFile[];
  categories: LookupObject[];
  locations: LookupObject[];
  addFiles: (props: UploadFileAddProps[]) => void;
  clearFiles: () => void;
  fileCountLimitReached: boolean;
  hasError: boolean;
  hasValidationErrors: boolean;
  canBeSent: boolean;
  uploadingState: FILES_UPLOADING_STATES;
  uploading: boolean;
  uploaded: boolean;
  submit: () => void;
  submitCommentsFiles: (callBack: CallBackSetContext, files: FileType[]) => void;
  hasAnyFiles: boolean;
  reset: () => void;
  onChange: (item: UploadFile, change: Partial<PropsOf<UploadFile>>) => void;
  onDelete: (item: UploadFile) => void;
  fetchInitial: () => void;
  resetState: () => void;
}

export interface Props {
  children: ReactNode;
  partnerKey?: string;
  serviceOrder?: Partial<ServiceOrder>;
  service?: Partial<Service>;
  context: "service" | "serviceOrder";
}

export enum FILES_UPLOADING_STATES {
  PENDING = 0,
  UPLOADING = 2,
  DONE = 3,
  FAILED = 4,
}

export enum FILE_UPLOAD_ERRORS {
  UNKNOWN = 0,
  TYPE_NOT_SUPPORTED = 19,
}

export type UploadFileCreateProps = PropsOf<UploadFile>;

export interface UploadFileResponse {
  fileName: string;
  fileId: string;
}

export type LinkFileIdTypes = "workOrderId" | "serviceOrderId" | "serviceId";

export interface LinkFileDto {
  fileName: string;
  fileServiceId: string;
  categoryId: number;
  description: string;
  createdDate: Date;
  sendToIntegration: boolean;
  workOrderId?: number;
  serviceOrderId?: number;
  projectId?: number;
}

export type UploadFileAddProps = Omit<
  UploadFileCreateProps,
  | "id"
  | "sendToIntegration"
  | "status"
  | "errorCode"
  | "location"
  | "category"
  | "description"
  | "progress"
>;

export type UploadFileNewProps = Omit<
  UploadFileCreateProps,
  "id" | "category" | "description" | "sendToIntegration" | "status" | "progress"
>;
export type UploadFileUpdateProps = Partial<
  Pick<
    UploadFile,
    | "location"
    | "category"
    | "category"
    | "description"
    | "sendToIntegration"
    | "progress"
    | "status"
  >
>;

export class UploadFile {
  constructor(
    readonly id: string,
    readonly file: File,
    readonly location: number,
    readonly category: number | null,
    readonly description: string,
    readonly sendToIntegration: boolean,
    readonly status: FILE_STATUSES,
    readonly errorCode: FILE_UPLOAD_ERRORS | null,
    readonly errors: FILE_FORMAT_ERRORS[],
    readonly progress: number
  ) {}

  static create(props: UploadFileCreateProps) {
    return new UploadFile(
      props.id,
      props.file,
      props.location,
      props.category,
      props.description,
      props.sendToIntegration,
      props.status,
      props.errorCode,
      props.errors,
      props.progress
    );
  }

  clone(props?: Partial<UploadFileCreateProps>) {
    return UploadFile.create({
      ...this,
      ...props,
    });
  }

  static new(props: UploadFileNewProps) {
    const id = uniqueId();
    return UploadFile.create({
      ...props,
      id,
      category: null,
      description: "",
      sendToIntegration: false,
      status: FILE_STATUSES.PENDING,
      progress: 0,
    });
  }

  getProgressPercents() {
    let percents = 0;
    if (this.progress) {
      percents = Math.round(this.progress * 100);
    }
    return percents;
  }

  canBeSent() {
    if (this.errors.length) {
      return false;
    }
    if (!this.location) {
      return false;
    }
    if (!this.category) {
      return false;
    }
    return true;
  }
}
