import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import MonteraSelect from "src/components/MonteraSelect";
import { requestMetaGet } from "src/utils/crud";
import { OrdersStatusType } from "src/providers/SODetailsStore/types";
import { useAppContext } from "src/providers/AppProvider";

const SelectServiceStatus = () => {
  const { control } = useFormContext();
  const [statuses, setServiceOrderStatuses] = useState<OrdersStatusType[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("orders");
  const {
    state: { user },
  } = useAppContext();

  const onOpen = () => {
    setLoading(true);
    requestMetaGet<OrdersStatusType[]>(
      `Lookups/PartnerPortal/statusTypes?territoryKey=${user?.territory?.key}`
    )
      .then((response) => {
        setLoading(false);
        setServiceOrderStatuses(response.data);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <Controller
      render={({ ref, ...props }) => (
        <MonteraSelect
          label={t("list_filter.status_label")}
          onOpen={onOpen}
          loading={loading}
          id="select-service-status"
          {...props}
          inputRef={ref}
          noneLabel={false}
        >
          {statuses.map((status) => (
            <MenuItem value={status.key} key={status.key}>
              {status.displayText}
            </MenuItem>
          ))}
        </MonteraSelect>
      )}
      defaultValue=""
      name="statusTypeId"
      control={control}
    />
  );
};

export default SelectServiceStatus;
