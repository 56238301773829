import React, { useCallback } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import SuccessIcon from "./icons/SuccessIcon";
import { useStyles } from "./styles";
import { useAppContext } from "src/providers/AppProvider";
import { useSellersContext } from "src/providers/SellersStore";

const SuccessSellerEdit: React.FC = () => {
  const classes = useStyles();
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    callAPI: { getSellers },
  } = useSellersContext();

  const handleModal = useCallback(() => {
    toggleModal({ id: "editSellerModal", status: false });
    getSellers();
  }, []);

  return (
    <Grid container justify="center">
      <Grid item>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">Seller was successfully updated!</Typography>
        </div>

        <div className={classes.btnGroup}>
          <Button onClick={handleModal} className={classes.submitBtn} type="submit">
            OK
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessSellerEdit;
