import React, { FC } from "react";
import { Grid, Button } from "@material-ui/core";
import MonteraDialog from "src/components/MonteraDialog";
import { useTranslation } from "react-i18next";
import { theme } from "src/utils/styleTheme/mainStyles";
import AlertIcon from "./icon/AlertIcon";
import SuccessIcon from "./icon/SuccessIcon";

interface Props {
  open: boolean;
  type: string;
  onClose: () => void;
  closeIcon: boolean;
  closeButton: boolean;
}

export const AlertPanel: FC<Props> = ({
  type = "", // info , success , error , warning
  children = null,
  onClose = () => {},
  closeButton = false,
  open = true, // Boolean
  closeIcon = true,
}) => {
  const { t } = useTranslation("common");
  return (
    <MonteraDialog open={open} onClose={onClose} showCloseIcon={closeIcon}>
      <Grid container justify="center">
        {type === "success" && <SuccessIcon />}
        {type === "error" && <AlertIcon />}
      </Grid>
      <br />
      {children}
      <br />
      {closeButton && (
        <Grid container justify="center">
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={onClose}
            style={{
              padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
              marginLeft: theme.spacing(2),
            }}
          >
            {t("labels.ok")}
          </Button>
        </Grid>
      )}
    </MonteraDialog>
  );
};
