import React, { FC, useEffect, useMemo } from "react";
import { List, Typography } from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Inactive from "src/assets/icons/chenalImgs/Inactive.svg";
import Grid from "@material-ui/core/Grid";
import { useCStyles } from "./style";
import ChannelItem from "./ChannelItem";
import { useTranslation } from "react-i18next";
import { useCommentsContext } from "src/providers/CommentsStore";
import useQueryString from "src/utils/hooks/useQueryString";
import { CommentsParamsType } from "src/pages/ServiceOrdersDetails/type";
interface Props {
  isOpen: boolean;
  handling: () => void;
}

const Channels: FC<Props> = React.memo(({ isOpen, handling = () => {} }) => {
  const {
    comments: { chatchannels },
    callAPI,
  } = useCommentsContext();
  const { t } = useTranslation("comments");
  const { arrow, wrapArrow, wrapper, mainIcons } = useCStyles({ isOpen });
  const [{ channelKey }, setQuery] = useQueryString<CommentsParamsType>();

  const memoChannelKey = useMemo(
    () => chatchannels.findIndex((channel) => Number(channel.key) === Number(channelKey)),
    [channelKey, chatchannels]
  );

  useEffect(() => {
    if (memoChannelKey === -1) return;
    parseQueryChannel(memoChannelKey);
  }, [memoChannelKey]);

  const parseQueryChannel = async (key: number) =>
    await callAPI.setContextData("selectedChannel", key);

  const handlingChannels = (id: number) => {
    setQuery({}, { fresh: true });
    callAPI.clearMsgList();
    callAPI.getChanelThreads(id);
    localStorage.setItem("comments.selectedChannel", `${id}`);
    callAPI.setContextData("scrollToBottom", true); // enable scroll to bottom
    callAPI.setContextData("selectedChannel", id);
    callAPI.setContextData("isAddNewThread", false);
    callAPI.setContextData("newThread", {});
  };

  return (
    <div className={wrapper}>
      <Grid container item xs={12} justify="space-between" onClick={handling}>
        <Button
          size="small"
          fullWidth
          className={wrapArrow}
          endIcon={<Avatar variant="square" src={Inactive} className={arrow} />}
        >
          {!isOpen && (
            <Typography variant="subtitle1" style={{ textTransform: "capitalize" }}>
              {t("labels.channels")}
            </Typography>
          )}
        </Button>
      </Grid>
      <List component="nav" dense style={{ paddingTop: 4 }}>
        {chatchannels.map((ent, index) => (
          <ChannelItem
            {...ent}
            id={index}
            channelKey={ent.key}
            key={ent.key || index}
            onClick={handlingChannels}
            icon={<Avatar variant="square" src={ent.icon} className={mainIcons} />}
            isOpen={isOpen}
          />
        ))}
      </List>
    </div>
  );
});

export default Channels;
