import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStyles } from "./styles";
import { Typography, TextField, Button, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import { Customer, InvoiceAddress } from "src/types";
import { requestGet, requestPost } from "src/utils/crud";
import SelectInput from "src/components/SelectInput";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "../SuccessNotification";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import InvoiceAddressIcon from "./icons/InvoiceAddressIcon";
import { AxiosResponse } from "axios";

const initialStateInvoice = {
  address: "",
  state: "",
  city: "",
  zipCode: "",
  country: {
    key: undefined,
    displayText: "",
  },
};

interface Props {
  customerId?: string | number;
  onSubmit?: (number:number) => void;
}

const CreateInvoiceAddress: React.FC<Props> = ({ customerId, onSubmit=()=>{} }) => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const [invoiceValues, setInvoiceValues] = useState<Partial<InvoiceAddress>>(initialStateInvoice);
  // Set data as Main Address
  const [invoiceAsMain, setInvoiceAsMain] = useState<boolean>(false);
  const [isDefaultAddress, setAsDefaultAddress] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { createInvoiceAddressModal },
    },
    callAppAPI,
  } = useAppContext();
  const {
    selectedCustomer: { customer },
    callAPI: { getInvoiceAddresses, setContextData },
  } = useCustomerDetailsContext();

  useEffect(() => {
    if ((!customer.key || customer.key === 0) && customerId) {
      const req = requestGet<{ customer: Partial<Customer> }>(`customers/${customerId}`).then(
        ({ data }) => data
      );
      req.then((data) => setContextData("customer", data.customer));
    }
  }, [customerId]);

  const compareCurrentAndCustomer = () => {
    if (
      invoiceValues.address === customer.address &&
      invoiceValues.country?.key === customer.country?.key &&
      invoiceValues.zipCode === customer.zipCode?.displayText &&
      invoiceValues.state === customer.province?.displayText &&
      invoiceValues.city === customer.city?.displayText
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleModalClose = useCallback(() => {
    callAppAPI.toggleModal({ id: "createInvoiceAddressModal", status: false });
    setSubmitted(false);
  }, []);

  const handleInvoiceDropdownChange = (value: number) => {
    setInvoiceValues({
      ...invoiceValues,
      country: { key: value },
    });
  };

  const handleInvoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceValues({ ...invoiceValues, [event.target.name]: event.target.value });
  };

  const handleInvoiceAsMain = () => {
    setInvoiceAsMain(!invoiceAsMain);
    setInvoiceValues({
      address: customer.address as string,
      state: customer.province?.displayText as string,
      city: customer.city?.displayText as string,
      zipCode: customer.zipCode?.displayText as string,
      country: {
        key: customer.country?.key,
        displayText: customer.country?.displayText,
      },
    });
  };

  const handleMakeAsDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsDefaultAddress(event.target.checked);
  };

  const createInvoiceAddress = async (): Promise<AxiosResponse<Partial<InvoiceAddress>>> => {
    try {
      const res = await requestPost(`InvoiceAddresses?hasResponse=true`, {
        ...invoiceValues,
        isDefault: isDefaultAddress,
      });
      return res;
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

  const assignInvoiceAddress = async (data: { key: number; isDefault: boolean }) => {
    try {
      const res = await requestPost(`Customers/${customer.key}/invoiceAddresses`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    await createInvoiceAddress()
      .then((response: AxiosResponse<Partial<InvoiceAddress>>) => {
        (async () => {
          if (response.data.key) {
            const invoiceAddressData = { key: response.data.key, isDefault: isDefaultAddress };
            await assignInvoiceAddress(invoiceAddressData)
              .then(() => {
                response.data.key && onSubmit( response.data.key );
                getInvoiceAddresses(Number(customer.key));
                setSubmitted(true);
              });
          }
        })();
      });
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    if (invoiceAsMain) {
      const matchingValues = compareCurrentAndCustomer();
      !matchingValues && setInvoiceAsMain(false);
    }

    if (
      invoiceValues &&
      invoiceValues.address &&
      invoiceValues.zipCode &&
      invoiceValues.city &&
      invoiceValues.country &&
      invoiceValues.country.key
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [invoiceValues]);

  useEffect(() => {
    if (!createInvoiceAddressModal) {
      setInvoiceValues(initialStateInvoice);
      setInvoiceAsMain(false);
    }
  }, [createInvoiceAddressModal]);

  return (
    <Modal isOpen={createInvoiceAddressModal} onClose={handleModalClose}>
      <Grid container justify="center">
        <Grid item>
          {!submitted && (
            <form onSubmit={handleSubmit}>
              <Typography variant="h3">{t("labels.details.create_invoice_address")}</Typography>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <InvoiceAddressIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.create_customer.addresses_form.invoice_address")}
                  </Typography>
                </div>
                <TextField
                  required
                  fullWidth
                  name="address"
                  label={t("labels.create_customer.addresses_form.address")}
                  value={invoiceValues.address}
                  onChange={handleInvoiceChange}
                  variant="outlined"
                  helperText={t("labels.create_customer.addresses_form.address_helper")}
                  FormHelperTextProps={{
                    classes: { root: classes.addressCaption },
                  }}
                />

                <div className={classes.fieldWrapper}>
                  <TextField
                    required
                    fullWidth
                    name="zipCode"
                    label={t("labels.create_customer.addresses_form.zip")}
                    value={invoiceValues.zipCode}
                    onChange={handleInvoiceChange}
                    variant="outlined"
                  />
                  <TextField
                    required
                    fullWidth
                    name="city"
                    label={t("labels.create_customer.addresses_form.city")}
                    value={invoiceValues.city}
                    onChange={handleInvoiceChange}
                    variant="outlined"
                    className={classes.cityField}
                  />
                </div>
                <TextField
                  fullWidth
                  name="state"
                  label={t("labels.create_customer.addresses_form.state")}
                  value={invoiceValues.state}
                  onChange={handleInvoiceChange}
                  variant="outlined"
                />
                <SelectInput
                  required
                  fullWidth
                  onSelect={handleInvoiceDropdownChange}
                  label={t("labels.create_customer.addresses_form.country")}
                  name="country"
                  value={invoiceValues?.country?.key!}
                  url="lookups/countries"
                  metaApi
                />
                <FormControlLabel
                  disabled={!customer}
                  control={
                    <Checkbox
                      onClick={handleInvoiceAsMain}
                      checked={invoiceAsMain}
                      color="primary"
                    />
                  }
                  label={t("labels.create_customer.addresses_form.invoice_as_main")}
                />
                <FormControlLabel
                  disabled={!customer}
                  control={
                    <Checkbox
                      onChange={handleMakeAsDefault}
                      checked={isDefaultAddress}
                      color="primary"
                    />
                  }
                  label={"Make as default Invoice address"}
                />
              </div>
              <div className={classes.btnGroup}>
                <Button
                  disabled={submitDisabled}
                  className={classes.submitBtn}
                  type="submit"
                  ref={submitButtonRef}
                >
                  {t("labels.common.save")}
                </Button>
                <Button onClick={handleModalClose} className={classes.cancelBtn}>
                  {t("labels.common.cancel")}
                </Button>
              </div>
            </form>
          )}
          {submitted && (
            <SuccessNotification
              message={t("labels.details.invoice_address_success")}
              onClose={handleModalClose}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateInvoiceAddress;
