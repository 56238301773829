import { makeStyles } from "@material-ui/core/styles";
import { theme } from "src/utils/styleTheme/mainStyles";

export const useStyles = makeStyles(() => ({
  topic: {
    display: "flex",
    flexDirection: "column",
    minWidth: 300,
  },
  threadItem: {
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "baseline",
    padding: theme.spacing(1),
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "&.MuiListItem-gutters": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(43, 89, 195, 0.1)"
    },
    minHeight: 69,
  },
  topicTitleWrapper: {
    marginBottom: theme.spacing(1)
  },
  topicTitle: {
    fontWeight: 500
  },
  topicSubtitle: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: 12,
    color: "rgba(20, 30, 48, 0.5)",
  },
  badgeCounter: {
      "& .MuiBadge-colorPrimary":{
        backgroundColor: "rgba(43, 89, 195, 1)",
        marginRight: theme.spacing(1)
      }
      
    
  }
}));
