import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import NoDataIcon from "src/pages/ServiceOrdersDetails/components/icons/NoDataIcon";

export const useStyles = makeStyles(() => ({
  nothingSubtitle: {
    fontSize: 16,
    color: "rgba(20, 30, 48, 0.5)",
  },
  nothingTitle: {
    fontWeight: 500,
    fontSize: 20,
    padding: 16,
  },
  nothing: {
    display: "flex",
    width: "100%",
    padding: "40px 40px 40px 0",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    flexDirection: "column",
    flex: 1,
  },
}));

const NoData: React.FC<{ is?: boolean }> = ({ is, children }) => {
  const { nothing, nothingTitle, nothingSubtitle } = useStyles();
  const { t } = useTranslation(`lineItems`);
  if (is) return null;
  return (
    <div className={nothing}>
      <NoDataIcon />
      {children}
      <Typography className={nothingTitle}>{t("labels.no_data")}</Typography>
      <Typography align="left" variant="subtitle2" className={nothingSubtitle}>
        {t("labels.no_data_subtitle")}
      </Typography>
    </div>
  );
};
export default NoData;
