import React from "react";
import { Container, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <Container>
      <Typography align="center" variant="h1">
        404
      </Typography>
      <Typography align="center" paragraph={true} variant="h4">
        Page Not Found
        <br />
        <Button color="primary" component={Link} to="/">
          Go to Home page
        </Button>
      </Typography>
    </Container>
  );
};

export default PageNotFound;
