import React, { FC, useCallback } from "react";
import { ServiceOrder } from "src/types";
import CommonList from "src/components/CommonList";
import SODetails from "src/pages/Customers/components/SO/SODetails";
import SOFieldList from "src/pages/Customers/components/SO/SOFieldList";
import Preloader from "src/components/Preloader";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { useServicesContext } from "src/providers/ServicesStore";

const SOList: FC<{ id: number }> = ({ id }) => {
  const { SOData } = useSOContext();
  const { callServicesAPI } = useServicesContext();
  const handlerServices = useCallback(
    () => ({ id }: ServiceOrder) => callServicesAPI.fetchingServices(id!, 1000),
    []
  );

  if (SOData.loading || SOData.loading || !SOData.selectedSO[id]) {
    return <Preloader is />;
  }

  return (
    <CommonList<ServiceOrder>
      listEntities={SOData.selectedSO[id]?.serviceOrders}
      handlerOnOpen={handlerServices}
      entityFields={(ent) => <SOFieldList data={ent} />}
      entityDetails={(ent) => <SODetails data={ent} />}
    />
  );
};

export default SOList;
