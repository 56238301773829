import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const MenuIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M7.0002 8.00039C7.0002 8.88405 7.71654 9.60039 8.6002 9.60039C9.48385 9.60039 10.2002 8.88405 10.2002 8.00039C10.2002 7.11673 9.48385 6.40039 8.6002 6.40039C7.71654 6.40039 7.0002 7.11673 7.0002 8.00039Z"
        fill="#141E30"
      />
      <path
        d="M13.4001 8.00039C13.4001 8.88405 14.1164 9.60039 15.0001 9.60039C15.8838 9.60039 16.6001 8.88405 16.6001 8.00039C16.6001 7.11673 15.8838 6.40039 15.0001 6.40039C14.1164 6.40039 13.4001 7.11673 13.4001 8.00039Z"
        fill="#141E30"
      />
      <path
        d="M0.600293 8.00039C0.600293 8.88405 1.31664 9.60039 2.20029 9.60039C3.08395 9.60039 3.80029 8.88405 3.80029 8.00039C3.80029 7.11673 3.08395 6.40039 2.20029 6.40039C1.31664 6.40039 0.600293 7.11673 0.600293 8.00039Z"
        fill="#141E30"
      />
    </g>
  </SvgIcon>
);
export default MenuIcon;
