import React from "react";
import { Typography } from "@material-ui/core";
import HouseColorIcon from "./icons/HouseColorIcon";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const EmptyState = () => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  return (
    <>
      <HouseColorIcon className={classes.colorHouseIcon} />
      <Typography variant="body1">
        {t("labels.create_customer.members_form.empty_state")}
      </Typography>
    </>
  );
};

export default EmptyState;
