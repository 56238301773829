import React, { useCallback, useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import NoServicesIcon from "../icons/NoServicesIcon";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useAppContext } from "src/providers/AppProvider";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { ServiceOrder } from "src/types";
import { ModalsUnion } from "src/providers/AppProvider/types";
type StyleProps = {
  disabled?: boolean;
};
export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 100,
    height: 100,
    marginTop: 20,
    marginBottom: 16,
  },
  title: {
    padding: theme.spacing(2),
  },
  links: ({ disabled }) => ({
    cursor: disabled ? "auto" : "pointer",
    color: disabled ? theme.palette.text.secondary : theme.palette.secondary.light,
    margin: `0px ${theme.spacing(1)}px`,
  }),
}));

interface Props {
  SOdata?: ServiceOrder;
  selectedService?: ServiceOrder;
}

const enabledByStatus = (status?: string) => {
  if (!status) return false;
  const Statuses = ["INVOICED", "INCOMPLETE", "NEW", "MISSINGINFORMATION"];
  return Statuses.includes(status.toLocaleUpperCase());
};

const NoServices: React.FC<Props> = ({ SOdata, selectedService }) => {
  const { t } = useTranslation("orders");
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const { callSOAPI } = useSOContext();

  const disabledByStatus = useMemo(() => {
    return enabledByStatus(SOdata?.statusMatchingName);
  }, [SOdata?.statusMatchingName]);
  const classes = useStyles({ disabled: !user?.partner?.isIntegration && !disabledByStatus });

  const handleServiceCreate = useCallback(
    (modalId: ModalsUnion) => {
      if (disabledByStatus && !user?.partner?.isIntegration) {
        toggleModal({ id: modalId, status: true });
        callSOAPI.setContextData("responseObject", selectedService || SOdata);
      }
    },
    [disabledByStatus]
  );

  return (
    <div className={classes.wrapper}>
      <NoServicesIcon className={classes.icon} />
      <Typography variant="h3" className={classes.title}>
        {t("labels.empty_state.title")}
      </Typography>
      <Grid container direction="row" justifyContent="center">
        <Typography variant="subtitle1" color="textSecondary">
          {t("labels.empty_state.subtitle")}
        </Typography>
        <Typography
          onClick={() => handleServiceCreate("createServiceModal")}
          variant="subtitle1"
          className={classes.links}
        >
          {t("labels.empty_state.create_single")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("labels.empty_state.subtitle_1")}
        </Typography>
        <Typography
          onClick={() => handleServiceCreate("createServiceBulkModal")}
          variant="subtitle1"
          className={classes.links}
        >
          {t("labels.empty_state.create_in_bulk")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("labels.empty_state.subtitle_2")}
        </Typography>
      </Grid>
    </div>
  );
};

export default NoServices;
