import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const WarehouseSellerIcon = (props: SvgIconProps) => (
    <SvgIcon
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{height: 80, width: "100%", margin: 10}}
        {...props}
    >
        <path d="M46.417 38.183C49.8175 38.183 52.5741 35.419 52.5741 32.0095C52.5741 28.6 49.8175 25.8361 46.417 25.8361C43.0166 25.8361 40.26 28.6 40.26 32.0095C40.26 35.419 43.0166 38.183 46.417 38.183Z" fill="#D0EACC" />
        <path d="M54.9016 40.7938V50.1469C52.4313 51.6578 49.5266 52.5297 46.4172 52.5297C43.3078 52.5297 40.4016 51.6578 37.9312 50.1453V40.7938C37.9312 39.3516 39.1 38.1828 40.5422 38.1828H52.2922C53.7328 38.1828 54.9016 39.3516 54.9016 40.7938Z" fill="#D0EACC" />
        <path d="M10.2874 30.462C11.1502 30.462 11.8498 29.7625 11.8498 28.8995V16.8119C11.8498 15.9489 11.1502 15.2494 10.2874 15.2494C9.42454 15.2494 8.72485 15.9489 8.72485 16.8119V28.8995C8.72485 29.7625 9.42438 30.462 10.2874 30.462Z" fill="#1B3A7F" />
        <path d="M10.2874 47.7759C11.1502 47.7759 11.8498 47.0762 11.8498 46.2134V34.1258C11.8498 33.263 11.1502 32.5633 10.2874 32.5633C9.42454 32.5633 8.72485 33.263 8.72485 34.1258V46.2134C8.72485 47.0762 9.42438 47.7759 10.2874 47.7759Z" fill="#1B3A7F" />
        <path d="M10.2874 64.3715C11.1502 64.3715 11.8498 63.672 11.8498 62.809V51.1203C11.8498 50.2575 11.1502 49.5578 10.2874 49.5578C9.42454 49.5578 8.72485 50.2575 8.72485 51.1203V62.8092C8.72485 63.672 9.42438 64.3715 10.2874 64.3715Z" fill="#1B3A7F" />
        <path d="M68.0835 0H13.1333C10.7024 0 8.72485 1.9775 8.72485 4.40828V11.5858C8.72485 12.4486 9.42454 13.1483 10.2874 13.1483C11.1502 13.1483 11.8499 12.4486 11.8499 11.5858V4.40828C11.8499 3.70078 12.4256 3.125 13.1333 3.125H21.0656V76.875H13.1333C12.4256 76.875 11.8499 76.2992 11.8499 75.5916V67.8756C11.8499 67.0128 11.1502 66.3131 10.2874 66.3131C9.42454 66.3131 8.72485 67.0128 8.72485 67.8756V75.5916C8.72485 78.0225 10.7024 80 13.1333 80H68.0836C70.5145 80 72.4921 78.0225 72.4921 75.5916V4.40828C72.4919 1.9775 70.5144 0 68.0835 0V0ZM69.3669 75.5916C69.3669 76.2992 68.7913 76.875 68.0835 76.875H24.1905V3.125H68.0835C68.7911 3.125 69.3669 3.70078 69.3669 4.40828V75.5916Z" fill="#1B3A7F" />
        <path d="M46.4172 18.4141C36.5808 18.4141 28.5782 26.4167 28.5782 36.2531C28.5782 42.513 31.7697 48.2044 37.1155 51.478C39.9088 53.1883 43.1252 54.0923 46.4172 54.0923C49.7071 54.0923 52.9227 53.1891 55.7174 51.4795C61.0643 48.2064 64.2563 42.5142 64.2563 36.2531C64.2563 26.4166 56.2536 18.4141 46.4172 18.4141ZM54.0863 48.8141C51.7833 50.2227 49.1313 50.9672 46.4172 50.9672C43.7013 50.9672 41.0493 50.2222 38.7474 48.8127C34.3364 46.1117 31.7032 41.4166 31.7032 36.253C31.7032 28.1395 38.3038 21.5389 46.4172 21.5389C54.5307 21.5389 61.1313 28.1395 61.1313 36.253C61.1313 41.4177 58.4975 46.1136 54.0863 48.8141Z" fill="#1B3A7F" />
        <path d="M62.6938 58.7731H30.1407C29.2778 58.7731 28.5782 59.4728 28.5782 60.3356C28.5782 61.1984 29.2778 61.8981 30.1407 61.8981H62.6938C63.5566 61.8981 64.2563 61.1984 64.2563 60.3356C64.2563 59.4728 63.5567 58.7731 62.6938 58.7731Z" fill="#1B3A7F" />
        <path d="M56.4438 66.0738H36.3907C35.5278 66.0738 34.8282 66.7734 34.8282 67.6363C34.8282 68.4991 35.5278 69.1988 36.3907 69.1988H56.4438C57.3066 69.1988 58.0063 68.4991 58.0063 67.6363C58.0063 66.7734 57.3068 66.0738 56.4438 66.0738Z" fill="#1B3A7F" />
        <path d="M7.50812 10.7205H16.1917V18.3716H7.50812V10.7205Z" fill="#D0EACC" />
        <path d="M7.50812 27.6898H16.1917V35.3409H7.50812V27.6898Z" fill="#D0EACC" />
        <path d="M7.50812 44.6591H16.1917V52.3102H7.50812V44.6591Z" fill="#D0EACC" />
        <path d="M7.50812 61.6283H16.1917V69.2794H7.50812V61.6283Z" fill="#D0EACC" />
    </SvgIcon>
);
export default WarehouseSellerIcon;
