import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import isEmpty from "lodash/fp/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import remove from "../icons/remove.svg";
import inc from "../icons/inc.svg";
import dec from "../icons/dec.svg";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import useCurrencyFormat from "src/utils/hooks/currency";
import Info from "./Info";
import NumberFormat from "src/components/NumberFormat";
import { TableHead, TextField, Typography } from "@material-ui/core";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import IkeaDirIcon from "src/pages/LIDetailsPage/components/icons/IkeaDirIcon";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { CustomItems, Seller } from "src/providers/LineItemsStore/types";
import { useTranslation } from "react-i18next";
import { stableSort, getComparator } from "src/utils/functions";
import { useStyles as useNodDataStyle } from "src/pages/LIDetailsPage/components/NoData";
import NoDataIcon from "src/pages/ServiceOrdersDetails/components/icons/NoDataIcon";
import { useParams } from "react-router-dom";
import uniqueId from "lodash/fp/uniqueId";
import { theme } from "src/utils/styleTheme/mainStyles";

const useStyle = makeStyles(() => ({
  mainWrapper: {},
  qty: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
    "& .MuiOutlinedInput-input": {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  selectOptions: {
    fontSize: 12,
  },
}));
const headCells = [
  { id: "name", disablePadding: true, label: "labels.item", colspan: 3 },
  { id: "qty", disablePadding: false, label: "labels.qty" },
  { id: "unit", disablePadding: false, label: "labels.unit_price" },
  { id: "Invoice", disablePadding: false, label: "labels.invoice_to" },
  { id: "Seller", disablePadding: false, label: "labels.table.seller" },
];
const ChildTable = () => {
  const {
    LIData: { listItems, sellersLookup, partnerInfo },
    callLineItemsAPI,
  } = useLineItemsContext();
  const { serviceId } = useParams();
  const {
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();
  const { t } = useTranslation(`lineItems`);
  const ParentSOIsNotCompleted = serviceOrder.statusMatchingName.toLowerCase() !== "completed";
  const { currencyFormat } = useCurrencyFormat();
  const { qty, selectOptions } = useStyle();
  const { nothing, nothingTitle, nothingSubtitle } = useNodDataStyle();

  const handleDec = (ent: Partial<CustomItems>) => {
    callLineItemsAPI.incDecInvoiceItem(ent, "dec")(serviceId);
  };
  const handleInc = (ent: Partial<CustomItems>) => {
    callLineItemsAPI.incDecInvoiceItem(ent, "inc")(serviceId);
  };
  const handleRemove = (ent: Partial<CustomItems>) => {
    callLineItemsAPI.removeInvoice(ent)(serviceId);
  };

  const handleSelect = (ent: Partial<CustomItems>) => (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (e.target.name === "lineItemType") {
      return callLineItemsAPI.updateInvoiceItem(ent, {
        lineItemType: e.target.value,
      })(serviceId);
    } else if (e.target.name === "sellerId") {
      return callLineItemsAPI.updateInvoiceItem(ent, {
        sellerId: e.target.value,
      })(serviceId);
    }
    return callLineItemsAPI.updateInvoiceItem(ent, {
      lineItemInvoicedId: e.target.value,
    })(serviceId);
  };

  const handleChange = (row: Partial<CustomItems>, value: string) => {
    callLineItemsAPI.updateInvoiceItem(row, { qty: value })(serviceId);
  };

  if (
    isEmpty(listItems[serviceId]) ||
    listItems[serviceId].filter((ent) => ent.type !== "REMOVE").length <= 0
  ) {
    return (
      <div className={nothing}>
        <NoDataIcon />
        <Typography className={nothingTitle}>{t("labels.no_data")}</Typography>
        <Typography align="left" variant="subtitle2" className={nothingSubtitle}>
          {t("labels.no_data_related_service")}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Typography
        variant="h4"
        style={{ paddingTop: theme.spacing(2), paddingLeft: theme.spacing(1) }}
      >
        {t("labels.selected_items")}
      </Typography>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              {headCells.map(({ label, colspan }, index) => (
                <TableCell
                  key={index}
                  colSpan={colspan || 1}
                  align={index !== 0 ? "right" : "left"}
                  padding={index !== 0 ? "none" : "default"}
                  style={{ fontWeight: 400 }}
                >
                  {t(label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(listItems[serviceId], getComparator("desc", "key")).map(
              (row: Partial<CustomItems>, index: number) => {
                const labelId = `enhanced-table--${index}`;
                const unitePrice = !!row?.value ? row?.value : row?.priceCustomer;
                const indexKey = !!row?.lineItemType ? row?.lineItemType - 1 : 0;
                if (row.type === "REMOVE") return null;
                return (
                  <TableRow hover tabIndex={-1} key={`lineItemRow${index}`}>
                    <TableCell
                      padding="none"
                      style={{
                        paddingLeft: 8,
                        borderTop: "1px solid rgba(20, 30, 48, 0.1)",
                        width: 20,
                      }}
                    >
                      <IkeaDirIcon />
                    </TableCell>
                    <TableCell
                      component="td"
                      id={labelId}
                      scope="row"
                      style={{
                        width: 280,
                        borderTop: "1px solid rgba(20, 30, 48, 0.1)",
                        padding: 8,
                        height: 44,
                      }}
                      padding="none"
                    >
                      <Info data={row?.catalogItem?.description}>
                        <Typography color="inherit" variant="caption">
                          {row.name}
                        </Typography>
                      </Info>
                    </TableCell>
                    <TableCell
                      align={"center"}
                      component="td"
                      id={labelId}
                      scope="row"
                      style={{
                        width: 100,
                        border: "1px solid rgba(20, 30, 48, 0.1)",
                        padding: 4,
                        height: 44,
                      }}
                      padding="none"
                    >
                      <Typography variant="caption" color="textPrimary">
                        {!!row.unitName ? row.unitName : !!row.unit ? row.unit.defaultText : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        width: 140,
                        padding: 8,
                        height: 44,
                        border: "1px solid rgba(20, 30, 48, 0.1)",
                      }}
                    >
                      <div className={qty}>
                        {ParentSOIsNotCompleted ? (
                          <div className={qty}>
                            <Avatar
                              variant={"square"}
                              src={dec}
                              onClick={() => handleDec(row)}
                              style={{ height: 14, width: 14, margin: 8 }}
                            />
                            <TextField
                              onChange={({ target }) => handleChange(row, target.value)}
                              value={row?.qty}
                              style={{ width: "90%", minWidth: 60 }}
                              InputProps={{
                                inputComponent: NumberFormat as any,
                              }}
                              variant="outlined"
                            />
                            <Avatar
                              variant={"square"}
                              src={inc}
                              onClick={() => handleInc(row)}
                              style={{ height: 14, width: 14, margin: 8 }}
                            />
                          </div>
                        ) : (
                          <Typography style={{ width: "100%" }} align="center">
                            {row.qty}
                          </Typography>
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        width: 110,
                        border: "1px solid rgba(20, 30, 48, 0.1)",
                      }}
                    >
                      <Typography variant="caption" color="textPrimary" noWrap>
                        {currencyFormat(unitePrice)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        width: 110,
                        padding: 5,
                        border: "1px solid rgba(20, 30, 48, 0.1)",
                      }}
                    >
                      {ParentSOIsNotCompleted ? (
                        <Select
                          value={row?.lineItemType || 1}
                          fullWidth
                          name={"lineItemType"}
                          onChange={handleSelect(row)}
                          variant={"outlined"}
                          classes={{
                            outlined: selectOptions,
                          }}
                        >
                          <MenuItem value={1}>{t("labels.partner")}</MenuItem>
                          <MenuItem value={2}>{t("labels.customer")}</MenuItem>
                        </Select>
                      ) : (
                        [t("labels.partner"), t("labels.customer")][indexKey]
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        width: 80,
                        padding: 5,
                        border: "1px solid rgba(20, 30, 48, 0.1)",
                      }}
                    >
                      {ParentSOIsNotCompleted ? (
                        <Select
                          value={row?.sellerId || partnerInfo?.seller?.key || 0}
                          fullWidth
                          name={"sellerId"}
                          onChange={handleSelect(row)}
                          variant={"outlined"}
                          classes={{
                            outlined: selectOptions,
                          }}
                        >
                          {!!sellersLookup &&
                            sellersLookup.map((opt: Seller) => {
                              return (
                                <MenuItem key={uniqueId("sellerLookup")} value={`${opt.key}`}>
                                  {opt.displayText}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      ) : (
                        row.seller?.displayText
                      )}
                    </TableCell>
                    {ParentSOIsNotCompleted &&
                      serviceOrder.statusMatchingName.toLowerCase() === "incomplete" && (
                        <TableCell
                          align="center"
                          style={{
                            width: 20,
                            borderTop: "1px solid rgba(20, 30, 48, 0.1)",
                            padding: 8,
                          }}
                        >
                          <IconButton
                            aria-haspopup="true"
                            size={"small"}
                            onClick={() => handleRemove(row)}
                          >
                            <Avatar
                              variant={"square"}
                              src={remove}
                              style={{ height: 14, width: 14 }}
                            />
                          </IconButton>
                        </TableCell>
                      )}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ChildTable;
