import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { DRAWER_WIDTH } from "./components/Filter/styles";

interface Props {
  isFilterPanelOpen?: boolean;
  isEmpty?: boolean;
}

export const useCustomersStyles = makeStyles<Theme, Props>((theme) => ({
  title: {
    textAlign: "left",
  },
  offSet: ({ isEmpty }) => ({
    marginTop: theme.spacing(3),
    ...(isEmpty ? { backgroundColor: "transparent", boxShadow: "none" } : { minWidth: 1300 }),
  }),
  mainWrapper: ({ isFilterPanelOpen }) => ({
    marginRight: isFilterPanelOpen ? `${DRAWER_WIDTH + theme.spacing(1)}px` : "auto",
  }),
  mainWrapper2: {
    width: "100%",
    padding: theme.spacing(2),
  },
  wrapper: {
    width: "100%",
    overflowX: "auto",
  },
  head: {
    paddingBottom: theme.spacing(3),
  },
  svg: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.375),
  },
  link: {
    color: theme.palette.functionalColors.dark,
    padding: theme.spacing(1),
    zIndex: 50,
    alignItems: "center",
    flexFlow: "nowrap",
    "& > *": {
      margin: "0 4px",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  dynamicWarningMessage: {
    minWidth: 28,
  },
  paper: {
    "& .MuiDrawer-paper": {
      width: DRAWER_WIDTH,
      position: "absolute !important" as "absolute",
      overflowY: "scroll",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      zIndex: "1 !important" as any,
      maxHeight: "calc(100vh - 31px - 51px - 16px - 57px)",
      minHeight: 500,
    },
  },
}));

export const customerListStyles = makeStyles((theme) => ({
  rootWrapper: {
    minWidth: 800,
  },
  accordionRoot: {
    "&::before": {
      backgroundColor: "#fff",
    },
  },
  panelSummary: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0 4px",
    "& :focused": {
      backgroundColor: "#fff",
    },
    "& >div": {
      margin: 0,
    },
    "& >.Mui-expanded": {
      margin: 0,
    },
    "& .MuiListItem-gutters": {
      padding: 4,
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(1),
    },
  },
  nameWrapper: {
    display: "flex",
  },
  infoBox: {
    width: "100%",
    padding: `0 ${theme.spacing(1)}px`,
    wordBreak: "break-word",
    verticalAlign: "sub",
  },
}));
