import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 400,
    margin: `${theme.spacing(1)}px 0px`,
  },
  actions: {
    justifyContent: "flex-start",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  nameColumn: {
    maxWidth: 200,
  },
  locationColumn: {
    maxWidth: 200,
    minWidth: 200,
  },
  categoryColumn: {
    maxWidth: 200,
    minWidth: 200,
  },
  descriptionColumn: {
    minWidth: 250,
  },
}));
