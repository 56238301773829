import React, { FC, useCallback, useMemo } from "react";
import { Warehouse } from "src/types";
import CommonList from "src/components/CommonList";
import WarehouseDetails from "./WarehouseTableDetails";
import WarehouseFieldList from "./WarehouseFieldList";
import { useWarehouseContext } from "src/providers/WarehousesStore";
import { useSOContext } from "src/providers/ServiceOrdersStore";

const WarehousesList: FC = React.memo(() => {
  const { warehousesData } = useWarehouseContext();
  const { callSOAPI } = useSOContext();

  const handlerServices = useCallback(
    () => ({ key }: Warehouse) => callSOAPI.fetchingServices(key, "Warehouses", 999),
    []
  );

  return (
    <CommonList<Warehouse>
      listEntities={useMemo(() => warehousesData.warehouses, [])}
      handlerOnOpen={handlerServices}
      entityFields={useCallback(
        (ent) => (
          <WarehouseFieldList data={ent} />
        ),
        []
      )}
      entityDetails={useCallback(
        (ent) => (
          <WarehouseDetails data={ent} />
        ),
        []
      )}
    />
  );
});

export default WarehousesList;
