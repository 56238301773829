import React, { useEffect, useState } from "react";

import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";

import { useTranslation } from "react-i18next";
import { useCommentsContext } from "src/providers/CommentsStore";
import AutoCompleteCommentTitles from "../../AutoCompleteCommentTitles";
import { FormProvider, useForm } from "react-hook-form";
import isEmpty from "lodash/fp/isEmpty";

interface Topic {
  topicName: string;
  topicComment: string;
  error: boolean;
  touch: boolean;
}

const INITIAL_STATE: Topic = {
  topicName: "",
  topicComment: "",
  error: false,
  touch: false,
};

const AddThread = () => {
  const { t } = useTranslation("comments");
  const { callAPI, comments } = useCommentsContext();
  const methods = useForm<Partial<Topic>>({
    mode: "all",
  });
  const topicName = methods.watch("topicName");
  const {
    isAddNewThread,
    chatchannels,
    topicDataIsValid,
    selectedChannel,
    meta,
    shareByEmail,
  } = comments;
  const [data, setData] = useState<Topic>(INITIAL_STATE);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    return () => {
      callAPI.setContextData("topicDataIsValid", false);
      callAPI.setContextData("isAddNewThread", false);
    };
  }, []);

  const closeInfo = () => {
    setOpen(!open);
  };
  const handlePrepareSave = () => {
    callAPI.setContextData("newThread", {
      messageText: data.topicComment.trim(),
      messageThread: {
        threadKey: 0,
        threadChannelKey: chatchannels[selectedChannel].key,
        entityKey: meta.key,
        entityType: meta.orderType,
        threadTitle: !!topicName ? topicName : "General",
      },
    });
  };

  const onShareByEmailChange: CheckboxProps["onChange"] = (_e, checked) => {
    callAPI.setContextData("shareByEmail", checked);
  };

  useEffect(() => {
    if (data.touch && data.topicComment === "") {
      setData((prev) => ({
        ...prev,
        error: true,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        error: false,
      }));
    }
  }, [data.topicComment]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement & { key: string }>) => {
    if (!topicDataIsValid) {
      if (!data.error && data.touch && !isEmpty(target.value.trim())) {
        callAPI.setContextData(
          "topicDataIsValid",
          !data.error && data.touch && !!target.value.trim()
        );
      }
    }
    if (isEmpty(target.value.trim())) {
      callAPI.setContextData("topicDataIsValid", false);
    }
    if (target.name !== "topicName") {
      setData((prev) => ({
        ...prev,
        [target.name]: target.value,
        touch: true,
      }));
    }
  };

  const classes = useStyles();

  return (
    <div style={{ padding: 8 }}>
      <FormProvider {...methods}>
        <AutoCompleteCommentTitles />
      </FormProvider>
      <Collapse in={isAddNewThread && open}>
        <Alert
          variant="filled"
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={closeInfo}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {t("labels.no_topic_info")}
        </Alert>
      </Collapse>
      <TextField
        required
        fullWidth
        multiline
        rows={4}
        name="topicComment"
        label={t("labels.topic_comment_placeholder")}
        value={data.topicComment}
        error={data.error}
        onBlur={handlePrepareSave}
        helperText={data.error ? t("labels.cannot_be_empty") : ""}
        onChange={handleChange}
        variant="outlined"
      />
      <div className={classes.actions}>
        <FormControlLabel
          label={<Typography variant="caption">{t("labels.share_by_email")}</Typography>}
          control={
            <Checkbox color="primary" onChange={onShareByEmailChange} checked={shareByEmail} />
          }
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((_theme) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default AddThread;
