import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SellersIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 1.47465V14.5253C15.0003 15.3399 14.2166 16.0003 13.25 16H2.75C1.7834 16.0003 0.999658 15.3399 1 14.5253V1.47465C0.999658 0.660138 1.7834 -0.000287924 2.75 9.41699e-08H13.25C14.2166 -0.000287924 15.0003 0.660138 15 1.47465Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M11 13C11 12.2044 10.6839 11.4413 10.1213 10.8787C9.55871 10.3161 8.79565 10 8 10C7.20435 10 6.44129 10.3161 5.87868 10.8787C5.31607 11.4413 5 12.2044 5 13L8 13H11Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M8.0005 5.07648C8.85001 5.07447 9.54011 5.76182 9.54155 6.61134C9.54284 7.46085 8.85491 8.15051 8.0054 8.15123C7.15588 8.15181 6.4668 7.4633 6.4668 6.61378C6.46593 5.76586 7.15243 5.07763 8.0005 5.07648Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M9.6 3.3678H6.4C6.17922 3.3678 6 3.14377 6 2.8678C6 2.59163 6.17922 2.3678 6.4 2.3678H9.6C9.82094 2.3678 10 2.59163 10 2.8678C10 3.14377 9.82094 3.3678 9.6 3.3678Z"
      fill="white"
    />
  </SvgIcon>
);

export default SellersIcon;
