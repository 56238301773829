import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { useStyles } from "../../styles";

interface ActionProps {
  submit: MouseEventHandler<HTMLButtonElement>;
  clear: MouseEventHandler<HTMLButtonElement>;
}

const Actions = ({ submit, clear }: ActionProps) => {
  const { actions } = useStyles();
  const { t } = useTranslation("orders");
  return (
    <div className={actions}>
      <Button  type="submit" variant="contained" color="primary" onClick={submit}>
        {t("list_filter.apply_btn")}
      </Button>
      <Button color="primary" onClick={clear}>
        {t("list_filter.clear_btn")}
      </Button>
    </div>
  );
};

export default Actions;
