import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import { DRAWER_WIDTH } from "./components/Filter/styles";
interface Props {
  isFilterPanelOpen?: boolean;
  isEmpty?: boolean;
}

export const useOrdersStyles = makeStyles<Theme, Props>((theme) => ({
  mainWrapper: ({ isFilterPanelOpen }) => ({
    marginRight: isFilterPanelOpen ? `${DRAWER_WIDTH + theme.spacing(1)}px` : "auto",
  }),
  head: {
    marginLeft: 40,
  },
  wrapper: {
    width: "100%",
    overflowX: "auto",
  },
  offSet: ({ isEmpty }) => ({
    ...(isEmpty ? { backgroundColor: "transparent", boxShadow: "none" } : { minWidth: 1300 }),
  }),
  svg: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.375),
  },
  paper: {
    "& .MuiDrawer-paper": {
      width: DRAWER_WIDTH,
      position: "absolute !important" as "absolute",
      overflowY: "scroll",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      zIndex: "1 !important" as any,
      maxHeight: "calc(100vh - 31px - 51px - 16px - 57px)",
      minHeight: 500,
    },
  },
}));
