import React, { useState } from "react";
import { useStyles } from "./styles";
import FilesFieldsList from "./components/FilesFields";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import { Typography, Container, Divider, Grid } from "@material-ui/core";
import { File } from "src/types";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import FileSortDropdown from "./components/FileSortDropdown";
import NoFiles from "src/components/NoFiles";
import { useTranslation } from "react-i18next";
import { FILE_VIEW_TYPES } from "src/components/Carousel/types";
import { isNil } from "lodash";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import Carousel from "src/components/Carousel/Carousel";
import DeleteFileCarouselAction from "src/components/Carousel/DeleteFileCarouselAction";
import { SODetailsType } from "../../pages/ServiceOrdersDetails/type";
import { Feature } from "../../components/FeatureFlag/index";

export const Files = () => {
  const { t } = useTranslation("orders");
  const { serviceOrderId } = useParams<SODetailsType>();
  const classes = useStyles();
  const {
    filesState: { files, loading, totalCount },
    callAPI: { getFiles },
  } = useSODetailsContext();
  const [fileViewType, setFileViewType] = useState(FILE_VIEW_TYPES.list);

  const onChangeFileViewType = (v: number) => {
    if (!isNil(v)) {
      setFileViewType(v);
    }
  };

  return (
    <Container className={classes.container} maxWidth={"xl"}>
      <PreloaderLine loading={loading} />
      {!loading && totalCount < 1 ? (
        <NoFiles />
      ) : (
        <div>
          <Typography variant="h3">{t("labels.files.title")}</Typography>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={2}>
              <FileSortDropdown />
            </Grid>
            <Grid item>
              <Feature featureKey="carouselFiles">
                {() => (
                  <ToggleButtonGroup
                    exclusive={true}
                    className={classes.fileTypesSwitch}
                    value={fileViewType}
                    onChange={(_, v) => onChangeFileViewType(v)}
                    color="primary"
                  >
                    <ToggleButton value={FILE_VIEW_TYPES.list}>
                      {t("labels.files.carousel.list")}
                    </ToggleButton>
                    <ToggleButton value={FILE_VIEW_TYPES.carousel}>
                      {t("labels.files.carousel.carousel")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Feature>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          {fileViewType === FILE_VIEW_TYPES.list && [
            files.serviceOrderFiles.map((file: File) => {
              return (
                <div key={file.id}>
                  <FilesFieldsList imageClassName={classes.previewImage} file={file} />
                  <Divider />
                </div>
              );
            }),
            files.serviceFiles.map((serviceFileCollection, idx) => (
              <div key={idx}>
                {serviceFileCollection.serviceFiles.map((file: File) => (
                  <div key={file.id}>
                    <FilesFieldsList imageClassName={classes.previewImage} file={file} />
                    <Divider />
                  </div>
                ))}
              </div>
            )),
          ]}
          <Feature featureKey="carouselFiles">
            {() => (
              <Carousel
                files={files.carouselFiles}
                open={fileViewType === FILE_VIEW_TYPES.carousel}
                renderAdditionalActions={(file) => (
                  <DeleteFileCarouselAction
                    onDelete={() => serviceOrderId && getFiles(serviceOrderId)}
                    deleteFile={file}
                  />
                )}
              />
            )}
          </Feature>
        </div>
      )}
    </Container>
  );
};

const FilesPage = () => <Files />;

export default FilesPage;
