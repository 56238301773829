import React, { FC } from "react";
import { ServiceFileQuestionnaire } from "src/providers/QuestionnaireStore/types";
import { Grid, Typography } from "@material-ui/core";
import ServiceIcon from "./icons/ServiceIcon";
import CollapsibleRow from "src/components/CollapsibleRow";
import { FileQuestionnaireItem } from "src/types";
import QuestionnaireTable from "./QuestionnaireTable";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type Props = {
  S_FileQuestionnaire_Items: ServiceFileQuestionnaire[];
};

const ServiceQuestionnaireTable: FC<Props> = ({ S_FileQuestionnaire_Items }) => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const Table = (el: FileQuestionnaireItem[]): JSX.Element => <QuestionnaireTable data={el} />;

  const entityFields = (s_name: string): JSX.Element => (
    <>
      <ServiceIcon style={{ marginRight: 10 }} />
      <Typography>{s_name}</Typography>
    </>
  );
  return (
    <>
      {S_FileQuestionnaire_Items.map((S_FileQuestionnaires_Item) => {
        if (!!S_FileQuestionnaires_Item.serviceFileQuestionnaires.length) {
          return (
            <React.Fragment key={S_FileQuestionnaires_Item.serviceId}>
              <Grid container className={classes.title}>
                <Grid item>
                  <Typography>{t("labels.details.questionnaire.services")}</Typography>
                </Grid>
              </Grid>
              <CollapsibleRow
                entityFields={entityFields(S_FileQuestionnaires_Item.serviceName)}
                entityDetails={Table(S_FileQuestionnaires_Item.serviceFileQuestionnaires)}
                classes={{
                  listItem: classes.listItem,
                  container: classes.container,
                }}
              />
            </React.Fragment>
          );
        } else return null;
      })}
    </>
  );
};

export default ServiceQuestionnaireTable;
