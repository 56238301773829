import React from "react";
import EditModal from "src/pages/ServiceDetails/tabs/LineItems/components/Modals/EditModal";
import DeleteModal from "src/pages/ServiceDetails/tabs/LineItems/components/Modals/DeleteModal";
import SuccessfulModal from "src/pages/ServiceDetails/tabs/LineItems/components/Modals/SuccessfulModal";
import { useLineItemsContext } from "src/providers/LineItemsStore";

export default function ModalMenu() {
  const {
    LIData: { modal },
  } = useLineItemsContext();

  if (modal.open === "edit") return <EditModal />;
  if (modal.open === "successful") return <SuccessfulModal />;
  if (modal.open === "delete") return <DeleteModal />;
  return null;
}
