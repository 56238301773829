import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  selected: {
    cursor: "default",
  },
  selectedIcon: {
    justifyContent: "flex-end",
  },
  currentFlag: {
    marginRight: theme.spacing(1),
  },
  menuClosed: {
    transition: ".4s",
  },
  menuOpened: {
    transform: "rotate(180deg)",
    transition: ".4s",
  },
  flag: {
    width: 29,
    height: "100%",
    margin: `0 ${theme.spacing(1)}px 0 0`,
  },
}));
