import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ReadAllIcon = () => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M5.19696 7.60007L7.29699 9.70009L14.9971 2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M13.5972 8.30008V13.2001C13.5972 13.9733 12.9704 14.6002 12.1972 14.6002H2.39709C1.62388 14.6002 0.99707 13.9733 0.99707 13.2001V3.40002C0.99707 2.62681 1.62388 2 2.39709 2H10.0972"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
};
export default ReadAllIcon;
