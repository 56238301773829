import makeStyles from "@material-ui/core/styles/makeStyles";

export const useServiceDetailsStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    padding: theme.spacing(2),
  },
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
  head: {
    paddingBottom: theme.spacing(3),
  },
  offSet: {
    marginTop: theme.spacing(3),
  },
  svg: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.375),
  },
  parentLink: {
    padding: theme.spacing(1),
  },
  outlineBtn: {
    padding: theme.spacing(2),
    color: "#2B59C3",
    fontSize: "14px !important",
  },
}));
