import React from "react";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { mainNavigationList, secondaryNavigationList, NavigationListType } from "./NavigationList";
import Drawer from "@material-ui/core/Drawer";
import { useStyles } from "src/containers/MainWrapper/styles";
import { ChevronNavBtnType } from "./ChevronNavBtn";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import NavigationLogoIcon from "src/assets/icons/NavigationLogoIcon";
import SmallNavLogo from "src/assets/icons/SmallNavLogo";
import uniqueId from "lodash/fp/uniqueId";
import { useTranslation } from "react-i18next";
import { Link, Link as RouterLink } from "react-router-dom";
import { useAppContext } from "src/providers/AppProvider";
import HelpIcon from "src/assets/icons/SecondaryNavListIcons/HelpIcon";

function ListItemLink(props: ListItemProps<Link, { button?: true }>) {
  return <ListItem button component={RouterLink} {...props} />;
}

const NavBar = ({ open }: ChevronNavBtnType) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { fullScreenMode } = useAppContext();
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
        [classes.hidden]: fullScreenMode,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.logoIcon}>
        {open ? (
          <NavigationLogoIcon style={{ width: "100%", height: "100%" }} />
        ) : (
          <SmallNavLogo style={{ width: 44, height: 44 }} />
        )}
      </div>
      <List component="nav" className={classes.listWrapper}>
        {mainNavigationList(t).map(({ title, icon, link }: NavigationListType) => (
          <ListItemLink button key={uniqueId("main")} to={`/${link}`}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
          </ListItemLink>
        ))}
      </List>
      <Divider className={classes.divider} />
      <List className={classes.listWrapper}>
        {secondaryNavigationList(t).map(({ title, icon, link }: NavigationListType) => (
          <ListItemLink button key={uniqueId("sec")} to={link && `/${link}`}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
          </ListItemLink>
        ))}
        <ListItem button component={"a"} target="_blank" href="https://montera.help/guide/partner">
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Help" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default React.memo(NavBar);
