import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import SuccessIcon from "src/pages/Warehouses/modals/CreateWarehouses/Success/icons/SuccessIcon";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
}

const SuccessCustomerCreate: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation("warehouses");
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">{t("form.edit_success")}</Typography>
        </div>

        <div className={classes.centerBtn}>
          <Button onClick={onClose} className={classes.submitBtn}>
            {t("labels.ok", { ns: "common" })}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessCustomerCreate;
