import React, { FC } from "react";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useCommentsContext } from "src/providers/CommentsStore";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";

interface Props {
  msg: any;
  selectedId: string | number;
}
const Content: FC<Props> = ({ msg = {}, selectedId = "" }) => {
  const { t } = useTranslation("comments");
  const {
    state: {
      user: { isStaffChatRole },
    },
  } = useAppContext();
  const { callAPI, comments } = useCommentsContext();
  const { chatchannels, selectedChannel } = comments;
  const shareList = chatchannels.filter(
    (ent) => [chatchannels[0].key, chatchannels[selectedChannel].key].indexOf(ent.key) < 0
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  //TODO add types
  const handleClick = (event: any): any => {
    setAnchorEl(event.currentTarget);
  };

  const shareByChanel = (channelId: string) => {
    if (channelId && selectedId) {
      callAPI.shareMsg({
        messageKey: selectedId,
        channelKey: channelId,
      });
    }
    setAnchorEl(null);
  };

  const shareCommentByEmailHandler = (comment: any) => {
    callAPI.setContextData("shareCommentByEmail", { ...comment });
    callAPI.setContextData("sharingByEmailOpen", true);
  };

  if (msg.key === selectedId) {
    return (
      <div>
        <Avatar
          variant={"rounded"}
          onClick={handleClick}
          style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
        >
          <MoreVertIcon
            style={{
              transform: "rotate(90deg)",
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "#141E30",
            }}
          />
        </Avatar>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {!!shareList &&
            isStaffChatRole &&
            shareList.map((ent) => (
              <MenuItem key={ent.key} onClick={() => shareByChanel(`${ent?.key}`)}>
                {ent.displayText}
              </MenuItem>
            ))}
          {!!msg.message && (
            <MenuItem key={msg.key} onClick={() => shareCommentByEmailHandler(msg)}>
              {t("labels.share_by_email")}
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
  return null;
};
export default Content;
