import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 36,
      height: 36,
    },
  })
);

const SuccessStepsIcon = (props: SvgIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={classes.root}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#4ECDC4" />
      <path
        d="M13.1973 15.6001L15.2973 17.7001L22.9974 10"
        stroke="#4ECDC4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5972 16.3001V21.2001C21.5972 21.9733 20.9704 22.6002 20.1972 22.6002H10.3971C9.62388 22.6002 8.99707 21.9733 8.99707 21.2001V11.4C8.99707 10.6268 9.62388 10 10.3971 10H18.0972"
        stroke="#4ECDC4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default SuccessStepsIcon;
