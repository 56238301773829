import React, { useCallback, useEffect } from "react";
import {
  TextField,
  Typography,
  Chip,
  Grid,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
} from "@material-ui/core";
import {
  BlockButton,
  Toolbar,
} from "src/containers/Comments/components/ThreadsAndChat/Chat/components";
import attachFileInactive from "../../../images/attachFileInactive.svg";

import { theme } from "src/utils/styleTheme/mainStyles";
import { useAppContext } from "src/providers/AppProvider";
import { useCommentsContext } from "src/providers/CommentsStore";
import { useTranslation } from "react-i18next";
import { deleteFileFromService, requestDelete } from "src/utils/crud";
import FileTypeIcon from "src/components/FileIcon";
import { FileType } from "src/providers/CommentsStore/types";
import { MonteraDropZoneNoUpload } from "src/components/DropZone";
import { useFilesContext } from "src/providers/FilesStore";
import { FileDescriptor } from "src/components/DropZone/interfaces";
import i18next from "i18next";
import { makeStyles } from "@material-ui/core/styles";
interface Props {
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileUploading?: (event: React.MouseEvent) => void;
  handleSendMsg: () => void;
  data: any;
}

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    marginTop: 0,
    flexDirection: "column",
    padding: 8,
  },
  dropZoneWrapper: {
    "& > div": {
      height: 113,
    },
  },
  pressForSendText: {
    display: "flex",
    paddingLeft: 12,
    fontSize: 12,
    alignItems: "center",
    color: "rgba(20, 30, 48, 0.5)",
  },
}));

const ActionsToolBar: React.FC<Props> = ({ onChangeHandler, handleSendMsg, data }) => {
  const { t } = useTranslation("comments");
  const classes = useStyles();
  const {
    callAppAPI: { toggleModal },
    state: {
      user: { isStaffChatRole },
      modals: { commentsFilesUpload },
    },
  } = useAppContext();

  const {
    callAPI,
    comments: {
      chatchannels,
      selectedChannel,
      uploadedFiles = [],
      shareByEmail,
      displayDragAndDrop,
    },
  } = useCommentsContext();

  const { addFiles } = useFilesContext();

  useEffect(() => {
    if (!commentsFilesUpload) callAPI.setContextData("displayDragAndDrop", commentsFilesUpload);
  }, [commentsFilesUpload]);

  // DELETE FILE HANDLER
  const handleDeleteFile = ({ fileServiceId, id }: Partial<FileType>) => {
    if (fileServiceId && id) {
      requestDelete(`files/${id}`);
      deleteFileFromService(fileServiceId);
      const newFileList = uploadedFiles.filter((file) => file.id !== id);
      callAPI.setContextData("uploadedFiles", newFileList);
    }
  };

  const onFileUploading = () => {
    toggleModal({ id: "commentsFilesUpload", status: true });
  };

  const onDrop = (items?: FileDescriptor[]) => {
    toggleModal({ id: "commentsFilesUpload", status: true });
    if (!!items?.length) {
      const newFiles = items.map((item) => ({
        ...item,
        file: item.file,
      }));
      addFiles(newFiles);
    }
  };
  const onShareByEmailChange: CheckboxProps["onChange"] = (_e, checked) => {
    callAPI.setContextData("shareByEmail", checked);
  };

  const handleDragEnter = useCallback(() => {
    callAPI.setContextData("displayDragAndDrop", true);
  }, []);

  const handleDragLeave = useCallback(() => {
    callAPI.setContextData("displayDragAndDrop", false);
  }, []);

  if (!isStaffChatRole && chatchannels[selectedChannel].matchingText === "Public") {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div
        style={{ display: displayDragAndDrop ? "block" : "none" }}
        className={classes.dropZoneWrapper}
      >
        <MonteraDropZoneNoUpload
          disabled={false}
          multiple={true}
          onDropPreview={onDrop}
          handleDragLeave={handleDragLeave}
          dropZoneDescriptions={
            <Typography component="span">
              {i18next.t("labels.main_text", { ns: "files" })}&nbsp;
            </Typography>
          }
        />
      </div>
      <div style={{ display: !displayDragAndDrop ? "block" : "none" }}>
        <TextField
          fullWidth
          required={true}
          placeholder={t("labels.type_comment")}
          rows={4}
          multiline
          name="text"
          value={data}
          onChange={onChangeHandler}
          variant="outlined"
          inputProps={{ maxLength: 10001 }}
          onDragEnter={handleDragEnter}
        />
      </div>
      {uploadedFiles.length !== 0 && (
        <Grid
          container
          component="ul"
          direction="row"
          alignItems="center"
          style={{ listStyleType: "none", padding: 0, margin: 0 }}
        >
          {uploadedFiles.map((file: FileType) => {
            return (
              <li key={file.id} style={{ marginRight: theme.spacing(1) }}>
                <Chip
                  icon={<FileTypeIcon type={file.mimeType} />}
                  label={file.fileName}
                  onDelete={() => handleDeleteFile(file)}
                  variant="outlined"
                  style={{ paddingLeft: theme.spacing(1) }}
                />
              </li>
            );
          })}
        </Grid>
      )}
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className={classes.pressForSendText}>
          {t("labels.press_for_sending")}
        </Typography>
        <div>
          <FormControlLabel
            label={<Typography variant="caption">{t("labels.share_by_email")}</Typography>}
            control={
              <Checkbox color="primary" onChange={onShareByEmailChange} checked={shareByEmail} />
            }
          />
          <BlockButton
            onClick={onFileUploading}
            icon={<img src={attachFileInactive} alt="attachFile" />}
          />
          <BlockButton
            onClick={handleSendMsg}
            icon={<img src="/images/utils/sentMsg.png" alt="sendMsg" width="18px" />}
          />
        </div>
      </Toolbar>
    </div>
  );
};

export default ActionsToolBar;
