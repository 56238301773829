import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { AxiosResponse } from "axios";
import {
  Typography,
  TextField,
  Button,
  debounce,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { DeliveryAddress, InvoiceAddress, LookupObject, Customer } from "src/types";
import { requestMetaGet, requestPost } from "src/utils/crud";
import SelectInput from "src/components/SelectInput";
import CustomerAutocomplete from "../CustomerAutocomplete";
import DeliveryAddressIcon from "./icons/DeliveryAddressIcon";
import InvoiceAddressIcon from "./icons/InvoiceAddressIcon";
import { useTranslation } from "react-i18next";

const initialStateDelivery = {
  address: "",
  city: {
    key: undefined,
    displayText: "",
  },
  zipCode: {
    key: undefined,
    displayText: "",
  },
  province: {
    key: undefined,
    displayText: "",
  },
  country: {
    key: undefined,
    displayText: "",
  },
};

const initialStateInvoice = {
  address: "",
  state: "",
  city: "",
  zipCode: "",
  country: {
    key: undefined,
    displayText: "",
  },
};

interface Props {
  customerData: Partial<Customer>;
  afterSubmit: () => void;
  handleModalClose: () => void;
}

const CreateAddressesForm: React.FC<Props> = ({ customerData, afterSubmit, handleModalClose }) => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const [deliveryValues, setDeliveryValues] = useState<Partial<DeliveryAddress>>(
    initialStateDelivery
  );
  const [invoiceValues, setInvoiceValues] = useState<Partial<InvoiceAddress>>(initialStateInvoice);
  // Handling Delivery Autocomplete
  const [deliveryZipCodesOpen, setDeliveryZipCodesOpen] = useState<boolean>(false);
  const [deliveryZipCodesLoading, setDeliveryZipCodesLoading] = useState<boolean>(false);
  const [deliveryZipCodeValues, setDeliveryZipCodeValues] = useState<LookupObject[]>([]);
  // Set data as Main Address
  const [deliveryAsMain, setDeliveryAsMain] = useState<boolean>(false);
  const [invoiceAsMain, setInvoiceAsMain] = useState<boolean>(false);
  // Set as Default Address
  const [isDefaultDeliveryAddress, setAsDefaultDeliveryAddress] = useState<boolean>(false);
  const [isDefaultInvoiceAddress, setAsDefaultInvoiceAddress] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const getZipCodes = debounce(async (displayText: string) => {
    const zipCodes = await requestMetaGet<LookupObject[]>(
      `lookups/zipCodes?countryKey=${deliveryValues.country?.key}&displayText=${displayText}`
    );
    let response = zipCodes.data.map((item) => {
      return { key: item.key, displayText: item.displayText };
    });
    setDeliveryZipCodeValues(response);
    setDeliveryZipCodesLoading(false);
  }, 600);

  const getCascadeData = async (zipCode: string | number) => {
    return await requestMetaGet<Partial<DeliveryAddress>>(
      `lookups/zipCodes/${zipCode}?countryKey=${deliveryValues.country?.key}`
    );
  };

  //Zip Code Autocomplete
  const handleAutocompleteChange = async (value: string, getNewData: boolean) => {
    if (getNewData) {
      setDeliveryZipCodesOpen(true);
      setDeliveryZipCodesLoading(true);
      await getZipCodes(value);
    } else {
      setDeliveryZipCodesOpen(false);
    }

    setDeliveryValues((prev) => ({ ...prev, zipCode: { ...prev.zipCode, displayText: value } }));
  };

  // Zip Code Select
  const handleAutocompleteSelect = (value: number, displayText: string) => {
    setDeliveryValues((prev) => ({ ...prev, zipCode: { ...prev.zipCode, key: value } }));
    setDeliveryZipCodesOpen(false);
    handleDataCascade(displayText);
  };

  const handleDataCascade = async (parentZipCode: string) => {
    const cascadeData = await getCascadeData(parentZipCode);
    if (cascadeData && cascadeData.data) {
      setDeliveryValues({
        ...deliveryValues,
        zipCode: {
          key: cascadeData.data.key,
          displayText: cascadeData.data.displayText,
        },
        city: {
          key: cascadeData.data?.city?.key,
          displayText: cascadeData.data?.city?.displayText,
        },
        province: {
          key: cascadeData.data?.province?.key,
          displayText: cascadeData.data?.province?.displayText,
        },
        country: {
          key: cascadeData.data?.country?.key,
          displayText: cascadeData.data?.country?.displayText,
        },
      });
    }
  };

  // Country Select
  const handleDeliveryDropdownChange = (value: number) => {
    setDeliveryValues({
      ...deliveryValues,
      country: { key: value },
      zipCode: {
        key: undefined,
        displayText: "",
      },
      city: {
        key: undefined,
        displayText: "",
      },
      province: {
        key: undefined,
        displayText: "",
      },
    });
  };

  const handleInvoiceDropdownChange = (value: number) => {
    setInvoiceValues({
      ...invoiceValues,
      country: { key: value },
    });
  };

  const handleDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeliveryValues({ ...deliveryValues, [event.target.name]: event.target.value });
  };

  const handleInvoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceValues({ ...invoiceValues, [event.target.name]: event.target.value });
  };

  const handleDeliveryAsMain = () => {
    setDeliveryAsMain(!deliveryAsMain);
    setDeliveryValues({
      address: customerData.address as string,
      city: { key: customerData.city?.key, displayText: customerData.city?.displayText },
      zipCode: {
        key: customerData.zipCode?.key,
        displayText: customerData.zipCode?.displayText,
      },
      province: {
        key: customerData.province?.key,
        displayText: customerData.province?.displayText,
      },
      country: {
        key: customerData.country?.key,
        displayText: customerData.country?.displayText,
      },
    });
  };

  const handleInvoiceAsMain = () => {
    setInvoiceAsMain(!invoiceAsMain);
    setInvoiceValues({
      address: customerData.address as string,
      state: customerData.province?.displayText as string,
      city: customerData.city?.displayText as string,
      zipCode: customerData.zipCode?.displayText as string,
      country: {
        key: customerData.country?.key,
        displayText: customerData.country?.displayText,
      },
    });
  };

  const createDeliveryAddress = async (data: Partial<DeliveryAddress>) => {
    try {
      const res = await requestPost(`DeliveryAddresses?hasResponse=true`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };
  // TODO: REFACTOR POST METHOD BELOW WHEN ENDPOINT IS AVAILABLE. Currently using endpoint from Staff API.
  const createInvoiceAddress = async (data: Partial<InvoiceAddress>) => {
    try {
      const res = await requestPost(`InvoiceAddresses?hasResponse=true`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const assignDeliveryAddress = async (data: { key: number }) => {
    try {
      const res = await requestPost(`Customers/${customerData.key}/deliveryAddresses`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const assignInvoiceAddress = async (data: { key: number }) => {
    try {
      const res = await requestPost(`Customers/${customerData.key}/invoiceAddresses`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleMakeAsDefault = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "deliveryAddress") setAsDefaultDeliveryAddress(e.target.checked);
    else setAsDefaultInvoiceAddress(e.target.checked);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let deliveryAssignedRes, invoiceAssignedRes;
    submitButtonRef.current?.setAttribute("disabled", "disabled");

    const deliveryObj = {
      address: deliveryValues.address,
      country: { key: deliveryValues.country?.key },
      city: { key: deliveryValues.city?.key },
      zipCode: { key: deliveryValues.zipCode?.key },
      state: { key: deliveryValues.province?.key },
      isDefault: isDefaultDeliveryAddress,
    };
    const deliveryCreatedRes = await createDeliveryAddress(deliveryObj);
    if ((deliveryCreatedRes as AxiosResponse).status === 201) {
      const deliveryAddressData = {
        key: (deliveryCreatedRes as AxiosResponse).data.key,
        isDefault: isDefaultDeliveryAddress,
      };
      deliveryAssignedRes = await assignDeliveryAddress(deliveryAddressData);
    }
    const invoiceObj = {
      ...invoiceValues,
      isDefault: isDefaultInvoiceAddress,
    };
    const invoiceCreatedRes = await createInvoiceAddress(invoiceObj);
    if ((invoiceCreatedRes as AxiosResponse).status === 201) {
      const invoiceAddressData = {
        key: (invoiceCreatedRes as AxiosResponse).data.key,
        isDefault: isDefaultInvoiceAddress,
      };
      invoiceAssignedRes = await assignInvoiceAddress(invoiceAddressData);
    }

    if (
      deliveryAssignedRes &&
      deliveryAssignedRes.status === 204 &&
      invoiceAssignedRes &&
      invoiceAssignedRes.status === 204
    ) {
      afterSubmit();
    }
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    !deliveryZipCodesOpen && setDeliveryZipCodeValues([]);
  }, [deliveryZipCodesOpen]);

  useEffect(() => {
    if (
      deliveryValues &&
      deliveryValues.address &&
      deliveryValues.city &&
      deliveryValues.city.key &&
      deliveryValues.country &&
      deliveryValues.country.key &&
      invoiceValues &&
      invoiceValues.address &&
      invoiceValues.zipCode &&
      invoiceValues.city &&
      invoiceValues.country &&
      invoiceValues.country.key
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [deliveryValues, invoiceValues]);

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h3"> {t("labels.create_customer.addresses_form.title")}</Typography>
      <div className={classes.fieldGroupFirst}>
        <div className={classes.titleWrapper}>
          <DeliveryAddressIcon />
          <Typography variant="h4" className={classes.fieldTitle}>
            {t("labels.create_customer.addresses_form.delivery_address")}
          </Typography>
        </div>
        <TextField
          required
          fullWidth
          name="address"
          label={t("labels.create_customer.addresses_form.address")}
          value={deliveryValues.address}
          onChange={handleDeliveryChange}
          variant="outlined"
          helperText={t("labels.create_customer.addresses_form.address_helper")}
          FormHelperTextProps={{
            classes: { root: classes.addressCaption },
          }}
        />
        <SelectInput
          required
          onSelect={handleDeliveryDropdownChange}
          label={t("labels.create_customer.addresses_form.country")}
          name="country"
          value={deliveryValues.country?.key!}
          url="lookups/countries/delivery"
          metaApi
        />
        <div className={classes.fieldWrapper}>
          <CustomerAutocomplete
            required
            options={deliveryZipCodeValues}
            name="zipCode"
            label={t("labels.create_customer.addresses_form.zip")}
            inputValue={deliveryValues.zipCode?.displayText}
            open={deliveryZipCodesOpen}
            setOpen={setDeliveryZipCodesOpen}
            loading={deliveryZipCodesLoading}
            setLoading={setDeliveryZipCodesLoading}
            onChange={handleAutocompleteChange}
            onSelect={handleAutocompleteSelect}
          />
          <TextField
            required
            fullWidth
            disabled
            name="cityId"
            label={t("labels.create_customer.addresses_form.city")}
            value={deliveryValues.city?.displayText}
            onChange={handleDeliveryChange}
            variant="outlined"
            className={classes.cityField}
          />
        </div>
        <TextField
          fullWidth
          disabled
          name="province"
          label={t("labels.create_customer.addresses_form.state")}
          value={deliveryValues.province?.displayText}
          onChange={handleDeliveryChange}
          variant="outlined"
        />
        <FormControlLabel
          disabled={!customerData}
          control={
            <Checkbox onClick={handleDeliveryAsMain} checked={deliveryAsMain} color="primary" />
          }
          label={t("labels.create_customer.addresses_form.delivery_as_main")}
        />
        <FormControlLabel
          disabled={!customerData}
          control={
            <Checkbox
              onChange={handleMakeAsDefault}
              checked={isDefaultDeliveryAddress}
              color="primary"
              value="deliveryAddress"
            />
          }
          label={"Make as default Delivery address"}
        />
      </div>
      <div className={classes.fieldGroup}>
        <div className={classes.titleWrapper}>
          <InvoiceAddressIcon />
          <Typography variant="h4" className={classes.fieldTitle}>
            {t("labels.create_customer.addresses_form.invoice_address")}
          </Typography>
        </div>
        <TextField
          required
          fullWidth
          name="address"
          label={t("labels.create_customer.addresses_form.address")}
          value={invoiceValues.address}
          onChange={handleInvoiceChange}
          variant="outlined"
          helperText={t("labels.create_customer.addresses_form.address_helper")}
          FormHelperTextProps={{
            classes: { root: classes.addressCaption },
          }}
        />

        <div className={classes.fieldWrapper}>
          <TextField
            required
            fullWidth
            name="zipCode"
            label={t("labels.create_customer.addresses_form.zip")}
            value={invoiceValues.zipCode}
            onChange={handleInvoiceChange}
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            name="city"
            label={t("labels.create_customer.addresses_form.city")}
            value={invoiceValues.city}
            onChange={handleInvoiceChange}
            variant="outlined"
            className={classes.cityField}
          />
        </div>
        <TextField
          fullWidth
          name="state"
          label={t("labels.create_customer.addresses_form.state")}
          value={invoiceValues.state}
          onChange={handleInvoiceChange}
          variant="outlined"
        />
        <SelectInput
          required
          onSelect={handleInvoiceDropdownChange}
          label={t("labels.create_customer.addresses_form.country")}
          name="country"
          value={invoiceValues.country?.key!}
          url="lookups/countries"
          metaApi
        />
        <FormControlLabel
          disabled={!customerData}
          control={
            <Checkbox onClick={handleInvoiceAsMain} checked={invoiceAsMain} color="primary" />
          }
          label={t("labels.create_customer.addresses_form.invoice_as_main")}
        />
        <FormControlLabel
          disabled={!customerData}
          control={
            <Checkbox
              onChange={handleMakeAsDefault}
              checked={isDefaultInvoiceAddress}
              color="primary"
              value="invoiceAddress"
            />
          }
          label={"Make as default Invoice address"}
        />
      </div>

      <div className={classes.btnGroup}>
        <Button
          disabled={submitDisabled}
          className={classes.submitBtn}
          type="submit"
          ref={submitButtonRef}
        >
          {t("labels.create_customer.addresses_form.save")}
        </Button>
        <Button onClick={handleModalClose} className={classes.cancelBtn}>
          {t("labels.create_customer.addresses_form.cancel")}
        </Button>
      </div>
    </form>
  );
};

export default CreateAddressesForm;
