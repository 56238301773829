import React, { FC } from "react";
import SellerUserFieldList from "./SellerUserFieldList";
import { Seller } from "../../../providers/SellersStore/types";
import Grid from "@material-ui/core/Grid";
import uniqueId from "lodash/fp/uniqueId";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { ListItem } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      "& .MuiListItem-root": {
        padding: 0,
      },
    },
  })
);
interface Props {
  data: Seller[];
}

const SellerUserList: FC<Props> = ({ data }) => {
  const classes = useStyles();
  return (
    <List component="nav" className={classes.root}>
      {data.map((seller) => (
        <div key={uniqueId("SellerUsers")}>
          <ListItem>
            <Grid container direction="column" alignItems="center" justify={"space-between"}>
              <SellerUserFieldList seller={seller} />
            </Grid>
          </ListItem>
          <Divider variant={"fullWidth"} />
        </div>
      ))}
    </List>
  );
};

export default SellerUserList;
