import React, { FC } from "react";
import { Warehouse } from "src/types";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
import { TFunction } from "i18next";
import WarehouseIcon from "src/pages/Warehouses/components/icons/WarehouseIcon";
import Link from "@material-ui/core/Link/Link";
import { useStyles } from "src/pages/Warehouses/components/styles";
import { navsTabsLink } from "../WarehouseDetails/data";

export interface FieldListType {
  label: string;
  value: string | number | Date | boolean | undefined;
}

const dataList = (t: TFunction, elem: Warehouse) => [
  {
    label: t("labels.row.email"),
    value: elem.email,
  },
  {
    label: t("labels.row.phone_number"),
    value: elem.mobilePhone,
  },
  {
    label: t("labels.row.so_count"),
    value: elem.serviceOrderCount,
  },
];

const WarehouseFieldList: FC<{ data: Warehouse }> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation("warehouses");

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={4} container id="warehouse-link-wrp" alignItems="center" justify="flex-start">
        <WarehouseIcon />
        <Link
          id="warehouse-link"
          className={classes.link}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
          onFocus={(event: React.FocusEvent<HTMLAnchorElement>) => event.stopPropagation()}
          href={`warehouses/details/${data.key}/${navsTabsLink[0]}`}
        >
          {data.name}
        </Link>
      </Grid>
      {dataList(t, data).map((ent: FieldListType) => (
        <Grid item xs key={uniqueId("warehouses")}>
          <Typography variant="caption">{ent.label}</Typography>
          <Typography variant="body2">{ent.value}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
export default WarehouseFieldList;
