import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ChevronNavBtnIconClosed = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 2H11" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 8H11" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 14H11" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14 11L16.8571 8.14286L14 5.28571"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronNavBtnIconClosed;
