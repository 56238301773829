import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DeliveryIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M6.09078 17C6.8941 17 7.54532 16.3488 7.54532 15.5454C7.54532 14.7421 6.8941 14.0909 6.09078 14.0909C5.28745 14.0909 4.63623 14.7421 4.63623 15.5454C4.63623 16.3488 5.28745 17 6.09078 17Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.8183 17C15.6216 17 16.2729 16.3488 16.2729 15.5454C16.2729 14.7421 15.6216 14.0909 14.8183 14.0909C14.015 14.0909 13.3638 14.7421 13.3638 15.5454C13.3638 16.3488 14.015 17 14.8183 17Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.39636 4.63636H17L15.7782 10.7382C15.6409 11.4293 15.0281 11.9226 14.3236 11.9091H6.63636C5.90144 11.9153 5.27734 11.3724 5.18182 10.6436L4.07636 2.26545C3.98154 1.54225 3.36576 1.0011 2.63636 1H1"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  </SvgIcon>
);

export default DeliveryIcon;
