import { requestGetFile } from "./crud";
import { File, FILE_PREVIEW_TYPES } from "../types";
import { FileRelatedEntityType, MonteraFile } from "src/components/Carousel/types";
import { FilesReq } from "src/providers/SODetailsStore/types";
import { flatMap } from "lodash";
import { ServicesFilesCollection } from "src/providers/SODetailsStore/types";

let fileUrlCache: Record<number, string> = {};

export const getFileUrl = async (fileServiceId: number) => {
  if (fileUrlCache[fileServiceId]) {
    return fileUrlCache[fileServiceId];
  }

  const data = await requestGetFile(fileServiceId);
  const url = window.URL.createObjectURL(new Blob([data.data]));
  fileUrlCache[fileServiceId] = url;

  return url;
};

export const getFileExtension = (file: File | MonteraFile) =>
  file.fileName.slice((Math.max(0, file.fileName.lastIndexOf(".")) || Infinity) + 1);

const imageExtensions = ["jpeg", "jpg", "png", "gif", "bmp"];
export const isImageFile = (file: File | MonteraFile) =>
  imageExtensions.includes(getFileExtension(file).toLowerCase());

export const isImageExtension = (ext: string) => imageExtensions.includes(ext.toLowerCase());

export const isPdfExtension = (ext: string) => ext.toLowerCase() === "pdf";

export const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const element = document.createElement("a");

  try {
    element.setAttribute("href", url);
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
  } finally {
    element.remove();
  }
};

export const mapPreviewTypeFromExtension = (ext: string): FILE_PREVIEW_TYPES | null => {
  if (isImageExtension(ext)) {
    return FILE_PREVIEW_TYPES.IMAGE;
  }

  if (isPdfExtension(ext)) {
    return FILE_PREVIEW_TYPES.PDF;
  }

  return null;
};

export const getCarouseFiles = (fileState: FilesReq) => {
  const fileList: any[] = [];
  const servicesWithFiles = fileState.files.serviceFiles.filter((x) => x.serviceFiles.length);

  fileList.push(
    ...fileState.files.serviceOrderFiles.map((el) => getSORelatedEntity(el, fileState))
  );

  const serviceFiles = flatMap(servicesWithFiles, (x) =>
    x.serviceFiles.map((file) => ({
      ...file,
      relatedEntity: getServiceRelatedEntity(x),
      original: `${file.id}`,
      thumbnail: `${file.id}`,
    }))
  );
  fileList.push(...serviceFiles);

  return fileList;
};

export const getSORelatedEntity = (serviceOrderFile: File, fileState: FilesReq) => {
  return {
    ...serviceOrderFile,
    relatedEntity: {
      key: fileState.files.serviceOrderId,
      displayText: fileState.files.serviceOrderName,
      type: FileRelatedEntityType.SO,
    },
    original: `${serviceOrderFile.id}`,
    thumbnail: `${serviceOrderFile.id}`,
  };
};

export const getServiceRelatedEntity = (serviceEntity: ServicesFilesCollection) => {
  return {
    key: serviceEntity.serviceId,
    displayText: serviceEntity.serviceName,
    type: FileRelatedEntityType.S,
  };
};
