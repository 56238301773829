import React, { useEffect, useState } from "react";
import {
  ContextDetails,
  CommentsState,
  Props,
  Loading,
  Threads,
  SendMsg,
  ChannelItem,
  ChatThead,
  FileType,
  EmailType,
  ShareComment,
} from "./types";
import { File } from "src/types";
import createCtx from "../../utils/createCtx";
import { useParams } from "react-router-dom";
import { ServiceDetailsType } from "src/pages/ServiceDetails/type";
import { requestGet, requestMetaGet, requestPost, requestPut } from "src/utils/crud";
import { CommentsParamsType, SODetailsType } from "src/pages/ServiceOrdersDetails/type";
import { channelsList } from "src/providers/CommentsStore/static";
import isEmpty from "lodash/isEmpty";
import { AxiosPromise } from "axios";
import { localStore } from "src/utils/functions";
import useQueryString from "src/utils/hooks/useQueryString";

const [useCtx, Provider] = createCtx<ContextDetails>();

const preDefaultChannel = localStore.getObject("comments.selectedChannel");

const initialComments: CommentsState = {
  threads: [],
  newThread: {},
  totalCount: 0,
  openEditThread: "",
  meta: {
    key: "",
    orderType: "",
  },
  selectedEntType: "",
  chatchannels: channelsList,
  selectedChannel: !isEmpty(preDefaultChannel) ? preDefaultChannel : 0,
  selectedThread: 0,
  loading: {
    thread: false,
    channels: false,
    services: false,
    serviceOrders: false,
    msgs: false,
    files: false,
  },
  error: false,
  errorMsg: "",
  chatThread: {
    key: "",
    messages: [],
    title: "",
    parentEntityKey: "",
    parentEntityType: "",
  },
  topicDataIsValid: false,
  chatTab: 0,
  readedMessages: [],
  uploadedFiles: [],
  fileList: [],
  isAddNewThread: false,
  threadsSelected: undefined,
  scrollToBottom: true,
  channelsUnreadComments: [], // amount of unreaded comments per channel,
  totalThreads: 0,

  sharingByEmailOpen: false,
  shareByEmail: false,
  shareCommentByEmail: null,
  shareEmails: [],
  shareWarehouseKey: null,
  sharingByEmail: false,

  displayDragAndDrop: false,
};

function CommentsProvider(props: Props): React.ReactElement {
  const [commentsStore, setComments] = useState(initialComments);
  const { serviceId } = useParams<ServiceDetailsType>();
  const { serviceOrderId } = useParams<SODetailsType>();
  const [{ channelKey }] = useQueryString<CommentsParamsType>();
  useEffect(() => {
    (async () => {
      await getChatChannels().then((res) => {
        if (props.context === "service" && !!serviceId) {
          (async () => {
            await setContextData("meta", {
              key: serviceId,
              orderType: "Service",
            });
            await reqChanelThreads(
              channelKey || res?.data[commentsStore.selectedChannel].key,
              serviceId
            );
            await getAmountOfUnreadedCommentsChannels(serviceOrderId);
            await getThreadList(
              serviceId,
              channelKey || commentsStore.chatchannels[Number(commentsStore.selectedChannel)].key
            );
          })();
        }
        if (props.context === "serviceOrder" && !!serviceOrderId) {
          (async () => {
            await setContextData("meta", {
              key: serviceOrderId,
              orderType: "ServiceOrder",
            });

            await reqChanelThreads(
              channelKey || res?.data[commentsStore.selectedChannel].key,
              serviceOrderId
            );
            await getAmountOfUnreadedCommentsChannels(serviceOrderId);
            await getThreadList(
              serviceOrderId,
              channelKey || commentsStore.chatchannels[Number(commentsStore.selectedChannel)].key
            );
          })();
        }
      });
    })();
  }, [serviceId, serviceOrderId]);

  const getMessageEmails = async (messageKey: number) => {
    const response = await requestGet<any>(`chat/message/${messageKey}/email/list`);
    const item = response.data;

    const warehouse = {
      key: item.warehouseKey,
      displayText: item.warehouseName,
      email: item.warehouseEmail,
      type: EmailType.Warehouse,
    };

    setComments((prev) => ({
      ...prev,
      shareEmails: [warehouse],
      shareWarehouseKey: warehouse.key,
    }));
  };

  useEffect(() => {
    const messageKey = commentsStore.shareCommentByEmail?.key;
    if (!messageKey) {
      return;
    }

    getMessageEmails(messageKey);
  }, [commentsStore.shareCommentByEmail]);

  const getThreadList = async (key: number | string, currentChannelKey: string | number = "") => {
    if (!!currentChannelKey) {
      updateLoading("thread", true);
      try {
        await requestGet<{ threads: Threads[] }>(
          `chat/${props.context}/${key}/thread/list?currentChannelKey=${currentChannelKey}`
        )
          .then(async (response) => {
            if (!!response && !!response.data.threads) {
              if (response.data.threads.length <= 0) {
              } else {
                setComments((prev) => ({
                  ...prev,
                  ...response.data,
                  threadsSelected: !isEmpty(prev.threadsSelected)
                    ? response.data.threads[0]
                    : prev.threadsSelected,
                  loading: {
                    ...prev.loading,
                    thread: false,
                  },
                }));
              }
              return response.data.threads;
            }
            return;
          })
          .catch((err) => console.log(err));
      } finally {
        updateLoading("thread", false);
      }
    }
  };

  const getChatChannels = async () => {
    try {
      updateLoading("channels", true);
      return await requestMetaGet<ChannelItem[]>("lookups/PartnerPortal/chatchannels").then(
        (response) => {
          const chatchannels = response.data.map((elemt) => ({
            ...elemt,
            ...channelsList.find((item) => elemt.matchingText === item.matchingText),
          }));
          setComments((prev) => ({
            ...prev,
            chatchannels,
            loading: {
              ...prev.loading,
              channels: false,
            },
          }));
          return response;
        }
      );
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const reqChanelThreads = async (
    chatchannelsId: number | string = 0,
    serviceId: number | string = 0
  ) => {
    if (!serviceId) return;
    updateLoading("thread", true);
    try {
      await requestGet<Threads[]>(
        `chat/${props.context}/${serviceId}/thread/list?currentChannelKey=${chatchannelsId}`,
        { pageSize: 1000 }
      ).then((response) => {
        setComments((prev) => ({
          ...prev,
          ...response.data,
        }));
      });
    } finally {
      updateLoading("thread", false);
    }
  };
  const getChanelThreads = async (id: number | string = 0, targetId: boolean = false) => {
    const key = targetId ? id : commentsStore.chatchannels[Number(id)].key;
    if (!!key) {
      await reqChanelThreads(key, commentsStore.meta.key);
    }
  };

  const getThreadMsgs = async (
    id: string | number = "",
    currentChannelKey: string,
    withLoading: boolean = true
  ) => {
    try {
      withLoading && updateLoading("msgs", true);
      return await requestGet<{ chatThread: any }>(
        `chat/thread/${id}?currentChannelKey=${currentChannelKey}`,
        {
          pageSize: 1000,
        }
      ).then((response) => {
        if (!!response) {
          setComments((prev) => ({
            ...prev,
            chatThread: {
              key: prev.threads[prev.selectedThread]?.key,
              title: prev.threads[prev.selectedThread]?.title,
              parentEntityKey: prev.threads[prev.selectedThread]?.parentEntityId,
              parentEntityType: prev.threads[prev.selectedThread]?.parentEntityType,
              messages: response.data.chatThread.messages,
            },
            loading: {
              ...prev.loading,
              thread: false,
              msgs: false,
            },
          }));
          return response.data;
        } else {
          updateLoading("msgs", false);
          console.log("Error getting user Data.");
          return;
        }
      });
    } catch (e) {
      withLoading && updateLoading("msgs", false);
      console.log(e);
      return null;
    }
  };
  const sendMsg = async (data: SendMsg) => {
    updateLoading("msgs", true);

    const { shareByEmail } = commentsStore;

    const message = await requestPost<any>("chat/message", data)
      .then((response) => {
        if (!!response) {
          // UPLOAD ATTACHED FILES
          if (commentsStore.uploadedFiles.length !== 0 && response.data.key) {
            let promiseArr: AxiosPromise[] = [];
            (commentsStore.uploadedFiles as (FileType & File)[] | []).forEach((file) => {
              promiseArr.push(
                requestPut(`Files/${file.id}`, {
                  ...file,
                  categoryId: file.category.key,
                  categoryName: file.category.displayText,
                  chatMessageId: response.data.key,
                })
              );
            });
            Promise.all(promiseArr).then(() => {
              const respData = response.data as any;
              respData.files = commentsStore.uploadedFiles;
              setComments((prev) => ({
                ...prev,
                chatThread: {
                  ...prev.chatThread,
                  messages: [...commentsStore.chatThread.messages, respData],
                },
                loading: {
                  ...prev.loading,
                  msgs: false,
                },
                uploadedFiles: [],
              }));
            });
          }
          setComments((prev) => ({
            ...prev,
            chatThread: {
              ...prev.chatThread,
              messages: [...prev.chatThread.messages, response.data],
            } as ChatThead,
          }));
          updateLoading("msgs", false);
        } else {
          updateLoading("msgs", false);
          console.log("Error getting user Data.");
        }

        return response.data;
      })
      .catch((err) => {
        setComments((prev) => ({
          ...prev,
          error: true,
          errorMsg: err,
          loading: {
            ...prev.loading,
            msgs: false,
          },
        }));
        console.log(err);
      });

    if (!shareByEmail) {
      return;
    }

    setContextData("shareCommentByEmail", { ...message });
    callAPI.setContextData("sharingByEmailOpen", true);
  };
  const shareMsg = async (data: any) => {
    updateLoading("msgs", true);
    return await requestPut(`/chat/message/${data.messageKey}/share/${data.channelKey}`)
      .then(async () => {
        updateLoading("msgs", false);
      })
      .catch((err) => {
        console.log(err);
        setComments((prev) => ({
          ...prev,
          error: true,
          errorMsg: err,
          loading: {
            ...prev.loading,
            msgs: false,
          },
        }));
      });
  };

  const postReadedComments = (unreadMessages: string | string[]) => {
    requestPost("chat/read", { messageIds: unreadMessages || commentsStore.readedMessages }).then(
      () => {
        refreshCurrentThreadMsg();
        updateState({
          readedMessages: [],
          scrollToBottom: false,
        });
        getChanelThreads(commentsStore.selectedChannel || 0); // Refresh the amount of unreaded messages for each thread
        getAmountOfUnreadedCommentsChannels(); // update the count of new comments for channels
      }
    );
  };
  const refreshCurrentThreadMsg = async () => {
    try {
      updateLoading("msgs", true);
      return await requestGet<{ threads: any }>(
        `chat/thread/${
          !!commentsStore?.threadsSelected?.key
            ? commentsStore?.threadsSelected.key
            : !!commentsStore?.threads
            ? commentsStore?.threads[0].key
            : ""
        }?currentChannelKey=${commentsStore.chatchannels[commentsStore.selectedChannel || 0].key}`,
        { pageSize: 1000 }
      ).then((response) => {
        if (!!response) {
          setComments((prev) => ({
            ...prev,
            ...response,
          }));
          updateLoading("msgs", false);
          return response.data.threads;
        } else {
          updateLoading("msgs", false);
          console.log("Error getting user Data.");
          return;
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const getAmountOfUnreadedCommentsChannels = (id?: string) => {
    requestGet<{ unreadMessages: any }>(
      `chat/channel/unread/${!!id ? id : commentsStore.meta.key}`
    ).then(({ data: { unreadMessages } }) => {
      if (!unreadMessages || isNaN(unreadMessages.length)) {
        return;
      }
      setContextData("channelsUnreadedComments", [...unreadMessages]);
    });
  };

  const addThread = async () => {
    const { shareByEmail } = commentsStore;
    updateLoading("thread", true);
    let message: any;
    try {
      message = await requestPost<any>("chat/message", commentsStore.newThread)
        .then((response) => {
          if (!!response) {
            setComments((prev) => ({
              ...prev,
              newThread: {},
              isAddNewThread: false,
              topicDataIsValid: false,
              loading: {
                ...prev.loading,
                thread: false,
              },
            }));
          } else {
            updateLoading("thread", false);
            console.log("Error getting user Data.");
          }
          return response;
        })
        .then(async (response) => {
          const { chatchannels, selectedChannel, meta } = commentsStore;
          try {
            await getThreadList(meta.key, `${chatchannels[selectedChannel].key}`).then(
              async (res: any) => {
                if (res.data.threads.length > 0) {
                  setComments((prev) => ({
                    ...prev,
                    threadsSelected: res.data.threads.find(
                      (ent: any) => ent.key === Number(response?.data.threadKey)
                    ),
                  }));
                }

                await getThreadMsgs(
                  response?.data.threadKey,
                  `${chatchannels[selectedChannel].key}`
                );
              }
            );
          } finally {
            return response.data;
          }
        })
        .catch((err) => {
          console.log(err);
          setComments((prev) => ({
            ...prev,
            error: true,
            errorMsg: err,
            loading: {
              ...prev.loading,
              thread: false,
            },
          }));
        });
    } finally {
      updateLoading("thread", false);
    }

    if (!shareByEmail) {
      return;
    }
    setContextData("shareCommentByEmail", { ...message });
    callAPI.setContextData("sharingByEmailOpen", true);
  };
  const getCommentsFiles = (
    forThreadOnly = false,
    sorting = { sortBy: "createdBy", des: true }
  ) => {
    !commentsStore.fileList.length && updateLoading("files", true);
    requestGet<{ files: FileType[] }>(`chat/${props.context}/${commentsStore.meta.key}/files`, {
      sortBy: sorting.sortBy,
      des: sorting.des,
      ...(forThreadOnly && { threadKey: commentsStore.chatThread.key }),
    }).then(({ data: { files } }) => {
      setComments((prev) => ({
        ...prev,
        fileList: [
          ...files.map((ent: FileType) => ({
            id: ent.key,
            description: ent.description,
            fileName: `${ent.fileName}`,
            category: {
              key: `${ent.category?.key}`,
              displayText: `${ent.category?.displayText}`,
            },
            fileServiceId: Number(ent.fileServiceId),
            createdDate: new Date(),
            createdBy: ent.createdBy?.displayText,
          })),
        ],
        loading: {
          ...prev.loading,
          files: false,
        },
      }));
    });
  };

  const shareByEmail = async (data: ShareComment) => {
    setComments((prev) => ({
      ...prev,
      sharingByEmail: true,
    }));

    try {
      const messageKey = commentsStore.shareCommentByEmail!.key;
      await requestPost(`chat/message/${messageKey}/email`, data);
      await refreshMessages();
    } finally {
      setComments((prev) => ({
        ...prev,
        sharingByEmail: false,
        sharingByEmailOpen: false,
      }));
    }
  };

  const refreshMessages = async () => {
    const { threadsSelected, chatchannels, selectedChannel } = commentsStore;
    const selectedThreadKey = threadsSelected?.key;
    if (!selectedThreadKey) {
      return;
    }

    const selectedChannelObj = chatchannels[selectedChannel];
    if (!selectedChannelObj) {
      return;
    }

    await getThreadMsgs(selectedThreadKey, `${selectedChannelObj.key}`, false);
  };

  const clearMsgList = () => {
    setComments((prev) => ({
      ...prev,
      chatThread: {
        ...prev.chatThread,
        messages: [],
      } as ChatThead,
    }));
  };
  const setContextData = (key: string, data: any) => {
    setComments((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };
  const setDataCallback = (key: keyof CommentsState, callback: (data: any) => CommentsState) => {
    setComments((prev) => ({
      ...prev,
      [key]: callback(prev[key]),
    }));
  };
  const updateState = (newProp = {}) => {
    setComments((prev) => ({
      ...prev,
      ...newProp,
    }));
  };
  const updateLoading = (key: keyof Loading, state?: boolean) => {
    setComments((prev) => ({
      ...prev,
      loading: {
        ...prev.loading,
        [key]: !!state ? state : !prev.loading[key],
      },
    }));
  };

  const callAPI = {
    getThreadList,
    getChanelThreads,
    getChatChannels,
    getThreadMsgs,
    postReadedComments,
    getCommentsFiles,
    setDataCallback,
    setContextData,
    clearMsgList,
    updateState,
    addThread,
    shareMsg,
    sendMsg,
    shareByEmail,
  };

  return (
    <Provider
      value={{
        comments: commentsStore,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useCommentsContext, CommentsProvider };
