import React from "react";
import { useSellersContext } from "src/providers/SellersStore";
import PaginationWrapper from "src/components/PaginationWrapper";

const Pagination = () => {
  const {
    sellersStore: { totalCount, currentPage, pageSize, pageCount },
    callAPI,
  } = useSellersContext();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    event?.preventDefault();
    callAPI.setContextData("currentPage", page + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    callAPI.setContextData("pageSize", +event.target.value);
    callAPI.setContextData("currentPage", 1);
  };

  const handleSpecificPageChange = (value: number) => {
    callAPI.setContextData("currentPage", value);
  };

  return (
    <PaginationWrapper
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={totalCount}
      rowsPerPage={pageSize}
      page={currentPage - 1}
      currentpage={currentPage}
      pagecount={pageCount}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onSpecificPageChange={handleSpecificPageChange}
    />
  );
};

export default Pagination;
