import { makeStyles } from "@material-ui/core/styles";
import { CustomStyles } from "./index";

export const useStyles = makeStyles((theme) => ({
  detailsRoot: {
    padding: 0,
    marginLeft: 0,
    flexDirection: "column",
  },
  activeServiceOrder: {
    zIndex: 1,
  },
  wrapper: {
    width: "100%",
    marginBottom: 4,
  },
  listItem: {
    paddingLeft: (customStyles: CustomStyles) => customStyles.paddingLeft,
    backgroundColor: (customStyles: CustomStyles) => customStyles.backgroundColor!,
    "& .MuiList-padding": {
      padding: 0,
    },
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 32,
  },
  panelSummary: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0 4px",
    "& :focused": {
      backgroundColor: "#fff",
    },
    "& >div": {
      margin: 0,
    },
    "& >.Mui-expanded": {
      marginTop: 1,
      margin: 0,
    },

    "& .MuiListItem-gutters": {
      padding: 4,
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(1),
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 32px",
    backgroundColor: "#dcdde0",
  },
  footerTitle: {
    fontWeight: 600,
    marginLeft: 32,
  },
  footerTotal: {
    textAlign: "end",
  },
  footerWrapper: {
    width: "100%",
    height: 32,
    margin: "4px 0 0 0",
    padding: "0 16px 0 0",
    justifyContent: "center",
    backgroundColor: (customStyles: CustomStyles) => customStyles.backgroundColor,
  },
  boldText: {
    fontWeight: 600,
  },
}));
