import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useWDetailsContext, WarehouseDetailsProvider } from "src/providers/WarehouseDetailsStore";
import { SellersProvider, useSellersContext } from "src/providers/SellersStore";
import { useWarehousesStyles } from "src/pages/Warehouses/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper/Paper";
import EditDetailsIcon from "src/assets/icons/Warehouse/EditDetailsIcon";
import { useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";

import DetailsWrapper from "src/components/DetailsWrapper";
import RelatedOrdersPage from "./tabs/RelatedOrders";
import Sellers from "./tabs/Sellers";

import { WarehousesDetailsType, WarehouseNavLinks } from "./type";
import { navsTabsLink, dataList } from "./data";
import PreloaderLine from "../../../components/Preloader/PreloaderLine";
import { DetailsType } from "./type";
import { useAppContext } from "../../../providers/AppProvider";
import { useCallback } from "react";
import EditFrom from "../modals/EditForm";
import ToggleActiveWarehouseModal from "../modals/ToggleActiveWarehouse";
import DeactivateSellerModal from "src/pages/Sellers/modals/DeactivateSeller";
import ActivateSellerModal from "src/pages/Sellers/modals/ActivateSeller";
import PageActions from "./components/PageActions";
import CreateSeller from "./components/CreateSeller";

interface DetailsSwitcherType<T> {
  [k: string]: T;
}

export const DetailsSwitcher: DetailsSwitcherType<JSX.Element> = {
  sellers: <Sellers />,
  related_orders: <RelatedOrdersPage />,
};

const WarehouseDetails: FunctionComponent = () => {
  const { type } = useParams<WarehousesDetailsType>();
  const tabs: DetailsType = !!type ? type : "sellers";
  const {
    callAppAPI: { toggleModal },
    state: { user },
  } = useAppContext();
  const {
    selectedWarehouse: { warehouse, loading },
    relatedOrders,
  } = useWDetailsContext();
  const {
    sellersStore,
    callAPI: { getWarehousesSellers },
  } = useSellersContext();

  const history = useHistory();
  const { t } = useTranslation("warehouses");
  const classes = useWarehousesStyles();

  const isIntegration = user?.partner?.isIntegration;

  const navItems: WarehouseNavLinks[] = [
    {
      path: "sellers",
      id: 1,
      label: "sellers",
      count: sellersStore.totalCount || 0,
    },
    {
      path: "related_orders",
      id: 2,
      label: "related_orders",
      count: relatedOrders.totalCount || 0,
    },
  ];

  const routeHandler = (event: React.ChangeEvent<HTMLDivElement>) => {
    history.push(event.currentTarget.id);
  };
  const handleModal = useCallback(() => {
    toggleModal({ id: "warehouseEditModal", status: true });
  }, []);

  return (
    <>
      <PreloaderLine loading={loading} />
      <PageActions />
      <Paper className={classes.mainWrapper}>
        <Grid
          className={classes.head}
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={4} container alignItems="center">
            <Typography variant={"h3"}>{warehouse.name}</Typography>
          </Grid>
          {!isIntegration && (
            <Grid>
              <Button onClick={handleModal}>
                <EditDetailsIcon className={classes.svg} />
                {t("labels.btn.edit_details")}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" alignItems="center">
          {dataList(t, warehouse).map((ent) => (
            <Grid item xs key={uniqueId("warehouses")}>
              <Typography variant="caption">{ent.label}</Typography>
              <Typography variant="body2">{ent?.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <EditFrom />
      <ToggleActiveWarehouseModal />
      <CreateSeller />
      <DeactivateSellerModal onDeactivate={() => getWarehousesSellers(warehouse.key!)} />
      <ActivateSellerModal onActivate={() => getWarehousesSellers(warehouse.key!)} />
      <DetailsWrapper<WarehouseNavLinks>
        navItems={navItems}
        handleChange={routeHandler}
        selected={navsTabsLink.indexOf(tabs)}
      >
        {DetailsSwitcher[tabs]}
      </DetailsWrapper>
    </>
  );
};

const WarehouseDetailsPage = () => {
  return (
    <WarehouseDetailsProvider>
      <SellersProvider>
        <WarehouseDetails />
      </SellersProvider>
    </WarehouseDetailsProvider>
  );
};

export default WarehouseDetailsPage;
