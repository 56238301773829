import { Grid, Typography } from "@material-ui/core";
import i18next from "i18next";
import React from "react";
import SuccessIcon from "src/pages/Warehouses/modals/CreateWarehouses/Success/icons/SuccessIcon";
import { CancelButton, SaveButton } from "../Buttons";
import Modal from "../Modal";
import { useStyles } from "./styles";

import { Props } from "./types";

export const SuccessDialog: React.FC<Props> = ({
  open = false,
  acceptLabel = i18next.t("labels.ok", { ns: "common" }),
  closeLabel = i18next.t("labels.cancel", { ns: "common" }),
  title,
  subtitle,
  onAccept,
  onClose,
}: Props) => {
  const classes = useStyles();
  return (
    <Modal isOpen={open} onClose={onClose}>
      <Grid container justify="center">
        <Grid item>
          <div className={classes.titleWrapper}>
            <SuccessIcon className={classes.successIcon} />
            <Typography variant="h4">{title}</Typography>
            <br />
            {subtitle && (
              <Typography variant="body1" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </div>
          <div className={classes.centerBtn}>
            {onAccept && <SaveButton label={acceptLabel} onClick={onAccept} />}
            {onClose && <CancelButton label={closeLabel} onClick={onClose} />}
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};
