import ReactImageGallery, { ReactImageGalleryProps } from "react-image-gallery";
import { getGalleryThumbsNode } from "../services";

type Result = {
  onBeforeSlide?: ReactImageGalleryProps["onBeforeSlide"];
};

export const useToolbar = (galleryRef: React.RefObject<ReactImageGallery>): Result => {
  const onBeforeSlide: ReactImageGalleryProps["onBeforeSlide"] = (index) => {
    const thumbs = getGalleryThumbsNode(galleryRef);
    if (!thumbs) {
      return;
    }

    const button = thumbs.children[index] as HTMLDivElement;
    if (!button) {
      return;
    }

    const areaWidth = thumbs.clientWidth;
    const left = button.offsetLeft - areaWidth / 2 + button.offsetWidth / 2;

    const scrollOptions = {
      top: 0,
      left,
      behavior: "smooth",
    };

    thumbs.scrollTo(scrollOptions as object);
  };

  return {
    onBeforeSlide,
  };
};
