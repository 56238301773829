import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";

import { useStyles } from "src/containers/FileUploadDislog/styles";
import FileInfoForm from "src/containers/FileUploadDislog/components/FileInfoForm";
import ClearAllForm from "src/containers/FileUploadDislog/components/ClearAllForm";
import { useFilesContext } from "src/providers/FilesStore";
import { useAppContext } from "src/providers/AppProvider";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import ModalFormWrapper from "src/components/FormWrapper/ModalFormWrapper";
import { Service, ServiceOrder } from "../../../types";

enum FILES_DIALOG_STATES {
  FILES_FORM = 0,
  CLEAR_ALL = 1,
}
interface Props {
  context: "service" | "serviceOrder";
  service?: Partial<Service>;
  serviceOrder?: Partial<ServiceOrder>;
}

const ServiceFileUploadDialog = ({ context, serviceOrder, service }: Props) => {
  const classes = useStyles();

  const { fetchInitial, resetState } = useFilesContext();

  const {
    callAppAPI: { toggleModal },
    state: {
      modals: { ordersFilesUpload },
    },
  } = useAppContext();

  const {
    callAPI: { getFiles },
  } = useSODetailsContext();

  const entity = context === "serviceOrder" ? serviceOrder : service;

  const [formState, setFormState] = useState(FILES_DIALOG_STATES.FILES_FORM);

  const handleOpen = () => {
    resetState();
    fetchInitial();
  };

  const onClearClick = () => {
    setFormState(FILES_DIALOG_STATES.CLEAR_ALL);
  };

  const onClearClose = () => {
    setFormState(FILES_DIALOG_STATES.FILES_FORM);
  };

  const onClose = () => {
    getFiles(entity?.id!);
    toggleModal({ id: "ordersFilesUpload", status: false });
  };

  const onDialogClose = () => {
    if (formState === FILES_DIALOG_STATES.FILES_FORM) {
      return onClose();
    }
    onClearClose();
  };

  useEffect(() => {
    if (ordersFilesUpload) {
      return handleOpen();
    }
  }, [ordersFilesUpload]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open={ordersFilesUpload}
      maxWidth="xl"
      onClose={onDialogClose}
    >
      {formState === FILES_DIALOG_STATES.FILES_FORM && (
        <FileInfoForm FormWrapper={ModalFormWrapper} onClear={onClearClick} onCancel={onClose} />
      )}
      {formState === FILES_DIALOG_STATES.CLEAR_ALL && (
        <ClearAllForm
          FormWrapper={ModalFormWrapper}
          onCancel={onClearClose}
          onSubmit={onClearClose}
        />
      )}
    </Dialog>
  );
};

export default ServiceFileUploadDialog;
