import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CustomerSection = (props: SvgIconProps) => (
  <SvgIcon width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M14.3333 16V14.3333C14.3333 12.4924 12.8409 11 11 11H4.33333C2.49238 11 1 12.4924 1 14.3333V16"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.66683 7.66667C9.50778 7.66667 11.0002 6.17428 11.0002 4.33333C11.0002 2.49238 9.50778 1 7.66683 1C5.82588 1 4.3335 2.49238 4.3335 4.33333C4.3335 6.17428 5.82588 7.66667 7.66683 7.66667Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default CustomerSection;
