import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { requestGet } from "src/utils/crud";
import { useFormContext } from "react-hook-form";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSOContext } from "src/providers/ServiceOrdersStore";

export interface ListType {
  key: number | string;
  displayText: string;
}

const WarehouseAutoComplete: FC = () => {
  const [open, setOpen] = React.useState(false);
  const data = useFormContext();
  const {
    SOData: { creationSO },
    callSOAPI,
  } = useSOContext();

  const defaultValue: ListType = {
    key: creationSO?.warehouseId || 0,
    displayText: creationSO?.warehouseName || "",
  };
  const { errors, register, setValue, trigger } = data;
  const { t } = useTranslation("orders");
  const [options, setOptions] = React.useState<ListType[]>([]);
  const loading = open && options.length === 0;
  register({ name: "warehouseId", required: true });
  register({ name: "warehouseName", required: true });
  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await requestGet<{ warehouses: ListType[] }>("warehouses/lookups");
      if (active) {
        setOptions(res.data.warehouses);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onReset = () => {
    setValue("warehouseId", null);
    setValue("warehouseName", null);
    setValue("sellerId", null);
    setValue("sellerName", null);
    trigger(["warehouseId", "warehouseName", "sellerId", "sellerName"]);
    callSOAPI.setContextDataBulk({
      warehouseId: null,
      warehouseName: null,
      sellerName: null,
      sellerId: null,
    });
  };
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.displayText === value.displayText}
      getOptionLabel={(option) => option.displayText}
      onChange={(_, value) => {
        if (!!value) {
          setValue("warehouseId", value?.key);
          setValue("warehouseName", value?.displayText);
          if (!!data.getValues("sellerId")) {
            setValue("sellerId", null);
            setValue("sellerName", null);
            trigger(["sellerId", "sellerName"]);
          }
          trigger(["warehouseId", "warehouseName"]);
        } else {
          onReset();
        }
      }}
      defaultValue={defaultValue}
      options={options}
      value={{ key: data.watch("warehouseId"), displayText: data.watch("warehouseName") }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          label={t("labels.creation.field_desc_warehouse")}
          {...params}
          variant="outlined"
          required
          error={!!errors.warehouseId}
          inputRef={register({
            required: "required",
          })}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={10} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default WarehouseAutoComplete;
