import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ActivateIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M5.197 6.6l2.1 2.1 7.7-7.7"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.597 7.3v4.9a1.4 1.4 0 01-1.4 1.4h-9.8a1.4 1.4 0 01-1.4-1.4V2.4a1.4 1.4 0 011.4-1.4h7.7"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default ActivateIcon;
