import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography } from "@material-ui/core/";
import clsx from "clsx";

function a11yProps(index: string | number) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabLabel: {
    fontSize: 14,
  },
  tab: {
    "&:hover": {
      backgroundColor: "rgba(43, 89, 195, 0.1)",
    },
  },
  tabs: {
    color: theme.palette.functionalColors.dark,
  },
}));
interface Items {
  id: number;
  counter: boolean;
  count: string | number;
  label: string | number;
  content: JSX.Element | null;
}

interface Props {
  navItems: Items[];
  handleTabChange: (newValue: string) => void;
  currentTab: number | string;
  rootOverrideClassName?: string;
}

export default function MonteraSubNavigation({
  navItems = [],
  handleTabChange = () => {},
  currentTab = 0,
  rootOverrideClassName,
}: Props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, rootOverrideClassName)}>
      <Tabs
        value={currentTab}
        onChange={(_, value) => {
          handleTabChange(value);
        }}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        className={classes.tabs}
      >
        {navItems.map((row) =>
          row.counter ? (
            <Tab
              className={classes.tab}
              label={
                <Typography variant="button">
                  {row.label} ({row.count})
                </Typography>
              }
              {...a11yProps(row.id! - 1)}
              key={row.id}
            />
          ) : (
            <Tab
              className={classes.tab}
              label={<Typography variant="button">{row.label}</Typography>}
              {...a11yProps(row.id! - 1)}
              key={row.id}
            />
          )
        )}
      </Tabs>
    </div>
  );
}
