import React, { useState, useRef, useCallback, useEffect } from "react";
import { useStyles } from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/styles";
import { AxiosResponse } from "axios";
import { Typography, Button, Grid, CircularProgress } from "@material-ui/core";
import { requestPut } from "src/utils/crud";
import SelectInput from "src/components/SelectInput";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/SuccessNotification";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import DeliveryAddressIcon from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/Icons/DeliveryAddressIcon";

const EditCustomerInfo: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { editCustomerInfoSO },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const {
    serviceOrderDetails: { customerInfo, serviceOrder },
    callAPI: { setContextData },
  } = useSODetailsContext();
  const [customerData, setCustomerData] = useState<number | null>(null);

  useEffect(() => {
    customerInfo?.customer?.key && setCustomerData(customerInfo.customer.key)
  }, [customerInfo]);

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "editCustomerInfoSO", status: false });
    setSubmitted(false);
  }, []);

  const handleDropdownChange = (value: number) => {
    setCustomerData(value);
  };

  const submitCustomer = async (data: any) => {
    try {
      const res = await requestPut(`ServiceOrders/${serviceOrder.id}/customerInfo`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const submitObj = { customerId: customerData };
    const customerRes = await submitCustomer(submitObj);

    if ((customerRes as AxiosResponse).status === 200) {
      setSubmitted(true);
      const newCustomerInfo = {
        ...customerInfo,
        customer: (customerRes as AxiosResponse).data.customerInfo.customer,
      };
      setContextData("customerInfo", newCustomerInfo);
    }
    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  return (
    <>
      <Modal isOpen={editCustomerInfoSO} onClose={handleModalClose}>
        <Grid container justify="center" style={{ width: "100%" }}>
          <Grid item style={{ width: "100%" }}>
            {!submitted && customerData && (
              <form onSubmit={handleSubmit}>
                <Typography variant="h3">
                  {t("labels.create_customer.customer_form.change_customer")}
                </Typography>
                <div className={classes.fieldGroup}>
                  <div className={classes.titleWrapper}>
                    <DeliveryAddressIcon />
                    <Typography variant="h4" className={classes.fieldTitle}>
                      {t("labels.create_customer.customer_form.customer")}
                    </Typography>
                  </div>
                  <SelectInput
                    required
                    onSelect={handleDropdownChange}
                    label={t("labels.create_customer.customer_form.select_customer")}
                    name="customer"
                    responseKey="customers"
                    value={customerData || ""}
                    url={`Customers/lookups`}
                    autoselectIfOnlyOneOption={false}
                  />
                </div>
                <div className={classes.btnGroup}>
                  <Button className={classes.submitBtn} type="submit" ref={submitButtonRef}>
                    {loading ? (
                      <CircularProgress size={18} color={"secondary"} />
                    ) : (
                      t("labels.common.save")
                    )}
                  </Button>
                  <Button onClick={handleModalClose} className={classes.cancelBtn}>
                    {t("labels.common.cancel")}
                  </Button>
                </div>
              </form>
            )}
            {submitted && (
              <SuccessNotification
                message={t("labels.edit_customer.success.title")}
                onClose={handleModalClose}
              />
            )}
          </Grid>
        </Grid>
      </Modal>
      {/* <CreateDeliveryAddress /> */}
    </>
  );
};

export default EditCustomerInfo;
