import React, { PropsWithChildren, memo, useCallback, useState } from "react";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import { Collapse, ListItem, makeStyles } from "@material-ui/core";
import uniqueId from "lodash/fp/uniqueId";
import Divider from "@material-ui/core/Divider";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

declare module "react" {
  function memo<A, B>(Component: (props: A) => B): (props: A) => JSX.Element | null;
}

export const useStyles = makeStyles((theme) => ({
  detailsRoot: {
    padding: 0,
    marginLeft: theme.spacing(4),
  },
  activeServiceOrder: {
    zIndex: 1,
  },
  wrapper: {
    padding: "16 0",
  },
  listItem: {
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  panelSummary: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0 4px",
    "& :focused": {
      backgroundColor: "#fff",
    },
    "& >div": {
      margin: 0,
    },
    "& >.Mui-expanded": {
      marginTop: 1,
      margin: 0,
    },

    "& .MuiListItem-gutters": {
      padding: 4,
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(1),
    },
  },
}));

interface Props<T> {
  listEntities: T[];
  entityFields: (ent: T) => JSX.Element;
  entityDetails?: (ent: T) => JSX.Element;
  openByDefault?: boolean;
  handlerOnOpen?: (event: React.ChangeEvent<{}>) => (ent: T) => void;
  handlerOnClose?: (event: React.ChangeEvent<{}>) => (ent: T) => void;
  classes?: Partial<ReturnType<typeof useStyles>>;
}

function CommonList<D extends {}>({
  listEntities,
  entityFields,
  entityDetails,
  openByDefault,
  handlerOnOpen,
  handlerOnClose,
  classes = {},
}: PropsWithChildren<Props<D>>) {
  classes = useStyles({ classes });
  const elemLen = listEntities.length;
  const [expands, updateList] = useState<string[]>(
    openByDefault ? listEntities.map((_, index) => `${index}`) : []
  );
  const handleChange = useCallback(
    (ent: D, index: number) => (event: React.ChangeEvent<{}>) => {
      if (expands.indexOf(`${index}`) < 0) {
        updateList([...expands, `${index}`]);
        if (typeof handlerOnOpen === "undefined") return;
        handlerOnOpen(event)(ent);
      } else {
        updateList([...expands.filter((ent) => ent !== `${index}`)]);
        if (typeof handlerOnClose === "undefined") return;
        handlerOnClose(event)(ent);
      }
    },
    [expands]
  );
  if (elemLen < 0) return null;
  return (
    <>
      {listEntities.map((ent, index) => {
        return (
          <div key={uniqueId("common")} className={classes.wrapper}>
            <Divider variant={"fullWidth"} light />
            <ListItem button onClick={handleChange(ent, index)} className={classes.listItem}>
              {expands.indexOf(`${index}`) >= 0 ? (
                <ExpandLess color={"disabled"} />
              ) : (
                <ExpandMore color={"disabled"} />
              )}
              {entityFields(ent)}
            </ListItem>
            <Collapse in={expands.indexOf(`${index}`) >= 0} timeout="auto" unmountOnExit>
              <Divider variant={"fullWidth"} light />
              <AccordionDetails className={classes.detailsRoot}>
                {!!entityDetails && entityDetails(ent)}
              </AccordionDetails>
            </Collapse>
            {index === elemLen - 1 && <Divider variant={"fullWidth"} light />}
          </div>
        );
      })}
    </>
  );
}

export default memo(CommonList);
