import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { requestMetaGet, requestGet } from "src/utils/crud/";
import { useStyles } from "./styles";
import { LookupObject } from "src/types/";

interface Props {
  required?: boolean;
  disabled?: boolean;
  onSelect: (value: number) => void;
  label: string;
  name: string;
  value: number | string;
  fullWidth?: boolean;
  customTextFieldStyle?: React.CSSProperties;
  url: string;
  metaApi?: boolean;
  responseKey?: string;
  autoselectIfOnlyOneOption?: boolean;
}

const SelectInput: React.FC<Props> = ({
  required = false,
  disabled = false,
  onSelect,
  label,
  name,
  value,
  fullWidth = false,
  customTextFieldStyle,
  url,
  metaApi = false,
  responseKey = "",
  autoselectIfOnlyOneOption = true,
}) => {
  const [lookupData, setLookupData] = useState<LookupObject[]>([]);
  const classes = useStyles();
  const onSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(Number(e.currentTarget.value));
  };

  useEffect(() => {
    if (metaApi) {
      requestMetaGet<LookupObject[]>(url).then((response) => {
        setLookupData(response.data);
      });
    } else {
      requestGet<{ [responseKey: string]: LookupObject[] }>(url).then((response) => {
        setLookupData(response.data[responseKey] || null);
      });
    }
  }, [url]);

  useEffect(() => {
    if (lookupData && lookupData.length >= 1 && !value && autoselectIfOnlyOneOption) {
      onSelect(Number(lookupData[0].key));
    }
  }, [lookupData]);

  return (
    <TextField
      select
      required={required}
      disabled={disabled}
      onChange={onSelectHandler}
      label={label}
      name={name}
      value={value}
      variant="outlined"
      fullWidth={fullWidth}
      style={customTextFieldStyle}
      SelectProps={{
        native: true,
      }}
      className={classes.root}
      InputLabelProps={{ shrink: true }}
    >
      <option aria-label="None" value="" />
      {disabled || (lookupData.length < 1 && <option value=""></option>)}
      {!disabled && lookupData && lookupData.length > 0 && (
        <>
          {lookupData.map((el, idx) => (
            <option key={idx} value={el.key}>
              {el.displayText}
            </option>
          ))}
        </>
      )}
    </TextField>
  );
};

export default SelectInput;
