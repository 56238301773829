import React, { useMemo } from "react";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useStyles } from "src/containers/FileUploadDislog/components/FileInfoForm/styles";
import FileInfoHeader from "src/containers/FileUploadDislog/components/FileInfoHeader";
import FileInfoItem from "src/containers/FileUploadDislog/components/FileInfoItem";
import FileInfoAlerts from "src/containers/FileUploadDislog/components/FileInfoAlerts";

import { useFilesContext } from "src/providers/FilesStore";
import { MonteraDropZoneNoUpload } from "src/components/DropZone";
import { FileDescriptor } from "src/components/DropZone/interfaces";
import { useTranslation } from "react-i18next";
import { FILES_UPLOADING_STATES } from "src/providers/FilesStore/types";
import { FormWrapper } from "src/components/FormWrapper";
import { useCommentsContext } from "src/providers/CommentsStore";
import { FILE_STATUSES } from "src/containers/FileUploadDislog/components/FileInfoStatus/constants";

interface Props {
  onClear: () => void;
  onCancel: (event: React.MouseEvent) => void;
  FormWrapper: FormWrapper;
}

const FileInfoForm = ({ onClear, onCancel, FormWrapper }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const {
    files,
    categories,
    locations,
    onChange,
    onDelete,
    hasAnyFiles,
    addFiles,
    canBeSent,
    uploading,
    submitCommentsFiles,
    reset,
    uploadingState,
  } = useFilesContext();
  const {
    comments: { uploadedFiles },
    callAPI: { setContextData },
  } = useCommentsContext();

  const onDrop = (items: FileDescriptor[]) => {
    const newFiles = items.map((item) => ({
      ...item,
      file: item.file,
    }));
    addFiles(newFiles);
  };

  const columnClassNames = useMemo(
    () => ({
      name: classes.nameColumn,
      location: classes.locationColumn,
      category: classes.categoryColumn,
      description: classes.descriptionColumn,
    }),
    [classes]
  );
  const isPendFilesExists = !!files.filter((f) => f.status === FILE_STATUSES.PENDING).length;
  return (
    <>
      <FormWrapper.Body>
        <MonteraDropZoneNoUpload disabled={false} multiple={true} onDropPreview={onDrop} />
        {hasAnyFiles && (
          <>
            <TableContainer className={classes.container}>
              <Table>
                <FileInfoHeader columnClassNames={columnClassNames} />
                <TableBody>
                  {files.map((x) => (
                    <FileInfoItem
                      key={x.id}
                      columnClassNames={columnClassNames}
                      fileItem={x}
                      categories={categories}
                      locations={locations}
                      onChange={onChange}
                      onDelete={onDelete}
                      uploadingState={uploadingState}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <FileInfoAlerts />
          </>
        )}
      </FormWrapper.Body>
      <FormWrapper.Actions>
        {isPendFilesExists && (
          <Button
            disabled={!canBeSent || uploading}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            startIcon={uploading ? <CircularProgress color="primary" size={20} /> : null}
            onClick={() => submitCommentsFiles(setContextData, uploadedFiles)}
          >
            {uploading
              ? t("files:actions.uploading")
              : t("labels.apply_to_msg", { ns: "comments" })}
          </Button>
        )}
        {uploadingState === FILES_UPLOADING_STATES.PENDING && hasAnyFiles && (
          <Button variant="text" size="large" color="primary" onClick={onClear}>
            {t("labels.clear_all", { ns: "common" })}
          </Button>
        )}

        {!isPendFilesExists && hasAnyFiles && (
          <>
            <Button variant="contained" size="large" color="primary" onClick={onCancel}>
              {t("files:actions.done")}
            </Button>

            <Button variant="text" size="large" color="primary" onClick={reset}>
              {t("files:actions.upload_again")}
            </Button>
          </>
        )}
      </FormWrapper.Actions>
    </>
  );
};

export default FileInfoForm;
