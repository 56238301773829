import React, { useCallback } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import FilterIcon from "src/assets/icons/FilterIcon";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useServicesContext } from "src/providers/ServicesStore";
import { useAppContext } from "src/providers/AppProvider";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { MonteraRefreshButton } from "src/components/Buttons";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    "& div:last-child": {
      marginLeft: "auto",
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

interface Props {
  toggleFilterOpen: () => void;
}

const PageActions = ({ toggleFilterOpen }: Props) => {
  const {
    callServicesAPI,
    servicesData: { expandAll },
  } = useServicesContext();
  const {
    SOData: { des, loading },
    callSOAPI,
  } = useSOContext();
  const {
    state: { user },
  } = useAppContext();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("orders");

  const handleNavigation = useCallback(() => {
    history.push("/serviceOrders/creation");
  }, []);
  const handleToggle = () => {
    callServicesAPI.toggleAll();
  };
  const handleClick = () => {
    callSOAPI.toggleSortDes();
  };
  return (
    <Grid container className={classes.wrapper} direction="row">
      {!expandAll ? (
        <Button onClick={handleToggle} endIcon={<ExpandMoreIcon />}>
          {t("labels.btn.expand_all")}
        </Button>
      ) : (
        <Button onClick={handleToggle} endIcon={<ExpandLessIcon />}>
          {t("labels.collapse_all", { ns: "lineItems" })}
        </Button>
      )}
      <Divider orientation="vertical" flexItem />
      {!des ? (
        <Button endIcon={<ExpandLessIcon />} onClick={handleClick}>
          {t("labels.btn.date_created_asc")}
        </Button>
      ) : (
        <Button endIcon={<ExpandMoreIcon />} onClick={handleClick}>
          {t("labels.btn.date_created_desc")}
        </Button>
      )}
      {!user?.partner?.isIntegration && (
        <>
          <Divider orientation="vertical" flexItem />
          <Button startIcon={<AddIcon />} className={classes.text} onClick={handleNavigation}>
            {t("labels.btn.add")}
          </Button>
        </>
      )}
      <div>
        <MonteraRefreshButton onClick={callSOAPI.refreshList} disabled={loading} />
        <IconButton onClick={toggleFilterOpen}>
          <FilterIcon />
        </IconButton>
      </div>
    </Grid>
  );
};

export default PageActions;
