import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { requestGet } from "src/utils/crud";
import { useTranslation } from "react-i18next";
import { WarehouseSeller } from "src/providers/CommentsStore/types";

interface Props {
  warehouseId: number;
  value: WarehouseSeller[];
  onChange: (value: WarehouseSeller[]) => any;
}

enum LOADING_STATE {
  PENDING,
  LOADING,
  LOADED,
}

const SellersAutoComplete = ({ warehouseId, value, onChange }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [loadingState, setLoadingState] = useState(LOADING_STATE.PENDING);

  const [options, setOptions] = React.useState<WarehouseSeller[]>([]);

  const loading = loadingState === LOADING_STATE.LOADING;

  const handleChange = (_e: unknown, value: WarehouseSeller[]) => {
    onChange(value);
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    setLoadingState(LOADING_STATE.LOADING);

    let active = true;
    (async () => {
      const res = await requestGet<{ sellers: WarehouseSeller[] }>(
        `warehouses/${warehouseId}/sellers/lookups`,
        {
          pageSize: 10000,
        }
      );
      if (active) {
        setLoadingState(LOADING_STATE.LOADED);
        setOptions(res.data.sellers);
      }
    })();

    return () => {
      active = false;
    };
  }, [warehouseId, open]);

  const { t } = useTranslation("orders");

  return (
    <Autocomplete
      open={open}
      loading={loading}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.displayText}
      getOptionSelected={(option, value) => option.key === value.key}
      onChange={handleChange}
      value={value}
      options={options}
      multiple={true}
      renderInput={(params) => (
        <TextField
          label={t("labels.creation.field_desc_seller")}
          {...params}
          variant="outlined"
          name="sellerName"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
    />
  );
};

export default SellersAutoComplete;
