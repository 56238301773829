import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cell: {
    padding: `0px ${theme.spacing(0.5)}px`,
    overflow: "hidden",
    "&[hidden]": {
      display: "none",
    },
  },
  cellContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  fileNameWrap: {
    display: "flex",
    overflow: "hidden",
  },
  fileName: {
    flex: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  fileIcon: {
    marginRight: theme.spacing(1),
    "& svg": {
      height: 20,
      width: 20,
    },
  },
  sizeText: {
    whiteSpace: "nowrap",
  },
  deleteButton: {
    padding: theme.spacing(0.5),
  },
}));
