import React, { useState, useRef, useCallback, useEffect } from "react";
import { useStyles } from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/styles";
import { AxiosResponse } from "axios";
import { Typography, Button, Grid, CircularProgress } from "@material-ui/core";
import { requestPut } from "src/utils/crud";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/SuccessNotification";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { PartnerInfo } from "src/providers/SODetailsStore/types";
import WarehouseIcon from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/Icons/WarehouseIcon";
import SellerIcon from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/Icons/SellerIcon";
import SelectInput from "src/components/SelectInput";

const EditPartnerInfo: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { editPartnerInfoSO },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const {
    serviceOrderDetails: { serviceOrder, partnerInfo },
    callAPI: { setContextData },
  } = useSODetailsContext();

  const [partnerData, setPartnerData] = useState<Partial<PartnerInfo>>({
    seller: { ...partnerInfo?.seller, key: partnerInfo?.seller?.key! },
    warehouse: { ...partnerInfo?.warehouse, key: partnerInfo?.warehouse?.key },
  });

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "editPartnerInfoSO", status: false });
    setSubmitted(false);
  }, []);

  const handleSellerChange = (value: number) => {
    setPartnerData({ ...partnerData, seller: { key: value } });
  };

  const handleWarehouseChange = (value: number) => {
    setPartnerData({ ...partnerData, warehouse: { key: value } });
  };

  const submitPartner = async (data: any) => {
    try {
      const res = await requestPut(`ServiceOrders/${serviceOrder.id}/partnerInfo`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const submitObj = {
      warehouseId: String(partnerData.warehouse?.key),
      sellerId: String(partnerData.seller?.key),
    };

    const partnerRes = await submitPartner(submitObj);
    if ((partnerRes as AxiosResponse).status === 200) {
      setSubmitted(true);
      const newPartnerInfo = {
        ...partnerInfo,
        seller: (partnerRes as AxiosResponse).data.partnerInfo.seller,
        partner: (partnerRes as AxiosResponse).data.partnerInfo.partner,
        warehouse: (partnerRes as AxiosResponse).data.partnerInfo.warehouse,
      };
      setContextData("partnerInfo", newPartnerInfo);
    }
    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    partnerInfo &&
      setPartnerData({
        seller: { ...partnerInfo?.seller, key: partnerInfo?.seller?.key! },
        warehouse: { ...partnerInfo?.warehouse, key: partnerInfo?.warehouse?.key },
      });
  }, [partnerInfo]);

  return (
    <>
      <Modal isOpen={editPartnerInfoSO} onClose={handleModalClose}>
        <Grid container justifyContent="center" style={{ width: "100%" }}>
          <Grid item style={{ width: "100%" }}>
            {!submitted && (
              <form onSubmit={handleSubmit}>
                <Typography variant="h3">Edit Partner Info</Typography>
                <div className={classes.fieldGroup}>
                  <div className={classes.titleWrapper}>
                    <WarehouseIcon />
                    <Typography variant="h4" className={classes.fieldTitle}>
                      Select a Warehouse
                    </Typography>
                  </div>
                  <SelectInput
                    required
                    onSelect={handleWarehouseChange}
                    label="Warehouse"
                    name="warehouse"
                    responseKey="warehouses"
                    value={partnerData.warehouse?.key!}
                    url={`Warehouses/lookups?partner=${partnerInfo?.partner?.key}`}
                  />
                </div>
                <div className={classes.fieldGroup}>
                  <div className={classes.titleWrapper}>
                    <SellerIcon />
                    <Typography variant="h4" className={classes.fieldTitle}>
                      Select a Seller
                    </Typography>
                  </div>
                  <SelectInput
                    disabled={!Boolean(partnerData.warehouse?.key)}
                    required
                    onSelect={handleSellerChange}
                    label="Seller"
                    name="seller"
                    responseKey="sellers"
                    value={partnerData.seller?.key!}
                    url={`Warehouses/${partnerData.warehouse?.key}/sellers/lookups`}
                  />
                </div>
                <div className={classes.btnGroup}>
                  <Button className={classes.submitBtn} type="submit" ref={submitButtonRef}>
                    {loading ? (
                      <CircularProgress size={18} color={"secondary"} />
                    ) : (
                      t("labels.common.save")
                    )}
                  </Button>
                  <Button onClick={handleModalClose} className={classes.cancelBtn}>
                    {t("labels.common.cancel")}
                  </Button>
                </div>
              </form>
            )}
            {submitted && (
              <SuccessNotification
                message={t("labels.common.save_success")}
                onClose={handleModalClose}
              />
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default EditPartnerInfo;
