import React from "react";
import clsx from "clsx";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import { useStyles } from "src/containers/FileUploadDislog/components/FileInfoHeader/styles";

interface Props {
  columnClassNames?: {
    name?: string;
    location?: string;
    category?: string;
    description?: string;
  };
}

const FileInfoHeader = ({ columnClassNames = {} }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation("files");

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        <TableCell
          className={clsx(classes.tableHeaderCell, classes.nameColumn, columnClassNames.name)}
        >
          <Typography variant="caption">{t("table.header.file_name")}</Typography>
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <Typography variant="caption">{t("table.header.file_size")}</Typography>
        </TableCell>
        <TableCell
          className={clsx(
            classes.tableHeaderCell,
            classes.locationColumn,
            columnClassNames.location
          )}
        >
          <Typography variant="caption">{t("table.header.file_location")}</Typography>
        </TableCell>
        <TableCell
          className={clsx(
            classes.tableHeaderCell,
            classes.categoryColumn,
            columnClassNames.category
          )}
        >
          <Typography variant="caption">{t("table.header.file_category")}</Typography>
        </TableCell>
        <TableCell
          className={clsx(
            classes.tableHeaderCell,
            classes.descriptionColumn,
            columnClassNames.description
          )}
        >
          <Typography variant="caption">{t("table.header.file_description")}</Typography>
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <Typography variant="caption">{t("table.header.file_status")}</Typography>
        </TableCell>
        <TableCell className={classes.tableHeaderCell} />
      </TableRow>
    </TableHead>
  );
};

export default FileInfoHeader;
