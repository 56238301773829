import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CustomerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3333 16V14.3333C14.3333 12.4924 12.8409 11 11 11H4.33333C2.49238 11 1 12.4924 1 14.3333V16"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.66732 7.66667C9.50827 7.66667 11.0007 6.17428 11.0007 4.33333C11.0007 2.49238 9.50827 1 7.66732 1C5.82637 1 4.33398 2.49238 4.33398 4.33333C4.33398 6.17428 5.82637 7.66667 7.66732 7.66667Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
export default CustomerIcon;
