import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
import { TFunction } from "i18next";
import OrdersIcon from "src/pages/Orders/components/icons/OrdersIcon";
import { useStyles } from "./styles";
import { navsTabsLink } from "src/pages/ServiceOrdersDetails/data";
import { ServiceOrder } from "src/types";
import format from "date-fns/format";
import StatusColor from "src/components/StatusColors";
import LinkTo from "src/components/LinkTo";
import DeliveryAddressTooltip from "./DeliveryAddressTooltip";
import WarningIcon from "src/pages/Orders/components/icons/WarningIcon";
import ProgressTooltip from "../../components/ProgressTooltip";
import ServiceStatusesTooltip from "../../components/ServiceStatusesTooltip";
import Hint from "src/components/Hint";

export interface FieldListType {
  label: string;
  value: string | number | Date | boolean | undefined | JSX.Element;
}

const ShowWarningIconForStatuses = ["INCOMPLETE"];

const dataList = (t: TFunction, elem: ServiceOrder) => [
  {
    label: t("labels.row.name"),
    value: elem.optionalName || <Typography variant="caption">none</Typography>,
  },
  {
    label: t("labels.row.customer"),
    value: elem.customer?.displayText,
  },
  {
    label: t("labels.row.warehouse"),
    value: elem.warehouse?.displayText,
  },
  {
    label: t("labels.row.services_qty"),
    value: elem.servicesCount,
  },
  {
    label: t("labels.row.seller"),
    value: elem?.seller?.displayText,
  },
  {
    label: t("labels.row.created"),
    value: !!elem.createdDate ? format(new Date(elem.createdDate), "dd.MM.yyyy") : "",
  },
  {
    label: t("labels.row.status"),
    value: !!elem.statusMatchingName ? (
      <StatusColor color={elem.statusMatchingName} />
    ) : (
      <Typography variant="caption">none</Typography>
    ),
  },
  {
    label: t("labels.row.externalId"),
    value: !!elem.externalId ? elem.externalId : <Typography variant="caption">none</Typography>,
  },
  {
    label: t("labels.row.billing"),
    value: !!elem.billingReference ? (
      elem.billingReference
    ) : (
      <Typography variant="caption">none</Typography>
    ),
  },
  {
    label: t("labels.row.ref"),
    value: !!elem.reference ? elem.reference : <Typography variant="caption">none</Typography>,
  },
];

const OrdersFieldList: FC<{ data: ServiceOrder }> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation("orders");

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={2} container className={classes.link} spacing={2}>
        <OrdersIcon />
        <LinkTo
          id="orders-link"
          href={`/serviceOrders/details/${data.id}/${navsTabsLink[0]}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
          onFocus={(event: React.FocusEvent<HTMLAnchorElement>) => event.stopPropagation()}
        >
          <Typography variant="body2">{data.name}</Typography>
        </LinkTo>
      </Grid>
      {dataList(t, data).map((ent: FieldListType) => (
        <Grid item xs key={uniqueId("orders")}>
          <Typography variant="caption">{ent.label}</Typography>
          <Typography variant="body2">{ent.value}</Typography>
        </Grid>
      ))}
      <Grid item className={classes.dynamicWarningMessage}>
        {ShowWarningIconForStatuses.includes(data.statusMatchingName.toUpperCase()) && (
          <Hint placement="bottom" title={<Typography> {t("labels.row.indicator")}</Typography>}>
            <span>
              <WarningIcon />
            </span>
          </Hint>
        )}
      </Grid>
      <Grid item>
        <DeliveryAddressTooltip title={data.deliveryAddress!} />
      </Grid>
      <Grid item>
        <ProgressTooltip
          completedChildCount={data.completedChildCount!}
          childServicesCount={data.servicesCount!}
        />
      </Grid>
      <Grid item className={classes.dynamicIcon}>
        {data.servicesCount! > 0 && <ServiceStatusesTooltip serviceOrderKey={data.id!} />}
      </Grid>
    </Grid>
  );
};
export default OrdersFieldList;
