import { DetailsType, SONavLinks } from "src/pages/ServiceOrdersDetails/type";
import { TFunction } from "i18next";
import { ServiceOrder } from "src/types";
import format from "date-fns/format";
import StatusColor from "src/components/StatusColors";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import { compact } from "lodash";

export const navsTabsLink: DetailsType[] = ["summary"];

export const serviceOrdersDetailsRouts = (data: SONavLinks[]): SONavLinks[] => {
  const res = navsTabsLink.map((ent, index) => ({
    path: ent,
    id: index,
    label: ent,
  }));

  return [...res, ...data];
};

export const dataList = (t: TFunction, elem: Partial<ServiceOrder>) =>
  compact([
    {
      label: t("labels.row.created"),
      value: !!elem.createdDate ? format(new Date(elem.createdDate), "dd.MM.yyyy") : "",
    },
    {
      label: t("labels.row.owner"),
      value: elem?.owner?.displayText ?? "",
    },
    {
      label: t("labels.row.seller"),
      value: elem?.seller?.displayText,
    },
    {
      label: t("labels.row.customer"),
      value: elem?.customer?.displayText,
    },
    elem.assemblyZone && {
      label: t("labels.row.assembly_zone"),
      value: elem.assemblyZone.displayText,
    },
    {
      label: t("labels.row.warehouse"),
      value: elem?.warehouse?.displayText,
    },
    {
      label: t("labels.row.externalId"),
      value: !!elem.externalId ? elem.externalId : <Typography variant="caption">none</Typography>,
    },
    {
      label: t("labels.row.billing"),
      value: !!elem.billingReference ? (
        elem.billingReference
      ) : (
        <Typography variant="caption">none</Typography>
      ),
    },
    {
      label: t("labels.row.ref"),
      value: !!elem.reference ? elem.reference : <Typography variant="caption">none</Typography>,
    },
    {
      label: t("labels.row.so_date_range"),
      value: `${!!elem.startDate ? format(new Date(elem.startDate), "dd.MM.yyyy") : " "} - ${
        !!elem.deliveryDate ? format(new Date(elem.deliveryDate), "dd.MM.yyyy") : " "
      } `,
      minWidth: 170,
    },
    {
      label: t("labels.row.status"),
      value: !!elem.statusMatchingName ? (
        <StatusColor color={elem.statusMatchingName} />
      ) : (
        <Typography variant="caption">none</Typography>
      ),
    },
  ]);
