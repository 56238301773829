import React, { useState, useRef } from "react";
import {
  Typography,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useCommentsContext } from "src/providers/CommentsStore";
import { requestPut } from "src/utils/crud";

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    padding: theme.spacing(2),
    color: "#ffff",
    backgroundColor: theme.palette.functionalColors.dark,
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}));

export const EditThread = () => {
  const classes = useStyles();
  const { t } = useTranslation("comments");
  const {
    callAPI,
    comments: { threads = [], threadsSelected, chatThread },
  } = useCommentsContext();
  const [data, setData] = useState({
    threadTitle: chatThread.title || "",
    entityKey: chatThread.parentEntityKey,
    entityType: chatThread.parentEntityType,
  });
  const threadTextLabel = useRef<any>({ current: {} });
  const submitButtonRef = useRef<any>();

  const onChangeHandler = (prop: string) => ({ target }: React.ChangeEvent<{ value: string }>) => {
    if (prop === "threadTitle" && target.value.length > 30) {
      return;
    } else
      setData((prev) => ({
        ...prev,
        [prop]: target.value,
      }));
  };

  // ON SUBMIT HENDLER
  const onSubmt = () => {
    submitButtonRef.current.setAttribute("disabled", "disabled"); // Disable submission button after first btn click
    requestPut(`Chat/thread/${chatThread.key}`, data)
      .then(() => {
        callAPI.getChatChannels().then(() => {
          // Update a thread title for the selected thread object
          callAPI.setContextData("threadsSelected", {
            ...threadsSelected,
            title: data.threadTitle,
          });
          // Update a thread title for the chatThread object ( display thread info in the messages area)
          callAPI.setContextData("chatThread", { ...chatThread, title: data.threadTitle });
          // Update a selected thread title in the list of all threads
          const threadsBoofer = threads;
          const threadIndex = threads.findIndex((el) => Number(el.key) === Number(chatThread.key));
          threadsBoofer[threadIndex].title! = data.threadTitle;
          callAPI.setContextData("threads", threadsBoofer);
          callAPI.setContextData("openEditThread", false);
        });
      })
      .catch(() => callAPI.setContextData("openEditThread", false));
  };

  const onCancel = () => {
    callAPI.setContextData("openEditThread", false);
  };

  return (
    <div>
      <Typography variant="h3">{t("labels.edit_topic")}</Typography>
      <br />
      <FormControl variant="outlined" fullWidth>
        <InputLabel ref={threadTextLabel} htmlFor="threadTitle">
          {t("labels.edit_topic_name")}
        </InputLabel>
        <OutlinedInput
          required
          id="threadTitle"
          type="text"
          value={data.threadTitle}
          onChange={onChangeHandler("threadTitle")}
          endAdornment={
            <InputAdornment position="end">
              {data.threadTitle ? data.threadTitle.length : "0"}/30
            </InputAdornment>
          }
          labelWidth={threadTextLabel.current.clientWidth || 130}
        />
      </FormControl>
      <br />
      <br />
      <Grid container>
        <Button
          onClick={onSubmt}
          ref={submitButtonRef}
          variant="contained"
          color="primary"
          size="large"
          style={{ padding: "16px 12px" }}
        >
          {t("labels.save_changes", { ns: "common" })}
        </Button>
        <Button onClick={onCancel} className={classes.cancelBtn}>
          {t("labels.cancel", { ns: "common" })}
        </Button>
      </Grid>
    </div>
  );
};
