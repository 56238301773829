import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const EmptySearchIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
        fill="#141E30"
        fillOpacity="0.05"
      />
      <path d="M56.3756 33.1686V48.2483H41.2679V126.83H112.589V33.1686H56.3756Z" fill="white" />
      <path
        d="M56.3756 33.1686L41.2679 48.2483H56.3756V33.1686Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
      <path
        d="M101.489 91.7836H52.2004C51.0668 91.7836 50.1479 92.7025 50.1479 93.8361C50.1479 94.9697 51.0668 95.8886 52.2004 95.8886H101.489C102.622 95.8886 103.541 94.9697 103.541 93.8361C103.541 92.7025 102.622 91.7836 101.489 91.7836Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
      <path
        d="M101.489 100.412H52.2004C51.0668 100.412 50.1479 101.331 50.1479 102.464C50.1479 103.598 51.0668 104.517 52.2004 104.517H101.489C102.622 104.517 103.541 103.598 103.541 102.464C103.541 101.331 102.622 100.412 101.489 100.412Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
      <path
        d="M79.7348 109.069H52.2004C51.0668 109.069 50.1479 109.987 50.1479 111.121C50.1479 112.255 51.0668 113.174 52.2004 113.174H79.7348C80.8684 113.174 81.7873 112.255 81.7873 111.121C81.7873 109.987 80.8684 109.069 79.7348 109.069Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
      <path
        d="M98.7433 87.6687L103.502 82.9099L93.787 73.1948L89.0282 77.9536L98.7433 87.6687Z"
        fill="#141E30"
      />
      <path
        d="M80.1664 85.6683C91.9648 85.6683 101.529 76.1038 101.529 64.3054C101.529 52.5069 91.9648 42.9424 80.1664 42.9424C68.368 42.9424 58.8035 52.5069 58.8035 64.3054C58.8035 76.1038 68.368 85.6683 80.1664 85.6683Z"
        fill="#141E30"
      />
      <path
        d="M80.163 80.8088C89.2779 80.8088 96.667 73.4198 96.667 64.3049C96.667 55.1901 89.2779 47.801 80.163 47.801C71.0482 47.801 63.6591 55.1901 63.6591 64.3049C63.6591 73.4198 71.0482 80.8088 80.163 80.8088Z"
        fill="#F2F6F9"
      />
      <path
        d="M103.258 80.1385L95.9978 87.3991C95.7465 87.6504 95.7465 88.0972 95.9978 88.3486L115.601 107.952C115.853 108.204 116.3 108.204 116.551 107.952L123.812 100.692C124.063 100.44 124.063 99.9934 123.812 99.7421L104.208 80.1385C103.957 79.8872 103.538 79.8872 103.258 80.1385Z"
        fill="#65AFFF"
      />
      <path
        d="M99.0646 91.4358L112.571 104.942L120.785 96.7275L107.279 83.2214L99.0646 91.4358Z"
        fill="#5E7CE2"
      />
    </g>
  </SvgIcon>
);
export default EmptySearchIcon;
