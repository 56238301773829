import React, { useEffect, useState } from "react";

import Divider from "@material-ui/core/Divider";
import { useStyles } from "src/containers/Comments/components/ThreadsAndChat/style";
import Threads from "src/containers/Comments/components/ThreadsAndChat/Threads";
import Chat from "src/containers/Comments/components/ThreadsAndChat/Chat";
import NoThread from "src/containers/Comments/components/ThreadsAndChat/Threads/NoThread";
import { Typography } from "@material-ui/core";
import { EditThread } from "src/containers/Comments/components/ThreadsAndChat/Chat/EditThread";
import Preloader from "src/components/Preloader";
import { useCommentsContext } from "src/providers/CommentsStore";
import MonteraDialog from "src/components/MonteraDialog";

const Comments = () => {
  const { callAPI, comments } = useCommentsContext();
  const [isEmptyList, checkList] = useState(true);
  const {
    totalCount,
    selectedChannel,
    chatchannels,
    isAddNewThread,
    openEditThread,
    threads = [],
    loading,
  } = comments;
  const { main, header, content } = useStyles();

  useEffect(() => {
    if (threads.length > 0) {
      const target = threads.filter((ent) => {
        const tempNeedle = Array.isArray(ent.channelKey) ? ent.channelKey : [ent.channelKey];
        return tempNeedle.indexOf(`${chatchannels[selectedChannel].key}`) >= 0;
      });
      if (target.length > 0) {
        checkList(false);
      } else {
        checkList(true);
      }
    }
  }, [selectedChannel, threads]);
  if (comments.loading.channels) {
    return <Preloader is />;
  }

  if (loading.thread) {
    return <Threads />;
  }

  if (totalCount <= 0 && !isAddNewThread) {
    return <NoThread />;
  }

  const closeEditThread = () => {
    callAPI.setContextData("openEditThread", false);
  };

  return (
    <div className={main}>
      {totalCount <= 0 && (
        <div className={header}>
          <Typography align="left" variant="body1">
            {!!selectedChannel && chatchannels[selectedChannel]?.label}
          </Typography>
        </div>
      )}
      <div className={content}>
        {!isEmptyList || isAddNewThread ? (
          <>
            <Threads />
            <Divider orientation="vertical" variant={"fullWidth"} />
            <Chat />
            {openEditThread && (
              <MonteraDialog open={!!openEditThread} onClose={closeEditThread}>
                <EditThread />
              </MonteraDialog>
            )}
          </>
        ) : (
          <NoThread />
        )}
      </div>
    </div>
  );
};

export default Comments;
