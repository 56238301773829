import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en_bundle, da_bundle, no_bundle } from "./LocalizationBundle";

export const Languages = ["en", "da", "nb"];
export const defaultLocale = "en";
export const defaultTerritoryLanguageKey = 0;
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    supportedLngs: Languages,
    whitelist: Languages,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });
export default i18n;

export const updateLocalization = async (locale: string) => {
  if (!locale || !Languages.includes(locale) ) {
    locale = defaultLocale;
  }
  if (!i18n.hasResourceBundle(locale, "common")) {
    const localizationBundle = getLocalizationBudle(locale);
    for (const [key, value] of Object.entries(localizationBundle)) {
      i18n.addResourceBundle(locale, key, value);
    }
  }
  i18n.changeLanguage(Languages.includes(locale) ? locale : defaultLocale);
};

const getLocalizationBudle = (locale: string) => {
  switch (locale) {
    case "en":
      return en_bundle;
    case "da":
      return da_bundle;
    case "nb":
      return no_bundle;
    default:
      return en_bundle;
  }
};
