
import { DetailsType } from "./type";
import { CustomerNavLinks } from "./type";
import { TFunction } from "i18next";
import { Customer } from "src/types";
import format from "date-fns/format";


export const navsTabsLinkPerson: DetailsType[] = [
  "summary",
  "invoice_addresses",
  "delivery_addresses",
  "related_orders",
  "household_members",
  "recent_activities",
];

export const navsTabsLinkBusiness: DetailsType[] = [
  "summary",
  "invoice_addresses",
  "delivery_addresses",
  "related_orders",
  "household_members",
  "recent_activities",
];

export const customerDetailsPersonRoutes: CustomerNavLinks[] = navsTabsLinkPerson.map(
  (ent, index) => ({
    path: ent,
    id: index,
    count: 0,
    label: ent,
    counter: false,
  })
);

export const customerDetailsBusinessRoutes: CustomerNavLinks[] = navsTabsLinkBusiness.map(
  (ent, index) => ({
    path: ent,
    id: index,
    count: 0,
    label: ent,
    counter: false,
  })
);

type element = Partial<Customer> & { 
  dkFlag: boolean;
}
export const dataList = (t: TFunction, elem: element) => [
  {
    label: t("labels.details.address"),
    value: elem.fullAddress,
  },
  {
    label: t("labels.details.email"),
    value: elem.email,
  },
  {
    label: t("labels.details.phone"),
    value: elem.mobile,
  },
  {
    label: t("labels.details.alt_phone"),
    value: elem.altPhone || "--",
  },
  {
    label: t("labels.details.business"),
    value: elem.isBusiness ? t("labels.details.status_yes") : t("labels.details.status_no"),
  },
  {
    label: t("labels.details.active"),
    value: elem.active ? t("labels.details.status_yes") : t("labels.details.status_no"),
  },
  (!elem.dkFlag ? {
    label: t("labels.details.ssn"),
    value: elem.ssn ? elem.ssn : "--"
  } : null),
  {
    label: t("labels.details.registered_date"),
    value: !!elem.createdDate ? format(new Date(elem.createdDate), "dd.MM.yyyy") : "",
  },
];

type navItemsType = { 
  IATotal:number; 
  DATotal:number; 
  ROTotal:number;
}
export const navItems = ({IATotal, DATotal, ROTotal}:navItemsType):CustomerNavLinks[]  => 
[
  {
    path: "summary",
    id: 1,
    label: "summary",
  },
  {
    path: "invoice_addresses",
    id: 2,
    label: "invoice_addresses",
    count: IATotal || 0,
  },
  {
    path: "delivery_addresses",
    id: 3,
    label: "delivery_addresses",
    count: DATotal || 0,
  },
  {
    path: "related_orders",
    id: 4,
    label: "related_orders",
    count: ROTotal || 0,
  },
  {
    path: "household_members",
    id: 5,
    label: "household_members",
  },
  {
    path: "recent_activities",
    id: 6,
    label: "recent_activities",
  },
];