import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { useTranslation } from "react-i18next";
import useCurrencyFormat from "src/utils/hooks/currency";

const TotalFooter = () => {
  const { t } = useTranslation(`lineItems`);
  const { currencyFormat } = useCurrencyFormat();
  const {
    LIData: {
      lineItemsSO: { total },
    },
  } = useLineItemsContext();

  if (!total || total.total === 0) return null;
  return (
    <Grid container direction="row" style={{ padding: "18px 25px", flexWrap: "nowrap" }}>
      <Grid item xs={8}>
        <Typography variant="h4" component="h3">
          {t("labels.total")}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="h4" component="h3" style={{ paddingRight: 80 }} align="left">
          {total.totalQty}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="h4" component="h3" style={{ paddingRight: 80 }} align="left">
          {currencyFormat(total.total)}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="h4" component="h3" align="left">
          {currencyFormat(total.totalVat)}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default TotalFooter;
