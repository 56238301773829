import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 36,
      height: 36,
    },
  })
);

const PartnerStepsIcon = (props: SvgIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={classes.root}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="16" fill="#2B59C3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="none"
        d="M17.25 8L20.25 11L12 19.25H9V16.25L17.25 8Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 23H22.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};
export default PartnerStepsIcon;
