import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import SuccessIcon from "./icons/SuccessIcon";
import { useStyles } from "./styles";
// import { useAppContext } from "src/providers/AppProvider";
// import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
}

const SuccessCustomerCreate: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  // const {
  //   callAppAPI: { toggleModal },
  // } = useAppContext();
  // const {
  //   callAPI: { getCustomerDetails },
  //   selectedCustomer,
  // } = useCustomerDetailsContext();
  const { t } = useTranslation("customers");

  // const handleModalClose = useCallback(() => {
  //   toggleModal({ id: "editCustomerModal", status: false });
  //   // getCustomerDetails(selectedCustomer.customer.key!);
  // }, []);

  return (
    <Grid container justify="center">
      <Grid item>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">{t("labels.edit_customer.success.title")}</Typography>
        </div>
        <div className={classes.centerBtn}>
          <Button onClick={onClose} className={classes.submitBtn}>
            {t("labels.create_customer.members_form_success.ok")}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessCustomerCreate;
