import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import PartnerManagerIcon from "./icons/PartnerManagerIcon";
import WarehouseSellerIcon from "./icons/WarehouseSellerIcon";
import uniqueId from "lodash/fp/uniqueId";
import { useAnalitycsStyles } from "./styles";
import { MainCardCollection } from "./types";
import { AnalyticsCard } from "./components/AnalitycsMainCard";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const MAIN_CARDS = (t: TFunction): MainCardCollection[] => [
  {
    title: t("labels.main.partner_manager"),
    link: "/analytics/partnerManager/",
    icon: <PartnerManagerIcon />,
  },
  {
    title: t("labels.main.warehouse_seller"),
    link: "/analytics/warehouseSeller/",
    icon: <WarehouseSellerIcon />,
  },
];

const AnalyticsPage: React.FC = () => {
  const classes = useAnalitycsStyles();
  const { t } = useTranslation("analytics");
  return (
    <Container style={{ margin: 0 }} maxWidth="xl">
      <Typography variant="h3">{t("labels.main.title")}</Typography>
      <Grid container direction="row" spacing={2} className={classes.mainWrapper}>
        {MAIN_CARDS(t).map((el) => (
          <Grid key={uniqueId("analytics")} item xs={4}>
            {AnalyticsCard(el)}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AnalyticsPage;
