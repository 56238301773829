import React, { FunctionComponent, useEffect } from "react";
import {
  useServiceDetailsContext,
  ServiceDetailsProvider,
} from "src/providers/ServiceDetailsStore";

import { useParams, Redirect } from "react-router-dom";

import Preloader from "src/components/Preloader";

const SOandService: FunctionComponent = () => {
  const { type, serviceId } = useParams();
  const {
    serviceDetails: { service, loading },
    callAPI,
  } = useServiceDetailsContext();
  useEffect(() => {
    (async () => {
      await callAPI.getServiceDetails(serviceId);
    })();
  }, [serviceId]);
  if (loading && !service?.serviceOrder?.key) {
    return <Preloader is />;
  }
  return (
    <Redirect exact to={`/services/details/${service?.serviceOrder?.key}/${serviceId}/${type}`} />
  );
};

const RedirectServiceDetailsPage = () => {
  return (
    <ServiceDetailsProvider>
      <SOandService />
    </ServiceDetailsProvider>
  );
};

export default RedirectServiceDetailsPage;
