import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import SuccessIcon from "src/pages/Warehouses/modals/CreateWarehouses/Success/icons/SuccessIcon";
import { useStyles } from "src/pages/Warehouses/modals/CreateWarehouses/Success/styles";
import { useAppContext } from "src/providers/AppProvider";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const SuccessCustomerCreate: React.FC = () => {
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();
  const history = useHistory();
  const handleSellerForm = () => {
    history.push("/sellers");
    toggleModal({ id: "warehouseModal", status: false });
    toggleModal({ id: "sellersModal", status: true });
  };

  const handleModal = useCallback(() => {
    toggleModal({ id: "warehouseModal", status: false });
  }, []);
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">Warehouse was successfully created!</Typography>
        </div>

        <div className={classes.btnGroup}>
          <Button onClick={handleSellerForm} className={classes.submitBtn} type="submit">
            Add Seller
          </Button>
          <Button onClick={handleModal} className={classes.cancelBtn}>
            open details page
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessCustomerCreate;
