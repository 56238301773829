import React, { useState, useEffect } from "react";
import { useCustomerContext } from "src/providers/CustomerStore";
import { TextField } from "@material-ui/core";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

const CustomersSortDropdown: React.FC = () => {
  const { t } = useTranslation(`customers`);
  const {
    customerData: { sortBy, des },
    callAPI: { setContextData },
  } = useCustomerContext();
  const classes = useStyles();
  const [value, setValue] = useState<string>("fileName");

  const handleSorting = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes("_desc")) {
      let val = event.target.value.split("_");
      setContextData("sortBy", val[0]);
      setContextData("des", true);
    } else {
      setContextData("sortBy", event.target.value);
      setContextData("des", false);
    }
  };

  const sortValues = [
    { key: "name_desc", displayText: t("labels.filters.name_desc") },
    { key: "name", displayText: t("labels.filters.name") },
    { key: "createdBy.displayText_desc", displayText: t("labels.filters.created_by_desc") },
    { key: "createdBy.displayText", displayText: t("labels.filters.created_by") },
    { key: "fullAddress_desc", displayText: t("labels.filters.address_desc") },
    { key: "fullAddress", displayText: t("labels.filters.address") },
    { key: "email_desc", displayText: t("labels.filters.email_desc") },
    { key: "email", displayText: t("labels.filters.email") },
    { key: "mobile_desc", displayText: t("labels.filters.phone_desc") },
    { key: "mobile", displayText: t("labels.filters.phone") },
    { key: "serviceOrderCount_desc", displayText: t("labels.filters.so_registered_desc") },
    { key: "serviceOrderCount", displayText: t("labels.filters.so_registered") },
    { key: "active_desc", displayText: t("labels.filters.active_desc") },
    { key: "active", displayText: t("labels.filters.active") },
  ];

  useEffect(() => {
    if (des) {
      setValue(`${sortBy}_desc`);
    } else {
      setValue(sortBy);
    }
  }, [sortBy, des]);

  return (
    <TextField
      select
      onChange={handleSorting}
      value={value}
      variant="outlined"
      SelectProps={{
        native: true,
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ classes: { root: classes.root } }}
    >
      {sortValues.map((el, idx) => (
        <option key={idx} value={el.key}>
          {el.displayText}
        </option>
      ))}
    </TextField>
  );
};

export default CustomersSortDropdown;
