import React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import { useTranslation } from "react-i18next";
import { LineItemsSORes } from "src/providers/LineItemsStore/types";
import LICustomerIcon from "src/pages/ServiceOrdersDetails/components/icons/LICustomerIcon";
import LIIkeaBusinessIcon from "src/pages/ServiceOrdersDetails/components/icons/LIIkeaBusinessIcon";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import useCurrencyFormat from "src/utils/hooks/currency";
import { LineItemTitle } from "src/pages/ServiceDetails/tabs/LineItems/components/LiServiceList/ServiceTable/LineItmesHead";

type Collection = "partnerSubtotal" | "customerSubtotal";
interface Props {
  data: LineItemsSORes;
  collection: Collection;
}

const LineItemsServiceHead: React.FC<Props> = ({ data, collection = "partnerSubtotal" }) => {
  const { t } = useTranslation(`lineItems`);
  const { currencyFormat, prepareDigits } = useCurrencyFormat();
  const {
    LIData: { partnerInfo, customerInfo },
  } = useLineItemsContext();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid
        xs={8}
        item
        container
        alignItems="center"
        justify="flex-start"
        style={{
          paddingLeft: 12,
          marginRight: -12
        }}
      >
        {collection === "partnerSubtotal" ? <LIIkeaBusinessIcon /> : <LICustomerIcon />}
        <LineItemTitle
          collection={collection}
          partnerInfo={partnerInfo}
          customerInfo={customerInfo}
        />
      </Grid>
      <Grid xs item>
        <Typography variant="caption" color="textSecondary">
          {t("labels.qty")}
        </Typography>
        <Typography variant="subtitle1">{prepareDigits(data[collection]?.qty)}</Typography>
      </Grid>
      <Grid xs item>
        <Typography variant="caption" color="textSecondary">
          {t("labels.table.subtotal")}
        </Typography>
        <Typography variant="subtitle1">{currencyFormat(data[collection]?.subtotal)}</Typography>
      </Grid>
      <Grid xs item>
        <Typography variant="caption" color="textSecondary">
          {t("labels.table.subtotal_var")}
        </Typography>
        <Typography variant="subtitle1">{currencyFormat(data[collection]?.subtotalVat)}</Typography>
      </Grid>
    </Grid>
  );
};

export default LineItemsServiceHead;
