import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  fieldGroup: {
    marginTop: 22,
  },
  fieldTitle: {
    display: "inline-block",
    marginLeft: 9,
    marginBottom: 10,
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    display: "inline-block",
  },
  addressCaption: {
    display: "inline-block",
    marginTop: 4,
    marginBottom: 11,
    marginLeft: theme.spacing(2),
    letterSpacing: "0.004em",
    color: "rgba(20, 30, 48, 0.5)",
  },
  addAltPhone: {
    color: "#2B59C3",
    display: "inline-block",
    marginBottom: 2,
  },
  addAltphoneText: {
    display: "inline-block",
  },
  btnGroup: {
    marginTop: theme.spacing(3),
  },
  submitBtn: {
    padding: theme.spacing(2),
    fontSize: "14px !important",
    color: "#ffff",
    minWidth: 80,
    backgroundColor: "#2B59C3",
    opacity: 1,
    "&:disabled": {
      opacity: 0.5,
      backgroundColor: "#2B59C3 !important",
    },
    "&:hover": {
      backgroundColor: theme.palette.brandColors.dark,
    },
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    fontSize: "14px !important",
    color: theme.palette.primary.main,
  },
  fieldWrapper: {
    display: "flex",
  },
  cityField: {
    marginLeft: theme.spacing(1),
  },
  titleWrapper: {
    display: "flex",
  },
}));
