import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";

const DeliveryAddressZipCodeInput = () => {
  const { control } = useFormContext();
  const { t } = useTranslation("orders");

  return (
    <Controller
      as={<TextField variant="outlined" fullWidth label={t("list_filter.zip_label")} />}
      name="deliveryAddressZipCode"
      control={control}
      defaultValue=""
    />
  );
};

export default DeliveryAddressZipCodeInput;
