import React, { useEffect } from "react";
import { useStyles } from "src/pages/Orders/CreationSO/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import AccordionWrapped from "src/pages/Orders/components/Accordion";
import { PartnerInfoSummary } from "./Info";
import Divider from "@material-ui/core/Divider";
import WarehouseIcon from "./icons/WarehouseIcon";
import DateRangeIcon from "src/pages/Orders/components/icons/DateRangeIcon";
import WarehouseAutoComplete from "src/pages/Orders/components/AutoCompleteWarehouse";
import SellersAutoComplete from "src/pages/Orders/components/AutoCompleteSellers";
import DatePicker from "src/components/DatePicker";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import addMonths from "date-fns/addMonths";

const PartnerInfo = React.memo(() => {
  const {
    SOData: { creationSO },
  } = useSOContext();
  const { control, errors, watch, setValue, trigger, register } = useFormContext();
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const startDate = watch("startDate");

  useEffect(() => {
    if (!!startDate) {
      register("deliveryDate");
      setValue("deliveryDate", new Date(addMonths(new Date(startDate), 3)).toISOString());
      trigger("deliveryDate");
    }
  }, [startDate]);

  return (
    <Grid container spacing={6} justify={"center"} direction={"row"}>
      <Grid item xs={5}>
        <div style={{ paddingBottom: 32 }}>
          <Typography variant="subtitle1" color="textSecondary">
            {t("labels.creation.new_so")}
          </Typography>
          <br />
          <Typography className={classes.stepTitle}>{t("labels.steps.step1")}</Typography>
        </div>
        <form>
          <div className={classes.titleWrapper}>
            <WarehouseIcon />
            <Typography variant="h6" className={classes.fieldTitle}>
              {t("labels.creation.warehouse")}
            </Typography>
          </div>
          <WarehouseAutoComplete />
          <div className={classes.titleWrapper}>
            <WarehouseIcon />
            <Typography variant="h6" className={classes.fieldTitle}>
              {t("labels.creation.seller")}
            </Typography>
          </div>
          <SellersAutoComplete />
          <div className={classes.fieldGroup}>
            <div className={classes.titleWrapper}>
              <DateRangeIcon />
              <Typography variant="h4" className={classes.fieldTitle}>
                {t("form.date_range")}
              </Typography>
            </div>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Controller
                  as={<DatePicker label={t("form.start_date")} required fullWidth />}
                  name="startDate"
                  control={control}
                  error={!!errors.startDate}
                  defaultValue={creationSO.startDate}
                  rules={{ required: "Required" }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={<DatePicker label={t("form.end_date")} required fullWidth />}
                  name="deliveryDate"
                  control={control}
                  error={!!errors.deliveryDate}
                  defaultValue={creationSO.deliveryDate}
                  rules={{ required: "Required" }}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Grid item xs={6}>
        <Typography variant="subtitle1" color="textSecondary">
          {t("labels.summary")}
        </Typography>
        <AccordionWrapped labelTextKey={t("labels.partner_info")} defaultExpanded>
          <PartnerInfoSummary />
        </AccordionWrapped>
      </Grid>
    </Grid>
  );
});

export default PartnerInfo;
