import React, { FC } from "react";
import { MainPropsLIType, SoLineItem } from "src/providers/LineItemsStore/types";
import TableHead from "./LineItmesHead";
import NoData from "src/pages/LIDetailsPage/components/NoData";
import MainTable from "src/components/MainTable";
import { Trans } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import CollapsibleRow from "src/components/CollapsibleRow";
import ActionsMenu from "../../ActionsMenu";
import MoreIcon from "src/pages/ServiceOrdersDetails/components/icons/MoreIcon";
import { makeStyles } from "@material-ui/core";
import { UseTableRowProps } from "react-table";
import Typography from "@material-ui/core/Typography/Typography";
import useCurrencyFormat from "src/utils/hooks/currency";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";

export const useStyles = makeStyles(() => ({
  listItem: {
    paddingLeft: 48,
  },
}));

export const columns = (isCanDeleted?: boolean, isCanEdit?: boolean, other?: any) => {
  return [
    {
      Header: () => (
        <Typography variant="body2">
          <Trans ns={"lineItems"} i18nKey="labels.item" />
        </Typography>
      ),
      id: "name",
      accessor: (ent: Partial<SoLineItem>) => ent.name,
      Cell: (ent: { value: string }) => <Typography variant="body2">{ent.value}</Typography>,
    },
    {
      Header: () => (
        <Typography variant="body2">
          <Trans ns={"lineItems"} i18nKey="labels.table.seller" />
        </Typography>
      ),

      id: "sellerName",
      accessor: (ent: Partial<SoLineItem>) => ent.seller?.displayText || "---",
      Cell: (ent: { value: string }) => <Typography variant="body2">{ent.value}</Typography>,
    },
    {
      Header: () => (
        <Typography variant="body2">
          <Trans ns={"lineItems"} i18nKey="labels.table.unit_name" />
        </Typography>
      ),

      id: "unitName",
      accessor: (ent: Partial<SoLineItem>) => ent.unitName,
      Cell: (ent: { value: string }) => <Typography variant="body2">{ent.value}</Typography>,
    },
    {
      Header: () => (
        <Typography variant="body2">
          <Trans ns={"lineItems"} i18nKey="labels.table.description" />
        </Typography>
      ),
      id: "description",
      accessor: (ent: Partial<SoLineItem>) => ent.description,
      Cell: (ent: { value: string }) => <Typography variant="body2">{ent.value}</Typography>,
    },
    {
      Header: () => (
        <Typography variant="body2">
          <Trans i18nKey="labels.qty" ns={"lineItems"} />
        </Typography>
      ),
      id: "qty",
      accessor: (ent: Partial<SoLineItem>) => ent.qty,
      Cell: (ent: { value: string }) => (
        <Typography variant="body2">{other.prepareDigits(ent.value)}</Typography>
      ),
    },
    {
      Header: () => (
        <Typography variant="body2">
          <Trans i18nKey="labels.table.subtotal" ns={"lineItems"} />
        </Typography>
      ),
      id: "summa",
      accessor: (ent: Partial<SoLineItem>) => ent.summa,
      Cell: (ent: { value: string }) => (
        <Typography variant="body2">{other.currencyFormat(ent.value)}</Typography>
      ),
    },
    {
      Header: () => (
        <Typography variant="body2">
          <Trans i18nKey="labels.table.subtotal_var" ns={"lineItems"} />
        </Typography>
      ),
      id: "summaVat",
      accessor: (ent: Partial<SoLineItem>) => ent.summaVat,
      Cell: (ent: { value: string }) => (
        <Typography align="left" variant="body2">
          {other.currencyFormat(ent.value)}
        </Typography>
      ),
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row }: { row: UseTableRowProps<SoLineItem> }) => (
        <>
          {(!isCanEdit || isCanDeleted) && (
            <ActionsMenu row={row.original} isCanDeleted={isCanDeleted} isCanEdit={isCanEdit}>
              <MoreIcon />
            </ActionsMenu>
          )}
        </>
      ),
    },
  ];
};
const FinalizedStatuses = ["FAILED", "CANCELED", "COMPLETED"];

const ServiceTable: FC<{ data: MainPropsLIType }> = ({ data }) => {
  const { currencyFormat, prepareDigits } = useCurrencyFormat();
  const classes = useStyles();
  const {
    serviceOrderDetails: {
      serviceOrder: { statusMatchingName },
    },
  } = useSODetailsContext();
  const {
    serviceDetails: { service },
  } = useServiceDetailsContext();

  const isEditable = React.useMemo(() => {
    return !(
      service.isIntegration &&
      FinalizedStatuses.includes(service.status?.matchingText?.toLocaleUpperCase()!)
    );
  }, [service.isIntegration, service.status?.matchingText]);

  if (data.partnerLineItems.length <= 0 && data.customerLineItems.length <= 0) {
    return <NoData />;
  }
  return (
    <Grid container direction="column">
      {data.partnerLineItems.length > 0 && (
        <CollapsibleRow
          classes={{
            listItem: classes.listItem,
            container: classes.listItem,
          }}
          entityFields={<TableHead data={data} collection={"partnerSubtotal"} />}
          entityDetails={
            <MainTable
              data={data.partnerLineItems}
              columnsData={() =>
                columns(statusMatchingName === "INCOMPLETE", isEditable, {
                  currencyFormat,
                  prepareDigits,
                })
              }
            />
          }
        />
      )}
      {data.customerLineItems.length > 0 && (
        <CollapsibleRow
          classes={{
            listItem: classes.listItem,
            container: classes.listItem,
          }}
          entityFields={<TableHead data={data} collection={"customerSubtotal"} />}
          entityDetails={
            <MainTable
              data={data.customerLineItems}
              columnsData={() =>
                columns(statusMatchingName === "INCOMPLETE", isEditable, {
                  currencyFormat,
                  prepareDigits,
                })
              }
            />
          }
        />
      )}
    </Grid>
  );
};

export default React.memo(ServiceTable);
