import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ContactsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9718 11.4817V13.5892C14.9734 13.9849 14.8082 14.3628 14.5166 14.6303C14.2251 14.8977 13.8344 15.0299 13.4403 14.9943C11.2786 14.7594 9.20202 14.0207 7.37757 12.8376C5.68016 11.7589 4.24105 10.3198 3.16245 8.62242C1.97517 6.78969 1.2363 4.70305 1.00571 2.53157C0.970211 2.13877 1.10144 1.74913 1.36734 1.45785C1.63324 1.16656 2.00932 1.00044 2.40372 1.00007H4.51129C5.21652 0.993128 5.81763 1.51008 5.91633 2.20841C6.00529 2.88287 6.17026 3.54511 6.4081 4.18249C6.6011 4.69595 6.47766 5.27477 6.09196 5.66481L5.19976 6.55701C6.19984 8.31581 7.65609 9.77206 9.41489 10.7721L10.3071 9.87994C10.6971 9.49424 11.276 9.3708 11.7894 9.5638C12.4268 9.80164 13.089 9.96661 13.7635 10.0556C14.4699 10.1552 14.9895 10.7685 14.9718 11.4817Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default ContactsIcon;
