import React, { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import service from "../../images/service.svg";
import { useCStyles } from "../Channels/style";
import ChannelItem from "../Channels/ChannelItem";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import Service from "src/containers/Comments/components/Services/Service";

export const useStyles = makeStyles((theme) => {
  return {
    expand: {
      transform: "rotate(180deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(0deg)",
    },
  };
});

const Services = ({ isOpen = true }) => {
  const [expanded, setExpanded] = useState(true);
  const { wrapper, icons } = useCStyles({ isOpen });
  const { expandOpen, expand } = useStyles({ isOpen });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={wrapper}>
      <ChannelItem
        id={7}
        icon={<Avatar variant="square" src={service} className={icons} />}
        displayText="Services"
        onClick={handleExpandClick}
        isOpen={isOpen}
        count={
          <IconButton
            className={clsx(expand, {
              [expandOpen]: expanded,
            })}
            size={"small"}
            disableFocusRipple
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={!expanded} timeout="auto" unmountOnExit>
        <Service />
      </Collapse>
    </div>
  );
};

export default Services;
