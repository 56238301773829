import React, { PropsWithChildren, useState } from "react";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { SelectProps } from "@material-ui/core/Select/Select";
import { useTranslation } from "react-i18next";

interface Props extends SelectProps {
  loading?: boolean;
  noneLabel?: boolean;
}

const MonteraSelect = ({
  children,
  loading,
  id,
  name,
  onChange,
  onOpen,
  noneLabel = true,
  ...props
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");
  const [value, setValue] = useState(props.value);
  const handleClose = () => setOpen(false);
  const handleOpen = (e: React.ChangeEvent<{}>) => {
    if (typeof onOpen === "function") onOpen(e);
    setOpen(true);
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, child: React.ReactNode) => {
    if (typeof onChange === "function") onChange(event, child);
    setValue(event.target.value as string);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor={id}>{loading ? <CircularProgress size={16} /> : props.label}</InputLabel>
      <Select
        open={open}
        value={value}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        inputProps={{
          name,
          id,
        }}
        fullWidth
        {...props}
      >
        <MenuItem aria-label="None" value="" style={{ minHeight: 32 }}>
          {noneLabel && t("labels.none")}
        </MenuItem>
        {children}
      </Select>
    </FormControl>
  );
};

export default MonteraSelect;
