import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
import { useStyles } from "./styles";
import ArrowDownIcon from "../../../../icons/ArrowDownIcon";

const SellerListHeader: React.FC = () => {
  const { headerWrapperSeller, firstItem } = useStyles();
  const { t } = useTranslation("analytics");

  return (
    <Grid container direction="row" alignItems="center" className={headerWrapperSeller}>
      <ArrowDownIcon style={{ width: 16, height: 16, marginRight: 8 }} />
      <Grid item xs key={uniqueId("report")} className={firstItem}>
        <Typography variant="body2">{t("labels.reports.seller")}</Typography>
      </Grid>
    </Grid>
  );
};
export default SellerListHeader;
