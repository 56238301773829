import { Switch, Redirect, Route } from "react-router-dom";
import React, { ReactElement } from "react";
import PageNotFound from "src/components/PageNotFound";
import Loading from "src/components/Suspense";
import WarehouseDetails from "./Warehouses/WarehouseDetails";
import CustomerDetails from "./Customers/CustomerDetails";
import CustomersPage from "./Customers";
import WarehousesPage from "./Warehouses";
import SellersPage from "./Sellers";
import OrdersPage from "./Orders";
import RedirectServiceDetailsPage from "./RedirectServiceDetailsPage";
import ServiceOrdersPage from "./ServiceOrdersDetails";
import LIDetailsPage from "./LIDetailsPage";
import ServiceDetailsPage from "./ServiceDetails";
import CreationSO from "./Orders/CreationSO";
import Typography from "@material-ui/core/Typography";
import AnalyticsPage from "./Analytics";
import AnalyticsPartnerManagerPage from "./Analytics/childPages/PartnerManager";
import AnalyticsWarehouseSeller from "./Analytics/childPages/WarehouseSeller";
import Support from "./Support";
import NewsReal from "./NewsPortal";

const TBA = () => (
  <div style={{ width: "100%" }}>
    <Typography align={"center"}>Functionalities in progress it will available soon</Typography>
  </div>
);

function Pages(): ReactElement {
  return (
    <Switch>
      <Redirect exact from="/" to="/orders" />
      <Route exact path="/home" component={TBA} />
      <Route exact path="/loading" component={Loading} />
      <Route exact path="/login" component={Loading} />
      <Route exact path="/customers" component={CustomersPage} />
      <Route exact path="/customers/details/:customerID/:type" component={CustomerDetails} />
      <Route
        exact
        path="/serviceOrders/details/:serviceOrderId/:type"
        component={ServiceOrdersPage}
      />
      <Route
        exact
        path="/services/details/:serviceId/:type"
        component={RedirectServiceDetailsPage}
      />
      <Route
        exact
        path="/services/details/:serviceOrderId/:serviceId/:type"
        component={ServiceDetailsPage}
      />
      <Route exact path="/serviceOrders/creation" component={CreationSO} />
      <Route exact path="/warehouses" component={WarehousesPage} />
      <Route exact path="/line_items/:serviceOrderId?/:serviceId?/" component={LIDetailsPage} />
      <Route exact path="/sellers" component={SellersPage} />
      <Redirect exact from="/orders" to="/orders/ordersList" />
      <Route exact path="/orders/:type?/" component={OrdersPage} />
      <Route exact path="/warehouses/details/:warehousesID/:type" component={WarehouseDetails} />
      <Route exact path="/analytics" component={AnalyticsPage} />
      <Route exact path="/analytics/partnerManager/" component={AnalyticsPartnerManagerPage} />
      <Route exact path="/analytics/warehouseSeller/" component={AnalyticsWarehouseSeller} />
      <Route exact path="/support" component={Support} />
      <Route exact path="/tba" component={TBA} />
      <Route path={[`/${process.env.REACT_APP_NEWS_BASE_APP_URL}`]} component={NewsReal} />
      <Route exact path="/404" component={PageNotFound} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default Pages;
