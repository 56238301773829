import { StepIconProps } from "@material-ui/core/StepIcon";
import SuccessStepsIcon from "src/pages/Orders/CreationSO/icons/SuccessStepsIcon";
import PartnerStepsIcon from "src/pages/Orders/CreationSO/icons/PartnerStepsIcon";
import StepsIcon from "src/pages/Orders/CreationSO/icons/StepsIcon";
import React from "react";

function StepIcon(props: StepIconProps) {
  const icons = () => {
    if (props.completed && !props.active) {
      return <SuccessStepsIcon />;
    }
    if (!props.completed && props.active) {
      return <PartnerStepsIcon />;
    }
    return <StepsIcon />;
  };
  return (
    <div
      style={{
        zIndex: 1,
        color: "#fff",
        display: "flex",
        width: 48,
        height: 48,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {icons()}
    </div>
  );
}
export default StepIcon;
