import React from "react";
import { useTranslation } from "react-i18next";
import { SuccessDialog } from "src/components/Dialog";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { useHistory } from "react-router-dom";

interface Props {
  open: boolean;
}

const SuccessNotification: React.FC<Props> = ({ open }: Props) => {
  const { t } = useTranslation("orders");
  const history = useHistory();
  const {
    SOData: { responseObject },
    callSOAPI,
  } = useSOContext();
  const statusIncomplete = responseObject?.statusMatchingName === "INCOMPLETE";

  const onClose = () => {
    history.push(`/serviceOrders/details/${responseObject?.id}/summary`);
    callSOAPI.setContextDataBulk({
      createService: false,
      success: false,
    });
  };

  return (
    <SuccessDialog
      open={open}
      title={t("create_services_in_bulk_form.success_dialog.title")}
      subtitle={
        statusIncomplete ? t("create_services_in_bulk_form.success_dialog.subtitle") : undefined
      }
      onAccept={onClose}
    />
  );
};

export default SuccessNotification;
