import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const EditDetailsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25293 1L12.2529 4L4.00293 12.25H1.00293V9.25L9.25293 1Z"
      stroke="#2B59C3"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.00293 16H14.5029"
      stroke="#2B59C3"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default EditDetailsIcon;
