import React from "react";

import { Alert, AlertTitle } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { useStyles } from "src/containers/FileUploadDislog/components/FileInfoAlerts/styles";
import {
  ErrorIcon,
  WarningIcon,
} from "src/containers/FileUploadDislog/components/FileInfoAlerts/icons";
import { useTranslation } from "react-i18next";
import { useFilesContext } from "src/providers/FilesStore";

const FileInfoAlerts = () => {
  const classes = useStyles();

  const { t } = useTranslation("files");

  const {
    constants: { MAX_FILES_COUNT },
    fileCountLimitReached,
    hasError,
    hasValidationErrors,
  } = useFilesContext();

  return (
    <div className={classes.alertsContainer}>
      {fileCountLimitReached && (
        <Alert
          elevation={10}
          severity="warning"
          icon={<WarningIcon />}
          className={classes.warningAlert}
        >
          <AlertTitle>
            <Typography variant="subtitle1">{t("alerts.max_files_reached.title")}</Typography>
          </AlertTitle>
          <Typography variant="caption">
            {t("alerts.max_files_reached.description", { maxFiles: MAX_FILES_COUNT })}
          </Typography>
        </Alert>
      )}
      {hasValidationErrors && (
        <Alert
          elevation={10}
          severity="warning"
          icon={<WarningIcon />}
          className={classes.warningAlert}
        >
          <AlertTitle>
            <Typography variant="subtitle1">{t("alerts.not_valid_files.title")}</Typography>
          </AlertTitle>
          <Typography variant="caption">{t("alerts.not_valid_files.description")}</Typography>
        </Alert>
      )}
      {hasError && (
        <Alert elevation={10} severity="error" icon={<ErrorIcon />} className={classes.errorAlert}>
          <AlertTitle>
            <Typography variant="subtitle1">{t("alerts.upload_error.title")}</Typography>
          </AlertTitle>
          <Typography variant="caption">{t("alerts.upload_error.description")}</Typography>
        </Alert>
      )}
    </div>
  );
};

export default FileInfoAlerts;
