import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DeliveryIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.09078 16.9999C6.8941 16.9999 7.54532 16.3487 7.54532 15.5454C7.54532 14.742 6.8941 14.0908 6.09078 14.0908C5.28745 14.0908 4.63623 14.742 4.63623 15.5454C4.63623 16.3487 5.28745 16.9999 6.09078 16.9999Z"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.8183 16.9999C15.6216 16.9999 16.2729 16.3487 16.2729 15.5454C16.2729 14.742 15.6216 14.0908 14.8183 14.0908C14.015 14.0908 13.3638 14.742 13.3638 15.5454C13.3638 16.3487 14.015 16.9999 14.8183 16.9999Z"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.39636 4.63636H17L15.7782 10.7382C15.6409 11.4293 15.0281 11.9226 14.3236 11.9091H6.63636C5.90144 11.9153 5.27734 11.3724 5.18182 10.6436L4.07636 2.26545C3.98154 1.54225 3.36576 1.0011 2.63636 1H1"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default DeliveryIcon;
