import React, { FC, useCallback, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import CommonList from "src/components/CommonList";
import OrdersDetails from "./OrdersTableDetails";
import OrdersFieldList from "./OrdersFieldList";
import { ServiceOrder } from "src/types";
import SearchIcon from "src/assets/icons/SearchIcon";
import { useStyles } from "./styles";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { useServicesContext } from "src/providers/ServicesStore";

const RelatedOrdersList: FC = React.memo(() => {
  const { relatedServiceOrdersState } = useSODetailsContext();
  const { callServicesAPI } = useServicesContext();
  const { icon, wrapper } = useStyles();
  const [openedServiceOrders, setOpenedServiceOrders] = useState<number[]>([]);
  const handlerServices = useCallback(
    () => ({ id }: ServiceOrder) => {
      if (id && openedServiceOrders.indexOf(id) < 0) {
        setOpenedServiceOrders((prev) => [...prev, id]);
      }
      callServicesAPI.fetchingServices(id!);
    },
    []
  );
  useEffect(() => {
    if (openedServiceOrders.length) {
      openedServiceOrders.forEach((id) => {
        callServicesAPI.fetchingServices(id);
      });
    }
  }, [relatedServiceOrdersState]);
  if (relatedServiceOrdersState.relatedOrders.length === 0 && !relatedServiceOrdersState.loading) {
    return (
      <div className={wrapper}>
        <SearchIcon className={icon} />
        <Typography variant="h3">No Search results for your request</Typography>
      </div>
    );
  }

  return (
    <CommonList<ServiceOrder>
      listEntities={relatedServiceOrdersState.relatedOrders}
      handlerOnOpen={handlerServices}
      entityFields={(ent) => <OrdersFieldList data={ent} />}
      entityDetails={(ent) => <OrdersDetails id={ent.id!} SOData={ent} />}
    />
  );
});

export default RelatedOrdersList;
