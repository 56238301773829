import React from "react";
import { Trans } from "react-i18next";
import { InvoiceAddress } from "src/types";

export const columns = () => {
  return [
    {
      Header: () => <Trans ns={"customers"} i18nKey="labels.details.address" />,
      id: "address",
      accessor: (ent: Partial<InvoiceAddress>) => ent.address,
    },
    {
      Header: () => <Trans i18nKey="labels.details.zip_code" ns={"customers"} />,
      id: "zipCode",
      style: { textAlign: "left" },
      accessor: (ent: Partial<InvoiceAddress>) => ent.zipCode,
    },
    {
      Header: () => <Trans i18nKey="labels.details.city" ns={"customers"} />,
      id: "city",
      style: { textAlign: "left" },
      accessor: (ent: Partial<InvoiceAddress>) => ent.city,
    },
    {
      Header: () => <Trans i18nKey="labels.details.state" ns={"customers"} />,
      id: "state",
      style: { textAlign: "left" },
      accessor: (ent: Partial<InvoiceAddress>) => ent.state,
    },
    {
      Header: () => <Trans ns={"customers"} i18nKey="labels.details.country" />,
      id: "country",
      style: { textAlign: "left" },
      accessor: (ent: Partial<InvoiceAddress>) => ent.country?.displayText,
    },
    {
      Header: () => <Trans i18nKey="labels.details.is_default" ns={"customers"} />,
      id: "isDefault",
      style: { textAlign: "left" },
      accessor: (ent: Partial<InvoiceAddress>) => (ent.isDefault ? "Yes" : "No"),
    },
  ];
};
