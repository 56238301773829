import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.functionalColors.dark,
    padding: theme.spacing(1),
    zIndex: 50,
    alignItems: "center",
    flexFlow: "nowrap",
    "& > *": {
      margin: "0 4px",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  activeServiceOrder: {
    backgound: "#fff",
  },
  panelSummary: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0 4px",
    "& :focused": {
      backgroundColor: "#fff",
    },
    "& >div": {
      margin: 0,
    },
    "& >.Mui-expanded": {
      margin: 0,
    },
    "& .MuiListItem-gutters": {
      padding: 4,
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(1),
    },
  },
  dynamicIcon: {
    minWidth: 48,
  },
  dynamicWarningMessage: {
    minWidth: 28,
  },
  icon: {
    fontSize: 72,
  },
  wrapper: {
    display: "grid",
    gridTemplateRows: "auto auto",
    gridGap: 12,
    alignItems: "center",
    justifyItems: "center",
    paddingTop: 32,
  },
}));
