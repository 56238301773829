import { makeStyles } from "@material-ui/core";
import React from "react";
import { getFileExtension, mapPreviewTypeFromExtension } from "src/utils/files";
import MonteraFilePreview from "src/components/FilePreview";

const usePreviewImageStyles = makeStyles(() => ({
  imageClass: {
    display: "block",
    margin: "0 auto",
  },
  imageWrapper: {
    margin: 10,
    textAlign: "center",
  },
}));

export default function QuestionnaireTableImagePreview({ file }: { file: any }) {
  const dto = {
    ...file,
    fileName: file.name,
  };
  const { imageClass, imageWrapper } = usePreviewImageStyles();
  const extension = getFileExtension(dto);
  const previewType = mapPreviewTypeFromExtension(extension);

  const hasPreview = !!previewType;
  if (!hasPreview) return null;
  return (
    <>
      {!!previewType && (
        <MonteraFilePreview
          id={file.fileServiceId}
          type={previewType}
          imageClassName={imageClass}
          className={imageWrapper}
        />
      )}
    </>
  );
}
