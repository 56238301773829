import React, { useCallback, useMemo } from "react";
import { Typography, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { useStyles } from "src/pages/ServiceOrdersDetails/components/styles";
import MonteraCard from "src/components/MonteraCard";
import { useAppContext } from "src/providers/AppProvider";
import EditIcon from "src/pages/ServiceOrdersDetails/components/icons/EditIcon";
import PartnerInfoIcon from "src/pages/ServiceOrdersDetails/components/icons/PartnerInfoIcon";
import PointLoader from "src/components/Preloader/PointLoader";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

const PartnerInfo = () => {
  const { serviceOrderDetails } = useSODetailsContext();
  const { partnerInfo, loading, serviceOrder } = serviceOrderDetails;
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    state: { user },
  } = useAppContext();
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();

  const handleEditPartnerInfo = useCallback(() => {
    toggleModal({ id: "editPartnerInfoSO", status: true });
  }, []);

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <MonteraCard
      headerIcon={<PartnerInfoIcon />}
      title={t("labels.summary_cards.partner_info")}
      action={
        isIntegrated && disabledByStatus ? (
          <IconButton onClick={handleEditPartnerInfo}>
            <EditIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        ) : (
          <></>
        )
      }
    >
      {!partnerInfo || loading ? (
        <PointLoader />
      ) : (
        <div className={classes.contentWrap}>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.warehouse")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {partnerInfo?.warehouse?.name}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.seller")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {partnerInfo?.seller?.name}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.seller_email")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              <a href={`mailto:${partnerInfo?.seller?.email}`} className={classes.emailLink}>
                {partnerInfo?.seller?.email}
              </a>
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.seller_phone")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {partnerInfo?.seller?.phone}
            </Typography>
          </div>
        </div>
      )}
    </MonteraCard>
  );
};

export default PartnerInfo;
