import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStyles } from "./styles";
import { LookupObject } from "src/types";
import EmptySearchIcon from "src/pages/Orders/components/icons/EmptySearchIcon";
interface Props {
  label: string;
  name: string;
  onSelect: (value: number, displayText: string) => void;
  onChange: (value: string, getNewData: boolean) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  loading: boolean;
  setLoading: (open: boolean) => void;
  inputValue?: string;
  required?: boolean;
  disabled?: boolean;
  options: { key: string; displayText: string }[];
}

const CustomerAutocomplete: React.FC<Props> = ({
  label = "",
  name = "", // html element name
  onSelect,
  onChange,
  open,
  setOpen,
  loading,
  inputValue = "", // default  value
  required = true,
  disabled = false,
  options,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const noResults = () => {
    return (
      <>
        <Box textAlign="center">
          <EmptySearchIcon className={classes.icon} />
        </Box>
        <Typography paragraph={true} display="block" align="center">
          {t("labels.autocomplete_no_results")}
        </Typography>
      </>
    );
  };

  const onInputChange: ComponentProps<typeof Autocomplete>["onInputChange"] = (
    event,
    val,
    reason
  ) => {
    event && event.persist();
    if (reason !== "reset") {
      onChange(val, true);
    }
  };

  return (
    <>
      <Autocomplete
        fullWidth={true}
        disabled={disabled}
        open={open} // open select
        autoSelect={true}
        options={options}
        inputValue={inputValue}
        classes={{
          inputRoot: classes.inputRoot,
          endAdornment: classes.endAdornment,
        }}
        onInputChange={onInputChange}
        noOptionsText={noResults()}
        getOptionLabel={(option) => option.displayText}
        getOptionSelected={(option, value) => option.displayText === value.displayText}
        loading={loading}
        onChange={(el, val) => {
          el &&
            val &&
            onSelect(Number((val as LookupObject).key), String((val as LookupObject).displayText));
          setOpen(false);
        }}
        renderInput={(params) => (
          <TextField
            required={required}
            label={label}
            name={name}
            placeholder={t("labels.autocomplete_start_typing")}
            {...params}
            variant="outlined"
          />
        )}
      />
    </>
  );
};

export default CustomerAutocomplete;
