import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: { margin: 0, padding: 0 },
  previewImage: {
    maxHeight: 750,
  },
  fileTypesSwitch: {
    "&> .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.light,
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));
