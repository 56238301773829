import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

interface Props {
  isFilterPanelOpen?: boolean;
}

export const usePageActionStyles = makeStyles<Theme, Props>((theme) => ({
    devider: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    text: {
      color: theme.palette.functionalColors.dark,
    },
    filterIcon: ({ isFilterPanelOpen }) => ({
        backgroundColor: isFilterPanelOpen ? "rgba(43, 89, 195, 0.2)" : "inherit"
      }),
}));

