import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ExpandAll from "src/assets/icons/MainNavListIcons/ExpandAll";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

const PageActions: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("warehouses");

  return (
    <Grid container className={classes.wrapper} direction="row">
      <Button startIcon={<ExpandAll />}>{t("labels.btn.expand_all")}</Button>
      <Divider orientation="vertical" flexItem />
      <Button endIcon={<ExpandMoreIcon />}>{t("labels.btn.name_asc")}</Button>
    </Grid>
  );
};

export default PageActions;
