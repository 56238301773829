import React, { PropsWithChildren } from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/Send";
import uniqueId from "lodash/uniqueId";
import StyledMenuItem from "./StyledMenuItem";
import { Button, ClickAwayListener, Grow, MenuList, Paper, Popper } from "@material-ui/core";

interface MenuItemElement {
  title: string | JSX.Element;
  icon?: boolean | JSX.Element;
  handler?: () => void;
}
interface Props {
  menuLinkList: Array<MenuItemElement>;
}

export default function CustomizedMenus({ children, menuLinkList }: PropsWithChildren<Props>) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const prevOpen = React.useRef(open);
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {children}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={"bottom-end"}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {menuLinkList.map((ent) => (
                    <ItemMenuElement key={uniqueId("p")} {...ent} />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

const ItemMenuElement = React.forwardRef(({ title, handler, icon }: MenuItemElement, ref) => {
  const handlerLink = () => !!handler && handler();
  return (
    <StyledMenuItem onClick={handlerLink}>
      <ListItemIcon ref={ref}>{icon ? icon : <SendIcon fontSize="small" />}</ListItemIcon>
      <ListItemText primary={title} />
    </StyledMenuItem>
  );
});
