import React, { PropsWithChildren, useState } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { useStyles } from "./styles";

interface Props {
  open: boolean;
  title: string;
  Icon: (props?: any) => JSX.Element;
}

const CollapsibleContent = ({ children, open = false, title, Icon }: PropsWithChildren<Props>) => {
  const [isOpen, setOpen] = useState(open);
  const { panel, root, actionIcon, icon } = useStyles({ isOpen });

  const handleExpandClick = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={root}>
      <div onClick={handleExpandClick} className={panel}>
        <Icon className={icon} />
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton
          disableFocusRipple
          disableRipple
          className={actionIcon}
          aria-expanded={isOpen}
          onClick={handleExpandClick}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse in={isOpen}>{children}</Collapse>
    </div>
  );
};

export default CollapsibleContent;
