import React from "react";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface Props {
  title: string;
}

export const useStyles = makeStyles((theme) => ({
  titleStyle: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const TabsContainer: React.FC<Props> = ({ title, children }) => {
  const { titleStyle } = useStyles();

  return (
    <Container style={{ margin: 0, padding: 0 }} maxWidth={"xl"}>
      <Typography variant="h3" className={titleStyle}>
        {title}
      </Typography>
      {children}
    </Container>
  );
};

export default TabsContainer;
