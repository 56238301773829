import React, { useCallback, useEffect } from "react";
import { useStyles } from "src/pages/Orders/CreationSO/styles";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useFormContext, Controller } from "react-hook-form";
import AccordionWrapped from "src/pages/Orders/components/Accordion";
import { PartnerInfoSummary, CustomerInfoSummary } from "./Info";
import Divider from "@material-ui/core/Divider";
import CustomerIcon from "./icons/CustomerIcon";
import DeliveryAddressIcon from "./icons/DeliveryAddressIcon";
import InvoiceAddressIcon from "./icons/InvoiceAddressIcon";
import InvoiceToIcon from "./icons/InvoiceToIcon";
import CustomerAutoComplete from "src/pages/Orders/components/AutoCompleteCustomer";
import AutoCompleteDeliveryAddress from "src/pages/Orders/components/AutoCompleteDeliveryAddress";
import AutoCompleteInvoiceAddress from "src/pages/Orders/components/AutoCompleteInvoiceAddress";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import LinkTo from "src/components/LinkTo";
import { useAppContext } from "src/providers/AppProvider";
import { ModalsUnion } from "src/providers/AppProvider/types";
import CreateCustomer from "src/pages/Customers/CreateCustomer";
import CreateDeliveryAddress from "src/pages/Customers/CustomerDetails/components/CreateDeliveryAddress";
import CreateInvoiceAddress from "src/pages/Customers/CustomerDetails/components/CreateInvoiceAddress";
import { Customer } from "src/types";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";

const CustomerInfo = React.memo(() => {
  const data = useFormContext();
  const { control, getValues, setValue, trigger, watch } = data;
  const {
    state: { modals },
    callAppAPI,
  } = useAppContext();
  const {
    SOData: { creationSO },
    callSOAPI: { fetchInvoiceAddresses, fetchDeliveryAddresses, setAssemblyZone },
  } = useSOContext();
  const {
    callAPI: { setContextData },
  } = useCustomerDetailsContext();
  const handleModal = useCallback(
    (type: ModalsUnion) => (event: React.SyntheticEvent) => {
      event.preventDefault();
      callAppAPI.toggleModal({ id: type, status: true });
    },
    []
  );
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const deliveryAddresses = getValues("deliveryAddress");

  useEffect(() => {
    if (!deliveryAddresses?.key) {
      return;
    }
    setAssemblyZone(deliveryAddresses.key);
  }, [deliveryAddresses]);

  const onAddNewCustomerHendler = (customerData: Customer) => {
    setValue("customer", { key: customerData?.key, displayText: customerData?.name });
    setContextData("customer", customerData);
    setValue("deliveryAddress", null);
    setValue("invoiceAddress", null);
    trigger(["invoiceAddress", "deliveryAddress"]);
  };

  const onSubmitDAHandler = (DAKey: number) => {
    fetchDeliveryAddresses(watch("customer").key).then((optionsList) => {
      const defaultValue = optionsList.find((el) => el.key === Number(DAKey));
      if (defaultValue) {
        setValue("deliveryAddress", defaultValue);
        trigger("deliveryAddress");
      }
    });
  };

  const onSubmitIAHandler = (IAKey: number) => {
    fetchInvoiceAddresses(watch("customer").key).then((optionsList) => {
      const defaultValue = optionsList.find((el) => el.key === Number(IAKey));
      if (defaultValue) {
        setValue("invoiceAddress", defaultValue);
        trigger("invoiceAddress");
      }
    });
  };

  return (
    <>
      <CreateCustomer onCustomerSubmit={onAddNewCustomerHendler} />
      {modals.createDeliveryAddressModal && (
        <CreateDeliveryAddress
          customerId={getValues("customer")?.key}
          onSubmit={onSubmitDAHandler}
        />
      )}
      {modals.createInvoiceAddressModal && (
        <CreateInvoiceAddress
          customerId={getValues("customer")?.key}
          onSubmit={onSubmitIAHandler}
        />
      )}
      <Grid container spacing={6} justify={"center"} direction={"row"}>
        <Grid item xs={5}>
          <div style={{ paddingBottom: 32 }}>
            <Typography variant="subtitle1" color="textSecondary">
              {t("labels.creation.new_so")}
            </Typography>
            <br />
            <Typography className={classes.stepTitle}>{t("labels.steps.step2")}</Typography>
          </div>
          <form>
            <div className={classes.fieldWrapper}>
              <div className={classes.titleWrapper}>
                <CustomerIcon />
                <Typography variant="h6" className={classes.fieldTitle}>
                  {t("labels.creation.customer")}
                </Typography>
              </div>
              <CustomerAutoComplete />
              <Typography variant="subtitle1" className={classes.fieldTitle}>
                {t("labels.creation.couldnt", {
                  field: t("labels.creation.customer"),
                })}
                <LinkTo href="#" onClick={handleModal("createCustomerModal")}>
                  {t("labels.creation.create_new")}
                </LinkTo>
              </Typography>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.titleWrapper}>
                <DeliveryAddressIcon />
                <Typography variant="h6" className={classes.fieldTitle}>
                  {t("labels.creation.delivery_address")}
                </Typography>
              </div>
              <AutoCompleteDeliveryAddress />
              <Typography variant="subtitle1" className={classes.fieldTitle}>
                {t("labels.creation.couldnt", {
                  field: t("labels.creation.delivery_address"),
                })}
                <LinkTo
                  href="#"
                  disabled={!getValues("customer")}
                  onClick={handleModal("createDeliveryAddressModal")}
                >
                  {t("labels.creation.create_new")}
                </LinkTo>
              </Typography>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.titleWrapper}>
                <InvoiceAddressIcon />
                <Typography variant="h6" className={classes.fieldTitle}>
                  {t("labels.creation.invoice_address")}
                </Typography>
              </div>
              <AutoCompleteInvoiceAddress />
              <Typography variant="subtitle1" className={classes.fieldTitle}>
                {t("labels.creation.couldnt", {
                  field: t("labels.creation.invoice_address"),
                })}
                <LinkTo
                  href="#"
                  disabled={!getValues("customer")}
                  onClick={handleModal("createInvoiceAddressModal")}
                >
                  {t("labels.creation.create_new")}
                </LinkTo>
              </Typography>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.titleWrapper}>
                <InvoiceToIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("form.invoice_to")}
                </Typography>
              </div>
              <Controller
                as={
                  <RadioGroup aria-label="invoiceTo" name="invoiceTo">
                    <FormControlLabel
                      value="1"
                      control={<Radio color={"primary"} />}
                      label={t("form.to_partner")}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color={"primary"} />}
                      label={t("form.to_customer")}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio color={"primary"} />}
                      label={t("form.other")}
                    />
                  </RadioGroup>
                }
                defaultValue={creationSO.invoiceTo || "1"}
                name="invoiceTo"
                control={control}
              />
              {getValues("invoiceTo") === "3" && (
                <Controller
                  as={<TextareaAutosize rowsMin={4} />}
                  defaultValue={creationSO?.invoiceToDescription || ""}
                  name="invoiceToDescription"
                  control={control}
                />
              )}
            </div>
          </form>
        </Grid>
        <Divider orientation="vertical" flexItem variant="middle" />
        <Grid item xs={6}>
          <Typography variant="subtitle1" color="textSecondary">
            {t("labels.summary")}
          </Typography>
          <AccordionWrapped labelTextKey={t("labels.customer_info")} defaultExpanded>
            <CustomerInfoSummary />
          </AccordionWrapped>
          <AccordionWrapped labelTextKey={t("labels.partner_info")}>
            <PartnerInfoSummary />
          </AccordionWrapped>
        </Grid>
      </Grid>
    </>
  );
});

export default CustomerInfo;
