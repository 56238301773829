import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DatePicker from "src/components/DatePicker";

const CreatedDateFrom = () => {
  const { control } = useFormContext();
  const { t } = useTranslation("orders");

  return (
    <Controller
      render={({ ref, ...props }) => (
        <DatePicker
          label={t("list_filter.created_date_from_label")}
          fullWidth
          {...props}
          inputRef={ref}
        />
      )}
      defaultValue=""
      name="createdDateFrom"
      control={control}
    />
  );
};

export default CreatedDateFrom;
