import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const IkeaDirIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.66674 2.48844L14.6591 2.48844C15.3998 2.48934 16 3.0889 16.0009 3.82876V12.2311C16.0001 12.9037 15.5039 13.4604 14.8572 13.5567C14.8577 13.5369 14.858 13.5171 14.858 13.4972L14.858 5.85732L14.858 5.85591C14.8564 4.53573 13.7741 3.49753 12.478 3.49599H7.47159L6.71456 2.61083C6.45557 2.30673 6.07674 2.13841 5.68499 2.14295L1.2319 2.14292L1.22372 2.14298C1.20182 2.14313 1.17998 2.14359 1.1582 2.14433C1.2534 1.50046 1.80645 1.00498 2.47735 1.0001H7.31194C7.37459 0.998147 7.43468 1.02515 7.47478 1.0733L8.66674 2.48844Z"
        fill="#5E7CE2"
      />
      <path
        d="M12.4757 4.63877L6.94432 4.63877L5.84405 3.35228C5.80704 3.30851 5.75157 3.28396 5.69374 3.28573H1.23104C0.549684 3.29061 0 3.83538 0 4.50569V13.4957C0.000826705 14.1683 0.554869 14.7134 1.23855 14.7142H12.4757C13.1594 14.7134 13.7135 14.1683 13.7143 13.4957V5.85725C13.7135 5.18465 13.1594 4.63959 12.4757 4.63877Z"
        fill="#65AFFF"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default IkeaDirIcon;
