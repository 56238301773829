import React from "react";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import LiServiceList from "./components/LiServiceList";
import PageActions from "./components/PageActions";
import { useTranslation } from "react-i18next";
import NoData from "src/pages/LIDetailsPage/components/NoData";
import TabsContainer from "src/components/TabsContainer";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import TotalFooter from "./components/LiServiceList/ServiceTable/TotalFooter";
import isEmpty from "lodash/isEmpty";

const LineItems = () => {
  const { t } = useTranslation(`lineItems`);
  const {
    LIData: { loading, lineItemsService },
  } = useLineItemsContext();
  if (loading) return <PreloaderLine loading={loading} />;
  if (!!loading && isEmpty(lineItemsService.lineItems)) return <NoData />;
  return (
    <TabsContainer title={t("labels.title")}>
      <PageActions />
      <LiServiceList />
      <TotalFooter />
    </TabsContainer>
  );
};

export default LineItems;
