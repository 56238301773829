import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { useTable, useExpanded, useSortBy, usePagination, useRowSelect } from "react-table";
import SortedIco from "src/assets/icons/SortedIcon";
import { useStyles } from "./style";
import clsx from "clsx";
interface Props<T extends object = {}> {
  data?: T[];
  styleOverlay?: string;
  columnsData?: () => any;
  rowActions?: (data: Partial<T>) => JSX.Element;
  pageSize?: number;
  total?: number;
  customStyles?: CustomStyles;
}
export interface CustomStyles {
  paddingLeft?: string;
}

function AnalyticsTable<D extends object = {}>({
  data = [],
  columnsData = () => [],
  rowActions,
  styleOverlay,
  pageSize = 10000,
  total,
  customStyles = { paddingLeft: "0" },
}: PropsWithChildren<Props<D>>) {
  const { t } = useTranslation("analytics");
  const { tableWrapper, headerText, footerText } = useStyles(customStyles);
  const columns = React.useMemo(() => columnsData(), []);
  const { getTableProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize,
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  if (!data?.length) {
    return null;
  }

  return (
    <MaUTable {...getTableProps()} className={clsx(tableWrapper, !!styleOverlay && styleOverlay)}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              return (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption" className={headerText}>
                      {column.render("Header")}
                    </Typography>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortedIco fontSize="small" />
                      ) : (
                        <SortedIco fontSize="small" style={{ transform: "rotate(180deg)" }} />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </TableCell>
              );
            })}
            {rowActions && <TableCell key={Math.random()}></TableCell>}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {page.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableCell align={"left"} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
              {rowActions && (
                <TableCell align={"center"} style={{}} key={Math.random()}>
                  {rowActions(row.original)}
                </TableCell>
              )}
            </TableRow>
          );
        })}
        {total ? (
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2" className={footerText}>
                {t("labels.reports.total")}
              </Typography>
            </TableCell>
            <TableCell style={{ border: 0 }}></TableCell>
            <TableCell style={{ border: 0 }}></TableCell>
            <TableCell>
              <Typography variant="subtitle2" className={footerText}>
                {`${total.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })} kr`}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    </MaUTable>
  );
}

export default React.memo(AnalyticsTable);
