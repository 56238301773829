import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRight: "2px solid #eaeaf0",
    },
    fieldTitle: {
      marginLeft: 9,
    },
    titleWrapper: {
      display: "flex",
      padding: "10px 0",
    },
    fieldWrapper: {
      paddingTop: 10,
    },
    fieldGroup: {
      marginTop: 22,
    },
    stepTitle: {
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0,
      textAlign: "left",
    },
    icons: {
      marginRight: theme.spacing(1),
    },
    textarea: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      lineHeight: 1.3,
      letterSpacing: 0.5,
      marginBottom: 10,
      border: "1px solid rgb(20,30,48,0.3)",
      "&::placeholder": {
        color: "rgb(20,30,48,0.4)",
        marginLeft: 10,
        paddingLeft: 10,
      },
    },
    infoTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    invoiceToLabel: {
      backgroundColor: "#fff",
      padding: `0px ${theme.spacing(1)}px`,
    },
    optionGroup: {
      fontStyle: "normal",
    },
    iconPlaceholder: {
      width: 16,
    },
  })
);
