import { keyBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useServiceOrderStatusesContext } from "src/providers/ServiceOrderStatusesStore";

export const useServiceOrderStatusesStyle = () => {
  const {
    state: { statuses },
  } = useServiceOrderStatusesContext();
  const { t } = useTranslation("lookups");

  const statusesDict = useMemo(() => keyBy(statuses, (x) => x.matchingText), [statuses]);

  const getName = (status: string) =>
    t(`lookups.status.${status.toLowerCase()}`, {
      defaultValue: statusesDict[status]?.displayText,
    });
  const getColor = (status: string) => statusesDict[status]?.color;

  return [getName, getColor];
};

export const useServiceOrderStatusStyle = (status: string) => {
  const [getName, getColor] = useServiceOrderStatusesStyle();
  return [getName(status), getColor(status)];
};
