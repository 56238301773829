import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import uniqueId from "lodash/fp/uniqueId";
import DropdownMenu from "src/components/DropdownMenu";
import { useStyles } from "./style";
import { TFunction } from "i18next";
import format from "date-fns/format";
import { File } from "src/types";
import FileIcon from "src/components/FileIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import PreviewIcon from "../../icons/PreviewIcon";
import MenuIcon from "../../icons/MenuIcon";
import StatusItem from "../StatusItem";
import ConfirmationModal from "../ConfirmationModal/";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import SuccessNotification from "../SuccessNotification/";
import { getFileExtension, getFileUrl, mapPreviewTypeFromExtension } from "src/utils/files";
import MonteraFilePreview from "src/components/FilePreview";
import { behaviorByStatus } from "src/utils/functions";
import { useAppContext } from "src/providers/AppProvider";

type CallbackHandlerType = (e: React.MouseEvent) => void;
type Actions = Record<"download" | "delete" | "preview", CallbackHandlerType>;

const actionsList = (
  t: TFunction,
  actions: Actions,
  hasPreview: boolean,
  isDeletable?: boolean
) => {
  const commonActions = [
    {
      label: t("labels.files.download"),
      icon: <DownloadIcon />,
      attr: {
        onClick: actions.download,
      },
    },
  ];
  const preview = [
    {
      label: t("labels.files.preview"),
      icon: <PreviewIcon />,
      attr: {
        onClick: actions.preview,
      },
    },
  ];
  const removable = [
    {
      label: t("labels.files.delete"),
      icon: <DeleteIcon />,
      attr: {
        onClick: actions.delete,
      },
    },
  ];
  if (!isDeletable) {
    commonActions.push(...removable);
  }
  if (hasPreview) {
    commonActions.push(...preview);
  }
  return commonActions;
};

interface Props {
  imageClassName?: string;
  file: File;
  onDelete?: () => void;
}

const FilesFields: React.FC<Props> = ({ imageClassName, file, onDelete = () => {} }) => {
  const classes = useStyles();
  const { t } = useTranslation(`orders`);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [openSuccessNotification, setOpenSuccessNotification] = useState<boolean>(false);
  const {
    state: { user },
  } = useAppContext();
  const {
    serviceOrderDetails: { serviceOrder },
    callAPI: { deleteFile, getFiles },
  } = useSODetailsContext();

  const downloadFile = (file: File) => async (e: React.MouseEvent) => {
    const url = await getFileUrl(file.fileServiceId);
    e.persist();
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", file.fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    element.remove();
  };

  const extension = getFileExtension(file);
  const previewType = mapPreviewTypeFromExtension(extension);

  const hasPreview = !!previewType;

  const disabledByStatus = useMemo(() => {
    return behaviorByStatus(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const isSameUser = useMemo(() => {
    return file.createdBy === user.name;
  }, [user.name, file.createdBy]);

  const handleDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleDeleteDeclined = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenSuccessNotification = () => {
    setOpenSuccessNotification(true);
  };

  const handleCloseSuccessNotification = () => {
    setOpenSuccessNotification(false);
    onDelete();
    serviceOrder.id && getFiles(serviceOrder.id);
  };

  const handleDeleteConfirmed = async () => {
    if (file && file.id && file.fileServiceId) {
      await deleteFile(file.id, file.fileServiceId);
      handleOpenSuccessNotification();
    }
    setOpenDeleteConfirmation(false);
  };

  const handlePreview = () => {
    if (!hasPreview) {
      return;
    }
    setPreviewOpen(!isPreviewOpen);
  };

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <ListItem
          alignItems="center"
          onClick={handlePreview}
          // @ts-ignore
          button={hasPreview}
        >
          <Grid item xs container alignItems="center" justify="flex-start">
            <FileIcon type={extension} />
            <div style={{ marginLeft: 8 }}>
              <Typography variant="caption" style={{ marginBottom: 8, display: "inline-block" }}>
                {file.fileName}
              </Typography>
              <Typography variant="subtitle1">{file.description || "No Description"}</Typography>
            </div>
          </Grid>
          <Grid
            item
            xs
            key={uniqueId("files")}
            container
            justify={"flex-end"}
            direction={"row"}
            alignItems="center"
            className={classes.fileInfoItem}
          >
            <Grid item>
              <Typography
                variant="subtitle1"
                style={{ marginRight: 10, color: "rgba(20, 30, 48, 0.5)" }}
              >
                {(file?.createdByActor || "") +
                  `${
                    file?.createdByOcuupationCategory
                      ? " (" + file.createdByOcuupationCategory + ")"
                      : ""
                  }`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                style={{ marginRight: 10, color: "rgba(20, 30, 48, 0.5)" }}
              >
                {format(new Date(file.createdDate as Date), "dd.MM.yyyy")}
              </Typography>
            </Grid>
            <Grid item>
              <StatusItem category={file.category} />
            </Grid>
            <Grid item>
              <DropdownMenu
                menuIcon={<MenuIcon />}
                menuActions={actionsList(
                  t,
                  {
                    download: downloadFile(file),
                    delete: handleDeleteConfirmation,
                    preview: handlePreview,
                  },
                  hasPreview,
                  !isSameUser || disabledByStatus
                )}
              />
            </Grid>
          </Grid>
        </ListItem>
        <div className={classes.previewCollapseContainer}>
          <Collapse in={isPreviewOpen} timeout="auto" unmountOnExit={false} mountOnEnter={true}>
            {!!previewType && (
              <MonteraFilePreview
                className={classes.preview}
                imageClassName={imageClassName}
                id={file.fileServiceId}
                type={previewType}
              />
            )}
          </Collapse>
        </div>
      </Grid>
      <ConfirmationModal
        isOpen={openDeleteConfirmation}
        handleConfirm={handleDeleteConfirmed}
        handleDecline={handleDeleteDeclined}
        fileName={file.fileName}
      />
      <SuccessNotification
        isOpen={openSuccessNotification}
        message={t("labels.files.delete_success")}
        onClose={() => handleCloseSuccessNotification()}
      />
    </>
  );
};

export default FilesFields;
