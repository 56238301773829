import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import { Link, useHistory } from "react-router-dom";
import BackIcon from "./icons/BackIcon";
import AddInvoiceIcon from "./icons/AddInvoice";
import AddDeliveryIcon from "./icons/AddDelivery";
import DeactivateIcon from "./icons/Deactivate";
import AddMember from "./icons/AddMember";
import { useTranslation } from "react-i18next";
import { useCustomersStyles } from "src/pages/Customers/styles";
import { useAppContext } from "src/providers/AppProvider";
import CreateDeliveryAddress from "./CreateDeliveryAddress";
import CreateInvoiceAddress from "./CreateInvoiceAddress";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

const PageActions: FunctionComponent = () => {
  const classes = useCustomersStyles({});
  const { t } = useTranslation("customers");
  const history = useHistory();
  const {
    state: { featureFlags, user },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    selectedCustomer: { customer },
  } = useCustomerDetailsContext();

  const handleBackAction = useCallback(() => {
    history.goBack();
  }, []);

  const handleAddInvoiceAddress = useCallback(() => {
    toggleModal({ id: "createInvoiceAddressModal", status: true });
  }, []);

  const handleAddDeliveryAddress = useCallback(() => {
    toggleModal({ id: "createDeliveryAddressModal", status: true });
  }, []);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Button className={classes.outlineBtn} startIcon={<BackIcon />} onClick={handleBackAction}>
          {t("labels.details.back")}
        </Button>
        {isIntegrated && (
          <>
            <Button
              className={classes.outlineBtn}
              startIcon={<AddIcon />}
              component={Link}
              to={{
                pathname: "/serviceOrders/creation",
                state: {
                  customerId: customer.key,
                  customerName: customer.name,
                },
              }}
            >
              {t("labels.details.create_order")}
            </Button>
            <Button
              className={classes.outlineBtn}
              startIcon={<AddInvoiceIcon />}
              onClick={handleAddInvoiceAddress}
            >
              {t("labels.details.add_invoice")}
            </Button>
            <Button
              className={classes.outlineBtn}
              startIcon={<AddDeliveryIcon />}
              onClick={handleAddDeliveryAddress}
            >
              {t("labels.details.add_delivery")}
            </Button>
            {!featureFlags.dk && (
              <Button className={classes.outlineBtn} startIcon={<AddMember />}>
                {t("labels.details.add_members")}
              </Button>
            )}
            <Button
              className={classes.outlineBtn}
              startIcon={<DeactivateIcon />}
              // onClick={handleModalOpen}
            >
              {t("labels.details.deactivate")}
            </Button>
          </>
        )}
      </div>
      <CreateDeliveryAddress customerId={customer?.key} />
      <CreateInvoiceAddress customerId={customer?.key} />
    </>
  );
};

export default PageActions;
