import React, { useState } from "react";
import { requestGet, requestMetaGet, requestPut } from "src/utils/crud";
import {
  ServiceDetailsState,
  ContextDetails,
  Props,
  RelatedServicesState,
  InfoType,
  RelatedServicesReq,
  AssignedWorkersState,
  ServiceStatusesState,
  AssignedWorkersReq,
  ServiceUpdateReq,
} from "./types";
import createCtx from "../../utils/createCtx";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ServiceDetailsType } from "src/pages/ServiceDetails/type";
import isEmpty from "lodash/fp/isEmpty";

const [useCtx, Provider] = createCtx<ContextDetails>();

const initialRelatedOrders: RelatedServicesState = {
  relatedServices: [],
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  totalCount: 0,
  loading: true,
  success: false,
};

const initialSelectedService: ServiceDetailsState = {
  service: {
    id: 0,
    name: "",
    createdDate: "",
    statusMatchingName: "",
  },
  loading: true,
  updated: false,
  success: false,
};

const initialAssignedWorkers: AssignedWorkersState = {
  assignedWorkers: [
    {
      key: 0,
      displayText: "",
      firstName: "",
      lastName: "",
      name: "",
    },
  ],
  loading: true,
};

const initialServiceStatuses: ServiceStatusesState = {
  serviceStatuses: [
    {
      id: 0,
      matchingName: "",
      color: "",
      statusType: "",
    },
  ],
  loading: true,
};

function ServiceDetailsProvider(props: Props): React.ReactElement {
  const [ServiceDetailsState, setServiceDetails] = useState(initialSelectedService);
  const [relatedServicesState, setRelatedServices] = useState(initialRelatedOrders);
  const [assignedWorkersState, setAssignedWorkers] = useState(initialAssignedWorkers);
  const [serviceStatusesState, setServiceStatuses] = useState(initialServiceStatuses);
  const { service } = ServiceDetailsState;
  const { serviceId } = useParams<ServiceDetailsType>();
  const { currentPage, pageSize } = relatedServicesState;

  useEffect(() => {
    if (!!serviceId) {
      getServiceDetails(serviceId);
    }
  }, [serviceId]);

  useEffect(() => {
    if (service.id !== 0 && !!service.id) {
      getRelatedOrders(service.id);
      getAssignedWorkers(service.id);
    }
    if (service.partner && service.partner.key) {
      getServiceStatuses(service.partner.key);
    }
  }, [service.id, service.partner?.key, pageSize, currentPage]);

  const getRelatedOrders = (key: number | string) => {
    setROLoading(true);
    requestGet<RelatedServicesReq>(`Services/${key}/relatedServices`, {
      page: relatedServicesState.currentPage,
      pageSize: relatedServicesState.pageSize,
      sortBy: "name",
    })
      .then((res) => {
        setRelatedServices((prev) => ({
          ...prev,
          ...res.data,
          relatedServices: [...res.data.relatedServices],
          pageSize: prev.pageSize,
          currentPage: prev.currentPage,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const getServiceStatuses = (partnerKey: number | string) => {
    requestMetaGet<any>(`Entities/projectStatuses?partner=${partnerKey}`).then((res) => {
      setServiceStatuses({ serviceStatuses: [...res.data], loading: false });
    });
  };

  const getAssignedWorkers = (key: number | string) => {
    requestGet<AssignedWorkersReq>(`Services/${key}/assignedWorkers`, { pageSize: 999 }).then(
      (res) => {
        setAssignedWorkers({
          ...res.data,
          loading: false,
        });
      }
    );
  };

  const getServiceDetails = (key: number | string) => {
    requestGet(`Services/${key}`)
      .then((res) => {
        setServiceDetails((prev) => ({
          ...prev,
          ...res.data,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const getServiceInfo = (key: number | string, infoType: InfoType) => {
    requestGet(`serviceOrders/${key}/${infoType}`)
      .then((res) => {
        setServiceDetails((prev) => ({
          ...prev,
          ...res.data,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const updateServiceData = async (data: ServiceUpdateReq) => {
    if (isEmpty(data)) return;
    setServiceLoading(true);
    await requestPut<ServiceUpdateReq>(`Services/${serviceId}`, data).then((res) => {
      setServiceDetails((prev) => ({
        ...prev,
        ...res.data,
        loading: false,
        updated: true,
      }));
    });
  };

  const setROLoading = (data: boolean) =>
    setRelatedServices((prev: RelatedServicesState) => ({
      ...prev,
      loading: data,
    }));

  const setServiceLoading = (data: boolean) =>
    setServiceDetails((prev: ServiceDetailsState) => ({
      ...prev,
      loading: data,
    }));

  const setROContextData = (key: string, data: any) => {
    setRelatedServices((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const setContextData = (key: string, data: any) => {
    setServiceDetails((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const callAPI = {
    getServiceDetails,
    getServiceInfo,
    setServiceLoading,
    setContextData,
    getRelatedOrders,
    getServiceStatuses,
    getAssignedWorkers,
    setROLoading,
    setROContextData,
    updateServiceData,
  };

  return (
    <Provider
      value={{
        serviceDetails: ServiceDetailsState,
        relatedServicesState,
        serviceStatuses: serviceStatusesState,
        assignedWorkers: assignedWorkersState,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useServiceDetailsContext, ServiceDetailsProvider };
