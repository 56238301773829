import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const UploadingIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="31"
    height="40"
    viewBox="0 0 31 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.42881 0C2.06691 0 0.952637 1.125 0.952637 2.5V37.5C0.952637 38.875 2.06691 40 3.42881 40H28.1905C29.5524 40 30.6667 38.875 30.6667 37.5V10L20.762 0H3.42881Z"
      fill="#2B59C3"
      fillOpacity="0.1"
    />
    <path
      d="M23.2379 10H30.6664L20.7617 0V7.5C20.7617 8.875 21.876 10 23.2379 10Z"
      fill="#2B59C3"
      fillOpacity="0.2"
    />
    <path d="M30.6658 17.5L23.2373 10H30.6658V17.5Z" fill="#2B59C3" fillOpacity="0.1" />
    <path
      d="M12.954 22.2315V30.7452C12.954 31.1125 13.2518 31.4102 13.619 31.4102H18.0873C18.4546 31.4102 18.7523 31.1125 18.7523 30.7452V22.2315H20.6519C21.2443 22.2315 21.5411 21.5153 21.1222 21.0964L16.8775 16.8518C16.3118 16.2861 15.3946 16.2861 14.8289 16.8518L10.5843 21.0964C10.1654 21.5153 10.4621 22.2315 11.0545 22.2315H12.954Z"
      fill="#2B59C3"
      fillOpacity="0.2"
    />
    <path
      d="M10.6713 14.5154H21.0351C21.7508 14.5154 22.3309 13.9352 22.3309 13.2195V13.1839C22.3309 12.4682 21.7508 11.8881 21.0351 11.8881H10.6713C9.95564 11.8881 9.37549 12.4682 9.37549 13.1839V13.2195C9.37549 13.9352 9.95564 14.5154 10.6713 14.5154Z"
      fill="#2B59C3"
      fillOpacity="0.2"
    />
  </SvgIcon>
);
export default UploadingIcon;
