import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStyles } from "./styles";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  debounce,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { Customer, CustomerRequest } from "src/types";
import { requestMetaGet, requestPut } from "src/utils/crud";
import { LookupObject } from "src/types";
import SelectInput from "src/components/SelectInput";
import CustomerAutocomplete from "../CreateCustomer/CustomerAutocomplete";
import CustomerAddress from "./icons/CustomerAddress";
import ContactsIcon from "./icons/ContactsIcon";
import EmailIcon from "./icons/EmailIcon";
import { AxiosResponse } from "axios";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessCustomerEdit from "./SuccessCustomerEdit";
import PhoneInputField from "src/components/PhoneInput/";
import { parsePhoneNumberBeforeSubmit } from "src/utils/functions";

const initialState = {
  key: 0,
  mobile: "",
  altPhone: "",
  email: "",
  address: "",
  city: { key: undefined, displayText: "" },
  province: { key: undefined, displayText: "" },
  zipCode: { key: undefined, displayText: "" },
  country: { key: undefined, displayText: "" },
  isBusiness: false,
};

interface Props {
  customer: Partial<Customer>;
  setContextData: (key: string, data: any) => void;
}

const EditCustomerForm: React.FC<Props> = React.memo(({ customer, setContextData }) => {
  const {
    state: {
      modals: { editCustomerModal },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const classes = useStyles();
  const [values, setValues] = useState<Partial<Customer>>(initialState);
  const [zipCodesOpen, setZipCodesOpen] = useState<boolean>(false);
  const [zipCodesLoading, setZipCodesLoading] = useState<boolean>(false);
  const [displayAltPhone, setAltPhoneDisplay] = useState<boolean>(false);
  const [zipCodeValues, setZipCodeValues] = useState<LookupObject[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { t } = useTranslation("customers");

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "editCustomerModal", status: false });
    setSubmitted(false);
  }, []);

  const handleDisplayAltPhone = (value: boolean) => {
    setAltPhoneDisplay(value);
  };

  const getZipCodes = debounce(async (displayText: string) => {
    const zipCodes = await requestMetaGet<LookupObject[]>(
      `lookups/zipCodes?countryKey=${values!.country!.key}&displayText=${displayText}`
    );
    let response = zipCodes.data.map((item) => {
      return { key: item.key, displayText: item.displayText };
    });
    setZipCodeValues(response);
    setZipCodesLoading(false);
  }, 600);

  const getCascadeData = async (zipCode: string | number) => {
    return await requestMetaGet<Partial<Customer>>(
      `lookups/zipCodes/${zipCode}?countryKey=${values!.country!.key}`
    );
  };

  //Zip Code Autocomplete
  const handleAutocompleteChange = async (value: string, getNewData: boolean) => {
    if (getNewData) {
      setZipCodesOpen(true);
      setZipCodesLoading(true);
      await getZipCodes(value);
    } else {
      setZipCodesOpen(false);
    }
    setValues((prev) => ({ ...prev, zipCode: { ...prev.zipCode, displayText: value } }));
  };

  // Zip Code Select
  const handleAutocompleteSelect = (value: number, displayText: string) => {
    setValues((prev) => ({ ...prev, zipCode: { ...prev.zipCode, key: value } }));
    setZipCodesOpen(false);
    handleDataCascade(displayText);
  };

  const handleDataCascade = async (parentZipCode: string) => {
    const cascadeData = await getCascadeData(parentZipCode);
    if (cascadeData && cascadeData.data) {
      setValues({
        ...values,
        zipCode: {
          key: cascadeData.data.key,
          displayText: cascadeData.data.displayText,
        },
        city: {
          key: cascadeData.data?.city?.key,
          displayText: cascadeData.data?.city?.displayText,
        },
        province: {
          key: cascadeData.data?.province?.key,
          displayText: cascadeData.data?.province?.displayText,
        },
        country: {
          key: cascadeData.data?.country?.key,
          displayText: cascadeData.data?.country?.displayText,
        },
      });
    }
  };

  // Country Select
  const handleDropdownChange = (value: number) => {
    setValues({
      ...values,
      country: { key: value },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "isBusiness") {
      setValues({
        ...values,
        [event.target.name]: event.target.checked,
        firstName: "",
        lastName: "",
        ssn: "",
      });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  };

  const editCustomer = async (data: Partial<CustomerRequest>) => {
    try {
      const res = await requestPut(`Customers/${customer.key}`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const submitObject: Partial<CustomerRequest> = {
      mobile: parsePhoneNumberBeforeSubmit(values.mobile),
      altPhone: parsePhoneNumberBeforeSubmit(values.altPhone),
      email: values.email,
      address: values.address,
      cityId: Number(values!.city!.key),
      provinceId: Number(values!.province!.key),
      zipCodeId: Number(values!.zipCode!.key),
      countryId: Number(values!.country!.key),
    };
    const customerRes = await editCustomer(submitObject);
    if ((customerRes as AxiosResponse).status === 200) {
      setSubmitted(true);
      setContextData("customer", (customerRes as AxiosResponse).data.customer);
    }
    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    !zipCodesOpen && setZipCodeValues([]);
  }, [zipCodesOpen]);

  useEffect(() => {
    if (
      values.address &&
      values.zipCode &&
      values.zipCode.key &&
      values.country &&
      values.email &&
      values.mobile
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [values]);

  useEffect(() => {
    if (customer?.altPhone) {
      handleDisplayAltPhone(true);
    }
    setValues({
      ...customer,
    });
  }, [customer]);

  if (customer.key === 0) return null;

  return (
    <Modal isOpen={editCustomerModal} onClose={handleModalClose}>
      <Grid container justify="center">
        <Grid item>
          {!submitted && (
            <form onSubmit={handleSubmit}>
              <Typography variant="h3">{t("labels.edit_customer.customer_form.title")}</Typography>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <CustomerAddress />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.create_customer.customer_form.address")}
                  </Typography>
                </div>
                <TextField
                  required
                  fullWidth
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={handleChange}
                  variant="outlined"
                  helperText={t("labels.create_customer.customer_form.address_helper")}
                  FormHelperTextProps={{
                    classes: { root: classes.addressCaption },
                  }}
                />
                <SelectInput
                  required
                  onSelect={handleDropdownChange}
                  label={t("labels.create_customer.customer_form.country")}
                  name="countryId"
                  value={values.country?.key!}
                  url="lookups/countries/delivery"
                  metaApi
                />
                <div className={classes.fieldWrapper}>
                  <CustomerAutocomplete
                    required
                    options={zipCodeValues}
                    name="zipCode"
                    label={t("labels.create_customer.customer_form.zip")}
                    inputValue={values.zipCode?.displayText}
                    open={zipCodesOpen}
                    setOpen={setZipCodesOpen}
                    loading={zipCodesLoading}
                    setLoading={setZipCodesLoading}
                    onChange={handleAutocompleteChange}
                    onSelect={handleAutocompleteSelect}
                  />
                  <TextField
                    required
                    fullWidth
                    disabled
                    name="cityId"
                    label={t("labels.create_customer.customer_form.city")}
                    value={values.city?.displayText}
                    onChange={handleChange}
                    variant="outlined"
                    className={classes.cityField}
                  />
                </div>
                <TextField
                  fullWidth
                  disabled
                  name="provinceId"
                  label={t("labels.create_customer.customer_form.province")}
                  value={values.province?.displayText}
                  onChange={handleChange}
                  variant="outlined"
                />
              </div>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <ContactsIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.create_customer.customer_form.contacts")}
                  </Typography>
                </div>
                <TextField
                  required
                  fullWidth
                  name="email"
                  label={t("labels.create_customer.customer_form.email")}
                  value={values.email}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    type: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.fieldGroup}>
                <PhoneInputField
                  value={values.mobile}
                  label={t("labels.create_customer.customer_form.mobile")}
                  name="mobile"
                  onChangeHandler={(value) => setValues({ ...values, mobile: value })}
                />
                {displayAltPhone ? (
                  <div className={classes.fieldGroup}>
                    <PhoneInputField
                      value={values.altPhone}
                      label={t("labels.create_customer.customer_form.alt_phone")}
                      name="altPhone"
                      onChangeHandler={(value) => setValues({ ...values, altPhone: value })}
                    />
                  </div>
                ) : (
                  <>
                    <Button
                      onClick={() => handleDisplayAltPhone(true)}
                      className={classes.addAltPhone}
                    >
                      <Typography variant="subtitle2">
                        {t("labels.create_customer.customer_form.add_alt_phone")}
                      </Typography>
                    </Button>
                    <Typography variant="body1" className={classes.addAltphoneText}>
                      {t("labels.create_customer.customer_form.alt_optional")}
                    </Typography>
                  </>
                )}
              </div>
              <div className={classes.btnGroup}>
                <Button
                  disabled={submitDisabled}
                  className={classes.submitBtn}
                  type="submit"
                  ref={submitButtonRef}
                >
                  {loading ? (
                    <CircularProgress size={18} color={"secondary"} />
                  ) : (
                    t("labels.create_customer.customer_form.save")
                  )}
                </Button>
                <Button onClick={handleModalClose} className={classes.cancelBtn}>
                  {t("labels.create_customer.customer_form.cancel")}
                </Button>
              </div>
            </form>
          )}
          {submitted && <SuccessCustomerEdit onClose={handleModalClose} />}
        </Grid>
      </Grid>
    </Modal>
  );
});

export default EditCustomerForm;
