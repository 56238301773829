import React, { useState } from "react";
import { requestGet, serializeParams } from "src/utils/crud";
import { Context, CustomerState, Props } from "./types";
import createCtx from "src/utils/createCtx";
import { useEffect } from "react";
import { FetchProps } from "../../types";

const [useCtx, Provider] = createCtx<Context>();

const initialState: CustomerState = {
  customers: [],
  currentPage: 1,
  pageCount: 10,
  pageSize: 10,
  totalCount: 0,
  success: false,
  loading: false,
  sortBy: "name",
  des: true,
  filters: {},
};

const defaultFetchProps: FetchProps = { cachingPolicy: "network" };

function CustomerProvider(props: Props): React.ReactElement {
  const [customerStore, setCustomerStore] = useState(initialState);
  const { currentPage, pageSize, sortBy, des, filters } = customerStore;
  useEffect(() => {
    if (!props.lazy) {
      callAPI.fetchCustomers();
    }
  }, [currentPage, pageSize, sortBy, des, filters]);

  const fetchCustomers = ({ cachingPolicy } = defaultFetchProps) => {
    if (cachingPolicy === "cache" && customerStore.success) {
      return;
    }
    setCustomersLoading(true);
    requestGet(`Customers`, {
      page: customerStore.currentPage,
      pageSize: customerStore.pageSize,
      sortBy: customerStore.sortBy,
      des: customerStore.des,
      filters: serializeParams(filters),
    })
      .then((res) => {
        setCustomerStore((prev) => ({
          ...prev,
          ...res.data,
          currentPage: prev.currentPage, //returned currentPage wrong number
          pageSize: prev.pageSize, //returned currentPage wrong number
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const setCustomersLoading = (data: boolean) => {
    setCustomerStore((prev: CustomerState) => ({
      ...prev,
      loading: data,
    }));
  };

  const toggleExpandCustomer = (id: number) => {
    setCustomerStore((prev: CustomerState) => ({
      ...prev,
      customers: prev.customers.map((elem) =>
        elem.key === id ? { ...elem, isExpanded: !elem.isExpanded } : elem
      ),
    }));
  };

  const setContextData = (key: string, data: any) => {
    setCustomerStore((prev) => ({
      ...prev,
      [key]: data,
    }));
  };

  const callAPI = {
    toggleExpandCustomer,
    fetchCustomers,
    setCustomersLoading,
    setContextData,
  };

  return (
    <Provider
      value={{
        customerData: customerStore,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useCustomerContext, CustomerProvider };
