import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  text: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    lineHeight: 1.3,
    letterSpacing: 0.5,
    color: "rgba(20, 30, 48, 0.5)",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    minWidth: 100,
    minHeight: 100,
  },
  successIcon: { width: 56, height: 56 },
  formControl: {
    "& .MuiInputLabel-root": {
      background: "rgba(255, 255, 255, 1)",
      paddingRight: 8,
    },
  },
  successWrapper: {
    "& .MuiDialog-paperWidthSm": {
      width: 600,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      lineHeight: 1.3,
      letterSpacing: 0.5,
      marginBottom: 10,
      border: "1px solid rgb(20,30,48,0.3)",
      "&::placeholder": {
        color: "rgb(20,30,48,0.4)",
        marginLeft: 10,
        paddingLeft: 10,
      },
    },

    "& .MuiDialogTitle-root": {
      paddingBottom: 0,
      fontSize: "20px",
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
    },
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      width: 600,
    },
    "& .MuiDialogActions-root": {
      justifyContent: "flex-start",
    },
  },
  dialogTitle: {
    paddingTop: 0,
  },
  closeBtn: {
    float: "right",
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
}));
