import React, { PropsWithChildren, useState } from "react";
import { columns as columnData } from "./columns";
import { FileQuestionnaireItem } from "src/types";
import QuestionnaireRowActions from "./QuestionnaireRowActions";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTable, useExpanded, useSortBy, usePagination, useRowSelect } from "react-table";
import SortedIco from "src/assets/icons/SortedIcon";
import { useStyles } from "./style";
import { Collapse } from "@material-ui/core";
import QuestionnaireTableImagePreview from "./QuestionnaireTableImagePreview";
interface MainTableProps {
  data?: FileQuestionnaireItem[];
}

const QuestionnaireTable = ({ data = [] }: PropsWithChildren<MainTableProps>) => {
  const { tableWrapper, tableCell } = useStyles();
  const columns = React.useMemo(() => columnData(), []);
  const [expandedRow, setExpandedRow] = useState<string[]>([]);
  const { getTableProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState: {
        sortBy: [
          {
            id: "name",
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const expandRow = (rowId: string) => {
    setExpandedRow((prev) => {
      const index = prev.indexOf(rowId);
      if (index > -1) {
        return prev.filter((el) => el !== rowId);
      } else {
        return prev.concat([rowId]);
      }
    });
  };
  return (
    <MaUTable {...getTableProps()} className={tableWrapper}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, id) => {
              return (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} key={id}>
                  <div
                    style={
                      !!column.style
                        ? { ...column.style }
                        : { display: "flex", alignItems: "center" }
                    }
                  >
                    <span>{column.render("Header")}</span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortedIco fontSize="small" />
                      ) : (
                        <SortedIco fontSize="small" style={{ transform: "rotate(180deg)" }} />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </TableCell>
              );
            })}
            <TableCell key={Math.random()}></TableCell>
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {page.map((row) => {
          prepareRow(row);
          return (
            <React.Fragment key={row.id}>
              <TableRow {...row.getRowProps()} onClick={() => expandRow(row.id)}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell align="left" className={tableCell} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
                <TableCell align={"center"} key={Math.random()} style={{ padding: 1 }}>
                  {QuestionnaireRowActions(row.original)}
                </TableCell>
              </TableRow>
              <TableRow style={{ border: "none" }}>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                  <Collapse
                    in={expandedRow.includes(row.id)}
                    mountOnEnter={true}
                    unmountOnExit={false}
                  >
                    <QuestionnaireTableImagePreview file={row.original} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </MaUTable>
  );
};

export default QuestionnaireTable;
