import React, { FC } from "react";
import MainTable from "src/components/MainTable";
import { Trans } from "react-i18next";
import { UseTableRowProps } from "react-table";
import { useServicesContext } from "src/providers/ServicesStore";
import { ServiceOrder, Service } from "src/types";
import { Grid, Typography } from "@material-ui/core";
import LinkTo from "src/components/LinkTo";
import format from "date-fns/format";
import NoServices from "../../../components/NoServices";
export const columns = () => {
  return [
    {
      Header: () => <Trans ns={"orders"} i18nKey="labels.table.service_id" />,
      id: "name",
      accessor: (ent: Partial<Service>) => ent.name,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <LinkTo href={`/services/details/${row.original.id}/summary`}>
          <Typography variant="body2" component={"span"}>
            {row.original.name}
          </Typography>
        </LinkTo>
      ),
    },
    {
      Header: () => <Trans ns={"orders"} i18nKey="labels.table.service_type" />,
      id: "serviceType",
      accessor: (ent: Partial<Service>) => ent?.serviceType?.displayText,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <Typography variant="body2">{row.original.serviceType?.displayText}</Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.seller" ns={"orders"} />,
      id: "seller",
      accessor: (ent: Partial<Service>) => ent?.seller?.displayText,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <Typography variant="body2">{row.original.seller?.displayText}</Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.start_date" ns={"orders"} />,
      id: "registeredDate",
      accessor: (ent: Partial<Service>) => ent.registeredDate,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) =>
        !!row.original.registeredDate ? (
          <Typography variant="body2">
            {format(new Date(row.original.registeredDate), "dd.MM.yyyy")}
          </Typography>
        ) : (
          <Typography variant="caption">none</Typography>
        ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.workers_count" ns={"orders"} />,
      id: "workersCount",
      accessor: (ent: Partial<Service>) => ent.assignedWorkersCount,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <Typography variant="body2">{row.original.assignedWorkersCount}</Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.capacity" ns={"orders"} />,
      id: "capacity",
      accessor: (ent: Partial<Service>) => ent.capacity || 0,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <Typography variant="body2">{row.original.capacity}</Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.status" ns={"orders"} />,
      id: "statusColor",
      accessor: (ent: Partial<Service>) => ent.status?.displayText,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) =>
        !!row.original.status?.displayText ? (
          <span
            style={{
              textAlign: "center",
              width: "fit-content",
              padding: 3,
              borderRadius: 5,
              backgroundColor: row.original.statusColor,
            }}
          >
            {row.original.status?.displayText}
          </span>
        ) : (
          <Typography variant="caption">none</Typography>
        ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.externalId" ns={"orders"} />,
      id: "externalId",
      accessor: (ent: Partial<Service>) => ent.externalId,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) =>
        !!row.original.externalId ? (
          <Typography variant="body2">{row.original.externalId}</Typography>
        ) : (
          <Typography variant="caption">none</Typography>
        ),
    },
  ];
};
const ServiceTable: FC<{ data: ServiceOrder }> = ({ data }) => {
  const {
    servicesData: { selectedSO },
  } = useServicesContext();

  if (selectedSO[data.id!]?.services.length <= 0) {
    return (
      <Grid container justify="center">
        <NoServices />
      </Grid>
    );
  }

  return <MainTable<Service> data={selectedSO[data.id!]?.services} columnsData={columns} />;
};

export default React.memo(ServiceTable);
