import { RefObject, useRef, useState } from "react";

export const useEllipsisTracking = (): [RefObject<HTMLElement>, boolean, () => void] => {
  const [hasEllipsis, setHasEllipsis] = useState(false);

  const nameTextRef = useRef<HTMLElement>(null);

  const checkEllipsis = () => {
    const el = nameTextRef.current;
    if (!el) {
      return;
    }
    if (el.offsetWidth < el.scrollWidth) {
      setHasEllipsis(true);
    }
  };

  return [nameTextRef, hasEllipsis, checkEllipsis];
};
