import React from "react";
import { Grid, Typography } from "@material-ui/core";
import NoDataIcon from "../../icons/NoDataIcon";
import { useNoDataStyles } from "./styles";
import { useTranslation } from "react-i18next";

const NoData: React.FC = () => {
  const { t } = useTranslation("analytics");
  const classes = useNoDataStyles();

  return (
    <Grid container justify="center" direction="column" className={classes.root}>
      <Grid item>
        <NoDataIcon style={{ width: "100%", height: 160 }} />
      </Grid>
      <br />
      <Typography variant="h4" align="center">
        {t("labels.no_data.title")}
      </Typography>
    </Grid>
  );
};

export default NoData;
