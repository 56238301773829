import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { requestGet } from "src/utils/crud";
import { useFormContext } from "react-hook-form";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface CustomersListType {
  key: number | string;
  displayText: string;
}

const CustomerAutoComplete: FC = () => {
  const [open, setOpen] = React.useState(false);
  const methods = useFormContext();
  const { t } = useTranslation("orders");
  const [value, setAutoCompleteValue] = React.useState<CustomersListType | null>(null);
  const { register, trigger, watch, setValue } = methods;
  const [options, setOptions] = React.useState<CustomersListType[]>([]);
  const location = useLocation<{ customerId: number; customerName: string }>();
  const customer = watch("customer");

  register("customer", { required: true });

  React.useEffect(() => {
    let active = true;
    (async () => {
      const res = await requestGet<{ customers: CustomersListType[] }>("Customers/lookups");
      if (active) {
        setOptions(res.data.customers);
      }
    })();
    return () => {
      active = false;
    };
  }, [open]);

  React.useEffect(() => {
    if (location.state) {
      const customerDataFromLocation = {
        key: location.state.customerId,
        displayText: location.state.customerName,
      };
      setAutoCompleteValue(customerDataFromLocation);
      setValue("customer", customerDataFromLocation);
      trigger(["customer"]);
    }
  }, [location.state]);

  React.useEffect(() => {
    if (customer && customer?.key !== value?.key) {
      setAutoCompleteValue(customer);
      trigger(["customer"]);
    }
  }, [customer]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(_, newValue) => {
        setAutoCompleteValue(newValue);
        setValue("customer", newValue);
        trigger(["customer"]);
      }}
      getOptionSelected={(option, value) => option.displayText === value.displayText}
      getOptionLabel={(option) => option.displayText}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={t("labels.creation.field_desc_customer")}
          variant="outlined"
        />
      )}
    />
  );
};

export default CustomerAutoComplete;
