import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  text: {
    padding: "16px 16px 8px 16px",
    fontWeight: 600,
  },
  totalServices: {
    color: theme.palette.text.secondary,
  },
  tooltip: {
    margin: "0 16px 16px 16px",
    minWidth: 200,
  },
}));
