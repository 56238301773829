import React from "react";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useStyles } from "src/containers/FileUploadDislog/components/FileInfoStatus/styles";
import { useTranslation } from "react-i18next";
import { FILE_STATUSES } from "src/containers/FileUploadDislog/components/FileInfoStatus/constants";
import { FILE_FORMAT_ERRORS } from "src/components/DropZone/constants";
import {
  AlertIcon,
  DoneIcon,
  PendingIcon,
  ErrorIcon,
} from "src/containers/FileUploadDislog/components/FileInfoStatus/icons";
import { FILE_UPLOAD_ERRORS, UploadFile } from "src/providers/FilesStore/types";

interface Props {
  fileItem: UploadFile;
}

const FileInfoStatus = ({ fileItem }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation("files");

  if (fileItem.errors.length) {
    let errorMessage = "";
    const error = fileItem.errors[0];
    if (error === FILE_FORMAT_ERRORS.MAX_FILE_SIZE) {
      errorMessage = t("statuses.max_size_reached");
    } else {
      errorMessage = t("statuses.unsupported_file_type");
    }
    return (
      <>
        <AlertIcon />
        <Typography className={classes.statusText} color="textSecondary">
          {errorMessage}
        </Typography>
      </>
    );
  }
  if (fileItem.status === FILE_STATUSES.DONE) {
    return (
      <>
        <DoneIcon />
        <Typography className={classes.statusText} color="textSecondary">
          {t("statuses.uploaded")}
        </Typography>
      </>
    );
  }
  if (fileItem.status === FILE_STATUSES.LOADING) {
    const percents = fileItem.getProgressPercents();
    return (
      <>
        <CircularProgress color="primary" size={16} variant="determinate" value={percents} />
        <Typography className={classes.statusText} color="textSecondary">
          {t("statuses.progress_percent", { progress: percents })}
        </Typography>
      </>
    );
  }
  if (fileItem.status === FILE_STATUSES.FAILED) {
    let message = t("statuses.failed");
    if (fileItem.errorCode === FILE_UPLOAD_ERRORS.TYPE_NOT_SUPPORTED) {
      message = t("statuses.failed_unsupported_file_type");
    }
    return (
      <>
        <ErrorIcon />
        <Typography className={classes.statusText} color="textSecondary">
          {message}
        </Typography>
      </>
    );
  }
  return (
    <>
      <PendingIcon />
      <Typography className={classes.statusText} color="textSecondary">
        {t("statuses.pending")}
      </Typography>
    </>
  );
};

export default FileInfoStatus;
