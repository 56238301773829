import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useLoadingStyles } from "./styles";
import Preloader from "src/components/Preloader";
import { useTranslation } from "react-i18next";

interface Props {
  loading: boolean;
}

const AnalyticsLoading: React.FC<Props> = ({ loading }) => {
  const { t } = useTranslation("analytics");
  const classes = useLoadingStyles();

  if (!loading) {
    return null;
  }
  return (
    <Grid container justify="center" direction="column" className={classes.root}>
      <Typography variant="h4" align="center">
        {t("labels.loading.loading")}
      </Typography>
      <br />
      <Typography variant="subtitle1" color="textSecondary" align="center">
        {t("labels.loading.title")}
      </Typography>
      <br />
      <Grid item>
        <Preloader is={true} />
      </Grid>
      <br />
    </Grid>
  );
};

export default AnalyticsLoading;
