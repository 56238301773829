import React, { FunctionComponent, useMemo } from "react";
import { Button } from "@material-ui/core";
import BackIcon from "./icons/BackIcon";
import AddLineItemsIcon from "./icons/AddLineItemsIcon";
import FilesIcon from "./icons/FilesIcon";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/providers/AppProvider";
import { Link, useHistory, useParams } from "react-router-dom";
import CreateService from "./CreateService";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { SODetailsType } from "../type";
import CreateServiceMenu from "./CreateServiceMenu";
import CreateServiceInBulkPage from "./CreateServiceInBulk";
import { useStyles } from "./styles";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";
import DeleteServiceOrder from "./DeleteServiceOrder";
import { Feature } from "../../../components/FeatureFlag/index";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";

const PageActions: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { serviceOrderId } = useParams<SODetailsType>();
  const { t } = useTranslation("orders");
  const {
    relatedServicesState: { services },
    serviceOrderDetails: { serviceOrder, partnerInfo, customerInfo },
  } = useSODetailsContext();
  const {
    state: { modals, user },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const handleBack = () => {
    history.goBack();
  };

  const onUploadFiles = () => {
    toggleModal({ id: "ordersFilesUpload", status: true });
  };

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Button className={classes.outlineBtn} startIcon={<BackIcon />} onClick={handleBack}>
          {t("labels.common.back")}
        </Button>
        {isIntegrated && (
          <Button
            className={classes.outlineBtn}
            startIcon={<AddIcon />}
            onClick={() => {
              history.push({
                pathname: `/serviceOrders/creation`,
                state: {
                  customerId: customerInfo?.customer.key,
                  customerName: customerInfo?.customer.name,
                  warehouseId: partnerInfo?.warehouse.key,
                  warehouseName: partnerInfo?.warehouse.name,
                  sellerId: partnerInfo?.seller.key,
                  sellerName: partnerInfo?.seller.name,
                },
              });
            }}
          >
            {t("labels.details.create_order")}
          </Button>
        )}
        {!!services.length && isIntegrated && disabledByStatus && (
          <Button
            className={classes.outlineBtn}
            to={`/line_items/${serviceOrderId}/${services[0]?.id}`}
            component={Link}
            startIcon={<AddLineItemsIcon />}
          >
            {t("labels.details.add_line_items")}
          </Button>
        )}
        {isIntegrated && disabledByStatus && <CreateServiceMenu />}
        <Button className={classes.outlineBtn} startIcon={<FilesIcon />} onClick={onUploadFiles}>
          {t("labels.details.add_files")}
        </Button>
      </div>
      {modals.createServiceModal && <CreateService />}
      {modals.createServiceBulkModal && <CreateServiceInBulkPage />}
      <Feature featureKey="deleteServiceOrder" children={() => <DeleteServiceOrder />} />
    </>
  );
};

export default PageActions;
