import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const UserIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        opacity="0.3"
        d="M15 1.47484V14.5255C15.0003 15.34 14.2166 16.0005 13.25 16.0002H2.75C1.7834 16.0005 0.999658 15.34 1 14.5255V1.47484C0.999658 0.660321 1.7834 -0.000104819 2.75 0.0001832H13.25C14.2166 -0.000104819 15.0003 0.660321 15 1.47484Z"
        fill="#4ECDC4"
      />
      <path
        d="M11 13.0002C11 12.2045 10.6839 11.4415 10.1213 10.8789C9.55871 10.3163 8.79565 10.0002 8 10.0002C7.20435 10.0002 6.44129 10.3163 5.87868 10.8789C5.31607 11.4415 5 12.2045 5 13.0002L8 13.0002H11Z"
        fill="#4ECDC4"
        stroke="#4ECDC4"
      />
      <path
        d="M8.00001 5.07666C8.84952 5.07465 9.53962 5.76201 9.54106 6.61152C9.54235 7.46103 8.85442 8.15069 8.00491 8.15141C7.1554 8.15199 6.46631 7.46348 6.46631 6.61397C6.46545 5.76604 7.15194 5.07782 8.00001 5.07666Z"
        fill="#4ECDC4"
        stroke="#4ECDC4"
      />
      <path
        d="M9.6 3.36798H6.4C6.17922 3.36798 6 3.14396 6 2.86798C6 2.59181 6.17922 2.36798 6.4 2.36798H9.6C9.82094 2.36798 10 2.59181 10 2.86798C10 3.14396 9.82094 3.36798 9.6 3.36798Z"
        fill="#4ECDC4"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(0 0.000183105)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default UserIcon;
