import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 40,
        height: 40,
        backgroundColor: "rgba(244, 206, 4, 1)", 
        borderRadius: 100
    },
    root: { 
        width: "100%",
        backgroundColor: "#fff"
    },
    message: {
        marginLeft: 14
    }
}));
