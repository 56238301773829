import { makeStyles } from "@material-ui/core/styles";
export const DRAWER_WIDTH = 400;
export const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 500,
  },
  actions: {
    padding: 16,
    display: "flex",
    width: DRAWER_WIDTH,
    background: "white",
    borderTop: "1px solid #f3f3f3",
    "& > button": {
      padding: "16px 24px",
      marginRight: 8,
    },
  },
  fieldsScroll: {
    overflow: "auto",
    flex: 1,
    height: "calc(100% - 16px)",
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "max-content",
    gap: `${theme.spacing(1)}px`,
  },
  chip: {
    backgroundColor: theme.palette.functionalColors.dark,
    borderRadius: 4,
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
    },
  },
  filtersStyles: {
    padding: 16,
    overflow: "hidden",
  },
}));
