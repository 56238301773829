import { makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  isOpen: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: ({ isOpen }) => ({
    borderLeft: "2px solid #65AFFF",
    borderBottom: isOpen ? "1px solid rgba(20, 30, 48, 0.3)" : "none",
    padding: 10,
    marginTop: 10,
  }),
  panel: {
    display: "grid",
    gridTemplateColumns: "25px 1fr 20px",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    fontSize: "1.2rem",
  },
  actionIcon: ({ isOpen }) => ({
    transform: `rotate(${isOpen ? 180 : 0}deg)`,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    height: 24,
  }),
}));
