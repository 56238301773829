import { InputBaseComponentProps } from "@material-ui/core";
import React, { forwardRef } from "react";
import NumberFormat, { NumberFormatProps, NumberFormatValues } from "react-number-format";

export type OnChange = (event: {
  target: { name: string | undefined; value: string; floatValue: number | undefined };
}) => void;

export type NumberFormatCustomProps = InputBaseComponentProps &
  NumberFormatProps & {
    inputRef: (instance: NumberFormat<any> | null) => void;
    onChange: OnChange;
  };

const NumberFormatCustom = forwardRef((props: NumberFormatCustomProps, _ref) => {
  const {
    inputRef,
    onChange,
    thousandSeparator = " ",
    decimalSeparator = ",",
    isNumericString = true,
    ...other
  } = props;

  const handleChange = (values: NumberFormatValues) => {
    onChange({
      target: {
        name: props.name,
        value: values.value,
        floatValue: values.floatValue,
      },
    });
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString={isNumericString}
    />
  );
});

export default NumberFormatCustom;
