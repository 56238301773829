import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const LICustomerIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6364 14.0909V12.6364C11.6364 11.0297 10.3339 9.72729 8.72727 9.72729H2.90909C1.30244 9.72729 0 11.0297 0 12.6364V14.0909"
        fill="#06A9C6"
      />
      <path
        d="M5.81827 6.81818C7.42492 6.81818 8.72736 5.51574 8.72736 3.90909C8.72736 2.30244 7.42492 1 5.81827 1C4.21162 1 2.90918 2.30244 2.90918 3.90909C2.90918 5.51574 4.21162 6.81818 5.81827 6.81818Z"
        fill="#06A9C6"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M13.6355 12.6364V14.0909H15.6356V12.6364C15.6356 11.0297 14.3331 9.72729 12.7265 9.72729H10.7264C12.333 9.72729 13.6355 11.0297 13.6355 12.6364Z"
        fill="#06A9C6"
      />
      <path
        d="M8.90825 6.64173C9.22009 6.75588 9.55691 6.81818 9.90829 6.81818C11.5149 6.81818 12.8174 5.51574 12.8174 3.90909C12.8174 2.30244 11.5149 1 9.90829 1C9.55691 1 9.22008 1.0623 8.90825 1.17645C10.0223 1.58425 10.8173 2.65382 10.8173 3.90909C10.8173 5.16436 10.0223 6.23393 8.90825 6.64173Z"
        fill="#06A9C6"
      />
    </g>
  </SvgIcon>
);
export default LICustomerIcon;
