import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { requestGet } from "src/utils/crud";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface ListType {
  key: number | string;
  displayText: string;
}

const AutoCompleteCommentTitles = () => {
  const [open, setOpen] = React.useState(false);
  const data = useFormContext();
  const { errors, register, setValue, getValues } = data;
  const { t } = useTranslation("comments");
  const [options, setOptions] = React.useState<ListType[]>([]);
  const loading = open && options.length === 0;
  React.useEffect(() => {
    (async () => {
      const res = await requestGet<{ titles: ListType[] }>(`chat/commentTitles?displayText=${getValues("topicName")}`);
      setOptions(res.data.titles);
    })();
  }, [loading]);

  React.useEffect(() => {
    register({ name: "topicName" });
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return (
    <Autocomplete
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.displayText === value.displayText}
      getOptionLabel={(option) => option.displayText}
      onChange={(_, value) => {
        if (!!value) {
          // @ts-ignore
          setValue("topicName", value?.displayText!);
        }
      }}
      onInputChange={(_, value) => {
        setValue("topicName", value);
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          label={t("labels.add_topic_title")}
          {...params}
          variant="outlined"
          error={!!errors.displayText}
          name="topicName"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={10} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteCommentTitles;
