import { parse, stringify } from "qs";
import { useLocation, useHistory } from "react-router-dom";

function useQueryString<T>() {
  const history = useHistory<T>();
  const location = useLocation<T>();

  const query: any = parse(location.search, { ignoreQueryPrefix: true });

  const setQuery = (newQuery: any, { fresh = false } = {}) => {
    history.replace(
      location.pathname +
        stringify({ ...(fresh ? {} : query), ...newQuery }, { addQueryPrefix: true })
    );
  };

  return [query, setQuery];
}

export default useQueryString;
