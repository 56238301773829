import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, useFormContext } from "react-hook-form";
import { requestMetaGet } from "src/utils/crud";
import { useSOContext } from "src/providers/ServiceOrdersStore";

interface Props {
  fieldName: string;
}

interface ServiceTypesListType {
  displayText: string;
  englishName: string;
  info: null | string;
  key: string;
  matchingName: null | string;
}
const ServiceTypeAutocomplete: React.FC<Props> = ({ fieldName }: Props) => {
  const [open, setOpen] = React.useState(false);
  const {
    SOData: { responseObject },
  } = useSOContext();
  const { control, register } = useFormContext();
  const [options, setOptions] = React.useState<ServiceTypesListType[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      const res = await requestMetaGet<ServiceTypesListType[]>(
        `lookups/ServiceTypes?partner=${responseObject?.partner?.key}`
      );
      if (active) {
        setOptions(res.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, responseObject]);

  React.useEffect(() => {
    register(`${fieldName}.serviceTypeId`);
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Controller
      render={({ onChange, ref }) => (
        <Autocomplete
          fullWidth
          id="serviceType"
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.displayText === value.displayText}
          getOptionLabel={(option) => option.displayText}
          onChange={(_, value) => {
            if (!!value) {
              onChange(value.key);
            }
          }}
          options={options}
          loading={loading}
          ref={ref}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              placeholder="Start typing"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={10} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      name={`${fieldName}.serviceTypeId`}
      control={control}
      defaultValue={""}
      rules={{ required: true }}
    />
  );
};

export default ServiceTypeAutocomplete;
