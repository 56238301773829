import { Chip } from "@material-ui/core";
import React from "react";
import { OrdersFilterParams } from "src/providers/ServiceOrdersStore/types";
import { ConfigType } from "./config";
import { filterChipStyles } from "./styles";

interface Props extends Partial<ConfigType> {
  name: keyof OrdersFilterParams;
  item: any;
  onDelete: (name: keyof OrdersFilterParams) => void;
}

const FilterChip: React.FC<Props> = ({ name, format, item, onDelete }) => {
  const classes = filterChipStyles();
  let formatItem = format;
  if (!formatItem) {
    formatItem = (x: string) => x;
  }

  const handleDelete = () => {
    onDelete(name);
  };

  return (
    <Chip
      className={classes.filterItemChip}
      color="primary"
      label={formatItem(item)}
      onDelete={handleDelete}
    />
  );
};

export default FilterChip;
