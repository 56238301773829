import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ListChevronRightIcon = (props: SvgIconProps) => (
    <SvgIcon
        width="16px" 
        height="16px" 
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
       <path d="M5.60022 12.5333L10.4002 7.73332L5.60022 2.93333" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round"/>
     </SvgIcon>
);
export default ListChevronRightIcon;