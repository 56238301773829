import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import HeadRelatedService from "./HeadRelatedService";
import ChildTable from "./ChildTable";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

export const useStyle = makeStyles(() => ({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 8,
  },
  heading: {
    fontSize: 14,
    fontWeight: 500,
  },
  tableWrap: {
    height: "65vh",
    overflow: "auto",
  },
}));

const RelatedServiceColumn = () => {
  const { mainWrapper } = useStyle();
  const { t } = useTranslation(`lineItems`);

  return (
    <div>
      <div className={mainWrapper}>
        <Typography variant="h4">{t("labels.add_li")}</Typography>
        <HeadRelatedService />
      </div>
      <ChildTable />
    </div>
  );
};
export default RelatedServiceColumn;
