import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import React, { FC, useState, useEffect } from "react";
import { FILE_VIEW_TYPES } from "src/components/Carousel/types";
// import { Feature } from "src/components/FeatureFlag";
import Preloader from "src/components/Preloader";
import { useQuestionnaireContext } from "src/providers/QuestionnaireStore";
import { FileQuestionnaires } from "src/providers/QuestionnaireStore/types";
import NoQuestionnaire from "./NoQuestionnaire";
import QuestionnaireOrdersTable from "./QuestionnaireOrdersTable";
import { useStyles } from "./styles";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import Carousel from "src/components/Carousel/Carousel";
import { FileQuestionnaireItem } from "src/types";
import { Feature } from "../../../../components/FeatureFlag/index";

const RelatedQuestionnaires: FC = React.memo(() => {
  const { t } = useTranslation("orders");
  const classes = useStyles();

  const {
    relatedQuestionnaireState: { fileQuestionnaires, loading },
  } = useQuestionnaireContext();
  const [fileViewType, setFileViewType] = useState(FILE_VIEW_TYPES.list);
  const j: boolean = fileQuestionnaires ? CheckIsEmptyQuestionnaire(fileQuestionnaires) : true;
  const [carouselFiles, setCarouselFiles] = useState<any[]>([]);

  const mapToCarouselFile = (file: FileQuestionnaireItem) => ({
    ...file,
    fileName: file.name,
  });
  useEffect(() => {
    if (!!fileQuestionnaires) {
      setCarouselFiles(
        carouselFiles.concat(
          ...fileQuestionnaires.serviceFileQuestionnaires.map((serviceQuestionnaire) =>
            serviceQuestionnaire.serviceFileQuestionnaires.map((serviceFilesQuestionnaire) =>
              mapToCarouselFile(serviceFilesQuestionnaire)
            )
          ),
          ...fileQuestionnaires.serviceOrderFileQuestionnaires.map(mapToCarouselFile)
        )
      );
    }
  }, [fileQuestionnaires]);
  const onChangeFileViewType = (v: number) => {
    if (!isNil(v)) {
      setFileViewType(v);
    }
  };

  if (loading)
    return (
      <div style={{ margin: "10vh 0px" }}>
        <Preloader is={loading} />
      </div>
    );

  if (j) {
    return <NoQuestionnaire />;
  } else
    return (
      <>
        <Feature featureKey="carouselServiceReports">
          {() => (
            <Grid container justify="center">
              <ToggleButtonGroup
                exclusive={true}
                className={classes.fileTypesSwitch}
                value={fileViewType}
                onChange={(_, v) => onChangeFileViewType(v)}
                color="primary"
              >
                <ToggleButton value={FILE_VIEW_TYPES.list}>
                  {t("labels.files.carousel.list")}
                </ToggleButton>
                <ToggleButton value={FILE_VIEW_TYPES.carousel}>
                  {t("labels.files.carousel.carousel")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
        </Feature>
        {fileViewType === FILE_VIEW_TYPES.list && <QuestionnaireOrdersTable />}
        <Feature featureKey="carouselServiceReports">
          {() => (
            <Carousel files={carouselFiles} open={fileViewType === FILE_VIEW_TYPES.carousel} />
          )}
        </Feature>
      </>
    );
});

export default RelatedQuestionnaires;

function CheckIsEmptyQuestionnaire(questionnaire: FileQuestionnaires): boolean {
  let isEmpty: boolean = true;
  for (const serviceFileQuestionnaire of questionnaire.serviceFileQuestionnaires) {
    if (!!serviceFileQuestionnaire.serviceFileQuestionnaires.length) {
      isEmpty = false;
      break;
    }
  }
  if (!!questionnaire.serviceOrderFileQuestionnaires.length) isEmpty = false;

  return isEmpty;
}
