import React from "react";
import isEmpty from "lodash/fp/isEmpty";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const Info = React.memo(({ data, children }) => {
  if (isEmpty(data))
    return <div style={{ display: "flex", justifyContent: "space-between" }}>{children}</div>;
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography color="inherit">{children}</Typography>
      <LightTooltip title={data}>
        <InfoOutlinedIcon fontSize="small" />
      </LightTooltip>
    </div>
  );
});

export default Info;
