import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const PieChartIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.40019 1H7.2002C6.64791 1 6.2002 1.44772 6.2002 2V14.4C6.2002 14.9523 6.64791 15.4 7.2002 15.4H8.4002C8.95248 15.4 9.4002 14.9523 9.4002 14.4V2C9.4002 1.44772 8.95248 1 8.40019 1Z"
      fill="#141E30"
      fillOpacity="0.5"
    />
    <path
      d="M13.6004 5H12.4004C11.8481 5 11.4004 5.44772 11.4004 6V14.4C11.4004 14.9523 11.8481 15.4 12.4004 15.4H13.6004C14.1527 15.4 14.6004 14.9523 14.6004 14.4V6C14.6004 5.44772 14.1527 5 13.6004 5Z"
      fill="#141E30"
      fillOpacity="0.3"
    />
    <path
      d="M3.2 9H2C1.44772 9 1 9.44772 1 10V14.4C1 14.9523 1.44772 15.4 2 15.4H3.2C3.75228 15.4 4.2 14.9523 4.2 14.4V10C4.2 9.44772 3.75228 9 3.2 9Z"
      fill="#141E30"
      fillOpacity="0.3"
    />
  </SvgIcon>
);
export default PieChartIcon;
