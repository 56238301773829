import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { useStyles } from "./styles";

interface Props {
  children?: React.ReactNode;
}

const ModalFormActions = ({ children }: Props) => {
  const classes = useStyles();
  return <DialogActions className={classes.actions}>{children}</DialogActions>;
};

export default ModalFormActions;
