import React, { useCallback } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import ArrowDownIcon from "src/pages/ServiceOrdersDetails/components/icons/ArrowDownIcon";
import ArrowUpIcon from "src/pages/ServiceOrdersDetails/components/icons/ArrowUpIcon";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  btnText: {
    marginLeft: theme.spacing(1),
  },
}));

const ExpandCollapseButton: React.FC = () => {
  const classes = useStyles();
  const {
    LIData: { lineItemsSO },
    callLineItemsAPI: { setContextData },
  } = useLineItemsContext();
  const { t } = useTranslation("lineItems");

  const expandAll = () => {
    let hasLineItems = [];
    for (let i = 0; i < lineItemsSO?.items.length; i++) {
      if (
        lineItemsSO?.items[i].partnerLineItems?.length ||
        lineItemsSO?.items[i].customerLineItems?.length
      ) {
        hasLineItems.push(`${i}`);
      }
    }
    setContextData("lineItemsSO", { ...lineItemsSO, expands: hasLineItems });
  };

  const collapseAll = () => {
    setContextData("lineItemsSO", { ...lineItemsSO, expands: [] });
  };

  const handleClick = useCallback(() => {
    if (lineItemsSO.expands.length > 0) {
      collapseAll();
    } else {
      expandAll();
    }
  }, [lineItemsSO.expands]);

  return (
    <div className={classes.wrapper}>
      <Button onClick={handleClick}>
        {lineItemsSO.expands.length > 1 ? <ArrowDownIcon /> : <ArrowUpIcon />}
        <Typography variant="button" className={classes.btnText}>
          {lineItemsSO.expands.length > 1 ? t("labels.collapse_all") : t("labels.expand_all")}
        </Typography>
      </Button>
    </div>
  );
};

export default ExpandCollapseButton;
