import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  btnGroup: {
    marginTop: theme.spacing(1),
  },
  submitBtn: {
    padding: theme.spacing(2),
    minWidth: 120,
    color: "#ffff",
    backgroundColor: theme.palette.functionalColors.dark,
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    minWidth: 120,
    color: theme.palette.primary.main,
  },
}));

interface Props {
  title: JSX.Element;
  okText: string;
  cancelText: string;
  action: (state: boolean) => void;
  open: boolean;
  acceptDisabled?: boolean;
}

const CommentsDialog: React.FC<Props> = ({
  title,
  okText = "",
  cancelText = "",
  children = "",
  action,
  open = false,
  acceptDisabled = false,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    action(false);
  };
  const handleAccept = () => {
    action(true);
  };
  return (
    <Dialog open={Boolean(open)} onClose={handleClose} PaperProps={{ style: { padding: 40 } }}>
      <DialogTitle style={{ padding: "0 0 24px 0" }}>{title}</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <DialogContentText style={{ marginBottom: 0 }}>{children}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-start", padding: 0 }}>
        <div className={classes.btnGroup}>
          <Button onClick={handleAccept} disabled={acceptDisabled} className={classes.submitBtn}>
            {okText}
          </Button>
          <Button autoFocus className={classes.cancelBtn} onClick={handleClose}>
            {cancelText}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CommentsDialog;
