import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  primaryActions: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    "& > .MuiButton-root": {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    "& :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
}));
