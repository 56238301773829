import React from "react";
import { FC } from "react";
import { Link, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { createStyles } from "@material-ui/core/styles";
import { LinkBaseProps } from "@material-ui/core/Link/Link";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      fontSize: 14,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1,
      color: "#003882",
      textTransform: "uppercase",
      textDecoration: "none",
    },
    disabled: {
      pointerEvents: "none",
      cursor: "default",
      color: theme.palette.text.secondary,
    },
  })
);
interface Props {
  disabled?: boolean;
  className?: string;
}

const LinkTo: FC<LinkBaseProps & Props> = (props) => {
  const classes = useStyles();

  return (
    <Link
      {...props}
      className={clsx(props.disabled && classes.disabled, props.className, classes.wrapper)}
    >
      {props.children}
    </Link>
  );
};
export default LinkTo;
