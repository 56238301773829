import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import CollapsibleContent from "src/components/CollapsibleContent";
import ClipboardIcon from "src/assets/icons/Clipboard";
import GridIcon from "src/assets/icons/GridIcon";
import { OrdersFilterParams } from "src/providers/ServiceOrdersStore/types";
import SelectWarehouse from "./components/SelectWarehouse";
import DeliveryAddressZipCodeInput from "./components/DeliveryAddressZipCodeInput";
import SelectSeller from "./components/SelectSeller";
import ServiceOrderExternalIdInput from "./components/ServiceOrderExternalIdInput";
import ServiceOrderIdInput from "./components/ServiceOrderIdInput";
import BillingIdsInput from "./components/BillingIdsInput";
import ReferenceOthersInput from "./components/ReferenceOthersInput";
import SelectServiceStatus from "./components/SelectServiceStatus";
import CreatedDateFrom from "./components/CreatedDateFrom";
import CreatedDateTo from "./components/CreatedDateTo";
import DeliveryDateFrom from "./components/DeliveryDateFrom";
import DeliveryDateTo from "./components/DeliveryDateTo";
import Actions from "./components/Actions";
import { useStyles } from "./styles";
import CustomerAutocomplete from "./components/CustomerAutocomplete";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { isEqual } from "lodash";
import DeliveryAddress from "./components/DeliveryAddress/index";
import ServiceType from "./components/ServiceStatus/index";

export const filterDefaultValues: OrdersFilterParams = {
  deliveryAddressZipCode: "",
  warehouses: [],
  seller: "",
  sellerName: "",
  sellerWarehouseKey: "",
  customerKey: "",
  customerName: "",
  externalIds: [],
  ids: [],
  statusTypeId: "",
  createdDateFrom: "",
  createdDateTo: "",
  deliveryDateFrom: "",
  deliveryDateTo: "",
  billingIds: [],
  referenceOthers: [],
  deliveryAddress: "",
  serviceTypes: [],
};

const Filter = () => {
  const {
    SOData: { filters },
    callSOAPI,
  } = useSOContext();
  const { heading, filtersStyles, fieldsScroll } = useStyles();
  const methods = useForm<OrdersFilterParams>();
  const { t } = useTranslation("orders");

  useEffect(() => {
    if (!isEqual(filters, methods.getValues())) {
      methods.reset(filters);
    }
  }, [filters]);

  const submit = () => {
    callSOAPI.setContextData("filters", methods.getValues());
    callSOAPI.setContextData("currentPage", 1);
  };
  const handleClear = () => {
    callSOAPI.setContextData("filters", {});
    callSOAPI.setContextData("currentPage", 1);
  };

  const clear = () => {
    methods.reset(filterDefaultValues, {
      errors: false,
      isDirty: false,
      isSubmitted: false,
      touched: false,
      isValid: false,
      submitCount: false,
      dirtyFields: false,
    });
    methods.trigger();
    handleClear();
  };

  return (
    <FormProvider {...methods}>
      <div className={filtersStyles}>
        <Typography variant="h3" className={heading}>
          Filters
        </Typography>
        <div className={fieldsScroll}>
          <CollapsibleContent open title={t("list_filter.order_details")} Icon={ClipboardIcon}>
            <SelectWarehouse />
            <SelectSeller />
            <CustomerAutocomplete />
            <DeliveryAddress />
            <DeliveryAddressZipCodeInput />
          </CollapsibleContent>
          <CollapsibleContent open title={t("list_filter.service_order")} Icon={GridIcon}>
            <ServiceOrderIdInput label={t("list_filter.so_id_label")} />
            <ServiceOrderExternalIdInput />
            <BillingIdsInput />
            <ReferenceOthersInput />
            <CreatedDateFrom />
            <CreatedDateTo />
            <DeliveryDateFrom />
            <DeliveryDateTo />
            <SelectServiceStatus />
          </CollapsibleContent>
          <CollapsibleContent open title={t("list_filter.service")} Icon={GridIcon}>
            <ServiceType />
          </CollapsibleContent>
        </div>
      </div>
      <Actions clear={clear} submit={submit} />
    </FormProvider>
  );
};

export default Filter;
