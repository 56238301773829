import { makeStyles, Typography, Link } from "@material-ui/core";
import React from "react";
import { MonteraFile } from "./types";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash";
import moment from "moment";
import { useYesNoLabel } from "./hooks/i18next";
import { Link as RouterLink } from "react-router-dom";
import { FileRelatedEntityType } from "src/components/Carousel/types";

type Props = {
  file: MonteraFile;
};

const CarouselItemInfo = ({ file }: Props) => {
  const {
    fileName: name,
    category,
    description,
    createdBy,
    isValid,
    createdDate,
    isSharedToContractor,
    isSharedToPartner,
    createdByName,
    relatedEntity,
  } = file;

  const { t } = useTranslation("orders");
  const classes = useStyles();

  const relatedToLink = () => {
    if (relatedEntity.type === FileRelatedEntityType.SO) {
      return `/serviceOrders/details/${relatedEntity.key}/summary`;
    }
    return `/services/details/${relatedEntity.key}/summary`;
  };

  const yesNoLabel = useYesNoLabel();
  return (
    <div className={classes.infoBlock}>
      <Typography>{`${t("labels.files.carousel.info.name")}: ${name}`}</Typography>
      {!!category && (
        <Typography>
          {`${t("labels.files.carousel.info.category")}: ${category.displayText}`}
        </Typography>
      )}
      {!!description && (
        <Typography>{`${t("labels.files.carousel.info.description")}: ${description}`}</Typography>
      )}
      {!!relatedEntity && (
        <Typography>
          {`${t("labels.files.carousel.info.related_to")}: `}
          <Link
            className={classes.link}
            to={relatedToLink()}
            target="_blank"
            component={RouterLink}
          >
            {relatedEntity.displayText}
          </Link>
        </Typography>
      )}
      {!isUndefined(isValid) && (
        <Typography>
          {`${t("labels.files.carousel.info.approved")}: ${yesNoLabel(isValid)}`}
        </Typography>
      )}
      {!isUndefined(isSharedToPartner) && (
        <Typography>
          {`${t("labels.files.carousel.info.shared_to_partner")}: ${yesNoLabel(isSharedToPartner)}`}
        </Typography>
      )}
      {!isUndefined(isSharedToContractor) && (
        <Typography>
          {`${t("labels.files.carousel.info.shared_to_contractor")}: ${yesNoLabel(
            isSharedToContractor
          )}`}
        </Typography>
      )}
      {!!createdDate && (
        <Typography>
          {`${t("labels.files.carousel.info.created_date")}: ${moment(createdDate).format("L LT")}`}
        </Typography>
      )}
      {!!createdBy && (
        <Typography>
          {t("labels.files.carousel.info.created_by")}
          {": "}
          {/* <Link
            className={classes.link}
            to={`/users/details/${createdBy}`}
            target="_blank"
            component={RouterLink}
          > */}
          {createdByName || createdBy}
          {/* </Link> */}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((_theme) => ({
  infoBlock: {
    background: "rgba(0,0,0,.4)",
    color: "#fff",
    lineHeight: 1,
    padding: "10px 20px",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 4,
  },
  link: {
    color: "#838cff",
  },
}));

export default CarouselItemInfo;
