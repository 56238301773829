import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "src/components/Suspense";
import "./index.css";
import App from "./App";

ReactDOM.render(
  <Router>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </Router>,
  document.getElementById("root")
);
