import React, { useCallback, useState } from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Container from "@material-ui/core/Container/Container";
import { useTranslation } from "react-i18next";
import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { ServiceOrdersProvider, useSOContext } from "src/providers/ServiceOrdersStore";
import CustomerInfo from "src/pages/Orders/CreationSO/CustomerInfo";
import ProjectInfo from "src/pages/Orders/CreationSO/ProjectInfo";
import PartnerInfo from "src/pages/Orders/CreationSO/PartnerInfo";
import PageActions from "./PageActions";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Connector from "src/pages/Orders/components/Connector";
import StepIcon from "src/pages/Orders/components/StepIcon";
import { CreationSO } from "src/providers/ServiceOrdersStore/types";
import { CustomerProvider } from "src/providers/CustomerStore";
import { CustomerDetailsProvider } from "src/providers/CustomerDetailsStore";
import { AppProvider } from "src/providers/AppProvider";
import Success from "./Success";
import { SODetailsProvider } from "src/providers/SODetailsStore";
import { ServicesProvider } from "src/providers/ServicesStore";
import CreateServiceInBulk from "./components/CreateServiceInBulk";
import Modal from "src/components/Modal";

export const StepsList = () => {
  const { t } = useTranslation("orders");
  return [
    t("labels.steps.partner_info"),
    t("labels.steps.customer_info"),
    t("labels.steps.project_info"),
  ];
};

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <PartnerInfo />;
    case 1:
      return (
        <CustomerProvider>
          <CustomerDetailsProvider>
            <AppProvider>
              <CustomerInfo />
            </AppProvider>
          </CustomerDetailsProvider>
        </CustomerProvider>
      );
    case 2:
      return <ProjectInfo />;
    default:
      return "Unknown step";
  }
}

const CreateSOForm: React.FC = () => {
  const { location, goBack } = useHistory<CreationSO & { customerId: number }>();
  const {
    SOData: { success, createService, servicesCreted, creationSO },
    callSOAPI: { setContextData },
  } = useSOContext();
  const methods = useForm<Partial<CreationSO>>({
    mode: "all",
    defaultValues: {
      warehouseId: location.state?.warehouseId,
      warehouseName: location.state?.warehouseName,
      sellerId: location.state?.sellerId,
      sellerName: location.state?.sellerName,
      customer: location.state?.customerId
        ? { key: location.state.customerId, displayText: location.state?.customerName || "" }
        : {},
    },
  });

  const [activeStep, setActiveStep] = useState(0);
  const steps = StepsList();

  const handleNavigation = useCallback(() => {
    goBack();
  }, []);

  const handleSteps = useCallback(
    (isInc = true) => {
      if (isInc) {
        setActiveStep(activeStep + 1);
      } else {
        if (activeStep === 0) {
          handleNavigation();
        }
        setActiveStep(activeStep - 1);
        methods.reset(creationSO);
      }
    },
    [activeStep]
  );

  const handleClose = () => {
    setContextData("createService", false);
  };

  return (
    <>
      <Container style={{ margin: 0 }} maxWidth={"xl"}>
        <FormProvider {...methods}>
          <PageActions handleSteps={handleSteps} activeStep={activeStep} />
          <Paper>
            <Container maxWidth={"md"}>
              <Stepper activeStep={activeStep} connector={<Connector />}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={StepIcon}>
                      <Typography style={{ textTransform: "uppercase" }}>{label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Container>
            <div>{getStepContent(activeStep)}</div>
          </Paper>
        </FormProvider>
      </Container>
      <Success open={success} />
      <Modal isOpen={createService} maxWidth={servicesCreted ? "sm" : "md"} onClose={handleClose}>
        <CreateServiceInBulk />
      </Modal>
    </>
  );
};

const CreateSO = () => (
  <ServiceOrdersProvider>
    <SODetailsProvider>
      <ServicesProvider>
        <CreateSOForm />
      </ServicesProvider>
    </SODetailsProvider>
  </ServiceOrdersProvider>
);

export default CreateSO;
