import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

function MicroFrontend({ name, host, env, msalInstance }) {
  const [error, setError] = useState(false);
  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;

    const renderMicroFrontend = () => {
      try {
        window[`render${name}`](`${name}-container`, env, msalInstance);
      } catch {
        console.log("ERROR renderMicroFrontend");
      }
    };

    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
      return;
    }
    try {
      fetch(`${host}/asset-manifest.json`)
        .then((res) => res.json())
        .then((manifest) => {
          const script = document.createElement("script");
          script.id = scriptId;
          script.crossOrigin = "";
          script.src = `${host}${manifest.files["main.js"]}`;
          script.onload = () => {
            renderMicroFrontend();
          };
          document.head.appendChild(script);
        })
        .catch(() => setError(true));
    } catch (e) {
      console.log("ERROR");
    }
    return () => (
      <>
        {!!window && name
          ? window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`)
          : null}
        ;
      </>
    );
  }, []);
  if (error)
    return (
      <Typography variant="h3" align="center">
        Something wrong, please try to refresh the page.
      </Typography>
    );
  return <main id={`${name}-container`} />;
}

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
