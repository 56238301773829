import React, { useCallback, useState } from "react";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import { useCommentsContext } from "src/providers/CommentsStore";
import Container from "@material-ui/core/Container/Container";
import { useStyles } from "./styles";
import ShowCommentsInOrder from "src/containers/Comments/components/ShowCommentsInOrder";
import Divider from "@material-ui/core/Divider/Divider";
import Channels from "src/containers/Comments/components/Channels";
import Files from "src/containers/Comments/components/Files";
import ThreadsAndChat from "src/containers/Comments/components/ThreadsAndChat";
import CommentsFileUploadDialog from "src/containers/FileUploadDislog/CommentsFileUploadDialog";
import { FilesProvider } from "src/providers/FilesStore";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";

const Comments = () => {
  const { containerHeader, main, channels } = useStyles();
  const { comments } = useCommentsContext();
  const {
    serviceDetails: { service },
  } = useServiceDetailsContext();
  const [isOpen, setOpen] = useState(!!localStorage.getItem("comments.expanded"));
  const handling = useCallback(() => {
    localStorage.setItem("comments.expanded", !isOpen ? "1" : "");
    setOpen(!isOpen);
  }, [isOpen]);

  if (comments.loading.channels) return <PreloaderLine loading={comments.loading.channels} />;
  return (
    <Container style={{ margin: 0, padding: 0 }} maxWidth={"xl"}>
      <div className={containerHeader}>
        <Divider />
        <ShowCommentsInOrder />
        <Divider />
      </div>
      <FilesProvider serviceOrder={{ id: service?.serviceOrder?.key }} context="service">
        <div className={main}>
          <div className={channels}>
            <Channels isOpen={isOpen} handling={handling} />
            <Divider variant={"middle"} />

            <Files isOpen={isOpen} />
            <CommentsFileUploadDialog />
          </div>
          <Divider orientation="vertical" />
          <ThreadsAndChat />
        </div>
      </FilesProvider>
    </Container>
  );
};

export default Comments;
