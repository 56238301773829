import React, { useState } from "react";
import { requestGet, requestMetaGet, requestPost, requestPut } from "src/utils/crud";
import {
  Context,
  LineItemsState,
  Props,
  LocalModal,
  LineItemsSOReq,
  PartnerInfoReq,
  CustomerInfoReq,
  LineItem,
  PartnerPriceCatalogReq,
  ProductCategoryItem,
  CustomItems,
  LineItemsServiceReq,
  Seller,
} from "./types";
import createCtx from "../../utils/createCtx";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import isEmpty from "lodash/fp/isEmpty";
import { inc, dec, operation } from "src/utils/functions";
import { AxiosPromise } from "axios";

const [useCtx, Provider] = createCtx<Context>();

const initialState: LineItemsState = {
  lineItemsSO: {
    items: [],
    partnerLineItems: [],
    customerLineItems: [],
    partnerSubtotal: {
      subtotal: 0,
      subtotalVat: 0,
      qty: 0,
    },
    customerSubtotal: {
      subtotal: 0,
      subtotalVat: 0,
      qty: 0,
    },
    total: {
      total: 0,
      totalVat: 0,
      totalQty: 0,
    },
    expands: [],
  },
  lineItem: {
    partnerLineItems: [],
    customerLineItems: [],
    partnerSubtotal: {
      subtotal: 0,
      subtotalVat: 0,
      qty: 0,
    },
    customerSubtotal: {
      subtotal: 0,
      subtotalVat: 0,
      qty: 0,
    },
    total: {
      total: 0,
      totalVat: 0,
      totalQty: 0,
    },
  },
  modal: {
    id: 0,
    open: "",
    row: {},
    qty: 0,
    type: "",
  },
  priceCatalog: [],
  lineItemsService: {
    lineItems: {
      partnerLineItems: [],
      customerLineItems: [],
      partnerSubtotal: {
        subtotal: 0,
        subtotalVat: 0,
        qty: 0,
      },
      customerSubtotal: {
        subtotal: 0,
        subtotalVat: 0,
        qty: 0,
      },
      total: {
        total: 0,
        totalVat: 0,
        totalQty: 0,
      },
      service: undefined,
      expands: [],
    },
  },
  partnerInfo: {},
  customerInfo: {},
  editData: {},
  lineItemList: [],
  invoicedLookup: [],
  sellersLookup: [],
  listItems: {},
  targetId: undefined,
  loading: false,
  deleting: false,
  editing: false,
};

function LineItemsProvider(props: Props): React.ReactElement {
  const [lineItemsData, setLineItemsData] = useState(initialState);
  const { partnerInfo, listItems } = lineItemsData;
  const { serviceOrderId, serviceId } = useParams();
  const history = useHistory();
  const location = useLocation<{ parentServiceType: "Services" | "ServiceOrders", serviceId: number }>();
  useEffect(() => {
    if (!!serviceOrderId) {
      fetchingLineItemsSO(Number(serviceOrderId));
      fetchLIInfo(Number(serviceOrderId));
      fetchSellerOptions();
    }
  }, []);

  useEffect(() => {
    if (!!partnerInfo?.partner?.key) {
      fetchPriceCatalog(partnerInfo?.partner?.key);
    }
  }, [partnerInfo]);

  useEffect(() => {
    if (!serviceId) {
      return;
    }

    if (props.forService) {
      fetchingLineItemsService(serviceId).then((res) => {
        if (!!res && res.data.result.service.serviceOrderKey) {
          fetchingLineItemsSO(Number(res.data.result.service.serviceOrderKey));
          fetchLIInfo(Number(res.data.result.service.serviceOrderKey));
        }
      });
    } else {
      fetchingLineItemsService(serviceId);
    }
  }, [serviceId]);

  const fetchLIInfo = async (id: number) => {
    setLineItemsLoading(true);
    return Promise.all([
      await requestGet<PartnerInfoReq>(`ServiceOrders/${id}/PartnerInfo`)
        .then((res) => {
          setLineItemsData((prev) => ({
            ...prev,
            partnerInfo: {
              ...prev.partnerInfo,
              ...res.data.partnerInfo,
            },
            loading: false,
          }));
          return res;
        })
        .catch((err) => console.log(err)),
      await requestGet<CustomerInfoReq>(`ServiceOrders/${id}/customerInfo`)
        .then((res) => {
          setLineItemsData((prev) => ({
            ...prev,
            customerInfo: {
              ...prev.customerInfo,
              ...res.data.customerInfo,
            },
            loading: false,
          }));
          return res;
        })
        .catch((err) => console.log(err)),
    ]);
  };
  const fetchingLineItemsSO = async (id: number) => {
    setLineItemsLoading(true);
    requestGet<LineItemsSOReq>(`lineItems/serviceOrder/${id}`)
      .then((res) => {
        setLineItemsData((prev) => ({
          ...prev,
          lineItemsSO: {
            ...prev.lineItemsSO,
            ...res.data.result,
          },
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };
  const fetchingLineItemsService = (id: number) => {
    setLineItemsLoading(true);
    return requestGet<LineItemsServiceReq>(`LineItems/service/${id}`)
      .then((res) => {
        setLineItemsData((prev) => ({
          ...prev,
          lineItemsService: {
            ...prev.lineItemsService,
            lineItems: {
              ...res.data.result.lineItems,
              service: res.data.result.service,
            },
          },
          listItems: {
            ...prev.listItems,
            [id]:
              !!prev.listItems[id] && prev.listItems[id].length > 0
                ? prev.listItems[id]
                : [
                    ...(res.data.result.lineItems.partnerLineItems as CustomItems[]),
                    ...(res.data.result.lineItems.customerLineItems as CustomItems[]),
                  ],
          },
          loading: false,
        }));
        return res;
      })
      .catch((err) => console.log(err));
  };
  const fetchPriceCatalog = (partnerId: number) => {
    setLineItemsLoading(true);
    requestMetaGet<PartnerPriceCatalogReq>(`Prices/partner/${partnerId}/priceList/list`)
      .then((res) => {
        const data = Array.isArray(res.data) && res.data.length > 0 ? res.data[0] : [];
        setContextData(
          "priceCatalog",
          data.productCategoryItems.map((item: ProductCategoryItem) => ({
            name: item.productCategory.name,
            subRows: item.items.map((elem) => ({
              name: elem.catalogItem.defaultText,
              value: elem.priceInfo.default,
              key: elem.key,
              unit: elem.catalogItem.unit,
              isDecimalQuantity: elem.catalogItem.isDecimalQuantity,
            })),
          }))
        );
        setLineItemsLoading(false);
      })
      .catch((err) => {
        setContextData("error", true);
        setContextData("errorMsg", err);
        console.log(err);
      });
  };

  const setLineItemsLoading = (data: boolean) => {
    setLineItemsData((prev: LineItemsState) => ({
      ...prev,
      loading: data,
    }));
  };
  const handleModal = (data: LocalModal) => {
    setLineItemsData((prev) => ({
      ...prev,
      editData: {},
      modal: {
        ...data,
      },
    }));
  };
  const handleEditDecimal = (data: any, priceListItemType?: string | number) => {
    setLineItemsData((prev) => ({
      ...prev,
      editData: {
        ...lineItemsData.modal.row,
        ...prev.editData,
        qty: operation(0, "custom", data.qty),
        priceListItemType,
      },
    }));
  };
  const handleEdit = (data: any, priceListItemType?: string | number) => {
    const {
      modal: { row },
    } = lineItemsData;

    setLineItemsData((prev) => ({
      ...prev,
      editData: {
        ...row,
        ...prev.editData,
        ...data,
        priceListItemType,
      },
    }));
  };
  const handleDelete = async (ids?: number | string) => {
    if (!ids) return;
    setContextData("deleting", true);
    await requestPost(`LineItems/delete`, [ids])
      .then(() => {
        fetchingLineItemsSO(serviceOrderId);
        setLineItemsData((prev) => ({
          ...prev,
          modal: {
            open: "",
          },
        }));
      })
      .catch((err) => console.log(err));
    setContextData("deleting", false);
  };
  const saveEdit = async () => {
    if (isEmpty(lineItemsData.editData)) return;
    const {
      modal: { row },
    } = lineItemsData;
    setContextData("editing", true);
    const editData = {
      ...lineItemsData.editData,
      qty: lineItemsData.editData.qty
    }
    if(lineItemsData.editData.hasOwnProperty("writeInPrice")){
      editData.writeInUnitId = lineItemsData.editData.unitId
    }
    
    await requestPut<{ LineItem: LineItem }>(`LineItems/${row?.key}`, editData)
      .then((res) => {
        if (props.forService) {
          fetchingLineItemsService(res.data.LineItem.serviceId);
        } else {
          fetchingLineItemsSO(serviceOrderId);
        }
        handleModal({ open: "successful" });
        setLineItemsData((prev) => ({
          ...prev,
          editData: {},
        }));
      })
      .catch((err) => console.log(err));
    setContextData("editing", false);
  };
  const addItemToInvoice = (data: Partial<CustomItems>, id: string) => {
    setLineItemsData((prev) => {
      const fetched: Partial<CustomItems> | undefined = prev.listItems[Number(id)]
        ? prev.listItems[Number(id)].find((ent) => `${ent.priceListItemId}` === `${data.key}`)
        : undefined;
      if (!!fetched) {
        return {
          ...prev,
          listItems: {
            ...prev.listItems,
            [id]: [
              ...prev.listItems[Number(id)].filter(
                (ent) => `${ent.priceListItemId}` !== `${data.key}`
              ),
              {
                ...fetched,
                qty: Number(fetched?.qty) + 1,
              },
            ],
          },
        };
      }
      const exist: CustomItems | undefined = prev.listItems[Number(id)]
        ? prev.listItems[Number(id)].find((ent) => `${ent.key}` === `${data.key}`)
        : undefined;

      if (!!exist) {
        incDecInvoiceItem(exist, "inc")(id);
        return prev;
      }
      const initInvoiceEntity: CustomItems = {
        type: "NEW",
        ...data,
        qty: 1,
        priceListItemId: data.key,
        serviceKey: `${serviceId}`,
        lineItemType: 1,
        sellerId: data.sellerId || prev.partnerInfo?.seller?.key,
      };
      if (!!prev.listItems[Number(id)]) {
        return {
          ...prev,
          listItems: {
            ...prev.listItems,
            [id]: [...prev.listItems[Number(id)], initInvoiceEntity],
          },
        };
      }
      return {
        ...prev,
        listItems: {
          ...prev.listItems,
          [id]: [initInvoiceEntity],
        },
      };
    });
  };
  const updateInvoiceItem = (data: Partial<CustomItems>, value?: {}) => (id: string) => {
    setLineItemsData((prev) => {
      const exist: CustomItems | undefined = !!prev.listItems[Number(id)]
        ? prev.listItems[Number(id)].find((ent) => `${ent.key}` === `${data.key}`)
        : undefined;
      if (!!exist) {
        return {
          ...prev,
          listItems: {
            ...prev.listItems,
            [id]: [
              ...prev.listItems[Number(id)].filter((ent) => `${ent.key}` !== `${data.key}`),
              {
                ...exist,
                serviceId: serviceId,
                id: exist.key,
                type: exist?.type === "NEW" ? exist?.type : "UPDATE",
                sellerId: exist?.sellerId || prev.partnerInfo.seller?.key,
                ...value,
              },
            ],
          },
        };
      }
      return prev;
    });
  };

  const incDecInvoiceItem = (data: Partial<CustomItems>, method: string) => (id: string) => {
    setLineItemsData((prev) => {
      const exist: CustomItems | undefined = prev.listItems[Number(id)].find(
        (ent) => `${ent.key}` === `${data.key}`
      );
      if (!!exist) {
        return {
          ...prev,
          listItems: {
            ...prev.listItems,
            [id]: [
              ...prev.listItems[Number(id)].filter((ent) => `${ent.key}` !== `${data.key}`),
              {
                ...exist,
                id: exist?.type === "NEW" ? exist.key : exist?.id,
                type: exist?.type === "NEW" ? exist?.type : "UPDATE",
                qty: method === "inc" ? inc(Number(exist?.qty), 1) : dec(Number(exist?.qty), 1),
              },
            ],
          },
        };
      }
      return prev;
    });
  };
  const removeInvoice = (data: Partial<CustomItems>) => (id: string) => {
    setLineItemsData((prev) => {
      const exist: CustomItems | undefined = !!prev.listItems[Number(id)]
        ? prev.listItems[Number(id)].find((ent) => `${ent.key}` === `${data.key}`)
        : undefined;
      if (!!exist) {
        return {
          ...prev,
          listItems: {
            ...prev.listItems,
            [id]:
              exist.type === "NEW"
                ? [...prev.listItems[Number(id)].filter((ent) => `${ent.key}` !== `${data.key}`)]
                : [
                    ...prev.listItems[Number(id)].filter((ent) => `${ent.key}` !== `${data.key}`),
                    {
                      ...exist,
                      type: "REMOVE",
                    },
                  ],
          },
        };
      }
      return prev;
    });
  };
  const resetInvoice = (serviceId: string) => {
    setLineItemsData((prev) => ({
      ...prev,
      listItems: {},
    }));
    (async () => {
      await fetchingLineItemsService(Number(serviceId));
    })();
  };
  const prepareForSending = (target: Partial<CustomItems>[]) => {
    return target.map((item: Partial<CustomItems>) => {
      return {
        service: {
          key: item.serviceKey,
        },
        priceListItem: {
          key: item.key,
        },
        id: item.key,
        lineItemType: item.lineItemType,
        priceListItemType: 1,
        lineItemInvoicedId: item.lineItemInvoicedId,
        description: !!item.description ? item.description : "",
        producer: null,
        kickback: "0",
        estimatedAssemblyTime: null,
        qty: item.qty,
        sellerId: item.sellerId,
      };
    });
  };
  const saveLineItems = () => {
    let newLineItems: Partial<CustomItems>[] = [];
    let lineItemsForUpdate: Partial<CustomItems>[] = [];
    let lineItemsForRemove: Partial<CustomItems>[] = [];
    //todo need optimization
    for (const item in listItems) {
      if (listItems.hasOwnProperty(item)) {
        newLineItems.push(...listItems[Number(item)].filter((ent) => ent.type === "NEW"));
        lineItemsForUpdate.push(...listItems[Number(item)].filter((ent) => ent.type === "UPDATE"));
        lineItemsForRemove.push(...listItems[Number(item)].filter((ent) => ent.type === "REMOVE"));
      }
    }

    try {
      let reqPool: AxiosPromise[] = [];
      if (lineItemsForRemove.length > 0) {
        reqPool.push(
          requestPost<CustomItems>(
            `LineItems/delete`,
            lineItemsForRemove.map((ent) => ent.key)
          ).catch((err) => err)
        );
      }
      if (newLineItems.length > 0) {
        reqPool.push(
          requestPost<CustomItems>(`LineItems/list`, {
            lineItemList: prepareForSending(newLineItems),
          }).catch((err) => err)
        );
      }
      if (lineItemsForUpdate.length > 0) {
        reqPool.push(
          requestPut<CustomItems>(`LineItems/list`, {
            lineItemList: prepareForSending(lineItemsForUpdate),
          }).catch((err) => err)
        );
      }

      Promise.all(reqPool).then(async () => {
        await fetchingLineItemsSO(Number(serviceOrderId)).then(() => {
          history.push(`/${location?.state?.parentServiceType || "serviceOrders"}/details/${location?.state?.serviceId || serviceOrderId}/line_items`);
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const setContextData = (key: string, data: any) => {
    setLineItemsData((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const fetchSellerOptions = () => {
    (async () => {
      await requestGet<{ sellers: Seller[] }>(
        `ServiceOrders/${serviceOrderId}/sellers/lookups`
      ).then((res) => {
        setLineItemsData((prev) => ({
          ...prev,
          sellersLookup: [...res.data.sellers],
        }));
      });
    })();
  };
  const callLineItemsAPI = {
    setLineItemsLoading,
    fetchingLineItemsService,
    fetchSellerOptions,
    fetchingLineItemsSO,
    fetchPriceCatalog,
    fetchLIInfo,
    addItemToInvoice,
    updateInvoiceItem,
    incDecInvoiceItem,
    resetInvoice,
    removeInvoice,
    handleModal,
    handleEditDecimal,
    handleEdit,
    saveEdit,
    handleDelete,
    setContextData,
    saveLineItems,
  };

  return (
    <Provider
      value={{
        LIData: lineItemsData,
        callLineItemsAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useLineItemsContext, LineItemsProvider };
