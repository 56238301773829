import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    tableWrapper: {
      "& .MuiTabs-root": {
        height: 30,
      },
      "& .MuiTableHead-root": {
        background: "rgba(43, 89, 195, 0.2)",
      },

      "& .MuiTableCell-head": {
        padding: "8px 16px",
      },
      "& .MuiTableRow-root": {
        borderBottom: "1px solid rgba(43, 89, 195, 0.2)",
      },
      "& .MuiTableCell-root": {
        padding: "8px",
        fontWeight: "normal",
        borderBottom: 0,
        "&:first-child": {
          borderLeft: "1px solid rgba(43, 89, 195, 0.2)",
        },
        "&:last-child": {
          borderRight: "1px solid rgba(43, 89, 195, 0.2)",
        },
      },
      "& .MuiTablePagination-toolbar": {
        padding: "0",
        minHeight: "0",
      },
    },
    tableCell: {
      "&:last-child": {
        borderLeft: "0",
      },
    },
    totalCell: {
      padding: "8px 16px",
    },
  };
});
