import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { CustomerProvider, useCustomerContext } from "src/providers/CustomerStore";
import { Container, Typography, Paper } from "@material-ui/core";
import CustomersList from "src/pages/Customers/components/Customer/CustomersList";
import { useCustomersStyles } from "./styles";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import CreateCustomer from "./CreateCustomer";
import { ServiceOrdersProvider, useSOContext } from "../../providers/ServiceOrdersStore";
import { ServicesProvider } from "src/providers/ServicesStore";
import Pagination from "./components/Pagination";
import PageActions from "./components/PageActions";
import { useAppContext } from "src/providers/AppProvider";
import CreateDeliveryAddress from "./CustomerDetails/components/CreateDeliveryAddress";
import CreateInvoiceAddress from "./CustomerDetails/components/CreateInvoiceAddress";
import { CustomerDetailsProvider } from "src/providers/CustomerDetailsStore";
import { useHistory } from "react-router-dom";
import { Customer } from "src/types";
import Filter from "./components/Filter";
import { useFlag } from "src/utils/hooks/useFlag";
import Drawer from "@material-ui/core/Drawer";
import LinearProgress from "@material-ui/core/LinearProgress";

const Customers: React.FC = () => {
  const { customerData } = useCustomerContext();
  const { SOData } = useSOContext();
  const isEmpty = !customerData.customers.length && !customerData.loading;
  const {
    state: { modals },
  } = useAppContext();
  const { t } = useTranslation("customers");

  const [filtersOpen, , , toggleFilters] = useFlag();

  const classes = useCustomersStyles({ isFilterPanelOpen: filtersOpen, isEmpty });
  return (
    <Container style={{ margin: 0 }} maxWidth={"xl"}>
      <div className={classes.title}>
        <Typography variant="h3">{t("labels.customers_list.title")}</Typography>
      </div>
      <PageActions toggleFilters={toggleFilters} />
      <div style={{ position: "relative" }}>
        <div className={classes.mainWrapper}>
          <Paper>
            <PreloaderLine loading={SOData.loading || customerData.loading} />
            <div className={classes.wrapper}>
              <div className={classes.offSet}>
                <Suspense fallback={<LinearProgress />}>
                  <CustomersList />
                </Suspense>
              </div>
            </div>
            {!isEmpty && <Pagination />}
          </Paper>
        </div>
        {filtersOpen && (
          <Drawer variant="persistent" anchor="right" open={filtersOpen} className={classes.paper}>
            <Filter />
          </Drawer>
        )}
      </div>

      {modals.createInvoiceAddressModal && <CreateInvoiceAddress />}
      {modals.createDeliveryAddressModal && <CreateDeliveryAddress />}
    </Container>
  );
};

const CustomersPage = () => {
  const history = useHistory();
  const onCustomerCreate = (customerData?: Partial<Customer>) => {
    history.push(`/customers/details/${customerData?.key}/summary`);
  };
  return (
    <CustomerProvider>
      <CustomerDetailsProvider>
        <ServiceOrdersProvider>
          <ServicesProvider>
            <>
              <Customers />
              {/* THIS IS REQUIRED, OTHERWISE COMPONENT DESTROYED BY FORCED REFLOW AFTER FETCHING NEW CUSTOMERS */}
              <CreateCustomer onCustomerSubmit={onCustomerCreate} />
            </>
          </ServicesProvider>
        </ServiceOrdersProvider>
      </CustomerDetailsProvider>
    </CustomerProvider>
  );
};
export default CustomersPage;
