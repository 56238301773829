import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { requestGet } from "src/utils/crud";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { ListType } from "src/pages/Orders/components/AutoCompleteWarehouse";

interface Props {
  warehouseId?: string | unknown;
}

const SellersAutoComplete: FC<Props> = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation("orders");
  const data = useFormContext();
  const { errors, register, setValue, trigger } = data;
  const {
    SOData: { creationSO },
    callSOAPI,
  } = useSOContext();
  const defaultValue: ListType = {
    key: creationSO?.sellerId || 0,
    displayText: creationSO?.sellerName || "",
  };
  const warehouseId = data.watch("warehouseId", creationSO.warehouseId);
  const [options, setOptions] = React.useState<ListType[]>([]);
  const loading = open && options.length === 0;
  register("sellerId", { required: true });
  register("sellerName", { required: true });
  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      const res = await requestGet<{ sellers: ListType[] }>(
        `warehouses/${warehouseId}/sellers/lookups`,
        {
          pageSize: 10000,
        }
      );
      if (active) {
        setOptions(res.data.sellers);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, warehouseId]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onReset = () => {
    setValue("sellerId", null);
    setValue("sellerName", null);
    trigger(["sellerId", "sellerName"]);
    callSOAPI.setContextDataBulk({ sellerName: null, sellerId: null });
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.displayText === value.displayText}
      getOptionLabel={(option) => option.displayText}
      onChange={(_, value) => {
        if (!!value) {
          setValue("sellerId", value?.key);
          setValue("sellerName", value?.displayText);
          trigger(["sellerId", "sellerName"]);
        } else {
          onReset();
        }
      }}
      options={options}
      value={{ key: data.watch("sellerId"), displayText: data.watch("sellerName") }}
      defaultValue={defaultValue}
      disabled={!errors.sellerName && !warehouseId}
      renderInput={(params) => (
        <TextField
          label={t("labels.creation.field_desc_seller")}
          {...params}
          required
          error={!!errors.sellerName}
          variant="outlined"
          name="sellerName"
          inputRef={register({
            required: "required",
          })}
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
    />
  );
};

export default SellersAutoComplete;
