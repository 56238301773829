import { useEffect } from "react";
import { CircularProgress, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useFlag } from "src/utils/hooks/useFlag";
import { CarouselItemData } from "./types";
import { getFileTypeIcon } from "./MonteraFileTypeIcons";

type Props = {
  item: CarouselItemData;
};

const CarouselThumbnail = ({ item }: Props) => {
  const [loading, setLoading, setDropLoading] = useFlag(false);
  useEffect(() => {
    if (item.loadingThumbnail) {
      setLoading();
    }
  }, [item.loadingThumbnail]);

  const onLoad = () => {
    setDropLoading();
  };

  const onError = () => {
    setDropLoading();
  };
  const hasPreview = !!item.thumbnail && item.isImage;
  const Icon = () => getFileTypeIcon(item.type, 30, 32, "none");

  const classes = useStyles({ loading, hasPreview });
  return (
    <div className={classes.container}>
      {hasPreview && (
        <img
          className={classes.image}
          src={item.thumbnail}
          alt=""
          onLoad={onLoad}
          onError={onError}
        />
      )}
      {!hasPreview && !loading && (
        <div className={classes.iconContainer}>
          <Icon />
        </div>
      )}
      <div className={classes.spinnerContainer}>
        <CircularProgress className={classes.spinner} size={20} />
      </div>
    </div>
  );
};

type StyleProps = {
  loading: boolean;
  hasPreview: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    position: "relative",
    height: "100%",
    width: "100%",
    cursor: "pointer",
  },
  iconContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: ({ hasPreview }) => (!hasPreview ? "#00000055" : undefined),
  },
  spinnerContainer: {
    display: ({ loading }) => (loading ? "block" : "none"),

    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    objectFit: "contain",
    maxHeight: "100%",
    maxWidth: "100%",
  },
  spinner: {
    color: theme.palette.primary.light,
  },
  image: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    objectFit: "contain",
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

export default CarouselThumbnail;
