import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import { useAppContext } from "../../../../providers/AppProvider";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

const PageActions: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("sellers");
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const handleModal = useCallback(() => {
    toggleModal({
      id: "sellersModal",
      status: true,
    });
  }, []);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <Grid container className={classes.wrapper} direction="row">
      <Button endIcon={<ExpandMoreIcon />}>{t("labels.btn.name_asc")}</Button>
      {isIntegrated && (
        <>
          <Divider orientation="vertical" flexItem />
          <Button startIcon={<AddIcon />} className={classes.text} onClick={handleModal}>
            {t("labels.btn.add")}
          </Button>
        </>
      )}
    </Grid>
  );
};

export default PageActions;
