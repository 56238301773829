import { AssignedTo } from "src/providers/ServicesStore/types";
import { OrdersFilterParams } from "../providers/ServiceOrdersStore/types";
import { Partner as EntityPartner } from "../providers/ServiceDetailsStore/types";

export interface User {
  id: number;
  displayText: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  address: string;
  fullAddress: string;
  phone: string;
  createdDate: string;
  isStaffChatRole: boolean;
  city: {
    key: number;
    displayText: string;
  };
  region: {
    key: number;
    displayText: string;
  };
  zipCode: {
    key: number;
    displayText: string;
  };
  suiteApt: string;
  altPhone: string;
  active: boolean;
  dateOfBirth: string;
  territory: {
    key: number;
    alpha2: string;
    alpha3: string;
    displayText: string;
  };
  country: {
    key: number;
    alpha2: string;
    alpha3: string;
    displayText: string;
  };
  province: {
    key: number;
    displayText: string;
  };
  locale?: {
    englishName: string;
    iso2: string;
    iso3: string;
    key: number;
    displayText: string;
  };
  partner: UserPartner;
  warehouse: LookupObject;
}
export interface FieldListType {
  label: string;
  value: any;
}

export interface Customer {
  key: number;
  isExpanded?: boolean;
  firstName: string;
  lastName: string;
  name?: string;
  email: string;
  mobile: string;
  altPhone?: string;
  notes: string;
  active?: boolean;
  address: string;
  fullAddress?: string;
  city: {
    key?: number;
    displayText?: string;
  };
  province: {
    key?: number;
    displayText?: string;
  };
  zipCode: {
    key?: number;
    displayText?: string;
  };
  country: {
    key?: number;
    displayText?: string;
  };
  createdBy?: {
    key: number;
    displayText: string;
  };
  isBusiness: boolean;
  territoryId?: number;
  createdById?: number;
  createdDate?: Date | string;
  updatedBy?: number;
  updatedDate?: string;
  serviceOrderCount?: number;
  displayText?: string;
  ssn?: string | number;
}

export interface UserReq {
  user: User;
  success: boolean;
}

export interface CustomerReq {
  customers: Customer[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  success: boolean;
  sortBy: string;
  des: boolean;
}

export interface CustomerDetailsReq {
  customer: Partial<Customer>;
  success: boolean;
}

export interface CustomerRequest extends Customer {
  cityId?: number;
  provinceId?: number;
  zipCodeId?: number;
  countryId?: number;
}

export interface Warehouse {
  id?: number;
  key: number;
  displayText: string;
  isExpanded?: boolean;
  name: string;
  partner: Partner;
  contactPerson: string;
  email: string;
  mobilePhone: string;
  active: boolean;
  registeredDate: Date | string;
  isIntegration: boolean;
  serviceOrderCount: number;
}

export interface WarehouseReq {
  warehouses: Warehouse[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  success: boolean;
}

export interface WarehouseDetailsReq {
  warehouse: Partial<Warehouse>;
  success: boolean;
}

export interface UserPartner extends Partial<EntityPartner> {
  key: number;
  name: string;
}

export interface Partner {
  key: number;
  displayText: string;
}
export interface ServicesReq {
  services: Service[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  success: boolean;
}

export interface Service {
  id?: number;
  key: number;
  serviceOrderKey: number;
  name: string;
  projectStatus: AssignedTo;
  serviceType: AssignedTo;
  seller?: AssignedTo;
  registeredDate: Date;
  deliveryAddress: AssignedTo;
  deliveryDate: Date;
  startDate: Date;
  assignedWorkersCount: number;
  assignedTo: AssignedTo;
  statusTypeId: number;
  statusTypeName: string;
  statusMatchingName: string;
  statusMatchingText?: string;
  lineItemCount: string | number;
  externalId?: string | number;
  createdDate?: Date | string;
  description?: string;
  serviceOrderId?: number;
  customer: { key: number; displayText: string; email?: string };
  partner: { key: number; displayText: string; email?: string };
  owner: { key: number; displayText: string; email?: string; phone?: string };
  status?: { key: number; displayText: string; matchingText?: string };
  externalIdType?: number;
  isIntegration?: boolean;
  serviceOrder?: {
    key: number;
    displayText: string;
    statusId?: number;
    statusMatchingName?: string;
  };
  statusColor: string;
  assemblyZone?: AssemblyZone;
  capacity?: number;
}

export interface ServiceCreateReq {
  serviceOrderId: number;
  ownerId: number;
  serviceTypeId: number | string;
  startDate: Date | string;
  deliveryDate: Date | string;
  description?: string;
  capacity: number | null;
}

export interface ServiceOrdersReq {
  serviceOrders: ServiceOrder[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  success: boolean;
  filters: Partial<OrdersFilterParams>;
}

export interface ServiceOrder {
  id: number;
  name: string;
  optionalName?: string;
  statusId?: number;
  statusTypeId?: number;
  statusMatchingName: string;
  createdDate: string;
  startDate?: string;
  deliveryDate?: string;
  partner?: Partner;
  warehouse?: Warehouse;
  seller?: Seller;
  owner?: Owner;
  customer?: Customer;
  deliveryAddress?: string;
  deliveryAddressZipCode?: string;
  childCount?: number;
  childServicesCount?: number;
  completedChildCount?: number;
  externalId?: number;
  billingReference?: number;
  lineItemCount?: number | string;
  reference?: number;
  hasStatusWarning?: boolean;
  description?: string;
  specialInstruction?: string;
  externalIdType?: number;
  isIntegration?: boolean;
  notes?: string;
  servicesCount?: number;
  statusName?: string;
  statusColor?: string;
  invoiceToPartnerName?: string;
  invoiceToCustomerName?: string;
  invoiceToDescription?: string;
  sellerId?: number;
  assemblyZone?: AssemblyZone;
  assemblyZoneId?: number | null;
}

export interface Seller {
  key?: number;
  displayText: string;
}

export interface Owner {
  email?: string;
  phone?: string;
  key?: number;
  displayText?: string;
}

export interface LookupObject {
  key: string;
  displayText: string;
  matchingText?: string;
}

export interface DeliveryAddressReq {
  deliveryAddresses: DeliveryAddress[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  success: boolean;
}

export interface DeliveryAddress {
  key?: number;
  displayText?: string;
  address: string;
  city: {
    key?: number;
    displayText?: string;
  };
  zipCode: {
    key?: number;
    displayText?: string;
  };
  province: {
    key?: number;
    displayText?: string;
  };
  state: {
    key?: number;
    displayText?: string;
  };
  country: {
    key?: number;
    displayText?: string;
  };
  isDefault: boolean;
  isUsed: boolean;
  createdByPartner: boolean;
}

export interface InvoiceAddressReq {
  invoiceAddresses: InvoiceAddress[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  success: boolean;
}

export interface InvoiceAddress {
  key?: number;
  displayText?: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
  country: {
    key?: number;
    displayText?: string;
  };
  isDefault: boolean;
  customerId: number | string;
  name?: string;
  isUsed: boolean;
  createdByPartner: boolean;
}

export interface HouseholdMember {
  key?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  ssn?: string;
  customerId: number;
  randomKey?: string;
}

export interface File {
  key?: number | string;
  id?: number | string;
  fileName: string;
  category: {
    key: number | string;
    displayText: string;
  };
  fileServiceId: number;
  description?: string;
  createdDate?: Date | string;
  createdBy?: string;
  createdByActor?: string;
  createdByOcuupationCategory?: string;
}

type CachingPolicyType = "network" | "cache";
export interface FetchProps {
  cachingPolicy: CachingPolicyType;
}

export enum FILE_PREVIEW_TYPES {
  IMAGE = 1,
  PDF = 2,
}

export interface AssemblyZone {
  key: number;
  displayText?: string;
}

export interface FileQuestionnaireItem {
  id: number;
  name: string;
  serviceId: number;
  categoryId: number;
  category: {
    key: number;
    displayText: string;
  };
  fileServiceId: number;
  description: string;
  createdDate: Date;
  isImage: boolean;
  isPDF: boolean;
  isValid: boolean;
  isSharedToPartner: boolean;
  isSharedToContractor: boolean;
  createdBy: number;
  createdByName: string;
  createdByActor?: string;
  createdByOcuupationCategory?: string;
  partnerId: string | number;
  partnerName: "Montera Norge";
  sendToIntegration: boolean;
}
