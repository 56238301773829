import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SellersIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0379 3.12794L9.09279 0.155392C8.67616 -0.0516346 8.18663 -0.0516346 7.77001 0.155392L5.27856 1.40111L12.1472 4.83542L15.2723 3.27288C15.1996 3.21801 15.1213 3.16937 15.0379 3.12794Z"
      fill="#F4CE04"
    />
    <path
      d="M15.8226 4.11572L8.93115 7.56146V15.9123C8.98601 15.8925 9.03999 15.8694 9.09279 15.843L15.0379 12.8705C15.543 12.6195 15.8625 12.1042 15.8628 11.5402V4.45815C15.8627 4.34127 15.8489 4.22649 15.8226 4.11572Z"
      fill="#F4CE04"
    />
    <path
      d="M7.93115 15.9147V7.56126L1.04015 4.11576C1.01386 4.22652 1.0001 4.34129 1.00004 4.45815V11.5328C0.996104 12.0978 1.31289 12.6162 1.81749 12.8705L7.76258 15.843C7.8176 15.8705 7.8739 15.8944 7.93115 15.9147Z"
      fill="#F4CE04"
    />
    <path
      d="M1.59051 3.2729L8.43136 6.69333L11.0291 5.39444L4.49222 2.12597C4.3926 2.07617 4.31688 1.99759 4.27008 1.90536L1.82492 3.12794C1.74152 3.16938 1.66318 3.21802 1.59051 3.2729Z"
      fill="#F4CE04"
    />
    <path
      d="M15.0379 3.12794L9.0928 0.155392C8.67617 -0.0516346 8.18664 -0.0516346 7.77002 0.155392L5.27857 1.40111L12.1472 4.83542L15.2723 3.27288C15.1996 3.21801 15.1213 3.16937 15.0379 3.12794Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M1.59052 3.2729L8.43137 6.69333L11.0292 5.39444L4.49223 2.12597C4.39261 2.07617 4.31689 1.99759 4.27009 1.90536L1.82493 3.12794C1.74153 3.16938 1.66319 3.21802 1.59052 3.2729Z"
      fill="white"
      fillOpacity="0.5"
    />
  </SvgIcon>
);

export default SellersIcon;
