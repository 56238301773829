import React, { useState, useRef, useCallback, useEffect } from "react";
import { useStyles } from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/styles";
import { AxiosResponse } from "axios";
import { Typography, Button, Grid, CircularProgress,  } from "@material-ui/core";
import { requestPut } from "src/utils/crud";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/SuccessNotification";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import TextArea from "src/components/TextArea";

const EditDescription: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { editDescriptionSO },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const {
    serviceOrderDetails: { serviceOrder },
    callAPI: { setContextData },
  } = useSODetailsContext();

  const [description, setDescription] = useState(serviceOrder.description);

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "editDescriptionSO", status: false });
    setSubmitted(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<any>) => {
    setDescription(event.target.value);
  };

  const submitDescription = async (data: any) => {
    try {
      const res = await requestPut(`ServiceOrders/${serviceOrder.id}/description`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const submitObj = { description: description };
    const descriptionRes = await submitDescription(submitObj);
    if (descriptionRes && (descriptionRes as AxiosResponse)?.status === 200) {
      setSubmitted(true);
      const newServiceOrder = {
        ...serviceOrder,
        description: description,
      };
      setContextData("serviceOrder", newServiceOrder);
    }
    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    serviceOrder && setDescription(serviceOrder.description);
  }, [serviceOrder]);

  return (
    <>
      <Modal isOpen={editDescriptionSO} onClose={handleModalClose}>
        <Grid container justify="center" style={{ width: "100%" }}>
          <Grid item style={{ width: "100%" }}>
            {!submitted && (
              <form onSubmit={handleSubmit}>
                <Typography variant="h3">{t("labels.details.edit_description")}</Typography>
                <div className={classes.fieldGroup}>
                  <TextArea 
                    value={description} 
                    name="notes" 
                    onChange={(data => {
                      handleChange(data);
                    })}
                    label={t("labels.details.description")}
                    placeholder={t("labels.details.description")}
                  />
                </div>
                <div className={classes.btnGroup}>
                  <Button className={classes.submitBtn} type="submit" ref={submitButtonRef}>
                    {loading ? (
                      <CircularProgress size={18} color={"secondary"} />
                    ) : (
                      t("labels.common.save")
                    )}
                  </Button>
                  <Button onClick={handleModalClose} className={classes.cancelBtn}>
                    {t("labels.common.cancel")}
                  </Button>
                </div>
              </form>
            )}
            {submitted && (
              <SuccessNotification
                message={t("labels.common.save_success")}
                onClose={handleModalClose}
              />
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default EditDescription;
