import Axios from "axios";
import { getFileData } from "src/utils/crud";

export const getPreview = async (fileServiceId: number) => {
  // TODO: Max, here should be optimized image call
  const blob = await getFileData("files", fileServiceId);
  return blob;
};

export const getContent = async (fileServiceId: number) => {
  const blob = await getFileData("files", fileServiceId);
  return blob;
};

export const getThumbnail = async (_fileServiceId: number) => {
  // TODO: Max, here should be image thumbnail call
  const blob = await Axios.get("https://picsum.photos/id/1018/250/150/", {
    responseType: "blob",
  }).then((x) => x.data);
  return blob;
};
