import React, { FunctionComponent, useState } from "react";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CustomizedMenus from "src/components/Menu";
import { useStyles } from "./styles";
import ChevronNavBtn from "../../components/NavBar/ChevronNavBtn";
import NavBar from "../../components/NavBar";
import LogoutIcon from "../../assets/icons/MainNavListIcons/LogoutIcon";
import CustomBreadcrumbs from "src/components/CustomBreadcrumbs";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-common";
import Preloader from "src/components/Preloader";
import { useAppContext } from "src/providers/AppProvider";
import { localStore } from "src/utils/functions";
import LocaleSwitcher from "src/components/LocaleSwitcher";
import NavigationLogoIcon from "src/assets/icons/NavigationLogoIcon";
import { HandleSystemError } from "src/components/SystemError/HandleSystemError";

const MainWrapper: FunctionComponent = ({ children }) => {
  const { instance } = useMsal();
  const { callAppAPI, state, fullScreenMode } = useAppContext();
  const accounts: AccountInfo[] = instance.getAllAccounts();
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    instance.logout();
    localStore.removeItem("user");
  };

  React.useEffect(() => {
    callAppAPI.initialCall();
  }, []);

  if (state.authError) {
    return (
      <div className={classes.errorContainer}>
        <NavigationLogoIcon />
        <Typography variant="h3" component="h3" align="center">
          Access Denied
        </Typography>
        <Typography variant="h4" component="h4" align="center">
          Contact MonteraPlus administrator
        </Typography>
        <Button variant="outlined" size="large" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    );
  }

  return (
    <HandleSystemError>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
            [classes.hidden]: fullScreenMode,
          })}
        >
          <Toolbar className={classes.toolbar}>
            <ChevronNavBtn
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
            />
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <CustomBreadcrumbs />
            </Typography>
            {state.locales.length > 0 ? (
              state.loading ? (
                <Preloader is />
              ) : (
                <LocaleSwitcher />
              )
            ) : null}
            <CustomizedMenus
              menuLinkList={[
                {
                  title: "Logout",
                  icon: <LogoutIcon />,
                  handler: handleLogout,
                },
              ]}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {accounts[0]?.username}
              </Typography>
            </CustomizedMenus>
          </Toolbar>
        </AppBar>
        <NavBar open={open} />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={"xl"} className={classes.container}>
            <Grid container spacing={3}>
              {children}
            </Grid>
          </Container>
        </main>
      </div>
    </HandleSystemError>
  );
};
export default MainWrapper;
