import React, { FC, useCallback } from "react";
import { Customer } from "src/types";
import CommonList from "src/components/CommonList";
import CustomerFieldList from "src/pages/Customers/components/Customer/CustomerFieldList";
import { useCustomerContext } from "src/providers/CustomerStore";
import { makeStyles, Typography } from "@material-ui/core";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import SOList from "src/pages/Customers/components/SO/SOList";
import SearchIcon from "src/assets/icons/SearchIcon";

export const useStyles = makeStyles(() => ({
  detailsRoot: {
    flexDirection: "column",
  },
  wrapper: {
    display: "grid",
    gridTemplateRows: "auto auto",
    gridGap: 12,
    alignItems: "center",
    justifyItems: "center",
    paddingTop: 32,
  },
  icon: {
    fontSize: 72,
  },
}));

const CustomersList: FC = () => {
  const { customerData } = useCustomerContext();
  const { callSOAPI } = useSOContext();
  const classes = useStyles();

  const handlerServices = useCallback(
    () => ({ key }: Customer) => callSOAPI.fetchingServices(key, "Customers", 999),
    []
  );

  if (customerData.customers.length === 0 && !customerData.loading) {
    return (
      <div className={classes.wrapper}>
        <SearchIcon className={classes.icon} />
        <Typography variant="h3">No Search results for your request</Typography>
      </div>
    );
  }

  return (
    <CommonList<Customer>
      listEntities={customerData.customers}
      handlerOnOpen={handlerServices}
      entityFields={
        (ent) => 
          <CustomerFieldList data={ent} />
      }
      entityDetails={
        (ent) => 
          <SOList id={ent.key} />
      }
      classes={{ detailsRoot: classes.detailsRoot }}
    />
  );
};

export default CustomersList;
