import React, { useState, useRef } from "react";
import Autocomplete, { AutocompleteInputChangeReason } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { requestGet } from "src/utils/crud";
import { LookupObject } from "src/types";
import { useStyles } from "./styles";
import { Box, Typography, Grid, Chip } from "@material-ui/core";
import SearchIcon from "src/assets/icons/SearchIcon";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

type ServiceOrdersResponse = {
  serviceOrders: LookupObject[];
  success: boolean;
};

type Props = {
  label: string;
};
const ServiceOrderIdInput: React.FC<Props> = ({ label }: Props) => {
  //HELPERS
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { setValue, register, watch } = useFormContext();
  const { t } = useTranslation("common");
  // DATA
  const [data, setData] = useState<LookupObject[]>([]);

  const [loading, setLoading] = useState(false);

  const timeOut: { current: NodeJS.Timeout | null } = useRef(null);
  const onInputChange = (value: string, reason: AutocompleteInputChangeReason) => {
    clearTimeout(timeOut.current as NodeJS.Timeout);

    if (reason === "reset") {
      return;
    }
    if (!value) {
      setData([]);
      return;
    }
    timeOut.current = setTimeout(() => {
      getData(value.split("-").join(""));
    }, 500);
  };

  React.useEffect(() => {
    register({ name: "ids" });
  }, [open]);

  const getData = async (displayText?: string) => {
    setLoading(true);
    requestGet<ServiceOrdersResponse>(
      `ServiceOrders/lookups/fullname?displayText=${displayText || ""}`,
      ""
    )
      .then((responseData) => {
        setData(responseData.data.serviceOrders);
      })
      .finally(() => setLoading(false));
  };

  const onChangeHandler = (selectedOption: LookupObject[]) => {
    setValue("ids", selectedOption);
  };

  const onOpenHandler = () => {
    setOpen(true);
  };
  const onCloseHandler = () => {
    setOpen(false);
    if (!watch("ids")) {
      setData([]);
    }
  };
  const noResults = () => {
    return (
      <>
        <Box textAlign="center">
          <SearchIcon className={classes.icon} />
        </Box>
        <Typography paragraph={true} display="block" align="center">
          {t("labels.no_data")}
        </Typography>
      </>
    );
  };
  const preloader = () => (
    <Grid container alignItems="center">
      <CircularProgress
        className="dataLoading"
        color="primary"
        size={20}
        style={{ marginRight: 8 }}
      />
      <Typography>{t("labels.loading")}</Typography>
    </Grid>
  );

  let filterOptions = (items: LookupObject[]) => items;

  return (
    <Autocomplete<LookupObject, true, false, false>
      multiple={true}
      options={data}
      value={watch("ids") || []}
      fullWidth
      limitTags={2}
      open={open}
      classes={{
        root: classes.rootWithCheckbox,
        paper: classes.paperWithCheckbox,
        option: classes.optionWithCheckbox,
        popupIndicator: classes.popupIndicator,
        clearIndicator: classes.clearIndicator,
      }}
      onOpen={onOpenHandler}
      onClose={onCloseHandler}
      onChange={(_, value) => onChangeHandler(value)}
      onInputChange={(_, value, reason) => onInputChange(value, reason)}
      getOptionLabel={(option) => option.displayText || ""}
      noOptionsText={noResults()}
      loading={loading}
      loadingText={preloader()}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color="primary"
            label={option.displayText}
            classes={{ root: classes.chip }}
            {...getTagProps({ index })}
          />
        ))
      }
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={t("labels.autocomplete_start_typing")}
        />
      )}
    />
  );
};

export default ServiceOrderIdInput;
