import { User } from "src/types";
import { storageFactory } from "./storageFactory";

const localStore = storageFactory(() => localStorage);
const sessionStore = storageFactory(() => sessionStorage);

export const operation = (count: number, behaviour: string, customValue: string) => {
  if (behaviour === "custom") {
    const lastChar = customValue.slice(-1);
    const regExp = new RegExp(/^[0-9.,]+$/, "g");
    !regExp.test(lastChar) && (customValue = customValue.substring(0, customValue.length - 1));
    // ///customValue = customValue.replaceAll(/[0-9]{1,2}[.,]\d{1-2}/, ""); // allow only number and dot
    const decimalNumber = customValue.split(/[,.]/); // split by dots or comma
    if (decimalNumber.length >= 2) {
      // if dots/commas more then 1
      customValue = `${decimalNumber[0]}.${decimalNumber[1].slice(0, 2)}`; // replace dot with comma and return only 2 decimal numbers
    }
    return customValue || " "; // return value
  }
  if (behaviour === "inc") {
    return Math.trunc(Number(count)) + 1;
  }
  if (count === 1) return count; //position is important

  if (behaviour === "dec") {
    return Math.trunc(Number(count)) - 1;
  }
  return count;
};

const inc = (arg1: number, arg2: number) => arg1 + arg2;
const dec = (arg1: number, arg2: number) => {
  if (arg1 <= 1) return 1;
  return arg1 - arg2;
};

export function descendingComparator(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}
export const stableSort = (array: any, comparator: any): any => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
};
export { inc, dec, localStore, sessionStore };

export function parsePhoneNumberBeforeSubmit(phoneNumber: string | undefined): string | undefined {
  return phoneNumber?.replace(/(\s)|\+/g, "");
}

export const setFeatureFlags = (user: User) => {
  const featureFlags = {
    en: false,
    dk: false,
    no: false,
  };
  switch (user.territory.alpha2) {
    case "en":
      // additional conditions
      featureFlags.en = true;
      break;
    case "dk":
      // additional conditions
      featureFlags.dk = true;
      break;
    case "no":
      // additional conditions
      featureFlags.no = true;
      break;
    default:
      break;
  }
  return featureFlags;
};

export const assemblyZoneAvailable = (isIntegration: boolean, partnerName: string) => {
  if (partnerName === "Elkjøp Norge") {
    return true;
  }
  if (isIntegration && partnerName === "IKEA (MIS)") {
    return true;
  }

  return false;
};

export const behaviorByStatus = (status?: string, additionalStatus?: string[]) => {
  if (!status) return false;
  const baseStatus = ["INVOICED", "COMPLETED"];
  const Statuses =
    !!additionalStatus && additionalStatus?.length > 0
      ? [...baseStatus, ...additionalStatus]
      : baseStatus;

  return Statuses.includes(status.toLocaleUpperCase());
};

export const isIncompleteOrNewOrMissingInformation = (status?: string) => {
  if (!status) return false;
  return ["INCOMPLETE", "NEW", "MISSINGINFORMATION"].includes(status.toLocaleUpperCase());
};
