import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const HouseColorIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M40 9.27124L10 29.6375V76.25H70V29.6375L40 9.27124Z" fill="#F2F6F9" />
    <path d="M21.25 51.25H35V76.25H21.25V51.25Z" fill="#FBEECA" />
    <path d="M48.75 62.5H66.25V67.5H48.75V62.5Z" fill="#1B3A7F" />
    <path d="M45 76.25V58.75L47.5 55L50 58.75V76.25H45Z" fill="#085C9C" />
    <path d="M55 76.25V58.75L57.5 55L60 58.75V76.25H55Z" fill="#085C9C" />
    <path d="M65 76.25V58.75L67.5 55L70 58.75V76.25H65Z" fill="#085C9C" />
    <path
      d="M43.75 40H36.25C34.1788 40 32.5 38.3212 32.5 36.25V28.75C32.5 26.6788 34.1788 25 36.25 25H43.75C45.8212 25 47.5 26.6788 47.5 28.75V36.25C47.5 38.3212 45.8212 40 43.75 40Z"
      fill="#C5DBF2"
    />
    <path
      d="M47.5 31.25H41.25V25H38.75V31.25H32.5V33.75H38.75V40H41.25V33.75H47.5V31.25Z"
      fill="#085C9C"
    />
    <path d="M28.75 62.5H31.25V65H28.75V62.5Z" fill="#085C9C" />
    <path d="M8.75 66.25H11.25V76.25H8.75V66.25Z" fill="#085C9C" />
    <path
      d="M16.25 61.2503C16.25 65.7378 13.4513 68.7503 10 68.7503C6.54875 68.7503 3.75 65.7378 3.75 61.2503C3.75 58.3203 6.41375 51.2253 8.26375 46.6516C8.89625 45.0878 11.105 45.0878 11.7375 46.6516C13.5863 51.2253 16.25 58.3203 16.25 61.2503Z"
      fill="#D0EACC"
    />
    <path d="M2.5 75H77.5V77.5H2.5V75Z" fill="#085C9C" />
    <path
      d="M73.5967 32.0686L40.0004 9.27114L6.40418 32.0686L3.59668 27.9311L40.0004 3.22864L76.4042 27.9311L73.5967 32.0686Z"
      fill="#FBEECA"
    />
  </SvgIcon>
);
export default HouseColorIcon;
