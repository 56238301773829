import React, { PropsWithChildren } from "react";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTable, useExpanded, useSortBy } from "react-table";
import SortedIco from "src/assets/icons/SortedIcon";
import { useStyles } from "./style";
import clsx from "clsx";

interface Props<T extends object = {}> {
  data?: T[];
  styleOverlay?: string;
  columnsData?: any;
  rowActions?: (data: Partial<T>) => JSX.Element;
}

function MainTable<D extends object = {}>({
  data = [],
  columnsData = [],
  styleOverlay,
}: PropsWithChildren<Props<D>>) {
  const { tableWrapper } = useStyles();
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    toggleAllRowsExpanded,
    expandAllSubComponents,
    isAllRowsExpanded,
  } = useTable(
    {
      columns: columnsData,
      data,
      initialState: {
        sortBy: [
          {
            id: "unit_price",
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    useExpanded
  );
  React.useEffect(() => {
    toggleAllRowsExpanded(expandAllSubComponents); // NOTE expandAllSubComponents is a boolean I use to toggle open/closed
  }, [expandAllSubComponents, toggleAllRowsExpanded]);

  return (
    <MaUTable {...getTableProps()} className={clsx(tableWrapper, !!styleOverlay && styleOverlay)}>
      <TableHead>
        {headerGroups.map((headerGroup) => {
          return (
            <TableRow {...headerGroup.getHeaderGroupProps()} style={{ width: headerGroup.width }}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span>
                      {column.render("Header", {
                        getToggleAllRowsExpanded: () =>
                          toggleAllRowsExpanded(expandAllSubComponents),
                        isAllRowsExpanded: isAllRowsExpanded,
                      })}
                    </span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortedIco fontSize="small" />
                      ) : (
                        <SortedIco fontSize="small" style={{ transform: "rotate(180deg)" }} />
                      )
                    ) : (
                      ""
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow
              hover
              {...row.getRowProps()}
              onClick={() => {
                row.toggleRowExpanded();
              }}
            >
              {row.cells.map((cell) => {
                return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

export default MainTable;
