import React, { useState } from "react";
import { Context, AnalyticsState, Props } from "./types";
import createCtx from "src/utils/createCtx";

const [useCtx, Provider] = createCtx<Context>();

const initialState: AnalyticsState = {
  loading: false,
  pageBarOpen: true,
  selectedMenuIndex: 1,
  partnerManagerMenuListItems: [
    {
      key: 1,
      transKey: "labels.main.sold_by_warehouse",
      transNS: "analytics",
    },
    {
      key: 2,
      transKey: "labels.main.sold_by_warehouse_seller",
      transNS: "analytics",
    },
  ],
};

function AnalyticsProvider(props: Props): React.ReactElement {
  const [analyticsStore, setAnalyticsStore] = useState<AnalyticsState>(initialState);

  const setContextData = (key: keyof AnalyticsState, data: any) => {
    setAnalyticsStore((prev: any) => ({
      ...prev,
      [key]: data,
    }));
  };

  const callAPI = {
    setContextData,
  };

  return (
    <Provider
      value={{
        analyticsData: analyticsStore,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useAnalyticsContext, AnalyticsProvider };
