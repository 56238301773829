import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import SuccessIcon from "./icons/SuccessIcon";
import AddAddressIcon from "./icons/addAddressIcon";
import AddHouseholdIcon from "./icons/addHouseholdIcon";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { User } from "src/types";
interface Props {
  completeProfile: () => void;
  handleModalClose: () => void;
  isBusiness: boolean;
  user: Partial<User>;
}

const SuccessCustomerCreate: React.FC<Props> = ({ completeProfile, handleModalClose, isBusiness, user }) => {
  const classes = useStyles();
  const { t } = useTranslation("customers");



  return (
    <Grid container justify="center">
      <Grid item>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">
            {t("labels.create_customer.customer_form_success.title")}
          </Typography>
        </div>

        <div>
          <Typography variant="body1" className={classes.subtitle}>
            {t("labels.create_customer.customer_form_success.tips")}
          </Typography>
          <span className={classes.itemWrapper}>
            <AddAddressIcon />
            <Typography variant="subtitle2" className={classes.primaryText}>
              {t("labels.create_customer.customer_form_success.tip1")}
            </Typography>
          </span>
          {!isBusiness && (user.territory?.alpha2 === "sv" || user.territory?.alpha2 === "se") && (
            <span className={classes.itemWrapper}>
              <AddHouseholdIcon />
              <Typography variant="subtitle2" className={classes.primaryText}>
                {t("labels.create_customer.customer_form_success.tip2")}
              </Typography>
            </span>
          )}
          <Typography variant="body1" className={classes.secondaryText}>
            {t("labels.create_customer.customer_form_success.optional")}
          </Typography>
        </div>
        <div className={classes.btnGroup}>
          <Button onClick={completeProfile} className={classes.submitBtn} type="submit">
            {t("labels.create_customer.customer_form_success.complete")}
          </Button>
          <Button onClick={handleModalClose} className={classes.cancelBtn}>
            {t("labels.create_customer.customer_form_success.cancel")}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessCustomerCreate;
