import { makeStyles } from "@material-ui/core/styles";

export const MessageListItemStyle = makeStyles((theme) => ({
  time: {
    paddingLeft: 8,
    fontSize: "12px",
    color: "rgba(20, 30, 48, 0.5)",
  },
  root: {
    padding: `0px ${theme.spacing(1)}px`,
    display: "flex",
    flexDirection: "column",
    marginTop: 2,
    "&:hover": {
      backgroundColor: "rgba(20, 30, 48, 0.05)",
    },
  },
  readedMsg: {
    backgroundColor: "#FBEECA",
  },
  sharedWrapper: {
    display: "flex",
    alignItems: "center",
  },
  sharedDivider: {
    borderLeft: `2px solid ${theme.palette.brandColors.dark}`,
    paddingLeft: theme.spacing(1),
  },
}));
