import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { Props } from "./types";
import { format as dateFns, addMinutes } from "date-fns";

const MonteraDateDisplay: FC<Props> = ({
  value,
  label,
  variant = "h6",
  format = "L",
  disableTimezoneOffset = false,
}: Props) => {
  const offset = disableTimezoneOffset ? new Date(value).getTimezoneOffset() : 0;
  return (
    <span>
      {label && (
        <Typography variant={variant} style={{ margin: "10px" }}>
          {label}
        </Typography>
      )}
      {dateFns(addMinutes(new Date(value), offset), format)}
    </span>
  );
};

export default MonteraDateDisplay;
