import { withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";

const Connector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

export default Connector;
