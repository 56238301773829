import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const MoreIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M6.99922 7.9999C6.99922 8.88356 7.71556 9.5999 8.59922 9.5999C9.48287 9.5999 10.1992 8.88356 10.1992 7.9999C10.1992 7.11625 9.48287 6.3999 8.59922 6.3999C7.71556 6.3999 6.99922 7.11625 6.99922 7.9999Z"
        fill="#141E30"
      />
      <path
        d="M13.3996 7.9999C13.3996 8.88356 14.116 9.5999 14.9996 9.5999C15.8833 9.5999 16.5996 8.88356 16.5996 7.9999C16.5996 7.11625 15.8833 6.3999 14.9996 6.3999C14.116 6.3999 13.3996 7.11625 13.3996 7.9999Z"
        fill="#141E30"
      />
      <path
        d="M0.598828 7.9999C0.598828 8.88356 1.31517 9.5999 2.19883 9.5999C3.08248 9.5999 3.79883 8.88356 3.79883 7.9999C3.79883 7.11625 3.08248 6.3999 2.19883 6.3999C1.31517 6.3999 0.598828 7.11625 0.598828 7.9999Z"
        fill="#141E30"
      />
    </g>
  </SvgIcon>
);
export default MoreIcon;
