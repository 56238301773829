import React, { FC } from "react";
import file from "../../images/file.svg";
import { useCStyles } from "../Channels/style";
import ChannelItem from "../Channels/ChannelItem";
import Avatar from "@material-ui/core/Avatar";

interface Props {
  isOpen: boolean;
}

const Files: FC<Props> = ({ isOpen }) => {
  const { wrapper, icons } = useCStyles({ isOpen });
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className={wrapper} onClick={onClick}>
      <ChannelItem
        id={6}
        icon={<Avatar variant="square" src={file} className={icons} />}
        isOpen={isOpen}
        displayText="Files"
      />
    </div>
  );
};

export default Files;
