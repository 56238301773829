import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem/ListItem";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      "& > div": {
        width: "100%",
      },
    },
    detailsRoot: {
      padding: 0,
      marginLeft: 0,
    },

    wrapper: {
      padding: "16 0",
    },
    listItem: {
      "& .MuiList-padding": {
        padding: 0,
      },
    },
  })
);
interface Props {
  entityFields: JSX.Element;
  entityDetails?: JSX.Element;
  classes?: Partial<ReturnType<typeof useStyles>>;
}
const CollapsibleRow: React.FC<Props> = ({ entityFields, entityDetails, classes }) => {
  classes = useStyles({ classes });
  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div className={classes.wrapper}>
      <Divider variant={"fullWidth"} light />
      <ListItem button onClick={handleChange} className={classes.listItem}>
        {checked ? <ExpandLess color={"disabled"} /> : <ExpandMore color={"disabled"} />}
        {entityFields}
      </ListItem>
      <Divider variant={"fullWidth"} light />
      <div className={classes.container}>
        <Collapse in={checked}>
          <AccordionDetails className={classes.detailsRoot}>{entityDetails}</AccordionDetails>
        </Collapse>
      </div>
    </div>
  );
};

export default CollapsibleRow;
