import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    actionMenu: {
      padding: 0,
      minWidth: 160,
    },
    listItemTypography: {
      marginLeft: 16,
    },
  };
});
