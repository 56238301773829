import React, { useState } from "react";
import {
  requestGet,
  requestPost,
  requestPut,
  requestDelete,
  deleteFileFromService,
} from "src/utils/crud";
import {
  SODetailsState,
  ContextDetails,
  Props,
  RelatedServicesState,
  InfoType,
  RelatedServicesReq,
  FilesState,
  FilesReq,
  RelatedServiceOrders,
  RelatedServiceOrdersReq,
} from "./types";
import createCtx from "../../utils/createCtx";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SODetailsType } from "src/pages/ServiceOrdersDetails/type";
import { ServiceCreateReq, ServiceOrder } from "src/types";
import isEmpty from "lodash/fp/isEmpty";
import { Services } from "./types";
import { AxiosResponse } from "axios";
import addMonths from "date-fns/addMonths";
import { getCarouseFiles } from "src/utils/files";
const [useCtx, Provider] = createCtx<ContextDetails>();

const initialRelatedServices: RelatedServicesState = {
  services: [],
  currentPage: 1,
  pageCount: 0,
  pageSize: 100,
  sortBy: "name",
  des: true,
  totalCount: 0,
  loading: true,
  success: false,
};

const initialSelectedSO: SODetailsState = {
  serviceOrder: {
    id: 0,
    name: "",
    createdDate: "",
    statusMatchingName: "",
  },
  partnerInfo: {
    partner: {},
    seller: {
      name: "empty",
      email: "empty",
      phone: "empty",
    },
    warehouse: {
      name: "empty",
    },
  },
  customerInfo: {
    customer: {},
    deliveryAddress: {
      key: undefined,
    },
  },
  invoiceInfo: {
    invoiceAddress: {
      name: "empty",
    },
    invoiceTo: {},
    invoiceToGroup: "",
  },
  loading: true,
  updated: false,
  success: false,
};

const initialFiles: FilesState = {
  files: {
    serviceOrderFiles: [],
    serviceFiles: [],
    carouselFiles: [],
    serviceOrderId: 0,
    serviceOrderName: "",
  },
  currentPage: 1,
  pageCount: 0,
  pageSize: 100,
  totalCount: 0,
  loading: true,
  success: false,
  sortBy: "fileName",
  des: true,
};
const initialRelatedServiceOrders: RelatedServiceOrders = {
  relatedOrders: [],
  currentPage: 1,
  pageCount: 0,
  pageSize: 100,
  totalCount: 0,
  loading: true,
  success: false,
  sortBy: "fileName",
  des: true,
};
function SODetailsProvider(props: Props): React.ReactElement {
  const [SODetailsState, setSODetails] = useState(initialSelectedSO);
  const [relatedServicesState, setRelatedServices] = useState(initialRelatedServices);
  const [relatedServiceOrdersState, setRelatedServiceOrders] = useState(
    initialRelatedServiceOrders
  );
  const [filesState, setFilesState] = useState(initialFiles);
  const { serviceOrderId } = useParams<SODetailsType>();
  useEffect(() => {
    if (!!serviceOrderId) {
      getSODetails(serviceOrderId);
      getSOInfo(serviceOrderId, "partnerInfo");
      getSOInfo(serviceOrderId, "customerInfo");
      getSOInfo(serviceOrderId, "invoiceInfo");
    }
  }, []);

  useEffect(() => {
    if (!!serviceOrderId) {
      getRelatedServices(serviceOrderId);
    }
  }, [
    serviceOrderId,
    relatedServicesState.pageSize,
    relatedServicesState.currentPage,
    relatedServicesState.sortBy,
    relatedServicesState.des,
  ]);

  useEffect(() => {
    if (!!serviceOrderId) {
      getRelatedServiceOrders(serviceOrderId);
    }
  }, [
    serviceOrderId,
    relatedServiceOrdersState.pageSize,
    relatedServiceOrdersState.currentPage,
    relatedServiceOrdersState.sortBy,
    relatedServiceOrdersState.des,
  ]);
  useEffect(() => {
    if (!!serviceOrderId) {
      getFiles(serviceOrderId);
    }
  }, [
    serviceOrderId,
    filesState.pageSize,
    filesState.currentPage,
    filesState.sortBy,
    filesState.des,
  ]);

  const getRelatedServices = async (key: number | string) => {
    setROLoading(true);
    return requestGet<RelatedServicesReq>(`ServiceOrders/${key}/services`, {
      page: relatedServicesState.currentPage,
      pageSize: relatedServicesState.pageSize,
      sortBy: relatedServicesState.sortBy,
      des: relatedServicesState.des,
    })
      .then((res) => {
        setRelatedServices((prev) => ({
          ...prev,
          ...res.data,
          services: [...res.data.services],
          totalCount: res.data.totalCount,
          pageSize: prev.pageSize,
          currentPage: prev.currentPage,
          loading: false,
        }));
        return res;
      })
      .catch((err) => console.log(err));
  };

  const getRelatedServiceOrders = async (key: number | string) => {
    setROLoading(true);
    return requestGet<RelatedServiceOrdersReq>(`ServiceOrders/${key}/relatedOrders`, {
      page: relatedServicesState.currentPage,
      pageSize: relatedServicesState.pageSize,
      sortBy: relatedServicesState.sortBy,
      des: relatedServicesState.des,
    })
      .then((res) => {
        setRelatedServiceOrders((prev) => ({
          ...prev,
          ...res.data,
          totalCount: res.data.totalCount,
          pageSize: prev.pageSize,
          currentPage: prev.currentPage,
          loading: false,
        }));
        return res;
      })
      .catch((err) => console.log(err));
  };

  const getSODetails = (key: number | string) => {
    requestGet(`serviceOrders/${key}`)
      .then((res) => {
        setSODetails((prev) => ({
          ...prev,
          ...res.data,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const creatService = async (service: Partial<ServiceCreateReq>) => {
    return requestPost(`Services`, service).then(async () => {
      if (!!serviceOrderId) {
        return getRelatedServices(serviceOrderId);
      }
    });
  };
  const createServiceInBulk = async (services: Services[]): Promise<boolean> => {
    const t = await requestPost(`Services/Bulk`, { services: services })
      .then(() => true)
      .catch(() => false);
    return Boolean(t);
  };
  const updateSOdata = async (data: ServiceOrder) => {
    if (isEmpty(data)) return;
    setSOLoading(true);
    await requestPut<ServiceOrder>(`ServiceOrders/${data.id}`, data).then((res) => {
      setSODetails((prev) => ({
        ...prev,
        ...res.data,
        loading: false,
        updated: true,
      }));
    });
  };

  const getSOInfo = (key: number | string, infoType: InfoType) => {
    requestGet(`serviceOrders/${key}/${infoType}`)
      .then((res) => {
        setSODetails((prev) => ({
          ...prev,
          ...res.data,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const getFiles = async (key: number | string): Promise<void | AxiosResponse<FilesReq>> => {
    setFilesLoading(true);
    return await requestGet<FilesReq>(`Files/serviceOrder/${key}`, {
      page: filesState.currentPage,
      pageSize: filesState.pageSize,
      sortBy: filesState.sortBy,
      des: filesState.des,
    })
      .then((res) => {
        setFilesState((prev) => ({
          ...res.data,
          files: {
            ...res.data.files,
            carouselFiles: getCarouseFiles(res.data),
          },
          totalCount:
            res.data.files.serviceOrderFiles.length +
            res.data.files.serviceFiles
              .map((el) => el.serviceFiles.length)
              .reduce((a, b) => a + b, 0),
          pageSize: prev.pageSize,
          currentPage: prev.currentPage,
          loading: false,
        }));
        return res;
      })
      .catch((err) => console.log(err));
  };

  const deleteFile = async (fileKey: number | string, fileServiceId: number) => {
    await requestDelete(`files/${fileKey}`);
    return await deleteFileFromService(fileServiceId);
  };

  const setFilesLoading = (data: boolean) => {
    setFilesState((prev: FilesState) => ({
      ...prev,
      loading: data,
    }));
  };

  const setROLoading = (data: boolean) =>
    setRelatedServices((prev: RelatedServicesState) => ({
      ...prev,
      loading: data,
    }));
  const setSOLoading = (data: boolean) =>
    setSODetails((prev: SODetailsState) => ({
      ...prev,
      loading: data,
    }));
  const setROContextData = (key: string, data: any) => {
    setRelatedServices((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };
  const setFilesContextData = (key: string, data: any) => {
    setFilesState((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };
  const setContextData = (key: string, data: any) => {
    setSODetails((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const getServiceDefaultDeliveryDate = () => {
    const { startDate, deliveryDate } = SODetailsState.serviceOrder;
    if (deliveryDate) {
      return deliveryDate;
    }

    if (!startDate) {
      return "";
    }

    return new Date(addMonths(new Date(startDate), 3)).toISOString();
  };

  const deleteServiceOrder = async (key: string | number) => {
    try {
      await requestDelete(`ServiceOrders/${key}?hasResponse=true`);
    } catch (err) {
      return;
    }
  };
  const callAPI = {
    getRelatedServices,
    getRelatedServiceOrders,
    getSODetails,
    getSOInfo,
    setSOLoading,
    setROLoading,
    setContextData,
    setROContextData,
    setFilesContextData,
    creatService,
    deleteFile,
    createServiceInBulk,
    updateSOdata,
    getFiles,
    deleteServiceOrder,
  };

  return (
    <Provider
      value={{
        serviceOrderDetails: SODetailsState,
        relatedServicesState,
        relatedServiceOrdersState,
        filesState: filesState,
        callAPI,
        getServiceDefaultDeliveryDate,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useSODetailsContext, SODetailsProvider };
