import React, { useState } from "react";
import createCtx from "../../utils/createCtx";
import { requestMetaGet, requestGet } from "src/utils/crud";
import { State, Context, Props, ModalData, ModalsContextUnion, Locales } from "./types";
import { UserReq } from "src/types";
import { localStore, setFeatureFlags } from "src/utils/functions";
import { initialState } from "src/providers/AppProvider/initialState";
import { defaultLocale, updateLocalization } from "src/utils/i18n";
import { useBrowserFullscreen } from "src/utils/hooks/useBrowserFullscreen";

const [useCtx, Provider] = createCtx<Context>();
const store = localStore.getItem("appStore");

const AppProvider = React.memo(function AppProvider(props: Props): React.ReactElement {
  const persistData = !!store ? JSON.parse(store) : initialState;
  const [appStore, setAppStore] = useState<State>(() =>
    !!persistData ? persistData : initialState
  );

  // if you are changing call order here, make sure to update authError behaviour
  // as its done currently in fetchingSOStatus.catch block
  const initialCall = () => {
    (async () => {
      await fetchingUserData();
      await fetchingUserLocale();
    })();
  };

  const fetchingUserData = () => {
    requestGet<Partial<UserReq>>(`Users/login`)
      .then(({ data }) => {
        setAppStore((prev: State) => ({
          ...prev,
          user: {
            ...prev.user,
            ...data.user,
            // dev code
            // partner: {
            //   ...data.user!.partner,
            //   isIntegration: true
            // }
            // isStaffChatRole: true
          },
          featureFlags: data.user ? setFeatureFlags(data.user) : prev.featureFlags,
        }));
        try {
          localStore.setItem("user", JSON.stringify(data.user));
          data?.user?.locale?.iso2 && updateLocalization(data.user.locale.iso2);
        } catch (e) {
          updateLocalization(defaultLocale);
        }
      })
      .catch(() => {
        setAppStore((prev: State) => ({
          ...prev,
          authError: true,
        }));
      });
  };

  const fetchingUserLocale = async () => {
    const locales = localStore.getObject("locales");

    if (Array.isArray(locales) && locales.length > 0) {
      const locale = !!locales ? locales : [];
      setAppStore((prev: State) => ({
        ...prev,
        locales: locale,
      }));
    } else {
      const res = await requestMetaGet<Locales[]>(`lookups/locales`);
      if (res.data && res.data.length > 0) {
        setAppStore((prev: State) => ({
          ...prev,
          locales: res.data,
        }));
        localStore.setItem("locales", JSON.stringify(res.data));
      }
    }
  };

  const toggleModal = <T extends ModalsContextUnion>({ id, status, ctx }: ModalData<T>) => {
    setAppStore((prev) => ({
      ...prev,
      modals: {
        ...prev.modals,
        [id]: status,
      },
      modalsCtx: {
        [id]: ctx,
      },
    }));
  };

  const setAppLoading = (data: boolean) =>
    setAppStore((prev: State) => ({
      ...prev,
      loading: data,
    }));

  const setContextData = (key: string, data: any) => {
    setAppStore((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const callAppAPI = {
    fetchingUserLocale,
    fetchingUserData,
    setContextData,
    setAppLoading,
    initialCall,
    toggleModal,
  };

  const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);

  useBrowserFullscreen(fullScreenMode, setFullScreenMode);

  return (
    <Provider
      value={{
        state: appStore,
        callAppAPI,
        fullScreenMode,
        setFullScreenMode,
      }}
    >
      {props.children}
    </Provider>
  );
});

export { useCtx as useAppContext, AppProvider };
