import React, { useEffect } from "react";
import { Grid, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { theme } from "src/utils/styleTheme/mainStyles";
import FileList from "./FileList";
import { useCommentsContext } from "src/providers/CommentsStore";
import FileSortDropdown from "./FileSortDropdown";

const useStyle = makeStyles(() => ({
  wrapper: {
    padding: theme.spacing(1),
    overflow: "auto"
  },
}));

const CommentFileList = () => {
  const classes = useStyle();
  const { callAPI, comments } = useCommentsContext();
  const { chatThread, fileList, loading, chatchannels, selectedChannel } = comments;

  useEffect(() => {
    callAPI.getCommentsFiles(true);
  }, [chatThread.key]);

  const deleteFileHandler = () => {
    callAPI.getCommentsFiles(true); // RELOAD FILE LIST
    callAPI.getThreadMsgs(chatThread.key, `${chatchannels[selectedChannel].key}`); // RELOAD MESSAGES LIST
  };
  return (
    <div className={classes.wrapper}>
      {!loading.files && fileList.length > 0 && (<>
        <FileSortDropdown />
        <FileList fileList={fileList} onDelete={deleteFileHandler} />
      </>)}
      {!loading.files && fileList.length === 0 && <Typography align="center">No Files</Typography>}
      {
        // PRELOADER
      }
      {loading.files && (
        <Grid container justify="center">
          <CircularProgress size={60} color="primary" />
        </Grid>
      )}
    </div>
  );
};

export default CommentFileList;
