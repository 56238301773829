import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  centerBtn: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  submitBtn: {
    padding: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.secondary.main,
    minWidth: 80,
    backgroundColor: theme.palette.functionalColors.dark,
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.brandColors.dark,
    },
  },
  successIcon: { width: 56, height: 56, marginBottom: 20 },
  titleWrapper: {
    textAlign: "center",
  },
}));
