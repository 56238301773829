import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import BackspaceOutlinedIcon from "@material-ui/icons/BackspaceOutlined";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import BlockIcon from "@material-ui/icons/Block";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { useHistory, useParams } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(1),
  },
  text: {
    color: theme.palette.functionalColors.dark,
    margin: `8px 17px 8px 0px`,
  },
}));

const PageActions: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { callLineItemsAPI } = useLineItemsContext();
  const history = useHistory();
  const { serviceId } = useParams();
  const handleSave = () => {
    callLineItemsAPI.saveLineItems();
  };
  const handleReset = () => {
    callLineItemsAPI.resetInvoice(serviceId);
  };
  const goBack = () => {
    history.goBack();
  };
  return (
    <Grid container className={classes.wrapper} direction="row">
      <Button
        size={"large"}
        className={classes.text}
        onClick={goBack}
        startIcon={<BlockIcon color={"action"} />}
      >
        {t("labels.cancel")}
      </Button>
      <Button className={classes.text} onClick={handleSave} startIcon={<AddIcon />}>
        {t("labels.save")}
      </Button>
      <Button
        variant="text"
        className={classes.text}
        onClick={handleReset}
        startIcon={<BackspaceOutlinedIcon fontSize={"small"} />}
      >
        {t("labels.reset")}
      </Button>
      <Divider />
    </Grid>
  );
};

export default PageActions;
