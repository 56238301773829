import React, { FC } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";

interface TitleStyle {
  padding: number | string;
}
interface ContentStyles {
  padding?: string;
  minWidth?: number | string;
  fill?: string;
}
interface Props {
  title?: string;
  open: boolean;
  showCloseIcon?: boolean;
  onClose: () => void;
  titleStyles?: TitleStyle;
  contentStyles?: ContentStyles;
  closeIconStyles?: ContentStyles;
  transparentBackdrop?: boolean;
  disableBackdropClick?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}
const MonteraDialog: FC<Props> = ({
  title = "",
  children = null,
  open = false,
  showCloseIcon = true,
  onClose = () => {},
  titleStyles = { padding: 0 },
  closeIconStyles = {},
  contentStyles = {
    padding: `${showCloseIcon ? 0 : 40}px 40px 0px 40px`,
    minWidth: 560,
  },
  transparentBackdrop = false, // Enable transparent backdrop
  disableBackdropClick = false,
  maxWidth,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={transparentBackdrop ? { style: { backgroundColor: "transparent" } } : {}}
      disableBackdropClick={disableBackdropClick}
      maxWidth={maxWidth}
    >
      {showCloseIcon && (
        <div>
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{ ...closeIconStyles, float: "right" }}
          ></IconButton>
        </div>
      )}
      <DialogTitle style={titleStyles}>{title}</DialogTitle>
      <DialogContent style={contentStyles as any}>
        {children}
        <br />
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default MonteraDialog;
