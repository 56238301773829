import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 100,
      height: 100,
    },
  })
);

const Loader = () => {
  const classes = useStyles();
  return (
    <SvgIcon
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      className={classes.root}
      xmlns="http://www.w3.org/1999/xlink"
    >
      <rect x="20" y="50" width="4" height="10" fill="#000">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="translate"
          values="0 0; 0 20; 0 0"
          begin="0"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="30" y="50" width="4" height="10" fill="#000">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="translate"
          values="0 0; 0 20; 0 0"
          begin="0.2s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="40" y="50" width="4" height="10" fill="#000">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="translate"
          values="0 0; 0 20; 0 0"
          begin="0.4s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </SvgIcon>
  );
};
export default Loader;
