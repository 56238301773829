import React, { FC } from "react";
import { FieldListType, ServiceOrder } from "src/types";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
import SOIcon from "src/pages/Customers/components/icons/SOIcon";
import Link from "@material-ui/core/Link/Link";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "src/pages/Customers/components/styles";
import StatusColor from "src/components/StatusColors";
import format from "date-fns/format";
import Hint from "src/components/Hint";
import WarningIcon from "../icons/WarningIcon";
import { useTranslation } from "react-i18next";
import DeliveryAddressTooltip from "src/pages/Orders/components/OrderList/DeliveryAddressTooltip";
import ProgressTooltip from "src/pages/Orders/components/ProgressTooltip";
import ServiceStatusesTooltip from "src/pages/Orders/components/ServiceStatusesTooltip";

const ShowWarningIconForStatuses = ["INCOMPLETE"];

const dataList = (elem: ServiceOrder): FieldListType[] => [
  {
    label: "Name",
    value: elem.name,
  },
  {
    label: "Warehouse",
    value: elem?.warehouse?.displayText,
  },
  {
    label: "Seller",
    value: elem?.seller?.displayText,
  },
  {
    label: "Qty of Services",
    value: elem.childServicesCount,
  },
  {
    label: "Date Created",
    value: !!elem.createdDate ? format(new Date(elem.createdDate), "dd.MM.yyyy H:mm") : "",
  },
  {
    label: "Status",
    value: <StatusColor color={elem.statusMatchingName} />,
  },
];
const SOFieldList: FC<{ data: ServiceOrder }> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation("orders");

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={4} container alignItems="center" justify="flex-start">
        <SOIcon />
        <Link
          className={classes.link}
          component={RouterLink}
          to={`serviceOrders/details/${data.id}/summary`}
        >
          {data.name}
        </Link>
      </Grid>
      {dataList(data).map((ent) => (
        <Grid item xs key={uniqueId("serviceOrders")}>
          <Typography variant="caption">{ent.label}</Typography>
          <Typography variant="body2">{ent.value}</Typography>
        </Grid>
      ))}
      <Grid item className={classes.dynamicWarningMessage}>
        {ShowWarningIconForStatuses.includes(data.statusMatchingName.toUpperCase()) && (
          <Hint placement="bottom" title={<Typography> {t("labels.row.indicator")}</Typography>}>
            <span>
              <WarningIcon />
            </span>
          </Hint>
        )}
      </Grid>
      <Grid item>
        <DeliveryAddressTooltip title={data.deliveryAddress!} />
      </Grid>
      <Grid item>
        <ProgressTooltip
          completedChildCount={data.completedChildCount!}
          childServicesCount={data.childServicesCount!}
        />
      </Grid>
      <Grid item className={classes.dynamicIcon}>
        {data.childServicesCount! > 0 && <ServiceStatusesTooltip serviceOrderKey={data.id!} />}
      </Grid>
    </Grid>
  );
};

export default SOFieldList;
