import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const HelpIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.89355 5.82856C6.24492 4.82974 7.26545 4.22996 8.30903 4.40896C9.35262 4.58796 10.1149 5.49355 10.1134 6.55237C10.1134 7.99999 7.94194 8.7238 7.94194 8.7238"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 11.6191V11.2572" stroke="white" strokeLinecap="round" />
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="white"
      fillOpacity="0.5"
    />
  </SvgIcon>
);

export default HelpIcon;
