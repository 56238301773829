import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RelatedOrdersFieldsList from "./RelatedOrdersFieldsList";
import { CustomerProvider } from "src/providers/CustomerStore";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import { ServicesProvider } from "src/providers/ServicesStore";
import Pagination from "./Pagination";
import Container from "@material-ui/core/Container/Container";
import ServiceTable from "./ServiceTable";
import CommonList from "src/components/CommonList";
import { useServicesContext } from "src/providers/ServicesStore";
import { ServiceOrder } from "src/types";

export const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.functionalColors.dark,
    padding: theme.spacing(2),
  },
  detailsRoot: {
    paddingBottom: 0,
    marginLeft: theme.spacing(4),
  },
  activeServiceOrder: {
    backgound: "#fff",
  },
  panelSummary: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0 4px",
    "& :focused": {
      backgroundColor: "#fff",
    },
    "& >div": {
      margin: 0,
    },
    "& >.Mui-expanded": {
      margin: 0,
    },

    "& .MuiListItem-gutters": {
      padding: 4,
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(1),
    },
  },
  accordionDetails: {
    flexDirection: "column",
  },
}));

const RelatedOrders: React.FC = () => {
  const {
    relatedOrders: { serviceOrders, loading },
  } = useCustomerDetailsContext();
  const { callServicesAPI } = useServicesContext();

  const handlerServices = useCallback(
    () => ({ id }: ServiceOrder) => {
      callServicesAPI.fetchingServices(id!);
    },
    []
  );

  if (loading) {
    return <PreloaderLine />;
  }

  return (
    <Container style={{ margin: 0, maxWidth: "100%" }} maxWidth={"lg"}>
      <PreloaderLine loading={loading} />
      <CommonList<ServiceOrder>
        listEntities={serviceOrders!}
        handlerOnOpen={handlerServices}
        entityFields={(ent) => <RelatedOrdersFieldsList data={ent} />}
        entityDetails={(ent) => <ServiceTable data={ent} />}
      />
      <Pagination />
    </Container>
  );
};

const RelatedOrdersPage = () => (
  <CustomerProvider>
    <ServicesProvider>
      <RelatedOrders />
    </ServicesProvider>
  </CustomerProvider>
);

export default RelatedOrdersPage;
