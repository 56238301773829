import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "src/pages/Orders/components/NoServices";
import NoServicesIcon from "src/pages/Orders/components/icons/NoServicesIcon";

const NoService = () => {
  const classes = useStyles({});
  const { t } = useTranslation("warehouses");

  return (
    <Grid container direction="column" justify="center" alignContent="center">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <NoServicesIcon className={classes.icon} />
      </div>
      <Typography variant="h3" className={classes.title}>
        {t("labels.empty_state.title")}
      </Typography>
    </Grid>
  );
};

export default NoService;
