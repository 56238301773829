import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { SuccessDialog } from "src/components/Dialog";

interface Props {
  open: boolean;
}

const SuccessNotification: React.FC<Props> = ({ open }: Props) => {
  const history = useHistory();
  const { t } = useTranslation("orders");
  const {
    SOData: { responseObject },
    callSOAPI,
  } = useSOContext();

  const onClose = () => {
    history.push(`/serviceOrders/details/${responseObject?.id}/summary`);
  };
  const activateServiceCreation = (): void => {
    callSOAPI.setContextDataBulk({
      createService: true,
      success: false,
    });
  };

  return (
    <SuccessDialog
      open={open}
      title={t("form.success_dialog.title")}
      subtitle={t("form.success_dialog.subtitle")}
      acceptLabel={t("labels.btn.create_service")}
      onAccept={activateServiceCreation}
      onClose={onClose}
    />
  );
};

export default SuccessNotification;
