import React, { useCallback, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useFieldArray, useForm, FormProvider } from "react-hook-form";
import { CancelButton, SaveButton } from "src/components/Buttons";
import { useTranslation } from "react-i18next";
import ServiceFieldsRow from "./components/ServiceFields/ServiceFieldsRow";
import { Services, ServicesReq } from "src/providers/SODetailsStore/types";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import Success from "./components/Success";
import PlusIcon from "./icons/PlusIcon";

import { useAppContext } from "src/providers/AppProvider";
import Modal from "src/components/Modal";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { useServicesContext } from "src/providers/ServicesStore";
import { useStyles } from "./styles";

const CreateServiceInBulk: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    callAPI: { setContextData, createServiceInBulk },
  } = useSODetailsContext();
  const [success, setSuccess] = useState<boolean>(false);
  const {
    state: {
      modals: { createServiceBulkModal },
    },
    callAppAPI,
  } = useAppContext();
  const {
    SOData: { responseObject },
    callSOAPI: { fetchingAllSO },
  } = useSOContext();

  const { callServicesAPI } = useServicesContext();
  const methods = useForm<ServicesReq>({
    defaultValues: {
      services: [
        {
          serviceTypeId: undefined,
          capacity: null,
        },
      ],
    },
  });
  const { handleSubmit, control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
  });
  const addNewRow = () => {
    append({
      serviceTypeId: undefined,
      capacity: null,
    });
  };
  const removeRow = (index: number) => {
    remove(index);
  };
  const onSubmit = (data: ServicesReq) => {
    if (
      data.services &&
      data.services.length > 0 &&
      responseObject?.id &&
      responseObject?.owner?.key
    ) {
      const parsedReqData = data.services.map(
        (el): Services => {
          // Add required info
          return {
            ...el,
            ownerId: Number(responseObject?.owner?.key),
            serviceOrderId: Number(responseObject.id),
          };
        }
      );
      createServiceInBulk(parsedReqData).then((success) => {
        // Submit services collection
        setContextData("servicesCreted", true);
        setSuccess(success);
        fetchingAllSO(); // to update SO -> Services count
        callServicesAPI.fetchingServices(responseObject.id!); // load the list of services
      });
    }
  };
  const handleModalClose = useCallback(() => {
    callAppAPI.toggleModal({ id: "createServiceBulkModal", status: false });
    setSuccess(false);
  }, []);

  return (
    <>
      {!success && (
        <Modal isOpen={createServiceBulkModal} onClose={handleModalClose} maxWidth="md">
          <Grid container>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Typography variant="h3">{t("create_services_in_bulk_form.title")}</Typography>
                <br />
                {!!fields.length && (
                  <div className={classes.container}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t("create_services_in_bulk_form.service_type")}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t("create_services_in_bulk_form.start_date")}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t("create_services_in_bulk_form.delivery_date")}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t("labels.creation.service.capacity")}
                    </Typography>
                    <div />
                    {fields.map((item, index) => {
                      return (
                        <ServiceFieldsRow
                          key={item.id}
                          item={item}
                          index={index}
                          removeRow={removeRow}
                        />
                      );
                    })}
                  </div>
                )}
                <div>
                  <Button
                    variant="text"
                    size="large"
                    className={classes.addMoreButton}
                    startIcon={<PlusIcon />}
                    onClick={addNewRow}
                  >
                    <Typography color="primary">Add more</Typography>
                  </Button>
                </div>
                <Grid container wrap="nowrap" alignItems="center">
                  <SaveButton label={t("labels.save", { ns: "common" })} type="submit" />
                  <CancelButton
                    label={t("labels.cancel", { ns: "common" })}
                    onClick={handleModalClose}
                  />
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Modal>
      )}
      {/* Display success message */}
      <Success open={success} />
    </>
  );
};

export default CreateServiceInBulk;
