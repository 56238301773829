import React, { useState, useEffect } from "react";
import { MenuItem, TextField, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useSellersContext } from "src/providers/SellersStore";

const sortValues = (t: TFunction) => [
  { 
    key: "name_desc", 
    displayText: t("sort_values.name_desc") },
  { 
    key: "name", 
    displayText: t("sort_values.name_asc") },
  {
    key: "createdDate_desc",
    displayText: t("sort_values.created_desc"),
  },
  { 
    key: "createdDate", 
    displayText: t("sort_values.created_asc") 
  },
];

const SellersSortDropdown: React.FC = React.memo(() => {
  const { t } = useTranslation(`sellers`);
  const {
    sellersStore: { sortBy, des },
    callAPI: { setContextData }
  } = useSellersContext();
  const classes = useStyles();
  const [value, setValue] = useState<string>(sortBy || "name");

  const handleSorting = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes("_desc")) {
      let val = event.target.value.split("_");
      setContextData("sortBy", val[0]);
      setContextData("des", true);
    } else {
      setContextData("sortBy", event.target.value);
      setContextData("des", false);
    }
  };

  useEffect(() => {
    if (des) {
      setValue(`${sortBy}_desc`);
    } else {
      setValue(sortBy);
    }
  }, [sortBy, des]);

  return (
    <TextField
      select
      style={{ width: 250 }}
      onChange={handleSorting}
      value={value}
      variant="outlined"
      margin="normal"
      className={classes.WithoutBorder}
      InputProps={{ className: classes.WithoutBorder }}
    >
      {sortValues(t).map((el, idx) => (
        <MenuItem key={idx} value={el.key}>
          <Typography variant="button">{el.displayText}</Typography>
        </MenuItem>
      ))}
    </TextField>
  );
});

export default React.memo(SellersSortDropdown);
