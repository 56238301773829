import React, { useState, useEffect } from "react";

import { Button, Grid, List, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import readAnnMsg from "../../../images/readAnnMsg.svg";
import { useTranslation } from "react-i18next";
import { useCommentsContext } from "src/providers/CommentsStore";
import ActionsToolBar from "./ActionsToolBar";
import MessageListItem from "./MessageListItem";
import CommentsDialog from "../../CommentsDialog";
import { useAppContext } from "../../../../../providers/AppProvider";
import ShareByEmailDialog from "../../ShareByEmailDialog";

const useStyle = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    maxHeight: "calc(100vh - 30px)",
  },
  list: {
    paddingTop: 4,
    overflowY: "auto",
    maxHeight: "calc(100% - 220px)",
    minHeight: 300,
    "& .react-tel-input .form-control": {
      width: "100%",
    },
  },
  chatLine: {
    width: "100%",
    backgroundColor: "#ccc",
    height: 1,
  },
}));

const Messages = () => {
  const { t } = useTranslation("comments");
  const { callAPI, comments } = useCommentsContext();
  const {
    meta,
    chatchannels,
    selectedChannel,
    chatThread,
    threads,
    threadsSelected,
    uploadedFiles,
    scrollToBottom,
  } = comments;
  const {
    state: {
      user: { isStaffChatRole },
    },
  } = useAppContext();

  const [data, setData] = useState<string>("");
  const msgWrapperRef = React.createRef<HTMLLIElement>();
  const [readAllCommentsConfPopUp, setReadAllCommentsConfPopUp] = useState(false);
  const { wrapper, list, chatLine } = useStyle();
  const unreadMessages = chatThread.messages
    ? chatThread.messages.filter((el) => el.isUnread === true).map((el) => el.key)
    : [];

  useEffect(() => {
    //setFileUploadingAnchorEl(null);
    scrollToBottom &&
      msgWrapperRef.current &&
      (msgWrapperRef.current.scrollTop! = msgWrapperRef?.current?.scrollHeight); // set scroll to bottom after msg submit
  }, [chatThread, threadsSelected]);

  const handleUserKeyPress = (e: React.KeyboardEvent) => {
    if (e.charCode === 13 && e.ctrlKey) {
      handleSendMsg();
    }
  };

  const canAddComments = chatchannels[selectedChannel].matchingText !== "Public" || isStaffChatRole;

  const handleSendMsg = () => {
    if (!!data || uploadedFiles.length !== 0) {
      callAPI.sendMsg({
        messageText: data,
        messageThread: {
          threadKey: chatThread?.key,
          threadChannelKey: `${chatchannels[selectedChannel].key}`,
          entityKey: meta.key,
          entityType: "serviceOrder",
        },
      });
      if (!!threads) {
        const threadsBoofer = threads;
        const threadIndex = threads.findIndex((el) => Number(el.key) === Number(chatThread.key));
        threadsBoofer[threadIndex].visibleMessageText = data;
        callAPI.updateState({
          threads: threadsBoofer,
          scrollToBottom: true,
        });
        setData("");
      }
    }
  };

  const readAllCommentsPopUpContr = () => {
    setReadAllCommentsConfPopUp((prev) => !prev);
  };

  const onConfirmationAction = (value: boolean) => {
    if (value) callAPI.postReadedComments(unreadMessages); // Reade all messages

    readAllCommentsPopUpContr(); // close popUp
  };
  const onReadCommentHandler = (key: string) => {
    if (!comments.readedMessages.includes(key))
      callAPI.setContextData("readedMessages", [...comments.readedMessages, key]);
  };
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(event.target.value);
  };

  return (
    <div className={wrapper} onKeyPress={handleUserKeyPress}>
      <List component="nav" dense className={list} ref={msgWrapperRef}>
        {chatThread.messages.map((message, idx) => {
          return (
            <div key={message.key}>
              {idx > 0 && !chatThread.messages[idx - 1].isUnread && message.isUnread && (
                <Grid container justify="center" wrap="nowrap" direction="row" alignItems="center">
                  <div className={chatLine}></div>
                  <Button
                    onClick={readAllCommentsPopUpContr}
                    type="button"
                    endIcon={<img src={readAnnMsg} alt="read all messages" />}
                    variant="contained"
                    color="primary"
                    style={{ borderRadius: 100, minWidth: "auto", textTransform: "inherit" }}
                  >
                    <Typography noWrap variant="subtitle1">
                      {unreadMessages.length} {t("labels.new_message")}
                    </Typography>
                  </Button>
                  <div className={chatLine}></div>
                </Grid>
              )}
              <MessageListItem message={message} onRead={onReadCommentHandler} />
            </div>
          );
        })}
      </List>
      {canAddComments && (
        <ActionsToolBar
          data={data}
          onChangeHandler={onChangeHandler}
          handleSendMsg={handleSendMsg}
        />
      )}
      <CommentsDialog
        open={Boolean(readAllCommentsConfPopUp)}
        title={
          <>
            {t("labels.mark_all_comments_as_read")}
            <br />
            <br />
            <Typography variant="body1">{t("labels.mark_all_comments_as_read_2")}</Typography>
          </>
        }
        okText={t("labels.yes", { ns: "common" })}
        cancelText={t("labels.cancel", { ns: "common" })}
        action={onConfirmationAction}
      />
      <ShareByEmailDialog />
    </div>
  );
};

export default Messages;
