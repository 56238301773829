import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  menuItem: {
    color: theme.palette.text.primary,
  },
}));
