import React, { useState, useEffect } from "react";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { TextField } from "@material-ui/core";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const sortValues = (t: TFunction) => [
  { key: "name_desc", displayText: t("labels.services_table.sort_values.name_desc") },
  { key: "name", displayText: t("labels.services_table.sort_values.name_asc") },
  {
    key: "serviceType.displayText_desc",
    displayText: t("labels.services_table.sort_values.service_type_desc"),
  },
  {
    key: "serviceType.displayText",
    displayText: t("labels.services_table.sort_values.service_type_asc"),
  },
  {
    key: "seller.displayText_desc",
    displayText: t("labels.services_table.sort_values.seller_desc"),
  },
  { key: "seller.displayText", displayText: t("labels.services_table.sort_values.seller_asc") },
  { key: "deliveryDate_desc", displayText: t("labels.services_table.sort_values.delivery_desc") },
  { key: "deliveryDate", displayText: t("labels.services_table.sort_values.delivery_asc") },
  {
    key: "assignedWorkersCount_desc",
    displayText: t("labels.services_table.sort_values.assigned_workers_count_desc"),
  },
  {
    key: "assignedWorkersCount",
    displayText: t("labels.services_table.sort_values.assigned_workers_count_asc"),
  },
  {
    key: "status.displayText_desc",
    displayText: t("labels.services_table.sort_values.status_desc"),
  },
  { key: "status.displayText", displayText: t("labels.services_table.sort_values.status_asc") },
];

const ServicesSortDropdown: React.FC = React.memo(() => {
  const { t } = useTranslation(`orders`);
  const {
    relatedServicesState: { sortBy, des },
    callAPI: { setROContextData },
  } = useSODetailsContext();
  const classes = useStyles();
  const [value, setValue] = useState<string>(sortBy || "name");

  const handleSorting = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes("_desc")) {
      let val = event.target.value.split("_");
      setROContextData("sortBy", val[0]);
      setROContextData("des", true);
    } else {
      setROContextData("sortBy", event.target.value);
      setROContextData("des", false);
    }
  };

  useEffect(() => {
    if (des) {
      setValue(`${sortBy}_desc`);
    } else {
      setValue(sortBy);
    }
  }, [sortBy, des]);

  return (
    <TextField
      select
      onChange={handleSorting}
      value={value}
      variant="outlined"
      SelectProps={{
        native: true,
      }}
      className={classes.textField}
      InputLabelProps={{ shrink: true }}
      InputProps={{ classes: { root: classes.root } }}
    >
      {sortValues(t).map((el, idx) => (
        <option key={idx} value={el.key}>
          {el.displayText}
        </option>
      ))}
    </TextField>
  );
});

export default React.memo(ServicesSortDropdown);
