import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const RefreshIcon = () => (
  <SvgIcon
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M1 1.72565V6.08928H5.36364"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M16.9999 13.362V8.99841H12.6362"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M15.1745 5.36204C14.4248 3.24336 12.6405 1.65747 10.4485 1.1615C8.2565 0.665529 5.96332 1.32884 4.37455 2.9184L1 6.08931M17 8.9984L13.6255 12.1693C12.0367 13.7589 9.7435 14.4222 7.55148 13.9262C5.35947 13.4302 3.57519 11.8444 2.82545 9.72568"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default RefreshIcon;
