import React, { FC, useState } from "react";
import { Pagination } from "@material-ui/lab";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useStyles } from "./styles";
import { useObjectUrl } from "src/utils/hooks/useObjectUrl";

interface PDF {
  numPages: number;
}

interface Props {
  blob: Blob;
}

const MonteraPdfPreview: FC<Props> = ({ blob }) => {
  const classes = useStyles();
  const url = useObjectUrl(blob);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: PDF) => {
    setTotalPages(numPages);
    setPage(1);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);

    event.preventDefault();
  };

  return (
    <div className={classes.pdfContainer}>
      <Document className={classes.pdfDocument} file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={page} />
      </Document>

      <Pagination page={page} count={totalPages} onChange={handleChangePage} />
    </div>
  );
};

export default MonteraPdfPreview;
