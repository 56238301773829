import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  contentWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflow: "auto",
    maxHeight: 250,
    "&::-webkit-scrollbar": {
      width: "0.4em",
      borderRadius: 20,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      borderRadius: 20,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: 20,
    },
  },
  statusWrap: {
    padding: 4,
    borderRadius: 4,
  },
  caption: {
    display: "block",
    color: "rgba(20, 30, 48, 0.5)",
    marginBottom: theme.spacing(1),
  },
  displayValue: {
    display: "block",
    marginBottom: theme.spacing(2),
  },
  listItem: {
    paddingLeft: 0,
  },
}));
