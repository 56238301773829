import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    margin: "10px 0",
    boxShadow: "none",
  },
  Accordion: {
    borderRadius: 4,
    backgroundColor: "#eaeef9",
  },
});
interface Props {
  labelTextKey?: string;
  icon?: string;
  defaultExpanded?: boolean;
}
const AccordionWrapped: FC<Props> = ({
  labelTextKey = "",
  icon = "",
  children,
  defaultExpanded = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <Accordion className={classes.root} defaultExpanded={defaultExpanded}>
      <AccordionSummary className={classes.Accordion} expandIcon={<ExpandMoreIcon />}>
        {icon}
        <Typography>
          <b>{t(labelTextKey)}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionWrapped;
