import React, { FC } from "react";
import { Customer } from "src/types";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import DropdownMenu from "src/components/DropdownMenu";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
import { TFunction } from "i18next";
import CustomerIcon from "src/pages/Customers/components/icons/CustomerIcon";
import ActionsAddSO from "src/pages/Customers/components/icons/ActionsAddSO";
import ActionsAddInvoice from "src/pages/Customers/components/icons/ActionsAddInvoice";
import ActionsAddDelivery from "src/pages/Customers/components/icons/ActionsAddDelivery";
import MenuIcon from "src/pages/Customers/components/icons/MenuIcon";
import { useStyles } from "src/pages/Customers/components/styles";
import Button from "@material-ui/core/Button/Button";
import { useAppContext } from "src/providers/AppProvider";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import { useHistory } from "react-router-dom";

interface FieldListType {
  id: string | number;
  label: string;
  width: number;
  value: string | number | undefined;
}

const dataList = (t: TFunction, elem: Partial<Customer>) => [
  {
    id: "registered_by",
    label: t("labels.table_fields.registered_by"),
    value: elem.createdBy?.displayText,
    width: 15,
  },
  {
    id: "address",
    label: t("labels.table_fields.address"),
    value: elem.fullAddress,
    width: 30,
  },
  {
    id: "email",
    label: t("labels.table_fields.email"),
    value: elem.email,
    width: 15,
  },
  {
    id: "phone",
    label: t("labels.table_fields.phone"),
    value: elem.mobile,
    width: 15,
  },
  {
    id: "so_registered",
    label: t("labels.table_fields.so_registered"),
    value: elem.serviceOrderCount,
    width: 10,
  },
  {
    id: "active",
    label: t("labels.table_fields.active"),
    value: elem.active ? "Yes" : "No",
    width: 5,
  },
];
const actionsList = (t: TFunction, onClickHandler: (event: string) => void) => [
  {
    label: t("labels.table_fields.actions.add_so"),
    icon: <ActionsAddSO />,
    attr: { onClick: () => onClickHandler("AddServiceOrder") },
  },
  {
    label: t("labels.table_fields.actions.add_invoice"),
    icon: <ActionsAddInvoice />,
    action: "AddInvoice",
    attr: { onClick: () => onClickHandler("AddInvoice") },
  },
  {
    label: t("labels.table_fields.actions.add_delivery"),
    icon: <ActionsAddDelivery />,
    attr: { onClick: () => onClickHandler("AddDelivery") },
  },
];

const CustomerFieldList: FC<{ data: Customer }> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const history = useHistory();
  const {
    state: { user },
    callAppAPI,
  } = useAppContext();
  const {
    callAPI: { setContextData },
  } = useCustomerDetailsContext();

  const isIntegrated = !user?.partner?.isIntegration;

  const onClickHandler = (event: string) => {
    switch (event) {
      case "AddInvoice":
        setContextData("customer", data);
        callAppAPI.toggleModal({ id: "createInvoiceAddressModal", status: true });
        break;
      case "AddDelivery":
        setContextData("customer", data);
        callAppAPI.toggleModal({ id: "createDeliveryAddressModal", status: true });
        break;
      case "AddServiceOrder":
        history.push(`/serviceOrders/creation`, { customerId: data.key, customerName: data.name });
        break;
      default:
        break;
    }
  };

  return (
    <Grid container direction="row" alignItems="center" style={{ flexWrap: "nowrap" }} spacing={2}>
      <Grid item style={{ width: 300 }}>
        <Button
          color="primary"
          className={classes.link}
          id="warehouse-link"
          href={`customers/details/${data.key}/summary`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
          onFocus={(event: React.FocusEvent<HTMLAnchorElement>) => event.stopPropagation()}
          size="large"
          startIcon={<CustomerIcon />}
        >
          <Typography variant="body2">{data.name}</Typography>
        </Button>
      </Grid>
      {dataList(t, data).map((ent: FieldListType) => (
        <Grid item style={{ width: `${ent?.width}%` }} key={uniqueId("")}>
          <Typography variant="caption">{ent.label}</Typography>
          <Typography variant="body2" style={{ wordBreak: "break-all" }}>
            {ent.value}
          </Typography>
        </Grid>
      ))}
      {isIntegrated && (
        <Grid
          key={uniqueId("")}
          item
          className={classes.centerElement}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        >
          <DropdownMenu menuIcon={<MenuIcon />} menuActions={actionsList(t, onClickHandler)} />
        </Grid>
      )}
    </Grid>
  );
};

export default CustomerFieldList;
