import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const ShowCommentsInOrder = () => {
  const { t } = useTranslation(`comments`);
  return (
    <FormControlLabel
      style={{
        padding: "8px 15px",
      }}
      control={<Checkbox value={1} checked={false} name="onlyFroOrder" />}
      label={
        <Typography align="left" variant="body1">
          {t("labels.show_comments_to_this_order")}
        </Typography>
      }
    />
  );
};

export default ShowCommentsInOrder;
