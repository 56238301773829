import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const LIServiceIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M8 0V4.8H12.8" fill="#C5DBF2" />
    <path
      d="M12.8 5.8V14.4C12.8 15.2837 12.0837 16 11.2 16H1.6C0.716344 16 0 15.2837 0 14.4V1.6C0 0.716344 0.716344 0 1.6 0H7V4.8C7 5.35228 7.44772 5.8 8 5.8H12.8Z"
      fill="#C5DBF2"
    />
    <path d="M8 4.8V0L12.8 4.8H8Z" fill="#C5DBF2" />
  </SvgIcon>
);
export default LIServiceIcon;
