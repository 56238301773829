import React from "react";
import Hint from "src/components/Hint";
import DeliveryIcon from "../icons/DeliveryTooltipIcon";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";

interface Props {
  title: string;
}

const DeliveryAddressTooltip: React.FC<Props> = ({ title }) => {
  const classes = useStyles();
  return (
    <Hint title={<Typography>{title}</Typography>}>
      <div className={classes.tooltipWrap}>
        <DeliveryIcon />
      </div>
    </Hint>
  );
};

export default DeliveryAddressTooltip;
