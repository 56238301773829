import React from "react";

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import noTopics from "../../../images/no topics.svg";
import addBtn from "../../../images/addBtn.svg";
import Avatar from "@material-ui/core/Avatar";
import { useStyles } from "src/containers/Comments/components/ThreadsAndChat/style";
import { useCommentsContext } from "src/providers/CommentsStore";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";

const AddNewTopicBtn = React.memo(() => {
  const { t } = useTranslation("comments");
  const {
    callAPI,
    comments: { isAddNewThread },
  } = useCommentsContext();
  const addTopic = () => {
    callAPI.setContextData("isAddNewThread", !isAddNewThread);
  };
  return (
    <Button
      variant="text"
      size={"small"}
      onClick={addTopic}
      style={{ margin: 16 }}
      startIcon={<Avatar variant={"square"} src={addBtn} style={{ height: 16, width: 16 }} />}
    >
      {t("labels.create_new_topic")}
    </Button>
  );
});

const ClickBelowToCreateTopicText = () => {
  const { t } = useTranslation("comments");

  return (
    <Typography align="left" variant="subtitle2" color={"textSecondary"}>
      {t("labels.click_to_create_topic")}
    </Typography>
  );
};

const NoThread = () => {
  const { t } = useTranslation("comments");
  const {
    state: {
      user: { isStaffChatRole },
    },
  } = useAppContext();
  const {
    comments: { selectedChannel, chatchannels },
  } = useCommentsContext();
  const { nothing, nothingTitle } = useStyles();
  return (
    <div className={nothing}>
      <img src={noTopics} alt="no topics" style={{ padding: 16, width: "20vh", minWidth: 150 }} />
      <Typography className={nothingTitle}>{t("labels.no_topic_created")}</Typography>

      {
        // SHOW "CLICK BELOW..." IF NOT STAFF ROLE AND NOT PUBLIC CHANEL
      }
      {!isStaffChatRole && chatchannels[selectedChannel]?.matchingText !== "Public" && (
        <ClickBelowToCreateTopicText />
      )}
      {
        // SHOW "CREATE NEW TOPIC BTN" IF NOT STAFF ROLE AND NOT PUBLIC CHANEL
      }
      {!isStaffChatRole && chatchannels[selectedChannel]?.matchingText !== "Public" &&  (
        <AddNewTopicBtn />
      )}
    </div>
  );
};

export default NoThread;
