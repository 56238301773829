import React, { useCallback, useMemo } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/pages/ServiceDetails/components/Description/styles";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";
import { useAppContext } from "src/providers/AppProvider";
import MonteraCard from "src/components/MonteraCard";
import DescriptionIcon from "src/pages/ServiceDetails/components/icons/DescriptionIcon";
import EditIcon from "src/pages/Customers/CustomerDetails/components/icons/EditIcon";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";
import { useSODetailsContext } from "src/providers/SODetailsStore";

const Description = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    state: { user },
  } = useAppContext();
  const {
    serviceDetails: { service },
  } = useServiceDetailsContext();
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();
  const handleDescriptionEdit = useCallback(() => {
    toggleModal({ id: "editDescriptionSO", status: true });
  }, []);

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder?.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <div className={classes.contentWrap}>
      <MonteraCard
        headerIcon={<DescriptionIcon />}
        title={t("labels.summary_cards.description")}
        action={
          isIntegrated && disabledByStatus ? (
            <IconButton onClick={handleDescriptionEdit}>
              <EditIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          ) : (
            <></>
          )
        }
      >
        <div className={classes.textWrapper}>
          <Typography variant="caption" className={classes.caption}>
            {t("labels.summary_cards.description")}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {service.description || `No description`}
          </Typography>
        </div>
      </MonteraCard>
    </div>
  );
};

export default Description;
