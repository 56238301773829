import React from "react";
import { Typography } from "@material-ui/core";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { useTranslation } from "react-i18next";
import useCurrencyFormat from "src/utils/hooks/currency";

const TotalFooter = () => {
  const { t } = useTranslation(`lineItems`);
  const { currencyFormat } = useCurrencyFormat();
  const {
    LIData: {
      lineItemsSO: { total },
    },
  } = useLineItemsContext();

  if (!total || total.total === 0) return null;
  return (
    <div
      style={{ display: "flex", flexDirection: "row", padding: "18px 25px", flexWrap: "nowrap" }}
    >
      <Typography variant="h4" component="h3">
        {t("labels.total")}
      </Typography>
      <div style={{ marginLeft: "auto", display: "flex", flexDirection: "row" }}>
        <Typography variant="h4" component="h3" style={{ paddingRight: 80 }}>
          {total.totalQty}
        </Typography>
        <Typography variant="h4" component="h3" style={{ paddingRight: 80 }}>
          {currencyFormat(total.total)}
        </Typography>
        <Typography variant="h4" component="h3">
          {currencyFormat(total.totalVat)}
        </Typography>
      </div>
    </div>
  );
};
export default TotalFooter;
