import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(3),
    justifyContent: "stretch",
    "& > .MuiButton-root": {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));
