import React, { FunctionComponent, useMemo } from "react";
import { Button } from "@material-ui/core";
import BackIcon from "src/pages/ServiceOrdersDetails/components/icons/BackIcon";
import AddLineItemsIcon from "src/pages/ServiceOrdersDetails/components/icons/AddLineItemsIcon";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";
import { SODetailsType } from "src/pages/ServiceOrdersDetails/type";
import { ServiceDetailsType } from "../type";
import { useServiceDetailsStyles } from "../styles";
import ArchiveIcon from "./icons/ArchiveIcon";
import FilesIcon from "../../ServiceOrdersDetails/components/icons/FilesIcon";
import { useAppContext } from "src/providers/AppProvider";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

const PageActions: FunctionComponent = () => {
  const classes = useServiceDetailsStyles();
  const history = useHistory();
  const { serviceOrderId } = useParams<SODetailsType>();
  const { serviceId } = useParams<ServiceDetailsType>();
  const { t } = useTranslation("orders");
  const {
    serviceDetails: { service },
  } = useServiceDetailsContext();
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const handleBack = () => {
    history.goBack();
  };

  const onUploadFiles = () => {
    toggleModal({ id: "ordersFilesUpload", status: true });
  };

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(service?.status?.matchingText);
  }, [service?.status?.matchingText]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Button className={classes.outlineBtn} startIcon={<BackIcon />} onClick={handleBack}>
          {t("labels.common.back")}
        </Button>
        {isIntegrated && disabledByStatus && (
          <Button
            className={classes.outlineBtn}
            to={{
              pathname: `/line_items/${serviceOrderId}/${serviceId}`,
              state: { parentServiceType: "Services", serviceId: serviceId },
            }}
            component={Link}
            startIcon={<AddLineItemsIcon />}
          >
            {t("labels.details.add_line_items")}
          </Button>
        )}
        {disabledByStatus && (
          <Button className={classes.outlineBtn} startIcon={<FilesIcon />} onClick={onUploadFiles}>
            {t("labels.details.add_files")}
          </Button>
        )}
        <Button
          className={classes.outlineBtn}
          startIcon={<ArchiveIcon />}
          target="_blank"
          href={getArchiveLink(
            service?.customer?.displayText || "",
            service?.partner?.displayText || ""
          )}
        >
          Archive
        </Button>
      </div>
    </>
  );
};

export default PageActions;

function getArchiveLink(customer: string, partner: string) {
  return `https://dropbox.monterahub.com/Projects?PartnerName=${getPartnerNameForUrl(
    partner
  )}&CustomerName=${getCustomerNameForUrl(customer)}`;
}

function getCustomerNameForUrl(mplusCustomerName: string) {
  return mplusCustomerName.replace(" ", "");
}

type tArchivePartners = {
  [key: string]: string;
};

function getPartnerNameForUrl(mplusPartnerName: string) {
  const d: tArchivePartners = {
    "Power Norge": "Power Norge",
    "Kvik Kristiansand": "Kvik Kristiansand",
    "Elkjøp Norge": "Elkjøp",
    "Nerostein Norge": "Nerostein Norge",
    "Arna Innredning": "Arna Innredning",
    "Superfront Norge": "Superfront",
    "IKEA (MIS)": "IKEA Norway",
    "Montera Norge": "Montera Norge",
    "Det Stolte Kjøkken": "Det Stolte Kjøkken",
    "ABC Hus": "ABC Hus",
    IKEA: "I K E A Business",
  };
  if (d.hasOwnProperty(mplusPartnerName)) return d[mplusPartnerName];
  else return "";
}
