import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import RelatedOrdersSortDropdown from "../RelatedOrdersSortDropdown";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

const PageActions: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper} direction="row">
      <RelatedOrdersSortDropdown />
    </Grid>
  );
};

export default PageActions;
