import React from "react";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { Seller } from "src/providers/LineItemsStore/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useLocation, useParams } from "react-router-dom";
import { useStyles } from "src/pages/ServiceOrdersDetails/tabs/LineItems/components/Modals/styles";
import Preloader from "src/components/Preloader";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DecIcon from "src/pages/ServiceOrdersDetails/components/icons/DecIcon";
import IncIcon from "src/pages/ServiceOrdersDetails/components/icons/IncIcon";
import NumberFormat from "src/components/NumberFormat";
import uniqueId from "lodash/fp/uniqueId";
import CloseIcon from "src/pages/ServiceOrdersDetails/components/icons/CloseIcon";

function EditModal() {
  const { key } = useParams();
  const { pathname } = useLocation();
  const {
    LIData: { modal, editing, editData, sellersLookup },
    callLineItemsAPI,
  } = useLineItemsContext();
  const { t } = useTranslation(`lineItems`);
  const classes = useStyles();

  const handleDec = (ent: number) => {
    callLineItemsAPI.handleEdit({ qty: ent - 1 }, modal.type);
  };

  const handleInc = (ent: number) => {
    callLineItemsAPI.handleEdit({ qty: ent + 1 }, modal.type);
  };
  const handleEditDecimal = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.value !== `${modal?.qty}`) {
      callLineItemsAPI.handleEditDecimal({ qty: target.value }, modal.type);
    }
  };

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (e.target?.value !== `${modal.row?.lineItemType}`) {
      callLineItemsAPI.handleEdit({ lineItemType: e.target.value }, modal.type);
    }
  };

  const handleSellerChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (e.target?.value !== `${modal.row?.sellerId}`) {
      callLineItemsAPI.handleEdit({ sellerId: e.target.value }, modal.type);
    }
  };

  const handleSave = () => {
    callLineItemsAPI.saveEdit(key, pathname.split("/")[3]);
  };
  const handleClose = () => {
    callLineItemsAPI.handleModal({ open: "" });
  };
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.paper}
      open={modal.open === "edit"}
    >
      {editing ? (
        <div style={{ minWidth: 300, minHeight: 300 }}>
          <Preloader is />
        </div>
      ) : (
        <>
          <div>
            <IconButton onClick={handleClose} className={classes.closeBtn}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h2">{t("labels.edit_qty_title")}</Typography>
          </DialogTitle>
          <DialogContent>
            {!modal.row?.isDecimalQuantity ? (
              <>
                <Typography variant="body1">{t("labels.edit_qty")}</Typography>
                <Grid container direction="row" alignItems="center">
                  <IconButton
                    color="secondary"
                    onClick={() => handleDec(editData.qty || modal.row?.qty)}
                    style={{ height: 18, width: 18 }}
                  >
                    <DecIcon />
                  </IconButton>
                  <Typography variant="body1" style={{ padding: 8 }}>
                    {editData.qty || modal.row?.qty}
                  </Typography>
                  <IconButton
                    color="secondary"
                    onClick={() => handleInc(editData.qty || modal.row?.qty)}
                    style={{ height: 18, width: 18 }}
                  >
                    <IncIcon />
                  </IconButton>
                </Grid>
              </>
            ) : (
              <TextField
                onChange={handleEditDecimal}
                value={String(editData.qty || modal.row.qty)}
                fullWidth
                InputProps={{
                  inputComponent: NumberFormat as any,
                }}
                label={t("labels.edit_qty")}
                name="qty"
                variant="outlined"
              />
            )}
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel id="lineItemType">{t("labels.edit_invoice")}</InputLabel>
              <Select
                labelId="lineItemType"
                id="lineItemType"
                value={editData.lineItemType || modal.row?.lineItemType}
                fullWidth
                displayEmpty
                onChange={handleChange}
                name={"lineItemType"}
                variant={"outlined"}
                label={t("labels.edit_qty_title")}
              >
                <MenuItem value={1}>{t("labels.partner")}</MenuItem>
                <MenuItem value={2}>{t("labels.customer")}</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel id="sellerId">{t("labels.seller")}</InputLabel>
              <Select
                labelId="sellerId"
                id="sellerId"
                value={editData.sellerId || modal.row?.sellerId || ""}
                fullWidth
                displayEmpty
                onChange={handleSellerChange}
                name={"sellerId"}
                variant={"outlined"}
                label={t("labels.seller")}
              >
                {!!sellersLookup &&
                  sellersLookup.map((opt: Seller) => {
                    return (
                      <MenuItem key={uniqueId("sellerLookup")} value={`${opt.key}`}>
                        {opt.displayText}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions
            style={{
              padding: "8px 27px",
            }}
          >
            <Button
              style={{
                padding: "16px 22px",
                fontSize: 14,
                backgroundColor: "#2B59C3",
              }}
              type="submit"
              variant={"contained"}
              color={"primary"}
              onClick={handleSave}
              size={"large"}
            >
              save changes
            </Button>
            <Button
              style={{
                padding: "16px 22px",
                fontSize: 14,
                color: "#2B59C3",
              }}
              variant={"text"}
              color={"inherit"}
              type="button"
              size={"large"}
              onClick={() => {
                handleClose();
              }}
            >
              cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default EditModal;
