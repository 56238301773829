import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStyles } from "./styles";
import { Typography, TextField, Button, Grid, CircularProgress } from "@material-ui/core";
import { Customer, CustomerRequest } from "src/types";
import { requestPut } from "src/utils/crud";
import { AxiosResponse } from "axios";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotesEdit from "./SuccessNotesEdit";

const initialState = {
  notes: "",
};

interface Props {
  customer: Partial<Customer>;
  setContextData: (key: string, data: any) => void;
}

const EditNotes: React.FC<Props> = React.memo(({ customer, setContextData }) => {
  const {
    state: {
      modals: { editCustomerNotes },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const classes = useStyles();
  const [values, setValues] = useState<Partial<Customer>>(initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { t } = useTranslation("customers");

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "editCustomerNotes", status: false });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const submitNotes = async (data: Partial<CustomerRequest>) => {
    try {
      const res = await requestPut(`Customers/${customer.key}/notes`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const submitObject: Partial<CustomerRequest> = {
      notes: values.notes,
    };
    const customerRes = await submitNotes(submitObject);
    if ((customerRes as AxiosResponse).status === 200) {
      setSubmitted(true);
      setContextData("customer", (customerRes as AxiosResponse).data.customer);
    }
    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    setValues({
      ...customer,
    });
  }, []);

  if (customer.key === 0) return null;

  return (
    <Modal isOpen={editCustomerNotes} onClose={handleModalClose}>
      <Grid container justify="center">
        <Grid item className={classes.gridItem}>
          {!submitted && (
            <form onSubmit={handleSubmit}>
              <Typography variant="h3">{t("labels.details.edit_notes")}</Typography>
              <div className={classes.fieldGroup}>
                <TextField
                  fullWidth
                  label={t("labels.create_customer.customer_form.notes")}
                  placeholder={t("labels.create_customer.customer_form.notes")}
                  rows={4}
                  multiline
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                  variant="outlined"
                  inputProps={{ maxLength: 500 }}
                />
              </div>
              <div className={classes.btnGroup}>
                <Button className={classes.submitBtn} type="submit" ref={submitButtonRef}>
                  {loading ? (
                    <CircularProgress size={18} color={"secondary"} />
                  ) : (
                    t("labels.create_customer.customer_form.save")
                  )}
                </Button>
                <Button onClick={handleModalClose} className={classes.cancelBtn}>
                  {t("labels.create_customer.customer_form.cancel")}
                </Button>
              </div>
            </form>
          )}
          {submitted && <SuccessNotesEdit />}
        </Grid>
      </Grid>
    </Modal>
  );
});

export default EditNotes;
