import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import SuccessIcon from "src/pages/Orders/modals/EditForm/Success/icons/SuccessIcon";
import { useStyles } from "src/pages/Orders/modals/EditForm/Success/styles";
import { useTranslation } from "react-i18next";

const SuccessModal: React.FC<{ handlerOnOK: () => void }> = ({ handlerOnOK }) => {
  const { t } = useTranslation("orders");
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item className={classes.itemWrapper}>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">{t("labels.edit.successful")}</Typography>
        </div>

        <div className={classes.btnGroup}>
          <Button onClick={handlerOnOK} className={classes.submitBtn} type="submit">
            Ok
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessModal;
