import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const AdditionalInformationIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1H3.6C2.71634 1 2 1.71634 2 2.6V15.4C2 16.2837 2.71634 17 3.6 17H13.2C14.0837 17 14.8 16.2837 14.8 15.4V5.8L10 1Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10 1V5.8H14.8"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.6002 9.80078H5.2002"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.6002 13H5.2002"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.8002 6.59961H6.0002H5.2002"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default AdditionalInformationIcon;
