import makeStyles from "@material-ui/core/styles/makeStyles";

export const useSellersStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    padding: theme.spacing(2),
  },
  head: {
    paddingBottom: theme.spacing(3),
  },
  offSet: {
    marginTop: theme.spacing(3),
  },
  svg: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.375),
  },
}));
