import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SuccessfulIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#4ECDC4"
    />
    <path
      opacity="0.2"
      d="M20.8457 40.6308L35.2432 55.0283C47.1675 51.8485 56.0003 40.9841 56.0003 27.9999C56.0003 27.7349 56.0003 27.4699 56.0003 27.2049L44.6943 16.7822L20.8457 40.6308Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M27.0259 35.9512C28.2625 37.1878 28.2625 39.3077 27.0259 40.5443L26.1444 41.4258C24.9078 42.6624 22.788 42.6624 21.5514 41.4258L10.3337 30.1198C9.0971 28.8832 9.0971 26.7633 10.3337 25.5267L11.2152 24.6452C12.4518 23.4086 14.5717 23.4086 15.8083 24.6452L27.0259 35.9512Z"
      fill="white"
    />
    <path
      d="M40.1897 16.4309C41.4263 15.1943 43.5462 15.1943 44.7828 16.4309L45.6643 17.3124C46.9009 18.549 46.9009 20.6688 45.6643 21.9054L26.2321 41.2493C24.9955 42.4859 22.8756 42.4859 21.639 41.2493L20.7575 40.3678C19.5209 39.1312 19.5209 37.0113 20.7575 35.7747L40.1897 16.4309Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="28.0004"
        y1="26.2499"
        x2="49.0004"
        y2="46.8124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#051C2C" />
        <stop offset="1" stopColor="#051C2C" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
export default SuccessfulIcon;
