import React, { useCallback, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useFieldArray, useForm, FormProvider } from "react-hook-form";
import { CancelButton, SaveButton } from "src/components/Buttons";
import { useTranslation } from "react-i18next";
import ServiceFieldsRow from "./components/ServiceFields/ServiceFieldsRow";
import { Services, ServicesReq } from "src/providers/SODetailsStore/types";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import Success from "./components/Success";
import PlusIcon from "./icons/PlusIcon";

import { useSOContext } from "src/providers/ServiceOrdersStore";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";

const CreateServiceInBulk: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("orders");
  const {
    callAPI: { createServiceInBulk },
  } = useSODetailsContext();
  const [success, setSuccess] = useState<boolean>(false);
  const {
    SOData: { responseObject },
    callSOAPI: { setContextData },
  } = useSOContext();
  const methods = useForm<ServicesReq>({
    defaultValues: {
      services: [
        {
          serviceTypeId: undefined,
        },
      ],
    },
  });
  const { handleSubmit, control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
  });
  const addNewRow = () => {
    append({
      serviceTypeId: undefined,
      capacity: null,
    });
  };
  const removeRow = (index: number) => {
    remove(index);
  };
  const onSubmit = (data: ServicesReq) => {
    if (
      data.services &&
      data.services.length > 0 &&
      responseObject?.id &&
      responseObject?.owner?.key
    ) {
      const parsedReqData = data.services.map(
        (el): Services => {
          // Add required info
          return {
            ...el,
            ownerId: Number(responseObject?.owner?.key),
            serviceOrderId: Number(responseObject.id),
          };
        }
      );
      createServiceInBulk(parsedReqData).then((success) => {
        // Submit services collection
        //todo change order of modal windows
        setContextData("servicesCreted", true); // hides parent modal behind frontal modal
        setSuccess(success);
      });
    }
  };
  const handleModalClose = useCallback(() => {
    history.push(`/serviceOrders/details/${responseObject?.id}/summary`);
  }, []);

  return (
    <Grid container>
      {!success && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <Typography variant="h3">{t("create_services_in_bulk_form.title")}</Typography>
            <br />
            {!!fields.length && (
              <div className={classes.container}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("create_services_in_bulk_form.service_type")}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("create_services_in_bulk_form.start_date")}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("create_services_in_bulk_form.delivery_date")}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("labels.creation.service.capacity")}
                </Typography>
                <div />
                {fields.map((item, index) => {
                  return (
                    <ServiceFieldsRow
                      key={item.id}
                      item={item}
                      index={index}
                      removeRow={removeRow}
                    />
                  );
                })}
              </div>
            )}
            <div>
              <Button
                variant="text"
                size="large"
                className={classes.addMoreButton}
                startIcon={<PlusIcon />}
                onClick={addNewRow}
              >
                <Typography color="primary">Add more</Typography>
              </Button>
            </div>
            <Grid container wrap="nowrap" alignItems="center">
              <SaveButton label={t("labels.save", { ns: "common" })} type="submit" />
              <CancelButton
                label={t("labels.cancel", { ns: "common" })}
                onClick={handleModalClose}
              />
            </Grid>
          </form>
        </FormProvider>
      )}
      {/* Display success message */}
      <Success open={success} />
    </Grid>
  );
};

export default CreateServiceInBulk;
