import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { authenticationParameters } from "src/utils/config/authConfig";
import { useMsal } from "@azure/msal-react";
import bgImage from "./login-bg.jpeg";
import clsx from "clsx";

const useStyles = makeStyles({
  textWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    flexDirection: "column",
  },
  buttonWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
  },
  button: {
    width: "250px",
    height: "50px",
    color: "white",
    fontSize: "17px",
    borderRadius: "50px",
    border: "2px solid white",
    transition: "ease-out 1s",
    letterSpacing: "2px",
    "&:hover": {
      border: "2px solid white",
      backgroundColor: "#085c9c",
    },
  },
  title: {
    color: "white",
    fontWeight: 600,
    padding: "40px",
  },
  subtitle: {
    color: "white",
    paddingTop: 20,
  },
  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  wrap: {
    textAlign: "center",
    backgroundColor: "#003882",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  titleWrapper: {
    padding: "40px",
  },
  loader: {
    color: "#ffffff",
  },
  footerWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    marginBottom: 10,
  },
  footer: {
    color: "#ffffff",
    bottom: 10,
    lineHeight: "40px",
  },
  link: {
    color: "#ffffff",
  },
});

const Login = () => {
  const classes = useStyles();
  const { instance, inProgress } = useMsal();
  const handleLogin = () => {
    instance.loginRedirect({ ...authenticationParameters, prompt: "select_account" });
  };

  return (
    <div className={clsx(classes.wrap, classes.background)} color="primary">
      <div className={classes.textWrapper}>
        <Typography variant="h2" component="h2" className={classes.title}>
          Montera+
        </Typography>
        <Typography variant="h3" component="h3" className={classes.subtitle}>
          Partner Portal
        </Typography>
      </div>
      {inProgress === "handleRedirect" ? (
        <div
          className="loaderWrapper"
          style={{ height: 100, paddingTop: 300, background: "transparent", margin: 0 }}
        >
          <div className="loader">
            <div className="loader__bar" />
            <div className="loader__bar" />
            <div className="loader__bar" />
            <div className="loader__bar" />
            <div className="loader__bar" />
            <div className="loader__ball" />
          </div>
        </div>
      ) : (
        <div className={classes.buttonWrapper}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleLogin}
          >
            <span>Login</span>
          </Button>
        </div>
      )}
      <div className={classes.footerWrapper}>
        <Typography variant="h4" component="h4" className={classes.footer}>
          Driven and Developed by{" "}
          <a className={classes.link} href="https://aionis.io/" target="_blank" rel="noreferrer">
            Aionis
          </a>
          <br />
          (c) Montera AS. All rights reserved
        </Typography>
      </div>
    </div>
  );
};

export default Login;
