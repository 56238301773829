import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStyles } from "./styles";
import { AxiosResponse } from "axios";
import {
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { InvoiceAddress } from "src/types";
import { requestPut } from "src/utils/crud";
import SelectInput from "src/components/SelectInput";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "../SuccessNotification";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import InvoiceAddressIcon from "./icons/InvoiceAddressIcon";
import { useParams } from "react-router-dom";
import { CustomerDetailsType } from "../../type";

interface Props {
  data: Partial<InvoiceAddress>;
}

const initialStateInvoice = {
  address: "",
  state: "",
  city: "",
  zipCode: "",
  country: {
    key: undefined,
    displayText: "",
  },
};

const EditInvoiceAddress: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const { customerID } = useParams<CustomerDetailsType>();
  const [invoiceValues, setInvoiceValues] = useState<Partial<InvoiceAddress>>(initialStateInvoice);
  const [loading, setLoading] = useState<boolean>(false);
  // Set data as Main Address
  const [invoiceAsMain, setInvoiceAsMain] = useState<boolean>(false);
  const [isDefaultAddress, setAsDefaultAddress] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { editInvoiceAddressModal },
    },
    callAppAPI,
  } = useAppContext();
  const {
    selectedCustomer: { customer },
    callAPI: { getInvoiceAddresses },
  } = useCustomerDetailsContext();

  const handleModalClose = useCallback(() => {
    callAppAPI.toggleModal({ id: "editInvoiceAddressModal", status: false });
    setSubmitted(false);
  }, []);

  const handleInvoiceDropdownChange = (value: number) => {
    setInvoiceValues({
      ...invoiceValues,
      country: { key: value },
    });
  };

  const compareCurrentAndCustomer = () => {
    if (
      invoiceValues.address === customer.address &&
      invoiceValues.country?.key === customer.country?.key &&
      invoiceValues.zipCode === customer.zipCode?.displayText &&
      invoiceValues.state === customer.province?.displayText &&
      invoiceValues.city === customer.city?.displayText
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleInvoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceValues({ ...invoiceValues, [event.target.name]: event.target.value });
  };

  const handleInvoiceAsMain = () => {
    setInvoiceAsMain(!invoiceAsMain);
    setInvoiceValues({
      ...invoiceValues,
      address: customer.address as string,
      state: customer.province?.displayText as string,
      city: customer.city?.displayText as string,
      zipCode: customer.zipCode?.displayText as string,
      country: {
        key: customer.country?.key,
        displayText: customer.country?.displayText,
      },
    });
  };

  const handleMakeAsDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsDefaultAddress(event.target.checked);
  };

  const submitAddress = async (data: Partial<InvoiceAddress>) => {
    try {
      const res = await requestPut(`InvoiceAddresses/${invoiceValues.key}`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const addressRes = await submitAddress({
      ...invoiceValues,
      isDefault: isDefaultAddress,
      customerId: customerID,
    });

    if ((addressRes as AxiosResponse).status === 200) {
      setSubmitted(true);
      getInvoiceAddresses(Number(customerID));
    }

    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    if (invoiceAsMain) {
      const matchingValues = compareCurrentAndCustomer();
      !matchingValues && setInvoiceAsMain(false);
    }

    if (
      invoiceValues &&
      invoiceValues.address &&
      invoiceValues.zipCode &&
      invoiceValues.city &&
      invoiceValues.country &&
      invoiceValues.country.key
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [invoiceValues]);

  useEffect(() => {
    if (data) {
      setInvoiceValues(data);
      setAsDefaultAddress(!!data.isDefault);
    }
  }, [data, editInvoiceAddressModal]);

  return (
    <Modal isOpen={editInvoiceAddressModal} onClose={handleModalClose}>
      <Grid container justify="center">
        <Grid item>
          {!submitted && (
            <form onSubmit={handleSubmit}>
              <Typography variant="h3">{t("labels.details.edit_invoice_address")}</Typography>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <InvoiceAddressIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.create_customer.addresses_form.invoice_address")}
                  </Typography>
                </div>
                <TextField
                  required
                  fullWidth
                  name="address"
                  label={t("labels.create_customer.addresses_form.address")}
                  value={invoiceValues.address}
                  onChange={handleInvoiceChange}
                  variant="outlined"
                  helperText={t("labels.create_customer.addresses_form.address_helper")}
                  FormHelperTextProps={{
                    classes: { root: classes.addressCaption },
                  }}
                />

                <div className={classes.fieldWrapper}>
                  <TextField
                    required
                    fullWidth
                    name="zipCode"
                    label={t("labels.create_customer.addresses_form.zip")}
                    value={invoiceValues.zipCode}
                    onChange={handleInvoiceChange}
                    variant="outlined"
                  />
                  <TextField
                    required
                    fullWidth
                    name="city"
                    label={t("labels.create_customer.addresses_form.city")}
                    value={invoiceValues.city}
                    onChange={handleInvoiceChange}
                    variant="outlined"
                    className={classes.cityField}
                  />
                </div>
                <TextField
                  fullWidth
                  name="state"
                  label={t("labels.create_customer.addresses_form.state")}
                  value={invoiceValues.state}
                  onChange={handleInvoiceChange}
                  variant="outlined"
                />
                <SelectInput
                  required
                  onSelect={handleInvoiceDropdownChange}
                  label={t("labels.create_customer.addresses_form.country")}
                  name="country"
                  value={invoiceValues.country?.key!}
                  url="lookups/countries"
                  metaApi
                />
                <FormControlLabel
                  disabled={!customer}
                  control={
                    <Checkbox
                      onClick={handleInvoiceAsMain}
                      checked={invoiceAsMain}
                      color="primary"
                    />
                  }
                  label={t("labels.create_customer.addresses_form.invoice_as_main")}
                />
                <FormControlLabel
                  disabled={!customer}
                  control={
                    <Checkbox
                      onChange={handleMakeAsDefault}
                      checked={isDefaultAddress}
                      color="primary"
                    />
                  }
                  label={"Make as default Invoice address"}
                />
              </div>
              <div className={classes.btnGroup}>
                <Button
                  disabled={submitDisabled}
                  className={classes.submitBtn}
                  type="submit"
                  ref={submitButtonRef}
                >
                  {loading ? (
                    <CircularProgress size={18} color={"secondary"} />
                  ) : (
                    t("labels.common.save")
                  )}
                </Button>
                <Button onClick={handleModalClose} className={classes.cancelBtn}>
                  {t("labels.common.cancel")}
                </Button>
              </div>
            </form>
          )}
          {submitted && (
            <SuccessNotification
              message={t("labels.details.invoice_address_success")}
              onClose={handleModalClose}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EditInvoiceAddress;
