import React, { useEffect, useState } from "react";
import { requestGet, requestPost } from "src/utils/crud";
import { Context, Props, ServicesState } from "./types";
import createCtx from "../../utils/createCtx";
import { ServicesReq } from "src/types";

const [useCtx, Provider] = createCtx<Context>();

const initialState: ServicesState = {
  selectedSO: {},
  services: [],
  currentPage: 1,
  pageCount: 10,
  pageSize: 999,
  expandAll: false,

  targetId: undefined,
  totalCount: 0,
  success: false,
  loading: false,
};

function ServicesProvider(props: Props): React.ReactElement {
  const [servicesData, setServicesData] = useState(initialState);
  const { currentPage, pageSize, targetId } = servicesData;

  useEffect(() => {
    if (!!targetId) {
      callServicesAPI.fetchingServices(Number(targetId));
    }
  }, [currentPage, pageSize, targetId]);

  const fetchingServices = (id: number, size: number = pageSize): any => {
    setServicesLoading(true);
    requestGet<ServicesReq>(`ServiceOrders/${id}/services`, {
      page: currentPage,
      pageSize: size,
      sortBy: "name",
    })
      .then((res) => {
        setServicesData((prev) => ({
          ...prev,
          selectedSO: {
            ...prev.selectedSO,
            [id]: res.data,
          },
          loading: false,
        }));
        return res.data;
      })
      .catch((err) => console.log(err));
  };

  const setServicesLoading = (data: boolean) => {
    setServicesData((prev: ServicesState) => ({
      ...prev,
      loading: data,
    }));
  };

  const deleteServices = (keys: number[]) => {
    return requestPost("Services/Delete", { serviceIds: keys }).then((response) => {
      if (response.status === 200) return true;
      else return false;
    });
  };

  const setContextData = (key: string, data: any) => {
    setServicesData((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const toggleAll = () => {
    setServicesData((prev) => {
      return {
        ...prev,
        expandAll: !prev.expandAll,
      };
    });
  };
  const callServicesAPI = {
    setServicesLoading,
    fetchingServices,
    deleteServices,
    setContextData,
    toggleAll,
  };

  return (
    <Provider
      value={{
        servicesData,
        callServicesAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useServicesContext, ServicesProvider };
