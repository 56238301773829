import React, { FC } from "react";
import CommonList from "src/components/CommonList";
import SellerFieldList from "./SellerFieldList";
import SellersTableDetails from "./SellersTableDetails";
import { useCallback } from "react";
import { useMemo } from "react";
import { useSellersContext } from "../../../providers/SellersStore";
import { SellersWarehouse } from "src/providers/SellersStore/types";

const SellersList: FC = React.memo(() => {
  const { sellersStore } = useSellersContext();

  return (
    <CommonList<SellersWarehouse>
      listEntities={useMemo(() => sellersStore.warehouses, [])}
      entityFields={useCallback(
        (ent) => (
          <SellerFieldList data={ent.warehouse} />
        ),
        []
      )}
      entityDetails={useCallback(
        (ent) => (
          <SellersTableDetails data={ent.sellers} />
        ),
        []
      )}
    />
  );
});

export default SellersList;
