import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M6.57268 0C2.94418 0 0.00268555 2.9415 0.00268555 6.56999C0.00268555 10.1985 2.94418 13.14 6.57268 13.14C10.2012 13.14 13.1427 10.1985 13.1427 6.56999C13.1395 2.94278 10.1999 0.00314217 6.57268 0ZM6.57268 10.8548C4.20627 10.8548 2.2879 8.9364 2.2879 6.56999C2.2879 4.20358 4.20627 2.28521 6.57268 2.28521C8.93909 2.28521 10.8575 4.20358 10.8575 6.56999C10.8543 8.93512 8.9378 10.8516 6.57268 10.8548Z"
        fill="#141E30"
        fillOpacity="0.5"
      />
      <path
        d="M15.6624 14.0484L13.3772 11.7631C12.9307 11.3167 12.2069 11.3167 11.7604 11.7631C11.3139 12.2096 11.3139 12.9335 11.7604 13.3799L14.0456 15.6652C14.4921 16.1116 15.2159 16.1116 15.6624 15.6652C16.1089 15.2187 16.1089 14.4948 15.6624 14.0484Z"
        fill="#141E30"
        fillOpacity="0.5"
      />
      <circle cx="6.5" cy="6.5" r="4.5" fill="none" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default SearchIcon;
