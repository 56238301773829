import React, { PropsWithChildren, memo, useCallback, useState } from "react";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import { Collapse, ListItem, Grid, Typography } from "@material-ui/core";
import uniqueId from "lodash/fp/uniqueId";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import ArrowDownIcon from "../../icons/ArrowDownIcon";
import ArrowUpIcon from "../../icons/ArrowUpIcon";
declare module "react" {
  function memo<A, B>(Component: (props: A) => B): (props: A) => JSX.Element | null;
}
export interface CustomStyles {
  backgroundColor?: string;
  paddingLeft?: string;
}

interface Props<T> {
  listEntities: T[];
  entityFields: (ent: T) => JSX.Element;
  entityDetails?: (ent: T) => JSX.Element;
  openByDefault?: boolean;
  handlerOnOpen?: (event: React.ChangeEvent<{}>) => (ent: T) => void;
  handlerOnClose?: (event: React.ChangeEvent<{}>) => (ent: T) => void;
  classes?: Partial<ReturnType<typeof useStyles>>;
  total?: number;
  customStyles?: CustomStyles;
}

function AnalyticsList<D extends {}>({
  listEntities,
  entityFields,
  entityDetails,
  openByDefault,
  handlerOnOpen,
  handlerOnClose,
  classes = {},
  total,
  customStyles = { backgroundColor: "#dcdde0", paddingLeft: "8px" },
}: PropsWithChildren<Props<D>>) {
  const { t } = useTranslation("analytics");
  classes = useStyles(customStyles);
  const elemLen = listEntities.length;
  const [expands, updateList] = useState<string[]>(
    openByDefault ? listEntities.map((_, index) => `${index}`) : []
  );

  const handleChange = useCallback(
    (ent: D, index: number) => (event: React.ChangeEvent<{}>) => {
      if (expands.indexOf(`${index}`) < 0) {
        updateList([...expands, `${index}`]);
        if (typeof handlerOnOpen === "undefined") return;
        handlerOnOpen(event)(ent);
      } else {
        updateList([...expands.filter((ent) => ent !== `${index}`)]);
        if (typeof handlerOnClose === "undefined") return;
        handlerOnClose(event)(ent);
      }
    },
    [expands]
  );

  if (elemLen < 0) return null;

  return (
    <>
      {listEntities.map((ent, index) => {
        return (
          <div key={uniqueId("common")} className={classes.wrapper}>
            <ListItem button onClick={handleChange(ent, index)} className={classes.listItem}>
              {expands.indexOf(`${index}`) >= 0 ? (
                <ArrowUpIcon style={{ width: 16, height: 16 }} />
              ) : (
                <ArrowDownIcon style={{ width: 16, height: 16 }} />
              )}
              {entityFields(ent)}
            </ListItem>
            <Collapse in={expands.indexOf(`${index}`) >= 0} timeout="auto" unmountOnExit>
              <Divider variant={"fullWidth"} light />
              <AccordionDetails className={classes.detailsRoot}>
                {!!entityDetails && entityDetails(ent)}
              </AccordionDetails>
            </Collapse>

            {index === elemLen - 1 && total ? (
              <>
                <Divider variant={"fullWidth"} />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  className={classes.footerWrapper}
                >
                  <Grid item xs key={uniqueId("footer")} className={classes.footerTitle}>
                    <Typography variant="subtitle2" className={classes.boldText}>
                      {t("labels.reports.total")}
                    </Typography>
                  </Grid>
                  <Grid item xs key={uniqueId("footer")}></Grid>
                  <Grid item xs key={uniqueId("footer")} className={classes.footerTotal}>
                    <Typography variant="subtitle2" className={classes.boldText}>
                      {total.toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                      })}{" "}
                      kr
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </div>
        );
      })}
    </>
  );
}

export default memo(AnalyticsList);
