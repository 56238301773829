import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    minHeight: 56,
    zIndex: 20,
  },
  divider: {
    background: theme.palette.secondary.main,
  },
  listWrapper: {
    paddingTop: theme.spacing(3),
    "& .MuiListItemIcon-root": {
      minWidth: 42,
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 24px 8px 0",
    ...theme.mixins.toolbar,
    "@media (min-width: 600px)": {
      minHeight: 56,
    },
  },
  logoIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 24px",
    ...theme.mixins.toolbar,
    "@media (min-width: 600px)": {
      minHeight: 56,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 2,
    width: "calc(100% - 57px)",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.primary.main,
    background: theme.palette.background.paper,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    textTransform: "lowercase",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      position: "relative",
    },
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    flexShrink: 0,
    background: theme.palette.brandColors.dark,
    color: theme.palette.secondary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    width: drawerWidth,
    background: theme.palette.brandColors.dark,
    color: theme.palette.secondary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    color: theme.palette.secondary.main,
    background: theme.palette.brandColors.dark,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "visible",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    minHeight: 100,
    overflow: "auto",
    background: "#F2F6F9",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  errorContainer: {
    backgroundColor: theme.palette.primary.main,
    height: "100vh",

    "& > h3": {
      fontSize: "2rem",
      marginTop: "10rem",
      color: theme.palette.common.white,
    },
    "& > h4": {
      fontSize: "1.5rem",
      padding: "1rem",
      color: theme.palette.common.white,
    },
    "& > button": {
      margin: "2rem auto",
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& > svg": {
      width: 270,
      height: 95,
      display: "block",
      margin: "0 auto",
    },
  },
  hidden: {
    display: "none",
  },
}));
