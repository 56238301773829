import React from "react";
import { useSellersContext } from "src/providers/SellersStore";
import { Seller } from "src/providers/SellersStore/types";
import SellerFieldsList from "./SellerFieldsList";
import Pagination from "./Pagination";
import EditSeller from "../../components/EditSeller";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import TabsContainer from "src/components/TabsContainer";
import PageActions from "./components/PageActions";

const Sellers = () => {
  const { t } = useTranslation(`warehouses`);
  const {state: {modals}} = useAppContext()
  const {
    sellersStore: { sellers },
  } = useSellersContext();

  return (
    <TabsContainer title={t("labels.sellers", { ns: "warehouses" })}>
      <PageActions />
      {sellers.map((seller: Seller) => {
        return <SellerFieldsList seller={seller} key={Math.random()} />;
      })}
      <Pagination />
      {modals.editSellerModal && <EditSeller />}
    </TabsContainer>
  );
};

export default Sellers;
