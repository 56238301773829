import React, { useState, useRef, useCallback, useEffect } from "react";
import { useStyles } from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/styles";
import { AxiosResponse } from "axios";
import {
  Typography,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import { requestPut } from "src/utils/crud";
import SelectInput from "src/components/SelectInput";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/SuccessNotification";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import InvoiceAddressIcon from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/Icons/InvoiceAddressIcon";
import InvoiceRecipientIcon from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/Icons/InvoiceRecipientIcon";
import CreateInvoiceAddress from "src/pages/ServiceOrdersDetails/components/CreateInvoiceAddress";
import { InvoiceAddress } from "src/types";

const EditInvoiceInfo: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { editInvoiceAddressSO },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const {
    serviceOrderDetails: { customerInfo, serviceOrder, invoiceInfo, partnerInfo },
    callAPI: { setContextData },
  } = useSODetailsContext();

  interface IInvoice {
    invoiceAddress: {
      key: number;
      displayText?: string;
    };
    invoiceTo: {
      key: number;
      displayText?: string;
    };
    invoiceToGroup: string;
    invoiceToDescription?: string;
  }
  const [invoiceAddress, setInvoiceAddress] = useState<Partial<IInvoice>>({
    invoiceAddress: {
      key: invoiceInfo?.invoiceAddress?.key!,
      displayText: invoiceInfo?.invoiceAddress?.name,
    },
    invoiceTo: {
      key: invoiceInfo?.invoiceTo?.key!,
      displayText: invoiceInfo?.invoiceTo?.displayText,
    },
    invoiceToGroup: invoiceInfo?.invoiceToGroup,
    invoiceToDescription: invoiceInfo?.invoiceToDescription
  });

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "editInvoiceAddressSO", status: false });
    setSubmitted(false);
  }, []);

  const handleCreateInvoiceAddress = useCallback(() => {
    toggleModal({ id: "createInvoiceAddressModal", status: true });
  }, []);

  const handleInvoiceChange = (value: number) => {
    setInvoiceAddress({ ...invoiceAddress, invoiceAddress: { key: value } });
  };

  const handleInvoiceToChange = (event: React.ChangeEvent<any>) => {
    setInvoiceAddress({
      ...invoiceAddress,
      invoiceTo: {
        key: parseInt(event.target.value as string),
        displayText: event.target.selectedOptions[0].text,
      },
      invoiceToGroup: event.target.selectedOptions[0].parentElement.attributes[1].value,
    });
  };

  const handleInvoiceToDescriptionChange = (description: string) => {
    setInvoiceAddress({
      ...invoiceAddress,
      invoiceToDescription: description
    });
  }
  const submitInvoice = async (data: any) => {
    try {
      const res = await requestPut(`ServiceOrders/${serviceOrder.id}/invoiceInfo`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const invoiceRes = await submitInvoice(invoiceAddress);

    if ((invoiceRes as AxiosResponse).status === 200) {
      setSubmitted(true);
      const newInvoiceInfo = {
        ...invoiceInfo,
        invoiceAddress: {
          ...invoiceInfo?.invoiceAddress,
          name: (invoiceRes as AxiosResponse).data.invoiceInfo.invoiceAddress.name,
          key: (invoiceRes as AxiosResponse).data.invoiceInfo.invoiceAddress.key,
        },
        invoiceTo: {
          key: invoiceAddress.invoiceTo?.key,
          displayText: invoiceAddress.invoiceTo?.displayText,
        },
        invoiceToGroup: invoiceAddress.invoiceToGroup,
        invoiceToDescription: invoiceAddress.invoiceToDescription
      };
      setContextData("invoiceInfo", newInvoiceInfo);
    }
    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    invoiceInfo &&
      setInvoiceAddress({
        invoiceAddress: {
          key: invoiceInfo?.invoiceAddress?.key!,
          displayText: invoiceInfo?.invoiceAddress?.name,
        },
        invoiceTo: {
          key: invoiceInfo?.invoiceTo?.key!,
          displayText: invoiceInfo?.invoiceTo?.displayText,
        },
        invoiceToGroup: invoiceInfo?.invoiceToGroup,
        invoiceToDescription: invoiceInfo?.invoiceToDescription
      });
  }, [invoiceInfo]);

  const onInvoiceAddressSubmitHandler = (data: Partial<InvoiceAddress>) => {
    setInvoiceAddress((prev) => ({
      ...prev,
      invoiceAddress: {
        key: data.key!,
        displayText: data.name!,
      },
    }));
  };

  return (
    <>
      <Modal isOpen={editInvoiceAddressSO} onClose={handleModalClose}>
        <Grid container justify="center" style={{ width: "100%" }}>
          <Grid item style={{ width: "100%" }}>
            {!submitted && invoiceAddress && (
              <form onSubmit={handleSubmit}>
                <Typography variant="h3">
                  {t("labels.create_customer.addresses_form.change_invoice")}
                </Typography>
                <div className={classes.fieldGroup}>
                  <div className={classes.titleWrapper}>
                    <InvoiceAddressIcon />
                    <Typography variant="h4" className={classes.fieldTitle}>
                      {t("labels.create_customer.addresses_form.invoice_address")}
                    </Typography>
                  </div>
                  <SelectInput
                    required
                    onSelect={handleInvoiceChange}
                    label={t("labels.create_customer.addresses_form.choose_invoice")}
                    name="invoiceAddress"
                    responseKey="invoiceAddresses"
                    value={invoiceAddress.invoiceAddress?.key!}
                    url={`InvoiceAddresses/${customerInfo?.customer?.key}/lookups`}
                  />
                  <div>
                    <Typography variant="body1" className={classes.createSuggestion}>
                      {t("labels.create_customer.addresses_form.could_not_find_invoice")}
                    </Typography>
                    <Button className={classes.createBtn} onClick={handleCreateInvoiceAddress}>
                      <Typography variant="button">
                        {t("labels.create_customer.addresses_form.create_new")}
                      </Typography>
                    </Button>
                  </div>
                </div>
                <div className={classes.fieldGroup}>
                  <div className={classes.titleWrapper}>
                    <InvoiceRecipientIcon />
                    <Typography variant="h4" className={classes.fieldTitle}>
                      {t("labels.create_customer.addresses_form.invoice_recepient")}
                    </Typography>
                  </div>
                  <FormControl required variant="outlined" fullWidth>
                    <InputLabel htmlFor="grouped-native-select" className={classes.invoiceToLabel}>
                      {t("labels.create_customer.addresses_form.invoice_recepient")}
                    </InputLabel>
                    <Select
                      required
                      native
                      fullWidth
                      value={invoiceAddress?.invoiceTo?.key}
                      id="grouped-native-select"
                      name="invoiceToKey"
                      onChange={handleInvoiceToChange}
                    >
                      <optgroup
                        className={classes.optionGroup}
                        data-group="partners"
                        label={t("labels.create_customer.addresses_form.partner")}
                      >
                        <option
                          disabled={!Boolean(partnerInfo?.partner?.key)}
                          value={partnerInfo?.partner?.key}
                        >
                          {partnerInfo?.partner?.name ||
                            t("labels.create_customer.addresses_form.no_partner")}
                        </option>
                      </optgroup>
                      <optgroup
                        className={classes.optionGroup}
                        data-group="customers"
                        label={t("labels.create_customer.addresses_form.customer")}
                      >
                        <option
                          disabled={!Boolean(customerInfo?.customer?.key)}
                          value={customerInfo?.customer?.key}
                        >
                          {customerInfo?.customer?.name ||
                            t("labels.create_customer.addresses_form.no_customer")}
                        </option>
                      </optgroup>
                      <optgroup
                        className={classes.optionGroup}
                        data-group="0"
                        label="Other"
                      >
                        <option
                          // disabled={invoiceInfo?.invoiceTo.key === 0}
                          value={0}
                        >
                          {t("form.other", {ns:"orders"})}
                        </option>
                      </optgroup>
                    </Select>
                    {(invoiceAddress?.invoiceTo?.key === 0) && 
                      <TextField 
                        required
                        multiline
                        rows={4}
                        value={invoiceAddress.invoiceToDescription}
                        variant="outlined"
                        onChange={(e) => handleInvoiceToDescriptionChange(e.target.value)}
                      />
                    }
                  </FormControl>
                </div>
                <div className={classes.btnGroup}>
                  <Button className={classes.submitBtn} type="submit" ref={submitButtonRef}>
                    {loading ? (
                      <CircularProgress size={18} color={"secondary"} />
                    ) : (
                      t("labels.common.save")
                    )}
                  </Button>
                  <Button onClick={handleModalClose} className={classes.cancelBtn}>
                    {t("labels.common.cancel")}
                  </Button>
                </div>
              </form>
            )}
            {submitted && (
              <SuccessNotification
                message={t("labels.edit_customer.success.title")}
                onClose={handleModalClose}
              />
            )}
          </Grid>
        </Grid>
      </Modal>
      <CreateInvoiceAddress onSubmit={onInvoiceAddressSubmitHandler} />
    </>
  );
};

export default EditInvoiceInfo;
