import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import { useHistory } from "react-router-dom";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import ExpandCollapseButton from "../ExpandCollapseButton";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";
import { useAppContext } from "src/providers/AppProvider";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 68,
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

const PageActions: FunctionComponent = () => {
  const classes = useStyles();
  const {
    LIData: { lineItemsSO },
  } = useLineItemsContext();
  const { t } = useTranslation("lineItems");
  const history = useHistory();
  const {
    state: { user },
  } = useAppContext();
  const {
    relatedServicesState: { services },
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();

  const handlerLink = useCallback(() => {
    history.push(`/line_items/${serviceOrder.id}/${lineItemsSO?.items[0]?.service?.key}`);
  }, [lineItemsSO]);

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <Grid container className={classes.wrapper} direction="row">
      <ExpandCollapseButton />
      {isIntegrated && disabledByStatus && (
        <>
          <Divider orientation="vertical" />
          {serviceOrder?.statusMatchingName !== "COMPLETED" && !!services.length && (
            <Button startIcon={<AddIcon />} className={classes.text} onClick={handlerLink}>
              {t("labels.btn.add_new_line_item")}
            </Button>
          )}
        </>
      )}
    </Grid>
  );
};

export default PageActions;
