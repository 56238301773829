import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStyles } from "./styles";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  debounce,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Customer, CustomerRequest } from "src/types";
import { requestMetaGet, requestPost } from "src/utils/crud";
import { LookupObject, User } from "src/types";
import SelectInput from "src/components/SelectInput";
import CustomerAutocomplete from "./CustomerAutocomplete";
import CustomerSection from "./icons/CustomerSection";
import CustomerAddress from "./icons/CustomerAddress";
import ContactsIcon from "./icons/ContactsIcon";
import EmailIcon from "./icons/EmailIcon";
import AdditionalInformationIcon from "./icons/AdditionalInformationIcon";
import { AxiosResponse } from "axios";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import { useCustomerContext } from "src/providers/CustomerStore";
import PhoneInputField from "src/components/PhoneInput/";
import { parsePhoneNumberBeforeSubmit } from "src/utils/functions";
const initialState = {
  key: 0,
  firstName: "",
  lastName: "",
  mobile: "",
  altPhone: "",
  email: "",
  address: "",
  ssn: "",
  notes: "",
  city: { key: undefined, displayText: "" },
  province: { key: undefined, displayText: "" },
  zipCode: { key: undefined, displayText: "" },
  country: { key: undefined, displayText: "" },
  isBusiness: false,
};
interface Props {
  afterSubmit: (customer: Customer) => void;
  user: Partial<User>;
}

export const CreateCustomerForm: React.FC<Props> = React.memo(({ afterSubmit, user }) => {
  const classes = useStyles();
  const [values, setValues] = useState<Customer>(initialState);
  const [zipCodesOpen, setZipCodesOpen] = useState<boolean>(false);
  const [zipCodesLoading, setZipCodesLoading] = useState<boolean>(false);
  const [displayAltPhone, setAltPhoneDisplay] = useState<boolean>(false);
  const [zipCodeValues, setZipCodeValues] = useState<LookupObject[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation("customers");
  const { callAppAPI } = useAppContext();

  const {
    callAPI: { fetchCustomers },
  } = useCustomerContext();

  const handleModalClose = useCallback(() => {
    callAppAPI.toggleModal({ id: "createCustomerModal", status: false });
  }, []);

  const handleDisplayAltPhone = (value: boolean) => {
    setAltPhoneDisplay(value);
  };

  const getZipCodes = debounce(async (displayText: string) => {
    const zipCodes = await requestMetaGet<LookupObject[]>(
      `lookups/zipCodes?countryKey=${values.country.key}&displayText=${displayText}`
    );
    let response = zipCodes.data.map((item) => {
      return { key: item.key, displayText: item.displayText };
    });
    setZipCodeValues(response);
    setZipCodesLoading(false);
  }, 600);

  const getCascadeData = async (zipCode: string | number) => {
    return await requestMetaGet<Partial<Customer>>(
      `lookups/zipCodes/${zipCode}?countryKey=${values.country.key}`
    );
  };

  //Zip Code Autocomplete
  const handleAutocompleteChange = async (value: string, getNewData: boolean) => {
    if (getNewData) {
      setZipCodesOpen(true);
      setZipCodesLoading(true);
      await getZipCodes(value);
    } else {
      setZipCodesOpen(false);
    }

    setValues((prev) => ({ ...prev, zipCode: { ...prev.zipCode, displayText: value } }));
  };

  // Zip Code Select
  const handleAutocompleteSelect = (value: number, displayText: string) => {
    setValues((prev) => ({ ...prev, zipCode: { ...prev.zipCode, key: value } }));
    setZipCodesOpen(false);
    handleDataCascade(displayText);
  };

  const handleDataCascade = async (parentZipCode: string) => {
    const cascadeData = await getCascadeData(parentZipCode);
    if (cascadeData && cascadeData.data) {
      setValues({
        ...values,
        zipCode: {
          key: cascadeData.data.key,
          displayText: cascadeData.data.displayText,
        },
        city: {
          key: cascadeData.data?.city?.key,
          displayText: cascadeData.data?.city?.displayText,
        },
        province: {
          key: cascadeData.data?.province?.key,
          displayText: cascadeData.data?.province?.displayText,
        },
        country: {
          key: cascadeData.data?.country?.key,
          displayText: cascadeData.data?.country?.displayText,
        },
      });
    }
  };

  // Country Select
  const handleDropdownChange = (value: number) => {
    setValues({
      ...values,
      country: { key: value },
      zipCode: {
        key: undefined,
        displayText: "",
      },
      city: {
        key: undefined,
        displayText: "",
      },
      province: {
        key: undefined,
        displayText: "",
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "isBusiness") {
      setValues({
        ...values,
        [event.target.name]: event.target.checked,
        firstName: "",
        lastName: "",
        ssn: "",
      });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  };

  const createCustomer = async (data: Partial<CustomerRequest>) => {
    try {
      const res = await requestPost(`Customers?hasResponse=true`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const submitObject: Partial<CustomerRequest> = {
      firstName: values.firstName,
      lastName: values.lastName,
      mobile: parsePhoneNumberBeforeSubmit(values.mobile),
      altPhone: parsePhoneNumberBeforeSubmit(values.altPhone),
      email: values.email,
      address: values.address,
      ssn: values.ssn,
      notes: values.notes,
      cityId: Number(values.city.key),
      provinceId: Number(values.province.key),
      zipCodeId: Number(values.zipCode.key),
      countryId: Number(values.country.key),
      isBusiness: values.isBusiness,
    };

    const customerRes = await createCustomer(submitObject);
    if ((customerRes as AxiosResponse).status === 201) {
      fetchCustomers();
      afterSubmit((customerRes as AxiosResponse).data);
    }
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    !zipCodesOpen && setZipCodeValues([]);
  }, [zipCodesOpen]);

  useEffect(() => {
    if (
      values.firstName &&
      values.lastName &&
      values.address &&
      values.zipCode &&
      values.zipCode.key &&
      values.country &&
      values.email &&
      values.mobile
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h3">{t("labels.create_customer.customer_form.title")}</Typography>
      <div className={classes.fieldGroup}>
        <div className={classes.titleWrapper}>
          <CustomerSection />
          <Typography variant="h4" className={classes.fieldTitle}>
            {t("labels.create_customer.customer_form.customer_name")}
          </Typography>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              style={{ fill: "black !important" }}
              className={classes.checkbox}
              inputProps={{ style: { fill: "black" } }}
              checked={values.isBusiness}
              onChange={handleChange}
              name="isBusiness"
              color="primary"
            />
          }
          className={classes.formControllLabel}
          label={t("labels.create_customer.customer_form.is_business")}
        />
        {!values.isBusiness && (
          <>
            <TextField
              required
              fullWidth
              name="firstName"
              label={t("labels.create_customer.customer_form.first_name")}
              value={values.firstName}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              required
              fullWidth
              name="lastName"
              label={t("labels.create_customer.customer_form.last_name")}
              value={values.lastName}
              onChange={handleChange}
              variant="outlined"
            />
            {(user.territory?.alpha2 === "sv" || user.territory?.alpha2 === "se") && (
              <TextField
                fullWidth
                name="ssn"
                label={t("labels.create_customer.customer_form.ssn")}
                value={values.ssn}
                onChange={handleChange}
                variant="outlined"
              />
            )}
          </>
        )}
        {values.isBusiness && (
          <>
            <TextField
              required
              fullWidth
              name="firstName"
              label={t("labels.create_customer.customer_form.business_name")}
              value={values.firstName}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              required
              fullWidth
              name="lastName"
              label={t("labels.create_customer.customer_form.business_number")}
              value={values.lastName}
              onChange={handleChange}
              variant="outlined"
            />
          </>
        )}
      </div>
      <div className={classes.fieldGroup}>
        <div className={classes.titleWrapper}>
          <CustomerAddress />
          <Typography variant="h4" className={classes.fieldTitle}>
            {t("labels.create_customer.customer_form.address")}
          </Typography>
        </div>
        <TextField
          required
          fullWidth
          name="address"
          label="Address"
          value={values.address}
          onChange={handleChange}
          variant="outlined"
          helperText={t("labels.create_customer.customer_form.address_helper")}
          FormHelperTextProps={{
            classes: { root: classes.addressCaption },
          }}
        />
        <SelectInput
          required
          onSelect={handleDropdownChange}
          label={t("labels.create_customer.customer_form.country")}
          name="countryId"
          value={values.country.key || ""}
          url="lookups/countries/delivery"
          metaApi
        />
        <div className={classes.fieldWrapper}>
          <CustomerAutocomplete
            required
            options={zipCodeValues}
            name="zipCode"
            label={t("labels.create_customer.customer_form.zip")}
            inputValue={values.zipCode.displayText}
            open={zipCodesOpen}
            setOpen={setZipCodesOpen}
            loading={zipCodesLoading}
            setLoading={setZipCodesLoading}
            onChange={handleAutocompleteChange}
            onSelect={handleAutocompleteSelect}
          />
          <TextField
            required
            fullWidth
            disabled
            name="cityId"
            label={t("labels.create_customer.customer_form.city")}
            value={values.city.displayText}
            onChange={handleChange}
            variant="outlined"
            className={classes.cityField}
          />
        </div>
        <TextField
          fullWidth
          disabled
          name="provinceId"
          label={t("labels.create_customer.customer_form.province")}
          value={values.province.displayText}
          onChange={handleChange}
          variant="outlined"
        />
      </div>
      <div className={classes.fieldGroup}>
        <div className={classes.titleWrapper}>
          <ContactsIcon />
          <Typography variant="h4" className={classes.fieldTitle}>
            {t("labels.create_customer.customer_form.contacts")}
          </Typography>
        </div>
        <TextField
          required
          fullWidth
          name="email"
          label={t("labels.create_customer.customer_form.email")}
          value={values.email}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            type: "email",
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.fieldGroup}>
        <PhoneInputField
          value={values.mobile}
          label={t("labels.create_customer.customer_form.mobile")}
          name="mobile"
          onChangeHandler={(value) => setValues({ ...values, mobile: value })}
        />
      </div>
      {displayAltPhone ? (
        <div className={classes.fieldGroup}>
          <PhoneInputField
            value={values.altPhone}
            label={t("labels.create_customer.customer_form.alt_phone")}
            labelWidth={180}
            name="altPhone"
            onChangeHandler={(value) => setValues({ ...values, altPhone: value })}
          />
        </div>
      ) : (
        <>
          <Button onClick={() => handleDisplayAltPhone(true)} className={classes.addAltPhone}>
            <Typography variant="subtitle2">
              {t("labels.create_customer.customer_form.add_alt_phone")}
            </Typography>
          </Button>
          <Typography variant="body1" className={classes.addAltphoneText}>
            {t("labels.create_customer.customer_form.alt_optional")}
          </Typography>
        </>
      )}
      <div className={classes.fieldGroup}>
        <div className={classes.titleWrapper}>
          <AdditionalInformationIcon />
          <Typography variant="h4" className={classes.fieldTitle}>
            {t("labels.create_customer.customer_form.additional_info")}
          </Typography>
        </div>
        <TextField
          fullWidth
          label={t("labels.create_customer.customer_form.notes")}
          placeholder={t("labels.create_customer.customer_form.notes")}
          rows={4}
          multiline
          name="notes"
          value={values.notes}
          onChange={handleChange}
          variant="outlined"
          inputProps={{ maxLength: 500 }}
        />
      </div>
      <div className={classes.btnGroup}>
        <Button
          disabled={submitDisabled}
          className={classes.submitBtn}
          type="submit"
          ref={submitButtonRef}
        >
          {t("labels.create_customer.customer_form.save")}
        </Button>
        <Button onClick={handleModalClose} className={classes.cancelBtn}>
          {t("labels.create_customer.customer_form.cancel")}
        </Button>
      </div>
    </form>
  );
});
