import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Hint from "src/components/Hint";
import { useTranslation } from "react-i18next";
import { requestGet } from "src/utils/crud";
import PieChartIcon from "src/pages/Orders/components/icons/PieChartIcon";
import { IconButton } from "@material-ui/core";

interface Props {
  serviceOrderKey: number;
}
const ServiceStatusesTooltip: React.FC<Props> = ({ serviceOrderKey }) => {
  const [chartData, setChartData] = useState<Highcharts.Options | null>(null);
  const { t } = useTranslation("orders");

  const getChartData = async () => {
    const res = await requestGet<Highcharts.Options>(
      `dashboards/GetServiceOrderStatusesVisual/${serviceOrderKey}`
    );
    let responseObject = res.data;
    if (responseObject?.chart) {
      responseObject.chart.height = 200;
      responseObject.chart.width = 300;
    }
    if (responseObject?.title) {
      responseObject.title.text = t("labels.row.service_statuses");
    }
    setChartData(responseObject);
  };

  const handleMouseEnter = () => {
    if (!serviceOrderKey || chartData) return;

    getChartData();
  };

  return (
    <Hint
      onMouseEnter={handleMouseEnter}
      title={<HighchartsReact highcharts={Highcharts} options={chartData} />}
    >
      <IconButton>
        <PieChartIcon />
      </IconButton>
    </Hint>
  );
};

export default ServiceStatusesTooltip;
