import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: 400,
  },
  actions: {
    padding: theme.spacing(3),
    justifyContent: "flex-start",
    "& > .MuiButton-root": {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  closeBtn: {
    float: "right",
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
}));
