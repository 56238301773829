import React from "react";
import { TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import { LookupObject } from "src/types/";

interface Props {
  required?: boolean;
  disabled?: boolean;
  allowEmpty?: boolean;
  onSelect: (value: number) => void;
  label: string;
  name: string;
  value: number | string | null;
  fullWidth?: boolean;
  customTextFieldStyle?: React.CSSProperties;
  data: LookupObject[];
}

const PureSelectInput: React.FC<Props> = ({
  required = false,
  disabled = false,
  allowEmpty = true,
  onSelect,
  label,
  name,
  value,
  fullWidth = false,
  customTextFieldStyle,
  data,
}) => {
  const classes = useStyles();
  const onSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(Number(e.currentTarget.value));
  };

  return (
    <TextField
      select
      required={required}
      disabled={disabled}
      onChange={onSelectHandler}
      label={label}
      name={name}
      value={value || ""}
      variant="outlined"
      fullWidth={fullWidth}
      style={customTextFieldStyle}
      SelectProps={{
        native: true,
      }}
      className={classes.root}
      InputLabelProps={{ shrink: true }}
    >
      <option key="" value="" disabled={!allowEmpty}></option>
      {data.map((el) => (
        <option key={el.key} value={el.key}>
          {el.displayText}
        </option>
      ))}
    </TextField>
  );
};

export default PureSelectInput;
