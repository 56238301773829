import React, { FC } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Fade from "@material-ui/core/Fade";
import { Grid, ListItemText, Typography } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { useCStyles } from "./style";
import { useCommentsContext } from "src/providers/CommentsStore";

interface Props {
  displayText?: string;
  matchingText?: string;
  id: number;
  icon: JSX.Element;
  count?: number | string | JSX.Element;
  isOpen: boolean;
  channelKey?: string | number;
  onClick?: (id: number) => void;
}

const ChannelItem: FC<Props> = ({
  displayText = "",
  matchingText,
  id,
  icon,
  count,
  isOpen,
  channelKey = "",
  onClick = () => {},
}) => {
  const {
    comments: { selectedChannel, channelsUnreadComments },
  } = useCommentsContext();
  const classes = useCStyles({ isOpen: false });
  const amountOfComments = channelsUnreadComments.find(
    (channel) => Number(channel.channelId) === Number(channelKey)
  ) || { countUnreadComments: 0 };
  return (
    <ListItem
      button
      className={classes.menuItem}
      onClick={() => {
        onClick(id);
      }}
      id={`${id}`}
      selected={id === selectedChannel}
    >
      <ListItemAvatar
        style={{
          minWidth: 32,
        }}
      >
        {icon}
      </ListItemAvatar>
      <Fade in={!isOpen}>
        <div style={{ width: "100%" }}>
          <ListItemText
            primary={
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="body2" style={{ textTransform: "capitalize" }}>
                  {!!displayText ? displayText : matchingText}
                </Typography>
                {!!amountOfComments?.countUnreadComments && (
                  <Grid className={classes.unreadedCommentsBadge}>
                    {amountOfComments.countUnreadComments}
                  </Grid>
                )}
              </Grid>
            }
          />
          <ListItemSecondaryAction>
            {!!count && typeof count === "string" ? (
              { count }
            ) : (
              <Typography variant="body1">{!!count && count}</Typography>
            )}
          </ListItemSecondaryAction>
        </div>
      </Fade>
    </ListItem>
  );
};

export default ChannelItem;
