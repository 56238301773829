import React from "react";
import { File } from "src/types";
import { AxiosResponse } from "axios";

export interface ContextDetails {
  comments: CommentsState;
  callAPI: {
    getThreadList: (key: number | string) => void;
    getChanelThreads: (key: number | string) => void;
    getChatChannels: () => Promise<AxiosResponse<ChannelItem[]> | undefined>;
    postReadedComments: (unreadMessages: string | string[]) => void;
    getThreadMsgs: (id: number | string, currentChannelKey: string, withLoading?: boolean) => void;
    setContextData: (key: string, data: any) => void;
    getCommentsFiles: (forThreadOnly: boolean, sortBy?: { sortBy: string; des: boolean }) => void;
    setDataCallback: (key: keyof CommentsState, callback: (data: any) => CommentsState) => void;
    addThread: () => void;
    clearMsgList: () => void;
    updateState: (data: any) => void;
    shareMsg: (data: any) => void;
    sendMsg: (data: SendMsg) => void;
    shareByEmail: (data: ShareComment) => void;
  };
}
export type ServicesType = "service" | "serviceOrder";

export interface Props {
  children?: React.ReactElement;
  context: ServicesType;
}
export interface ChannelItem {
  key?: number | string;
  matchingText: string;
  displayText?: string;
  label?: string;
  icon: string;
  count: string | number;
}
export interface Threads {
  title: string;
  matchingText: string;
  channelKey: string;
  visibleMessageText: string;
  key: string;
  displayText: string;
  id: string;
  visibleMessageCreatedDate: string | number | Date;
  isDefault: boolean;
  parentEntityId: string;
  parentEntityName: string;
  parentEntityType: string;
  countUnreadComments: string;
  visibleMessageAuthor: string;
}
export interface MessageThread {
  threadKey: string;
  threadChannelKey: string;
  entityKey: string;
  entityType: string;
}
export interface SendMsg {
  messageText: string;
  messageThread: MessageThread;
}
export interface Loading {
  thread: boolean;
  channels: boolean;
  services: boolean;
  serviceOrders: boolean;
  msgs: boolean;
  files: boolean;
}
export interface CommentsMeta {
  key: string;
  orderType: string;
}

export interface User {
  firstName: string;
  lastName: string;
}

export interface Messages {
  childMessages: [];
  createdBy: CreatedByType;
  createdDate: string;
  files: [];
  key: string;
  sharedDate: string | number;
  sharedUser: CreatedByType;
  isImportant: boolean;
  isShared: boolean;
  isSharedByEmail: boolean;
  sharedToEmails: string[];
  isUnread: boolean;
  message: string;
  sharedChannel: string;
  sharedThread: string;
  threadKey: string;
}
interface CreatedByType {
  key: string;
  firstName: string;
  lastName: string;
}
export interface ChatThead {
  key: string;
  messages: Messages[];
  title: string;
  parentEntityKey: string;
  parentEntityType: string;
  channelKey?: string[];
  headKey?: string;
  isDefault?: false;
}

export interface Channels {
  channelId: string;
  countUnreadComments: string;
}

export interface FileType {
  finished: boolean;
  verifyKey: string | number;
  type: string;
  description?: string;
  fileName?: string;
  fileSign: string;
  modifiedDateUtc: string;
  mimeType: string;
  name: string;
  fileServiceId: string;
  serviceOrderId: string;
  id: string;
  key: string;
  size?: string | number;
  categoryId?: string | number;
  category?: { key: string | number; displayText: string };
  createdBy?: { key: string | number; displayText: string };
}

export enum EmailType {
  Warehouse,
}

export type EmailItem = {
  key: number;
  email: string;
  displayText: string;
  type: EmailType;
};

export type Comment = {
  key: number;
  message: string;
  sharedChannel: string;
  createdDate: string;
  createdBy: {
    firstName: string;
    lastName: string;
  };
};

export type ShareComment = {
  warehouseEmail: string;
  customEmails: string;
  warehouseSellerEmails: string;
  isIncludeThread: boolean;
};

export type WarehouseSeller = {
  key: number | string;
  displayText: string;
  email: string;
};

export interface CommentsState {
  threads: Threads[];
  threadsSelected?: Threads;
  newThread: {};
  chatchannels: ChannelItem[];
  meta: CommentsMeta;
  chatThread: ChatThead;
  selectedChannel: number;
  selectedThread: number;
  isAddNewThread: boolean;
  selectedEntType: string;
  loading: Loading;
  fileList: File[];
  channelsUnreadComments: Channels[];
  topicDataIsValid: boolean;
  chatTab: number;
  readedMessages: string[];
  uploadedFiles: FileType[] | [];
  totalCount: string | number;
  openEditThread: string;
  scrollToBottom: boolean;
  error: boolean;
  errorMsg: string;
  totalThreads: number;

  sharingByEmailOpen: boolean;
  shareByEmail: boolean;
  shareCommentByEmail: Comment | null;
  shareEmails: EmailItem[];
  shareWarehouseKey: number | null;
  sharingByEmail: boolean;

  displayDragAndDrop: boolean;
}
