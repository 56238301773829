import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { useStyles } from "./styles";
import CloseIcon from "./CloseIcon";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  children: JSX.Element;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  displayCloseIcon?: boolean;
}

const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  children,
  maxWidth = "sm",
  displayCloseIcon = true,
}) => {
  const classes = useStyles();
  return (
    <Dialog fullWidth open={isOpen} onClose={onClose} style={{ zIndex: 1300 }} maxWidth={maxWidth}>
      {displayCloseIcon && (
        <div>
          <IconButton onClick={onClose} className={classes.closeBtn}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      )}
      {title && <DialogTitle className={classes.titleStyles}>{title}</DialogTitle>}
      <DialogContent className={classes.contentStyles}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
