import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import uniqueId from "lodash/fp/uniqueId";

const HeadRelatedService = React.memo(() => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { serviceId } = useParams();
  const {
    relatedServicesState: { services },
  } = useSODetailsContext();

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    let link: string[] | unknown = pathname.split("/");
    if (Array.isArray(link)) {
      if (!!serviceId) {
        link[link.length - 1] = event.target.value;
      } else {
        link[link.length] = event.target.value;
      }
      history.push({
        pathname: `${link.join("/")}`,
        state: history?.location?.state
      });
    }
  };
  if (services.length <= 0) return null;
  return (
    <Select value={serviceId} variant={"outlined"} onChange={handleChange}>
      {services.map((service) => {
        return (
          <MenuItem
            key={uniqueId("li")}
            value={`${service.id}`}
          >{`${service.name} ${service.serviceType.displayText}`}</MenuItem>
        );
      })}
    </Select>
  );
});

export default HeadRelatedService;
