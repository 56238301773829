import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TablePagination, { TablePaginationProps } from "@material-ui/core/TablePagination";
import { Typography, TextField, Button } from "@material-ui/core";
import { TableCellBaseProps } from "@material-ui/core/TableCell/TableCell";
import { useStyles } from "./styles";
interface Props {
  pagecount?: number | string;
  currentpage?: number | string;
  component?: React.ElementType<TableCellBaseProps>;
  onSpecificPageChange?: (value: number) => void;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
}
const Pagination = React.memo(
  ({ onSpecificPageChange, ...props }: TablePaginationProps & Props) => {
    const classes = useStyles();
    const [error, setError] = useState(false);
    const [active, setActive] = useState(false);
    const [newPage, setNewPage] = useState(props.currentpage);
    const inputRef = useRef(null);
    const { t } = useTranslation("common");
    const handleInputError = (val: boolean) => {
      setError(val);
    };

    const handleInputButtonClick = () => {
      setActive(true);
    };

    // Function detects if user clicked outside the editable pagination input, and sets it to 'inactive'.
    const useOutsideAlerter = (ref: any) => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActive(false);
          handleInputError(false);
        }
      }
      useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      });
    };
    // Initiates event listener attachment for pagination input
    useOutsideAlerter(inputRef);

    const isOutOfRange = (num: number) => {
      if (num > 0 && num <= Math.ceil(props.count / props.rowsPerPage)) {
        return false;
      } else {
        return true;
      }
    };

    const handleSpecificChange = (event: any) => {
      // If new page number out of range, do not redirect and display error.
      setNewPage(event.target.value);
      if (isOutOfRange(event.target.value)) {
        handleInputError(true);
      } else {
        handleInputError(false);
        onSpecificPageChange && onSpecificPageChange(event.target.value);
      }
    };

    return (
      <div className={classes.paginationWrapper}>
        <div className={classes.btnWrapper}>
          {error ? (
            <span className={classes.error}>
              <Typography variant="caption" className={classes.errorText}>
                {t("labels.wrong_page")}
              </Typography>
            </span>
          ) : (
            <></>
          )}
          <Typography variant="body2">{t("labels.page")}</Typography>
          {active ? (
            <TextField
              error={error}
              ref={inputRef}
              autoFocus
              required
              variant="outlined"
              value={newPage}
              name="page"
              onChange={handleSpecificChange}
              className={classes.editPageNum}
              InputProps={
                error
                  ? {
                      className: classes.inputError,
                    }
                  : {
                      className: classes.input,
                    }
              }
            />
          ) : (
            <Button className={classes.inputButton} onClick={handleInputButtonClick}>
              <Typography className={classes.currentPage} variant="body2">
                {props.currentpage}
              </Typography>
            </Button>
          )}
          <Typography variant="body2">
            {t("labels.of")} {props.pagecount}
          </Typography>
        </div>
        <TablePagination {...props} />
      </div>
    );
  }
);

export default React.memo(Pagination);
