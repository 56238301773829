import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { CancelButton, SaveButton } from 'src/components/Buttons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
    onAccept: (status:boolean) => void;
    onCancel: (status:boolean) => void;
    subtitle: TFunction
}

const DeleteAddressConformationDialog:React.FC<Props> = ({onAccept, onCancel, subtitle}) => {
  
  const { t } = useTranslation("customers");

  const handleAccept = () => {
    onAccept(true);
  };

  const handleCancel = () => {
    onCancel(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle disableTypography={true}>
          <Typography variant="h3" align="center">
            {t("delete_address_confirmation_dialog.title")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText >
            {subtitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleCancel} label={t("labels.cancel", {ns: "common"})}/>
          <SaveButton onClick={handleAccept} label={t("labels.delete", {ns: "common"})} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteAddressConformationDialog;