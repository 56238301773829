import React, { useEffect, useState } from "react";
import isEmpty from "lodash/fp/isEmpty";
import NoData, { useStyles as useNodDataStyle } from "src/pages/LIDetailsPage/components/NoData";
import { Typography, IconButton } from "@material-ui/core";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { Trans, useTranslation } from "react-i18next";
import CustomTable from "../../components/CustomTable";
import { CustomItems, ProductCategoryItem } from "src/providers/LineItemsStore/types";
import { UseExpandedInstanceProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import IkeaDirIcon from "../icons/IkeaDirIcon";
import add from "../icons/add.svg";
import useCurrencyFormat from "src/utils/hooks/currency";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ColumnProps } from "./types";

export const columns = (handler: (data: Partial<CustomItems>) => void, prepareValue: any) => {
  return [
    {
      Header: ({ getToggleAllRowsExpanded = () => {}, isAllRowsExpanded }: ColumnProps) => (
        <IconButton onClick={getToggleAllRowsExpanded}>
          <ExpandMoreIcon
            style={
              !!isAllRowsExpanded
                ? {
                    transform: "rotate(180deg)",
                    transition: ".2s",
                  }
                : {
                    transform: "rotate(0deg)",
                    transition: ".2s",
                  }
            }
          />
        </IconButton>
      ),
      filterable: false,
      resizable: false,
      id: "exp",
      sortable: false,
      width: "30%",
      Cell: ({
        row,
      }: UseExpandedInstanceProps<ProductCategoryItem> & {
        row: UseExpandedRowProps<ProductCategoryItem> & UseTableRowProps<ProductCategoryItem>;
      }) => (row.canExpand ? <IkeaDirIcon /> : null),
    },
    {
      Header: () => <Trans ns={"lineItems"} i18nKey="labels.item" />,
      id: "name",
      width: 400,
      sortable: false,
      accessor: (ent: { name: string }) => ent.name,
    },
    {
      Header: () => <Trans ns={"lineItems"} i18nKey="labels.unit_price" />,
      id: "unit_price",
      width: "30%",
      accessor: (ent: { value: number | string }) => ent.value,
      Cell: (ent: any) => {
        return prepareValue(ent.value);
      },
    },
    {
      id: "actions",
      width: "30%",
      Cell: (ent: any) => {
        return (
          !ent.row.canExpand && (
            <IconButton
              color="primary"
              onClick={() => {
                handler(ent.row.original);
              }}
              size={"small"}
              aria-label="add"
              component="span"
            >
              <img src={add} style={{ height: 14, width: 14 }} alt="add" />
            </IconButton>
          )
        );
      },
    },
  ];
};

export default function PartnerItems() {
  const { nothingTitle, nothingSubtitle } = useNodDataStyle();
  const { callLineItemsAPI } = useLineItemsContext();
  const {
    LIData: { priceCatalog },
  } = useLineItemsContext();
  const { serviceId } = useParams();
  const { currencyFormat } = useCurrencyFormat();
  const handleAdd = React.useCallback(
    (data: Partial<CustomItems>) => {
      callLineItemsAPI.addItemToInvoice(data, serviceId);
    },
    [serviceId]
  );
  const [data, setData] = useState(() => columns(handleAdd, currencyFormat));
  const { t } = useTranslation(`lineItems`);

  useEffect(() => {
    setData(() => columns(handleAdd, currencyFormat));
  }, [serviceId]);

  if (isEmpty(priceCatalog)) {
    return (
      <div style={{ height: "800px", overflow: "auto" }}>
        <NoData>
          <Typography className={nothingTitle}>{t("labels.li_not_available")}</Typography>
          <Typography align="left" variant="subtitle2" className={nothingSubtitle}>
            {t("labels.contact_to_admin")}
          </Typography>
        </NoData>
      </div>
    );
  }

  return (
    <div style={{ height: "800px", overflow: "auto" }}>
      <CustomTable<ProductCategoryItem> data={priceCatalog} columnsData={data} />
    </div>
  );
}
