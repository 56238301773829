import React from "react";
import { List, ListItemText, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import ListItem from "@material-ui/core/ListItem";
import { useTranslation } from "react-i18next";
import Preloader from "src/components/Preloader";
import { useSODetailsContext } from "src/providers/SODetailsStore";

const Service = ({ isOpen }: { isOpen?: boolean }) => {
  const { t } = useTranslation();
  const {
    relatedServicesState: { services, loading },
  } = useSODetailsContext();
  const history = useHistory();
  const toUrl = (id: string | number) => {
    history.push(`/services/details/${id}/comments`);
  };

  if (loading) {
    return <Preloader is />;
  }
  if (services.length <= 0) {
    return (
      <span style={{ paddingLeft: 36, color: "rgba(0, 0, 0, 0.12)" }}>
        ...{t("common.empty")}...
      </span>
    );
  }
  return (
    <List component="nav" dense style={{ overflow: "auto", maxHeight: 200 }}>
      {services.map((ent) => (
        <ListItem
          onClick={() => {
            toUrl(ent.id || ent.key);
          }}
          style={{
            paddingLeft: 36,
            cursor: "pointer",
          }}
          key={Math.random()}
        >
          <Fade in={!isOpen}>
            <ListItemText
              primary={(<>
                <Typography variant="caption" style={{ textTransform: "capitalize" }}>
                  {ent.name}
                </Typography>
                <Typography variant="body2" style={{ textTransform: "capitalize" }} noWrap={true}>
                  {ent.serviceType.displayText}
                </Typography>
              </>)}
            />
          </Fade>
        </ListItem>
      ))}
    </List>
  );
};

export default Service;
