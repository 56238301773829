import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles<Theme>((theme) => ({
  dropWrap: {
    border: "1px dashed rgba(20, 30, 48, 0.3)",
    borderRadius: 4,
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.05)",
    height: 140,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "10px 0px",
  },
  dropWrapDisabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  isDragActive: {
    border: `2px dashed ${theme.palette.primary.main} !important`,
  },
}));
