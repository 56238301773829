import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const AddSingleServiceIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0V4.8H14.8"
      fill="#C5DBF2"
    />
    <path
      d="M14.8 5.8V14.4C14.8 15.2837 14.0837 16 13.2 16H3.6C2.71634 16 2 15.2837 2 14.4V1.6C2 0.716344 2.71634 0 3.6 0H9V4.8C9 5.35228 9.44772 5.8 10 5.8H14.8Z"
      fill="#C5DBF2"
    />
    <path d="M10 4.8V0L14.8 4.8H10Z" fill="#C5DBF2" />
  </SvgIcon>
);
export default AddSingleServiceIcon;
