import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  rowFields: {
    margin: "0 0 0 8px",
  },
  zeroPad: { padding: 0 },
  subTotal: { textAlign: "right" },
  styledLink: {
    color: theme.palette.functionalColors.dark,
    padding: theme.spacing(2),
    fontWeight: 500,
  },
  firstItem: {
    display: "flex",
    alignItems: "center",
  },
  headerWrapper: {
    margin: "0 0 8px 8px",
  },
}));
