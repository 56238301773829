import React from "react";
import { useStyles } from "src/pages/Orders/CreationSO/styles";
import { TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import AccordionWrapped from "src/pages/Orders/components/Accordion";
import { PartnerInfoSummary, CustomerInfoSummary, ProjectInfoSummary } from "./Info";
import Divider from "@material-ui/core/Divider";
import SOIcon from "src/pages/Orders/CreationSO/icons/SOIcon";
import ReferencesIcon from "src/pages/Orders/CreationSO/icons/ReferencesIcon";
import DescIcon from "src/pages/Orders/CreationSO/icons/DescIcon";
import TextArea from "src/components/TextArea";

const ProjectInfo = React.memo(() => {
  const data = useFormContext();
  const { register, errors, watch, setValue } = data;
  const classes = useStyles();
  const { t } = useTranslation("orders");

  return (
    <Grid container spacing={6} justifyContent="center" direction="row">
      <Grid item xs={5}>
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            {t("labels.creation.new_so")}
          </Typography>
          <br />
          <Typography className={classes.stepTitle}>{t("labels.steps.step3")}</Typography>
        </div>
        <form>
          <div className={classes.fieldGroup}>
            <div className={classes.titleWrapper} style={{ flexDirection: "column" }}>
              <div className={classes.titleWrapper}>
                <SOIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("labels.creation.project_name")}
                </Typography>
              </div>
              <TextField
                fullWidth
                error={!!errors.optionalName}
                name="optionalName"
                label={t("form.so_name")}
                inputRef={register()}
                variant="outlined"
              />
              <div className={classes.titleWrapper}>
                <ReferencesIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("labels.creation.references_op")}
                </Typography>
              </div>
              <TextField
                fullWidth
                error={!!errors.externalId}
                name="externalId"
                label={t("form.externalId_ph")}
                inputRef={register()}
                variant="outlined"
              />
              <TextField
                fullWidth
                error={!!errors.billingReference}
                name="billingReference"
                label={t("form.billing_reference_ph")}
                inputRef={register()}
                variant="outlined"
              />
              <TextField
                fullWidth
                error={!!errors.reference}
                name="reference"
                label={t("form.reference_ph")}
                inputRef={register()}
                variant="outlined"
              />
              <div className={classes.titleWrapper}>
                <DescIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("labels.creation.description_op")}
                </Typography>
              </div>
              <TextArea
                value={watch("description")}
                name="description"
                onChange={({ target }) => {
                  register("description");
                  setValue(target.name, target.value);
                }}
                label={t("labels.details.description")}
                placeholder={t("form.description_ph")}
              />
            </div>
          </div>
        </form>
      </Grid>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Grid item xs={6}>
        <Typography variant="subtitle1" color="textSecondary">
          {t("labels.summary")}
        </Typography>
        <AccordionWrapped labelTextKey={t("labels.service_order_info")} defaultExpanded>
          <ProjectInfoSummary />
        </AccordionWrapped>
        <AccordionWrapped labelTextKey={t("labels.partner_info")}>
          <PartnerInfoSummary />
        </AccordionWrapped>
        <AccordionWrapped labelTextKey={t("labels.customer_info")}>
          <CustomerInfoSummary />
        </AccordionWrapped>
      </Grid>
    </Grid>
  );
});

export default ProjectInfo;
