import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CheckIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ color: "transparent" }}
    {...props}
  >
    <path d="M17 2L6 13L1 8" stroke="#2B59C3" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default CheckIcon;
