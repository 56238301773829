import React from "react";

import Button from "@material-ui/core/Button";

import { useFilesContext } from "src/providers/FilesStore";
import { useTranslation } from "react-i18next";
import { FormWrapper } from "src/components/FormWrapper";

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
  FormWrapper: FormWrapper;
}

const ClearAllForm = ({ onCancel, onSubmit, FormWrapper }: Props) => {
  const { t } = useTranslation();

  const { reset } = useFilesContext();

  const handleSubmit = () => {
    reset();
    onSubmit();
  };

  return (
    <>
      <FormWrapper.Title onClose={onCancel}>{t("files:headers.clear_all")}</FormWrapper.Title>
      <FormWrapper.Body>{t("files:contents.would_be_cleared")}</FormWrapper.Body>
      <FormWrapper.Actions>
        <Button variant="contained" size="large" color="primary" onClick={handleSubmit}>
          {t("labels.yes", { ns: "common" })}
        </Button>
        <Button variant="text" size="large" color="primary" onClick={onCancel}>
          {t("labels.cancel", { ns: "common" })}
        </Button>
      </FormWrapper.Actions>
    </>
  );
};

export default ClearAllForm;
