import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import { HouseholdMember } from "src/types";
import DeleteIcon from "./icons/DeleteIcon";

interface Props {
  member: Partial<HouseholdMember>;
  deleteMember: (key: number) => void;
}

const MemberDisplayCard: React.FC<Props> = ({ member, deleteMember }) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4">
          {member.firstName} {member.lastName}
        </Typography>
        <IconButton onClick={() => deleteMember(member.key!)}>
          <DeleteIcon />
        </IconButton>
      </div>
      <Typography variant="subtitle1">{member.email}</Typography>
      <Typography variant="subtitle1">{member.phone}</Typography>
      <Typography variant="subtitle1">{member.ssn}</Typography>
    </div>
  );
};

export default MemberDisplayCard;
