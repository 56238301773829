import React, { useCallback, useState } from "react";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import { useCommentsContext } from "src/providers/CommentsStore";
import Container from "@material-ui/core/Container/Container";
import { useStyles } from "./styles";
import ShowCommentsInOrder from "./components/ShowCommentsInOrder";
import Divider from "@material-ui/core/Divider/Divider";
import Channels from "./components/Channels";
import Files from "./components/Files";
import Services from "./components/Services";
import ThreadsAndChat from "./components/ThreadsAndChat";
import CommentsFileUploadDialog from "src/containers/FileUploadDislog/CommentsFileUploadDialog";

const Comments = () => {
  const { containerHeader, main, channels } = useStyles();
  const { comments } = useCommentsContext();
  const [isOpen, setOpen] = useState(!!localStorage.getItem("comments.expanded"));
  const handling = useCallback(() => {
    localStorage.setItem("comments.expanded", !isOpen ? "1" : "");
    setOpen(!isOpen);
  }, [isOpen]);

  if (comments.loading.channels) return <PreloaderLine loading={comments.loading.channels} />;
  return (
    <Container style={{ margin: 0, padding: 0 }} maxWidth={"xl"}>
      <div className={containerHeader}>
        <Divider />
        <ShowCommentsInOrder />
        <Divider />
      </div>
      <div className={main}>
        <div className={channels}>
          <Channels isOpen={isOpen} handling={handling} />
          <Divider variant={"middle"} />
          <Services isOpen={isOpen} />
          <Files isOpen={isOpen} />
        </div>
        <Divider orientation="vertical" />
        <ThreadsAndChat />
      </div>
      <CommentsFileUploadDialog />
    </Container>
  );
};

export default Comments;
