import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ServiceIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="path-1-outside-1"
      maskUnits="userSpaceOnUse"
      x="9"
      y="0.654175"
      width="7"
      height="7"
      fill="black"
    >
      <rect fill="white" x="9" y="0.654175" width="7" height="7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 1.65417V6.45417H14.8" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 1.65417V6.45417H14.8" fill="#C5DBF2" />
    <path
      d="M10.3536 1.30062C10.1583 1.10536 9.84171 1.10536 9.64645 1.30062C9.45118 1.49588 9.45118 1.81247 9.64645 2.00773L10.3536 1.30062ZM14.4464 6.80773C14.6417 7.00299 14.9583 7.00299 15.1536 6.80773C15.3488 6.61247 15.3488 6.29588 15.1536 6.10062L14.4464 6.80773ZM15.1536 6.10062L10.3536 1.30062L9.64645 2.00773L14.4464 6.80773L15.1536 6.10062ZM11 1.65417C11 1.10189 10.5523 0.654175 10 0.654175C9.44772 0.654175 9 1.10189 9 1.65417H11ZM10 6.45417H9C9 7.00646 9.44772 7.45417 10 7.45417V6.45417ZM14.8 7.45417C15.3523 7.45417 15.8 7.00646 15.8 6.45417C15.8 5.90189 15.3523 5.45417 14.8 5.45417V7.45417ZM9 1.65417V6.45417H11V1.65417H9ZM10 7.45417H14.8V5.45417H10V7.45417Z"
      fill="white"
      mask="url(#path-1-outside-1)"
    />
    <path
      d="M15.3 7.45417C15.3 7.17803 15.0761 6.95417 14.8 6.95417C15.0022 6.95417 15.1845 6.83235 15.2619 6.64552C15.3393 6.45868 15.2966 6.24362 15.1536 6.10062L10.3536 1.30062C10.2106 1.15762 9.9955 1.11484 9.80866 1.19224C9.62182 1.26963 9.5 1.45194 9.5 1.65417C9.5 1.37803 9.27614 1.15417 9 1.15417H3.6C2.4402 1.15417 1.5 2.09438 1.5 3.25417V16.0542C1.5 17.214 2.4402 18.1542 3.6 18.1542H13.2C14.3598 18.1542 15.3 17.214 15.3 16.0542V7.45417Z"
      fill="#C5DBF2"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ServiceIcon;
