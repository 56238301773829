import React, { useEffect, useMemo } from "react";

import { List, Typography } from "@material-ui/core";
import { useStyles } from "src/containers/Comments/components/ThreadsAndChat/Threads/style";
import { useStyles as styles } from "src/containers/Comments/components/ThreadsAndChat/style";
import Divider from "@material-ui/core/Divider";
import Thread from "src/containers/Comments/components/ThreadsAndChat/Threads/Thread";
import AddBtn from "src/containers/Comments/components/ThreadsAndChat/Threads/AddBtn";
import { useCommentsContext } from "src/providers/CommentsStore";
import Preloader from "src/components/Preloader";
import { CommentsParamsType } from "src/pages/ServiceOrdersDetails/type";
import useQueryString from "src/utils/hooks/useQueryString";

const Threads = () => {
  const {
    callAPI,
    comments: {
      selectedChannel,
      chatchannels,
      threads = [],
      chatThread,
      loading,
      isAddNewThread = false,
      threadsSelected,
    },
  } = useCommentsContext();
  const { topic } = useStyles();
  const { header } = styles();
  const [{ threadKey }, setQuery] = useQueryString<CommentsParamsType>();

  const memoThreadKey = useMemo(
    () => threads.findIndex((thread) => Number(thread.key) === Number(threadKey)),
    [threadKey, threads]
  );

  useEffect(() => {
    if (memoThreadKey === -1) return;
    handleThreadsSelect(threadKey, memoThreadKey);
  }, [memoThreadKey]);

  useEffect(() => {
    const target = threads.filter((ent) => {
      const tempNeedle = Array.isArray(ent.channelKey) ? ent.channelKey : [ent.channelKey];
      return tempNeedle.indexOf(`${chatchannels[selectedChannel].key}`) >= 0;
    });
    if (target.length > 0) {
      let isTopicExists = threads.find((thread) => thread.key === threadsSelected?.key);
      callAPI.getThreadMsgs(
        !!isTopicExists?.key ? isTopicExists.key : target[0].key,
        `${chatchannels[selectedChannel].key}`
      );
    } else {
      callAPI.clearMsgList();
      callAPI.setContextData("threadsSelected", {});
    }
  }, [threads]);

  const handleThreadsSelect = (id: string, idx: number) => {
    if (chatThread?.key !== `${id}`) {
      setQuery({}, { fresh: true });
      callAPI.getThreadMsgs(id, `${chatchannels[selectedChannel].key}`);
      callAPI.updateState({
        selectedThread: idx,
        threadsSelected: threads.find((ent) => ent.key === id),
        scrollToBottom: true,
        ...(isAddNewThread && { isAddNewThread: false }),
      });
    }
  };
  if (loading.thread) {
    return <Preloader is />;
  }
  return (
    <div className={topic}>
      <div className={header}>
        <Typography align="left" variant="body1" style={{ paddingLeft: 10 }}>
          {!!`${selectedChannel}` && chatchannels[selectedChannel]?.matchingText}
        </Typography>
        <AddBtn />
      </div>
      <Divider orientation="horizontal" variant={"fullWidth"} />
      <List
        component="nav"
        dense
        style={{ display: "flex", flexDirection: "column", overflow: "auto" }}
      >
        {threads.map((ent, idx) => {
          return <Thread {...ent} id={ent.key} onClick={() => handleThreadsSelect(ent.key, idx)} />;
        })}
      </List>
    </div>
  );
};

export default Threads;
