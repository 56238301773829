import React, { useCallback, useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import NoServicesIcon from "./icons/NoServicesIcon";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useAppContext } from "src/providers/AppProvider";
import { ModalsUnion } from "src/providers/AppProvider/types";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 100,
    height: 100,
    marginTop: 20,
    marginBottom: 16,
  },
  title: {
    padding: theme.spacing(2),
  },
  links: {
    cursor: "pointer",
    color: "#003882",
    margin: `0px ${theme.spacing(1)}px`,
  },
}));

const NoServices: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const handleServiceCreate = useCallback((modal: ModalsUnion) => {
    if (disabledByStatus && !user?.partner?.isIntegration) {
      toggleModal({ id: modal, status: true });
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <NoServicesIcon className={classes.icon} />
      <Typography variant="h3" className={classes.title}>
        {t("labels.empty_state.title")}
      </Typography>
      <Grid container justify="center" alignItems="center">
        <Typography variant="subtitle1" color="textSecondary">
          {t("labels.empty_state.subtitle")}
        </Typography>
        <Typography
          onClick={() => handleServiceCreate("createServiceModal")}
          variant="subtitle1"
          display="inline"
          style={{
            color: disabledByStatus && !user?.partner?.isIntegration ? "#003882" : "grey",
          }}
          className={classes.links}
        >
          {t("labels.empty_state.create_single")}
        </Typography>
        {t("labels.empty_state.subtitle_1")}
        <Typography
          onClick={() => handleServiceCreate("createServiceBulkModal")}
          variant="subtitle1"
          display="inline"
          style={{
            color: disabledByStatus && !user?.partner?.isIntegration ? "#003882" : "grey",
          }}
          className={classes.links}
        >
          {t("labels.empty_state.create_in_bulk")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" display="inline">
          {t("labels.empty_state.subtitle_2")}
        </Typography>
      </Grid>
    </div>
  );
};

export default NoServices;
