import React, { useState } from "react";
import { requestGet } from "src/utils/crud";
import {
  ContextDetails,
  FileQuestionnairePreview,
  RelatedQuestionnaireReq,
  RelatedQuestionnaireState,
} from "./types";
import createCtx from "../../utils/createCtx";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SODetailsType } from "src/pages/ServiceOrdersDetails/type";
import { AxiosResponse } from "axios";
import { Props } from "./types";
import { FileQuestionnaireItem } from "src/types";
import { flatMap } from "lodash";

const [useCtx, Provider] = createCtx<ContextDetails>();

const initialQuestionnaire: RelatedQuestionnaireState = {
  fileQuestionnaires: {
    serviceFileQuestionnaires: [],
    serviceOrderFileQuestionnaires: [],
    carouselFileQuestionnaires: [],
  },
  loading: true,
  success: false,
  totalCount: 0,
};
function QuestionnaireProvider(props: Props): React.ReactElement {
  const [relatedQuestionnaireState, setRelatedQuestionnaireState] = useState<
    RelatedQuestionnaireState
  >(initialQuestionnaire);
  const [previewState, setPreviewState] = useState<FileQuestionnairePreview | null>(null);
  const { serviceOrderId } = useParams<SODetailsType>();

  useEffect(() => {
    if (!!serviceOrderId) {
      getRelatedQuestionnaire();
    }
  }, [serviceOrderId]);

  const getRelatedQuestionnaire = async (): Promise<void | AxiosResponse<
    RelatedQuestionnaireReq
  >> => {
    setQuestionnaireLoading(true);
    return await requestGet<RelatedQuestionnaireReq>(
      `FileQuestionnaires/serviceOrder/${serviceOrderId}`
    )
      .then((res) => {
        const totalCount = new Array<FileQuestionnaireItem[]>().concat(
          res.data.fileQuestionnaires?.serviceOrderFileQuestionnaires || [],
          flatMap(
            res.data.fileQuestionnaires?.serviceFileQuestionnaires.map(
              (service) => service.serviceFileQuestionnaires
            ) || []
          )
        ).length;
        setRelatedQuestionnaireState({
          ...res.data,
          fileQuestionnaires: {
            ...res.data.fileQuestionnaires,
            carouselFileQuestionnaires: [],
          },
          loading: false,
          totalCount,
        });
        return res;
      })
      .catch((err) => console.log(err));
  };

  const setContextData = (key: string, data: any) => {
    setRelatedQuestionnaireState((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const setQuestionnaireLoading = (data: boolean) =>
    setRelatedQuestionnaireState((prev: RelatedQuestionnaireState) => ({
      ...prev,
      loading: data,
    }));

  const setPreview = (data: FileQuestionnairePreview | null) => {
    setPreviewState(data);
  };
  const callAPI = {
    getRelatedQuestionnaire,
    setContextData,
    setPreview,
  };

  return (
    <Provider
      value={{
        relatedQuestionnaireState,
        previewState,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useQuestionnaireContext, QuestionnaireProvider };
