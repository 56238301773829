import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { requestGetUnprefixed } from "src/utils/crud";
import { useStyles } from "../../styles";

interface LookupResponse {
  lookups: string[];
}

const BillingIdsInput = () => {
  const { control } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [billingIds, setBillingIds] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("orders");
  const handleClose = () => setOpen(false);
  const { chip } = useStyles();
  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
    requestGetUnprefixed<LookupResponse>("PartnerPortal/ServiceOrders/lookups/ext?property=billingId")
      .then((response) => {
        setLoading(false);
        setBillingIds(response.data.lookups);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <Controller
      render={({ onChange, ref, ...props }) => (
        <Autocomplete<string, true, false, false>
          multiple
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          loading={loading}
          onChange={(_, value) => onChange(value)}
          options={billingIds}
          id="billing-ids"
          innerRef={ref}
          {...props}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                color="primary"
                label={option}
                classes={{ root: chip }}
                {...getTagProps({ index })}
              />
            ))
          }
          limitTags={2}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("list_filter.billing_reference")}
              placeholder={t("list_filter.billing_reference")}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={16} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      defaultValue={[]}
      name="billingIds"
      control={control}
    />
  );
};

export default BillingIdsInput;
