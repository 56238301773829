import { createTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    brandColors: Palette["primary"];
    functionalColors: Palette["primary"];
  }
  interface PaletteOptions {
    brandColors: PaletteOptions["primary"];
    functionalColors: PaletteOptions["primary"] | TypeText;
  }
}

export const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 48,
      lineHeight: 1.3,
      letterSpacing: 0.25,
    },
    h2: {
      fontSize: 34,
      lineHeight: 1.3,
      letterSpacing: 0.25,
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.3,
      letterSpacing: 0,
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.3,
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: 0.1,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.3,
      letterSpacing: 0.5,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.3,
      letterSpacing: 0.25,
    },
    button: {
      textTransform: "uppercase",
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: 1.25,
    },
    caption: {
      fontSize: 12,
      lineHeight: 1,
      letterSpacing: 0.4,
      color: "rgba(20, 30, 48, 0.5)",
    },
    overline: {
      textTransform: "uppercase",
      fontSize: 10,
      lineHeight: 1,
      letterSpacing: 1.5,
    },
  },
  palette: {
    primary: {
      main: "#141E30",
    },
    secondary: {
      main: "#FFFFFF",
      light: "#1b3a7f",
    },

    brandColors: {
      main: "#085C9C",
      dark: "#1b3a7f",
      light: "#06A9C6",
    },
    functionalColors: {
      main: "#141E30",
      dark: "#2B59C3",
      light: "#FFFFFF",
      hint: "#F6F6F6",
    },
    text: {
      primary: "#141e30",
      secondary: "rgba(20,30,48,0.5)",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        maxWidth: 300,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        lineHeight: "100%",
        letterSpacing: "0.0004em",
      },
    },
    MuiButton: {
      root: {
        "&$containedPrimary": {
          backgroundColor: "#2B59C3 !important",
        },
        "&:disabled": {
          backgroundColor: "#2B59C3 !important",
          opacity: 0.5,
          color: "#fff !important",
        },
      },
    },
    MuiFab: {
      root: {
        width: 40,
        height: 40,
      },
    },
    MuiTab: {
      root: {
        "@media (min-width: 600px)": {
          minWidth: 50,
        },
      },
      textColorPrimary: {
        "&$selected": {
          color: "#2B59C3",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#2B59C3",
      },
    },
    MuiTableSortLabel: {
      root: {
        "&:hover": {
          color: "rgba(0, 0, 0, 0.58)",
        },
        "&:focus": {
          color: "#fff",
        },
      },
    },
    MuiTableCell: {
      head: {
        color: "black",
        fontWeight: "bolder",
        padding: "16px",
        lineHeight: 0.9,
      },
      root: {
        wordBreak: "break-word",
        maxWidth: 400,
        padding: "5px 16px ",
      },
    },
    MuiFormControl: {
      root: {
        margin: "10px 0",
      },
    },
    MuiSvgIcon: {
      colorAction: {
        color: "#2B59C3",
      },
    },
    MuiTextField: {
      root: {
        margin: "10px 0",
        outlined: {
          backgroundColor: "#ccc",
        },
        "& textarea": {
          resize: "vertical",
        },
      },
    },
  },
});
