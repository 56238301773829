import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  pdfContainer: {
    background: "#f1f1f1",
    padding: theme.spacing(2),

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > :not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  pdfDocument: {
    minHeight: 800,
  },
  image: {
    maxWidth: "60%",
    maxHeight: "60vh",
  },
}));
