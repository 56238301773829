import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ProgressHintIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      y="5.5"
      x="0.5"
      width="15"
      height="5"
      rx="0.6"
      stroke="#141E30"
      strokeOpacity="0.5"
      fill="none"
    />
    <path d="M2 7H8V9H2V7Z" fill="#141E30" fillOpacity="0.5" />
  </SvgIcon>
);

export default ProgressHintIcon;
