import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ServiceDetailsProvider,
  useServiceDetailsContext,
} from "src/providers/ServiceDetailsStore";
import { SODetailsProvider, useSODetailsContext } from "src/providers/SODetailsStore";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper/Paper";
import EditDetailsIcon from "src/assets/icons/Warehouse/EditDetailsIcon";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import DetailsWrapper from "src/components/DetailsWrapper";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import { useAppContext } from "src/providers/AppProvider";
import LineItems from "./tabs/LineItems";
import { LineItemsProvider } from "src/providers/LineItemsStore";
import { useServiceDetailsStyles } from "src/pages/ServiceDetails/styles";
import RightArrowIcon from "src/pages/ServiceDetails/components/icons/RightArrowIcon";
import Summary from "src/pages/ServiceDetails/tabs/Summary";
import { DetailsType, ServiceDetailsType, ServiceNavLinks } from "src/pages/ServiceDetails/type";
import { dataList, serviceDetailsRouts } from "src/pages/ServiceDetails/data";
import CommentsPage from "src/pages/ServiceDetails/tabs/Comments";
import EditForm from "./modals/EditForm/";
import { CommentsProvider, useCommentsContext } from "src/providers/CommentsStore";
import FilesPage from "src/pages/ServiceDetails/tabs/Files";
import PageActions from "./components/PageActions";
import { FilesProvider } from "../../providers/FilesStore";
import ServiceFileUploadDialog from "../../containers/FileUploadDislog/ServiceFileUploadDialog";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

interface DetailsSwitcherType<T> {
  [k: string]: T;
}

export const DetailsSwitcher: DetailsSwitcherType<JSX.Element> = {
  summary: <Summary />,
  line_items: <LineItems />,
  files: <FilesPage />,
  comments: <CommentsPage />,
};

const ServiceDetails: FunctionComponent = () => {
  const { type } = useParams<ServiceDetailsType>();
  const tabs: DetailsType = !!type ? type : "summary";
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();
  const {
    serviceDetails: { service, loading },
  } = useServiceDetailsContext();
  const { comments } = useCommentsContext();
  const { filesState } = useSODetailsContext();
  const history = useHistory();
  const { t } = useTranslation("orders");
  const classes = useServiceDetailsStyles();
  const navItems = serviceDetailsRouts([
    {
      path: "files",
      id: 2,
      label: "files",
      count: filesState.totalCount || 0,
    },
    {
      path: "comments",
      id: 3,
      label: "comments",
      count: comments.totalCount || 0,
    },
    {
      path: "line_items",
      id: 4,
      label: "line_items",
      count: service.lineItemCount || 0,
    },
  ]);
  const position = navItems.map((ent) => ent.path);
  const routeHandler = (event: React.ChangeEvent<HTMLDivElement>) => {
    history.push(event.currentTarget.id);
  };
  const handleEditDetailsModal = useCallback(() => {
    toggleModal({ id: "editServiceStatusModal", status: true });
  }, []);

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder?.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <>
      <PreloaderLine loading={loading} />
      <PageActions />
      <Paper className={classes.mainWrapper}>
        <Grid
          className={classes.head}
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={6} container alignItems="center">
            <Button
              className={classes.parentLink}
              component={RouterLink}
              to={`/serviceOrders/details/${service.serviceOrder?.key}/summary`}
            >
              <Typography variant={"h3"}>{service.serviceOrder?.displayText}</Typography>
            </Button>
            <RightArrowIcon />
            <Typography variant={"h3"}>{service.name}</Typography>
          </Grid>
          <Grid>
            {isIntegrated && disabledByStatus && (
              <Button onClick={handleEditDetailsModal}>
                <EditDetailsIcon className={classes.svg} />
                {t("labels.btn.edit_details")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          {dataList(t, { ...service, assemblyZone: serviceOrder.assemblyZone }).map((ent) => (
            <Grid item xs key={uniqueId("orders")}>
              <Typography variant="caption">{ent.label}</Typography>
              <Typography variant="body2">{ent?.value}</Typography>
            </Grid>
          ))}
        </Grid>
        <EditForm />
      </Paper>
      <FilesProvider service={service} serviceOrder={serviceOrder} context="service">
        <DetailsWrapper<ServiceNavLinks>
          navItems={navItems}
          handleChange={routeHandler}
          selected={position.indexOf(tabs)}
        >
          {DetailsSwitcher[tabs]}
        </DetailsWrapper>
        <ServiceFileUploadDialog context="service" service={service} />
      </FilesProvider>
    </>
  );
};

const ServiceDetailsPage = () => {
  return (
    <SODetailsProvider>
      <ServiceDetailsProvider>
        <LineItemsProvider forService>
          <CommentsProvider context={"service"}>
            <ServiceDetails />
          </CommentsProvider>
        </LineItemsProvider>
      </ServiceDetailsProvider>
    </SODetailsProvider>
  );
};

export default ServiceDetailsPage;
