import React, { useEffect, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Actions from "./components/Actions";
import { useStyles } from "./styles";
import { isEqual } from "lodash";
import { CustomerFilterParams } from "src/providers/CustomerStore/types";
import { useCustomerContext } from "src/providers/CustomerStore";
import { FormLabel, TextField } from "@material-ui/core";
import PhoneInputField from "src/components/PhoneInput/";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CreatedDateFrom from "./components/CreatedDateFrom";
import CreatedDateTo from "./components/CreatedDateTo";

export const filterDefaultValues: CustomerFilterParams = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  active: "true",
};

const Filter = () => {
  const {
    customerData: { filters },
    callAPI: { setContextData },
  } = useCustomerContext();
  const { heading, filtersStyles, fieldsScroll } = useStyles();
  const methods = useForm<CustomerFilterParams>({
    mode: "all",
  });
  const [phoneCountry, setPhoneCountry] = useState("");
  const { t } = useTranslation("customers");
  useEffect(() => {
    if (!isEqual(filters, methods.getValues())) {
      methods.reset(filters);
    }
  }, [filters]);

  const submit = () => {
    setContextData("filters", methods.getValues());
    setContextData("currentPage", 1);
  };
  const handleClear = () => {
    setContextData("filters", {});
    setContextData("currentPage", 1);
  };

  const clear = () => {
    methods.reset(filterDefaultValues, {
      isDirty: false,
    });
    handleClear();
  };

  return (
    <FormProvider {...methods}>
      <Typography variant="h3" className={heading}>
        {t("list_filter.title")}
      </Typography>
      <div className={filtersStyles}>
        <div className={fieldsScroll}>
          <TextField
            fullWidth
            name="firstName"
            label="First Name"
            inputRef={methods.register()}
            variant="outlined"
          />
          <TextField
            fullWidth
            name="lastName"
            label="Last Name"
            inputRef={methods.register()}
            variant="outlined"
          />
          <Controller
            as={<TextField variant="outlined" fullWidth label="Delivery Address" />}
            name="deliveryAddress"
            control={methods.control}
            defaultValue=""
          />
          <TextField
            fullWidth
            name="email"
            label="Email"
            inputRef={methods.register()}
            variant="outlined"
          />
          <Controller
            render={() => (
              <PhoneInputField
                required={false}
                label="Phone"
                name="mobile"
                labelWidth={80}
                onChangeHandler={(value, country) => {
                  setPhoneCountry(country.dialCode);
                  methods.setValue("mobile", value || "");
                }}
                value={methods.watch("mobile") || `${phoneCountry}`}
              />
            )}
            defaultValue=""
            name="mobile"
            control={methods.control}
            inputRef={methods.register()}
          />
          <CreatedDateFrom />
          <CreatedDateTo />
          <FormLabel htmlFor="active" component="legend">
            {t("labels.active", { ns: "common" })}
          </FormLabel>
          <Controller
            control={methods.control}
            name="active"
            defaultValue="true"
            as={
              <RadioGroup onChange={({ target }) => methods.setValue("active", target.value)}>
                <FormControlLabel
                  value="true"
                  control={<Radio color="default" />}
                  label={t("labels.yes", { ns: "common" })}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="default" />}
                  label={t("labels.no", { ns: "common" })}
                />
              </RadioGroup>
            }
          />
        </div>
      </div>
      <Actions clear={clear} submit={submit} />
    </FormProvider>
  );
};

export default Filter;
