import { Button } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "src/containers/Files/icons/DeleteIcon";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";
import ConformationDialog from "../ConformationDialog";
import { useParams } from "react-router-dom";
import { SODetailsType } from "../../type";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";

const DeleteServiceOrder: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("orders");
  const { serviceOrderId } = useParams<SODetailsType>();
  const [openDialog, setOpenDialog] = useState(false);
  const {
    callAPI,
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();

  const allowToDelete = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const handlerDelete = () => {
    setOpenDialog(true);
  };

  const AcceprDelete = () => {
    serviceOrderId &&
      callAPI.deleteServiceOrder(serviceOrderId).then(() => history.push("/orders/ordersList"));
  };

  if (!allowToDelete) return null;

  return (
    <>
      <Button className={classes.outlineBtn} startIcon={<DeleteIcon />} onClick={handlerDelete}>
        {t("labels.details.delete")}
      </Button>
      {openDialog && (
        <ConformationDialog
          onAccept={AcceprDelete}
          onCancel={() => setOpenDialog(false)}
          deleteServiceOrder={true}
        />
      )}
    </>
  );
};

export default DeleteServiceOrder;
