import React, { useCallback, useMemo } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { useStyles } from "src/pages/ServiceOrdersDetails/components/styles";
import { TFunction } from "i18next";
import DeliveryInfoIcon from "src/pages/ServiceOrdersDetails/components/icons/DeliveryInfoIcon";
import DropdownMenu from "src/components/DropdownMenu";
import DeliveryIcon from "src/pages/ServiceOrdersDetails/components/icons/DeliveryIcon";
import InvoiceIcon from "src/pages/ServiceOrdersDetails/components/icons/InvoiceIcon";
import { useAppContext } from "src/providers/AppProvider";
import MonteraCard from "src/components/MonteraCard";
import EditIcon from "src/pages/ServiceOrdersDetails/components/icons/EditIcon";
import PointLoader from "src/components/Preloader/PointLoader";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

const DeliveryInfo = () => {
  const { serviceOrderDetails } = useSODetailsContext();
  const { customerInfo, invoiceInfo, loading, serviceOrder } = serviceOrderDetails;
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const customer = !!customerInfo ? customerInfo : undefined;
  const {
    state: { user },
  } = useAppContext();
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();

  const handleDeliveryEdit = useCallback(() => {
    toggleModal({ id: "editDeliveryAddressSO", status: true });
  }, []);

  const handleInvoiceEdit = useCallback(() => {
    toggleModal({ id: "editInvoiceAddressSO", status: true });
  }, []);

  const actionsListAddresses = (t: TFunction) => [
    {
      label: t("labels.details.change_delivery"),
      icon: <DeliveryIcon style={{ width: 16, height: 16 }} />,
      attr: { onClick: handleDeliveryEdit },
    },
    {
      label: t("labels.details.change_invoice"),
      icon: <InvoiceIcon style={{ width: 16, height: 16 }} />,
      attr: { onClick: handleInvoiceEdit },
    },
  ];

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <MonteraCard
      headerIcon={<DeliveryInfoIcon />}
      title={t("labels.summary_cards.delivery_info")}
      action={
        isIntegrated && disabledByStatus ? (
          <DropdownMenu
            menuIcon={<EditIcon style={{ width: 16, height: 16 }} />}
            menuActions={actionsListAddresses(t)}
          />
        ) : (
          <></>
        )
      }
    >
      {!customer || loading ? (
        <PointLoader />
      ) : (
        <div className={classes.contentWrap}>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.delivery_address")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {customer?.deliveryAddress?.name}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.invoice_address")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {invoiceInfo?.invoiceAddress?.name}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.invoice_recipient")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {invoiceInfo?.invoiceTo.displayText ||
                (invoiceInfo?.invoiceTo.key === 0 && "Other") ||
                "---"}
            </Typography>
            {invoiceInfo?.invoiceTo.key === 0 && (
              <>
                <Typography variant="caption" className={classes.caption}>
                  {t("labels.details.description")}
                </Typography>
                <Typography variant="subtitle1" className={classes.displayValue}>
                  {invoiceInfo.invoiceToDescription}
                </Typography>
              </>
            )}
          </div>
        </div>
      )}
    </MonteraCard>
  );
};

export default DeliveryInfo;
