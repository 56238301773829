import React from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "src/assets/icons/Loader";

const PointLoader = React.memo(() => {
  return (
    <Grid container justify="center">
      <Loader />
    </Grid>
  );
});

export default PointLoader;
