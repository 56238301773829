import React, { useEffect, useRef, useState } from "react";
import { requestGet, requestMetaGet, requestPost, serializeParams } from "src/utils/crud";
import { Context, SOState, Props, CreationSO, MetaDataType } from "./types";
import createCtx from "../../utils/createCtx";
import { DeliveryAddress, ServiceOrder, ServiceOrdersReq } from "src/types";
import { SOSourceType, AddressesListType } from "./types";
import { useDeepCompareEffect } from "src/utils/hooks/useDeepCompareEffect";
import { useAppContext } from "../AppProvider";
import { assemblyZoneAvailable } from "src/utils/functions";
import { useParams } from "react-router-dom";
import { ListOrdersType, OrdersRouteType } from "src/pages/Orders";

const [useCtx, Provider] = createCtx<Context>();

const initialServicesState: SOState = {
  selectedSO: {},
  serviceOrders: [],
  creationSO: {
    deliveryDate: "",
    startDate: "",
  },
  currentPage: 1,
  pageCount: 10,
  pageSize: 10,
  totalCount: 0,
  filters: {},
  success: false,
  loading: false,
  responseObject: null,
  createService: false,
  servicesCreted: false,
  des: true,
  IAOptions: [],
  DAOptions: [],
  refresTrigger: true,
};

function ServiceOrdersProvider(props: Props): React.ReactElement {
  const { type } = useParams<OrdersRouteType>();
  const {
    state: {
      user: { id, partner, warehouse },
    },
  } = useAppContext();
  const readyToFetch = useRef(false);

  const [servicesStore, setServicesStore] = useState(initialServicesState);
  const { currentPage, pageSize, filters } = servicesStore;

  const refreshList = () => {
    if (!!props.defaultRequest) {
      if (type === ListOrdersType.incomplete) {
        callSOAPI.fetchingIncompleteSO();
      } else {
        callSOAPI.fetchingAllSO();
      }
      setContextData("refresTrigger", !servicesStore.refresTrigger);
    }
  };

  useDeepCompareEffect(() => {
    if (!readyToFetch.current) {
      return;
    }
    refreshList();
  }, [currentPage, pageSize, filters, servicesStore.des]);

  useEffect(() => {
    if (!id) {
      return;
    }
    readyToFetch.current = true;
    if (warehouse) {
      setServicesStore((prev) => {
        const state = {
          ...prev,
          filters: {
            ...prev.filters,
            warehouses: [warehouse as any],
          },
        };

        return state;
      });

      return;
    }
    refreshList();
  }, [id]);

  const fetchingAllSO = () => {
    setServicesLoading(true);
    requestGet<ServiceOrder>(`ServiceOrders`, {
      page: currentPage,
      pageSize,
      sortBy: "createdDate",
      des: servicesStore.des,
      filters: serializeParams(filters || {}),
    })
      .then((res) => {
        setServicesStore((prev) => ({
          ...prev,
          ...res.data,
          currentPage: prev.currentPage, //returned currentPage wrong number
          pageSize: prev.pageSize, //returned currentPage wrong number
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const fetchingIncompleteSO = () => {
    setServicesLoading(true);
    requestGet<ServiceOrder>(`ServiceOrders`, {
      page: currentPage,
      pageSize,
      sortBy: "createdDate",
      des: servicesStore.des,
      filters: serializeParams({ ...filters, statusTypeId: 6 } || {}),
    })
      .then((res) => {
        setServicesStore((prev) => ({
          ...prev,
          ...res.data,
          currentPage: prev.currentPage, //returned currentPage wrong number
          pageSize: prev.pageSize, //returned currentPage wrong number
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const fetchInvoiceAddresses = async (customerKey: number | string) => {
    const response = await requestGet<{ invoiceAddresses: AddressesListType[] }>(
      `InvoiceAddresses/${customerKey}/lookups`,
      {
        pageSize: 999,
      }
    );
    setContextData("IAOptions", response.data.invoiceAddresses);
    return response.data.invoiceAddresses;
  };

  const fetchDeliveryAddresses = async (customerKey: number | string) => {
    const response = await requestGet<{ deliveryAddresses: AddressesListType[] }>(
      `DeliveryAddresses/${customerKey}/lookups`,
      {
        pageSize: 999,
      }
    );
    setContextData("DAOptions", response.data.deliveryAddresses);
    return response.data.deliveryAddresses;
  };

  const fetchingServices = (id: number, from: SOSourceType, size: number = pageSize) => {
    setServicesLoading(true);

    requestGet<ServiceOrdersReq>(`${from}/${id}/serviceOrders`, {
      page: currentPage,
      pageSize: size,
      sortBy: "createdDate",
    })
      .then((res) => {
        setServicesStore((prev) => ({
          ...prev,
          selectedSO: {
            ...prev.selectedSO,
            [id]: res.data,
          },
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const sendSO = async (data: Partial<CreationSO>): Promise<ServiceOrder | any> => {
    const { creationSO } = servicesStore;
    return await requestPost<ServiceOrder>(
      `ServiceOrders`,
      {
        warehouseId: creationSO.warehouseId,
        sellerId: creationSO.sellerId,
        customerId: creationSO.customer?.key,
        invoiceAddressId: creationSO.invoiceAddress?.key,
        deliveryAddressId: creationSO.deliveryAddress?.key,
        startDate: creationSO.startDate,
        deliveryDate: creationSO.deliveryDate,
        invoiceTo: creationSO.invoiceTo,
        invoiceToDescription: creationSO.invoiceToDescription,
        assemblyZoneId: creationSO.assemblyZone || undefined,
        externalId: data.externalId,
        billingReference: data.billingReference,
        reference: data.reference,
        description: data.description,
        optionalName: data.optionalName,
      },
      { hasResponse: true }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const setServicesLoading = (data: boolean) => {
    setServicesStore((prev: SOState) => ({
      ...prev,
      loading: data,
    }));
  };

  const setContextData = (key: string, data: any) => {
    setServicesStore((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const setContextDataBulk = (newValues: object) => {
    setServicesStore((prev) => {
      return {
        ...prev,
        ...newValues,
      };
    });
  };
  const setCreationSO = (data: Partial<CreationSO>) => {
    setServicesStore((prev) => {
      return {
        ...prev,
        creationSO: {
          ...prev.creationSO,
          ...data,
        },
      };
    });
  };

  const setAssemblyZone = async (deliveryAddressKey: string | number) => {
    const showAssemblyZone = assemblyZoneAvailable(false, partner?.name || "");
    const deliveryAddress = await getZipCode(deliveryAddressKey);
    await requestMetaGet<MetaDataType>(
      `lookups/assemblyZones/zip/${deliveryAddress?.zipCode.key}`,
      { ...(showAssemblyZone && { partnerKey: partner?.key }) }
    ).then(({ data }) => {
      if (!!data && data.length) {
        if (data[0].displayText !== "No zone") {
          setServicesStore((prev) => {
            return {
              ...prev,
              creationSO: {
                ...prev.creationSO,
                assemblyZone: data[0].key,
              },
            };
          });
        } else {
          setServicesStore((prev) => {
            return {
              ...prev,
              creationSO: {
                ...prev.creationSO,
                assemblyZone: null,
              },
            };
          });
        }
      }
    });
  };
  const getZipCode = async (
    deliveryAddressKey: string | number
  ): Promise<DeliveryAddress | null> => {
    if (deliveryAddressKey) {
      const response = await requestGet<{ deliveryAddress: DeliveryAddress }>(
        `deliveryAddresses/${deliveryAddressKey}`
      );
      const address = response.data.deliveryAddress;
      return address;
    } else return null;
    //setZipCode(address.zipCode);
  };
  const toggleSortDes = () => {
    setServicesStore((prev) => ({
      ...prev,
      des: !prev.des,
    }));
  };
  const callSOAPI = {
    refreshList,
    fetchingServices,
    fetchingAllSO,
    fetchInvoiceAddresses,
    fetchDeliveryAddresses,
    fetchingIncompleteSO,
    sendSO,
    setServicesLoading,
    setContextData,
    setContextDataBulk,
    toggleSortDes,
    setCreationSO,
    setAssemblyZone,
  };

  return (
    <Provider
      value={{
        SOData: servicesStore,
        callSOAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useSOContext, ServiceOrdersProvider };
