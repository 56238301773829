import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  massages: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minWidth: 220,
  },
  content: {
    display: "flex",
    width: "100%",
    height: "100%",
    flex: "1 1 auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 50,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
  },
  info: {
    fontFamily: "Roboto",
    fontSize: "12px",
    color: "rgba(20, 30, 48, 0.5)",
  },
  nothingTitle: {
    fontWeight: 500,
    fontSize: 20,
    padding: 16,
  },
  nothing: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    flexDirection: "column",
    flex: 1,
  },
  actions: {
    marginRight: theme.spacing(1),

    display: "flex",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
}));
