import Axios from "axios";
import compose from "lodash/fp/compose";
import get from "lodash/fp/get";
import find from "lodash/fp/find";
import { msalInstance } from "src/App";
import { authenticationParameters } from "src/utils/config/authConfig";

import { LookupObject } from "src/types";

const __domain = process.env.REACT_APP_Domain;
const __apiVersion = process.env.REACT_APP_ApiVersion;
const __metaApi = process.env.REACT_APP_MetaApi;
const __filesApi = process.env.REACT_APP_FilesApi;

const requiresInteractionErrorCodes = [
  "consent_required",
  "interaction_required",
  "login_required",
  "user_login_error",
  "invalid_grant",
];

// to avoid infinite loop with redirections
const redirectState = {
  key: "montera-redirect-count",
  maxRedirects: 2,

  get: () => {
    const count = sessionStorage.getItem(redirectState.key);

    if (count) return parseInt(count, 10);

    return 0;
  },
  increment: () => {
    sessionStorage.setItem(redirectState.key, `${redirectState.get() + 1}`);
  },
  isThresholdReached: () => {
    return redirectState.get() >= redirectState.maxRedirects;
  },
  clear: () => {
    sessionStorage.removeItem(redirectState.key);
  },
};

export const authObject = async () => {
  return msalInstance
    .acquireTokenSilent({
      ...authenticationParameters,
      account: msalInstance.getAllAccounts()[0],
    })
    .catch((err) => {
      if (
        requiresInteractionErrorCodes.includes(err.errorCode) &&
        !redirectState.isThresholdReached()
      ) {
        redirectState.increment();
        return msalInstance.loginPopup();
      }

      throw err;
    });
};

export const dataAuthObject = async () => {
  const config = {
    scopes: [`${process.env.REACT_APP_CONFIG_FILE_Scopes}`],
    authority: process.env.REACT_APP_CONFIG_Authority,
    account: msalInstance.getAllAccounts()[0],
  };

  try {
    return await msalInstance.acquireTokenSilent(config);
  } catch (e) {
    if (requiresInteractionErrorCodes.includes(e.errorCode)) {
      return await msalInstance.loginPopup(config);
    }
    throw e;
  }
};

const requestGetBase = async <T extends {}>(url: string, params?: any) => {
  try {
    const token = await authObject();

    redirectState.clear();

    return await Axios.get<T>(url, {
      params: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
    });
  } catch (e) {
    console.log(`request to ${url} failed, original error: ${e}`);
    throw e;
  }
};

export const requestGetUnprefixed = async <T extends {}>(uri: string, params?: any) =>
  requestGetBase<T>(`${__domain}/${__apiVersion}/${uri}`, params);

export const requestGet = async <T extends {}>(entity: string, params?: any) =>
  requestGetBase<T>(`${__domain}/${__apiVersion}/PartnerPortal/${entity}`, params);

export const requestMetaGet = async <T extends {}>(entity: string, params?: any) =>
  requestGetBase<T>(`${__metaApi}/${__apiVersion}/${entity}`, params);

export const requestPost = async <T extends {}>(entity: string, data?: any, params?: any) => {
  const token = await authObject();
  try {
    return await Axios.post<T>(
      `${__domain}/${__apiVersion}/PartnerPortal/${entity}`,
      JSON.stringify(data),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.accessToken,
        },
        params,
      }
    );
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const requestPut = async <T extends {}>(entity: string, data?: any) => {
  const token = await authObject();
  try {
    return await Axios.put<T>(
      `${__domain}/${__apiVersion}/PartnerPortal/${entity}`,
      JSON.stringify(data),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.accessToken,
        },
      }
    );
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const requestGetFile = async <T extends Blob>(id: number, params?: any) => {
  const token = await dataAuthObject();
  try {
    return await Axios.get<T>(`${__filesApi}/${__apiVersion}/files/${id}`, {
      params: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      responseType: "blob",
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export async function getFileData(collection: string, id: number) {
  return dataAuthObject().then(async (authDataObject) => {
    // restartTimeout();
    const response = await Axios.get(`${__filesApi}/${__apiVersion}/${collection}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authDataObject.accessToken,
      },
      responseType: "blob",
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export const requestDelete = async <T extends {}>(entity: string) => {
  const token = await authObject();
  try {
    return await Axios.delete<T>(`${__domain}/${__apiVersion}/PartnerPortal/${entity}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteFileFromService = async <T extends {}>(key: number | string) => {
  const token = await dataAuthObject();
  try {
    return await Axios.delete<T>(`${__filesApi}/${__apiVersion}/files/${key}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const uploadFile = async <T extends {}>(
  file: File,
  progressCallback?: (progress: number) => void
) => {
  const token = await dataAuthObject();
  let formData = new FormData();
  formData.append("file", file);
  try {
    const result = await Axios.post<T>(
      `${__filesApi}/${__apiVersion}/files/?fileSign=Project`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token.accessToken,
        },
        onUploadProgress: (p) => {
          progressCallback && progressCallback(p.loaded / p.total);
        },
      }
    );
    progressCallback && progressCallback(1);
    return result;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

type OperatorType = "ge" | "lt" | "eq";

const paramsOperatorMap: Record<string, OperatorType> = {
  createdDateFrom: "ge",
  createdDateTo: "lt",
  deliveryDateFrom: "ge",
  deliveryDateTo: "lt",
};

const prepareKey = (key: string) => {
  if (key.includes("DateFrom")) {
    return key.replace("From", "");
  }

  if (key.includes("DateTo")) {
    return key.replace("To", "");
  }

  if (key.endsWith("Key")) {
    return key.replace("Key", ".key");
  }

  return key;
};

type filterObject = { key: string; [name: string]: any };
type ParamValueType = string | number | Date | filterObject[];

const prepareObject = (value: { [name: string]: any }) => {
  if (value.hasOwnProperty("key")) {
    return value.key;
  }
};

const prepareValue = (value: ParamValueType) => {
  if (Array.isArray(value)) {
    if (typeof value[0] === "object") {
      return value.map((el) => prepareObject(el)).join("+");
    } else return value.join("+");
  }
  return value;
};

const getOperator = (key: string) => paramsOperatorMap[key] || "eq";

export function serializeParams<T = Record<string, ParamValueType>>(params: T): string {
  const keys = Object.keys(params) as (keyof T)[];

  return keys
    .reduce<string[]>((acc, key) => {
      const value = (params[key] as unknown) as ParamValueType;
      if (!value) return acc;

      if (Array.isArray(value) && value.length === 0) {
        return acc;
      }

      return [
        ...acc,
        `${prepareKey(String(key))} ${getOperator(String(key))} ${prepareValue(value)}`,
      ];
    }, [])
    .join(",");
}

export const updateUserLocale = async (localeId: number) => {
  const localeObj = {
    locale: { key: localeId },
  };
  const token = await authObject();
  try {
    await Axios.put(
      `${__domain}/${__apiVersion}/PartnerPortal/Profile/locale/`,
      JSON.stringify(localeObj),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.accessToken,
        },
      }
    );
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const createGetOptionLabelByKey = (lookups: LookupObject[]) => (option: string) =>
  compose(
    get("displayText"),
    find(({ key }) => key.toString() === option)
  )(lookups);
