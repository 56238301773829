import React, { useState, useEffect } from "react";
import { SellersReq, SellersState, Seller } from "src/providers/AnalyticsStore/types";
import AnalyticsList from "src/pages/Analytics/components/AnalyticsList";
import Preloader from "src/components/Preloader";
import SellerFieldList from "./SellerFieldList";
import ServicesList from "./ServicesList";
import { requestGet } from "src/utils/crud";
import SellerListHeader from "./SellerListHeader";

const SellersList: React.FC<{ id: number }> = ({ id }) => {
  const [data, setData] = useState<SellersState>({
    sellers: [],
    loading: false,
    success: false,
    TotalWithoutVat: 0,
  });

  const getSellers = async (key: number | string) => {
    return requestGet<SellersReq>(`Reports/Warehouse/${key}/sellers`)
      .then((res) => {
        setData((prev: SellersState) => ({
          ...prev,
          sellers: [...res.data.sellers],
          loading: false,
          success: res.data.success,
          TotalWithoutVat: res.data.TotalWithoutVat,
        }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSellers(id);
  }, []);

  if (data.loading || !data.sellers) {
    return <Preloader is />;
  }

  return (
    <>
      {data.sellers && <SellerListHeader />}
      <AnalyticsList<Seller>
        listEntities={data.sellers}
        entityFields={(ent) => <SellerFieldList data={ent} />}
        entityDetails={(ent) => <ServicesList id={ent.sellerId} />}
        customStyles={{ backgroundColor: "rgba(43, 89, 195, 0.2)", paddingLeft: "32px" }}
        total={data.TotalWithoutVat}
      />
    </>
  );
};

export default React.memo(SellersList);
