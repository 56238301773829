import React from "react";
import { useTranslation } from "react-i18next";
import { SuccessDialog } from "src/components/Dialog";
import { useAppContext } from "src/providers/AppProvider";
import { useSOContext } from "src/providers/ServiceOrdersStore";

interface Props {
  open: boolean;
}

const SuccessNotification: React.FC<Props> = ({ open }: Props) => {
  const { t } = useTranslation("orders");
  const {
    SOData: { responseObject },
  } = useSOContext();
  const { callAppAPI } = useAppContext();
  const statusIncomplete = responseObject?.statusMatchingName === "INCOMPLETE";

  const onClose = () => {
    callAppAPI.toggleModal({ id: "createServiceBulkModal", status: false });
  };

  return (
    <SuccessDialog
      open={open}
      title={t("create_services_in_bulk_form.success_dialog.title")}
      subtitle={
        statusIncomplete ? t("create_services_in_bulk_form.success_dialog.subtitle") : undefined
      }
      onAccept={onClose}
    />
  );
};

export default SuccessNotification;
