import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { useCommentsContext } from "src/providers/CommentsStore";

const FileSortDropdown: React.FC = () => {
  const { t } = useTranslation(`orders`);
  const { callAPI } = useCommentsContext();
  const classes = useStyles();
  const [sortingValue, setSortingValue] = useState<string>("createdBy_desc");
  const handleSorting = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSortingValue(event.target.value)
      let val = event.target.value.split("_");
      callAPI.getCommentsFiles(true, {sortBy: val[0], des: val[1] === "desc" ? true : false});
  };

  const sortValues = [
    { key: "createdBy_desc", displayText: t("labels.files.sort_values.сreatedBy_desc") },
    { key: "createdBy", displayText: t("labels.files.sort_values.сreatedBy_asc") },
  ];

  return (
    <TextField
      select
      onChange={handleSorting}
      variant="outlined"
      SelectProps={{
        native: true,
      }}
      value={sortingValue}
      InputLabelProps={{ shrink: true }}
      InputProps={{ classes: { root: classes.root } }}
    >
      {sortValues.map((el, idx) => (
        <option key={idx} value={el.key}>
          {el.displayText}
        </option>
      ))}
    </TextField>
  );
};

export default FileSortDropdown;
