import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const LogoutIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.6 17H2.6C1.71634 17 1 16.2837 1 15.4V2.6C1 1.71634 1.71634 1 2.6 1H6.6"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2002 12.2008L15.4002 9.00078L12.2002 5.80078"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.3998 9H5.7998" stroke="#2B59C3" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default LogoutIcon;
