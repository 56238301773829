import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SuccessIcon = () => {
  return (
    <SvgIcon width="56" height="56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z"
        fill="#4ECDC4"
      />
      <path
        opacity=".2"
        d="M20.845 40.63l14.398 14.398C47.167 51.848 56 40.984 56 28v-.795L44.694 16.782 20.845 40.631z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M27.027 35.951c1.236 1.237 1.236 3.357 0 4.593l-.882.882c-1.236 1.236-3.356 1.236-4.593 0L10.334 30.12c-1.236-1.237-1.236-3.357 0-4.593l.882-.882c1.237-1.236 3.356-1.236 4.593 0l11.218 11.306z"
        fill="#fff"
      />
      <path
        d="M40.191 16.43c1.237-1.236 3.357-1.236 4.593 0l.882.882c1.236 1.237 1.236 3.357 0 4.593L26.234 41.25c-1.237 1.237-3.357 1.237-4.594 0l-.881-.881c-1.237-1.237-1.237-3.357 0-4.593L40.19 16.43z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="28"
          y1="26.25"
          x2="49"
          y2="46.813"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
export default SuccessIcon;
