import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const AddBulkServicesIcon = (props: SvgIconProps) => (
    <SvgIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2 3C2 2.44772 2.44772 2 3 2H12C12.5523 2 13 2.44772 13 3V15C13 15.5523 12.5523 16 12 16H3C2.44771 16 2 15.5523 2 15V3Z"
            fill="#F4CE04"
        />
        <path
            d="M5 0C4.44772 0 4 0.447716 4 1L12 1C13.1046 1 14 1.89543 14 3V14C14.5523 14 15 13.5523 15 13V1C15 0.447715 14.5523 0 14 0H5Z"
            fill="#FFAA47"
        />
    </SvgIcon>
);
export default AddBulkServicesIcon;



