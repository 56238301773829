import React, { FC } from "react";
import { Service, ServiceOrder } from "src/types";
import MainTable from "src/components/MainTable";
import { Trans } from "react-i18next";
import { UseTableRowProps } from "react-table";
import Link from "@material-ui/core/Link";
import { theme } from "src/utils/styleTheme/mainStyles";
import { Link as RouterLink } from "react-router-dom";
import { useServicesContext } from "src/providers/ServicesStore";
import StatusColor from "src/components/StatusColors";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography";
import NoServices from "../../components/NoServices";
import { Grid } from "@material-ui/core";

export const columns = () => {
  return [
    {
      Header: () => <Trans ns={"orders"} i18nKey="labels.table.service_id" />,
      id: "name",
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <Link
          style={{
            color: theme.palette.functionalColors.dark,
            padding: theme.spacing(2),
          }}
          component={RouterLink}
          to={`/services/details/${row.original.id}/summary`}
        >
          {row.original.name}
        </Link>
      ),
    },
    {
      Header: () => <Trans ns={"orders"} i18nKey="labels.table.service_type" />,
      id: "serviceType",
      accessor: (ent: Partial<Service>) => ent?.serviceType?.displayText,
    },
    {
      Header: () => <Trans i18nKey="labels.table.seller" ns={"orders"} />,
      id: "seller",
      accessor: (ent: Partial<Service>) => ent?.seller?.displayText,
    },
    {
      Header: () => <Trans i18nKey="labels.table.start_date" ns={"orders"} />,
      id: "registeredDate",
      accessor: (ent: Partial<Service>) =>
        !!ent.registeredDate ? (
          format(new Date(ent.registeredDate), "dd.MM.yyyy")
        ) : (
          <Typography variant="caption">none</Typography>
        ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.workers_count" ns={"orders"} />,
      id: "workersCount",
      accessor: (ent: Partial<Service>) => ent.assignedWorkersCount,
    },
    {
      Header: () => <Trans i18nKey="labels.table.capacity" ns={"orders"} />,
      id: "capacity",
      accessor: (ent: Partial<Service>) => ent.capacity || 0,
    },
    {
      Header: () => <Trans i18nKey="labels.table.status" ns={"orders"} />,
      id: "status",
      accessor: (ent: Partial<Service>) => {
        return !!ent.statusMatchingName ? (
          <StatusColor color={ent.statusMatchingName} />
        ) : (
          <Typography variant="caption">none</Typography>
        );
      },
    },
    {
      Header: () => <Trans i18nKey="labels.table.externalId" ns={"orders"} />,
      id: "externalId",
      accessor: (ent: Partial<Service>) =>
        !!ent.externalId ? ent.externalId : <Typography variant="caption">none</Typography>,
    },
  ];
};

const OrdersTableDetails: FC<{ id: number; SOData: ServiceOrder }> = React.memo(
  ({ id, SOData }) => {
    const { servicesData } = useServicesContext();
    if (servicesData.selectedSO[id]?.services.length <= 0) {
      return (
        <Grid container justify="center">
          <NoServices SOData={SOData} />
        </Grid>
      );
    }
    return (
      <MainTable<Service> data={servicesData.selectedSO[id]?.services} columnsData={columns} />
    );
  }
);

export default OrdersTableDetails;
