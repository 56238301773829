import React, { forwardRef, FC } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  toolbar: {
    padding: 8,
  },
  menu: {
    "& > *": {
      display: "inline-block",
    },
    "& > * + * ": {
      marginLeft: 15,
    },
  },
  squareButton: {
    padding: theme.spacing(1),
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
  btn: ({ reversed, active }: { reversed?: string; active?: string }) => {
    return {
      cursor: "pointer",
      color: reversed ? (active ? "white" : "#aaa") : active ? "black" : "#ccc",
    };
  },
}));
export const Button = forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: {
      className: string;
      active: string;
      reversed: string;
    },
    ref: any
  ) => {
    const { btn } = useStyle({ reversed, active });
    return <span {...props} ref={ref} className={btn} />;
  }
);

export const Menu = forwardRef(({ className, ...props }: { className: string }, ref: any) => {
  const { menu } = useStyle({});
  return <div {...props} ref={ref} className={menu} />;
});

export const Portal: FC = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

export const Toolbar = forwardRef(({ className, ...props }: any, ref) => {
  const { toolbar } = useStyle({});
  return <Menu {...props} ref={ref} className={toolbar} />;
});

export const BlockButton = ({
  icon,
  onClick,
}: {
  icon: JSX.Element;
  onClick?: (event: React.MouseEvent) => void;
}) => {
  const { squareButton } = useStyle({});
  return (
    <IconButton color="primary" onClick={onClick} className={squareButton}>
      {icon}
    </IconButton>
  );
};
