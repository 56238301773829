import React, { useEffect, useMemo, useState } from "react";
import createCtx from "../../utils/createCtx";
import { requestMetaGet } from "src/utils/crud";
import { Context, Props, ServiceOrderStatus } from "./types";
import { useAppContext } from "../AppProvider";
import { useLazy } from "src/utils/hooks/useLazy";
import { useUpdatedEffect } from "src/utils/hooks/useUpdatedEffect";

const [useCtx, Provider] = createCtx<Context>();

const ServiceOrderStatusesProvider = (props: Props): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState<ServiceOrderStatus[]>([]);

  const {
    state: { loading: appLoading, user },
  } = useAppContext();

  const fetchingSOStatuses = async () => {
    try {
      const res = await requestMetaGet<ServiceOrderStatus[]>(
        `lookups/serviceOrderStatuses?territoryKey=${user.territory?.key}&includeIncomplete=true`
      );
      setStatuses(res.data);
    } finally {
      setLoading(false);
    }
  };

  const [subscribe, refresh] = useLazy(() => {
    if (appLoading) {
      return;
    }

    if (!user.territory?.key) {
      return;
    }
    fetchingSOStatuses();
  }, [appLoading, user.territory?.key]);

  useUpdatedEffect(() => {
    if (!user?.locale?.key) {
      return;
    }

    refresh();
  }, [user?.locale?.key]);

  const state = useMemo(
    () => ({
      loading,
      statuses,
    }),
    [loading, statuses]
  );

  return (
    <Provider
      value={{
        state,
        subscribe,
      }}
    >
      {props.children}
    </Provider>
  );
};

const useServiceOrderStatusesContext = () => {
  const data = useCtx();
  const { subscribe } = data;

  useEffect(subscribe, []);

  return data;
};

export { useServiceOrderStatusesContext, ServiceOrderStatusesProvider };
