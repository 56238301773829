import { makeStyles, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";
import { CaptionIcon } from "../../images";
import { useTranslation } from "react-i18next";
import { Comment } from "src/providers/CommentsStore/types";
import { multiLineEllipsis } from "src/utils/commonStyles";

type Props = {
  message: Comment;
};

const MessagePreview = ({ message }: Props) => {
  const { t } = useTranslation("comments");
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.name}>
        {message.createdBy.firstName} {message.createdBy.lastName}
      </Typography>
      <Typography className={classes.message}>{message.message}</Typography>
      <div className={classes.info}>
        <CaptionIcon />
        <Typography variant="caption">
          {t("labels.shared_from")} {message.sharedChannel} {t("labels.chanel")}.{" "}
          {t("labels.posted")} {format(new Date(message.createdDate), "MM.dd.yyyy HH:mm")}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: "2px solid #1B3A7F",
    paddingLeft: theme.spacing(1),
    "& > :not(:first-child)": {
      marginTop: theme.spacing(1),
    },
  },
  name: {
    fontWeight: "bold",
  },
  info: {
    display: "flex",
    alignItems: "center",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
  message: {
    ...multiLineEllipsis(8),
  },
}));

export default MessagePreview;
