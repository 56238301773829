import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const OrdersIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.44445 1H1V6.44445H6.44445V1Z" fill="white" fillOpacity="0.5" />
    <path d="M15.0001 1H9.55566V6.44445H15.0001V1Z" fill="white" />
    <path d="M15.0001 9.55554H9.55566V15H15.0001V9.55554Z" fill="white" fillOpacity="0.5" />
    <path d="M6.44445 9.55554H1V15H6.44445V9.55554Z" fill="white" fillOpacity="0.5" />
  </SvgIcon>
);

export default OrdersIcon;
