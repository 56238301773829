import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    tableWrapper: {
      "& .MuiTabs-root": {
        height: 30,
      },
      "& .MuiTableHead-root": {
        backgroundColor: "rgba(255, 255, 255, 0.0)",
      },
      "& .MuiTableCell-head": {
        padding: "8px 16px",
      },
      "& .MuiTableCell-root": {
        fontWeight: "normal",
      },
    },
    totalCell: {
      padding: "8px 16px",
    },
  };
});
