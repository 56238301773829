import React, { FC } from "react";

import { Typography, Link, Badge, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import ListItem from "@material-ui/core/ListItem";
import { Link as RouterLink } from "react-router-dom";
import { useCommentsContext } from "src/providers/CommentsStore";
import { theme } from "src/utils/styleTheme/mainStyles";

export const capitalize = (str: string) => {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};
interface Props {
  onClick: (id: string) => void;
  id: string;
  title: string;
  visibleMessageText: string;
  visibleMessageCreatedDate: string | number | Date;
  isDefault: boolean;
  parentEntityId: string;
  parentEntityName: string;
  parentEntityType: string;
  serviceTypeName?: string;
  countUnreadComments: string;
  visibleMessageAuthor: string;
}

const Thread: FC<Props> = (data) => {
  const {
    onClick,
    id,
    title,
    visibleMessageText,
    visibleMessageCreatedDate = new Date(),
    isDefault,
    parentEntityId,
    parentEntityName,
    parentEntityType,
    countUnreadComments,
    visibleMessageAuthor
  } = data;
  const {
    comments: { chatThread, meta },
  } = useCommentsContext();
  const { topicTitle,  topicSubtitle, threadItem, badgeCounter, topicTitleWrapper } = useStyles();
  const isCommentFromService: boolean = (!!parentEntityId && !!parentEntityType) && (Number(meta.key) !== Number(parentEntityId))
  return (
    <ListItem
      button
      className={threadItem}
      onClick={() => {
        onClick(id);
      }}
      id={id}
      disableGutters={true}
      style={{ order: isDefault ? 0 : 1, minHeight: isCommentFromService ? 115 : 70 }}
      selected={Number(id) === Number(chatThread.key)}
    >
      <Grid container justify="space-between" alignItems="center" className={topicTitleWrapper}>
        <Typography align="left" variant="subtitle1" className={topicTitle}>
          {capitalize(title)}
        </Typography>
        {countUnreadComments ? (
            <Badge badgeContent={countUnreadComments} color="primary" className={badgeCounter} />
          ) : (
            ""
        )}
      </Grid>
      {isCommentFromService && (<>
        <Link
          component={RouterLink}
          underline="none"
          to={`/services/details/${parentEntityId}/comments`}
          align="left"
          variant="subtitle2"
        >
          <Typography align="left" color="textSecondary" variant="body2">
            {parentEntityName}: {title}
          </Typography>
        </Link>
          <Typography align="left" variant="body1">
            {data?.serviceTypeName}
          </Typography>
        
      </>)}
      <div className={topicSubtitle}>
        <Typography 
          align="left" 
          color="textSecondary" 
          variant="body2"
          style={{
            whiteSpace: "nowrap",
            maxWidth: 290,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {visibleMessageAuthor}: {visibleMessageText}
        </Typography>
        <Typography  
          color="textSecondary" 
          variant="body2" 
          style={{whiteSpace: "nowrap", marginLeft: theme.spacing(1)}}>• {formatDistanceToNow(new Date(visibleMessageCreatedDate))}
        </Typography>
      </div>
    </ListItem>
  );
};

export default Thread;
