import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  contentWrap: {
    maxHeight: 250,
    "&::-webkit-scrollbar": {
      width: "0.4em",
      borderRadius: 20,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      borderRadius: 20,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: 20,
    },
  },
  caption: {
    display: "block",
    color: "rgba(20, 30, 48, 0.5)",
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: 16,
  },
  textWrapper: {
    height: "calc(100% - 10px)",
    overflow: "auto"
  }
}));
