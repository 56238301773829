import React, { useCallback } from "react";
import MonteraCard from "src/components/MonteraCard";
import NotesIcon from "../../../components/icons/NotesIcon";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import { useAppContext } from "src/providers/AppProvider";
import EditNotesIcon from "./icons/EditNotesIcon";
import EditNotes from "./EditNotes";

type Props = {
  editable: boolean;
};

const Summary: React.FC<Props> = ({ editable }: Props) => {
  const { t } = useTranslation("customers");
  const {
    selectedCustomer: { customer },
    callAPI: { setContextData },
  } = useCustomerDetailsContext();
  const {
    callAppAPI: { toggleModal },
    state: { user },
  } = useAppContext();

  const handleModal = useCallback(() => {
    toggleModal({ id: "editCustomerNotes", status: true });
  }, []);

  const isIntegration = user?.partner?.isIntegration;

  return (
    <>
      <MonteraCard
        headerIcon={<NotesIcon />}
        title={t("labels.details.notes")}
        action={
          editable && !isIntegration ? (
            <IconButton onClick={handleModal}>
              <EditNotesIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          ) : undefined
        }
      >
        {customer.notes || "--"}
      </MonteraCard>
      <EditNotes customer={customer} setContextData={setContextData} />
    </>
  );
};

export default React.memo(Summary);
