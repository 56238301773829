import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  btnGroup: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  submitBtn: {
    padding: theme.spacing(2),
    fontSize: "14px !important",
    color: "#ffff",
    backgroundColor: "#2B59C3",
    opacity: 1,
    "&:disabled": {
      opacity: 0.5,
      backgroundColor: "#2B59C3 !important",
    },
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    fontSize: "14px !important",
    color: theme.palette.primary.main,
  },
  successIcon: { width: 56, height: 56, marginBottom: 20 },
  titleWrapper: {
    textAlign: "center",
  },
  subtitle: {
    color: theme.palette.text.secondary,
    margin: `${theme.spacing(2)}px 0`,
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  primaryText: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));
