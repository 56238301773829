// Config object to be passed to Msal on creation
// import { LogLevel } from "@azure/msal-common";

export const msalConfig = {
  auth: {
    authority: `${process.env.REACT_APP_CONFIG_Authority}`,
    clientId: `${process.env.REACT_APP_CONFIG_ClientID}`,
    redirectUri: `${process.env.REACT_APP_CONFIG_Redirect_URI}`,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: `${process.env.REACT_APP_CONFIG_Post_LogoutRedirect_URI}`,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CONFIG_Cache_Location,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      // loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
      //   if (containsPii) {
      //     return;
      //   }
      //   switch (level) {
      //     case LogLevel.Error:
      //       console.error(message);
      //       return;
      //     case LogLevel.Info:
      //       console.info(message);
      //       return;
      //     case LogLevel.Verbose:
      //       console.debug(message);
      //       return;
      //     case LogLevel.Warning:
      //       console.warn(message);
      //       return;
      //   }
      // },
      // piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authenticationParameters = {
  scopes: [`${process.env.REACT_APP_CONFIG_Scopes}`],
};

export const authenticationParametersFile = {
  scopes: [process.env.REACT_APP_CONFIG_FILE_Scopes],
};
