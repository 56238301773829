import { useFeatureFlagContext } from "src/providers/FeatureFlagStore";
import { AwilableFeatureFlagKeys } from "src/providers/FeatureFlagStore/types";

type Props<T> = {
  featureKey: AwilableFeatureFlagKeys;
  children: () => T;
};
export const Feature = <T,>({ featureKey, children }: Props<T>) => {
  const featureFlags = useFeatureFlagContext();

  if (!featureFlags[featureKey]) {
    return null;
  }

  return children();
};
