import React, { useState, useEffect } from "react";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { TextField } from "@material-ui/core";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

const FileSortDropdown: React.FC = () => {
  const { t } = useTranslation(`orders`);
  const {
    filesState: { sortBy, des },
    callAPI: { setFilesContextData },
  } = useSODetailsContext();
  const classes = useStyles();
  const [value, setValue] = useState<string>("fileName");

  const handleSorting = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes("_desc")) {
      let val = event.target.value.split("_");
      setFilesContextData("sortBy", val[0]);
      setFilesContextData("des", true);
    } else {
      setFilesContextData("sortBy", event.target.value);
      setFilesContextData("des", false);
    }
  };

  const sortValues = [
    { key: "fileName_desc", displayText: t("labels.files.sort_values.name_desc") },
    { key: "fileName", displayText: t("labels.files.sort_values.name_asc") },
    { key: "createdDate_desc", displayText: t("labels.files.sort_values.created_desc") },
    { key: "createdDate", displayText: t("labels.files.sort_values.created_asc") },
    { key: "category.displayText_desc", displayText: t("labels.files.sort_values.category_desc") },
    { key: "category.displayText", displayText: t("labels.files.sort_values.category_asc") },
    { key: "createdBy_desc", displayText: t("labels.files.sort_values.сreatedBy_desc") },
    { key: "createdBy", displayText: t("labels.files.sort_values.сreatedBy_asc") },
  ];

  useEffect(() => {
    if (des) {
      setValue(`${sortBy}_desc`);
    } else {
      setValue(sortBy);
    }
  }, [sortBy, des]);

  return (
    <TextField
      select
      onChange={handleSorting}
      value={value}
      variant="outlined"
      SelectProps={{
        native: true,
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ classes: { root: classes.root } }}
    >
      {sortValues.map((el, idx) => (
        <option key={idx} value={el.key}>
          {el.displayText}
        </option>
      ))}
    </TextField>
  );
};

export default FileSortDropdown;
