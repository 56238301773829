import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SpecialInstructionsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 2H17" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 8H17" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 14H17" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 2H2" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 8H2" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 14H2" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
export default SpecialInstructionsIcon;
