import React, { useCallback } from "react";
import { Button, Divider, Grid, IconButton } from "@material-ui/core";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/providers/AppProvider";
import CustomersSortDropdown from "../CustomersSortDropdown";
import FilterIcon from "src/assets/icons/FilterIcon";
import { usePageActionStyles } from "./styles";

interface Props {
  toggleFilters: () => void;
}

const PageActions = ({ toggleFilters }: Props) => {
  const { t } = useTranslation("customers");
  const {
    state: { user },
    callAppAPI,
  } = useAppContext();
  const handleModalOpen = useCallback(() => {
    callAppAPI.toggleModal({ id: "createCustomerModal", status: true });
  }, []);

  const classes = usePageActionStyles({ isFilterPanelOpen: false });
  const isIntegration = user?.partner?.isIntegration;

  return (
    <Grid container direction="row" justify="space-between" spacing={1} wrap="nowrap">
      <Grid item container direction="row">
        <CustomersSortDropdown />
        {!isIntegration && (
          <>
            <Divider orientation="vertical" flexItem classes={{ root: classes.devider }} />
            <Button startIcon={<AddIcon />} onClick={handleModalOpen}>
              {t("labels.customers_list.new_customer")}
            </Button>
          </>
        )}
      </Grid>
      <Grid item>
        <IconButton onClick={toggleFilters} className={classes.filterIcon}>
          <FilterIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PageActions;
