import { makeStyles } from "@material-ui/core";

export const useAnalitycsStyles = makeStyles((theme) => ({
    mainWrapper: {
        marginTop: theme.spacing(1),
    },
    mainLink: {
        margin: "5px 10px"
    }
}));
