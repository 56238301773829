import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const EmptyFilesIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="80" cy="80" r="80" fill="#141E30" fillOpacity="0.05" />
    <g>
      <path
        d="M103.895 120H42.1587C40.738 120 39.7049 118.651 40.0759 117.279L54.098 78.8361C54.3518 77.8954 55.2056 77.2416 56.1808 77.2416H117.917C119.338 77.2416 120 78.5988 120 79.963L105.978 118.406C105.724 119.346 104.87 120 103.895 120Z"
        fill="white"
      />
      <path
        d="M103.895 120H42.1588C40.7381 120 39.705 118.651 40.0761 117.278L54.0981 78.8357C54.3519 77.895 55.2057 77.2412 56.1809 77.2412H117.917C119.338 77.2412 120 78.5985 120 79.9626L105.978 118.405C105.724 119.346 104.871 120 103.895 120Z"
        fill="white"
      />
      <path
        d="M54.5679 79.007L54.5753 78.9868L54.5809 78.9659C54.7758 78.2436 55.4315 77.7412 56.1809 77.7412H117.917C118.442 77.7412 118.815 77.9805 119.077 78.3695C119.338 78.7572 119.486 79.2983 119.499 79.877L105.508 118.234L105.501 118.254L105.495 118.275C105.301 118.997 104.645 119.5 103.895 119.5H42.1588C41.0741 119.5 40.2834 118.476 40.5536 117.428L54.5679 79.007Z"
        stroke="#141E30"
        strokeOpacity="0.1"
        fill="none"
      />
      <path
        d="M109.335 64.8277H106.207V77.2415H111.724V67.2167C111.724 65.8967 110.654 64.8277 109.335 64.8277Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
      <path
        d="M45.5172 55.1719H42.389C41.0703 55.1719 40 56.2408 40 57.5608V116.496C40.0952 116.499 40.1903 116.504 40.2828 116.51L45.5172 102.237V55.1719Z"
        fill="white"
      />
      <path
        d="M45.5172 55.1723H42.389C41.0703 55.1723 40 56.2413 40 57.5613V116.496C40.0952 116.499 40.1903 116.505 40.2828 116.51L45.5172 102.237V55.1723Z"
        fill="#141E30"
        fillOpacity="0.5"
      />
      <path
        d="M54.0979 78.8359C54.3517 77.8952 55.2055 77.2414 56.1807 77.2414H58.9393H106.207V60.6897V40H85.5172H53.7931V79.6703L54.0979 78.8359Z"
        fill="white"
      />
      <path
        d="M63.4481 62.0686H96.5515C97.3129 62.0686 97.9308 61.452 97.9308 60.6892C97.9308 59.9265 97.3129 59.3099 96.5515 59.3099H63.4481C62.6867 59.3099 62.0688 59.9265 62.0688 60.6892C62.0688 61.452 62.6867 62.0686 63.4481 62.0686Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
      <path
        d="M63.4481 52.4136H77.2412C78.0026 52.4136 78.6205 51.797 78.6205 51.0343C78.6205 50.2715 78.0026 49.655 77.2412 49.655H63.4481C62.6867 49.655 62.0688 50.2715 62.0688 51.0343C62.0688 51.797 62.6867 52.4136 63.4481 52.4136Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
      <path
        d="M96.5515 68.9653H63.4481C62.6867 68.9653 62.0688 69.5819 62.0688 70.3446C62.0688 71.1074 62.6867 71.7239 63.4481 71.7239H96.5515C97.3129 71.7239 97.9308 71.1074 97.9308 70.3446C97.9308 69.5819 97.3129 68.9653 96.5515 68.9653Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
      <path d="M53.7929 79.6701V42.7584H49.655V91.015L53.7929 79.6701Z" fill="white" />
      <path
        d="M53.7932 79.6705V42.7588H49.6553V91.0153L53.7932 79.6705Z"
        fill="#141E30"
        fillOpacity="0.1"
      />
      <path d="M49.6555 91.0155V45.5176H45.5176V102.36L49.6555 91.0155Z" fill="white" />
      <path
        d="M49.6553 91.0153V45.5173H45.5173V102.36L49.6553 91.0153Z"
        fill="#141E30"
        fillOpacity="0.3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="80" height="80" fill="white" transform="translate(40 40)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default EmptyFilesIcon;
