import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { useStyles } from "src/pages/ServiceOrdersDetails/tabs/LineItems/components/Modals/styles";
import Preloader from "src/components/Preloader";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "src/pages/ServiceOrdersDetails/components/icons/CloseIcon";

function DeleteModal() {
  const {
    LIData: { modal, deleting },
    callLineItemsAPI,
  } = useLineItemsContext();
  const classes = useStyles();
  const { t } = useTranslation(`lineItems`);

  const handleClose = () => {
    callLineItemsAPI.handleModal({ open: "" });
  };
  const handleDeletion = () => {
    callLineItemsAPI.handleDelete(modal?.id);
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.paper}
      open={modal.open === "delete"}
    >
      {deleting ? (
        <div style={{ minWidth: 300, minHeight: 300 }}>
          <Preloader is />
        </div>
      ) : (
        <>
          <div>
            <IconButton onClick={handleClose} className={classes.closeBtn}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h2">{t("labels.delete_title")}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">{t("labels.delete_desc")}</Typography>
          </DialogContent>
          <DialogActions
            style={{
              padding: "8px 27px",
            }}
          >
            <Button
              style={{
                padding: "16px 22px",
                fontSize: 14,
                backgroundColor: "#2B59C3",
              }}
              type="submit"
              variant={"contained"}
              color={"primary"}
              onClick={handleDeletion}
              size={"large"}
            >
              {t("labels.accept")}
            </Button>
            <Button
              style={{
                padding: "16px 22px",
                fontSize: 14,
                color: "#2B59C3",
              }}
              variant={"text"}
              color={"inherit"}
              type="button"
              size={"large"}
              onClick={() => {
                handleClose();
              }}
            >
              {t("labels.cancel", { ns: "common" })}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default DeleteModal;
