import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const WarehouseIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0379 3.12782L9.09279 0.15527C8.67616 -0.0517567 8.18663 -0.0517567 7.77001 0.15527L5.27856 1.40099L12.1472 4.8353L15.2723 3.27276C15.1996 3.21789 15.1213 3.16925 15.0379 3.12782Z"
      fill="#F4CE04"
    />
    <path
      d="M15.8226 4.1156L8.93115 7.56134V15.9121C8.98601 15.8924 9.03999 15.8693 9.09279 15.8429L15.0379 12.8703C15.543 12.6194 15.8625 12.1041 15.8628 11.5401V4.45803C15.8627 4.34115 15.8489 4.22637 15.8226 4.1156Z"
      fill="#F4CE04"
    />
    <path
      d="M7.93115 15.9146V7.56114L1.04015 4.11564C1.01386 4.2264 1.0001 4.34117 1.00004 4.45803V11.5327C0.996104 12.0977 1.31289 12.6161 1.81749 12.8703L7.76258 15.8429C7.8176 15.8704 7.8739 15.8943 7.93115 15.9146Z"
      fill="#F4CE04"
    />
    <path
      d="M1.59051 3.27278L8.43136 6.69321L11.0291 5.39431L4.49222 2.12585C4.3926 2.07604 4.31688 1.99747 4.27008 1.90523L1.82492 3.12782C1.74152 3.16926 1.66318 3.2179 1.59051 3.27278Z"
      fill="#F4CE04"
    />
    <path
      d="M15.0379 3.12782L9.0928 0.15527C8.67617 -0.0517567 8.18664 -0.0517567 7.77002 0.15527L5.27857 1.40099L12.1472 4.8353L15.2723 3.27276C15.1996 3.21789 15.1213 3.16925 15.0379 3.12782Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M1.59052 3.27278L8.43137 6.69321L11.0292 5.39431L4.49223 2.12585C4.39261 2.07604 4.31689 1.99747 4.27009 1.90523L1.82493 3.12782C1.74153 3.16926 1.66319 3.2179 1.59052 3.27278Z"
      fill="white"
      fillOpacity="0.5"
    />
  </SvgIcon>
);

export default WarehouseIcon;
