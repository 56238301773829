import React, { useCallback, useEffect, useMemo } from "react";
import { useStyles } from "src/pages/Orders/modals/EditForm/styles";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ReferencesIcon from "../../components/icons/ReferencesIcon";
import DateRangeIcon from "../../components/icons/DateRangeIcon";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import { ServiceOrder } from "src/types";
import { Controller, useForm } from "react-hook-form";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import DatePicker from "src/components/DatePicker";
import Success from "./Success";
import { useSellersContext } from "src/providers/SellersStore";
import { assemblyZoneAvailable, behaviorByStatus } from "src/utils/functions";
import SelectInput from "src/components/SelectInput";

const SOEditForm = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    serviceOrderDetails: { serviceOrder, customerInfo, loading, updated },
    callAPI: { updateSOdata, setContextData },
  } = useSODetailsContext();
  const {
    state: {
      modals: { serviceOrdersEditModal },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    sellersStore,
    callAPI: { getWarehousesSellers },
  } = useSellersContext();
  const { handleSubmit, register, errors, control, setValue, watch } = useForm<ServiceOrder>({
    mode: "all",
    defaultValues: {
      ...serviceOrder,
      assemblyZoneId: serviceOrder?.assemblyZone?.key || null,
      sellerId: serviceOrder?.seller?.key,
    },
  });
  const sellers = sellersStore.sellers;

  const handleAssemblyZoneChange = (value: number) => {
    register("assemblyZoneId");
    setValue("assemblyZoneId", value);
  };

  useEffect(() => {
    serviceOrder.warehouse?.key && getWarehousesSellers(serviceOrder.warehouse.key, 999, false);
  }, []);

  const disabledByStatus = useMemo(() => {
    return behaviorByStatus(serviceOrder?.statusMatchingName, ["FAILED", "NEW"]);
  }, [serviceOrder.statusMatchingName]);

  const handleModal = useCallback(() => {
    toggleModal({ id: "serviceOrdersEditModal", status: false });
  }, []);

  const onChangeHandler = (event: any) => {
    if (event.target.value) {
      const sellerData = sellers.find((seller) => seller.key === event.target.value);
      register({ name: "sellerId" });
      setValue("sellerId", sellerData?.key);
    } else {
      setValue("sellerId", "");
    }
  };

  const onSubmit = useCallback(
    (data: ServiceOrder) => {
      updateSOdata({ ...data, id: serviceOrder.id });
    },
    [serviceOrder.id]
  );

  if (updated) {
    return (
      <Modal isOpen={serviceOrdersEditModal} onClose={handleModal}>
        <Success
          handlerOnOK={() => {
            setContextData("updated", false);
            handleModal();
          }}
        />
      </Modal>
    );
  }

  return (
    <Modal isOpen={serviceOrdersEditModal} onClose={handleModal}>
      <Grid container justify="center" spacing={1}>
        <Grid item>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h3">{t("form.title")}</Typography>
            <div className={classes.fieldGroup}>
              <div className={classes.titleWrapper}>
                <ReferencesIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("form.references")}
                </Typography>
              </div>
              <TextField
                fullWidth
                error={!!errors.externalId}
                name="externalId"
                label={t("form.externalId")}
                variant="outlined"
                inputRef={register()}
              />
              <TextField
                fullWidth
                error={!!errors.billingReference}
                name="billingReference"
                label={t("form.billing_reference")}
                variant="outlined"
                inputRef={register()}
              />
              <TextField
                fullWidth
                error={!!errors.reference}
                name="reference"
                label={t("form.reference_other")}
                variant="outlined"
                inputRef={register()}
              />
              <Controller
                control={control}
                name="sellerId"
                as={
                  <Select
                    variant="outlined"
                    disabled={disabledByStatus}
                    fullWidth
                    onChange={(e) => onChangeHandler(e)}
                  >
                    {sellers.map((seller) => (
                      <MenuItem value={seller.key} key={seller.key}>
                        {seller.displayText}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
              {serviceOrder.deliveryAddressZipCode &&
                assemblyZoneAvailable(
                  !!serviceOrder.isIntegration,
                  serviceOrder.partner?.displayText || ""
                ) && (
                  <SelectInput
                    metaApi={true}
                    onSelect={handleAssemblyZoneChange}
                    label={t("labels.creation.assembly_zone")}
                    name="assemblyZone"
                    disabled={disabledByStatus}
                    value={watch("assemblyZoneId") || ""}
                    url={`lookups/assemblyZones/zip/${customerInfo?.deliveryAddress?.zipCode?.key}?partnerKey=${serviceOrder.partner?.key}`}
                    autoselectIfOnlyOneOption={serviceOrder.partner?.displayText === "IKEA (MIS)"}
                  />
                )}
            </div>
            <div className={classes.fieldGroup}>
              <div className={classes.titleWrapper}>
                <DateRangeIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("form.date_range")}
                </Typography>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Controller
                    as={
                      <DatePicker
                        label={t("form.start_date")}
                        required
                        fullWidth
                        disabled={disabledByStatus}
                      />
                    }
                    error={!!errors.startDate}
                    name="startDate"
                    control={control}
                    rules={{ required: "Required" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    as={
                      <DatePicker
                        label={t("form.delivery_date")}
                        required
                        fullWidth
                        disabled={disabledByStatus}
                      />
                    }
                    error={!!errors.deliveryDate}
                    name="deliveryDate"
                    control={control}
                    rules={{ required: "Required" }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.btnGroup}>
              <Button type="submit" className={classes.submitBtn}>
                {loading ? (
                  <CircularProgress size={18} color={"secondary"} />
                ) : (
                  t("labels.common.save_changes")
                )}
              </Button>

              <Button onClick={handleModal} className={classes.cancelBtn}>
                {t("labels.common.cancel")}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SOEditForm;
