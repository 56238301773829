import { Button, Paper, Divider, IconButton, Link } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";

import { reject, map, isEmpty, min, filter } from "lodash";
import FilterIcon from "./icons/filterIcon";
import ClearIcon from "./icons/clearIcon";
import ArrowUpIcon from "./icons/arrow-upIcon";
import i18next from "i18next";
import clsx from "clsx";
import { filterDefaultValues } from "../Filter";
import { OrdersFilterParams } from "src/providers/ServiceOrdersStore/types";
import { filterChipStyles } from "./styles";
import { config } from "./config";
import FilterGroupMulti from "./filterGroupMilti";
import FilterGroup from "./filterGroup";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { useTranslation } from "react-i18next";

const ParsedKeys = ["seller", "customerKey"];

const OrdersSelectedFilters: React.FC = () => {
  const classes = filterChipStyles();
  const { t } = useTranslation("orders");
  const {
    SOData: { filters },
    callSOAPI,
  } = useSOContext();
  const [expanded, setExpanded] = useState(false);
  const groups = React.useRef<HTMLDivElement>(null);

  const ignoreCall = useRef(false);

  const [stateFilters, setStateFilters] = useState<Partial<OrdersFilterParams>>(filters);
  useEffect(() => {
    let booferObject: { [key: string]: any } = {};
    for (const [key, value] of Object.entries(filters)) {
      if (!!value) {
        if (ParsedKeys.includes(key)) {
          switch (key) {
            case "seller":
              booferObject.seller = { key: value, displayText: filters.sellerName };
              break;
            case "customerKey":
              booferObject.customerKey = { key: value, displayText: filters.customerName };
              break;
            default:
              break;
          }
        } else booferObject[key] = value;
      }
    }

    ignoreCall.current = true;
    setStateFilters(booferObject);
  }, [filters]);

  const handleRemove = (name: keyof OrdersFilterParams) => {
    ignoreCall.current = false;
    let booferObject: Partial<OrdersFilterParams> = filters;

    switch (name) {
      case "seller":
        booferObject.seller = "";
        booferObject.sellerName = "";
        break;
      case "customerKey":
        booferObject.customerKey = "";
        booferObject.customerName = "";
        break;
      case "createdDateFrom":
      case "createdDateTo":
      case "deliveryDateFrom":
      case "deliveryDateTo":
      case "deliveryAddressZipCode":
      case "deliveryAddress":
        booferObject[name] = "";
        break;
      default:
        break;
    }
    callSOAPI.setContextData("filters", { ...booferObject });
  };

  const handleRemoveArray = (name: keyof OrdersFilterParams, key: string) => {
    ignoreCall.current = false;
    callSOAPI.setContextData("filters", {
      ...filters,
      ...(key === filters.sellerWarehouseKey && {
        seller: "",
        sellerName: "",
        sellerWarehouseKey: "",
      }),
      [name]: reject(filters[name], (x: any) => x.key === key),
    });
  };

  const clearFilters = () => {
    callSOAPI.setContextData("filters", filterDefaultValues);
    setExpanded(false);
  };

  const onShowMoreClick = () => {
    setExpanded(true);
  };

  const onCollapseClick = () => {
    setExpanded(false);
  };

  const filteredValue = reject(
    config,
    (x) =>
      !stateFilters[x.key] ||
      isEmpty(stateFilters[x.key]) ||
      (!x.multi && x.format && !x.format(stateFilters[x.key]))
  );

  const empty = isEmpty(filteredValue);

  const [moreItemsCount, setMoreItemsCount] = useState(0);

  const getMoreItemsCount = () => {
    const itemsOffsets = map(groups?.current?.children, (x) => x.getBoundingClientRect().y);
    const minOffsetHeight = min(itemsOffsets);
    const newMoreItemsCount = filter(itemsOffsets, (x: number) => x > minOffsetHeight!).length;
    return newMoreItemsCount;
  };

  // TODO: maybe there is a better way to manage block resizing
  useEffect(() => {
    if (!expanded) {
      const interval = setInterval(() => {
        if (groups.current) {
          const newMoreItemsCount = getMoreItemsCount();
          setMoreItemsCount(newMoreItemsCount);
        }
      }, 500);
      return () => clearInterval(interval);
    } else return;
  }, []);

  if (empty) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.filtersLabel}>
        <FilterIcon />
        <span>{i18next.t("list_filter.title", { ns: "orders" })}:</span>
      </div>
      <div
        className={clsx(classes.groupsContainer, { [classes.groupsContainerExpanded]: expanded })}
      >
        <div className={classes.groupsWrapper}>
          <div
            className={clsx(classes.groups, { [classes.groupsExpanded]: expanded })}
            ref={groups}
          >
            {map(
              filteredValue,
              (x: {
                key: keyof OrdersFilterParams;
                [name: string]: any;
                format: (text: string) => string;
              }) =>
                x.multi ? (
                  <FilterGroupMulti
                    {...x}
                    name={x.key}
                    label={x.label(i18next)}
                    item={stateFilters[x.key]}
                    onDelete={handleRemoveArray}
                  />
                ) : (
                  <FilterGroup
                    {...x}
                    name={x.key}
                    label={x.label(i18next)}
                    item={stateFilters[x.key]}
                    onDelete={handleRemove}
                  />
                )
            )}
          </div>
        </div>
        <div className={classes.groupsEnd}>
          {expanded ? (
            <>
              <IconButton className={classes.collapseButton} onClick={onCollapseClick}>
                <ArrowUpIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Link
                className={clsx(classes.showMore, { [classes.showMoreHidden]: !moreItemsCount })}
                color="primary"
                onClick={onShowMoreClick}
              >
                {i18next.t("labels.more_items", { ns: "common", count: moreItemsCount })}
              </Link>
              <Divider orientation="vertical" />
            </>
          )}
        </div>
      </div>
      <Button
        className={clsx({ [classes.clearAllButtonExpanded]: expanded })}
        color="primary"
        startIcon={<ClearIcon />}
        onClick={clearFilters}
      >
        {t("list_filter.clear_btn")}
      </Button>
    </Paper>
  );
};

export default OrdersSelectedFilters;
