import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const EditIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 1L12.25 4L4 12.25H1V9.25L9.25 1Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M1 16H14.5"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  </SvgIcon>
);
export default EditIcon;
