import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const WarningIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.69066 1.73717L0.205221 12.5641C0.071506 12.7957 0.000754715 13.0582 6.00468e-06 13.3256C-0.000742706 13.593 0.0685372 13.8559 0.200953 14.0882C0.333369 14.3205 0.524306 14.5141 0.754766 14.6497C0.985227 14.7853 1.24718 14.8582 1.51456 14.8612H14.4854C14.7528 14.8582 15.0148 14.7853 15.2452 14.6497C15.4757 14.5141 15.6666 14.3205 15.799 14.0882C15.9315 13.8559 16.0007 13.593 16 13.3256C15.9992 13.0582 15.9285 12.7957 15.7948 12.5641L9.30934 1.73717C9.17284 1.51214 8.98064 1.32608 8.7513 1.19696C8.52195 1.06783 8.2632 1 8 1C7.7368 1 7.47805 1.06783 7.2487 1.19696C7.01936 1.32608 6.82716 1.51214 6.69066 1.73717Z"
      fill="#F4CE04"
    />
    <path d="M8 5.67188V8.73466" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 11.7988H8.00667" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
export default WarningIcon;
