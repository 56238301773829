import React from "react";
import Modal from "src/components/Modal";
import { Container, Grid, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

interface Props {
  isOpen: boolean;
  handleConfirm: () => void;
  handleDecline: () => void;
  fileName: string;
}

const ConfirmationModal: React.FC<Props> = ({ isOpen, handleConfirm, handleDecline, fileName }) => {
  const { t } = useTranslation(`orders`);
  const classes = useStyles();

  return (
    <Modal isOpen={isOpen} onClose={handleDecline}>
      <Container>
        <Grid container justify="center">
          <Grid item>
            <Typography variant="h6" style={{ paddingBottom: 24 }}>
              {t("labels.files.confirmation_title")}
            </Typography>
            <Typography>{t("labels.files.delete_text", { fileName: `"${fileName}"` })}</Typography>
          </Grid>

          <div className={classes.btnGroup}>
            <Button onClick={handleConfirm} className={classes.submitBtn}>
              {t("labels.common.yes")}
            </Button>
            <Button onClick={handleDecline} className={classes.cancelBtn}>
              {t("labels.common.cancel")}
            </Button>
          </div>
        </Grid>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
