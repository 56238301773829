import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.palette.functionalColors.dark,
    borderRadius: 4,
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
    },
  },
  icon: {
    fontSize: "4rem",
    padding: 15,
    backgroundColor: "#ececec",
    borderRadius: 100,
  },
  rootWithCheckbox: {
    "& .MuiChip-root": {
      backgroundColor: "#2B59C3",
      borderRadius: 4,
      color: "#fff",
    },
    "& .MuiChip-deleteIcon": {
      color: "#fff",
    },
  },
  paperWithCheckbox: {
    padding: 0,
  },
  optionWithCheckbox: {
    "&>span": {
      padding: 0,
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#2B59C3",
      color: "#fff",
    },
  },
  inputRoot: {
    paddingRight: "35px !important",
  },
  popupIndicator: {
    display: "inline-block",
  },
  clearIndicator: {
    visibility: "visible",
  },
}));
