import React from "react";
import { Trans } from "react-i18next";
import { UseTableRowProps } from "react-table";
import { Service } from "src/types";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography/Typography";
import LinkTo from "src/components/LinkTo";

export const columns = () => {
  return [
    {
      Header: () => (
        <Typography align={"left"} variant="body2">
          <Trans ns={"orders"} i18nKey="labels.table.service_name" />
        </Typography>
      ),
      id: "name",
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <LinkTo href={`/services/details/${row.original.id}/summary`}>
          <Typography variant="body2" component={"span"}>
            {row.original.name}
          </Typography>
        </LinkTo>
      ),
      accessor: (ent: Partial<Service>) => ent.name,
    },
    {
      Header: () => (
        <Typography align={"left"} variant="body2">
          <Trans ns={"orders"} i18nKey="labels.table.service_type" />
        </Typography>
      ),
      id: "serviceType.displayText",
      accessor: (ent: Partial<Service>) => ent.serviceType?.displayText,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <Typography variant="body2">{row.original.serviceType?.displayText}</Typography>
      ),
    },
    {
      Header: () => (
        <Typography align={"left"} variant="body2">
          <Trans i18nKey="labels.table.seller" ns={"orders"} />
        </Typography>
      ),
      id: "seller.displayText",
      accessor: (ent: Partial<Service>) => ent?.seller?.displayText,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <Typography variant="body2">{row.original.seller?.displayText}</Typography>
      ),
    },
    {
      Header: () => (
        <Typography align={"left"} variant="body2">
          <Trans i18nKey="labels.table.start_date" ns={"orders"} />
        </Typography>
      ),
      id: "startDate",
      accessor: (ent: Partial<Service>) => ent.startDate,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) =>
        !!row.original.startDate ? (
          <Typography variant="body2">
            {format(new Date(row.original.startDate), "dd.MM.yyyy")}
          </Typography>
        ) : (
          <Typography variant="caption">none</Typography>
        ),
    },
    {
      Header: () => (
        <Typography align={"left"} variant="body2">
          <Trans i18nKey="labels.table.workers_count" ns={"orders"} />
        </Typography>
      ),
      id: "assignedWorkersCount",
      accessor: (ent: Partial<Service>) => ent.assignedWorkersCount,
      Cell: (ent: { value: string }) => {
        return (
          <Typography align={"center"} component={"span"} variant="body2">
            {ent.value}
          </Typography>
        );
      },
    },
    {
      Header: () => (
        <Typography align={"left"} variant="body2">
          <Trans i18nKey="labels.table.capacity" ns={"orders"} />
        </Typography>
      ),
      id: "capacity",
      accessor: (ent: Partial<Service>) => ent.capacity || 0,
      Cell: (ent: { value: string }) => {
        return (
          <Typography align={"center"} component={"span"} variant="body2">
            {ent.value}
          </Typography>
        );
      },
    },
    {
      Header: () => (
        <Typography align={"left"} variant="body2">
          <Trans i18nKey="labels.table.status" ns={"orders"} />
        </Typography>
      ),
      id: "status.displayText",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      accessor: (ent: Partial<Service>) => ent.status?.displayText,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!!row.original.status?.displayText ? (
            <span
              style={{
                textAlign: "center",
                width: "fit-content",
                padding: 3,
                borderRadius: 5,
                backgroundColor: row.original.statusColor,
              }}
            >
              {row.original.status?.displayText}
            </span>
          ) : (
            <Typography variant="caption">none</Typography>
          )}
        </span>
      ),
    },
    {
      Header: () => (
        <Typography variant="body2">
          <Trans i18nKey="labels.table.externalId" ns={"orders"} />
        </Typography>
      ),
      id: "externalId",
      style: {
        display: "flex",
        justifyContent: "flex-end",
      },
      accessor: (ent: Partial<Service>) => ent.externalId,
      disableSortBy: true,
    },
  ];
};
