import { makeStyles } from "@material-ui/core";

export const filterChipStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    gridAutoFlow: "column",
    gap: `${theme.spacing(1)}px`,
    alignItems: "flex-start",
    marginBottom: theme.spacing(1),
  },
  filtersLabel: {
    display: "grid",
    gridAutoFlow: "column",
    gap: `${theme.spacing(1)}px`,
    alignItems: "center",
    color: theme.palette.text.hint,
    height: 28,
  },
  groupsContainer: {
    height: "100%",
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "1fr auto",
    gap: `${theme.spacing(1)}px`,
  },
  groupsContainerExpanded: {
    gridColumn: "2 / span 2",
  },
  showMore: {
    alignSelf: "center",
    padding: `0px ${theme.spacing(2)}px`,
    cursor: "pointer",
  },
  showMoreHidden: {
    visibility: "collapse",
  },
  groupsWrapper: {
    position: "relative",
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },
  groups: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: -theme.spacing(1),
    marginTop: -theme.spacing(1),
    "&>*": {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  groupsExpanded: {
    position: "static",
    alignItems: "flex-start",
  },
  groupsEnd: {
    display: "flex",
  },
  filterGroup: {
    display: "flex",
    alignItems: "center",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
  filterItemChip: {
    height: "unset",
    padding: theme.spacing(0.25),
  },
  clearAllButtonExpanded: {
    gridRow: 2,
    gridColumn: "1 / span 3",
    justifySelf: "flex-end",
  },
  collapseButton: {
    alignSelf: "flex-start",
  },
}));
