import warehouses_en from "src/assets/locales/en/warehouses.json";
import warehouses_da from "src/assets/locales/da/warehouses.json";
import common_en from "src/assets/locales/en/common.json";
import common_da from "src/assets/locales/da/common.json";
import log_templates_en from "src/assets/locales/en/log_templates.json";
import log_templates_da from "src/assets/locales/da/log_templates.json";
import lookups_en from "src/assets/locales/en/lookups.json";
import lookups_da from "src/assets/locales/da/lookups.json";
import customers_en from "src/assets/locales/en/customers.json";
import customers_da from "src/assets/locales/da/customers.json";
import sellers_en from "src/assets/locales/en/sellers.json";
import sellers_da from "src/assets/locales/da/sellers.json";
import orders_da from "src/assets/locales/da/orders.json";
import orders_en from "src/assets/locales/en/orders.json";
import lineItems_en from "src/assets/locales/en/lineItems.json";
import lineItems_da from "src/assets/locales/da/lineItems.json";
import files_en from "src/assets/locales/en/files.json";
import files_da from "src/assets/locales/da/files.json";
import analytics_en from "src/assets/locales/en/analytics.json";
import analytics_da from "src/assets/locales/da/analytics.json";
import comments_en from "src/assets/locales/en/comments.json";
import comments_da from "src/assets/locales/da/comments.json";

import warehouses_no from "src/assets/locales/no/warehouses.json";
import common_no from "src/assets/locales/no/common.json";
import log_templates_no from "src/assets/locales/no/log_templates.json";
import lookups_no from "src/assets/locales/no/lookups.json";
import customers_no from "src/assets/locales/no/customers.json";
import sellers_no from "src/assets/locales/no/sellers.json";
import orders_no from "src/assets/locales/no/orders.json";
import lineItems_no from "src/assets/locales/no/lineItems.json";
import files_no from "src/assets/locales/no/files.json";
import analytics_no from "src/assets/locales/no/analytics.json";
import comments_no from "src/assets/locales/no/comments.json";

export const en_bundle = {
  warehouses: warehouses_en,
  common: common_en,
  log_templates: log_templates_en,
  lookups: lookups_en,
  customers: customers_en,
  sellers: sellers_en,
  orders: orders_en,
  lineItems: lineItems_en,
  analytics: analytics_en,
  files: files_en,
  comments: comments_en,
};

export const da_bundle = {
  warehouses: warehouses_da,
  common: common_da,
  log_templates: log_templates_da,
  lookups: lookups_da,
  customers: customers_da,
  sellers: sellers_da,
  orders: orders_da,
  lineItems: lineItems_da,
  analytics: analytics_da,
  files: files_da,
  comments: comments_da,
};
export const no_bundle = {
  warehouses: warehouses_no,
  common: common_no,
  log_templates: log_templates_no,
  lookups: lookups_no,
  customers: customers_no,
  sellers: sellers_no,
  orders: orders_no,
  lineItems: lineItems_no,
  analytics: analytics_no,
  files: files_no,
  comments: comments_no,
};
