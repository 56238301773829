import React, { FunctionComponent } from "react";
import { useCallback } from "react";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import PaginationWrapper from "src/components/PaginationWrapper";

const Pagination: FunctionComponent = React.memo(() => {
  const {
    relatedOrders: { totalCount, currentPage, pageSize, pageCount },
    callAPI,
  } = useCustomerDetailsContext();
  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      event?.preventDefault();
      callAPI.setContextOrdersData("currentPage", page + 1);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    callAPI.setContextOrdersData("pageSize", +event.target.value);
    callAPI.setContextOrdersData("currentPage", 1);
  }, []);

  const handleSpecificPageChange = useCallback((value: number) => {
    callAPI.setContextData("currentPage", value);
  }, []);

  return (
    <PaginationWrapper
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={totalCount}
      rowsPerPage={pageSize}
      page={currentPage - 1}
      currentpage={currentPage}
      pagecount={pageCount}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onSpecificPageChange={handleSpecificPageChange}
    />
  );
});

export default React.memo(Pagination);
