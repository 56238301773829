import React from "react";
import MonteraCard from "src/components/MonteraCard";
import RelatedServicesIcon from "src/pages/ServiceDetails/components/icons/RelatedServicesIcon";
import AssignedWorkersIcon from "src/pages/ServiceDetails/components/icons/AssignedWorkersIcon";
import RelatedServices from "src/pages/ServiceDetails/components/RelatedServices";
import AssignedWorkers from "src/pages/ServiceDetails/components/AssignedWorkers";
import Description from "src/pages/ServiceDetails/components/Description";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import EditDescription from "./EditServiceSummary/EditDescription";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";

const Summary: React.FC = () => {
  const { t } = useTranslation("orders");
  const {
    relatedServicesState: { relatedServices },
  } = useServiceDetailsContext();

  const relatedServicesCount = relatedServices.length > 0 ? ` (${relatedServices.length})` : "";

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <MonteraCard
            headerIcon={<RelatedServicesIcon />}
            title={`${t("labels.summary_cards.related_services")}${relatedServicesCount}`}
          >
            <RelatedServices />
          </MonteraCard>
        </Grid>
        <Grid item>
          <MonteraCard
            headerIcon={<AssignedWorkersIcon />}
            title={t("labels.summary_cards.assigned_workers")}
          >
            <AssignedWorkers />
          </MonteraCard>
        </Grid>
        <Grid item>
          <Description />
        </Grid>
      </Grid>
      <EditDescription />
    </>
  );
};

export default React.memo(Summary);
