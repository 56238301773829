import React, { PropsWithChildren, useState } from "react";
import { requestGet } from "src/utils/crud";
import { Context, WarehousesState, Props, LookupResponse } from "./types";
import createCtx from "../../utils/createCtx";
import { useEffect } from "react";
import { FetchProps } from "../../types";

const [useCtx, Provider] = createCtx<Context>();

const initialState: WarehousesState = {
  warehouses: [],
  warehousesLookup: [],
  currentPage: 1,
  pageCount: 10,
  pageSize: 10,
  totalCount: 0,
  success: false,
  loading: false,
};

const defaultFetchProps: FetchProps = { cachingPolicy: "network" };

function WarehouseProvider({
  lazy = false,
  children,
}: PropsWithChildren<Props>): React.ReactElement {
  const [warehouseStore, setWarehouseStore] = useState(initialState);
  const { currentPage, pageSize } = warehouseStore;

  useEffect(() => {
    if (!lazy) {
      callAPI.fetchWarehouses();
    }
  }, [currentPage, pageSize]);

  const fetchWarehouses = ({ cachingPolicy } = defaultFetchProps, pageSize?: number) => {
    if (cachingPolicy === "cache" && warehouseStore.success) {
      return;
    }
    setWarehouseLoading(true);
    requestGet("Warehouses", {
      page: warehouseStore.currentPage,
      pageSize: pageSize || warehouseStore.pageSize,
      sortBy: "name",
    })
      .then((res) => {
        setWarehouseStore((prev) => ({
          ...prev,
          ...res.data,
          currentPage: prev.currentPage, //returned currentPage wrong number
          pageSize: prev.pageSize, //returned currentPage wrong number
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };
  const fetchWarehousesLookup = () => {
    if (!!warehouseStore.warehousesLookup.length) return;
    setWarehouseLoading(true);
    requestGet<LookupResponse>("Warehouses/lookups")
      .then((res) => {
        setWarehouseStore((prev) => ({
          ...prev,
          warehousesLookup: res.data.warehouses,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };
  const setWarehouseLoading = (data: boolean) =>
    setWarehouseStore((prev: WarehousesState) => ({
      ...prev,
      loading: data,
    }));

  const setContextData = (key: string, data: any) => {
    setWarehouseStore((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };
  const callAPI = {
    fetchWarehouses,
    fetchWarehousesLookup,
    setWarehouseLoading,
    setContextData,
  };

  return (
    <Provider
      value={{
        warehousesData: warehouseStore,
        callAPI,
      }}
    >
      {children}
    </Provider>
  );
}

export { useCtx as useWarehouseContext, WarehouseProvider };
