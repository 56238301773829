import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
// import { useSOContext } from "src/providers/ServiceOrdersStore";
import { SuccessDialog } from "src/components/Dialog";
import { SODetailsType } from "src/pages/ServiceOrdersDetails/type";
import { useAppContext } from "src/providers/AppProvider";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { useSODetailsContext } from "src/providers/SODetailsStore";

interface Props {
  open: boolean;
  redirectToServicesTab?: boolean;
}

const SuccessNotification: React.FC<Props> = ({ open, redirectToServicesTab }: Props) => {
  const history = useHistory();
  const { serviceOrderId } = useParams<SODetailsType>();
  const {
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();
  const { t } = useTranslation("orders");
  const { callAppAPI } = useAppContext();
  const { callSOAPI } = useSOContext();
  const statusIncomplete = serviceOrder.statusMatchingName === "INCOMPLETE";
  const onClose = () => {
    callAppAPI.toggleModal({ id: "createServiceBulkModal", status: false });
    redirectToServicesTab && history.push(`/serviceOrders/details/${serviceOrderId}/services`);
    callSOAPI.setContextData("responseObject", null);
  };

  return (
    <SuccessDialog
      open={open}
      title={t("create_services_in_bulk_form.success_dialog.title")}
      subtitle={statusIncomplete ? t("create_services_in_bulk_form.success_dialog.subtitle") : ""}
      onAccept={onClose}
    />
  );
};

export default SuccessNotification;
