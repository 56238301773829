import React, { ChangeEvent, memo } from "react";
import clsx from "clsx";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import { useStyles } from "src/containers/FileUploadDislog/components/FileInfoItem/styles";
import FileTypeIcon from "src/components/FileIcon";
import StringifiedFileSize from "src/containers/FileUploadDislog/components/StringifiedFileSize";
import { useEllipsisTracking } from "src/containers/FileUploadDislog/hooks";
import { useTranslation } from "react-i18next";
import FileInfoStatus from "src/containers/FileUploadDislog/components/FileInfoStatus";
import PureSelectInput from "src/components/PureSelectInput";

import { FILES_UPLOADING_STATES, UploadFile } from "src/providers/FilesStore/types";
import { LookupObject } from "src/types";
import { FILE_STATUSES } from "src/containers/FileUploadDislog/components/FileInfoStatus/constants";
import { PropsOf } from "src/utils/propsOf";
import { RemoveIcon } from "src/containers/FileUploadDislog/components/FileInfoItem/icons";

interface Props {
  fileItem: UploadFile;
  columnClassNames?: {
    name?: string;
    location?: string;
    category?: string;
    description?: string;
  };
  categories: LookupObject[];
  locations: LookupObject[];
  onChange: (item: UploadFile, change: Partial<PropsOf<UploadFile>>) => void;
  onDelete: (item: UploadFile) => void;
  uploadingState: number;
}

const DEFAULT_PROPS = {
  columnClassNames: {},
};

const FileInfoItem = memo(
  ({
    fileItem,
    columnClassNames = DEFAULT_PROPS.columnClassNames,
    categories,
    locations,
    onChange,
    onDelete,
    uploadingState,
  }: Props) => {
    const classes = useStyles();

    const { t } = useTranslation("files");

    const split = fileItem.file.name.split(".");
    const name = [...split].slice(0, -1).join(".");
    let extension = split[split.length - 1];
    if (extension) {
      extension = `.${extension}`;
    }

    const [nameTextRef, hasEllipsis, handleEnter] = useEllipsisTracking();

    const onRemoveClick = () => {
      onDelete(fileItem);
    };

    const onLocationChange = (location: number) => {
      onChange(fileItem, { location });
    };

    const onCategoryChange = (category: number) => {
      onChange(fileItem, { category });
    };

    const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const description = e.target.value;
      onChange(fileItem, { description });
    };

    const disabled = fileItem.status !== FILE_STATUSES.PENDING;
    const hasErrors = !!fileItem.errors.length;

    return (
      <TableRow>
        <TableCell className={clsx(classes.cell, columnClassNames.name)}>
          <div className={classes.cellContent}>
            <div className={classes.fileIcon}>
              <FileTypeIcon type={fileItem.file.type} />
            </div>
            <Tooltip title={hasEllipsis ? fileItem.file.name : ""}>
              <div className={classes.fileNameWrap} onMouseEnter={handleEnter}>
                <Typography ref={nameTextRef} className={classes.fileName}>
                  {name}
                </Typography>
                <Typography>{extension}</Typography>
              </div>
            </Tooltip>
          </div>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.sizeText)}>
          <StringifiedFileSize size={fileItem.file.size} />
        </TableCell>
        <TableCell className={clsx(classes.cell, columnClassNames.location)}>
          <PureSelectInput
            required={true}
            fullWidth={true}
            name="location"
            label={t("table.body.location")}
            data={locations}
            allowEmpty={false}
            value={fileItem.location}
            onSelect={onLocationChange}
            disabled={disabled || hasErrors}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, columnClassNames.category)}>
          <PureSelectInput
            required={true}
            fullWidth={true}
            name="category"
            label={t("table.body.category")}
            data={categories}
            allowEmpty={false}
            value={fileItem.category}
            onSelect={onCategoryChange}
            disabled={disabled || hasErrors}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, columnClassNames.description)}>
          <TextField
            fullWidth
            label={t("table.body.description")}
            multiline={true}
            value={fileItem.description}
            onChange={onDescriptionChange}
            variant="outlined"
            inputProps={{ maxLength: 300 }}
            disabled={disabled || hasErrors}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell)}>
          <div className={classes.cellContent}>
            <FileInfoStatus fileItem={fileItem} />
          </div>
        </TableCell>

        <TableCell className={classes.cell}>
          {uploadingState !== FILES_UPLOADING_STATES.DONE && (
            <IconButton
              className={classes.deleteButton}
              onClick={onRemoveClick}
              disabled={disabled}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }
);

export default FileInfoItem;
