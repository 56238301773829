import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SOIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 0.00012207C0.447715 0.00012207 0 0.447837 0 1.00012V7.00012H7V0.00012207H1Z"
      fill="#65AFFF"
    />
    <path
      d="M9 0.00012207V7.00012H16V1.00012C16 0.447837 15.5523 0.00012207 15 0.00012207H9Z"
      fill="#65AFFF"
    />
    <path
      d="M16 9.00012H9V16.0001H15C15.5523 16.0001 16 15.5524 16 15.0001V9.00012Z"
      fill="#65AFFF"
    />
    <path d="M7 16.0001V9.00012H0V15.0001C0 15.5524 0.447715 16.0001 1 16.0001H7Z" fill="#65AFFF" />
  </SvgIcon>
);
export default SOIcon;
