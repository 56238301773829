import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const AnalyticsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M11.6 1.59998H13.2C14.0837 1.59998 14.8 2.31632 14.8 3.19998V14.4C14.8 15.2836 14.0837 16 13.2 16H3.6C2.71634 16 2 15.2836 2 14.4V3.19998C2 2.31632 2.71634 1.59998 3.6 1.59998H5.2" fill="white" fillOpacity="0.5"/>
    <path d="M10.8001 0H6.00007C5.55825 0 5.20007 0.358172 5.20007 0.8V2.4C5.20007 2.84183 5.55825 3.2 6.00007 3.2H10.8001C11.2419 3.2 11.6001 2.84183 11.6001 2.4V0.8C11.6001 0.358172 11.2419 0 10.8001 0Z" fill="white"/>
  </SvgIcon>
);

export default AnalyticsIcon;





