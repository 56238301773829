import { makeStyles } from "@material-ui/core/styles";
import { theme } from "src/utils/styleTheme/mainStyles";

export const useStyles = makeStyles(() => {
  return {
    submitBtn: {
      padding: theme.spacing(2),
      fontSize: theme.spacing(2),
      color: theme.palette.secondary.main,
      minWidth: 80,
      backgroundColor: theme.palette.functionalColors.dark,
      opacity: 1,
      "&:hover": {
        backgroundColor: theme.palette.brandColors.dark,
      },
      "&:disabled": {
        opacity: 0.5,
      },
    },
    cancelBtn: {
      marginLeft: theme.spacing(2),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      fontSize: 14,
      color: theme.palette.primary.main,
    },
  };
});
