import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ClipboardIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6 2.59998H13.2C14.0837 2.59998 14.8 3.31632 14.8 4.19998V15.4C14.8 16.2836 14.0837 17 13.2 17H3.6C2.71634 17 2 16.2836 2 15.4V4.19998C2 3.31632 2.71634 2.59998 3.6 2.59998H5.2"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.8002 1H6.0002C5.55837 1 5.2002 1.35817 5.2002 1.8V3.4C5.2002 3.84183 5.55837 4.2 6.0002 4.2H10.8002C11.242 4.2 11.6002 3.84183 11.6002 3.4V1.8C11.6002 1.35817 11.242 1 10.8002 1Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default ClipboardIcon;
