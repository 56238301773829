import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SellerIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 6H1C1 6 1.00022 6.49249 1.00022 7.1V15.9C1.00022 16.5075 1.78371 17 2.75019 17H13.25C14.2165 17 15 16.5075 15 15.9V7.1V6Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12 9V5C12 2.79086 10.2091 1 8 1V1C5.79086 1 4 2.79086 4 5L4 9"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default SellerIcon;
