import React, { PropsWithChildren, useEffect } from "react";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "./TablePaginationActions";
import { useTable, useExpanded, useSortBy, usePagination, useRowSelect } from "react-table";
import SortedIco from "src/assets/icons/SortedIcon";
import { useStyles } from "./style";
import { Column } from "react-table";

interface Props<T extends object = {}> {
  data?: T[];
  currentPage?: number;
  pageSize?: number;
  totalCount?: number;
  textAlign?: "left" | "right" | "center";
  columnsData?: () => Array<Column<T>>;
  rowActions?: (data: T) => JSX.Element;
  handleChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortBy?: string;
  des?: boolean;
  setSortingData?: (sortBy: string, des: boolean) => void;
}

function MainTable<D extends object = {}>({
  data = [],
  currentPage = 1,
  pageSize = 10,
  totalCount = 0,
  columnsData = () => [],
  rowActions,
  handleChangePage = () => [],
  handleChangeRowsPerPage = () => [],
  textAlign = "right",
  sortBy = "name",
  des = true,
  setSortingData,
}: PropsWithChildren<Props<D>>) {
  const { tableWrapper, tableCell } = useStyles();
  const columns = React.useMemo(() => columnsData(), []);
  const { getTableProps, headerGroups, prepareRow, page, state } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState: {
        sortBy: [
          {
            id: sortBy,
            desc: des,
          },
        ],
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (setSortingData && state.sortBy[0]?.id) {
      setSortingData(state.sortBy[0]?.id, !!state.sortBy[0]?.desc);
    }
  }, [state.sortBy]);

  return (
    <MaUTable {...getTableProps()} className={tableWrapper}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              return (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div
                    style={
                      !!column.style
                        ? { ...column.style }
                        : { display: "flex", alignItems: "center" }
                    }
                  >
                    <span>{column.render("Header")}</span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortedIco fontSize="small" />
                      ) : (
                        <SortedIco fontSize="small" style={{ transform: "rotate(180deg)" }} />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </TableCell>
              );
            })}
            {rowActions && <TableCell key={Math.random()}></TableCell>}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {page.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <TableCell
                    align={index === 0 ? "left" : textAlign}
                    className={tableCell}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
              {rowActions && (
                <TableCell align={"center"} key={Math.random()} style={{ padding: 1 }}>
                  {rowActions(row.original)}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
      {Math.ceil(totalCount / pageSize) > 1 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, { label: "All", value: totalCount }]}
              count={totalCount}
              rowsPerPage={pageSize}
              page={currentPage - 1}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      )}
    </MaUTable>
  );
}

export default React.memo(MainTable);
