import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const PlusIcon: React.FC = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#2B59C3"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 2V14" stroke="#2B59C3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 8H14" stroke="#2B59C3" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default PlusIcon;
