import React, { FunctionComponent, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useAppContext } from "src/providers/AppProvider";
import ServicesSortDropdown from "../ServicesSortDropdown/";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

const PageActions: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    state: { user },
    callAppAPI,
  } = useAppContext();
  const {
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const handleModal = useCallback(() => {
    callAppAPI.toggleModal({
      id: "createServiceModal",
      status: true,
    });
  }, []);

  return (
    <Grid container className={classes.wrapper} direction="row">
      <ServicesSortDropdown />
      {disabledByStatus && !user?.partner?.isIntegration && (
        <>
          <Divider orientation="vertical" flexItem />
          <Button startIcon={<AddIcon />} className={classes.text} onClick={handleModal}>
            {t("labels.btn.add_service")}
          </Button>
        </>
      )}
    </Grid>
  );
};

export default PageActions;
