import { matchPath, generatePath } from "react-router-dom";
import { CustomBreadcrumbMap, CustomBreadcrumbSetting } from "./types";

const customBreadcrumbs: CustomBreadcrumbSetting[] = [
  {
    path: "/serviceOrders/details/:id/:tab",
    items: [
      {
        redirect: "orders",
        text: "orders",
      },
      {
        redirect: "serviceOrders/details/:id/summary",
        text: "so_details",
      },
    ],
  },
  // 5
  {
    path: "/line_items/:serviceId/:lineItemId",
    items: [
      {
        redirect: "orders",
        text: "orders",
      },
      {
        redirect: "serviceOrders/details/:serviceId/summary",
        text: "so_details",
      },
      {
        redirect: "line_items/:serviceId/:lineItemId",
        text: "line_items",
      },
    ],
  },
  // 6
  {
    path: "/services/details/:orderId/:serviceId/:tab",
    items: [
      {
        redirect: "orders",
        text: "orders",
      },
      {
        redirect: "serviceOrders/details/:orderId/summary",
        text: "so_details",
      },
      {
        redirect: "services/details/:orderId/:serviceId/:tab",
        text: "service_details",
      },
    ],
  },
  // 10
  {
    path: "/serviceOrders/creation",
    items: [
      {
        redirect: "orders",
        text: "orders",
      },
      {
        redirect: "serviceOrders/creation",
        text: "new_service_order",
      },
    ],
  },
  // 11
  {
    path: "/warehouses/details/:id/:tab",
    items: [
      {
        redirect: "warehouses",
        text: "warehouses",
      },
      {
        redirect: "warehouses/details/:id/:tab",
        text: "warehouse_details",
      },
    ],
  },
  // 13
  {
    path: "/customers/details/:id/:tab",
    items: [
      {
        redirect: "customers",
        text: "customers",
      },
      {
        redirect: "customers/details/:id/:tab",
        text: "customer_details",
      },
    ],
  },
  // 14
  {
    path: "/analytics/partnerManager",
    items: [
      {
        redirect: "analytics",
        text: "analytics",
      },
      {
        redirect: "analytics/partnerManager",
        text: "partner_manager",
      },
    ],
  },
];

export const getCustomBreadcrumb = (pathname: string) => {
  const matches = customBreadcrumbs.reduce<Record<string, CustomBreadcrumbMap>>((acc, item) => {
    const match = matchPath(pathname, { path: item.path });

    if (match) {
      return {
        ...acc,
        [item.path]: {
          match,
          breadcrumb: item,
        },
      };
    }

    return acc;
  }, {});

  const matchesFound = Object.keys(matches).length;

  if (matchesFound === 0) return false;
  if (matchesFound > 1) {
    return console.error(`found duplicate breadcrumbs matches ${matches} for current path`);
  }

  const { match, breadcrumb } = Object.values(matches)[0];

  return breadcrumb.items.map((item) => {
    if (item.redirect) {
      return {
        ...item,
        redirect: generatePath(item.redirect, match.params),
      };
    }

    return item;
  });
};
