import { makeStyles } from "@material-ui/core/styles";

export const DRAWER_WIDTH = 400;
export const useStyles = makeStyles((theme) => ({
  paper: {
    width: DRAWER_WIDTH,
    top: 205,
    height: "calc(100vh - 290px)",
    marginRight: 40,
    overflowY: "scroll",
    paddingBottom: 70,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  heading: {
    fontWeight: 500,
    padding: 16,
  },
  fieldsScroll: {
    overflow: "auto",
    flex: 1,
    height: "100%",
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "max-content",
    gap: `${theme.spacing(1)}px`,
    borderLeft: "2px solid #65AFFF",
    paddingLeft: 10,
  },
  actions: {
    padding: 16,
    display: "flex",
    width: DRAWER_WIDTH,
    background: "white",
    borderTop: "1px solid #f3f3f3",
    "& > button": {
      padding: "16px 24px",
      marginRight: 8,
    },
  },
  filtersStyles: {
    padding: 16,
    overflow: "auto",
  },
  formWrapper: {
    borderLeft: "2px solid #65AFFF",
    padding: 10,
    borderRadius: 0,
    marginTop: 10,
  },
}));
