import React from "react";
export function getFileTypeIcon(type: string, width = 15, height = 16, pointerEvents: any) {
  switch (type) {
    case "doc":
    case "application/msword":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents={pointerEvents}
        >
          <path
            d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
            fill="#E2E5E7"
          />
          <path
            d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
            fill="#B0B7BD"
          />
          <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
          <path
            d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
            fill="#65AFFF"
          />
          <path
            d="M2.07488 12C1.94414 12 1.80151 11.9275 1.80151 11.752V9.48152C1.80151 9.33752 1.94414 9.23352 2.07488 9.23352H2.98166C4.79124 9.23352 4.75113 12 3.01682 12H2.07488ZM2.32448 9.72102V11.512H2.98166C4.05087 11.512 4.09791 9.72102 2.98166 9.72102H2.32448Z"
            fill="white"
          />
          <path
            d="M6.26645 12.04C5.534 12.072 4.77332 11.58 4.77332 10.6005C4.77332 9.61704 5.5335 9.13354 6.26645 9.13354C6.95928 9.16904 7.68381 9.65304 7.68381 10.6005C7.68381 11.5485 6.95928 12.04 6.26645 12.04ZM6.22287 9.65354C5.77914 9.65354 5.29628 9.96954 5.29628 10.601C5.29628 11.229 5.77963 11.549 6.22287 11.549C6.67848 11.549 7.1653 11.229 7.1653 10.601C7.1653 9.96904 6.67848 9.65354 6.22287 9.65354Z"
            fill="white"
          />
          <path
            d="M8.1499 10.5966C8.1499 9.82512 8.62929 9.16162 9.54003 9.16162C9.88471 9.16162 10.1581 9.26562 10.4468 9.51762C10.5538 9.61712 10.5657 9.79312 10.4587 9.90512C10.3517 10.0006 10.1893 9.98912 10.0942 9.89312C9.93225 9.72112 9.76585 9.67362 9.54003 9.67362C8.93039 9.67362 8.63771 10.1096 8.63771 10.5971C8.63771 11.0931 8.92643 11.5486 9.54003 11.5486C9.76585 11.5486 9.97583 11.4561 10.1581 11.2926C10.2804 11.1966 10.4508 11.2441 10.5221 11.3406C10.5855 11.4286 10.6167 11.5766 10.4785 11.7161C10.2012 11.9766 9.86886 12.0286 9.53953 12.0286C8.58175 12.0286 8.1499 11.3681 8.1499 10.5966Z"
            fill="white"
          />
          <path
            d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
            fill="#CAD1D8"
          />
        </svg>
      );
    case "xls":
    case "xlsx":
    case "application/vnd.ms-excel":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents={pointerEvents}
        >
          <path
            d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
            fill="#E2E5E7"
          />
          <path
            d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
            fill="#B0B7BD"
          />
          <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
          <path
            d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
            fill="#84BD5A"
          />
          <path
            d="M3.67698 10.1935L4.36585 9.322C4.57186 9.05 4.97201 9.3975 4.74619 9.6575C4.50897 9.9415 4.25937 10.2495 4.02166 10.565L4.82939 11.5805C5.04729 11.8805 4.61198 12.168 4.39804 11.872L3.66905 10.9285L2.95245 11.8925C2.75039 12.184 2.3027 11.8565 2.52853 11.5845L3.32437 10.565C3.07477 10.249 2.8331 9.9415 2.59192 9.6575C2.34282 9.3575 2.80586 9.05 2.98811 9.3305L3.67698 10.1935Z"
            fill="white"
          />
          <path
            d="M5.31812 9.47349C5.31812 9.34149 5.42905 9.22949 5.56771 9.22949C5.69449 9.22949 5.80146 9.34199 5.80146 9.47349V11.4765H6.8786C7.26637 11.4765 7.27479 12 6.8786 12H5.56821C5.42954 12 5.31861 11.9005 5.31861 11.7565V9.47349H5.31812Z"
            fill="white"
          />
          <path
            d="M7.62923 9.83347C7.72035 9.05747 8.88019 8.91797 9.42693 9.34197C9.69634 9.56147 9.41108 9.90947 9.17337 9.73347C8.88019 9.54597 8.21509 9.45797 8.13189 9.87297C8.02492 10.529 9.74735 10.1535 9.71961 11.217C9.69188 12.2325 8.2349 12.256 7.68816 11.8005C7.55693 11.6925 7.56138 11.517 7.6327 11.4085C7.73571 11.305 7.85011 11.269 7.9853 11.381C8.30968 11.6045 9.14564 11.7725 9.20457 11.2045C9.15356 10.6135 7.50641 10.969 7.62923 9.83347Z"
            fill="white"
          />
          <path
            d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
            fill="#CAD1D8"
          />
        </svg>
      );
    case "txt":
    case "text/plain":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents={pointerEvents}
        >
          <path
            d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
            fill="#E2E5E7"
          />
          <path
            d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
            fill="#B0B7BD"
          />
          <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
          <path
            d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
            fill="#576D7E"
          />
          <path
            d="M3.31951 9.73367H2.62668C2.28199 9.73367 2.28199 9.22217 2.62668 9.22217H4.49965C4.85226 9.22217 4.85226 9.73367 4.49965 9.73367H3.84198V11.7522C3.84198 12.0997 3.31901 12.1082 3.31901 11.7522V9.73367H3.31951Z"
            fill="white"
          />
          <path
            d="M6.15584 10.1931L6.8452 9.32156C7.05122 9.05006 7.45087 9.39756 7.22554 9.65756C6.98783 9.94156 6.73873 10.2496 6.50052 10.5646L7.30874 11.5801C7.52615 11.8801 7.09084 12.1681 6.8769 11.8721L6.14841 10.9286L5.4318 11.8921C5.22975 12.1836 4.78206 11.8561 5.00838 11.5841L5.80372 10.5646C5.55462 10.2491 5.31295 9.94156 5.07127 9.65756C4.82168 9.35756 5.28521 9.05006 5.46697 9.33006L6.15584 10.1931Z"
            fill="white"
          />
          <path
            d="M8.4423 9.73355H7.74997C7.40528 9.73355 7.40528 9.22205 7.74997 9.22205H9.62245C9.97505 9.22205 9.97505 9.73355 9.62245 9.73355H8.96527V11.752C8.96527 12.0995 8.4423 12.108 8.4423 11.752V9.73355Z"
            fill="white"
          />
          <path
            d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
            fill="#CAD1D8"
          />
        </svg>
      );
    case "pdf":
    case "application/pdf":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents={pointerEvents}
        >
          <path
            d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
            fill="#E2E5E7"
          />
          <path
            d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
            fill="#B0B7BD"
          />
          <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
          <path
            d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
            fill="#FB3640"
          />
          <path
            d="M2.35852 9.47351C2.35852 9.34151 2.46153 9.19751 2.62743 9.19751H3.54213C4.05717 9.19751 4.52071 9.54551 4.52071 10.2125C4.52071 10.8445 4.05717 11.1965 3.54213 11.1965H2.88099V11.7245C2.88099 11.9005 2.77006 12 2.62743 12C2.49669 12 2.35852 11.9005 2.35852 11.7245V9.47351ZM2.88099 9.70101V10.697H3.54213C3.80758 10.697 4.01755 10.4605 4.01755 10.2125C4.01755 9.93301 3.80758 9.70101 3.54213 9.70101H2.88099Z"
            fill="white"
          />
          <path
            d="M5.29644 12C5.1657 12 5.02307 11.928 5.02307 11.7525V9.48152C5.02307 9.33802 5.1657 9.23352 5.29644 9.23352H6.20321C8.0128 9.23352 7.97318 12 6.23887 12H5.29644ZM5.54604 9.72152V11.5125H6.20321C7.27242 11.5125 7.31997 9.72152 6.20321 9.72152H5.54604Z"
            fill="white"
          />
          <path
            d="M8.61494 9.75341V10.3889H9.62472C9.76735 10.3889 9.90998 10.5329 9.90998 10.6724C9.90998 10.8044 9.76735 10.9124 9.62472 10.9124H8.61494V11.7519C8.61494 11.8919 8.51639 11.9994 8.37772 11.9994C8.2034 11.9994 8.09692 11.8919 8.09692 11.7519V9.48091C8.09692 9.33741 8.2039 9.23291 8.37772 9.23291H9.76784C9.94217 9.23291 10.0452 9.33741 10.0452 9.48091C10.0452 9.60891 9.94217 9.75291 9.76784 9.75291H8.61494V9.75341Z"
            fill="white"
          />
          <path
            d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
            fill="#CAD1D8"
          />
        </svg>
      );
    case "ppt":
    case "application/vnd.ms-powerpoint":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents={pointerEvents}
        >
          <path
            d="M3.17178 0C2.62693 0 2.18115 0.45 2.18115 1V15C2.18115 15.55 2.62693 16 3.17178 16H13.078C13.6229 16 14.0687 15.55 14.0687 15V4L10.1062 0H3.17178Z"
            fill="#E2E5E7"
          />
          <path
            d="M11.0968 4H14.0687L10.1062 0V3C10.1062 3.55 10.552 4 11.0968 4Z"
            fill="#B0B7BD"
          />
          <path d="M14.0688 7L11.0938 4H14.0688V7Z" fill="#CAD1D8" />
          <path
            d="M12.0875 13C12.0875 13.275 11.8646 13.5 11.5921 13.5H0.695264C0.422842 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422842 7.5 0.695264 7.5H11.5921C11.8646 7.5 12.0875 7.725 12.0875 8V13Z"
            fill="#F15642"
          />
          <path
            d="M2.47498 9.47351C2.47498 9.34151 2.57798 9.19751 2.74389 9.19751H3.65859C4.17363 9.19751 4.63717 9.54551 4.63717 10.2125C4.63717 10.8445 4.17363 11.1965 3.65859 11.1965H2.99745V11.7245C2.99745 11.9005 2.88701 12 2.74438 12C2.61364 12 2.47547 11.9005 2.47547 11.7245V9.47351H2.47498ZM2.99745 9.70101V10.697H3.65859C3.92403 10.697 4.13401 10.4605 4.13401 10.2125C4.13401 9.93301 3.92403 9.70101 3.65859 9.70101H2.99745Z"
            fill="white"
          />
          <path
            d="M5.13745 9.47351C5.13745 9.34151 5.24046 9.19751 5.40686 9.19751H6.32156C6.8366 9.19751 7.30014 9.54551 7.30014 10.2125C7.30014 10.8445 6.8366 11.1965 6.32156 11.1965H5.66042V11.7245C5.66042 11.9005 5.54949 12 5.40686 12C5.27612 12 5.13745 11.9005 5.13745 11.7245V9.47351ZM5.65992 9.70101V10.697H6.32106C6.58651 10.697 6.79649 10.4605 6.79649 10.2125C6.79649 9.93301 6.58651 9.70101 6.32106 9.70101H5.65992Z"
            fill="white"
          />
          <path
            d="M8.54641 9.73355H7.85275C7.50742 9.73355 7.50742 9.22205 7.85275 9.22205H9.72879C10.0821 9.22205 10.0821 9.73355 9.72879 9.73355H9.07037V11.7525C9.07037 12.1 8.54641 12.1085 8.54641 11.7525V9.73355Z"
            fill="white"
          />
          <path
            d="M11.5921 13.5H2.18115V14H11.5921C11.8645 14 12.0874 13.775 12.0874 13.5V13C12.0874 13.275 11.8645 13.5 11.5921 13.5Z"
            fill="#CAD1D8"
          />
        </svg>
      );
    case "jpg":
    case "jpeg":
    case "image/jpeg":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents={pointerEvents}
        >
          <path
            d="M4.17162 0C3.62686 0 3.18115 0.45 3.18115 1V15C3.18115 15.55 3.62686 16 4.17162 16H14.0763C14.6211 16 15.0668 15.55 15.0668 15V4L11.1049 0H4.17162Z"
            fill="#E2E5E7"
          />
          <path
            d="M12.0952 4H15.0666L11.1047 0V3C11.1047 3.55 11.5504 4 12.0952 4Z"
            fill="#B0B7BD"
          />
          <path d="M15.0666 7L12.0952 4H15.0666V7Z" fill="#CAD1D8" />
          <path
            d="M13.0857 13C13.0857 13.275 12.8628 13.5 12.5905 13.5H1.69531C1.42293 13.5 1.20007 13.275 1.20007 13V8C1.20007 7.725 1.42293 7.5 1.69531 7.5H12.5905C12.8628 7.5 13.0857 7.725 13.0857 8V13Z"
            fill="#50BEE8"
          />
          <path
            d="M4.6038 9.47344C4.6038 9.13744 5.12676 9.13744 5.12676 9.47344V11.0524C5.12676 11.6804 4.82962 12.0604 4.14471 12.0604C3.80796 12.0604 3.52716 11.9679 3.28152 11.6484C3.07947 11.4044 3.45981 11.0529 3.66582 11.3084C3.83222 11.5164 4.01051 11.5644 4.18433 11.5564C4.4057 11.5484 4.60033 11.4479 4.60429 11.0524V9.47344H4.6038Z"
            fill="white"
          />
          <path
            d="M5.82263 9.47339C5.82263 9.34139 5.92564 9.19739 6.09204 9.19739H7.00674C7.52178 9.19739 7.98532 9.54539 7.98532 10.2124C7.98532 10.8444 7.52178 11.1964 7.00674 11.1964H6.3456V11.7244C6.3456 11.9004 6.23467 11.9999 6.09204 11.9999C5.9613 11.9999 5.82263 11.9004 5.82263 11.7244V9.47339ZM6.3456 9.70088V10.6969H7.00674C7.27218 10.6969 7.48216 10.4604 7.48216 10.2124C7.48216 9.93288 7.27218 9.70088 7.00674 9.70088H6.3456Z"
            fill="white"
          />
          <path
            d="M10.8129 11.6925C10.5316 11.9285 10.187 12.0285 9.83881 12.0285C9.00682 12.0285 8.41699 11.549 8.41699 10.597C8.41699 9.78998 9.03901 9.16248 9.87397 9.16248C10.187 9.16248 10.5311 9.26998 10.7768 9.51398C11.018 9.75348 10.6698 10.1095 10.4326 9.89798C10.286 9.75398 10.08 9.64598 9.87397 9.64598C9.39508 9.64598 8.93204 10.0335 8.93204 10.597C8.93204 11.189 9.3198 11.549 9.83881 11.549C10.08 11.549 10.286 11.4765 10.4326 11.369V10.8775H9.83881C9.4862 10.8775 9.52186 10.3895 9.83881 10.3895H10.6307C10.7773 10.3895 10.9115 10.5015 10.9115 10.6255V11.477C10.9115 11.549 10.8803 11.616 10.8129 11.6925Z"
            fill="white"
          />
          <path
            d="M12.5906 13.5H3.18115V14H12.5906C12.863 14 13.0858 13.775 13.0858 13.5V13C13.0858 13.275 12.863 13.5 12.5906 13.5Z"
            fill="#CAD1D8"
          />
        </svg>
      );
    case "png":
    case "image/png":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents={pointerEvents}
        >
          <path
            d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
            fill="#E2E5E7"
          />
          <path
            d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
            fill="#B0B7BD"
          />
          <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
          <path
            d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
            fill="#A066AA"
          />
          <path
            d="M2.08228 9.47352C2.08228 9.34152 2.18479 9.19702 2.35119 9.19702H3.26638C3.78093 9.19702 4.24447 9.54502 4.24447 10.2125C4.24447 10.8445 3.78093 11.196 3.26638 11.196H2.60425V11.724C2.60425 11.9005 2.49431 12 2.35119 12C2.22045 12 2.08228 11.9005 2.08228 11.724V9.47352ZM2.60425 9.70102V10.6965H3.26588C3.53084 10.6965 3.74081 10.4605 3.74081 10.2125C3.74081 9.93252 3.53084 9.70102 3.26588 9.70102H2.60425Z"
            fill="white"
          />
          <path
            d="M4.74915 9.51352C4.74915 9.36902 4.78085 9.22952 4.98686 9.22952C5.129 9.22952 5.16119 9.26552 5.26766 9.36902L6.57805 11.0245V9.47352C6.57805 9.34152 6.69245 9.19702 6.82765 9.19702C6.97028 9.19702 7.10845 9.34152 7.10845 9.47352V11.724C7.10845 11.9005 7.00148 11.9675 6.89847 12C6.7603 12 6.69245 11.968 6.57805 11.8605L5.26766 10.181V11.7245C5.26766 11.901 5.16069 12.0005 5.01806 12.0005C4.87544 12.0005 4.74866 11.901 4.74866 11.7245V9.51352H4.74915Z"
            fill="white"
          />
          <path
            d="M10.0871 11.6925C9.80581 11.928 9.46113 12.0285 9.11298 12.0285C8.28099 12.0285 7.69116 11.5485 7.69116 10.597C7.69116 9.78949 8.31318 9.16199 9.14814 9.16199C9.46113 9.16199 9.80532 9.26999 10.051 9.51399C10.2926 9.75349 9.94398 10.1095 9.70677 9.89799C9.56018 9.75349 9.35416 9.64599 9.14814 9.64599C8.66925 9.64599 8.20621 10.0335 8.20621 10.597C8.20621 11.189 8.59397 11.5485 9.11298 11.5485C9.35416 11.5485 9.56018 11.4765 9.70677 11.3685V10.8775H9.11298C8.76037 10.8775 8.79603 10.389 9.11298 10.389H9.90486C10.0514 10.389 10.1857 10.5015 10.1857 10.6255V11.477C10.1857 11.5485 10.1545 11.616 10.0871 11.6925Z"
            fill="white"
          />
          <path
            d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
            fill="#CAD1D8"
          />
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents={pointerEvents}
        >
          <path
            d="M2.48096 1C2.48096 0.721617 2.70731 0.5 2.97143 0.5H9.69619L13.3666 4.20571V15C13.3666 15.2784 13.1402 15.5 12.8761 15.5H2.97143C2.70731 15.5 2.48096 15.2784 2.48096 15V1Z"
            fill="white"
            stroke="#B0B7BD"
          />
          <g opacity="0.5">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.08983 7.5606C7.76852 7.50549 7.4543 7.69016 7.34612 7.99769C7.30946 8.10189 7.19528 8.15664 7.09108 8.11999C6.98689 8.08333 6.93213 7.96915 6.96879 7.86495C7.14169 7.37343 7.6439 7.07827 8.15746 7.16636C8.67097 7.25444 9.04609 7.70002 9.04538 8.22102C9.04529 8.61662 8.75122 8.88589 8.52203 9.03868C8.40133 9.11915 8.28206 9.17864 8.19375 9.21789C8.14931 9.23764 8.11199 9.25258 8.08532 9.26274C8.07197 9.26783 8.06124 9.27174 8.05356 9.27447L8.04437 9.27769L8.04158 9.27864L8.04066 9.27895L8.04017 9.27911C8.04011 9.27913 8.04005 9.27915 7.97799 9.09298L8.04017 9.27911C7.93538 9.31404 7.822 9.25745 7.78707 9.15266C7.75216 9.04794 7.8087 8.93475 7.91336 8.89975L7.97679 9.08936C7.91356 8.89968 7.91341 8.89973 7.91336 8.89975L7.91422 8.89945L7.91956 8.89758C7.9246 8.89579 7.93253 8.89291 7.94293 8.88895C7.96376 8.88101 7.9943 8.86881 8.03129 8.85237C8.10584 8.81924 8.2037 8.77016 8.30015 8.70586C8.50521 8.56916 8.64538 8.4042 8.64538 8.22085C8.64586 7.89484 8.41115 7.61572 8.08983 7.5606Z"
              fill="#141E30"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.00005 10.4476C8.11051 10.4476 8.20005 10.3581 8.20005 10.2476V10.1029C8.20005 9.99241 8.11051 9.90287 8.00005 9.90287C7.88959 9.90287 7.80005 9.99241 7.80005 10.1029V10.2476C7.80005 10.3581 7.88959 10.4476 8.00005 10.4476Z"
              fill="#141E30"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.00002 6.10471C6.51148 6.10471 5.30479 7.31141 5.30479 8.79995C5.30479 10.2885 6.51148 11.4952 8.00002 11.4952C9.48856 11.4952 10.6953 10.2885 10.6953 8.79995C10.6953 7.31141 9.48856 6.10471 8.00002 6.10471ZM4.90479 8.79995C4.90479 7.0905 6.29057 5.70471 8.00002 5.70471C9.70948 5.70471 11.0953 7.0905 11.0953 8.79995C11.0953 10.5094 9.70948 11.8952 8.00002 11.8952C6.29057 11.8952 4.90479 10.5094 4.90479 8.79995Z"
              fill="#141E30"
            />
          </g>
          <path
            d="M10.8953 4H13.8667L9.90479 0V3C9.90479 3.55 10.3505 4 10.8953 4Z"
            fill="#B0B7BD"
          />
          <path d="M13.8664 7L10.895 4H13.8664V7Z" fill="#CAD1D8" />
        </svg>
      );
  }
}
