import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ServiceFieldsList from "./ServiceFieldsList";
import ServiceTable from "./ServiceTable";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import Container from "@material-ui/core/Container/Container";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { LineItemsSORes } from "src/providers/LineItemsStore/types";
import ModalMenu from "../Modals/ModalMenu";
import LineItemsList from "src/pages/ServiceOrdersDetails/components/LineItemsList";

export const useStyles = makeStyles(() => ({
  detailsRoot: {
    paddingBottom: 0,
    marginLeft: 0,
  },
}));

const LiServiceList = () => {
  const classes = useStyles();
  const {
    LIData: { loading, lineItemsSO },
    callLineItemsAPI: { setContextData },
  } = useLineItemsContext();

  const handleExpand = (index: number) => (event: React.ChangeEvent<{}>) => {
    event.persist();
    if (lineItemsSO.expands.indexOf(`${index}`) < 0) {
      setContextData("lineItemsSO", {
        ...lineItemsSO,
        expands: [...lineItemsSO.expands, `${index}`],
      });
    } else {
      setContextData("lineItemsSO", {
        ...lineItemsSO,
        expands: [...lineItemsSO.expands.filter((ent) => ent !== `${index}`)],
      });
    }
  };

  const expandAll = () => {
    let hasLineItems = [];
    for (let i = 0; i < lineItemsSO?.items.length; i++) {
      if (
        lineItemsSO?.items[i].partnerLineItems?.length ||
        lineItemsSO?.items[i].customerLineItems?.length
      ) {
        hasLineItems.push(`${i}`);
      }
    }
    setContextData("lineItemsSO", { ...lineItemsSO, expands: hasLineItems });
  };

  useEffect(() => {
    if (lineItemsSO?.items?.length) {
      expandAll();
    }
  }, [lineItemsSO?.items]);

  if (loading) {
    return <PreloaderLine />;
  }
  return (
    <Container style={{ margin: 0, padding: 0 }} maxWidth={"xl"}>
      <PreloaderLine loading={loading} />
      <LineItemsList<LineItemsSORes>
        listEntities={lineItemsSO?.items}
        entityFields={(ent) => <ServiceFieldsList data={ent} />}
        entityDetails={(ent) => <ServiceTable data={ent} />}
        classes={{ detailsRoot: classes.detailsRoot }}
        expands={lineItemsSO?.expands}
        handleChange={handleExpand}
      />
      <ModalMenu />
    </Container>
  );
};

export default LiServiceList;
