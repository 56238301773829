import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const AddMember = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9091 14.0909V12.6364C11.9091 11.0297 10.6066 9.72729 9 9.72729H3.90909C2.30244 9.72729 1 11.0297 1 12.6364V14.0909"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.4545 6.81818C8.06115 6.81818 9.36359 5.51574 9.36359 3.90909C9.36359 2.30244 8.06115 1 6.4545 1C4.84785 1 3.54541 2.30244 3.54541 3.90909C3.54541 5.51574 4.84785 6.81818 6.4545 6.81818Z"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.8184 4.63623V8.99987"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16.9999 6.81812H12.6362"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default AddMember;
