import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/providers/AppProvider";

export const useCurrencyFormat = () => {
  const {
    state: { locales },
  } = useAppContext();
  const { currency, localeTag } = locales[0];

  const prepareValue = (
    value: number | string | undefined,
    minimumFractionDigits = 1,
    maximumFractionDigits = 2
  ) => {
    if (typeof value !== "number") return "";

    return new Intl.NumberFormat(localeTag, {
      style: "currency",
      currency: currency?.code,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    }).format(value);
  };
  const prepareStyle = (
    value: number | string | undefined,
    minimumFractionDigits = 1,
    maximumFractionDigits = 2
  ) => {
    if (typeof value !== "number") return 0;
    return new Intl.NumberFormat(localeTag, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    }).format(value);
  };

  return {
    currencyFormat: prepareValue,
    prepareDigits: prepareStyle,
    getCurrencySymbol: prepareValue(0, 0, 0).replace(/\d/g, "").trim(),
  };
};

export const useCurrencySymbol = () => {
  const { language } = i18n;
  const { t } = useTranslation("main");

  return new Intl.NumberFormat(language, {
    style: "currency",
    currency: t("all.currencyCode"),
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace(/\d/g, "")
    .trim();
};

export default useCurrencyFormat;
