import React, { useState, useEffect } from "react";
import { useStyles } from "src/pages/Warehouses/modals/CreateWarehouses/styles";
import { Typography, TextField, InputAdornment, Button } from "@material-ui/core";
import WarehouseDetailsIcon from "src/pages/Warehouses/modals/CreateWarehouses/icons/WarehouseDetailsIcon";
import EmailIcon from "src/pages/Warehouses/modals/CreateWarehouses/icons/EmailIcon";
import { useAppContext } from "src/providers/AppProvider";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { OutlinedInput } from "@material-ui/core";
import ContactPersonIcon from "src/pages/Warehouses/modals/CreateWarehouses/icons/ContactPersonIcon";
import Modal from "src/components/Modal";
import { Grid } from "@material-ui/core";
import { requestPut } from "src/utils/crud";
import { Controller, useForm } from "react-hook-form";
import { FormHelperText } from "@material-ui/core";
import { emailPattern } from "src/utils/constants";
import { CircularProgress } from "@material-ui/core";
import { useWDetailsContext } from "src/providers/WarehouseDetailsStore";
import Success from "./Success";
import { Warehouse, WarehouseDetailsReq } from "src/types";
import PhoneInputField from "src/components/PhoneInput/";

const WarehouseForm = () => {
  const {
    selectedWarehouse: { warehouse },
    callAPI: { setContextData },
  } = useWDetailsContext();
  const {
    state: {
      modals: { warehouseEditModal },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    formState,
    handleSubmit,
    register,
    errors,
    reset,
    setValue,
    control,
    setError,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...warehouse,
    },
  });
  const classes = useStyles();
  const { t } = useTranslation("warehouses");

  const handleModal = useCallback(() => {
    setStatus("");
    toggleModal({ id: "warehouseEditModal", status: false });
  }, []);

  const onSubmit = (data: Warehouse) => {
    setLoading(true);
    (async () => {
      const res = await requestPut<WarehouseDetailsReq>(`Warehouses/${warehouse.key}`, {
        email: data.email,
        mobilePhone: data.mobilePhone,
        contactPerson: data.contactPerson,
      });
      setContextData("warehouse", res.data.warehouse!);
      setStatus(`${res.status}`);
      setLoading(false);
    })();
  };

  useEffect(() => {
    reset(warehouse);
  }, [warehouse]);

  const onErrorHandler = (isError: boolean) => {
    isError
      ? setError("mobilePhone", {
          type: "manual",
        })
      : clearErrors("mobilePhone");
  };

  if (warehouse.key === 0) return null;

  if (status === "204" || status === "200") {
    return (
      <Modal isOpen={warehouseEditModal} onClose={handleModal}>
        <Success onClose={handleModal} />
      </Modal>
    );
  }

  return (
    <Modal isOpen={warehouseEditModal} onClose={handleModal}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h3">{t("form.edit_title")}</Typography>
            <div className={classes.fieldGroup}>
              <div className={classes.titleWrapper}>
                <WarehouseDetailsIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("form.warehouse_details")}
                </Typography>
              </div>
              <FormControl
                error={!!errors.email}
                required
                fullWidth
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel htmlFor="email">{t("form.email")}</InputLabel>
                <OutlinedInput
                  id="email"
                  type="email"
                  name="email"
                  fullWidth
                  inputRef={register({
                    required: "Required",
                    pattern: emailPattern,
                  })}
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  }
                  labelWidth={140}
                />
                {!!errors.email && (
                  <FormHelperText id="email">{t("form.invalid_email")}</FormHelperText>
                )}
              </FormControl>
              {/* <FormControl
                error={!!errors.mobilePhone}
                required
                fullWidth
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel htmlFor="phone">{t("form.phone")}</InputLabel>
                <OutlinedInput
                  id="phone"
                  name="mobilePhone"
                  error={!!errors.mobilePhone}
                  fullWidth
                  inputRef={register({
                    required: "Required",
                  })}
                  startAdornment={
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  }
                  labelWidth={140}
                />
              </FormControl> */}
            </div>
            <div className={classes.fieldGroup}>
              <Controller
                as={
                  <PhoneInputField
                    required={true}
                    label={t("form.phone")}
                    labelWidth={110}
                    name="mobilePhone"
                    onChangeHandler={(value) => setValue("mobilePhone", value)}
                    onErrorHandler={(isError) => onErrorHandler(isError)}
                  />
                }
                name="mobilePhone"
                control={control}
                inputRef={register({
                  required: "Required",
                })}
              />
            </div>
            <div className={classes.fieldGroup}>
              <div className={classes.titleWrapper}>
                <ContactPersonIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("form.contact_person")}
                </Typography>
              </div>
              <TextField
                fullWidth
                error={!!errors.contactPerson}
                name="contactPerson"
                label={t("form.contact_person")}
                variant="outlined"
                inputRef={register()}
              />
            </div>
            <div className={classes.btnGroup}>
              <Button type="submit" className={classes.submitBtn} disabled={!formState.isValid}>
                {loading ? (
                  <CircularProgress size={18} color={"secondary"} />
                ) : (
                  t("labels.btn.save")
                )}
              </Button>

              <Button onClick={handleModal} className={classes.cancelBtn}>
                {t("labels.btn.cancel")}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default WarehouseForm;
