import { makeStyles } from "@material-ui/core/styles";

export const useCStyles = makeStyles((theme) => {
  return {
    menuItem: {
      padding: "4px 12px",
      borderRadius: 4,
      maxHeight: 32,
      "&:hover": {
        backgroundColor: "rgba(43, 89, 195, 0.1)",
      },
      "&.Mui-selected": {
        backgroundColor: "rgba(43, 89, 195, 0.1)"
      },
    },
    menuItemActive: {
      margin: `0 ${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      padding: 0,
      borderRadius: 4,
      backgroundColor: theme.palette.text.disabled,
    },
    isOpen: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(1),
      minWidth: 0,
      transition: ".4s",
    },
    iconOpen: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(1),
      minWidth: 0,
      transition: ".4s",
    },
    wrapper: {
      width: ({ isOpen }: { isOpen: boolean }) => (isOpen ? 48 : 220),
      transition: ".4s",
    },
    wrapArrow: {
      "& .MuiButton-endIcon": {
        marginLeft: ({ isOpen }: { isOpen: boolean }) => (isOpen ? 0 : 8),
      },
      minWidth: 48,
      justifyContent: "space-between",
    },
    mainIcons: {
      width: 24,
      height: 24,
    },
    icons: {
      marginLeft: 4,
      width: 16,
      height: 17,
    },
    arrow: {
      width: 32,
      transform: ({ isOpen }: { isOpen: boolean }) => (isOpen ? "rotate(0deg)" : "rotate(180deg)"),
      transition: ".3s",
    },
    unreadedCommentsBadge: {
      display: "flex",
      height: 15,
      minWidth: 15,
      borderRadius: 100,
      backgroundColor: theme.palette.functionalColors.dark,
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      fontSize: 10,
      position: "absolute",
      right: theme.spacing(1),
    },
  };
});
