import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CustomerAddress = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6 2H15.4C16.28 2 17 2.72 17 3.6V13.2C17 14.08 16.28 14.8 15.4 14.8H2.6C1.72 14.8 1 14.08 1 13.2V3.6C1 2.72 1.72 2 2.6 2Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M17 3.59961L9 9.19961L1 3.59961"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  </SvgIcon>
);
export default CustomerAddress;
