import React from "react";
import {
  DocIcon,
  XlsIcon,
  TxtIcon,
  PdfIcon,
  PptIcon,
  JpgIcon,
  PngIcon,
  DefaultIcon,
} from "./icons";

interface Props {
  type: string;
}

const FileTypeIcon = ({ type }: Props) => {
  switch (type) {
    case "doc":
    case "application/msword":
      return <DocIcon />;
    case "xls":
    case "xlsx":
    case "application/vnd.ms-excel":
      return <XlsIcon />;
    case "txt":
    case "text/plain":
      return <TxtIcon />;
    case "pdf":
    case "application/pdf":
      return <PdfIcon />;
    case "ppt":
    case "application/vnd.ms-powerpoint":
      return <PptIcon />;
    case "jpg":
    case "jpeg":
    case "image/jpeg":
      return <JpgIcon />;
    case "png":
    case "image/png":
      return <PngIcon />;
    default:
      return <DefaultIcon />;
  }
};

export default FileTypeIcon;
