import React from "react";
import { useStyles } from "src/pages/Warehouses/modals/CreateWarehouses/styles";
import { Typography, TextField, InputAdornment, Button } from "@material-ui/core";
import WarehouseDetailsIcon from "src/pages/Warehouses/modals/CreateWarehouses/icons/WarehouseDetailsIcon";
import EmailIcon from "src/pages/Warehouses/modals/CreateWarehouses/icons/EmailIcon";
import { useAppContext } from "src/providers/AppProvider";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PhoneIcon from "src/pages/Warehouses/modals/CreateWarehouses/icons/PhoneIcon";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { OutlinedInput } from "@material-ui/core";
import ContactPersonIcon from "src/pages/Warehouses/modals/CreateWarehouses/icons/ContactPersonIcon";
import Modal from "src/components/Modal";
import { Grid } from "@material-ui/core";
import { Warehouse } from "src/types";
import { requestPost } from "src/utils/crud";
import { useForm } from "react-hook-form";
import { FormHelperText } from "@material-ui/core";
import { emailPattern } from "src/utils/constants";
import Success from "src/pages/Warehouses/modals/CreateWarehouses/Success";
import { useState } from "react";

interface Props extends Partial<Warehouse> {
  firstName: string;
  lastName: string;
}

const CreateWarehouseForm: React.FC = () => {
  const [status, setStatus] = useState("");
  const { handleSubmit, register, errors, formState } = useForm<Props>({
    mode: "onBlur",
  });
  const classes = useStyles();
  const {
    state: {
      modals: { warehouseModal },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const { t } = useTranslation("warehouses");

  const handleModal = useCallback(() => {
    toggleModal({ id: "warehouseModal", status: false });
  }, []);

  const onSubmit = useCallback((data: Props) => {
    (async () => {
      const res = await requestPost<Props>("Warehouses", {
        mobilePhone: data.mobilePhone,
        email: data.email,
        name: data.name,
        contactPerson: `${data.firstName} ${data.lastName}`,
      });
      setStatus(`${res.status}`);
    })();
  }, []);

  if (status === "204" || status === "200") {
    return (
      <Modal isOpen={warehouseModal} onClose={handleModal}>
        <Success />
      </Modal>
    );
  }
  return (
    <Modal isOpen={warehouseModal} onClose={handleModal}>
      <Grid container justify="center">
        <Grid item>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h3">{t("form.title")}</Typography>
            <div className={classes.fieldGroup}>
              <div className={classes.titleWrapper}>
                <WarehouseDetailsIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("form.warehouse_details")}
                </Typography>
              </div>
              <TextField
                required
                fullWidth
                error={!!errors.name}
                name="name"
                label={t("form.enter_name")}
                inputRef={register({
                  required: "Required",
                })}
                variant="outlined"
              />

              <FormControl
                error={!!errors.email}
                required
                fullWidth
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel htmlFor="email">{t("form.email")}</InputLabel>
                <OutlinedInput
                  id="email"
                  type="email"
                  name="email"
                  fullWidth
                  inputRef={register({
                    required: "Required",
                    pattern: emailPattern,
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <EmailIcon />
                    </InputAdornment>
                  }
                  labelWidth={140}
                />
                {!!errors.email && (
                  <FormHelperText id="email">{t("form.invalid_email")}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={!!errors.mobilePhone}
                required
                fullWidth
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel htmlFor="phone">{t("form.phone")}</InputLabel>
                <OutlinedInput
                  id="phone"
                  type={"number"}
                  name="mobilePhone"
                  error={!!errors.mobilePhone}
                  fullWidth
                  inputRef={register({
                    required: "Required",
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <PhoneIcon />
                    </InputAdornment>
                  }
                  labelWidth={140}
                />
              </FormControl>
            </div>
            <div className={classes.fieldGroup}>
              <div className={classes.titleWrapper}>
                <ContactPersonIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("form.warehouse_details")}
                </Typography>
              </div>
              <TextField
                required
                fullWidth
                error={!!errors.firstName}
                name="firstName"
                label={t("form.first_name")}
                variant="outlined"
                inputRef={register({
                  required: "Required",
                })}
              />

              <TextField
                required
                fullWidth
                error={!!errors.lastName}
                name="lastName"
                inputRef={register({
                  required: "Required",
                })}
                label={t("form.last_name")}
                variant="outlined"
              />
            </div>
            <div className={classes.btnGroup}>
              <Button type="submit" disabled={!formState.isValid} className={classes.submitBtn}>
                {t("labels.btn.save")}
              </Button>
              <Button onClick={handleModal} className={classes.cancelBtn}>
                {t("labels.btn.cancel")}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default CreateWarehouseForm;
