import { DependencyList, EffectCallback, useCallback, useEffect, useState } from "react";

enum LazyState {
  pending,
  waiting,
  loaded,
}

export const useLazy = (effect: EffectCallback, deps?: DependencyList) => {
  const [state, setState] = useState(LazyState.pending);

  const trigger = useCallback(() => {
    if (state === LazyState.pending) {
      setState(LazyState.waiting);
    }
  }, [state]);

  const reset = useCallback(() => {
    if (state === LazyState.loaded) {
      setState(LazyState.waiting);
    }
  }, [state]);

  useEffect(() => {
    if (state !== LazyState.waiting) {
      return;
    }
    setState(LazyState.loaded);
    return effect();
  }, [...(deps || []), state]);

  return [trigger, reset];
};
