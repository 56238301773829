import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  fileInfoItem: {
    display: "flex",
    alignItems: "center",
    "& .type_1": {
      backgroundColor: "#D0EACC",
      padding: theme.spacing(1),
      borderRadius: 4,
    },
    "& .type_2": {
      backgroundColor: "#C5DBF2",
      padding: theme.spacing(1),
      borderRadius: 4,
    },
    "& .type_3": {
      backgroundColor: "#F9E0FA",
      padding: theme.spacing(1),
      borderRadius: 4,
    },
    "& .type_20": {
      backgroundColor: "#E7E8EA",
      padding: theme.spacing(1),
      borderRadius: 4,
    },
    "& .type_21": {
      backgroundColor: "#EEE8A9",
      padding: theme.spacing(1),
      borderRadius: 4,
    },
    "& .type_100,& .type_103": {
      backgroundColor: "#B3D7E0",
      padding: theme.spacing(1),
      borderRadius: 4,
    },
  },
  previewCollapseContainer: {
    width: "100%",
  },
  preview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
