import { makeStyles } from "@material-ui/core/styles";
import { CustomStyles } from "./index";

export const useStyles = makeStyles((theme) => {
  return {
    tableWrapper: {
      backgroundColor: "white",
      "& .MuiTabs-root": {
        height: 30,
      },
      "& .MuiTableRow-root": {
        borderBottom: "1px solid rgba(43, 89, 195, 0.2)",
      },
      "& .MuiTableCell-root": {
        maxWidth: 180,
        // padding: "8px 16px",
        padding: "0 8px",
        height: 34,
        fontWeight: "normal",
        borderBottom: 0,
        borderLeft: "1px solid rgba(43, 89, 195, 0.2)",
        "&:first-child": {
          borderLeft: 0,
          paddingLeft: (customStyles: CustomStyles) => customStyles.paddingLeft,
        },
        "&:last-child": {
          borderRight: 0,
        },
      },
      "& .MuiTableCell-head": {
        padding: "8px 16px",
        borderLeft: 0,
      },
      "& .MuiTablePagination-toolbar": {
        padding: "0",
        minHeight: "0",
      },
    },
    tableCell: {
      "&:last-child": {
        borderLeft: "0",
      },
    },
    totalCell: {
      padding: "8px 16px",
    },
    headerText: {
      color: theme.palette.text.primary,
    },
    footerText: {
      fontWeight: 600,
    },
  };
});
