import React from 'react';
import { Paper } from '@material-ui/core';
import { useWarehouseSellerStyles } from './styles';



const AnalyticsWarehouseSeller: React.FC = () => {
    const classes = useWarehouseSellerStyles();
    return (
        <Paper className={classes.mainWrapper}>
            Analytics Warehouse Seller
      </Paper>
    )
};

export default AnalyticsWarehouseSeller;