import React from "react";
import { Typography } from "@material-ui/core";
import NoServicesIcon from "src/pages/Orders/components/icons/NoServicesIcon";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 100,
    height: 100,
    marginTop: 20,
    marginBottom: 16,
  },
  title: {
    padding: theme.spacing(2),
  },
  links: {
    cursor: "pointer",
    color: theme.palette.secondary.light
  }
}));

const NoServices:React.FC = () => {
  
  const classes = useStyles();
  const { t } = useTranslation("orders");
  
  return (
    <div className={classes.wrapper}>
      <NoServicesIcon className={classes.icon} />
      <Typography variant="h3" className={classes.title} >
        {t("labels.empty_state.title")}
        </Typography>
    </div>
  );
};

export default NoServices;
