import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const AssignedWorkersIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6364 14.0909V12.6364C12.6364 11.0297 11.3339 9.72729 9.72727 9.72729H3.90909C2.30244 9.72729 1 11.0297 1 12.6364V14.0909"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.81827 6.81818C8.42492 6.81818 9.72736 5.51574 9.72736 3.90909C9.72736 2.30244 8.42492 1 6.81827 1C5.21162 1 3.90918 2.30244 3.90918 3.90909C3.90918 5.51574 5.21162 6.81818 6.81827 6.81818Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17.0002 14.0909V12.6363C16.9992 11.3106 16.102 10.1532 14.8184 9.82178"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.9092 1.09448C13.1964 1.42406 14.0967 2.58393 14.0967 3.91266C14.0967 5.2414 13.1964 6.40127 11.9092 6.73085"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default AssignedWorkersIcon;
