import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import { requestGet } from "src/utils/crud";
import { useFormContext } from "react-hook-form";
export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 4,
    },
  },
}));

interface WarehousesListType {
  key: number;
  displayText: string;
}

const Suggestion: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const data = useFormContext();
  const [options, setOptions] = React.useState<WarehousesListType[]>([]);
  const loading = open && options.length === 0;
  const classes = useStyles();
  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await requestGet<{ warehouses: WarehousesListType[] }>(`warehouses/lookups`);
      if (active) {
        setOptions(res.data.warehouses);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    data.register({ name: "warehouseId" });
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.displayText === value.displayText}
      getOptionLabel={(option) => option.displayText}
      onChange={(_, value) => {
        if (!!value) {
          data.setValue("warehouseId", value?.key);
        }
      }}
      options={options}
      loading={loading}
      className={classes.root}
      renderInput={(params) => (
        <TextField
          label="Warehouse name"
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={10} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default Suggestion;
