import React, { useCallback, useMemo } from "react";
import { Typography, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/pages/ServiceOrdersDetails/components/styles";
import NotesIcon from "src/pages/ServiceOrdersDetails/components/icons/NotesIcon";
import MonteraCard from "src/components/MonteraCard";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { useAppContext } from "src/providers/AppProvider";
import EditIcon from "src/pages/ServiceOrdersDetails/components/icons/EditIcon";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

const Notes = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    state: { user },
  } = useAppContext();
  const {
    serviceOrderDetails: { serviceOrder },
  } = useSODetailsContext();
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();

  const handleNotesEdit = useCallback(() => {
    toggleModal({ id: "editNotesSO", status: true });
  }, []);

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <MonteraCard
      headerIcon={<NotesIcon />}
      title={t("labels.summary_cards.notes")}
      action={
        isIntegrated && serviceOrder?.externalIdType === 0 && disabledByStatus ? (
          <IconButton onClick={handleNotesEdit}>
            <EditIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        ) : (
          <></>
        )
      }
    >
      <div className={classes.contentWrap}>
        <Typography variant="caption" className={classes.caption}>
          {t("labels.summary_cards.notes")}
        </Typography>
        <Typography variant="subtitle1" className={classes.displayValue}>
          {serviceOrder.notes || "---"}
        </Typography>
      </div>
    </MonteraCard>
  );
};

export default Notes;
