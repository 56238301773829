import React, { memo } from "react";
import { LinearProgress } from "@material-ui/core";

const PreloaderLine = memo<{ loading?: boolean }>(({ loading }) => {
  if (!loading) {
    return null;
  }
  return <LinearProgress />;
});

export default PreloaderLine;
