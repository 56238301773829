import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SuccessIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#4ECDC4"
    />
    <path
      opacity="0.2"
      d="M20.8452 40.6308L35.2427 55.0283C47.167 51.8485 55.9998 40.9841 55.9998 27.9999C55.9998 27.7349 55.9998 27.4699 55.9998 27.2049L44.6938 16.7822L20.8452 40.6308Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M27.0269 35.9515C28.2635 37.188 28.2635 39.3079 27.0269 40.5445L26.1454 41.426C24.9088 42.6626 22.7889 42.6626 21.5523 41.426L10.3347 30.12C9.09808 28.8834 9.09808 26.7636 10.3347 25.527L11.2162 24.6455C12.4528 23.4089 14.5727 23.4089 15.8092 24.6455L27.0269 35.9515Z"
      fill="white"
    />
    <path
      d="M40.1912 16.4309C41.4278 15.1943 43.5476 15.1943 44.7842 16.4309L45.6657 17.3124C46.9023 18.549 46.9023 20.6688 45.6657 21.9054L26.2336 41.2493C24.997 42.4859 22.8771 42.4859 21.6405 41.2493L20.759 40.3678C19.5224 39.1312 19.5224 37.0113 20.759 35.7747L40.1912 16.4309Z"
      fill="white"
    />
  </SvgIcon>
);
export default SuccessIcon;
