import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Props,
  Context,
  UploadFile,
  UploadFileAddProps,
  FILES_UPLOADING_STATES,
  // FileCategoryLookupObject,
  UploadFileResponse,
  LinkFileDto,
  LinkFileIdTypes,
  FILE_UPLOAD_ERRORS,
  CallBackSetContext,
} from "./types";
import createCtx from "../../utils/createCtx";
import { get, take } from "lodash";
import { LookupObject, File } from "src/types";
import { FILE_STATUSES } from "src/containers/FileUploadDislog/components/FileInfoStatus/constants";
import { PropsOf } from "src/utils/propsOf";
import { requestGet, requestMetaGet } from "src/utils/crud";
import { requestPost, uploadFile } from "src/utils/crud";
import { useParams } from "react-router-dom";
import { SODetailsType } from "src/pages/ServiceOrdersDetails/type";
import { FileType } from "src/providers/CommentsStore/types";

const [useCtx, Provider] = createCtx<Context>();

const FilesProvider = ({ children, serviceOrder, service, context }: Props): React.ReactElement => {
  const isServiceOrder = context === "serviceOrder";
  const isService = context === "service";

  const [files, setFiles] = useState<UploadFile[]>([]);
  const params = useParams<SODetailsType>();
  const [relationId, setRelationId] = useState<string>("");
  const [locations, setLocations] = useState<LookupObject[]>([]);
  const [categories, setCategories] = useState<LookupObject[]>([]);
  const [uploadingState, setUploadingState] = useState(FILES_UPLOADING_STATES.PENDING);
  const hasValidationErrors = useMemo(() => files.some((x) => x.errors.length), [files]);
  useEffect(() => {
    if (isServiceOrder && !params?.serviceOrderId) {
      return setRelationId(`${serviceOrder?.id}`);
    }

    if (isService && !params?.serviceId) {
      return setRelationId(`${service?.id}`);
    }

    if (isServiceOrder) {
      return setRelationId(`${params?.serviceOrderId}`);
    }

    if (isService) {
      return setRelationId(`${params?.serviceId}`);
    }
  }, [serviceOrder]);

  const addFiles = (props: UploadFileAddProps[]) => {
    if (!!relationId && relationId !== "") {
      const newFiles = props.map((x) =>
        UploadFile.new({
          ...x,
          file: x.file,
          location: Number(relationId),
          errorCode: null,
        })
      );
      setFiles((prev) => take([...prev, ...newFiles], constants.MAX_FILES_COUNT));
    }
  };

  const onDelete = useCallback((item: UploadFile) => {
    setFiles((prev) => prev.filter((x) => x.id !== item.id));
  }, []);

  const onChange = useCallback(
    (item: UploadFile, update: Partial<PropsOf<UploadFile>>, data?: any) => {
      setFiles((prev) => {
        return prev.map((x) => {
          if (x.id !== item.id) {
            return x;
          }

          const updateProps = {
            ...update,
            ...data,
          };

          return x.clone(updateProps);
        });
      });
    },
    [categories]
  );

  const clearFiles = () => {
    setFiles([]);
  };

  const fetchInitial = async () => {
    let partner: string | undefined;
    const [locations, categories] = await Promise.all([
      API.getLocations(Number(serviceOrder?.id)),
      API.getCategories(partner),
    ]);
    setLocations(locations);
    setCategories(categories);
  };

  const resetState = () => {
    setFiles([]);
    setUploadingState(FILES_UPLOADING_STATES.PENDING);
    setLocations([]);
    setCategories([]);
  };

  const fileCountLimitReached = files.length >= constants.MAX_FILES_COUNT;

  const canBeSent = useMemo(() => {
    if (!files.length) {
      return false;
    }
    return files.every((x) => x.canBeSent());
  }, [files]);

  const uploadFile = async (fileItem: UploadFile) => {
    const isServiceOrderLocations =
      fileItem.location === Number(relationId) && context === "serviceOrder";
    const entityKey = isServiceOrderLocations ? "serviceOrderId" : "serviceId";

    const progressShift = 0.01;
    let progress = 0;
    const onProgress = (p: number) => {
      progress = Math.max(0, p - progressShift);
      onChange(fileItem, { progress });
    };

    try {
      onChange(fileItem, { status: FILE_STATUSES.LOADING });
      const uploadResponse = await API.uploadFile(fileItem, onProgress);

      const res = await API.linkFile(fileItem, uploadResponse, entityKey);

      onChange(fileItem, { status: FILE_STATUSES.DONE, progress }, res.data);
      return { data: { ...res.data, ...uploadResponse } };
    } catch (e) {
      const code: number = get(e, "response.data.code", 0);

      let errorCode = FILE_UPLOAD_ERRORS.UNKNOWN;
      if (code === FILE_UPLOAD_ERRORS.TYPE_NOT_SUPPORTED) {
        errorCode = FILE_UPLOAD_ERRORS.TYPE_NOT_SUPPORTED;
      }

      onChange(fileItem, { status: FILE_STATUSES.FAILED, errorCode });
      throw e;
    }
  };

  const handleSubmit = async (): Promise<FileType[] | null> => {
    setUploadingState(FILES_UPLOADING_STATES.UPLOADING);

    const promises = files.filter((f) => f.status === FILE_STATUSES.PENDING).map(uploadFile);
    const result = await Promise.allSettled(promises);
    const hasErrors = result.some((x) => x.status === "rejected");
    if (hasErrors) {
      setUploadingState(FILES_UPLOADING_STATES.FAILED);
    }
    if (result.length === 1 && hasErrors) return [];

    setUploadingState(FILES_UPLOADING_STATES.DONE);

    return result
      .filter((file) => file.status === "fulfilled")
      .map((ent) => {
        return ((ent as any).value as { data: FileType & File }).data;
      });
  };

  const submit = () => {
    handleSubmit();
  };

  const submitCommentsFiles = async (
    setContextData: CallBackSetContext,
    uploadedFiles: FileType[]
  ) => {
    const files = await handleSubmit();
    setContextData("uploadedFiles", files?.concat(...uploadedFiles));
  };

  const reset = () => {
    setFiles([]);
    setUploadingState(FILES_UPLOADING_STATES.PENDING);
  };

  const hasAnyFiles = !!files.length;
  const hasError = uploadingState === FILES_UPLOADING_STATES.FAILED;

  const uploading = uploadingState === FILES_UPLOADING_STATES.UPLOADING;
  const uploaded =
    uploadingState === FILES_UPLOADING_STATES.DONE ||
    uploadingState === FILES_UPLOADING_STATES.FAILED;

  return (
    <Provider
      value={{
        constants,
        files,
        categories,
        locations,
        addFiles,
        clearFiles,
        fileCountLimitReached,
        hasError,
        uploading,
        uploaded,
        hasValidationErrors,
        canBeSent,
        uploadingState,
        submit,
        submitCommentsFiles,
        hasAnyFiles,
        reset,
        onChange,
        onDelete,
        fetchInitial,
        resetState,
      }}
    >
      {children}
    </Provider>
  );
};

const constants = {
  MAX_FILES_COUNT: 20,
};

const API = {
  getLocations: async (serviceOrderId: number): Promise<LookupObject[]> => {
    const response = await requestGet<{ serviceOrder: LookupObject; services: LookupObject[] }>(
      `ServiceOrders/${serviceOrderId}/lookup-with-services`
    );
    return [response.data.serviceOrder, ...response.data.services];
  },

  getCategories: async (partner: string | undefined): Promise<LookupObject[]> => {
    const response = await requestMetaGet<LookupObject[]>("lookups/filecategories", {
      partner,
    });
    return response.data.map((x) => ({
      key: x.key,
      displayText: x.displayText,
    }));
  },

  uploadFile: async (fileItem: UploadFile, progressCallback: (progress: number) => void) => {
    const response = await uploadFile<UploadFileResponse>(fileItem.file, progressCallback);

    return response.data;
  },

  linkFile: async (
    fileItem: UploadFile,
    uploadResponse: UploadFileResponse,
    entityKey: LinkFileIdTypes
  ) => {
    const dto: LinkFileDto = {
      fileName: uploadResponse.fileName,

      fileServiceId: uploadResponse.fileId,
      categoryId: Number(fileItem.category),
      description: fileItem.description,
      createdDate: new Date(),
      sendToIntegration: fileItem.sendToIntegration,
      [entityKey]: fileItem.location,
    };

    return await requestPost("files", dto, { hasResponse: true });
  },
};

export { useCtx as useFilesContext, FilesProvider };
