import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const InfoIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M8 17C12.4183 17 16 13.4183 16 9C16 4.58172 12.4183 1 8 1C3.58172 1 0 4.58172 0 9C0 13.4183 3.58172 17 8 17Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8 13L8 10"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8 7L8 6"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  </SvgIcon>
);
export default InfoIcon;
