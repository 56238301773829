import React, { useState, useRef, useCallback, useEffect } from "react";
import { useStyles } from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/styles";
import { AxiosResponse } from "axios";
import { Typography, Button, Grid, CircularProgress } from "@material-ui/core";
import { requestPut } from "src/utils/crud";
import SelectInput from "src/components/SelectInput";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/SuccessNotification";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import DeliveryAddressIcon from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/Icons/DeliveryAddressIcon";
import CreateDeliveryAddress from "src/pages/ServiceOrdersDetails/components/CreateDeliveryAddress";
import { DeliveryAddress } from "src/types";

const EditDeliveryAddress: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { editDeliveryAddressSO },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const {
    serviceOrderDetails: { customerInfo, serviceOrder },
    callAPI: { setContextData, getSODetails },
  } = useSODetailsContext();

  const [deliveryAddress, setDeliveryAddress] = useState({
    key: customerInfo?.deliveryAddress?.key,
    displayText: customerInfo?.deliveryAddress?.name,
    isDefault: customerInfo?.deliveryAddress?.isDefault,
  });

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "editDeliveryAddressSO", status: false });
    setSubmitted(false);
  }, []);

  const handleCreateDeliveryAddress = useCallback(() => {
    toggleModal({ id: "createDeliveryAddressModal", status: true });
  }, []);

  const handleDropdownChange = (value: number) => {
    setDeliveryAddress({ ...deliveryAddress, key: value });
  };

  const submitAddress = async (data: any) => {
    try {
      const res = await requestPut(`ServiceOrders/${serviceOrder.id}/deliveryAddress`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const submitObj: { deliveryAddressId: number; assemblyZoneId?: number } = {
      deliveryAddressId: deliveryAddress.key!,
    };
    const addressRes = await submitAddress(submitObj);

    if ((addressRes as AxiosResponse).status === 200) {
      setSubmitted(true);
      const newCustomerInfo = {
        ...customerInfo,
        deliveryAddress: {
          ...customerInfo?.deliveryAddress,
          name: (addressRes as AxiosResponse).data.serviceOrder.deliveryAddress,
          key: deliveryAddress.key,
        },
      };
      setContextData("customerInfo", newCustomerInfo);
      getSODetails(serviceOrder.id || 0);
    }
    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  const refreshState = async () => {
    if (!customerInfo) {
      return;
    }
    setDeliveryAddress({
      key: customerInfo?.deliveryAddress?.key,
      displayText: customerInfo?.deliveryAddress?.name,
      isDefault: customerInfo?.deliveryAddress?.isDefault,
    });
  };

  useEffect(() => {
    refreshState();
  }, [customerInfo]);

  const onDeliveryAddressSubmitHandler = (data: Partial<DeliveryAddress>) => {
    setDeliveryAddress({ ...deliveryAddress, key: data?.key });
  };

  return (
    <>
      <Modal isOpen={editDeliveryAddressSO} onClose={handleModalClose}>
        <Grid container justify="center" style={{ width: "100%" }}>
          <Grid item style={{ width: "100%" }}>
            {!submitted && deliveryAddress && (
              <form onSubmit={handleSubmit}>
                <Typography variant="h3">
                  {t("labels.create_customer.addresses_form.change_delivery")}
                </Typography>
                <div className={classes.fieldGroup}>
                  <div className={classes.titleWrapper}>
                    <DeliveryAddressIcon />
                    <Typography variant="h4" className={classes.fieldTitle}>
                      {t("labels.create_customer.addresses_form.delivery_address")}
                    </Typography>
                  </div>
                  <SelectInput
                    required
                    onSelect={handleDropdownChange}
                    label={t("labels.create_customer.addresses_form.choose_delivery")}
                    name="deliveryAddresses"
                    responseKey="deliveryAddresses"
                    value={deliveryAddress.key!}
                    url={`DeliveryAddresses/${customerInfo?.customer?.key}/lookups`}
                  />
                  <div>
                    <Typography variant="body1" className={classes.createSuggestion}>
                      {t("labels.create_customer.addresses_form.could_not_find_delivery")}
                    </Typography>
                    <Button className={classes.createBtn} onClick={handleCreateDeliveryAddress}>
                      <Typography variant="button">
                        {t("labels.create_customer.addresses_form.create_new")}
                      </Typography>
                    </Button>
                  </div>
                </div>
                <div className={classes.btnGroup}>
                  <Button className={classes.submitBtn} type="submit" ref={submitButtonRef}>
                    {loading ? (
                      <CircularProgress size={18} color={"secondary"} />
                    ) : (
                      t("labels.common.save")
                    )}
                  </Button>
                  <Button onClick={handleModalClose} className={classes.cancelBtn}>
                    {t("labels.common.cancel")}
                  </Button>
                </div>
              </form>
            )}
            {submitted && (
              <SuccessNotification
                message={t("labels.edit_customer.success.title")}
                onClose={handleModalClose}
              />
            )}
          </Grid>
        </Grid>
      </Modal>
      <CreateDeliveryAddress onSubmit={onDeliveryAddressSubmitHandler} />
    </>
  );
};

export default EditDeliveryAddress;
