import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { requestGet, requestPut } from "src/utils/crud";
import createCtx from "src/utils/createCtx";
import { WarehousesDetailsType } from "src/pages/Warehouses/WarehouseDetails/type";
import { WDetailsState, ContextDetails, Props, RelatedOrdersState, WarehouseAction } from "./types";

const [useCtx, Provider] = createCtx<ContextDetails>();

const initialRelatedOrders: RelatedOrdersState = {
  serviceOrders: [],
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  sortBy: "createdDate",
  des: true,
  totalCount: 0,
  loading: true,
  success: false,
};

const initialSelectedWarehouse: WDetailsState = {
  warehouse: {
    key: 0,
    name: "",
    email: "",
    mobilePhone: "",
    active: false,
    registeredDate: "",
  },
  loading: true,
  success: false,
};

function WarehouseDetailsProvider(props: Props): React.ReactElement {
  const [selectedWarehouseStore, setSelectedWarehouse] = useState(initialSelectedWarehouse);
  const [relatedOrders, setRelatedOrders] = useState(initialRelatedOrders);
  const { warehouse } = selectedWarehouseStore;
  const { currentPage, pageSize, sortBy, des } = relatedOrders;
  const { warehousesID } = useParams<WarehousesDetailsType>();

  useEffect(() => {
    !!warehousesID && getWarehouseDetails(warehousesID);
  }, []);
  useEffect(() => {
    if (warehouse.key !== 0 && !!warehouse.key) {
      getRelatedOrders(warehouse.key);
    }
  }, [warehouse.key, pageSize, sortBy, des, currentPage]);

  const getRelatedOrders = (key: number | string) => {
    setOrdersLoading(true);
    requestGet(`Warehouses/${key}/serviceOrders`, {
      page: relatedOrders.currentPage,
      pageSize: relatedOrders.pageSize,
      sortBy: relatedOrders.sortBy,
      des: relatedOrders.des,
    })
      .then((res) => {
        setRelatedOrders((prev) => ({
          ...prev,
          ...res.data,
          pageSize: prev.pageSize,
          currentPage: prev.currentPage,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };
  const getWarehouseDetails = (key: number | string) => {
    setOrdersLoading(true);
    requestGet(`Warehouses/${key}`)
      .then((res) => {
        setSelectedWarehouse((prev) => ({
          ...prev,
          ...res.data,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const setWarehouseAction = async (action: WarehouseAction) => {
    setWarehouseLoading(true);

    try {
      const { data } = await requestPut<WDetailsState>(`Warehouses/${warehouse.key}/${action}`);
      setSelectedWarehouse((prev) => ({
        ...prev,
        ...data,
        loading: false,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const setWarehouseLoading = (data: boolean) =>
    setSelectedWarehouse((prev: WDetailsState) => ({
      ...prev,
      loading: data,
    }));
  const setOrdersLoading = (data: boolean) =>
    setRelatedOrders((prev: RelatedOrdersState) => ({
      ...prev,
      loading: data,
    }));
  const setContextOrdersData = (key: string, data: any) => {
    setRelatedOrders((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };
  const setContextData = (key: string, data: any) => {
    setSelectedWarehouse((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const callAPI = {
    getWarehouseDetails,
    setWarehouseLoading,
    getRelatedOrders,
    setContextOrdersData,
    setContextData,
    setWarehouseAction,
  };

  return (
    <Provider
      value={{
        selectedWarehouse: selectedWarehouseStore,
        relatedOrders,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useWDetailsContext, WarehouseDetailsProvider };
