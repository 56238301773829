import { memo } from "react";

interface Props {
  size: number;
}

const StringifiedFileSize = memo(({ size }: Props) => {
  const sizeTypes = ["B", "KB", "MB"];
  const decimals = 1;
  const format = (size: number, type: string) => {
    const mul = decimals * 10;
    const rounded = Math.round(size * mul) / mul;
    return `${rounded}${type}`;
  };
  for (const type of sizeTypes) {
    if (size < 1024) {
      return format(size, type);
    }
    size /= 1024;
  }
  const lastType = sizeTypes[sizeTypes.length - 1];
  return format(size, lastType);
});

export default StringifiedFileSize;
