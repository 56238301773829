import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.functionalColors.dark,
    padding: theme.spacing(2),
  },
  activeServiceOrder: {
    backgound: "#fff",
  },
  centerElement: {
    textAlign: "center",
  },
  panelSummary: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0 4px",
    "& :focused": {
      backgroundColor: "#fff",
    },
    "& >div": {
      margin: 0,
    },
    "& >.Mui-expanded": {
      margin: 0,
    },
    "& .MuiListItem-gutters": {
      padding: 4,
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(1),
    },
  },
  dynamicWarningMessage: {
    minWidth: 28,
  },
  dynamicIcon: {
    minWidth: 64,
  },
}));
