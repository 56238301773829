import { Dictionary, difference, keysIn, merge, omitBy, valuesIn } from "lodash";
import { useEffect, useState } from "react";
import { getPreview, getThumbnail } from "../api";
import { CarouselItemData, MonteraFile } from "../types";

export const useItemsData = () => {
  const [itemsData, setItemsData] = useState<Dictionary<CarouselItemData>>({});
  const getItemData = (key: MonteraFile["id"]): CarouselItemData | undefined => itemsData[key];

  const updateItemData = (key: MonteraFile["id"], update: Partial<CarouselItemData>) => {
    setItemsData((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        ...update,
      },
    }));
  };

  const updateAllData = (newData: Dictionary<CarouselItemData>) => {
    const newKeys = keysIn(newData);
    setItemsData((prev) => {
      let updatedData = merge({}, prev, newData);

      const oldKeys = keysIn(prev);
      const keysDifference = difference(oldKeys, newKeys);

      for (let oldKey of keysDifference) {
        const itemData = prev[oldKey];
        clearItemData(itemData);
      }

      updatedData = omitBy(updatedData, (_v, k) => keysDifference.includes(k));

      return updatedData;
    });
  };

  const clearItemData = (itemData: CarouselItemData) => {
    if (itemData.original) {
      window.URL.revokeObjectURL(itemData.original);
    }
    if (itemData.thumbnail) {
      window.URL.revokeObjectURL(itemData.thumbnail);
    }
  };

  const tryLoadItem = async (fileKey: number, fileServiceId: number) => {
    const currentItem = getItemData(fileKey);
    if (!currentItem) {
      return;
    }

    if (currentItem.original || currentItem.loadingOriginal) {
      return;
    }

    if (!currentItem.isImage) {
      return;
    }

    updateItemData(fileKey, {
      loadingOriginal: true,
    });

    let original: string | undefined = undefined;
    try {
      const blob = await getPreview(fileServiceId);
      original = window.URL.createObjectURL(blob);
    } finally {
      updateItemData(fileKey, {
        loadingOriginal: false,
        original,
      });
    }
  };

  const loadThumbs = async (files: MonteraFile[]) => {
    const promises: Promise<void>[] = [];

    for (let file of files) {
      const currentData = itemsData[file.id];

      if (!currentData) {
        continue;
      }

      if (currentData.thumbnail) {
        continue;
      }

      if (!currentData.isImage) {
        continue;
      }

      const action = async () => {
        updateItemData(file.id, {
          loadingThumbnail: true,
        });

        let thumbnail: string | undefined = undefined;
        try {
          const blob = await getThumbnail(file.fileServiceId);
          thumbnail = window.URL.createObjectURL(blob);
        } finally {
          updateItemData(file.id, {
            loadingThumbnail: false,
            thumbnail,
          });
        }
      };
      promises.push(action());
    }
    await Promise.all(promises);
  };

  useEffect(() => {
    return () => {
      for (let itemData of valuesIn(itemsData)) {
        clearItemData(itemData);
      }
    };
  }, []);

  return { itemsData, getItemData, updateAllData, tryLoadItem, loadThumbs };
};
