import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    contentStyles: {
      minWidth: 560,
      overflowY: "unset",
      paddingTop: 0,
      paddingBottom: 40,
    },
    titleStyles: {
      padding: 0,
    },
    closeBtn: {
      float: "right",
    },
    closeIcon: {
      width: 16,
      height: 16,
    },
  };
});
