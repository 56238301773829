import React from "react";
import Button from "@material-ui/core/Button";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import { useAppContext } from "src/providers/AppProvider";
import { useCommentsContext } from "src/providers/CommentsStore";

const AddBtn = () => {
  const {
    state: {
      user: { isStaffChatRole },
    },
  } = useAppContext();
  const {
    callAPI,
    comments: { selectedChannel, chatchannels, isAddNewThread },
  } = useCommentsContext();

  const addTreads = () => {
    callAPI.setContextData("isAddNewThread", true);
  };

  const btn = (
    <Button
      variant="text"
      color="secondary"
      size={"small"}
      onClick={addTreads}
      style={{ marginLeft: "auto", height: "100%" }}
    >
      <AddBoxOutlinedIcon color="disabled" />
    </Button>
  );
  if (chatchannels[selectedChannel]?.matchingText === "Public") {
    if (isStaffChatRole) {
      return btn;
    } else {
      return null;
    }
  }
  if (!isAddNewThread) {
    return btn;
  }
  return null;
};
export default AddBtn;
