import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStyles } from "./styles";
import { AxiosResponse } from "axios";
import {
  Typography,
  TextField,
  Button,
  debounce,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { DeliveryAddress, LookupObject } from "src/types";
import { requestGet, requestMetaGet, requestPost } from "src/utils/crud";
import SelectInput from "src/components/SelectInput";
import CustomerAutocomplete from "../../../CreateCustomer/CustomerAutocomplete";
import DeliveryAddressIcon from "./icons/DeliveryAddressIcon";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "../SuccessNotification";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import { Customer } from "src/types/";

const initialStateDelivery = {
  address: "",
  city: {
    key: undefined,
    displayText: "",
  },
  zipCode: {
    key: undefined,
    displayText: "",
  },
  province: {
    key: undefined,
    displayText: "",
  },
  country: {
    key: undefined,
    displayText: "",
  },
};
interface Props {
  customerId?: string | number;
  onSubmit?: (number:number) => void;
}
const CreateDeliveryAddress: React.FC<Props> = ({ customerId, onSubmit=()=>{} }) => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const [deliveryValues, setDeliveryValues] = useState<Partial<DeliveryAddress>>(
    initialStateDelivery
  );
  // Handling Delivery Autocomplete
  const [deliveryZipCodesOpen, setDeliveryZipCodesOpen] = useState<boolean>(false);
  const [deliveryZipCodesLoading, setDeliveryZipCodesLoading] = useState<boolean>(false);
  const [deliveryZipCodeValues, setDeliveryZipCodeValues] = useState<LookupObject[]>([]);
  // Set data as Main Address
  const [deliveryAsMain, setDeliveryAsMain] = useState<boolean>(false);
  // Set as Main Address
  const [isDefaultAddress, setAsDefaultAddress] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { createDeliveryAddressModal },
    },
    callAppAPI,
  } = useAppContext();
  const {
    selectedCustomer: { customer },
    callAPI: { getDeliveryAddresses, setContextData },
  } = useCustomerDetailsContext();

  useEffect(() => {
    if (!!customerId) {
      const req = requestGet<{ customer: Partial<Customer> }>(`customers/${customerId}`).then(
        ({ data }) => data
      );
      req.then((data) => setContextData("customer", data.customer));
    }
  }, [customerId]);

  const handleModalClose = useCallback(() => {
    callAppAPI.toggleModal({ id: "createDeliveryAddressModal", status: false });
    setSubmitted(false);
  }, []);

  const compareCurrentAndCustomer = () => {
    if (
      deliveryValues.address === customer.address &&
      deliveryValues.country?.key === customer.country?.key &&
      deliveryValues.zipCode?.key === customer.zipCode?.key &&
      deliveryValues.province?.key === customer.province?.key &&
      deliveryValues.city?.key === customer.city?.key
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getZipCodes = debounce(async (displayText: string) => {
    const zipCodes = await requestMetaGet<LookupObject[]>(
      `lookups/zipCodes?countryKey=${deliveryValues.country?.key}&displayText=${displayText}`
    );
    let response = zipCodes.data.map((item) => {
      return { key: item.key, displayText: item.displayText };
    });
    setDeliveryZipCodeValues(response);
    setDeliveryZipCodesLoading(false);
  }, 600);

  const getCascadeData = async (zipCode: string | number) => {
    return await requestMetaGet<Partial<DeliveryAddress>>(
      `lookups/zipCodes/${zipCode}?countryKey=${deliveryValues.country?.key}`
    );
  };

  //Zip Code Autocomplete
  const handleAutocompleteChange = async (value: string, getNewData: boolean) => {
    if (getNewData) {
      setDeliveryZipCodesOpen(true);
      setDeliveryZipCodesLoading(true);
      await getZipCodes(value);
    } else {
      setDeliveryZipCodesOpen(false);
    }
    setDeliveryValues((prev) => ({ ...prev, zipCode: { ...prev.zipCode, displayText: value } }));
  };

  // Zip Code Select
  const handleAutocompleteSelect = (value: number, displayText: string) => {
    setDeliveryValues((prev) => ({ ...prev, zipCode: { ...prev.zipCode, key: value } }));
    setDeliveryZipCodesOpen(false);
    handleDataCascade(displayText);
  };

  const handleDataCascade = async (parentZipCode: string) => {
    const cascadeData = await getCascadeData(parentZipCode);
    if (cascadeData && cascadeData.data) {
      setDeliveryValues({
        ...deliveryValues,
        zipCode: {
          key: cascadeData.data.key,
          displayText: cascadeData.data.displayText,
        },
        city: {
          key: cascadeData.data?.city?.key,
          displayText: cascadeData.data?.city?.displayText,
        },
        province: {
          key: cascadeData.data?.province?.key,
          displayText: cascadeData.data?.province?.displayText,
        },
        country: {
          key: cascadeData.data?.country?.key,
          displayText: cascadeData.data?.country?.displayText,
        },
      });
    }
  };

  // Country Select
  const handleDeliveryDropdownChange = (value: number) => {
    setDeliveryValues({
      ...deliveryValues,
      country: { key: value },
      zipCode: {
        key: undefined,
        displayText: "",
      },
      city: {
        key: undefined,
        displayText: "",
      },
      province: {
        key: undefined,
        displayText: "",
      },
    });
  };

  const handleDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeliveryValues({ ...deliveryValues, [event.target.name]: event.target.value });
  };

  const handleDeliveryAsMain = () => {
    setDeliveryAsMain(!deliveryAsMain);
    setDeliveryValues({
      address: customer.address as string,
      city: { key: customer.city?.key, displayText: customer.city?.displayText },
      zipCode: {
        key: customer.zipCode?.key,
        displayText: customer.zipCode?.displayText,
      },
      province: {
        key: customer.province?.key,
        displayText: customer.province?.displayText,
      },
      country: {
        key: customer.country?.key,
        displayText: customer.country?.displayText,
      },
    });
  };

  const handleMakeAsDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsDefaultAddress(event.target.checked);
  };

  const createDeliveryAddress = async (data: Partial<DeliveryAddress>) => {
    try {
      const res = await requestPost<DeliveryAddress>(`DeliveryAddresses?hasResponse=true`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const assignDeliveryAddress = async (data: { key: number | string; isDefault: boolean }) => {
    try {
      const res = await requestPost(`Customers/${customerId}/deliveryAddresses`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let deliveryAssignedRes;
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const deliveryObj = {
      address: deliveryValues.address,
      country: { key: deliveryValues.country?.key },
      city: { key: deliveryValues.city?.key },
      zipCode: { key: deliveryValues.zipCode?.key },
      state: { key: deliveryValues.province?.key },
    };
    const deliveryCreatedRes = await createDeliveryAddress({
      ...deliveryObj,
      isDefault: isDefaultAddress,
    });
    if ((deliveryCreatedRes as AxiosResponse).status === 201) {
      const deliveryAddressData = {
        key: (deliveryCreatedRes as AxiosResponse).data.key,
        isDefault: isDefaultAddress,
      };
      deliveryAssignedRes = await assignDeliveryAddress(deliveryAddressData);
    }

    if (
      deliveryAssignedRes &&
      (deliveryAssignedRes.status === 204 || deliveryAssignedRes.status === 201)
    ) {
      onSubmit( (deliveryCreatedRes as AxiosResponse).data.key );
      getDeliveryAddresses(Number(customer.key));
      setSubmitted(true);
    }
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    !deliveryZipCodesOpen && setDeliveryZipCodeValues([]);
  }, [deliveryZipCodesOpen]);

  useEffect(() => {
    if (deliveryAsMain) {
      const matchingValues = compareCurrentAndCustomer();
      !matchingValues && setDeliveryAsMain(false);
    }

    if (
      deliveryValues &&
      deliveryValues.address &&
      deliveryValues.city &&
      deliveryValues.city.key &&
      deliveryValues.country &&
      deliveryValues.country.key
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [deliveryValues]);

  useEffect(() => {
    if (!createDeliveryAddressModal) {
      setDeliveryValues(initialStateDelivery);
      setDeliveryAsMain(false);
    }
  }, [createDeliveryAddressModal]);

  return (
    <Modal isOpen={createDeliveryAddressModal} onClose={handleModalClose}>
      <Grid container justify="center">
        <Grid item>
          {!submitted && (
            <form onSubmit={handleSubmit}>
              <Typography variant="h3">{t("labels.details.create_delivery_address")}</Typography>
              <div className={classes.fieldGroupFirst}>
                <div className={classes.titleWrapper}>
                  <DeliveryAddressIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.create_customer.addresses_form.delivery_address")}
                  </Typography>
                </div>
                <TextField
                  required
                  fullWidth
                  name="address"
                  label={t("labels.create_customer.addresses_form.address")}
                  value={deliveryValues.address}
                  onChange={handleDeliveryChange}
                  variant="outlined"
                  helperText={t("labels.create_customer.addresses_form.address_helper")}
                  FormHelperTextProps={{
                    classes: { root: classes.addressCaption },
                  }}
                />
                <SelectInput
                  required
                  onSelect={handleDeliveryDropdownChange}
                  label={t("labels.create_customer.addresses_form.country")}
                  name="country"
                  value={deliveryValues.country?.key!}
                  url="lookups/countries/delivery"
                  metaApi
                />
                <div className={classes.fieldWrapper}>
                  <CustomerAutocomplete
                    required
                    options={deliveryZipCodeValues}
                    name="zipCode"
                    label={t("labels.create_customer.addresses_form.zip")}
                    inputValue={deliveryValues.zipCode?.displayText}
                    open={deliveryZipCodesOpen}
                    setOpen={setDeliveryZipCodesOpen}
                    loading={deliveryZipCodesLoading}
                    setLoading={setDeliveryZipCodesLoading}
                    onChange={handleAutocompleteChange}
                    onSelect={handleAutocompleteSelect}
                  />
                  <TextField
                    required
                    fullWidth
                    disabled
                    name="cityId"
                    label={t("labels.create_customer.addresses_form.city")}
                    value={deliveryValues.city?.displayText}
                    onChange={handleDeliveryChange}
                    variant="outlined"
                    className={classes.cityField}
                  />
                </div>
                <TextField
                  fullWidth
                  disabled
                  name="province"
                  label={t("labels.create_customer.addresses_form.state")}
                  value={deliveryValues.province?.displayText}
                  onChange={handleDeliveryChange}
                  variant="outlined"
                />
                <FormControlLabel
                  disabled={!customer}
                  control={
                    <Checkbox
                      onClick={handleDeliveryAsMain}
                      checked={deliveryAsMain}
                      color="primary"
                    />
                  }
                  label={t("labels.create_customer.addresses_form.delivery_as_main")}
                />
                <FormControlLabel
                  disabled={!customer}
                  control={
                    <Checkbox
                      onChange={handleMakeAsDefault}
                      checked={isDefaultAddress}
                      color="primary"
                    />
                  }
                  label={"Make as default Delivery address"}
                />
              </div>
              <div className={classes.btnGroup}>
                <Button
                  disabled={submitDisabled}
                  className={classes.submitBtn}
                  type="submit"
                  ref={submitButtonRef}
                >
                  {t("labels.create_customer.addresses_form.save")}
                </Button>
                <Button onClick={handleModalClose} className={classes.cancelBtn}>
                  {t("labels.create_customer.customer_form.cancel")}
                </Button>
              </div>
            </form>
          )}
          {submitted && (
            <SuccessNotification
              message={t("labels.details.delivery_address_success")}
              onClose={handleModalClose}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateDeliveryAddress;
