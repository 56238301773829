
import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const WarningIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="rgba(244, 206, 4, 1)"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" fill="rgba(244, 206, 4, 1)" clipRule="evenodd" d="M7.69066 1.73717L1.20522 12.5641C0.933125 13.0353 0.9315 13.6155 1.20095 14.0882C1.47041 14.561 1.97047 14.8552 2.51456 14.8612H15.4854C16.0295 14.8552 16.5296 14.561 16.799 14.0882C17.0685 13.6155 17.0669 13.0353 16.7948 12.5641L10.3093 1.73717C10.0317 1.2795 9.53529 1 9 1C8.46471 1 7.96828 1.2795 7.69066 1.73717Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 5.67285V8.73563" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 11L9 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
);
export default WarningIcon;
