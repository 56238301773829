import React, { useCallback, useState } from "react";
import { CreateCustomerForm } from "./CreateCustomerForm";
import SuccessCustomerCreate from "./SuccessCustomerCreate";
import SuccessAddressCreate from "./SuccessAddressCreate";
import SuccessAddressCreateBusiness from "./SuccessAddressCreateBusiness";
import SuccessMembersCreate from "./SuccessMembersCreate";
import CreateAddressesForm from "./CreateAddressesForm";
import { CreateHouseholdMembers } from "./CreateHouseholdMembers";
import { Container, Grid } from "@material-ui/core";
import { Customer } from "src/types";
import Modal from "src/components/Modal";
import { useAppContext } from "src/providers/AppProvider";
// import { useCustomerContext } from "src/providers/CustomerStore";

const initialState = {
  key: 0,
  firstName: "",
  lastName: "",
  mobile: "",
  altPhone: "",
  email: "",
  address: "",
  ssn: "",
  notes: "",
  city: { key: undefined, displayText: "" },
  province: { key: undefined, displayText: "" },
  zipCode: { key: undefined, displayText: "" },
  country: { key: undefined, displayText: "" },
  isBusiness: false,
};

interface Props {
  onCustomerSubmit?: (customerData: Customer) => void;
}

const CreateCustomer: React.FC<Props> = ({ onCustomerSubmit }) => {
  const [customerData, setCustomerData] = useState<Customer>(initialState);
  const [customerSubmitted, setCustomerSubmitted] = useState<boolean>(false);
  const [proceedToAddressCreation, setProceedToAddressCreation] = useState<boolean>(false);
  const [addressSubmitted, setAddressSubmitted] = useState<boolean>(false);
  const [proceedToHouseholdCreation, setProceedToHouseholdCreation] = useState<boolean>(false);
  const [householdMembersSubmitted, setHouseholdMembersSubmitted] = useState<boolean>(false);

  const {
    state: {
      modals: { createCustomerModal },
      user,
    },
    callAppAPI,
  } = useAppContext();

  const handleModalClose = useCallback(() => {
    callAppAPI.toggleModal({ id: "createCustomerModal", status: false });
    customerSubmitted && onCustomerSubmit && onCustomerSubmit(customerData);
  }, [customerSubmitted, customerData]);

  const handleAddressCreation = () => {
    setProceedToAddressCreation(true);
  };

  const handleCustomerSuccess = (customer: Customer) => {
    setCustomerSubmitted(true);
    setCustomerData(customer);
  };

  const handleAddressSuccess = () => {
    setAddressSubmitted(true);
    callAppAPI.toggleModal({ id: "createCustomerModal", status: false });
    onCustomerSubmit && onCustomerSubmit(customerData);
  };

  const handleHouseholdCreation = () => {
    setProceedToHouseholdCreation(true);
  };

  const handleHouseholdSuccess = () => {
    setHouseholdMembersSubmitted(true);
  };

  return (
    <Modal isOpen={createCustomerModal} onClose={handleModalClose}>
      <Container>
        <Grid container justify="center">
          <Grid item>
            {/* CREATE CUSTOMER STEP 1: Customer Creation */}
            {!customerSubmitted && (
              <CreateCustomerForm afterSubmit={handleCustomerSuccess} user={user} />
            )}

            {/* CUSTOMER CREATION SUCCESS CARD */}
            {customerSubmitted && !proceedToAddressCreation && (
              <SuccessCustomerCreate
                completeProfile={handleAddressCreation}
                handleModalClose={handleModalClose}
                isBusiness={customerData.isBusiness!}
                user={user}
              />
            )}

            {/* CREATE CUSTOMER STEP 2: Create Invoice & Delivery Address */}
            {proceedToAddressCreation && !addressSubmitted && (
              <CreateAddressesForm
                customerData={customerData}
                afterSubmit={handleAddressSuccess}
                handleModalClose={handleModalClose}
              />
            )}
            {/* SUCCESS IF CUSTOMER NOT A BUSINESS AND USER IS FROM SWEDEN TERRITORY */}
            {addressSubmitted &&
              !proceedToHouseholdCreation &&
              !customerData.isBusiness &&
              (user.territory?.alpha2 === "sv" || user.territory?.alpha2 === "se") && (
                <SuccessAddressCreate completeProfile={handleHouseholdCreation} />
              )}

            {/* SUCCESS IF CUSTOMER IS A BUSINESS */}
            {addressSubmitted &&
              !proceedToHouseholdCreation &&
              (customerData.isBusiness ||
                (user.territory?.alpha2 !== "sv" && user.territory?.alpha2 !== "se")) && (
                <SuccessAddressCreateBusiness />
              )}
            {/* CREATE CUSTOMER STEP 3: Customer Household Members */}
            {proceedToHouseholdCreation && !householdMembersSubmitted && (
              <CreateHouseholdMembers
                customerData={customerData}
                afterSubmit={handleHouseholdSuccess}
              />
            )}

            {/* Household CREATION SUCCESS CARD */}
            {householdMembersSubmitted && <SuccessMembersCreate />}
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default CreateCustomer;
