import { makeStyles, Theme } from "@material-ui/core/styles";
import { theme } from "src/utils/styleTheme/mainStyles";
import { StyleProps } from "./";
export const useStyles = makeStyles<Theme, StyleProps>(() => ({
  phoneInputWrapper: {
    display: "inline-block",
    width: "100%",
  },
  phoneInputField: {
    "&:focus": {
      borderColor: "#141e30 !important",
      boxShadow: "0 0 0 1px #141e30 !important",
    },
  },
  buttonStyle: ({ label, labelWidth }) => ({
    backgroundColor: "transparent !important",
    border: "none !important",
    "&::before": {
      content: `'${label}' !important`,
      width: `${labelWidth}px !important`,
      left: 8,
      position: "absolute",
      top: -8,
      whiteSpace: "nowrap",
      color: "rgba(20,30,48,0.5) !important",
      fontSize: 13,
      padding: "0px 6px",
      backgroundColor: "#fff",
      display: "block !important",
    },
    "&>.selected-flag": {
      background: "transparent !important",
    },
  }),
  errorText: {
    margin: `${theme.spacing(1)}px 0px`,
  },
}));
