import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { requestGetUnprefixed } from "src/utils/crud";
import { useStyles } from "../../styles";
import { useTranslation } from "react-i18next";

interface ExternalIDLookupResponse {
  lookups: string[];
}

const ServiceOrderExternalIdInput = () => {
  const { setValue, register, watch } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [orderIds, setOrderIds] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("orders");
  const handleClose = () => setOpen(false);
  const { chip } = useStyles();
  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
    requestGetUnprefixed<ExternalIDLookupResponse>("PartnerPortal/ServiceOrders/lookups/ext?property=externalId")
      .then((response) => {
        setLoading(false);
        setOrderIds(response.data.lookups);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  const onChangeHandler = (value: string[]) => {
    setValue("externalIds", value);
  };
  React.useEffect(() => {
    register({ name: "externalIds" });
  }, [open]);

  return (
    <Autocomplete<string, true, false, false>
      multiple
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      loading={loading}
      options={orderIds}
      onChange={(_, value) => onChangeHandler(value)}
      value={watch("externalIds") || []}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color="primary"
            label={option}
            classes={{ root: chip }}
            {...getTagProps({ index })}
          />
        ))
      }
      filterSelectedOptions
      limitTags={2}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("list_filter.so_external_id_label")}
          placeholder={t("list_filter.so_external_id_label")}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={16} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default ServiceOrderExternalIdInput;
