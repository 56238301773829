import React from 'react';
import { useStyles } from './styles';
import { Grid, SnackbarContent } from '@material-ui/core';
import WarningIcon from './icons/WarningIcon';
import { useTranslation } from 'react-i18next';

interface Props {
    status: string
}
const ACTIVATE_FOR = [
    "INCOMPLETE"
]

const SnackbarNotification:React.FC<Props> = ({status}: Props): null|JSX.Element => {
    const { t } = useTranslation("orders");
    const classes = useStyles();

    if(ACTIVATE_FOR.indexOf(status) === -1) {
        return null;
    }
    return (
        <SnackbarContent
            elevation={1}
            className={classes.root}
            message={(
                <Grid container wrap="nowrap" alignItems="center" >
                    <div className={classes.wrapper}><WarningIcon /></div>
                    <div className={classes.message}>
                        {t("labels.details.warning")}
                    </div>
                </Grid>
            )}
        />
    )
}

export default React.memo(SnackbarNotification);