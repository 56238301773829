import React, { useCallback, useState } from "react";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import Container from "@material-ui/core/Container/Container";
import IconButton from "@material-ui/core/IconButton";
import { InvoiceAddress } from "src/types";
import { useCustomerDetailsContext } from "src/providers/CustomerDetailsStore";
import { useAppContext } from "src/providers/AppProvider";
import { columns } from "./InvoiceAddressTable";
import MainTable from "src/components/MainTableWithActions";
import Typography from "@material-ui/core/Typography/Typography";
import { useTranslation } from "react-i18next";
import CreateInvoiceAddress from "../../components/CreateInvoiceAddress";
import EditInvoiceAddress from "../../components/EditInvoiceAddress";
import EditIcon from "../../components/icons/EditIcon";
import { theme } from "src/utils/styleTheme/mainStyles";
import DeleteAddressConformationDialog from "../../components/DeleteAddressConformationDialog";
import { useParams } from "react-router-dom";
import { CustomerDetailsType } from "../../type";
import { Grid } from "@material-ui/core";
import DeleteIcon from "../../components/icons/DeleteIcon";

const InvoiceAddressesPage = () => {
  const { customerID } = useParams<CustomerDetailsType>();
  const { t } = useTranslation(`customers`);
  const [invoiceAddressEdit, setInvoiceAddressEdit] = useState<Partial<InvoiceAddress>>({});
  const {
    invoiceAddresses: { invoiceAddresses, loading, currentPage, pageSize, totalCount },
    callAPI: { setContextInvoiceAddressesData, getInvoiceAddresses, deleteInvoiceAddress },
  } = useCustomerDetailsContext();
  const {
    callAppAPI: { toggleModal },
    state: { user },
  } = useAppContext();
  const [deletingAddressId, setDeletingAddressId] = useState<number | null>(null);
  const handleEditInvoiceAddressModal = useCallback((data: Partial<InvoiceAddress>) => {
    toggleModal({ id: "editInvoiceAddressModal", status: true });
    setInvoiceAddressEdit(data);
  }, []);

  const handleDeleteInvoiceAddressModal = (data: Partial<InvoiceAddress>) => {
    setDeletingAddressId(data.key!);
  };

  const isIntegration = user?.partner?.isIntegration;

  let rowActions: ((data: Partial<InvoiceAddress>) => JSX.Element) | undefined;
  if (!isIntegration) {
    rowActions = (data: Partial<InvoiceAddress>) => {
      if (!data.createdByPartner) {
        return <></>;
      }
      return (
        <Grid container>
          <IconButton onClick={() => handleEditInvoiceAddressModal(data)}>
            <EditIcon style={{ width: 16, height: 16 }} />
          </IconButton>
          {!data.isUsed && (
            <IconButton onClick={() => handleDeleteInvoiceAddressModal(data)}>
              <DeleteIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          )}
        </Grid>
      );
    };
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    event?.preventDefault();
    setContextInvoiceAddressesData("currentPage", page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContextInvoiceAddressesData("pageSize", Number(event.target.value));
  };

  const onAcceptDelete = async () => {
    if (deletingAddressId && customerID) {
      deleteInvoiceAddress(customerID, deletingAddressId).then((status) => {
        status && getInvoiceAddresses(Number(customerID));
        setDeletingAddressId(null);
      });
    }
  };

  const onCancelDelete = () => {
    setDeletingAddressId(null);
  };

  if (loading) {
    return <PreloaderLine />;
  }
  return (
    <Container style={{ margin: 0, padding: 0, paddingBottom: theme.spacing(2) }} maxWidth={"xl"}>
      <Typography variant="h3" paragraph={true} style={{ marginTop: theme.spacing(2) }}>
        {t("labels.details.invoice_addresses")}
      </Typography>
      <PreloaderLine loading={loading} />
      <MainTable<InvoiceAddress>
        data={invoiceAddresses}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={totalCount}
        textAlign="left"
        columnsData={columns}
        rowActions={rowActions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <CreateInvoiceAddress />
      <EditInvoiceAddress data={invoiceAddressEdit} />
      {deletingAddressId && (
        <DeleteAddressConformationDialog
          onAccept={onAcceptDelete}
          onCancel={onCancelDelete}
          subtitle={t("delete_address_confirmation_dialog.sub_title_ia")}
        />
      )}
    </Container>
  );
};

export default InvoiceAddressesPage;
