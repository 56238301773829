import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AlertIcon = () => {
  return (
    <SvgIcon width="56" height="56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg>
        <path
          d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z"
          fill="#FB3640"
        />
        <path
          opacity=".2"
          d="M16.253 40.63l15.104 15.105c12.72-1.502 22.789-11.483 24.379-24.202L40.543 16.341l-24.29 24.29z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M39.382 36.05c1.237 1.236 1.237 3.356 0 4.593l-.606.606c-1.237 1.237-3.357 1.237-4.593 0L14.75 21.817c-1.237-1.237-1.237-3.357 0-4.593l.694-.695c1.237-1.237 3.357-1.237 4.594 0l19.343 19.52z"
          fill="#fff"
        />
        <path
          d="M29.137 25.715l-5.288 5.288L34.183 41.25c1.237 1.237 3.356 1.237 4.593 0l.695-.695c1.237-1.236 1.237-3.356 0-4.593L29.137 25.715z"
          fill="#D6D6D6"
        />
        <path
          d="M34.671 16.483c1.237-1.237 3.357-1.237 4.593 0l.695.695c1.237 1.237 1.237 3.356 0 4.593L20.44 41.115c-1.237 1.236-3.357 1.236-4.594 0l-.606-.607c-1.237-1.236-1.237-3.356 0-4.593l19.432-19.432z"
          fill="#fff"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="26.25"
            y1="26.25"
            x2="45.938"
            y2="49"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#051C2C" />
            <stop offset="1" stopColor="#051C2C" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
export default AlertIcon;
