import React, { useEffect, useMemo, useState } from "react";

import { Typography, IconButton, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useStyles } from "src/containers/Comments/components/ThreadsAndChat/style";
import Divider from "@material-ui/core/Divider";
import { Block, SaveOutlined } from "@material-ui/icons";
import AddThread from "src/containers/Comments/components/ThreadsAndChat/Chat/AddThread";
import Messages from "src/containers/Comments/components/ThreadsAndChat/Chat/Messages";
import isEmpty from "lodash/fp/isEmpty";
import { capitalize } from "src/containers/Comments/components/ThreadsAndChat/Threads/Thread";
import edit from "../../../images/edit.svg";

import ReadAllIcon from "../../../images/ReadAllIcon";
import { difference } from "lodash";
import { useCommentsContext } from "src/providers/CommentsStore";
import MonteraSubNavigation from "src/components/MonteraSubNavigation";
import { theme } from "src/utils/styleTheme/mainStyles";
import { useTranslation } from "react-i18next";
import CommentsDialog from "../../CommentsDialog";
import CommentsFileList from "./CommentFileList";
import Preloader from "src/components/Preloader";

const navItems = [
  {
    id: 0,
    label: "Comments",
    counter: false,
    count: 0,
    content: <Messages />,
  },
  {
    id: 1,
    label: "Files",
    counter: false,
    count: 0,
    content: <CommentsFileList />,
  },
];

const Chat = () => {
  const { t } = useTranslation("comments");
  const { callAPI, comments } = useCommentsContext();
  const {
    newThread,
    topicDataIsValid,
    isAddNewThread,
    chatTab = 0,
    readedMessages,
    chatThread,
    chatchannels,
    selectedChannel,
    threadsSelected,
  } = comments;
  const { header, massages, actions } = useStyles();

  const addTopic = () => {
    if (!isEmpty(newThread)) {
      callAPI.addThread();
    }
  };
  const cancelTopic = () => {
    callAPI.setContextData("newThread", {});
    callAPI.setContextData("isAddNewThread", false);
  };

  const [readAllOpen, setReadAllOpen] = useState(false);
  const handleReadAllOpen = () => {
    setReadAllOpen(true);
  };
  const handleReadAllAction = (submit: boolean) => {
    if (submit) {
      onReadAllMessages();
    }
    setReadAllOpen(false);
  };

  useEffect(() => {
    const refreshIntervalId = setInterval(() => {
      threadsSelected?.key &&
        callAPI.getThreadMsgs(threadsSelected?.key, `${chatchannels[selectedChannel].key}`, false);
    }, 10000);
    return () => {
      clearInterval(refreshIntervalId);
    };
  }, [chatThread, selectedChannel]);

  useEffect(() => {
    if (readedMessages.length) {
      callAPI.postReadedComments(readedMessages);
    }
  }, [readedMessages]);

  const hasUnreadMessaged = useMemo(() => {
    const { messages } = chatThread;
    if (!messages) {
      return false;
    }
    if (!messages.length) {
      return false;
    }

    return messages.some((x) => x.isUnread);
  }, [chatThread.messages]);

  const OpenEditThread = () => {
    callAPI.setContextData("openEditThread", true);
  };

  const handleTabChange = (newValue: string) => {
    callAPI.setContextData("chatTab", newValue);
  };

  const onReadAllMessages = () => {
    const { messages } = chatThread;
    if (!messages) {
      return;
    }
    if (!messages.length) {
      return;
    }

    const messageKeys = messages.map((x) => x.key);

    callAPI.setDataCallback("readedMessages", (prev: any) => {
      const unreadMessages = difference(messageKeys, prev);
      if (!unreadMessages.length) {
        return prev;
      }
      return [...prev, ...unreadMessages];
    });
  };
  if (comments.loading.msgs) {
    return <Preloader is />;
  }
  return (
    <div className={massages}>
      <div className={header}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography align="left" variant="body1" style={{ marginLeft: theme.spacing(1) }}>
              {!!chatThread && !isAddNewThread ? capitalize(chatThread.title) : ""}
            </Typography>
          </Grid>
          {!isAddNewThread && (
            <Grid item>
              <MonteraSubNavigation
                navItems={navItems}
                handleTabChange={handleTabChange}
                currentTab={chatTab}
              />
            </Grid>
          )}
        </Grid>
        <div className={actions}>
          {!isAddNewThread && (
            <>
              {hasUnreadMessaged && (
                <IconButton color="primary" onClick={handleReadAllOpen} aria-label="read">
                  <ReadAllIcon />
                </IconButton>
              )}
              <IconButton onClick={OpenEditThread} aria-label="edit">
                <img src={edit} alt="edit" />
              </IconButton>
            </>
          )}
        </div>
        {isAddNewThread && (
          <>
            <Button
              variant="text"
              size={"small"}
              onClick={addTopic}
              disabled={!topicDataIsValid}
              style={{ padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}
              startIcon={<SaveOutlined />}
            >
              {t("labels.save", { ns: "common" })}
            </Button>
            <Button
              variant="text"
              size={"small"}
              onClick={cancelTopic}
              style={{
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                marginLeft: theme.spacing(1),
              }}
              startIcon={<Block />}
            >
              {t("labels.cancel", { ns: "common" })}
            </Button>
          </>
        )}
      </div>
      <Divider orientation="horizontal" variant={"fullWidth"} />
      {isAddNewThread ? <AddThread /> : navItems[chatTab].content}
      <CommentsDialog
        open={readAllOpen}
        title={
          <>
            {t("labels.mark_all_comments_as_read")}
            <br />
            <br />
            <Typography variant="body1">{t("labels.mark_all_comments_as_read_2")}</Typography>
          </>
        }
        okText={t("labels.yes", { ns: "common" })}
        cancelText={t("labels.cancel", { ns: "common" })}
        action={handleReadAllAction}
      />
    </div>
  );
};

export default Chat;
