import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import EmptyFilesIcon from "src/pages/ServiceOrdersDetails/components/icons/EmptyFilesIcon";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 100,
    height: 100,
    marginTop: 20,
    marginBottom: 16,
  },
  title: {
    padding: theme.spacing(2),
  },
}));

const NoQuestionnaire: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  return (
    <div className={classes.wrapper}>
      <EmptyFilesIcon className={classes.icon} />
      <Typography variant="h3" className={classes.title}>
        {t("labels.details.questionnaire.no_questionnaire")}
      </Typography>
    </div>
  );
};

export default NoQuestionnaire;
