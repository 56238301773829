import React, { ReactElement } from "react";
import Pages from "./pages";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import Login from "./pages/Login";
import MainWrapper from "src/containers/MainWrapper";
import { Redirect, Route, Switch } from "react-router-dom";
import { msalConfig } from "src/utils/config/authConfig";
import Loading from "src/components/Suspense";
import { AppProvider } from "src/providers/AppProvider";
import { I18nextProvider } from "react-i18next";
import i18n from "src/utils/i18n";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "src/utils/styleTheme/mainStyles";
import { ServiceOrderStatusesProvider } from "./providers/ServiceOrderStatusesStore";
import { FeatureFlagProvider } from "./providers/FeatureFlagStore";

export const msalInstance = new PublicClientApplication(msalConfig);

const App = (): ReactElement => {
  return (
    <MsalProvider instance={msalInstance}>
      <AppProvider>
        <FeatureFlagProvider>
          <ServiceOrderStatusesProvider>
            <I18nextProvider i18n={i18n}>
              <ThemeProvider theme={theme}>
                <AuthenticatedTemplate>
                  <MainWrapper>
                    <Pages />
                  </MainWrapper>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/loading" component={Loading} />
                    <Redirect to="/" />
                  </Switch>
                </UnauthenticatedTemplate>
              </ThemeProvider>
            </I18nextProvider>
          </ServiceOrderStatusesProvider>
        </FeatureFlagProvider>
      </AppProvider>
    </MsalProvider>
  );
};

export default App;
