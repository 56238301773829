import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ActionsAddSO = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 0C0.447715 0 0 0.447715 0 1V7H7V0H1Z" fill="#65AFFF" />
    <path d="M9 0V7H16V1C16 0.447715 15.5523 0 15 0H9Z" fill="#65AFFF" />
    <path d="M16 9H9V16H15C15.5523 16 16 15.5523 16 15V9Z" fill="#65AFFF" />
    <path d="M7 16V9H0V15C0 15.5523 0.447715 16 1 16H7Z" fill="#65AFFF" />
  </SvgIcon>
);
export default ActionsAddSO;
