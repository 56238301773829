import React from "react";
import { Typography, List, ListItem, Grid } from "@material-ui/core";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";
import Preloader from "src/components/Preloader";
import { useStyles } from "src/pages/ServiceDetails/components/AssignedWorkers/styles";
import { uniqueId } from "lodash";
import { AssignedWorker as AssignedWorkerType } from "src/providers/ServiceDetailsStore/types";
import MonteraDateDisplay from "src/components/DisplayDate";

const AssignedWorkers = () => {
  const {
    assignedWorkers: { assignedWorkers, loading },
  } = useServiceDetailsContext();
  const classes = useStyles();
  if (!assignedWorkers || loading) {
    return <Preloader is />;
  }

  return (
    <div className={classes.contentWrap}>
      <List>
        {assignedWorkers &&
          assignedWorkers.length > 0 &&
          assignedWorkers.map((worker: Partial<AssignedWorkerType>) => (
            <ListItem key={uniqueId("workers")} className={classes.listItem}>
              <Grid container alignItems="center" wrap="nowrap" justify="space-between">
                <Grid item container direction="row" alignItems="center" wrap="nowrap">
                  <div className={classes.userLogo}>
                    {(worker.displayText || "").substring(0, 1)}
                    {(worker.displayText || "").split(" ")[1]?.substring(0, 1)}
                  </div>
                  <Grid item>
                    {!!worker.occupationCategories?.length ? (
                      worker?.occupationCategories.map((category, idx) => (
                        <Typography key={idx} variant="caption" color="textSecondary" align="left">
                          {`${category}, `}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="caption" color="textSecondary" align="left">
                        {worker?.displayText}
                      </Typography>
                    )}
                    {!!worker.tasks?.length &&
                      worker.tasks.map((el) => (
                        <div key={el.key}>
                          <Typography variant="caption" color="textPrimary">
                            <MonteraDateDisplay value={el.start_date} format="dd.MM.yyyy HH:mm" />
                            {" - "}
                            <MonteraDateDisplay value={el.end_date} format="dd.MM.yyyy HH:mm" />
                          </Typography>
                        </div>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          ))}
      </List>
    </div>
  );
};

export default AssignedWorkers;
