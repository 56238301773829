import React, { PropsWithChildren } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Card from "@material-ui/core/Card";
import { useStyles } from "./styles";
import SearchInlineIcon from "../../assets/icons/SearchInlineIcon";
import InfoIcon from "../../assets/icons/InfoIcon";

const PseudoLink = ({ children }: PropsWithChildren<{}>) => {
  const { questionItem } = useStyles();
  return (
    <Typography variant="body1" className={questionItem}>
      {children}
    </Typography>
  );
};

const StaffSupport = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3" style={{ padding: "12px 0" }}>
        Montera Plus Support Hub
      </Typography>
      <div className={classes.mainWrapper}>
        <Paper className={classes.mainContainer}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchInlineIcon />
            </div>
            <InputBase
              className={classes.searchText}
              placeholder="What do you need help with?"
              inputProps={{ "aria-label": "Search" }}
            />
          </div>
          <Typography variant="caption" style={{ color: "rgba(20, 30, 48, 0.5)" }}>
            E.g "create a service" or "assign workers" or "attach files"
          </Typography>
          <div className={classes.groupWrapper}>
            <div className={classes.questionGroup}>
              <Typography variant="h4" className={classes.cardSubtitle}>
                Getting Started
              </Typography>
              <PseudoLink>Confirming your account</PseudoLink>
              <PseudoLink>Changing the Email Address</PseudoLink>
              <PseudoLink>I can't sign in to my account</PseudoLink>
              <PseudoLink>Create a Service</PseudoLink>
              <PseudoLink>Create a Service Order</PseudoLink>
              <PseudoLink>Create a Work Order</PseudoLink>
            </div>

            <div className={classes.questionGroup}>
              <Typography variant="h4" className={classes.cardSubtitle}>
                Tools and Features
              </Typography>
              <PseudoLink>Contacting a Partner</PseudoLink>
              <PseudoLink>Report a problem with a Partner</PseudoLink>
              <PseudoLink>Refunds, Returns and Exchanges</PseudoLink>
              <PseudoLink>Using Messages on Montera</PseudoLink>
            </div>

            <div className={classes.questionGroup}>
              <Typography variant="h4" className={classes.cardSubtitle}>
                Become a Service Owner
              </Typography>
              <PseudoLink>Lorem Ipsum</PseudoLink>
              <PseudoLink>Lorem Ipsum</PseudoLink>
              <PseudoLink>Lorem Ipsum</PseudoLink>
              <PseudoLink>Lorem Ipsum</PseudoLink>
            </div>
            <div className={classes.questionGroup}>
              <Typography variant="h4" className={classes.cardSubtitle}>
                Category title
              </Typography>
              <PseudoLink>Article Title</PseudoLink>
              <PseudoLink>Article Title</PseudoLink>
              <PseudoLink>Article Title</PseudoLink>
              <PseudoLink>Article Title</PseudoLink>
            </div>
          </div>
        </Paper>
        <div className={classes.sideContent}>
          <Card className={classes.sideCard}>
            <div className={classes.cardTitle}>
              <InfoIcon />

              <Typography variant="h4" style={{ marginLeft: 8 }}>
                Info
              </Typography>
            </div>
            <div className={classes.cardContent}>
              We have now changed the lay portal in Norway so that we can use some features that we
              have available.
              <br />
              We have a new workflow which includes creation of Work ORders, Service Orders and
              Services!
              <br />
              Projects will be called Services in the new portal, all Services should be created
              under a Service Order.
              <br />
              <br />
              Work Orders are created for Big companies or where we have multiple Service Orders,
              this way it will be easier to track services that belong to one Partner/Customer which
              they belong to.
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default StaffSupport;
