import { theme } from "src/utils/styleTheme/mainStyles";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  addMoreButton: {
    padding: "15px 22px",
    margin: `${theme.spacing(1)}px 0px`,
  },
  container: {
    display: "grid",
    gridTemplateColumns: "minmax(300px, 1fr) 175px 175px 150px auto",
    gap: `${theme.spacing(2)}px`,
    alignItems: "center",
  },
}));
