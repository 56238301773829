import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const PartnerManagerIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 76 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{height: 80, width: "100%", margin: 10}}
    {...props}
  >
    <path d="M46.4514 10.7406H62.6294L60.786 3.23094C60.2275 0.956254 57.9308 -0.435153 55.6561 0.123285L44.746 2.80141L46.4514 10.7406Z" fill="#C5DBF2"/>
    <path d="M33.7684 5.49609L3.88594 12.8316C1.61126 13.39 0.219851 15.6867 0.778132 17.9614L11.7125 62.5048V14.5616C11.7125 12.4512 13.4231 10.7406 15.5334 10.7406H32.6417L33.7684 5.49609Z" fill="#C5DBF2"/>
    <path d="M15.5334 72.9372C15.0705 72.9372 14.6269 72.8548 14.2162 72.704L15.2141 76.769C15.7725 79.0437 18.0692 80.4351 20.3439 79.8767L48.6133 72.9372H15.5334Z" fill="#C5DBF2"/>
    <path d="M75.2217 62.0384L67.3806 30.0959V68.3301L72.114 67.1683C74.3887 66.61 75.7801 64.3133 75.2217 62.0384Z" fill="#C5DBF2"/>
    <path d="M63.5597 9.17812H47.7139L46.4337 3.21906H48.3419C49.2047 3.21906 49.9044 2.51937 49.9044 1.65656C49.9044 0.793743 49.2047 0.0940552 48.3419 0.0940552H30.7512C29.8883 0.0940552 29.1887 0.793743 29.1887 1.65656C29.1887 2.51952 29.8883 3.21906 30.7512 3.21906H32.6594L31.3792 9.17812H15.5336C12.5651 9.17812 10.1501 11.5931 10.1501 14.5616V69.1162C10.1501 72.0847 12.5651 74.4997 15.5336 74.4997H63.5597C66.5281 74.4997 68.9431 72.0847 68.9431 69.1162V14.5616C68.9431 11.5931 66.5281 9.17812 63.5597 9.17812ZM48.3422 17.7695V21.9623H30.7508V17.7695H48.3422ZM43.2373 3.21906L45.6919 14.6444H33.4012L35.8558 3.21906H43.2373ZM65.8181 69.1164C65.8181 70.3617 64.805 71.3748 63.5597 71.3748H15.5336C14.2883 71.3748 13.2751 70.3617 13.2751 69.1164V14.5616C13.2751 13.3162 14.2883 12.3031 15.5336 12.3031H30.708L30.205 14.6444H29.1883C28.3253 14.6444 27.6258 15.3441 27.6258 16.2069V23.5247C27.6258 24.3875 28.3253 25.0872 29.1883 25.0872H37.9841V32.8378C37.9841 33.7006 38.6837 34.4003 39.5466 34.4003C40.4094 34.4003 41.1091 33.7006 41.1091 32.8378V25.0872H49.9047C50.7675 25.0872 51.4672 24.3875 51.4672 23.5247V16.2069C51.4672 15.3441 50.7675 14.6444 49.9047 14.6444H48.8881L48.3852 12.3031H63.5595C64.8048 12.3031 65.818 13.3162 65.818 14.5616V69.1164H65.8181Z" fill="#1B3A7F"/>
    <path d="M56.8105 38.4375H22.2828C21.42 38.4375 20.7203 39.137 20.7203 40C20.7203 40.8628 21.42 41.5625 22.2828 41.5625H56.8105C57.6733 41.5625 58.373 40.8628 58.373 40C58.373 39.137 57.6733 38.4375 56.8105 38.4375Z" fill="#1B3A7F"/>
    <path d="M56.8105 49.3998H22.2828C21.42 49.3998 20.7203 50.0995 20.7203 50.9623C20.7203 51.8252 21.42 52.5248 22.2828 52.5248H56.8105C57.6733 52.5248 58.373 51.8252 58.373 50.9623C58.373 50.0995 57.6733 49.3998 56.8105 49.3998Z" fill="#1B3A7F"/>
  </SvgIcon>
);
export default PartnerManagerIcon;
