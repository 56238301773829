import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useStyles } from "src/pages/ServiceDetails/tabs/LineItems/components/Modals/styles";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { useTranslation } from "react-i18next";
import SuccessfulIcon from "src/pages/ServiceOrdersDetails/components/icons/SuccessfulIcon";
import { Typography } from "@material-ui/core";

function SuccessfulModal() {
  const {
    LIData: { modal },
    callLineItemsAPI,
  } = useLineItemsContext();
  const classes = useStyles();
  const { t } = useTranslation(`lineItems`);
  const handleClose = () => {
    callLineItemsAPI.handleModal({ open: "" });
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.successWrapper}
      open={modal.open === "successful"}
    >
      <DialogTitle>
        <div className={classes.iconWrapper}>
          <SuccessfulIcon className={classes.successIcon} />
        </div>
        <Typography variant="body1">{t("labels.popup.success")}</Typography>
      </DialogTitle>
      <DialogActions
        style={{
          padding: "16px 27px",
        }}
      >
        <Button
          style={{
            padding: "16px 22px",
            fontSize: 14,
            backgroundColor: "#2B59C3",
          }}
          type="submit"
          variant={"contained"}
          color={"primary"}
          onClick={handleClose}
          size={"large"}
        >
          {t("labels.ok", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SuccessfulModal;
