import React, { useState, useEffect } from "react";
import { requestDelete, requestGet } from "src/utils/crud";
import {
  CustomerDetailsState,
  ContextDetails,
  Props,
  RelatedOrdersState,
  DeliveryAddressesState,
  InvoiceAddressesState,
  DeliveryAddressesReq,
} from "./types";
import createCtx from "../../utils/createCtx";
import { useParams } from "react-router-dom";
import { CustomerDetailsType } from "../../pages/Customers/CustomerDetails/type";
import { InvoiceAddressReq } from "src/types";
import { AxiosResponse } from "axios";

const [useCtx, Provider] = createCtx<ContextDetails>();

const initialRelatedOrders: RelatedOrdersState = {
  serviceOrders: [],
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  totalCount: 0,
  loading: true,
  success: false,
};

const initialDelieryAddresses: DeliveryAddressesState = {
  deliveryAddresses: [],
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  totalCount: 0,
  loading: true,
  success: false,
};

const initialInvoiceAddresses: InvoiceAddressesState = {
  invoiceAddresses: [],
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  totalCount: 0,
  loading: true,
  success: false,
};

const initialSelectedCustomer: CustomerDetailsState = {
  customer: {
    key: 0,
    name: "",
    email: "",
    mobile: "",
    active: false,
    createdDate: "",
  },
  loading: true,
  success: false,
};

function CustomerDetailsProvider(props: Props): React.ReactElement {
  const [selectedCustomerStore, setSelectedCustomer] = useState(initialSelectedCustomer);
  const [relatedOrders, setRelatedOrders] = useState(initialRelatedOrders);
  const [deliveryAddresses, setDeliveryAddresses] = useState(initialDelieryAddresses);
  const [invoiceAddresses, setInvoiceAddresses] = useState(initialInvoiceAddresses);
  const { customer } = selectedCustomerStore;
  const { customerID } = useParams<CustomerDetailsType>();

  useEffect(() => {
    !!customerID && getCustomerDetails(customerID);
  }, []);

  useEffect(() => {
    if (customer.key !== 0 && !!customer.key) {
      getRelatedOrders(customer.key);
    }
  }, [customer.key, relatedOrders.pageSize, relatedOrders.currentPage]);

  useEffect(() => {
    if (customer.key !== 0 && !!customer.key) {
      getInvoiceAddresses(customer.key);
    }
  }, [customer.key, invoiceAddresses.currentPage, invoiceAddresses.pageSize]);

  useEffect(() => {
    if (customer.key !== 0 && !!customer.key) {
      getDeliveryAddresses(customer.key);
    }
  }, [customer.key, deliveryAddresses.currentPage, deliveryAddresses.pageSize]);

  const getRelatedOrders = (key: number | string) => {
    setCustomerLoading(true);
    requestGet(`Customers/${key}/serviceOrders`, {
      page: relatedOrders.currentPage,
      pageSize: relatedOrders.pageSize,
      sortBy: "name",
    })
      .then((res) => {
        setRelatedOrders((prev) => ({
          ...prev,
          ...res.data,
          pageSize: prev.pageSize,
          currentPage: prev.currentPage,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const getDeliveryAddresses = (key: number) => {
    setCustomerLoading(true);
    requestGet<DeliveryAddressesReq>(`Customers/${key}/deliveryAddresses`, {
      page: deliveryAddresses.currentPage,
      pageSize: deliveryAddresses.pageSize,
      sortBy: "name",
    })
      .then((res) => {
        setDeliveryAddresses((prev) => ({
          ...prev,
          ...res.data,
          pageSize: prev.pageSize,
          currentPage: prev.currentPage,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const getInvoiceAddresses = (key: number) => {
    setCustomerLoading(true);
    requestGet<InvoiceAddressReq>(`Customers/${key}/invoiceAddresses`, {
      page: invoiceAddresses.currentPage,
      pageSize: invoiceAddresses.pageSize,
      sortBy: "name",
    })
      .then((res) => {
        setInvoiceAddresses((prev) => ({
          ...prev,
          ...res.data,
          pageSize: prev.pageSize,
          currentPage: prev.currentPage,
          loading: false,
        }));
        setContextInvoiceAddressesData("totalCount", res.data.totalCount);
      })
      .catch((err) => console.log(err));
  };

  const getCustomerDetails = (key: number | string) => {
    setOrdersLoading(true);
    requestGet(`Customers/${key}`)
      .then((res) => {
        setSelectedCustomer((prev) => ({
          ...prev,
          ...res.data,
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const setCustomerLoading = (data: boolean) =>
    setSelectedCustomer((prev: CustomerDetailsState) => ({
      ...prev,
      loading: data,
    }));

  const setOrdersLoading = (data: boolean) =>
    setRelatedOrders((prev: RelatedOrdersState) => ({
      ...prev,
      loading: data,
    }));

  const setContextOrdersData = (key: string, data: any) => {
    setRelatedOrders((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const setContextDeliveryAddressesData = (key: string, data: any) => {
    if (key === "deliveryAddresses") {
      const newAddresses = deliveryAddresses.deliveryAddresses?.map((address) =>
        address.key === data.key ? data : address
      );
      setDeliveryAddresses((prev) => {
        return {
          ...prev,
          deliveryAddresses: newAddresses,
        };
      });
    } else if (key === "pageSize") {
      setDeliveryAddresses((prev) => {
        return {
          ...prev,
          currentPage: 1,
          pageSize: data,
        };
      });
    } else {
      setDeliveryAddresses((prev) => {
        return {
          ...prev,
          [key]: data,
        };
      });
    }
  };

  const setContextInvoiceAddressesData = (key: string, data: any) => {
    if (key === "invoiceAddresses") {
      const newAddresses = invoiceAddresses.invoiceAddresses?.map((address) =>
        address.key === data.key ? data : address
      );
      setInvoiceAddresses((prev) => {
        return {
          ...prev,
          invoiceAddresses: newAddresses,
        };
      });
    } else if (key === "pageSize") {
      setInvoiceAddresses((prev) => {
        return {
          ...prev,
          currentPage: 1,
          pageSize: data,
        };
      });
    } else {
      setInvoiceAddresses((prev) => {
        return {
          ...prev,
          [key]: data,
        };
      });
    }
  };

  const setContextData = (key: string, data: any) => {
    setSelectedCustomer((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const deleteDeliveryAddress = (
    customerKey: number | string,
    deliveryAddressKey: number | string
  ): Promise<boolean> => {
    return requestDelete<AxiosResponse>(
      `Customers/${customerKey}/DeliveryAddress/${deliveryAddressKey}`
    )
      .then((response) => {
        if (response.status === 200) return true;
        else return false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const deleteInvoiceAddress = (
    customerKey: number | string,
    inoviceAddressKey: number | string
  ): Promise<boolean> => {
    return requestDelete<AxiosResponse>(
      `Customers/${customerKey}/InvoiceAddress/${inoviceAddressKey}`
    )
      .then((response) => {
        if (response.status === 200) return true;
        else return false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const callAPI = {
    getCustomerDetails,
    setCustomerLoading,
    getDeliveryAddresses,
    getInvoiceAddresses,
    getRelatedOrders,
    setContextOrdersData,
    setContextDeliveryAddressesData,
    setContextInvoiceAddressesData,
    deleteDeliveryAddress,
    deleteInvoiceAddress,
    setContextData,
  };

  return (
    <Provider
      value={{
        selectedCustomer: selectedCustomerStore,
        relatedOrders: relatedOrders,
        deliveryAddresses: deliveryAddresses,
        invoiceAddresses: invoiceAddresses,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useCustomerDetailsContext, CustomerDetailsProvider };
