import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const FilterIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 1H1L7.4 8.568V13.8L10.6 15.4V8.568L17 1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default FilterIcon;
