import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  WithoutBorder: {
    height: 30,
    margin: 0,
    fontSize: 14,
    letterSpacing: 0,
    "& input + fieldset": {
      border: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
      },
    },
  }
}));
