import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DeliveryAddressIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.09126 16.9999C5.89459 16.9999 6.54581 16.3487 6.54581 15.5454C6.54581 14.742 5.89459 14.0908 5.09126 14.0908C4.28794 14.0908 3.63672 14.742 3.63672 15.5454C3.63672 16.3487 4.28794 16.9999 5.09126 16.9999Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.8178 16.9999C14.6212 16.9999 15.2724 16.3487 15.2724 15.5454C15.2724 14.742 14.6212 14.0908 13.8178 14.0908C13.0145 14.0908 12.3633 14.742 12.3633 15.5454C12.3633 16.3487 13.0145 16.9999 13.8178 16.9999Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.39636 4.63636H16L14.7782 10.7382C14.6409 11.4293 14.0281 11.9226 13.3236 11.9091H5.63636C4.90144 11.9153 4.27734 11.3724 4.18182 10.6436L3.07636 2.26545C2.98154 1.54225 2.36576 1.0011 1.63636 1H0"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
export default DeliveryAddressIcon;
