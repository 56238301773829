import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ServiceFieldsList from "./ServiceFieldsList";
import ServiceTable from "./ServiceTable";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import Container from "@material-ui/core/Container/Container";
import CommonList from "src/components/CommonList";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { MainPropsLIType } from "src/providers/LineItemsStore/types";
import ModalMenu from "../Modals/ModalMenu";

export const useStyles = makeStyles(() => ({
  detailsRoot: {
    paddingBottom: 0,
    marginLeft: 0,
  },
}));
const LiServiceList = () => {
  const classes = useStyles();
  const {
    LIData: {
      loading,
      lineItemsService: { lineItems },
    },
  } = useLineItemsContext();
  const data = !!lineItems ? [lineItems] : [];
  if (loading) {
    return <PreloaderLine />;
  }

  return (
    <Container style={{ margin: 0, padding: 0 }} maxWidth={"xl"}>
      <PreloaderLine loading={loading} />
      <CommonList<MainPropsLIType>
        openByDefault
        listEntities={data}
        entityFields={(ent) => <ServiceFieldsList data={ent} />}
        entityDetails={(ent) => <ServiceTable data={ent} />}
        classes={{ detailsRoot: classes.detailsRoot }}
      />
      <ModalMenu />
    </Container>
  );
};

export default LiServiceList;
