
import React from 'react';
import { Card, Grid,Link, Typography } from '@material-ui/core';

import { MainCardCollection } from '../../types';
import { Link as RouterLink } from 'react-router-dom';
import { useAnalitycsMainCard } from './styles';

export function AnalyticsCard(args:MainCardCollection): JSX.Element {
    const classes = useAnalitycsMainCard();
    return (
        <Card>
            <Grid container direction="row">
                <Grid item xs={9}>
                    <Link
                        component={RouterLink}
                        to={args.link}
                        underline="none"
                        color="initial"
                    >
                        <Typography variant="h6" className={classes.mainLink} >
                            {args.title}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={3} container justify="center">
                    {args.icon}
                </Grid>
            </Grid>
        </Card>
    )
}