import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  btnGroup: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  submitBtn: {
    minWidth: 120,
    padding: theme.spacing(2),
    fontSize: 14,
    color: "#ffff",
    backgroundColor: theme.palette.functionalColors.dark,
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.brandColors.dark,
    },
    "&:disabled": {
      opacity: 0.5,
      backgroundColor: theme.palette.functionalColors.dark,
    },
  },
  cancelBtn: {
    minWidth: 120,
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));
