import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BucketIcon: React.FC = () => (
  <SvgIcon
    width="16" 
    height="17" 
    viewBox="0 0 16 17" 
    xmlns="http://www.w3.org/2000/svg"
    style={{fill: "transparent"}}
  >
    <g opacity="0.5">
    <path d="M1 4H2.5H14.5" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.75 4V2.5C4.75 1.67157 5.42157 1 6.25 1H9.25C10.0784 1 10.75 1.67157 10.75 2.5V4M13 4V14.5C13 15.3284 12.3284 16 11.5 16H4C3.17157 16 2.5 15.3284 2.5 14.5V4H13Z" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </SvgIcon>
);

export default BucketIcon;
