import React from "react";

import Paper from "@material-ui/core/Paper/Paper";
import { PropsWithChildren } from "react";
import { Tabs } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavItemsType } from "../../pages/Warehouses/WarehouseDetails/type";
import { useTranslation } from "react-i18next";
import { SONavLinks } from "src/pages/ServiceOrdersDetails/type";

interface Props<T> {
  navItems: NavItemsType<T>[];
  selected: number;
  handleChange?: (event: React.ChangeEvent<any>) => void;
}

function a11yProps(index: number | string) {
  return {
    id: `${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  mainWrapper: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    marginTop: theme.spacing(2)
  },
  container: {
    width: "100%",
    height: "auto",
    padding: theme.spacing(1),
    paddingTop: 0
  },
  tabLabel: {
    fontSize: 14,
  },
  tab: {
    "&:hover": {
      backgroundColor: "rgba(43, 89, 195, 0.1)",
    },
  },
  tabs: {
    color: "#2B59C3",
  },
}));
function DetailsWrapper<D>({
  navItems,
  selected,
  handleChange,
  children,
}: PropsWithChildren<Props<D>>) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <Paper className={classes.mainWrapper}>
      <Tabs
        value={selected === -1 ? 0 : selected}
        variant="scrollable"
        scrollButtons="on"
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs"
        className={classes.tabs}
      >
        {navItems
          .sort((a, b) => a.id - b.id)
          .map((row: SONavLinks) => (
            <Tab
              className={classes.tab}
              label={
                <Typography variant="button">
                  {t(`labels.${row.label}`)}{" "}
                  {`${!!row.count || row.count === 0 ? `(${row.count})` : ""}`}
                </Typography>
              }
              {...a11yProps(row.path)}
              key={row.id}
            />
          ))}
      </Tabs>
      <Paper className={classes.container}>{children}</Paper>
    </Paper>
  );
}

export default DetailsWrapper;
