import React, { FC } from "react";
import MonteraFilePreview from "src/components/FilePreview";
import Modal from "src/components/Modal";
import { useQuestionnaireContext } from "src/providers/QuestionnaireStore";
import { ServiceFileQuestionnaire } from "src/providers/QuestionnaireStore/types";
import { FileQuestionnaireItem } from "src/types";
import QuestionnaireTable from "./QuestionnaireTable";
import ServiceQuestionnaireTable from "./ServiceQuestionnaireTable";
import { useStyles } from "./styles";

const QuestionnaireOrdersTable: FC = () => {
  const classes = useStyles();
  const {
    relatedQuestionnaireState: { fileQuestionnaires },
    previewState,
    callAPI,
  } = useQuestionnaireContext();

  const serviceOrderQuestionnaireTable = (SO_FileQuestionnaires: FileQuestionnaireItem[]) => (
    <QuestionnaireTable data={SO_FileQuestionnaires} />
  );

  const serviceQuestionnaireTable = (S_FileQuestionnaires: ServiceFileQuestionnaire[]) => (
    <ServiceQuestionnaireTable S_FileQuestionnaire_Items={S_FileQuestionnaires} />
  );

  const onClosePreview = () => {
    callAPI.setPreview(null);
  };
  const PreviewDialog = (): JSX.Element => {
    if (!!previewState?.previewType) {
      return (
        <Modal isOpen={!!previewState} onClose={onClosePreview}>
          <MonteraFilePreview
            className={classes.preview}
            imageClassName={classes.imageClassName}
            id={previewState.fileServiceId}
            type={previewState.previewType}
          />
        </Modal>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const displayTable = (propertyName: string): boolean => {
    return (
      !!fileQuestionnaires &&
      fileQuestionnaires.hasOwnProperty(propertyName) &&
      !!fileQuestionnaires.serviceOrderFileQuestionnaires.length
    );
  };
  return (
    <>
      {!!fileQuestionnaires &&
        displayTable("serviceOrderFileQuestionnaires") &&
        serviceOrderQuestionnaireTable(fileQuestionnaires.serviceOrderFileQuestionnaires)}
      {!!fileQuestionnaires &&
        displayTable("serviceOrderFileQuestionnaires") &&
        serviceQuestionnaireTable(fileQuestionnaires.serviceFileQuestionnaires)}
      <PreviewDialog />
    </>
  );
};

export default QuestionnaireOrdersTable;
