import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ContactPersonIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 1.47444V1.47465V14.5253V14.5256C14.5002 14.9869 14.0242 15.5002 13.2501 15.5H13.25H2.75H2.74985C1.97581 15.5002 1.49981 14.9869 1.5 14.5256V14.5253L1.5 1.47465L1.5 1.47444C1.49981 1.01309 1.97581 0.499769 2.74985 0.5H2.75H13.25L13.2501 0.5C14.0242 0.499769 14.5002 1.01309 14.5 1.47444Z"
      stroke="#141E30"
      fill="none"
    />
    <path
      d="M11 13C11 12.2044 10.6839 11.4413 10.1213 10.8787C9.55871 10.3161 8.79565 10 8 10C7.20435 10 6.44129 10.3161 5.87868 10.8787C5.31607 11.4413 5 12.2044 5 13L8 13H11Z"
      stroke="#141E30"
      fill="none"
    />
    <path
      d="M8.00001 5.07666C8.84952 5.07465 9.53962 5.76201 9.54106 6.61152C9.54235 7.46103 8.85442 8.15069 8.00491 8.15141C7.1554 8.15199 6.46631 7.46348 6.46631 6.61397C6.46545 5.76604 7.15194 5.07782 8.00001 5.07666Z"
      stroke="#141E30"
      fill="none"
    />
    <path
      d="M9.6 3.36768H6.4C6.17922 3.36768 6 3.14365 6 2.86768C6 2.5915 6.17922 2.36768 6.4 2.36768H9.6C9.82094 2.36768 10 2.5915 10 2.86768C10 3.14365 9.82094 3.36768 9.6 3.36768Z"
      fill="#141E30"
    />
  </SvgIcon>
);
export default ContactPersonIcon;
