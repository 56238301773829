import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CustomersIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6364 14.0909V12.6364C11.6364 11.0297 10.3339 9.72729 8.72727 9.72729H2.90909C1.30244 9.72729 0 11.0297 0 12.6364V14.0909"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6366 12.6364V14.0909H15.6367V12.6364C15.6367 11.0297 14.3343 9.72729 12.7276 9.72729H10.7275C12.3342 9.72729 13.6366 11.0297 13.6366 12.6364Z"
      fill="white"
    />
    <path
      d="M5.81827 6.81818C7.42492 6.81818 8.72736 5.51574 8.72736 3.90909C8.72736 2.30244 7.42492 1 5.81827 1C4.21162 1 2.90918 2.30244 2.90918 3.90909C2.90918 5.51574 4.21162 6.81818 5.81827 6.81818Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.90918 6.64173C9.22102 6.75588 9.55784 6.81818 9.90922 6.81818C11.5159 6.81818 12.8183 5.51574 12.8183 3.90909C12.8183 2.30244 11.5159 1 9.90922 1C9.55784 1 9.22102 1.0623 8.90918 1.17645C10.0232 1.58425 10.8182 2.65382 10.8182 3.90909C10.8182 5.16436 10.0232 6.23393 8.90918 6.64173Z"
      fill="white"
    />
  </SvgIcon>
);

export default CustomersIcon;
