import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { useLocation } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography";
import { BreadCrumbsItemsType } from "./types";
import { getCustomBreadcrumb } from "./custom";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
        color: theme.palette.brandColors.main,
      },
    },
    paper: {
      padding: theme.spacing(1, 1),
      top: "63px",
      zIndex: 99,
    },
    linkStyles: {
      color: theme.palette.common.black,
      textTransform: "capitalize",
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 16,
      height: 16,
      marginTop: 4,
    },
  })
);

const routes: BreadCrumbsItemsType[] = [
  {
    path: "serviceOrders",
    redirect: "orders",
    text: "orders",
  },
  {
    path: "customers",
    text: "customers",
  },
  {
    path: "create",
    text: "add_customer",
  },
  {
    path: "details",
    text: "so_details",
  },
  {
    path: "orders",
    text: "orders",
  },
  {
    path: "services",
    text: "services",
  },
  {
    path: "sellers",
    text: "sellers",
  },
  {
    path: "warehouses",
    text: "warehouses",
  },
  {
    path: "profile",
    text: "profile",
  },
  {
    path: "analytics",
    text: "analytics",
  },
];
const initState = {
  path: "",
  text: "",
};

const BreadcrumbsWrapper: React.FC<{}> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" color={"primary"} />}
        aria-label="breadcrumb"
      >
        {children}
      </Breadcrumbs>
    </div>
  );
};

const CustomBreadcrumbs = React.memo(() => {
  const [paths, setPath] = useState<BreadCrumbsItemsType[]>([initState]);
  const { pathname } = useLocation<any>();
  const { t } = useTranslation("common");
  const classes = useStyles();
  useEffect(() => {
    const route: BreadCrumbsItemsType[] = [initState];
    pathname
      .slice(1)
      .split("/")
      .forEach((ent: string) => {
        routes.forEach((elem) => {
          if (`${elem.path}` === `${ent}`) {
            route.push(elem);
          }
        });
        setPath(route);
      });
  }, [pathname]);

  const customBreadcrumbs = getCustomBreadcrumb(pathname);

  if (customBreadcrumbs) {
    return (
      <BreadcrumbsWrapper>
        {customBreadcrumbs.map(({ redirect, text }) => (
          <Link
            component={RouterLink}
            key={uniqueId("links")}
            className={classes.linkStyles}
            to={`/${!!redirect ? redirect : pathname}`}
            underline="none"
          >
            <Typography>{t(`breadcrumbs.${text}`)}</Typography>
          </Link>
        ))}
      </BreadcrumbsWrapper>
    );
  }

  return (
    <BreadcrumbsWrapper>
      {paths.length > 0 &&
        paths.slice(1).map((el) => (
          <Link
            key={uniqueId("links")}
            component={RouterLink}
            underline="none"
            to={`/${!!el.redirect ? el.redirect : el.path}`}
          >
            <Typography>{t(`breadcrumbs.${el.text}`)}</Typography>
          </Link>
        ))}
    </BreadcrumbsWrapper>
  );
});
export default CustomBreadcrumbs;
