import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ArchiveIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.22812 0.139892C8.08077 0.0865481 7.91946 0.0865481 7.77212 0.139892L0.842773 2.65989L8.00012 5.38655L15.1575 2.65989L8.22812 0.139892Z"
      fill="#2B59C3"
    />
    <path
      d="M9 7.55949V15.9001L15.5614 13.3935C15.8247 13.2975 16.0001 13.0468 16.0001 12.7668V4.76614L9 7.55949Z"
      fill="#2B59C3"
      fillOpacity="0.5"
    />
    <path
      d="M0 12.7668C0 13.0468 0.175344 13.2975 0.438656 13.3935L7 15.9001V7.55949L0 4.76614V12.7668Z"
      fill="#2B59C3"
      fillOpacity="0.5"
    />
  </SvgIcon>
);

export default ArchiveIcon;
