import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormContext } from "react-hook-form";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSOContext } from "src/providers/ServiceOrdersStore";

const AutoCompleteDeliveryAddress: FC = () => {
  const [open, setOpen] = React.useState(false);
  const { register, setValue, watch,trigger } = useFormContext();
 
  // useSOContext
  const { 
    callSOAPI: { fetchDeliveryAddresses, setContextData }, 
    SOData: { DAOptions }
  } = useSOContext();
  const customer = watch("customer");
  const deliveryAddress = watch("deliveryAddress");
  const { t } = useTranslation("orders");

  register("deliveryAddress", {
    required: true,
  });

  React.useEffect(() => {
    register("deliveryAddress", {
      required: true,
    });
    if(customer?.key) {
      fetchDeliveryAddresses(customer.key)
        .then(response => {
          const defaultValue = response.find((el) => el.isDefault) ||
            (response.length === 1 && response[0]) || null;
          if (defaultValue) {
            setValue("deliveryAddress", defaultValue);
            trigger("deliveryAddress");
          }
        });
    } else {
      setContextData("DAOptions", []);
      setValue("deliveryAddress", null);
      trigger("deliveryAddress");
    }
  }, [ customer ]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={deliveryAddress || null}
      onChange={(_, newValue) => {
        setValue("deliveryAddress", newValue);
        trigger("deliveryAddress");
      }}
      getOptionSelected={(option, value) => option.displayText === value.displayText}
      getOptionLabel={(option) => option.displayText}
      options={DAOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          required={true}
          label={t("labels.creation.field_desc_delivery_addresses")}
          variant="outlined"
        />
      )}
    />
  );
};

export default AutoCompleteDeliveryAddress;
