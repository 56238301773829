import { makeStyles, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { OrdersFilterParams } from "src/providers/ServiceOrdersStore/types";
import FilterChip from "./filterChip";

const useStyles = makeStyles((theme) => ({
  filterGroup: {
    display: "flex",
    alignItems: "center",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface Props {
  name: keyof OrdersFilterParams;
  label: TFunction;
  item: any;
  onDelete: (name: keyof OrdersFilterParams) => void;
  format: (text: string) => string;
}

const FilterGroup: React.FC<Props> = ({ name, label, item, onDelete, format }) => {
  const classes = useStyles();
  return (
    <div className={classes.filterGroup}>
      <Typography noWrap={true}>{label}:</Typography>
      <FilterChip name={name} format={format} item={item} onDelete={onDelete} />
    </div>
  );
};

export default FilterGroup;
