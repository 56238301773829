import React, { useCallback, useMemo } from "react";
import { Typography } from "@material-ui/core";
import NoServicesIcon from "src/pages/Orders/components/icons/NoServicesIcon";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useAppContext } from "src/providers/AppProvider";
import { ServiceOrder } from "src/types";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    width: 100,
    height: 100,
    marginTop: 20,
    marginBottom: 16,
  },
  title: {
    padding: theme.spacing(2),
  },
  links: {
    cursor: "pointer",
    color: "#003882",
  },
}));

interface Props {
  SOData?: ServiceOrder;
}

const NoServices: React.FC<Props> = ({ SOData }) => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const { callSOAPI } = useSOContext();

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(SOData?.statusMatchingName);
  }, [SOData?.statusMatchingName]);

  const handleServiceCreate = useCallback((type: "single" | "bulk") => {
    if (disabledByStatus && !user?.partner?.isIntegration) {
      type === "single" && toggleModal({ id: "createServiceModal", status: true });
      type === "bulk" && toggleModal({ id: "createServiceBulkModal", status: true });
      callSOAPI.setContextData("responseObject", SOData);
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <NoServicesIcon className={classes.icon} />
      <Typography variant="h3" className={classes.title}>
        {t("labels.empty_state.title")}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {t("labels.empty_state.subtitle")}
        <Typography
          onClick={() => handleServiceCreate("single")}
          variant="subtitle1"
          display="inline"
          style={{
            color: disabledByStatus && !user?.partner?.isIntegration ? "#003882" : "inherit",
          }}
          className={classes.links}
        >
          {t("labels.empty_state.create_single")}
        </Typography>
        {t("labels.empty_state.subtitle_1")}
        <Typography
          onClick={() => handleServiceCreate("bulk")}
          variant="subtitle1"
          display="inline"
          style={{
            color: disabledByStatus && !user?.partner?.isIntegration ? "#003882" : "inherit",
          }}
          className={classes.links}
        >
          {t("labels.empty_state.create_in_bulk")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" display="inline">
          {t("labels.empty_state.subtitle_2")}
        </Typography>
      </Typography>
    </div>
  );
};

export default NoServices;
