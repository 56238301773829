import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(0.5),
    },
  })
);

const SharedIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classes.root}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M5.28571 1H2.11111C1.49746 1 1 1.49746 1 2.11111V11L3.22222 8.77778H9.88889C10.5025 8.77778 11 8.28032 11 7.66667V6.71429"
          stroke="#141E30"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.27271 1H11V3.72727"
          stroke="#141E30"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.71411 5.28571L10.9998 1"
          stroke="#141E30"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
export default SharedIcon;
