import React, { FC } from "react";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { ServiceOrder, Warehouse } from "src/types";
import MainTable from "../../../components/MainTable";
import { Trans } from "react-i18next";
import { UseTableRowProps } from "react-table";
import Link from "@material-ui/core/Link";
import { theme } from "../../../utils/styleTheme/mainStyles";
import { Link as RouterLink } from "react-router-dom";
import Preloader from "src/components/Preloader";
import format from "date-fns/format";
import { Typography } from "@material-ui/core";
import NoService from "src/pages/Warehouses/components/NoService";

export const columns = () => {
  return [
    {
      Header: () => <Trans ns={"warehouses"} i18nKey="labels.table.name" />,
      id: "name",
      Cell: ({ row }: { row: UseTableRowProps<Warehouse> }) => (
        <Link
          style={{
            color: theme.palette.functionalColors.dark,
            padding: theme.spacing(2),
          }}
          component={RouterLink}
          to={`serviceOrders/details/${row.original.id}/summary`}
        >
          {row.original.name}
        </Link>
      ),
    },
    {
      Header: () => <Trans ns={"warehouses"} i18nKey="labels.table.customer" />,
      id: "customer",
      accessor: (ent: Partial<ServiceOrder>) => (
        <Typography variant="body2">{ent.customer?.displayText}</Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.seller" ns={"warehouses"} />,
      id: "seller",
      accessor: (ent: Partial<ServiceOrder>) => (
        <Typography variant="body2">{ent?.seller?.displayText}</Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.qty_of_services" ns={"warehouses"} />,
      id: "qtyOfservices",
      accessor: (ent: Partial<ServiceOrder>) => (
        <Typography variant="body2">{ent.childCount}</Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.date_creation" ns={"warehouses"} />,
      id: "date_creation",
      accessor: (ent: Partial<ServiceOrder>) =>
        !!ent.createdDate ? (
          <Typography variant="body2">
            {format(new Date(ent.createdDate), "dd.MM.yyyy H:mm")}
          </Typography>
        ) : (
          ""
        ),
    },
    {
      Header: () => <Trans i18nKey="labels.table.status" ns={"warehouses"} />,
      id: "status",
      accessor: (ent: Partial<ServiceOrder>) => (
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            width: "fit-content",
            padding: 3,
            borderRadius: 5,
            backgroundColor: ent.statusColor,
          }}
        >
          {ent.statusName}
        </Typography>
      ),
    },
  ];
};

const WarehouseTableDetails: FC<{ data: Warehouse }> = ({ data }) => {
  const { SOData } = useSOContext();

  if (SOData.loading) {
    return <Preloader is />;
  }
  if (SOData.selectedSO[data.key]?.serviceOrders.length <= 0) {
    return <NoService />;
  }
  return (
    <MainTable<ServiceOrder>
      data={SOData.selectedSO[data.key]?.serviceOrders}
      columnsData={columns}
      pageSize={10000}
    />
  );
};

export default React.memo(WarehouseTableDetails);
