import React, { FunctionComponent } from "react";
import { LineItemsProvider } from "src/providers/LineItemsStore";
import PageActions from "./components/PageActions";
import RelatedService from "./components/RelatedService";
import PartnerItems from "./components/PartnerItems";
import { SODetailsProvider } from "src/providers/SODetailsStore";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import Container from "@material-ui/core/Container/Container";
import Divider from "@material-ui/core/Divider/Divider";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const LIDetails: FunctionComponent = () => {
  const { t } = useTranslation(`lineItems`);

  return (
    <Container
      style={{
        marginTop: -20,
        padding: 0,
      }}
      maxWidth={"xl"}
    >
      <PageActions />
      <Paper elevation={3} style={{ height: "auto", minHeight: 800 }}>
        <Grid container justify={"space-between"} direction={"row"} style={{ flexWrap: "nowrap" }}>
          <Grid item xs={6}>
            <Typography variant="h4" style={{ padding: "18px" }}>
              {t("labels.select_items")}
            </Typography>
            <PartnerItems />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs>
            <RelatedService />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const LIDetailsPage = () => {
  return (
    <SODetailsProvider>
      <LineItemsProvider>
        <LIDetails />
      </LineItemsProvider>
    </SODetailsProvider>
  );
};

export default LIDetailsPage;
