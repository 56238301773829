import { makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ImageGallery, { ReactImageGalleryProps } from "react-image-gallery";
import CarouselItem from "./CarouselItem";
import { FakeGalleryItem, MonteraFile } from "./types";
import { filesToCarouselItemsDictionary } from "./services";
import CarouselThumbnail from "./CarouselThumbnail";
import { useToolbar } from "./hooks/useToolbar";
import CarouselItemInfo from "./CarouselItemInfo";
import CarouselActions from "./CarouselActions";

import "react-image-gallery/styles/css/image-gallery.css";
import { useItemsData } from "./hooks/useItemsData";
import { useAppContext } from "src/providers/AppProvider";

type Props = {
  files: FakeGalleryItem[];
  open: boolean;
  renderAdditionalActions?: (file: MonteraFile) => any;
};

const Carousel = ({ files, open, renderAdditionalActions }: Props) => {
  const [startIndex, setStartIndex] = useState(0);
  const galleryRef = useRef<ImageGallery>(null);
  const itemsDataRefreshed = useRef(true);

  const { onBeforeSlide } = useToolbar(galleryRef);
  const { itemsData, getItemData, updateAllData, loadThumbs, tryLoadItem } = useItemsData();

  const { fullScreenMode, setFullScreenMode } = useAppContext();

  const onFullscreenToggle = () => {
    setFullScreenMode(!fullScreenMode);
  };

  const handleOnBeforeSlide: ReactImageGalleryProps["onBeforeSlide"] = (index: number) => {
    setStartIndex(index);
    onBeforeSlide && onBeforeSlide(index);
    tryLoadItem(files[index].id, files[index].fileServiceId);
  };

  useEffect(() => {
    if (fullScreenMode) {
      galleryRef.current?.fullScreen();
      return;
    }

    galleryRef.current?.exitFullScreen();
  }, [fullScreenMode]);

  useEffect(() => {
    if (itemsDataRefreshed.current) {
      return;
    }
    itemsDataRefreshed.current = true;

    // TODO: temporary
    // remove 'false &&' after thumbnails functionality will be done
    false && loadThumbs(files);
    loadFirstImage();
  }, [itemsData, files]);

  const loadFirstImage = () => {
    tryLoadItem(files[0].id, files[0].fileServiceId);
  };
  useEffect(() => {
    if (!open) {
      return;
    }
    itemsDataRefreshed.current = false;

    const items = filesToCarouselItemsDictionary(files);
    updateAllData(items);
    setStartIndex((prev) => Math.min(prev, files.length - 1));
  }, [files, open]);

  useEffect(() => {
    if (!open) {
      return;
    }
    return () => setFullScreenMode(false);
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }
    setStartIndex(0);
  }, [open]);

  const classes = useStyles({ fullScreen: fullScreenMode });

  const renderCustomControls = () => {
    const currentIndex = galleryRef.current?.getCurrentIndex();
    let currentFile: MonteraFile | undefined;
    if (currentIndex !== undefined) {
      currentFile = files[currentIndex];
    }

    if (!currentFile) {
      return null;
    }

    const itemData = getItemData(currentFile.id);

    if (!itemData) {
      return null;
    }

    return (
      <>
        <CarouselItemInfo file={currentFile} />
        <CarouselActions
          isFullscreen={fullScreenMode}
          onFullscreenToggle={onFullscreenToggle}
          renderAdditionalActions={renderAdditionalActions}
          file={currentFile}
        />
      </>
    );
  };

  const renderItem: ReactImageGalleryProps["renderItem"] | any = (item: FakeGalleryItem) => {
    const file = item as FakeGalleryItem;

    if (!file) {
      return null;
    }

    const itemData = getItemData(file.id);

    if (!itemData) {
      return null;
    }

    const onLoad = () => {};

    return <CarouselItem item={itemData} onLoad={onLoad} />;
  };

  const renderThumbInner: ReactImageGalleryProps["renderThumbInner"] | any = (
    item: FakeGalleryItem
  ) => {
    const file = item;
    if (!file) {
      return null;
    }

    const itemData = getItemData(file.id);

    if (!itemData) {
      return null;
    }
    return <CarouselThumbnail item={itemData} />;
  };

  if (!open) {
    return null;
  }

  return (
    <ImageGallery
      ref={galleryRef}
      additionalClass={classes.gallery}
      items={files}
      onBeforeSlide={handleOnBeforeSlide}
      renderItem={renderItem}
      renderThumbInner={renderThumbInner}
      showIndex={true}
      disableThumbnailScroll={true}
      showPlayButton={false}
      lazyLoad={true}
      renderCustomControls={renderCustomControls}
      showFullscreenButton={false}
      useBrowserFullscreen={false}
      // missed prop in types declaration
      {...{
        disableThumbnailSwipe: true,
      }}
      startIndex={startIndex}
    />
  );
};

type StyleProps = {
  fullScreen: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  gallery: ({ fullScreen }) => ({
    maxWidth: "100%",
    flex: 1,
    padding: fullScreen ? 0 : theme.spacing(1),
    "& .image-gallery-content": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: fullScreen ? "100%" : "calc(100vh - 80px - 56px)",
      padding: theme.spacing(1),
      background: "#222222",
      borderRadius: fullScreen ? 0 : 10,
    },
    "& .image-gallery-slide-wrapper, .image-gallery-swipe, .image-gallery-slides": {
      flex: 1,
      display: "flex !important",
    },
    "& .image-gallery-slide": {
      height: "100%",
      display: "flex",
    },
    "& .image-gallery-slide.left": {
      padding: theme.spacing(2),
    },
    "& .image-gallery-slide.right": {
      padding: theme.spacing(2),
    },
    "& .image-gallery-thumbnail > *": {
      minHeight: 64,
    },
    "& .image-gallery-thumbnails-wrapper": {
      position: "static",
      width: "100%",
      maxWidth: 1500,
      alignSelf: "center",
      overflow: "hidden",
    },
    "& .image-gallery-thumbnails-container": {
      position: "relative",
      overflow: "auto",
      padding: theme.spacing(1),
      transform: "none !important",
      cursor: "default",
    },
  }),
}));

export default Carousel;
