import React, { lazy, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography/Typography";
import Container from "@material-ui/core/Container/Container";
import Paper from "@material-ui/core/Paper/Paper";

import PreloaderLine from "src/components/Preloader/PreloaderLine";
import { ServicesProvider } from "src/providers/ServicesStore";
import { SellersProvider } from "src/providers/SellersStore";
import { WarehouseProvider } from "src/providers/WarehousesStore";
import { ServiceOrdersProvider, useSOContext } from "src/providers/ServiceOrdersStore";
import { CustomerProvider } from "src/providers/CustomerStore";
import PageActions from "./components/PageActions";
import Filter from "./components/Filter";
import Pagination from "./components/OrderList/Pagination";
import { useOrdersStyles } from "./styles";
import { useAppContext } from "src/providers/AppProvider";
import CreateService from "./components/CreateService";
import { SODetailsProvider } from "src/providers/SODetailsStore";
import CreateServiceInBulk from "./components/CreateServiceInBulk";
import OrdersSelectedFilters from "./components/SelectedFilters";
import { useHistory, useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Drawer from "@material-ui/core/Drawer";

type OrdersPathType = "ordersList" | "incompleteList";
const routeMap: OrdersPathType[] = ["ordersList", "incompleteList"];

export enum ListOrdersType {
  orders = "ordersList",
  incomplete = "incompleteList",
}

export interface OrdersRouteType {
  type: OrdersPathType;
}

const views = {
  [ListOrdersType.orders]: lazy(() => import("src/pages/Orders/components/OrderList")),
  [ListOrdersType.incomplete]: lazy(() => import("src/pages/Orders/components/IncompleteList")),
};

const Orders = () => {
  const { SOData, callSOAPI } = useSOContext();
  const {
    state: { modals, user },
  } = useAppContext();
  const isIntegration = user?.partner?.isIntegration;

  const { type } = useParams<OrdersRouteType>();
  const history = useHistory<OrdersRouteType>();
  const Component = views[type as ListOrdersType] || null;
  const [isFilterPanelOpen, setFilterPanelOpen] = useState(false);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    if (routeMap[newValue] === "incompleteList") {
      callSOAPI.setContextData("filters", { ...SOData.filters, statusTypeId: 6 });
    } else {
      callSOAPI.setContextData("filters", { ...SOData.filters, statusTypeId: "" });
    }
    history.push(routeMap[newValue]);
  };
  const { t } = useTranslation("orders");
  const isEmpty = SOData.serviceOrders.length === 0 && !SOData.loading;
  const classes = useOrdersStyles({ isFilterPanelOpen, isEmpty });

  return (
    <Container style={{ margin: 0 }} maxWidth={"xl"}>
      <Typography variant="h3">{t("labels.main.title")}</Typography>
      <PageActions toggleFilterOpen={() => setFilterPanelOpen(!isFilterPanelOpen)} />
      <div style={{ position: "relative" }}>
        <div className={classes.mainWrapper}>
          <PreloaderLine loading={SOData.loading} />
          <OrdersSelectedFilters />
          <Paper>
            <div className={classes.head}>
              <Tabs
                value={!!type && routeMap.indexOf(type) >= 0 ? routeMap.indexOf(type) : 0}
                indicatorColor="primary"
                textColor="primary"
                aria-label="disabled tabs example"
                onChange={handleChange}
              >
                <Tab label={t("labels.main.title")} />
                {!isIntegration && <Tab label={t("labels.main.incomplete_orders")} />}
              </Tabs>
            </div>
            <div className={classes.wrapper}>
              <div className={classes.offSet}>
                <Suspense fallback={<LinearProgress />}>{Component && <Component />}</Suspense>
              </div>
            </div>
            {!isEmpty && <Pagination />}
          </Paper>
        </div>
        {isFilterPanelOpen && (
          <Drawer
            variant="persistent"
            anchor="right"
            open={isFilterPanelOpen}
            className={classes.paper}
          >
            <Filter />
          </Drawer>
        )}
      </div>

      {modals.createServiceModal && <CreateService />}
      {modals.createServiceBulkModal && <CreateServiceInBulk />}
    </Container>
  );
};

const OrdersPage = () => (
  <SellersProvider lazy>
    <ServiceOrdersProvider defaultRequest>
      <SODetailsProvider>
        <ServicesProvider>
          <CustomerProvider lazy>
            <WarehouseProvider lazy>
              <Orders />
            </WarehouseProvider>
          </CustomerProvider>
        </ServicesProvider>
      </SODetailsProvider>
    </ServiceOrdersProvider>
  </SellersProvider>
);
export default OrdersPage;
