import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStyles } from "./styles";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { ServiceCreateReq } from "src/types";
import SelectInput from "src/components/SelectInput";
import ServiceTypeIcon from "./icons/ServiceTypeIcon";
import ServiceDateIcon from "./icons/ServiceDateIcon";
import DescriptionIcon from "../icons/DescriptionIcon";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "../SuccessNotification";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import DatePicker from "src/components/DatePicker";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { useServicesContext } from "src/providers/ServicesStore";
import TextArea from "src/components/TextArea";
import NumberFormatCustom from "src/components/NumberFormat";

const initialStateService = {
  serviceOrderId: 0,
  ownerId: 0,
  serviceTypeId: 0,
  startDate: "",
  deliveryDate: "",
  description: "",
  capacity: null,
};

const CreateService: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    serviceOrderDetails: { serviceOrder, partnerInfo },
    callAPI: { creatService },
    getServiceDefaultDeliveryDate,
  } = useSODetailsContext();
  const { startDate } = serviceOrder;

  const [service, setService] = useState<Partial<ServiceCreateReq>>(() => ({
    ...initialStateService,
    startDate,
    deliveryDate: getServiceDefaultDeliveryDate(),
  }));
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { createServiceModal },
    },
    callAppAPI,
  } = useAppContext();

  const {
    SOData: { responseObject },
    callSOAPI,
  } = useSOContext();
  const { callServicesAPI } = useServicesContext();
  const handleModalClose = useCallback(() => {
    callAppAPI.toggleModal({ id: "createServiceModal", status: false });
    setSubmitted(false);
  }, []);
  // Country Select
  const handleDropdown = (value: number) => {
    setService({
      ...service,
      serviceTypeId: value,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setService((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleChangeByFieldName = (fieldName: string, value: string | null) =>
    setService((state) => ({ ...state, [fieldName]: value }));

  const handleCapacityChange = (e: any) => {
    setService((prev) => ({ ...prev, capacity: e.target.floatValue || null }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    await creatService(service).then(() => {
      setSubmitted(true);
      setService(initialStateService);
      callSOAPI.setContextData("responseObject", null);
      callServicesAPI.fetchingServices(service.serviceOrderId!); // load the list of services
    });
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    if (
      service &&
      service.serviceOrderId &&
      service.ownerId &&
      service.serviceTypeId &&
      service.startDate &&
      service.deliveryDate
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [service]);

  useEffect(() => {
    setService((prev) => ({
      ...prev,
      serviceOrderId: responseObject?.id || serviceOrder.id,
      ownerId: responseObject?.owner?.key || serviceOrder.owner?.key,
    }));
  }, [responseObject, serviceOrder]);
  return (
    <Modal isOpen={createServiceModal} onClose={handleModalClose}>
      <Grid container justify="center" className={classes.fullWidth}>
        <Grid item className={classes.fullWidth}>
          {!submitted && (
            <form onSubmit={handleSubmit}>
              <Typography variant="h3"> {t("labels.creation.service.title")}</Typography>
              <div className={classes.fieldGroupFirst}>
                <div className={classes.titleWrapper}>
                  <ServiceTypeIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.creation.service.service_type")}
                  </Typography>
                </div>
                <SelectInput
                  required
                  onSelect={handleDropdown}
                  label={t("labels.creation.service.service_type")}
                  name="serviceTypeId"
                  value={service.serviceTypeId!}
                  url={`lookups/ServiceTypes?partner=${partnerInfo?.partner?.key}`}
                  metaApi
                />
              </div>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <ServiceDateIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.creation.service.service_date_range")}
                  </Typography>
                </div>
                <div className={classes.displayEven}>
                  <DatePicker
                    label={t("labels.creation.service.start_date")}
                    required
                    name="startDate"
                    value={service.startDate || null}
                    onChange={(value) => handleChangeByFieldName("startDate", value)}
                    maxDate={service.deliveryDate || undefined}
                  />
                  <DatePicker
                    style={{ marginLeft: 16 }}
                    label={t("form.delivery_date")}
                    required
                    name="deliveryDate"
                    value={service.deliveryDate}
                    onChange={(value) => handleChangeByFieldName("deliveryDate", value)}
                    minDate={service.startDate || undefined}
                  />
                </div>
              </div>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <Typography variant="h4" className={classes.fieldTitleNoIcon}>
                    {t("labels.creation.service.capacity")}
                  </Typography>
                </div>

                <TextField
                  variant="outlined"
                  fullWidth={true}
                  label={t("labels.creation.service.capacity")}
                  value={service.capacity}
                  onChange={handleCapacityChange}
                  name="capacity"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      decimalScale: 0,
                    },
                  }}
                />
              </div>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <DescriptionIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.creation.service.description_opt")}
                  </Typography>
                </div>
                <TextArea
                  value={service.description}
                  name="description"
                  onChange={handleChange}
                  label={t("labels.creation.service.description_opt")}
                  placeholder={t("form.description_ph")}
                />
              </div>
              <div className={classes.btnGroup}>
                <Button
                  disabled={submitDisabled}
                  className={classes.submitBtn}
                  type="submit"
                  ref={submitButtonRef}
                >
                  {t("labels.common.save")}
                </Button>
                <Button onClick={handleModalClose} className={classes.cancelBtn}>
                  {t("labels.common.cancel")}
                </Button>
              </div>
            </form>
          )}
          {submitted && (
            <SuccessNotification
              message={t("labels.creation.successful")}
              onClose={handleModalClose}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateService;
