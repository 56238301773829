import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
import { useStyles } from "src/pages/Warehouses/components/styles";
import { TFunction } from "i18next";
import { FieldListType } from "src/types";
import { MainPropsLIType } from "src/providers/LineItemsStore/types";
import LIServiceIcon from "src/pages/ServiceOrdersDetails/components/icons/LIServiceIcon";
import LinkTo from "src/components/LinkTo";
import useCurrencyFormat from "src/utils/hooks/currency";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

const dataList = (
  t: TFunction,
  { partnerSubtotal, customerSubtotal }: MainPropsLIType,
  other: any
): FieldListType[] => [
  {
    label: t("labels.qty"),
    value: other.prepareDigits(partnerSubtotal.qty + customerSubtotal.qty),
  },
  {
    label: t("labels.subtotal"),
    value: other.currencyFormat(partnerSubtotal.subtotal + customerSubtotal.subtotal),
  },
  {
    label: t("labels.subtotalVat"),
    value: other.currencyFormat(partnerSubtotal.subtotalVat + customerSubtotal.subtotalVat),
  },
];
const ServiceFieldsList: FC<{ data: MainPropsLIType }> = ({ data }) => {
  const classes = useStyles();
  const { currencyFormat, prepareDigits } = useCurrencyFormat();
  const link = useMemo(() => `/line_items/${data.service?.serviceOrderKey}/${data.service?.key}`, [
    data.service?.serviceOrderKey,
  ]);

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(data.service?.statusMatchingName);
  }, [data.service?.statusMatchingName]);

  const { t } = useTranslation(`common`);
  return (
    <Grid container direction="row" alignItems="center">
      <Grid
        item
        xs={2}
        container
        alignItems="center"
        justify="flex-start"
        wrap={"nowrap"}
        style={{ paddingLeft: 12 }}
      >
        <LIServiceIcon />
        {!disabledByStatus ? (
          <LinkTo
            color="primary"
            id="li-link"
            className={classes.link}
            href={link}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
            onFocus={(event: React.FocusEvent<HTMLAnchorElement>) => event.stopPropagation()}
          >
            <Typography variant="body2"> {data.service?.name}</Typography>
          </LinkTo>
        ) : (
          <Typography className={classes.link} variant="body2">
            {" "}
            {data.service?.name}
          </Typography>
        )}
      </Grid>
      <Grid item xs={6} key={uniqueId("lineItems")}>
        <Typography variant="caption">{t("labels.service_type")}</Typography>
        <Typography variant="body2">{data.service?.serviceType?.displayText}</Typography>
      </Grid>
      {dataList(t, data, { currencyFormat, prepareDigits }).map((ent: FieldListType) => (
        <Grid item xs key={uniqueId("lineItems")}>
          <Typography variant="caption">{ent.label}</Typography>
          <Typography variant="body2">{ent.value}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default ServiceFieldsList;
