import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
// import { useAppContext } from "src/providers/AppProvider";
import SuccessIcon from "src/pages/Orders/modals/EditForm/Success/icons/SuccessIcon";
import { useStyles } from "src/pages/Orders/modals/EditForm/Success/styles";
interface Props {
  onClose: () => void;
}

const SuccessModal: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item className={classes.itemWrapper}>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">Services status was successfully updated!</Typography>
        </div>

        <div className={classes.btnGroup}>
          <Button onClick={onClose} className={classes.submitBtn} type="submit">
            Ok
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessModal;
