import React, { useEffect, useState } from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import { requestGet } from "src/utils/crud";
import { useWDetailsContext } from "src/providers/WarehouseDetailsStore";
import MonteraSelect from "src/components/MonteraSelect";

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 4,
    },
  },
}));

interface WarehousesListType {
  key: number;
  displayText: string;
}


const WarehouseSelect: React.FC = () => {
  const { selectedWarehouse } = useWDetailsContext();
  const [options, setOptions] = useState<WarehousesListType[]>([]);

  useEffect(() => {
    let active = true;
    (async () => {
      const res = await requestGet<{ warehouses: WarehousesListType[] }>(
        `warehouses/lookups?partner=${selectedWarehouse?.warehouse?.partner?.key}`
      );
      if (active) {
        setOptions(res.data.warehouses);
      }
    })();

    return () => {
      active = false;
    };
  }, []);


  return (
    <MonteraSelect
      value={selectedWarehouse?.warehouse.key}
      disabled
    >
      {options.filter(option => Number(option.key) === Number(selectedWarehouse?.warehouse.key))
        .map(option => 
          <MenuItem aria-label="None" value={option.key} >
            {option.displayText}
          </MenuItem>
        )
      
      }
    </MonteraSelect>
  );
};

export default WarehouseSelect;
