import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CustomerIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4444 15V13.4444C14.4444 11.7262 13.0515 10.3333 11.3333 10.3333H5.11111C3.39289 10.3333 2 11.7262 2 13.4444V15"
      fill="#4ECDC4"
    />
    <path
      d="M8.22231 7.22221C9.94052 7.22221 11.3334 5.82932 11.3334 4.11111C11.3334 2.39289 9.94052 1 8.22231 1C6.50409 1 5.1112 2.39289 5.1112 4.11111C5.1112 5.82932 6.50409 7.22221 8.22231 7.22221Z"
      fill="#4ECDC4"
    />
  </SvgIcon>
);
export default CustomerIcon;
