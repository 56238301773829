import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DeliveryInfoIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6 2.6001H13.2C14.0837 2.6001 14.8 3.31644 14.8 4.2001V15.4001C14.8 16.2838 14.0837 17.0001 13.2 17.0001H3.6C2.71634 17.0001 2 16.2838 2 15.4001V4.2001C2 3.31644 2.71634 2.6001 3.6 2.6001H5.2"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.8002 1H6.0002C5.55837 1 5.2002 1.35817 5.2002 1.8V3.4C5.2002 3.84183 5.55837 4.2 6.0002 4.2H10.8002C11.242 4.2 11.6002 3.84183 11.6002 3.4V1.8C11.6002 1.35817 11.242 1 10.8002 1Z"
      stroke="#141E30"
      strokeLinecap="round"
      fill="none"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default DeliveryInfoIcon;
