import { ChannelItem } from "src/providers/CommentsStore/types";
import staff from "src/assets/icons/chenalImgs/staff.svg";
import partner from "src/assets/icons/chenalImgs/partner.svg";
import customer from "src/assets/icons/chenalImgs/customer.svg";
import worker from "src/assets/icons/chenalImgs/worker.svg";
import subcontractor from "src/assets/icons/chenalImgs/subcontractor.svg";
import publicImg from "src/assets/icons/chenalImgs/public.svg";

export const channelsList: ChannelItem[] = [];
channelsList[0] = { matchingText: "Staff", icon: staff, count: 0 };
channelsList[1] = { matchingText: "Partner", icon: partner, count: 0 };
channelsList[2] = { matchingText: "Worker", icon: worker, count: 0 };
channelsList[3] = { matchingText: "Customer", icon: customer, count: 0 };
channelsList[4] = { matchingText: "Public", icon: publicImg, count: 0 };
channelsList[5] = {
  matchingText: "Subcontractor",
  icon: subcontractor,
  count: 0,
};
