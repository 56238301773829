import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import AddServiceIcon from "../icons/AddServiceIcon";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ArrowDownIcon from 'src/pages/Analytics/icons/ArrowDownIcon';
import AddBulkServicesIcon from './icons/AddBulkServicesIcon';
import AddSingleServiceIcon from './icons/AddSingleServiceIcon';
import { useAppContext } from 'src/providers/AppProvider';
import { ModalsUnion } from "src/providers/AppProvider/types"
export const useStyles = makeStyles((theme) => ({
    outlineBtn: {
        padding: theme.spacing(2),
        color: "#2B59C3"
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));

const CreateServiceMenu: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation("orders");
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const {callAppAPI: { toggleModal }} = useAppContext();
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleServiceCreate = useCallback((modal:ModalsUnion) => {
        toggleModal({ id: modal, status: true });
      }, []);

    return (<>
        <Button
            startIcon={<AddServiceIcon />}
            endIcon={<ArrowDownIcon fill="#2B59C3" />}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.outlineBtn}
        >
            {t("labels.details.add_service")}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem onClick={() => handleServiceCreate("createServiceModal")}>
                                    <AddSingleServiceIcon className={classes.icon} />
                                    {t("labels.details.create_single")}
                                </MenuItem >
                                <MenuItem onClick={() => handleServiceCreate("createServiceBulkModal")}>
                                    <AddBulkServicesIcon className={classes.icon} />
                                    {t("labels.details.create_in_bulk")}
                                </MenuItem >
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>
    );
}


export default CreateServiceMenu;