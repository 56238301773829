import React from "react";
import { Typography } from "@material-ui/core";

interface Props {
  category: {
    key: number | string;
    displayText: string;
  };
}

const StatusItem: React.FC<Props> = ({ category }) => {
  return (
    <Typography variant="body2" className={`type_${category.key}`}>
      {category.displayText}
    </Typography>
  );
};

export default StatusItem;
