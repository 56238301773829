import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const NoServicesIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M160 80C160 124.183 124.183 160 80 160C35.8167 160 0 124.183 0 80C0 35.8167 35.8167 0 80 0C124.183 0 160 35.8167 160 80Z"
      fill="#141E30"
      fillOpacity="0.05"
    />
    <g opacity="0.3">
      <path
        d="M108 68.375V108C108 111.866 104.866 115 101 115H59C55.134 115 52 111.866 52 108V52C52 48.134 55.134 45 59 45H84.625V66C84.625 68.4162 84.5838 68.375 87 68.375H108Z"
        fill="#4ECDC4"
      />
      <path d="M87 66V45L108 66H87Z" fill="#4ECDC4" />
    </g>
    <rect opacity="0.5" x="59" y="80" width="46" height="12" rx="1" fill="#4ECDC4" />
    <rect x="56.5" y="76.5" width="46" height="12" rx="1.5" fill="white" stroke="#4ECDC4" />
    <rect
      x="56.5"
      y="97.5"
      width="46"
      height="12"
      rx="1.5"
      fill="#4ECDC4"
      fillOpacity="0.3"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="4 4"
    />
  </SvgIcon>
);
export default NoServicesIcon;
