import React, { useCallback } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/providers/AppProvider";
import Modal from "src/components/Modal";
import { useStyles } from "src/components/Modal/toggleModalBaseStyles";
import Button from "@material-ui/core/Button/Button";
import { useWDetailsContext } from "src/providers/WarehouseDetailsStore";

const ToggleActiveWarehouseModal = () => {
  const {
    selectedWarehouse: { warehouse, loading },
    callAPI: { setWarehouseAction },
  } = useWDetailsContext();
  const {
    state: {
      modals: { warehouseToggleActiveModal },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const closeModal = useCallback(() => {
    toggleModal({ id: "warehouseToggleActiveModal", status: false });
  }, []);

  const onSubmit = async () => {
    await setWarehouseAction(warehouse.active ? "deactivate" : "activate");
    closeModal();
  };

  const { t } = useTranslation("warehouses");

  const { submitBtn, cancelBtn, heading, actions, main } = useStyles();
  const titleKey = warehouse.active ? "deactivate_title" : "activate_title";
  const textKey = warehouse.active ? "deactivate_text" : "activate_text";
  const buttonTitleKey = warehouse.active ? "labels.details.deactivate" : "labels.details.activate";

  return (
    <Modal isOpen={warehouseToggleActiveModal} onClose={closeModal}>
      <>
        <header className={heading}>
          <Typography variant="h3">
            {t(`toggle_active.${titleKey}`, { name: warehouse.name })}
          </Typography>
        </header>
        <section className={main}>
          <Typography variant="body1">{t(`toggle_active.${textKey}`)}</Typography>
        </section>
        <section className={actions}>
          <Button type="submit" onClick={onSubmit} className={submitBtn}>
            {loading ? (
              <CircularProgress size={18} color="secondary" />
            ) : (
              t(buttonTitleKey, { ns: "customers" })
            )}
          </Button>
          <Button onClick={closeModal} className={cancelBtn}>
            {t("cancel", { ns: "common" })}
          </Button>
        </section>
      </>
    </Modal>
  );
};

export default ToggleActiveWarehouseModal;
