import DialogContent from "@material-ui/core/DialogContent";
import Title from "./components/Title";
import ModalFormActions from "./components/Actions";
import PrimaryActions from "./components/PrimaryActions";
import SecondaryActions from "./components/SecondaryActions";
import { FormWrapper } from "../index";

const ModalFormWrapper: FormWrapper = {
  Title: Title,
  Body: DialogContent,
  Actions: ModalFormActions,
  PrimaryActions: PrimaryActions,
  SecondaryActions: SecondaryActions,
};

export default ModalFormWrapper;
