import { IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { DeleteIcon } from "./icons";
import { deleteFileFromService, requestDelete } from "src/utils/crud";
import ConfirmationModal from "src/containers/Files/components/ConfirmationModal";

type Props = {
  onDelete?: () => void;
  deleteFile: any;
};

const DeleteFileCarouselAction = ({ onDelete, deleteFile }: Props) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    if (deleteFile && deleteFile.id && deleteFile.fileServiceId) {
      await requestDelete(`files/${deleteFile.id}`);
      await deleteFileFromService(deleteFile.fileServiceId);
      onDelete && onDelete();
    }
    setOpenDeleteConfirmation(false);
  };
  const handleDecline = async () => {
    setOpenDeleteConfirmation(false);
  };
  const classes = useStyles();
  return (
    <>
      <IconButton className={classes.actionButton} size="medium">
        <DeleteIcon onClick={handleDeleteConfirmation} height={26} width={26} />
      </IconButton>
      <ConfirmationModal
        isOpen={openDeleteConfirmation}
        handleConfirm={handleDelete}
        handleDecline={handleDecline}
        fileName={deleteFile.fileName}
      />
    </>
  );
};

const useStyles = makeStyles((_theme) => ({
  actionButton: {
    color: "#fff",
  },
}));

export default DeleteFileCarouselAction;
