import React from "react";
import { FileQuestionnaireItem } from "src/types";
import DownloadIcon from "./icons/DownloadIcon";
import { getFileUrl, mapPreviewTypeFromExtension } from "src/utils/files";
import PreviewIcon from "./icons/PreviewIcon";
import DropdownMenu from "src/components/DropdownMenu";
import MenuIcon from "./icons/MenuIcon";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useQuestionnaireContext } from "src/providers/QuestionnaireStore";

const QuestionnaireRowActions = (data: FileQuestionnaireItem) => {
  const { t } = useTranslation(`orders`);
  const { callAPI } = useQuestionnaireContext();

  const extension = data.name.slice((Math.max(0, data.name.lastIndexOf(".")) || Infinity) + 1);
  const previewType = mapPreviewTypeFromExtension(extension);
  const hasPreview = !!previewType;

  const downloadFile = async (e: React.MouseEvent) => {
    if (data.fileServiceId && data.name) {
      const url = await getFileUrl(data.fileServiceId);
      e.persist();
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", data.name);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      element.remove();
    }
  };

  type CallbackHandlerType = (e: React.MouseEvent) => void;
  type Actions = Record<"download" | "preview", CallbackHandlerType>;
  const actionsList = (t: TFunction, actions: Actions, hasPreview: boolean) => {
    const commonActions = [
      {
        label: t("labels.files.download"),
        icon: <DownloadIcon />,
        attr: {
          onClick: actions.download,
        },
      },
    ];
    const preview = [
      {
        label: t("labels.files.preview"),
        icon: <PreviewIcon />,
        attr: {
          onClick: actions.preview,
        },
      },
    ];
    if (hasPreview) return [...commonActions, ...preview];
    return commonActions;
  };
  const handlePreview = () => {
    if (!hasPreview) {
      return;
    }
    callAPI.setPreview({ ...data, previewType: previewType });
  };
  return (
    <DropdownMenu
      menuIcon={<MenuIcon />}
      menuActions={actionsList(
        t,
        {
          download: downloadFile,
          preview: handlePreview,
        },
        hasPreview
      )}
    />
  );
};

export default QuestionnaireRowActions;
