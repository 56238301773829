import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./styles";
import { CloseIcon } from "../../../icons";

interface Props {
  children?: React.ReactNode;
  onClose?: () => void;
}

const ModalFormTitle = ({ children, onClose }: Props) => {
  const classes = useStyles();
  return (
    <DialogTitle className={classes.actions}>
      {children}
      {!!onClose && (
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default ModalFormTitle;
