import React, { useState, useEffect } from "react";
import AnalyticsTable from "../../../../components/AnalyticsTable";
import { Service, ServicesReq, ServicesState } from "src/providers/AnalyticsStore/types";
import ServicesTableFields from "./ServicesTableFields";
import { requestGet } from "src/utils/crud";

const WarehouseTableDetails: React.FC<{ warehouseId: number }> = React.memo(({ warehouseId }) => {
  const [data, setData] = useState<ServicesState>({
    services: [],
    loading: false,
    success: false,
    TotalWithoutVat: 0,
  });

  const getServices = async (key: number | string) => {
    return requestGet<ServicesReq>(`Reports/Warehouse/${key}/services`)
      .then((res) => {
        setData((prev: ServicesState) => ({
          ...prev,
          services: [...res.data.services],
          loading: false,
          success: res.data.success,
          TotalWithoutVat: res.data.TotalWithoutVat,
        }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getServices(warehouseId);
  }, []);

  return (
    <AnalyticsTable<Service>
      data={data.services}
      columnsData={ServicesTableFields}
      customStyles={{ paddingLeft: "32px" }}
    />
  );
});

export default WarehouseTableDetails;
