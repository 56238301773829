import React, { FC } from "react";
import MainTable from "src/components/MainTable";
import { Trans, useTranslation } from "react-i18next";
import { UseTableRowProps } from "react-table";
import Link from "@material-ui/core/Link";
import { theme } from "src/utils/styleTheme/mainStyles";
import { Link as RouterLink } from "react-router-dom";
import { useServicesContext } from "src/providers/ServicesStore";
import { ServiceOrder, Service } from "src/types";
import { Grid, Typography } from "@material-ui/core";
import format from "date-fns/format";
import { TFunction } from "i18next";
import NoServices from "../../../components/NoServices";

export const columns = (t: TFunction) => {
  return [
    {
      Header: () => <Trans ns={"customers"} i18nKey="labels.details.services_table.service_name" />,
      id: "service_name",
      Cell: ({ row }: { row: UseTableRowProps<Service> }) => (
        <Link
          style={{
            color: theme.palette.functionalColors.dark,
            padding: theme.spacing(2),
          }}
          component={RouterLink}
          to={`/services/details/${row.original.id}/summary`}
        >
          {row.original.name}
        </Link>
      ),
    },
    {
      Header: () => <Trans ns={"customers"} i18nKey="labels.details.services_table.service_type" />,
      id: "service_type",
      accessor: (ent: Partial<Service>) => ent?.serviceType?.displayText,
    },
    {
      Header: () => <Trans i18nKey="labels.details.services_table.seller" ns={"customers"} />,
      id: "seller",
      accessor: (ent: Partial<Service>) => ent?.seller?.displayText,
    },
    {
      Header: () => <Trans i18nKey="labels.details.services_table.start_date" ns={"customers"} />,
      id: "registeredDate",
      accessor: (ent: Partial<Service>) =>
        !!ent.registeredDate ? (
          format(new Date(ent.registeredDate), "dd.MM.yyyy")
        ) : (
          <Typography variant="caption">none</Typography>
        ),
    },
    {
      Header: () => (
        <Trans i18nKey="labels.details.services_table.assigned_workers" ns={"customers"} />
      ),
      id: "assigned_workers_count",
      accessor: (ent: Partial<Service>) => ent.assignedWorkersCount,
    },
    {
      Header: () => <Trans i18nKey="labels.details.services_table.capacity" ns={"customers"} />,
      id: "capacity",
      accessor: (ent: Partial<Service>) => ent.capacity || 0,
    },
    {
      Header: () => <Trans i18nKey="labels.details.services_table.status" ns={"customers"} />,
      id: "statusColor",
      accessor: (ent: Partial<Service>) => ent.status?.displayText,
      Cell: ({ row }: { row: UseTableRowProps<Service> }) =>
        !!row.original.status?.displayText ? (
          <span
            style={{
              textAlign: "center",
              width: "fit-content",
              padding: 3,
              borderRadius: 5,
              backgroundColor: row.original.statusColor,
            }}
          >
            {t(`lookups.status.${row.original.statusMatchingName.toLowerCase()}`)}
          </span>
        ) : (
          <Typography variant="caption">none</Typography>
        ),
    },
    {
      Header: () => <Trans i18nKey="labels.details.services_table.external_id" ns={"customers"} />,
      id: "external_id",
      accessor: (ent: Partial<Service>) => ent.externalId,
    },
  ];
};

const ServiceTable: FC<{ data: ServiceOrder }> = ({ data }) => {
  const {
    servicesData: { selectedSO },
  } = useServicesContext();
  const { t } = useTranslation("lookups");

  if (selectedSO[data.id!]?.services.length <= 0) {
    return (
      <Grid container justify="center">
        <NoServices />
      </Grid>
    );
  }

  return (
    <MainTable<Service> data={selectedSO[data.id!]?.services} columnsData={() => columns(t)} />
  );
};

export default React.memo(ServiceTable);
