import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  fieldGroup: {
    marginTop: 22,
  },
  btnGroup: {
    marginTop: theme.spacing(3),
  },
  submitBtn: {
    padding: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.secondary.main,
    minWidth: 80,
    backgroundColor: theme.palette.functionalColors.dark,
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.brandColors.dark,
    },
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));
