import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { WarehouseProvider, useWarehouseContext } from "src/providers/WarehousesStore";
import { ServiceOrdersProvider, useSOContext } from "src/providers/ServiceOrdersStore";
import { useWarehousesStyles } from "./styles";
import Typography from "@material-ui/core/Typography/Typography";
import WarehousesList from "./components/List";
import Container from "@material-ui/core/Container/Container";
import Paper from "@material-ui/core/Paper/Paper";
import Pagination from "./components/Pagination";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import Preloader from "src/components/Preloader";
import PageActions from "./components/PageActions";
import CreationFrom from "./modals/CreateWarehouses";

const Warehouses: FunctionComponent = () => {
  const { warehousesData } = useWarehouseContext();
  const { SOData } = useSOContext();
  const { t } = useTranslation("warehouses");
  const classes = useWarehousesStyles();

  if (warehousesData.loading) {
    return <Preloader is />;
  }

  return (
    <Container style={{ margin: 0 }} maxWidth={"lg"}>
      <Typography variant="h3">{t("labels.main.title")}</Typography>
      <PageActions />
      <Paper className={classes.offSet}>
        <WarehousesList />
        <PreloaderLine loading={SOData.loading || warehousesData.loading} />
        <Pagination />
      </Paper>
      <CreationFrom />
    </Container>
  );
};

const WarehousesPage = () => (
  <WarehouseProvider>
    <ServiceOrdersProvider>
      <Warehouses />
    </ServiceOrdersProvider>
  </WarehouseProvider>
);
export default WarehousesPage;
