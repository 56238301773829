import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const NewsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M10.6 1L5.26667 5.26667H1V11.6667H5.26667L10.6 15.9333V1Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.5"
      d="M3.13379 13.8L3.13379 17"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4424 4.69067C16.4422 5.69082 17.0039 7.04713 17.0039 8.46134C17.0039 9.87555 16.4422 11.2319 15.4424 12.232"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7334 6.33334C13.4164 6.8992 13.8001 7.66656 13.8001 8.46668C13.8001 9.2668 13.4164 10.0342 12.7334 10.6"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default NewsIcon;
