import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
import { TFunction } from "i18next";
import { useStyles } from "src/pages/Sellers/components/styles";
import { navsTabsLink } from "src/pages/Warehouses/WarehouseDetails/data";
import SellersIcon from "./icons/SellerIcon";
import { WarehouseSeller } from "../../../providers/SellersStore/types";
import Button from "@material-ui/core/Button/Button";

export interface FieldListType {
  label: string;
  value: string | number | Date | boolean | undefined;
}

const dataList = (t: TFunction, elem: WarehouseSeller) => [
  {
    label: t("labels.row.email"),
    value: elem.email,
  },
  {
    label: t("labels.row.phone_number"),
    value: !!elem.mobilePhone ? elem.mobilePhone : "Empty",
  },
  {
    label: t("labels.row.sellers"),
    value: elem.sellerCount,
  },
];

const SellerFieldList: FC<{ data: WarehouseSeller }> = ({ data }) => {
  const { t } = useTranslation("sellers");
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={3} container id="seller-link-wrp" alignItems="center" justify="flex-start">
        <Button
          id="seller-link"
          className={classes.link}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
          onFocus={(event: React.FocusEvent<HTMLAnchorElement>) => event.stopPropagation()}
          href={`warehouses/details/${data.key}/${navsTabsLink[0]}`}
          startIcon={<SellersIcon />}
          color="primary"
          size="large"
        >
          <Typography variant="body2">{data.name}</Typography>
        </Button>
      </Grid>
      {dataList(t, data).map((ent: FieldListType) => (
        <Grid item xs key={uniqueId("warehouses")}>
          <Typography variant="caption">{ent.label}</Typography>
          <Typography variant="body2">{ent.value}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
export default SellerFieldList;
