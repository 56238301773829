import { useEffect, useMemo, useState } from "react";
import { requestMetaGet } from "src/utils/crud";

export interface CompartmentsType {
  box_capacity: number;
  id: number;
  location_address: string;
}

interface ServiceTypesListType {
  displayText: string;
  englishName: string;
  info: null | string;
  key: string;
  matchingName: null | string;
}

// Hook
export const useServiceType = (key?: number | string, initialCall?: false) => {
  const [data, setData] = useState<ServiceTypesListType[] | []>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");

  const exportData = useMemo(() => data, [data]);
  const exportLoading = useMemo(() => loading, [loading]);
  const exportError = useMemo(() => error, [error]);

  const fetchData = async () => {
    if (loaded || !key) return;
    setLoading(true);
    if (error) setError("");
    const response = await requestMetaGet<ServiceTypesListType[] | []>(
      `lookups/ServiceTypes?partner=${key}`
    );
    if (response.status === 200) {
      setData(response?.data);
      setLoaded(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialCall) {
      fetchData().catch((error) => {
        setError(error.message);
        setLoading(false);
      });
    }
  }, []);

  return {
    fetchData,
    data: exportData,
    loading: exportLoading,
    loaded,
    error: exportError,
  };
};
