import React, { useCallback } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/providers/AppProvider";
import Modal from "src/components/Modal";
import Button from "@material-ui/core/Button/Button";
import { useStyles } from "src/components/Modal/toggleModalBaseStyles";
import { useSellersContext } from "src/providers/SellersStore";

interface Props {
  onDeactivate?: () => void;
}

const DeactivateSellerModal = ({ onDeactivate }: Props) => {
  const {
    sellersStore: { loading },
    callAPI: { deactivateSellers },
  } = useSellersContext();

  const {
    state: {
      modals: { sellersDeactivateModal },
      modalsCtx,
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const closeModal = useCallback(() => {
    toggleModal({ id: "sellersDeactivateModal", status: false });
  }, []);

  const onSubmit = async () => {
    if (!modalsCtx.sellersDeactivateModal || !modalsCtx.sellersDeactivateModal.seller.key) {
      console.error("seller is not found");

      return;
    }

    const { seller } = modalsCtx.sellersDeactivateModal;

    await deactivateSellers([seller.key!]);
    if (onDeactivate) onDeactivate();
    closeModal();
  };

  const { actions, heading, submitBtn, cancelBtn, main } = useStyles();
  const { t } = useTranslation("sellers");

  if (!modalsCtx.sellersDeactivateModal || !modalsCtx.sellersDeactivateModal.seller.name) {
    return null;
  }

  return (
    <Modal isOpen={sellersDeactivateModal} onClose={closeModal}>
      <>
        <header className={heading}>
          <Typography variant="h3">
            {t("toggle_active.deactivate_title", {
              name: modalsCtx.sellersDeactivateModal.seller.name,
            })}
          </Typography>
        </header>
        <section className={main}>
          <Typography variant="body1">{t("toggle_active.deactivate_text")}</Typography>
        </section>
        <section className={actions}>
          <Button type="submit" onClick={onSubmit} className={submitBtn}>
            {loading ? (
              <CircularProgress size={18} color="secondary" />
            ) : (
              t("toggle_active.deactivate_action")
            )}
          </Button>
          <Button onClick={closeModal} className={cancelBtn}>
            {t("cancel", { ns: "common" })}
          </Button>
        </section>
      </>
    </Modal>
  );
};

export default DeactivateSellerModal;
