import { LinearProgress, withStyles } from "@material-ui/core";

export const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 10,
    backgroundColor: "rgba(20, 30, 48, 0.1)",
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#2B59C3",
  },
})(LinearProgress);
