import { CircularProgress } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { FILE_PREVIEW_TYPES } from "src/types";
import { requestGetFile } from "src/utils/crud";
import MonteraImagePreview from "./MonteraImagePreview";
import MonteraPdfPreview from "./MonteraPdfPreview";

interface Props {
  id: number;
  className?: string;
  imageClassName?: string;
  type: FILE_PREVIEW_TYPES;
}

const MonteraFilePreview: FC<Props> = ({ id, className, imageClassName, type }) => {
  const [blob, setBlob] = useState<Blob>();
  const [loading, setLoading] = useState(false);

  const fetchFile = async () => {
    setLoading(true);

    try {
      const response = await requestGetFile(id);
      setBlob(response.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFile();
  }, []);

  if (loading) {
    return (
      <div className={className}>
        <CircularProgress size={40} />
      </div>
    );
  }

  if (!blob) {
    return null;
  }

  if (type === FILE_PREVIEW_TYPES.IMAGE) {
    return (
      <div className={className}>
        <MonteraImagePreview className={imageClassName} blob={blob} />
      </div>
    );
  }

  if (type === FILE_PREVIEW_TYPES.PDF) {
    return (
      <div className={className}>
        <MonteraPdfPreview blob={blob} />
      </div>
    );
  }

  return null;
};

export default MonteraFilePreview;
