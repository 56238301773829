import React, { Component, ErrorInfo } from "react";

import { SystemError } from "./";

export class HandleSystemError extends Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    console.info(info);
  }

  render() {
    if (this.state.hasError) {
      return <SystemError />;
    }

    return this.props.children;
  }
}
