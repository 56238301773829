import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import SuccessIcon from "./icons/SuccessIcon";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";

interface Props {
  message: string;
  onClose: () => void;
  isOpen: boolean;
}
const SuccessNotification: React.FC<Props> = ({ isOpen, message, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation("orders");

  return (
    <Modal isOpen={isOpen}>
      <Grid container justify="center">
        <Grid item>
          <div className={classes.titleWrapper}>
            <SuccessIcon className={classes.successIcon} />
            <Typography variant="h4">{message}</Typography>
          </div>
          <div className={classes.centerBtn}>
            <Button onClick={onClose} className={classes.submitBtn}>
              {t("labels.ok", { ns: "common" })}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SuccessNotification;
