import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ChevronNavBtnIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.99994 2H16.9999" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.99994 8H16.9999" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.99994 14H16.9999" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4.00012 5.28564L1.14298 8.14279L4.00012 10.9999"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronNavBtnIcon;
