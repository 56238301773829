import React, { useState, useRef, useCallback, useEffect } from "react";
import { useStyles } from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/styles";
import { AxiosResponse } from "axios";
import { Typography, Button, Grid, CircularProgress, TextField } from "@material-ui/core";
import { requestPut } from "src/utils/crud";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "src/pages/ServiceOrdersDetails/tabs/Summary/EditSOSummary/SuccessNotification";
import { useSODetailsContext } from "src/providers/SODetailsStore";

const EditNotes: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { editNotesSO },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const {
    serviceOrderDetails: { serviceOrder },
    callAPI: { setContextData },
  } = useSODetailsContext();

  const [notes, setNotes] = useState(serviceOrder.notes);

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "editNotesSO", status: false });
    setSubmitted(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<any>) => {
    setNotes(event.target.value);
  };

  const submitNotes = async (data: any) => {
    try {
      const res = await requestPut(`ServiceOrders/${serviceOrder.id}/notes`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const submitObj = { notes: notes };
    const notesRes = await submitNotes(submitObj);
    if ((notesRes as AxiosResponse).status === 200) {
      setSubmitted(true);
      const newServiceOrder = {
        ...serviceOrder,
        notes: notes,
      };
      setContextData("serviceOrder", newServiceOrder);
    }
    setLoading(false);
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    serviceOrder && setNotes(serviceOrder.notes);
  }, [serviceOrder]);

  return (
    <>
      <Modal isOpen={editNotesSO} onClose={handleModalClose}>
        <Grid container justify="center" style={{ width: "100%" }}>
          <Grid item style={{ width: "100%" }}>
            {!submitted && (
              <form onSubmit={handleSubmit}>
                <Typography variant="h3">{t("labels.details.edit_notes")}</Typography>
                <div className={classes.fieldGroup}>
                  <TextField
                    fullWidth
                    label={t("labels.details.notes")}
                    placeholder={t("labels.details.notes")}
                    rows={4}
                    multiline
                    name="notes"
                    value={notes}
                    onChange={handleChange}
                    variant="outlined"
                    inputProps={{ maxLength: 500 }}
                  />
                </div>
                <div className={classes.btnGroup}>
                  <Button className={classes.submitBtn} type="submit" ref={submitButtonRef}>
                    {loading ? (
                      <CircularProgress size={18} color={"secondary"} />
                    ) : (
                      t("labels.common.save")
                    )}
                  </Button>
                  <Button onClick={handleModalClose} className={classes.cancelBtn}>
                    {t("labels.common.cancel")}
                  </Button>
                </div>
              </form>
            )}
            {submitted && (
              <SuccessNotification
                message={t("labels.common.save_success")}
                onClose={handleModalClose}
              />
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default EditNotes;
