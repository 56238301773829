import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import BackIcon from "./icons/BackIcon";
import DeactivateIcon from "./icons/Deactivate";
import ActivateIcon from "./icons/ActivateIcon";
import { useWDetailsContext } from "src/providers/WarehouseDetailsStore";
import { useAppContext } from "src/providers/AppProvider";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import { useWarehousesStyles } from "../../styles";

const PageActions: React.FC = () => {
  const classes = useWarehousesStyles();
  const { t } = useTranslation("warehouses");
  const history = useHistory();
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    selectedWarehouse: { warehouse },
  } = useWDetailsContext();

  const handleModal = useCallback(() => {
    toggleModal({
      id: "sellersModal",
      status: true,
    });
  }, []);

  const handleSOCreation = useCallback(() => {
    history.push(`/serviceOrders/creation`, {
      warehouseName: warehouse.name,
      warehouseId: warehouse.key,
    });
  }, [warehouse]);

  const openToggleActiveModal = useCallback(() => {
    toggleModal({ id: "warehouseToggleActiveModal", status: true });
  }, []);

  const {
    selectedWarehouse: {
      warehouse: { active },
    },
  } = useWDetailsContext();

  const isIntegrated = !user?.partner?.isIntegration;

  return (
    <div style={{ textAlign: "left" }}>
      <Button
        className={classes.outlineBtn}
        startIcon={<BackIcon />}
        component={Link}
        to="/warehouses"
      >
        {t("labels.details.back")}
      </Button>
      {isIntegrated && (
        <>
          <Button startIcon={<AddIcon />} className={classes.outlineBtn} onClick={handleModal}>
            {t("labels.details.add_seller")}
          </Button>
          <Button startIcon={<AddIcon />} className={classes.outlineBtn} onClick={handleSOCreation}>
            {t("labels.details.add_service_order")}
          </Button>

          <Button
            className={classes.outlineBtn}
            startIcon={active ? <DeactivateIcon /> : <ActivateIcon />}
            onClick={openToggleActiveModal}
          >
            {active ? t("labels.details.deactivate") : t("labels.details.activate")}
          </Button>
        </>
      )}
    </div>
  );
};

export default PageActions;
