import { DetailsType } from "./type";
import { WarehouseNavLinks } from "./type";
import { TFunction } from "i18next";
import { Warehouse } from "../../../types";
import format from "date-fns/format";

export const navsTabsLink: DetailsType[] = ["sellers", "related_orders"];

export const warehousesDetailsRouts: WarehouseNavLinks[] = navsTabsLink.map((ent, index) => ({
  path: ent,
  id: index,
  count: 0,
  label: ent,
  counter: false,
}));

export const dataList = (t: TFunction, elem: Partial<Warehouse>) => [
  {
    label: t("labels.details.contact_person"),
    value: elem.contactPerson,
  },
  {
    label: t("labels.details.email"),
    value: elem.email,
  },
  {
    label: t("labels.details.phone_number"),
    value: elem.mobilePhone,
  },
  {
    label: t("labels.details.registered_date"),
    value: !!elem.registeredDate ? format(new Date(elem.registeredDate), "dd.MM.yyyy") : "",
  },
  {
    label: t("labels.details.active"),
    value: elem.active ? t("labels.details.status_yes") : t("labels.details.status_no"),
  },
];
