import React from "react";
import { usePageNavigationListStyles } from "./styles";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import clsx from "clsx";
import ListChevronRightIcon from "src/pages/Analytics/icons/ListChevronRightIcon";
import { useAnalyticsContext } from "src/providers/AnalyticsStore";
import i18next from "i18next";

const PageNavigationList: React.FC = () => {
  const {
    analyticsData: { pageBarOpen, selectedMenuIndex, partnerManagerMenuListItems },
    callAPI,
  } = useAnalyticsContext();
  const classes = usePageNavigationListStyles();

  const handleListItemClick = (index: number) => {
    callAPI.setContextData("selectedMenuIndex", index);
  };

  return (
    <List className={clsx({ [classes.hide]: !pageBarOpen })}>
      {partnerManagerMenuListItems.map((el) => (
        <ListItem
          key={el.key}
          button
          selected={selectedMenuIndex === el.key}
          onClick={() => handleListItemClick(el.key)}
        >
          <ListItemText
            primaryTypographyProps={{
              variant: "subtitle2",
            }}
            primary={i18next.t(el.transKey, { ns: el.transNS })}
          />
          <ListItemSecondaryAction>
            <ListChevronRightIcon style={{ color: "transparent" }} />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default PageNavigationList;
