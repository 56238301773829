import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SmallNavLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="48"
    height="35"
    viewBox="0 0 48 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.3475 7.18264C23.6988 6.63631 22.8724 6.34791 22.0254 6.37225C21.4998 6.3464 20.9755 6.44521 20.4952 6.66064C20.0149 6.87607 19.592 7.20205 19.261 7.61208L12.6541 15.8476L11.88 16.8658C11.5469 17.2418 11.1296 17.5333 10.6622 17.7164C10.1949 17.8994 9.69091 17.9687 9.19166 17.9186H0V34.258H6.60003V18.1471L9.92423 21.8251C10.2488 22.234 10.6653 22.5602 11.1398 22.7769C11.6143 22.9936 12.1332 23.0947 12.6541 23.0718C13.1807 23.1003 13.7065 23.0019 14.1874 22.785C14.6683 22.5681 15.0905 22.2389 15.4185 21.8251L18.7427 18.1471V34.258H25.3427V9.12204C25.3353 8.74413 25.2418 8.37293 25.0693 8.0368C24.8968 7.70067 24.6499 7.40851 24.3475 7.18264Z"
      fill="white"
    />
    <path
      d="M6.05405 7.61884C5.72802 7.21154 5.3112 6.88657 4.83709 6.67004C4.36298 6.45351 3.84481 6.35146 3.3242 6.37208C2.47151 6.35155 1.63991 6.6392 0.981365 7.18247C0.679198 7.40758 0.43329 7.69991 0.262998 8.03644C0.092705 8.37298 0.00267683 8.74454 0 9.12187V12.2595H9.78601L6.05405 7.61884Z"
      fill="white"
    />
    <path
      d="M47.8773 5.19474H42.6941V-6.10352e-05H39.1902V5.19474H34V8.71336H39.1902V13.9082H42.6941V8.71336H47.8773V5.19474Z"
      fill="#06A9C6"
    />
  </SvgIcon>
);

export default SmallNavLogo;
