import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useStyles } from "../../styles";
import { requestMetaGet } from "../../../../../../utils/crud/index";
import { useAppContext } from "src/providers/AppProvider";
import { ServiceTypeLookup } from "src/providers/ServiceOrdersStore/types";

const ServiceType = () => {
  const { control } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [serviceTypes, setServiceType] = useState<ServiceTypeLookup[]>([]);
  const [open, setOpen] = useState(false);
  const {
    state: { user },
  } = useAppContext();
  const { t } = useTranslation("orders");
  const handleClose = () => setOpen(false);
  const { chip } = useStyles();
  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
    requestMetaGet<ServiceTypeLookup[]>(
      `lookups/serviceTypes?partner=${user?.partner?.key}&showDisabled=true`
    )
      .then((response) => {
        setLoading(false);
        setServiceType(response.data);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <Controller
      render={({ onChange, ref, ...props }) => (
        <Autocomplete<ServiceTypeLookup, true, false, false>
          multiple
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          loading={loading}
          onChange={(_, value) => onChange(value)}
          options={serviceTypes}
          innerRef={ref}
          getOptionLabel={(option) => option.displayText}
          getOptionSelected={(option, value) => option.key === value.key}
          {...props}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                color="primary"
                label={option.displayText}
                classes={{ root: chip }}
                {...getTagProps({ index })}
              />
            ))
          }
          limitTags={2}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("list_filter.service_type_label")}
              placeholder="Service Type"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={16} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      defaultValue={[]}
      name="serviceTypes"
      control={control}
    />
  );
};

export default ServiceType;
