import React, { useState, useEffect, useCallback } from "react";
import { useStyles } from "./styles";
import { Typography, Button, TextField } from "@material-ui/core";
import DateIcon from "../icons/DateIcon";
import ServiceTypeIcon from "../icons/ServiceTypeIcon";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import { Grid } from "@material-ui/core";
import { ServiceUpdateReq } from "src/providers/ServiceDetailsStore/types";
import { CircularProgress } from "@material-ui/core";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";
import DatePicker from "src/components/DatePicker";
import Success from "./Success";
import SelectInput from "src/components/SelectInput";
import NumberFormatCustom from "src/components/NumberFormat";

const initialStateService: ServiceUpdateReq = {
  id: 0,
  serviceTypeId: 0,
  startDate: "",
  deliveryDate: "",
  capacity: null,
};

const ServiceEditForm = () => {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const {
    serviceDetails: { service, loading, updated },
    callAPI: { updateServiceData, setContextData },
  } = useServiceDetailsContext();
  const {
    state: {
      modals: { editServiceStatusModal },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const [data, setData] = useState<ServiceUpdateReq>(initialStateService);

  useEffect(() => {
    if (!!service) {
      setData({
        id: service.id!,
        serviceTypeId: service.serviceType?.key!,
        startDate: service.startDate,
        deliveryDate: service.deliveryDate,
        capacity: service.capacity ?? null,
      });
    }
  }, [service]);

  const handleModal = useCallback(() => {
    toggleModal({ id: "editServiceStatusModal", status: false });
  }, []);

  const handleType = (value: number) => {
    setData({ ...data, serviceTypeId: value });
  };

  const handleChangeByFieldName = (fieldName: string, value: string | null) =>
    setData({ ...data, [fieldName]: value });

  const handleCapacityChange = (e: any) => {
    setData((prev) => ({ ...prev, capacity: e.target.floatValue || null }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateServiceData({ ...data });
  };

  if (updated) {
    return (
      <Modal isOpen={editServiceStatusModal} onClose={handleModal}>
        <Success
          handlerOnOK={() => {
            setContextData("updated", false);
            handleModal();
          }}
        />
      </Modal>
    );
  }

  return (
    <Modal isOpen={editServiceStatusModal} onClose={handleModal}>
      <Grid container justify="center" spacing={1}>
        <Grid item>
          <form onSubmit={handleSubmit}>
            <Typography variant="h3">{t("labels.details.edit_service_title")}</Typography>
            <div className={classes.fieldGroup}>
              <div className={classes.titleWrapper}>
                <ServiceTypeIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("labels.details.select_service_type")}
                </Typography>
              </div>
              <SelectInput
                required
                metaApi
                onSelect={handleType}
                label={t("labels.details.project_type")}
                name="serviceTypeId"
                value={data.serviceTypeId}
                url={`Lookups/serviceTypes?partner=${service?.partner?.key}`}
                autoselectIfOnlyOneOption={false}
              />
              <div className={classes.titleWrapper}>
                <DateIcon />
                <Typography variant="h4" className={classes.fieldTitle}>
                  {t("labels.details.select_service_date_range")}
                </Typography>
              </div>
              <div className={classes.displayEven}>
                <DatePicker
                  required
                  label={t("labels.details.start_date")}
                  name="startDate"
                  value={data.startDate}
                  onChange={(value) => handleChangeByFieldName("startDate", value)}
                  maxDate={data.deliveryDate}
                />
                <DatePicker
                  required
                  style={{ marginLeft: 16 }}
                  label={t("labels.details.delivery_date")}
                  name="deliveryDate"
                  value={data.deliveryDate}
                  onChange={(value) => handleChangeByFieldName("deliveryDate", value)}
                  minDate={data.startDate}
                />
              </div>

              <div className={classes.titleWrapper}>
                <Typography variant="h4" className={classes.fieldTitleNoIcon}>
                  {t("labels.details.select_capacity")}
                </Typography>
              </div>

              <TextField
                variant="outlined"
                fullWidth={true}
                label={t("labels.details.capacity")}
                value={data.capacity}
                onChange={handleCapacityChange}
                name="capacity"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    decimalScale: 0,
                  },
                }}
              />
            </div>
            <div className={classes.btnGroup}>
              <Button type="submit" className={classes.submitBtn}>
                {loading ? (
                  <CircularProgress size={18} color={"secondary"} />
                ) : (
                  t("labels.common.save_changes")
                )}
              </Button>
              <Button onClick={handleModal} className={classes.cancelBtn}>
                {t("labels.common.cancel")}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ServiceEditForm;
