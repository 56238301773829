import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStyles } from "./styles";
import { Typography, TextField, InputAdornment, Button } from "@material-ui/core";
import { Customer, HouseholdMember } from "src/types";
import { useAppContext } from "src/providers/AppProvider";
import { requestPost } from "src/utils/crud";
import EmailIcon from "./icons/EmailIcon";
import HouseholdIcon from "./icons/HouseholdIcon";
import InfoIcon from "./icons/InfoIcon";
import EmptyState from "./EmptyState";
import MemberDisplayCard from "./MemberDisplayCard";
import { uniqueId } from "lodash";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  ssn: "",
};

interface Props {
  customerData: Partial<Customer> | [];
  afterSubmit: () => void;
}

export const CreateHouseholdMembers: React.FC<Props> = ({ customerData, afterSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const [newMember, setNewMember] = useState<Partial<HouseholdMember>>(initialState);
  const [membersList, setMembersList] = useState<Partial<HouseholdMember>[]>([]);
  const [addMemberDisabled, setAddMemberDisabled] = useState<boolean>(true);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { callAppAPI } = useAppContext();

  const handleModal = useCallback(() => {
    callAppAPI.toggleModal({
      id: "createCustomerModal",
      status: false,
    });
  }, []);

  const handleClearButton = () => {
    setNewMember(initialState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMember({ ...newMember, [event.target.name]: event.target.value });
  };

  const createMembers = async (data: { householdMemberList: Partial<HouseholdMember>[] }) => {
    try {
      const res = await requestPost(`HouseholdMembers/list`, data);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleMemberAdd = () => {
    setMembersList([...membersList, { ...newMember, key: Math.floor(Math.random() * 10000) }]);
    setNewMember(initialState);
    setAddMemberDisabled(true);
  };

  const handleSubmit = async () => {
    submitButtonRef.current?.setAttribute("disabled", "disabled");
    const listWithCustomerId = membersList.map((member) => ({
      ...member,
      customerId: (customerData as Partial<Customer>).key,
    }));
    const submitObject = {
      householdMemberList: listWithCustomerId,
    };
    const householdMembersRes = await createMembers(submitObject);
    if ((householdMembersRes as AxiosResponse).status === 204) {
      afterSubmit();
    }
    submitButtonRef.current?.removeAttribute("disabled");
  };

  const handleMemberDelete = (key: number) => {
    let newArr = membersList.filter((member) => member.key !== key);
    setMembersList(newArr);
  };

  useEffect(() => {
    if (
      newMember.firstName &&
      newMember.lastName &&
      newMember.email &&
      newMember.phone &&
      membersList.length < 4
    ) {
      setAddMemberDisabled(false);
    } else {
      setAddMemberDisabled(true);
    }
  }, [newMember, membersList]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [membersList]);

  return (
    <>
      <form>
        <Typography variant="h3"> {t("labels.create_customer.members_form.title")}</Typography>
        <div className={classes.fieldGroup}>
          <div className={classes.titleWrapper}>
            <HouseholdIcon />
            <Typography variant="h4" className={classes.fieldTitle}>
              {t("labels.create_customer.members_form.member")}
            </Typography>
          </div>
          <TextField
            required
            fullWidth
            name="firstName"
            label={t("labels.create_customer.members_form.first_name")}
            value={newMember.firstName}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            name="lastName"
            label={t("labels.create_customer.members_form.last_name")}
            value={newMember.lastName}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            name="phone"
            label={t("labels.create_customer.members_form.phone")}
            value={newMember.phone}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            name="email"
            label={t("labels.create_customer.members_form.email")}
            value={newMember.email}
            onChange={handleChange}
            variant="outlined"
            InputProps={{
              type: "email",
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="ssn"
            label={t("labels.create_customer.members_form.ssn")}
            value={newMember.ssn}
            onChange={handleChange}
            variant="outlined"
          />
          <div className={classes.infoWrapper}>
            <InfoIcon />
            <Typography variant="caption" className={classes.infoText}>
              {t("labels.create_customer.members_form.info")}
            </Typography>
          </div>
        </div>

        <div className={classes.btnGroup}>
          <Button
            disabled={addMemberDisabled}
            className={classes.submitBtn}
            onClick={handleMemberAdd}
          >
            {t("labels.create_customer.members_form.add_btn")}
          </Button>
          <Button onClick={handleClearButton} className={classes.cancelBtn}>
            {t("labels.create_customer.members_form.clear_btn")}
          </Button>
        </div>
      </form>

      <div className={classes.summaryTitle}>
        <hr />
        <Typography variant="subtitle1">
          {t("labels.create_customer.members_form.members_list")}
        </Typography>
        {membersList && membersList.length < 1 && <EmptyState />}
      </div>

      {membersList.map((member) => (
        <MemberDisplayCard member={member} key={uniqueId()} deleteMember={handleMemberDelete} />
      ))}

      <div className={classes.btnGroup}>
        <Button
          disabled={submitDisabled}
          className={classes.submitBtn}
          onClick={handleSubmit}
          ref={submitButtonRef}
        >
          {t("labels.create_customer.members_form.save")}
        </Button>
        <Button onClick={handleModal} className={classes.cancelBtn}>
          {t("labels.create_customer.members_form.cancel")}
        </Button>
      </div>
    </>
  );
};
