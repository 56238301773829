import React from "react";
import Hint from "src/components/Hint";
import ProgressHintIcon from "../icons/ProgressHintIcon";
import { Typography, IconButton } from "@material-ui/core";
import { BorderLinearProgress } from "src/components/ProgressBar/";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

interface Props {
  completedChildCount: number;
  childServicesCount: number;
}

const ProgressTooltip: React.FC<Props> = ({ completedChildCount, childServicesCount }) => {
  const { t } = useTranslation("orders");
  const classes = useStyles();
  return (
    <Hint
      title={
        <>
          <Typography variant="subtitle2" className={classes.text}>
            {t("labels.row.completed")}: {completedChildCount} /{" "}
            <span className={classes.totalServices}>{childServicesCount}</span>
          </Typography>
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            value={Math.floor((completedChildCount / childServicesCount) * 100) || 0}
            className={classes.tooltip}
          />
        </>
      }
    >
      <IconButton>
        <ProgressHintIcon />
      </IconButton>
    </Hint>
  );
};

export default ProgressTooltip;
