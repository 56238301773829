import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const LIIkeaBusinessIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6667 3.20004H11.3333V2.46668C11.3333 1.6578 10.7354 1 10 1H6C5.26466 1 4.66666 1.65777 4.66666 2.46668V3.2H1.33334C0.597969 3.20004 0 3.8578 0 4.66668V5.86668C0 6.67559 0.597969 7.33336 1.33334 7.33336H6.66669V6.96668C6.66669 6.76401 6.81578 6.6 7.00003 6.6H9.00003C9.18428 6.6 9.33337 6.76401 9.33337 6.96668V7.33336H14.6667C15.402 7.33336 16 6.67559 16 5.86668V4.66668C16 3.8578 15.402 3.20004 14.6667 3.20004ZM10 3.20004H6V2.46668H10V3.20004Z"
      fill="#141E30"
      fillOpacity="0.3"
    />
    <path
      d="M15.8151 8.72915C15.7015 8.66721 15.5658 8.68154 15.4655 8.76532C15.2285 8.96263 14.9524 9.06682 14.6666 9.06682H9.33334V10.1668C9.33334 10.3695 9.18425 10.5335 9 10.5335H7C6.81575 10.5335 6.66666 10.3695 6.66666 10.1668V9.06682H1.33334C1.04753 9.06682 0.7715 8.96263 0.5345 8.76532C0.433906 8.68082 0.2985 8.66649 0.184875 8.72915C0.071625 8.79113 0 8.91822 0 9.05754V14.2002C0 15.0091 0.597969 15.6669 1.33334 15.6669H14.6667C15.402 15.6669 16 15.0091 16 14.2002V9.05754C16 8.91822 15.9284 8.79113 15.8151 8.72915Z"
      fill="#141E30"
      fillOpacity="0.5"
    />
  </SvgIcon>
);
export default LIIkeaBusinessIcon;
