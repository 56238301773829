import React, { useState, FC } from "react";
import { Typography, List, ListItem } from "@material-ui/core";
import { File } from "src/types";
import FilesFields from "src/containers/Files/components/FilesFields";
import { useTranslation } from "react-i18next";
import { AlertPanel } from "./AlertPanel";
import uniqueId from "lodash/fp/uniqueId";
import { useStyles } from "./styles";

interface Props {
  fileList?: File[];
  onDelete?: () => void;
}

const FileList: FC<Props> = ({ fileList = [], onDelete = () => {} }) => {
  const { t } = useTranslation("files");
  const [fileDeleted, setFileDeleted] = useState(false);

  const classes = useStyles();
  return (
    <>
      <List>
        {fileList.length ? (
          fileList.map((file) => (
            <ListItem key={uniqueId("filesList")}>
              <FilesFields imageClassName={classes.previewImage} file={file} onDelete={onDelete} />
            </ListItem>
          ))
        ) : (
          <Typography color="textSecondary">{t("labels.no_files")}</Typography>
        )}
      </List>
      {fileDeleted && (
        <AlertPanel
          type="success"
          open={fileDeleted}
          onClose={() => {
            setFileDeleted(false);
            onDelete();
          }}
          closeIcon={false}
          closeButton={true}
        >
          <Typography variant="h6" align="center">
            {t("labels.file_deleted")}
          </Typography>
        </AlertPanel>
      )}
    </>
  );
};

export default FileList;
