import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 160,
      height: 160,
    },
  })
);

const NoDataIcon = (props: SvgIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      className={classes.root}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M160 80C160 124.183 124.183 160 80 160C35.8167 160 0 124.183 0 80C0 35.8167 35.8167 0 80 0C124.183 0 160 35.8167 160 80Z"
        fill="#141E30"
        fillOpacity="0.05"
      />
      <path d="M35 57.9131H125.104V116.574H35V57.9131Z" fill="#D0EACC" />
      <path
        d="M80.0527 57.9131H125.105V116.574H80.0527V57.9131Z"
        fill="#2B59C3"
        fillOpacity="0.05"
      />
      <path d="M40.0254 104H120.08V111.397H40.0254V104Z" fill="white" />
      <path d="M80.0508 104H120.08V111.397H80.0508V104Z" fill="#2B59C3" fillOpacity="0.05" />
      <path
        d="M91.2628 51.1905C85.0976 51.1905 80.0527 50.8436 80.0527 57.6383C80.0527 50.8436 75.0079 51.1905 68.8426 51.1905H40.0254V104.688H71.7917C79.8779 104.688 80.0536 110.664 80.0536 110.664C80.0536 110.664 80.06 104.688 88.3147 104.688H120.081V51.1905H91.2628Z"
        fill="#C5DBF2"
      />
      <path
        d="M91.2628 51.1905C85.0976 51.1905 80.0527 50.8436 80.0527 57.6383C80.0527 50.8436 75.0079 51.1905 68.8426 51.1905H40.0254V104.688H71.7917C79.8779 104.688 80.0536 110.664 80.0536 110.664C80.0536 110.664 80.06 104.688 88.3147 104.688H120.081V51.1905H91.2628Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M47.8613 44H70.2244C75.6507 44 80.0904 48.4453 80.0904 53.8784V111.009C80.0904 105.576 75.6507 101.13 70.2244 101.13H50.0812L47.8613 100.883V44Z"
        fill="white"
      />
      <path d="M55.2812 50.2607H70.1205V70.201H55.2812V50.2607Z" fill="#FBEECA" />
      <path d="M55.2812 75.0693H70.1205V95.0096H55.2812V75.0693Z" fill="#F9E0FA" />
      <path
        d="M91.2627 51.1905C85.0974 51.1905 80.0526 50.8436 80.0526 57.6383C80.0526 57.6048 80.0508 57.5731 80.0508 57.5396V110.621C80.0517 110.646 80.0526 110.664 80.0526 110.664C80.0526 110.664 80.0589 104.688 88.3137 104.688H120.08V51.1905H91.2627Z"
        fill="white"
        fillOpacity="0.2"
      />
      <rect
        x="91.2207"
        y="61.8535"
        width="19.2447"
        height="34.7796"
        fill="#2B59C3"
        fillOpacity="0.2"
      />
    </SvgIcon>
  );
};
export default NoDataIcon;
