import React from "react";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography";
import Hint from "src/components/Hint";
import ArrowDownIcon from "src/pages/Analytics/icons/ArrowDownIcon";
import { Trans } from "react-i18next";
import Link from "@material-ui/core/Link";
import { theme } from "src/utils/styleTheme/mainStyles";
import { Link as RouterLink } from "react-router-dom";
import StatusColor from "src/components/StatusColors";
import { Service } from "src/providers/AnalyticsStore/types";
import { UseTableRowProps } from "react-table";

const ServicesTableFields = () => {
  return [
    {
      Header: () => <Trans ns={"analytics"} i18nKey="labels.reports.so_id" />,
      id: "serviceOrderId",
      accessor: (ent: Partial<Service>) => ent.serviceOrderName,
      Cell: ({ row }: { row: UseTableRowProps<Partial<Service>> }) => (
        <Link
          style={{
            color: theme.palette.functionalColors.dark,
            padding: theme.spacing(2),
          }}
          component={RouterLink}
          to={`/serviceOrders/details/${row.original.serviceOrderId}/summary`}
        >
          <Typography variant="body2">{row.original.serviceOrderName}</Typography>
        </Link>
      ),
    },
    {
      Header: () => <Trans ns={"analytics"} i18nKey="labels.reports.so_name" />,
      id: "serviceOrderName",
      accessor: (ent: Partial<Service>) => ent?.serviceOrderName,
      Cell: ({ row }: { row: UseTableRowProps<Partial<Service>> }) => (
        <Typography variant="body2">{row.original.serviceOrderName}</Typography>
      ),
    },
    {
      Header: () => <Trans ns={"analytics"} i18nKey="labels.reports.service_id" />,
      id: "serviceName",
      accessor: (ent: Partial<Service>) => ent.serviceName,
      Cell: ({ row }: { row: UseTableRowProps<Partial<Service>> }) => (
        <Link
          style={{
            color: theme.palette.functionalColors.dark,
            padding: theme.spacing(2),
          }}
          component={RouterLink}
          to={`/services/details/${row.original.serviceId}/summary`}
        >
          <Typography variant="body2">{row.original.serviceName}</Typography>
        </Link>
      ),
    },
    {
      Header: () => <Trans ns={"analytics"} i18nKey="labels.reports.customer_name" />,
      id: "customerName",
      accessor: (ent: Partial<Service>) => ent.customerName,
      Cell: ({ row }: { row: UseTableRowProps<Partial<Service>> }) => (
        <Link
          style={{
            color: theme.palette.functionalColors.dark,
            padding: theme.spacing(2),
          }}
          component={RouterLink}
          to={`/customers/details/${row.original.customerId}/summary`}
        >
          <Typography variant="body2">{row.original.customerName}</Typography>
        </Link>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.reports.service_type" ns={"analytics"} />,
      id: "serviceTypeName",
      accessor: (ent: Partial<Service>) => ent.serviceTypeName,
      Cell: ({ row }: { row: UseTableRowProps<Partial<Service>> }) => (
        <Typography variant="body2">{row.original.serviceTypeName}</Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.reports.service_description" ns={"analytics"} />,
      id: "description",
      accessor: (ent: Partial<Service>) => ent.description,
      Cell: ({ row }: { row: UseTableRowProps<Partial<Service>> }) => (
        <Hint title={<Typography variant="body1">{row.original.description}</Typography>}>
          {row.original.description ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">
                {row.original.description?.substring(0, 23)}{" "}
                {row.original.description.length > 23 && "..."}
              </Typography>
              <ArrowDownIcon />
            </div>
          ) : (
            <span></span>
          )}
        </Hint>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.reports.service_status" ns={"analytics"} />,
      id: "statusTypeName",
      accessor: (ent: Partial<Service>) => ent.statusTypeName,
      Cell: ({ row }: { row: UseTableRowProps<Partial<Service>> }) =>
        !!row.original.statusTypeName ? (
          <StatusColor color={row.original.statusTypeName} />
        ) : (
          <Typography variant="caption">None</Typography>
        ),
    },
    {
      Header: () => <Trans i18nKey="labels.reports.date_created" ns={"analytics"} />,
      id: "createdDate",
      accessor: (ent: Partial<Service>) => ent.createdDate,
      Cell: ({ row }: { row: UseTableRowProps<Partial<Service>> }) => (
        <Typography variant="body2">
          {!!row.original.createdDate
            ? format(new Date(row.original.createdDate), "dd.MM.yyyy hh:mm")
            : `---`}
        </Typography>
      ),
    },
  ];
};

export default ServicesTableFields;
