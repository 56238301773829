import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 36,
      height: 36,
    },
  })
);

const StepsIcon = (props: SvgIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={classes.root}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="15.5" stroke="#141E30" strokeOpacity="0.5" />
      <g opacity="0.5">
        <path
          d="M16 23C19.866 23 23 19.866 23 16C23 12.134 19.866 9 16 9C12.134 9 9 12.134 9 16C9 19.866 12.134 23 16 23Z"
          stroke="#141E30"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M16 11.7998V15.9998L18.1 18.0998"
          stroke="#141E30"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
};
export default StepsIcon;
