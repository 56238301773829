import React from "react";
import { Typography } from "@material-ui/core";
import FilterChip from "./filterChip";
import { filterChipStyles } from "./styles";
import { drop, map } from "lodash";
import { OrdersFilterParams } from "src/providers/ServiceOrdersStore/types";

interface Props {
  name: keyof OrdersFilterParams;
  format: (text: string) => string;
  label: string;
  item: any;
  onDelete: (name: keyof OrdersFilterParams, key: string) => void;
}

const FilterGroupMulti: React.FC<Props> = ({ name, label, item, format, onDelete }) => {
  const classes = filterChipStyles();
  const handleDelete = (key: string) => {
    onDelete(name, key);
  };

  const firstItem = item?.length && item[0];
  const otherItems = drop(item, 1);

  return (
    <>
      <div className={classes.filterGroup}>
        <Typography noWrap={true}>{label}:</Typography>
        <FilterChip
          key={firstItem.key}
          name={firstItem.key}
          format={format}
          item={firstItem}
          onDelete={handleDelete}
        />
      </div>
      {map(otherItems, (x: any) => (
        <FilterChip
          key={x.key || x}
          name={x.key || x}
          format={format}
          item={x}
          onDelete={handleDelete}
        />
      ))}
    </>
  );
};

export default FilterGroupMulti;
