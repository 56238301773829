import React, { PropsWithChildren } from "react";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTable, useExpanded, useSortBy, usePagination, useRowSelect } from "react-table";

import SortedIco from "src/assets/icons/SortedIcon";
import { useStyles } from "./style";
import clsx from "clsx";
interface Props<T extends object = {}> {
  data?: T[];
  styleOverlay?: string;
  columnsData?: () => any;
  rowActions?: (data: Partial<T>) => JSX.Element;
  pageSize?: number;
}

function MainTable<D extends object = {}>({
  data = [],
  columnsData = () => [],
  rowActions,
  styleOverlay,
  pageSize = 10000,
}: PropsWithChildren<Props<D>>) {
  const { tableWrapper, tableRow } = useStyles();
  const columns = React.useMemo(() => columnsData(), []);
  const { getTableProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "name",
            desc: true,
          },
        ],
        pageSize,
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  return (
    <MaUTable {...getTableProps()} className={clsx(tableWrapper, !!styleOverlay && styleOverlay)}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              return (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortedIco fontSize="small" />
                      ) : (
                        <SortedIco fontSize="small" style={{ transform: "rotate(180deg)" }} />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </TableCell>
              );
            })}
            {rowActions && <TableCell key={Math.random()}></TableCell>}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {page.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps({ className: tableRow })}>
              {row.cells.map((cell) => {
                return (
                  <TableCell align={"left"} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
              {rowActions && (
                <TableCell align={"center"} style={{}} key={Math.random()}>
                  {rowActions(row.original)}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

export default React.memo(MainTable);
