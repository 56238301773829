import React, { cloneElement } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { SoLineItem } from "src/providers/LineItemsStore/types";

interface Props {
  row: SoLineItem;
  isCanDeleted?: boolean;
  isCanEdit? : boolean;
}
const ActionsMenu: React.FC<Props> = ({ row, children, isCanDeleted, isCanEdit }) => {
  const { callLineItemsAPI } = useLineItemsContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation(`common`);
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    callLineItemsAPI.handleModal({ open: "delete", id: row.key });
    handleClose();
  };

  const handleEdit = () => {
    const type = row.writeInPrice ? 3 : row.priceListItemType;
    callLineItemsAPI.handleModal({ open: "edit", row, type });
    handleClose();
  };

  return (
    <div>
      {!!children && cloneElement(children as JSX.Element, { onClick })}
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!isCanEdit && <MenuItem onClick={handleEdit}>{t("labels.edit")}</MenuItem>}
        {isCanDeleted && <MenuItem onClick={handleDelete}>{t("labels.delete")}</MenuItem>}
      </Menu>
    </div>
  );
};
export default ActionsMenu;
