import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSellersContext } from "src/providers/SellersStore";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Hint from "src/components/Hint";
import { useStyles } from "./styles";
import { LookupObject } from "src/types";

export default function SelectSeller() {
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const { control, watch, setValue, register } = useFormContext();
  const {
    sellersStore: { sellersGroupedByWarehouses },
    callAPI,
  } = useSellersContext();
  const selectedWarehouses = watch<string, LookupObject[] | []>("warehouses", []);

  useEffect(() => {
    if (watch("seller")) {
      if (
        !sellersGroupedByWarehouses.some(
          (warehouse: LookupObject) => warehouse.key === selectedWarehouses
        )
      )
        resetSeller();
    }
  }, [selectedWarehouses]);

  const onOpen = () => {
    callAPI.getGroupedWarehousesSellers(selectedWarehouses);
  };
  const onChangeHandler = (selectedObject: any) => {
    if (selectedObject.hasOwnProperty("value") && !!selectedObject.value) {
      if (selectedObject["data-reset"]) {
        resetSeller();
        return;
      }
      registerValues();
      setValue("seller", selectedObject.value);
      setValue("sellerName", selectedObject.children);
      setValue("sellerWarehouseKey", selectedObject["data-warehousekey"]);
    }
  };

  const resetSeller = () => {
    setValue("seller", "");
    setValue("sellerName", "");
    setValue("sellerWarehouseKey", "");
  };
  const registerValues = () => {
    register({ name: "sellerName" });
    register({ name: "sellerWarehouse" });
    register({ name: "sellerWarehouseKey" });
  };
  const titleText = selectedWarehouses ? "" : t("list_filter.select_warehouse_first");
  return (
    <Hint title={titleText} placement="bottom">
      <FormControl fullWidth>
        <Controller
          disabled={!selectedWarehouses.length}
          as={
            <TextField
              select
              id="grouped-select"
              variant="outlined"
              label={t("list_filter.seller_label")}
              SelectProps={{ onOpen, onChange: (_, child: any) => onChangeHandler(child.props) }}
            >
              <MenuItem data-reset value={["any", ""]}>
                Any
              </MenuItem>
              {sellersGroupedByWarehouses.map((warehouse) => [
                <ListSubheader key={warehouse.key}>{warehouse.displayText}</ListSubheader>,
                warehouse.sellers.map((seller) => (
                  <MenuItem
                    className={classes.menuItem}
                    data-warehousekey={warehouse.key}
                    value={seller.key}
                    key={seller.key}
                  >
                    {seller.displayText}
                  </MenuItem>
                )),
              ])}
            </TextField>
          }
          name="seller"
          control={control}
          defaultValue=""
        />
      </FormControl>
    </Hint>
  );
}
