import ReactDOM from "react-dom";
import { keyBy } from "lodash";
import ReactImageGallery from "react-image-gallery";
import { isImageFile, getFileExtension } from "src/utils/files";
import { MonteraFile } from "./types";

export const fileToCarouselItem = (file: MonteraFile) => {
  return {
    ...file,
    original: undefined,
    thumbnail: undefined,
    loadingOriginal: false,
    loadingThumbnail: false,
    isImage: isImageFile(file),
    type: getFileExtension(file),
  };
};

export const filesToCarouselItemsDictionary = (files: MonteraFile[]) => {
  const items = files.map(fileToCarouselItem);
  return keyBy(items, (x) => x.id);
};

export const getGalleryThumbsNode = (ref: React.RefObject<ReactImageGallery>) => {
  const node = ReactDOM.findDOMNode(ref.current) as HTMLElement;
  if (!node) {
    return null;
  }
  const thumbs = node.querySelector(".image-gallery-thumbnails-container") as HTMLElement;
  return thumbs;
};
