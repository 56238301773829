import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const OrdersIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 5.6L8.2 0L15.4 5.6V14.4C15.4 15.2837 14.6837 16 13.8 16H2.6C1.71634 16 1 15.2837 1 14.4V5.6Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.80078 16V8H10.6008V16" fill="white" />
  </SvgIcon>
);

export default OrdersIcon;
