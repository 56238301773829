import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LookupObject } from "src/types";
import { useStyles } from "../../styles";
import { useWarehouseContext } from "src/providers/WarehousesStore";

const SelectWarehouse = () => {
  const { control } = useFormContext();
  const {
    warehousesData: { loading, warehousesLookup },
    callAPI,
  } = useWarehouseContext();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("orders");
  const handleClose = () => setOpen(false);
  const { chip } = useStyles();
  const handleOpen = () => {
    setOpen(true);
    callAPI.fetchWarehousesLookup();
  };

  return (
    <Controller
      render={({ onChange, ref, ...props }) => (
        <Autocomplete<LookupObject, true, false, false>
          multiple
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          loading={loading}
          onChange={(_, value) => onChange(value)}
          options={warehousesLookup}
          getOptionLabel={(option: LookupObject) => option.displayText}
          getOptionSelected={(option, value) => option.key === value.key}
          innerRef={ref}
          {...props}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                color="primary"
                label={option.displayText}
                classes={{ root: chip }}
                {...getTagProps({ index })}
              />
            ))
          }
          limitTags={2}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("list_filter.warehouse_label")}
              placeholder={t("list_filter.warehouse_label")}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={16} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      defaultValue={[]}
      name="warehouses"
      control={control}
    />
  );
};

export default SelectWarehouse;
