import { useEffect } from "react";
import { CircularProgress, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useFlag } from "src/utils/hooks/useFlag";
import { CarouselItemData } from "./types";
import { getFileTypeIcon } from "./MonteraFileTypeIcons";

type Props = {
  item: CarouselItemData;
  onLoad: () => void;
};

const CarouselItem = ({ item, onLoad: onItemLoad }: Props) => {
  const [loading, setLoading, setDropLoading] = useFlag(false);

  const onLoad = () => {
    setDropLoading();
  };

  const onError = () => {
    setDropLoading();
  };

  useEffect(() => {
    if (item.loadingOriginal) {
      setLoading();
    }
  }, [item.loadingOriginal]);

  useEffect(() => {
    onItemLoad();
  }, []);

  const hasPreview = !!item.original && item.isImage;
  const isIcon = !item.isImage;
  const Icon = () => getFileTypeIcon(item.type, 120, 128, "none");

  const classes = useStyles({ loading });
  return (
    <div className={classes.container}>
      {hasPreview && (
        <img
          className={classes.image}
          src={item.original}
          alt=""
          onLoad={onLoad}
          onError={onError}
        />
      )}
      {isIcon && <Icon />}
      <CircularProgress className={classes.spinner} />
    </div>
  );
};

type StyleProps = {
  loading: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    position: "relative",
    height: "100%",
    width: "100%",
    color: "#fff",
    flex: 1,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spinner: {
    display: ({ loading }) => (loading ? "block" : "none"),
    color: theme.palette.primary.light,
  },
  image: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    objectFit: "contain",
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

export default CarouselItem;
