import React, { FC, useRef, useState } from "react";
// import { Customer } from "src/types";
// import { SvgIconProps, SvgIconTypeMap } from "@material-ui/core/SvgIcon";
// import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import IconButton from "@material-ui/core/IconButton/IconButton";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
// import { TFunction } from "i18next";
// import CustomerIcon from "src/pages/Customers/components/icons/CustomerIcon";
// import ViewDetailsIcon from "src/pages/Customers/components/icons/ViewDetailsIcon";
// import Link from "@material-ui/core/Link/Link";
// import { Link as RouterLink, useLocation } from "react-router-dom";
import { useStyles } from "./style";

interface ActionTypes {
  icon: JSX.Element;
  label: string;
  attr?: {
    onClick: (e: React.MouseEvent) => void;
  };
}

interface DropdownMenuTypes {
  menuIcon: JSX.Element;
  menuActions: ActionTypes[];
}

const DropdownMenu: FC<DropdownMenuTypes> = ({ menuIcon, menuActions }) => {
  const classes = useStyles();
  //   const { t } = useTranslation("customers");
  //   const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleActionsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleActionsClose = () => {
    setIsOpen(false);
  };
  return (
    <span ref={ref}>
      <IconButton onClick={(e) => handleActionsClick(e)}>{menuIcon}</IconButton>
      <Menu
        id={uniqueId("menu")}
        anchorEl={ref.current}
        open={isOpen}
        onClick={handleActionsClose}
        onClose={handleActionsClose}
        elevation={1}
        MenuListProps={{ className: classes.actionMenu }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {menuActions.map((action) => (
          <MenuItem key={uniqueId("i")} {...action.attr}>
            {action.icon}
            <Typography variant="button" className={classes.listItemTypography}>
              {action.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </span>
  );
};

export default DropdownMenu;
