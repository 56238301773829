import { withStyles, Tooltip } from "@material-ui/core";

const Hint = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #dadde9",
    "& svg": {
      marginRight: theme.spacing(1),
      verticalAlign: "sub",
    },
  },
}))(Tooltip);

export default Hint;
