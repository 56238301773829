import React, { useCallback, useMemo } from "react";
import { Typography, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { useStyles } from "src/pages/ServiceOrdersDetails/components/styles";
import MonteraCard from "src/components/MonteraCard";
import CustomerIcon from "src/pages/ServiceOrdersDetails/components/icons/CustomerIcon";
import EditIcon from "src/pages/ServiceOrdersDetails/components/icons/EditIcon";
import { useAppContext } from "src/providers/AppProvider";
import PointLoader from "src/components/Preloader/PointLoader";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";

const CustomerInfo = () => {
  const { serviceOrderDetails } = useSODetailsContext();
  const { customerInfo, loading, serviceOrder } = serviceOrderDetails;
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const customer = !!customerInfo ? customerInfo.customer : undefined;
  const {
    state: { user },
  } = useAppContext();
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();

  const handleEditCustomerInfo = useCallback(() => {
    toggleModal({ id: "editCustomerInfoSO", status: true });
  }, []);

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <MonteraCard
      headerIcon={<CustomerIcon />}
      title={t("labels.summary_cards.customer_info")}
      action={
        isIntegrated && disabledByStatus ? (
          <IconButton onClick={handleEditCustomerInfo}>
            <EditIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        ) : (
          <></>
        )
      }
    >
      {!customer || loading ? (
        <PointLoader />
      ) : (
        <div className={classes.contentWrap}>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.name")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {customer?.name}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.address")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {customer?.address}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.email")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              <a href={`mailto:${customer?.email}`} className={classes.emailLink}>
                {customer?.email}
              </a>
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className={classes.caption}>
              {t("labels.details.phone_number")}
            </Typography>
            <Typography variant="subtitle1" className={classes.displayValue}>
              {customer?.phone}
            </Typography>
          </div>
        </div>
      )}
    </MonteraCard>
  );
};

export default CustomerInfo;
