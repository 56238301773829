import React, { useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
import SuccessIcon from "./icons/SuccessIcon";
import { useStyles } from "./styles";
import { useAppContext } from "src/providers/AppProvider";
import { useSellersContext } from "src/providers/SellersStore";
import { WarehousesDetailsType } from "../../../type";
import { useParams } from "react-router-dom";
import { SaveButton } from "src/components/Buttons";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
}

const SuccessSellerCreate: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { warehousesID } = useParams<WarehousesDetailsType>();
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    callAPI: { getWarehousesSellers },
  } = useSellersContext();
  const handleModal = useCallback(() => {
    toggleModal({ id: "sellersModal", status: false });
    getWarehousesSellers(Number(warehousesID));
    onClose();
  }, []);

  return (
    <Grid container justify="center">
      <Grid item>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">Seller was successfully created!</Typography>
        </div>

        <div className={classes.btnGroup}>
          <SaveButton
            type="button"
            onClick={handleModal}
            label={t("labels.ok", { ns: "common" })}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessSellerCreate;
