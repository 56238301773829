import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import { ServicesProvider, useServicesContext } from "src/providers/ServicesStore";
import { Service } from "src/types";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import { columns } from "src/pages/ServiceOrdersDetails/tabs/Services/ServiceTable";
import MainTable from "src/components/MainTableWithActions";
import PageActions from "src/pages/ServiceOrdersDetails/tabs/Services/PageActions";
import { useTranslation } from "react-i18next";
import NoServices from "./NoServices";
import IconButton from "@material-ui/core/IconButton";
import TabsContainer from "src/components/TabsContainer";
import DeleteIcon from "./../../components/icons/DeleteIcon";
import ConformationDialog from "../../components/ConformationDialog";
import SearchIcon from "../../../../assets/icons/SearchIcon";
import { isIncompleteOrNewOrMissingInformation } from "../../../../utils/functions";

const Services = React.memo(() => {
  const { t } = useTranslation(`orders`);
  const { push } = useHistory();
  const [deletingServiceId, setDeletingServiceId] = useState<number | null>(null);
  const {
    relatedServicesState: { services, loading, currentPage, pageSize, totalCount, sortBy, des },
    serviceOrderDetails: { serviceOrder },
    callAPI: { setContextData, setROContextData, getRelatedServices },
  } = useSODetailsContext();

  const { callServicesAPI } = useServicesContext();

  const handleDeleteService = (service: Partial<Service>) => {
    setDeletingServiceId(service.id!);
  };

  const handleViewService = (service: Partial<Service>) => {
    push(`/services/details/${service.id}/summary`);
  };

  const rowActions = (data: Partial<Service>) => {
    return (
      <>
        <IconButton onClick={() => handleViewService(data)}>
          <SearchIcon style={{ width: 16, height: 16, opacity: "0.7" }} />
        </IconButton>
        {isIncompleteOrNewOrMissingInformation(serviceOrder.statusMatchingName) && (
          <IconButton onClick={() => handleDeleteService(data)}>
            <DeleteIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        )}
      </>
    );
  };

  const setSortingData = (sortBy: string, des: boolean) => {
    setROContextData("sortBy", sortBy);
    setROContextData("des", des);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    event?.preventDefault();
    setContextData("currentPage", page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContextData("pageSize", Number(event.target.value));
  };

  const onAcceptDelete = async () => {
    if (deletingServiceId) {
      callServicesAPI.deleteServices([deletingServiceId]).then(() => {
        setDeletingServiceId(null);
        getRelatedServices(serviceOrder.id!);
      });
    }
  };
  const onCancelDelete = () => {
    setDeletingServiceId(null);
  };
  if (loading) {
    return <PreloaderLine />;
  }
  if (services.length <= 0) {
    return <NoServices />;
  }

  return (
    <TabsContainer title={t("labels.services", { ns: "common" })}>
      <PageActions />
      <PreloaderLine loading={loading} />
      <MainTable<Service>
        textAlign="left"
        data={services}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={totalCount}
        columnsData={columns}
        rowActions={rowActions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        des={des}
        setSortingData={setSortingData}
      />
      {deletingServiceId && (
        <ConformationDialog
          onAccept={onAcceptDelete}
          onCancel={onCancelDelete}
          deleteServiceOrder={false}
        />
      )}
    </TabsContainer>
  );
});

const ServicesPage = React.memo(() => (
  <ServicesProvider>
    <Services />
  </ServicesProvider>
));

export default React.memo(ServicesPage);
