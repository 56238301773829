import { Button, Tooltip, IconButton } from "@material-ui/core";
import React from "react";
import { TFunction } from "i18next";

import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import RefreshIcon from "src/assets/icons/RefreshIcon";

interface Props {
  type?: "submit" | "button";
  disabled?: boolean;
  onClick?: () => void;
  label?: TFunction | React.ReactNode;
  customStyle?: {
    margin: string | number;
  };
  className?: string;
}

export const SaveButton: React.FC<Props> = ({ type, disabled, onClick, label }) => {
  const { submitBtn } = useStyles();
  return (
    <Button type={type} disabled={disabled} className={submitBtn} onClick={onClick}>
      {label}
    </Button>
  );
};

export const CancelButton: React.FC<Partial<Props>> = ({ onClick, label }) => {
  const { cancelBtn } = useStyles();
  return (
    <Button type="button" className={cancelBtn} onClick={onClick}>
      {label}
    </Button>
  );
};

export const MonteraRefreshButton: React.FC<Partial<Props>> = ({
  onClick,
  customStyle = {},
  disabled = false,
  className = "",
}) => {
  const { t } = useTranslation("common");
  return (
    <Tooltip placement="bottom" title={`${t("labels.refresh")}`} arrow={true}>
      <span>
        <IconButton
          disabled={disabled}
          size="medium"
          className={className}
          style={customStyle}
          onClick={onClick}
        >
          <RefreshIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
