import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    root: { width: "100%" },
    icon: {
      height: 70,
      width: 70,
    },
    inputRoot: {
      paddingRight: "35px !important",
      maxHeight: 56,
    },
    endAdornment: {
      top: 4,
    },
  };
});
