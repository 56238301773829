import React from "react";
import compose from "lodash/fp/compose";
import dateFns from "@date-io/date-fns";
import formatISO from "date-fns/formatISO";
import format from "date-fns/fp/format";
import parseISO from "date-fns/parseISO";
import set from "date-fns/set";
import locale from "date-fns/locale/en-GB";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { useStyles } from "./styles";

const START_DATE_OBJECT = { hours: 0, minutes: 0, seconds: 0 };
const END_DATE_OBJECT = { hours: 23, minutes: 59, seconds: 59 };

type Props = Omit<KeyboardDatePickerProps, "value" | "onChange"> & {
  value?: ParsableDate;
  onChange?: (date: string | null) => void;
  style?: React.CSSProperties;
  disableKeyboard?: boolean;
  endDate?: boolean;
};

const defaultDateFormat = "dd.MM.yyyy";
export const formatDate = compose(format(defaultDateFormat), parseISO);
const noop = () => {};

const prepareValue = (value?: any) => {
  if (value === "" || value === null) {
    return null;
  }
  return parseISO(value);
};

const DatePicker = ({
  value = null,
  onChange = noop,
  placeholder,
  endDate = false,
  ...rest
}: Props) => {
  const { root } = useStyles();

  const formatDateOnSubmit = (date: MaterialUiPickersDate) => {
    if (date instanceof Date && !isNaN(date.valueOf())) {
      if (endDate) return formatISO(set(date, END_DATE_OBJECT));
      return formatISO(set(date, START_DATE_OBJECT));
    }
    return null;
  };

  const handleChange = (date: MaterialUiPickersDate) => {
    let format = formatDateOnSubmit(date);
    if (format) {
      format = new Date(format).toISOString();
    }
    onChange(format);
  };

  return (
    <MuiPickersUtilsProvider utils={dateFns} locale={locale}>
      <KeyboardDatePicker
        disableToolbar
        variant="dialog"
        inputVariant="outlined"
        className={root}
        format={defaultDateFormat}
        value={prepareValue(value)}
        onChange={handleChange}
        placeholder={placeholder || defaultDateFormat}
        autoOk
        clearable
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
