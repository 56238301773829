import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import NavigationLogoIcon from "src/assets/icons/NavigationLogoIcon";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      padding: 0,
      margin: 0,
    },
  },
  root: {
    padding: 30,
    backgroundColor: theme.palette.primary.main,
    height: "100vh",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& > h3": {
      fontSize: "1.5rem",
      padding: "30px 0",
      color: "white",
      textAlign: "center",
    },
  },
  logo: {
    width: 167,
    height: 57,
  },
}));

export const SystemError = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NavigationLogoIcon className={classes.logo} fill="#2B59C3" />
      <Typography variant="h3" component="h3">
        Ooops, Something went wrong.
      </Typography>
      <Typography variant="h3" component="h3">
        Please contact administrator.
      </Typography>
    </div>
  );
};
