import React from "react";
import OrdersIcon from "../../assets/icons/MainNavListIcons/OrdersIcon";
import HomeIcon from "../../assets/icons/MainNavListIcons/HomeIcon";
import CustomersIcon from "src/assets/icons/MainNavListIcons/CustomersIcon";
import AnalyticsIcon from "src/assets/icons/MainNavListIcons/AnalyticsIcon";
import SellersIcon from "src/assets/icons/MainNavListIcons/SellersIcon";
import WarehouseIcon from "src/assets/icons/MainNavListIcons/WarehouseIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import NewsIcon from "src/assets/icons/SecondaryNavListIcons/NewsIcon";
import { TFunction } from "i18next";

export interface NavigationListType {
  title: string;
  link: string;
  icon: SvgIconProps;
}

export const mainNavigationList = (t: TFunction) => [
  {
    title: t("nav_menu.home"),
    link: "home",
    icon: <HomeIcon />,
  },
  {
    title: t("nav_menu.orders"),
    link: "orders",
    icon: <OrdersIcon />,
  },
  {
    title: t("nav_menu.warehouses"),
    link: "warehouses",
    icon: <WarehouseIcon />,
  },
  {
    title: t("nav_menu.sellers"),
    link: "sellers",
    icon: <SellersIcon />,
  },
  {
    title: t("nav_menu.customers"),
    link: "customers",
    icon: <CustomersIcon />,
  },
  {
    title: t("nav_menu.analytics"),
    link: "analytics",
    icon: <AnalyticsIcon />,
  },
];
export const secondaryNavigationList = (t: TFunction) => [
  {
    title: t("nav_menu.news"),
    link: `tba`,
    icon: <NewsIcon />,
  },
];
