import React from "react";
import { useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const maxEmailsCountToShowBtn = 2;

interface ISharedEmailsProps {
  sharedEmailsArray: string[];
  translation: TFunction;
}

function SharedEmails({ sharedEmailsArray, translation }: ISharedEmailsProps) {
  const { t } = useTranslation("common");
  const [expanded, setExpand] = useState<boolean>(false);
  if (!sharedEmailsArray || !Array.isArray(sharedEmailsArray)) return null;
  return (
    <Grid container alignItems="center" style={{ paddingLeft: 40 }}>
      <Typography variant="body2" color="textSecondary" style={{ lineHeight: "20px" }}>
        <img
          src="/images/utils/shareIcon.png"
          alt="share"
          style={{ marginRight: 8, verticalAlign: "text-top" }}
          width="16px"
        />
        {translation("labels.shared_by_email_icon")}
        {": "}
        {getEmailLinks(sharedEmailsArray, expanded)}
        {sharedEmailsArray.length > maxEmailsCountToShowBtn && (
          <Typography
            style={{ cursor: "pointer", verticalAlign: "-1px" }}
            display="inline"
            variant="body2"
            onClick={() => setExpand(!expanded)}
          >
            {t(`labels.${!expanded ? "show_more" : "show_less"}`)}
          </Typography>
        )}
      </Typography>
    </Grid>
  );
}

export default SharedEmails;

function getEmailLinks(sharedEmailsArray: string[], expanded: boolean) {
  const link = (email: string, key: number) => (
    <span key={key}>
      <a href={`mailto:${email}`}>{email}</a>
      {"; "}
    </span>
  );
  return sharedEmailsArray.map((email, key) => {
    if (!expanded) {
      if (key < maxEmailsCountToShowBtn) return link(email, key);
      else return null;
    } else return link(email, key);
  });
}
