import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const OrdersIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.333 12.7332L16.9997 10.0666L14.333 7.3999"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.4668 15.9333V12.2C8.4668 11.0218 9.42192 10.0667 10.6001 10.0667H17.0001"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.2 15.9333C3.26902 15.9333 2.53096 15.9333 1.99936 15.9333C1.44708 15.9333 1 15.4856 1 14.9333V2C1 1.44772 1.44772 1 2 1H14.9333C15.4856 1 15.9333 1.44697 15.9333 1.99926C15.9333 2.49347 15.9333 3.20388 15.9333 4.2"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default OrdersIcon;
