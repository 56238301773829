import React, { FC } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card/Card";
import Typography from "@material-ui/core/Typography/Typography";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  detailsCard: {
    display: "inline-block",
    width: 386,
    height: 354,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    "&:first-child": {
      marginLeft: 0,
    },
    "@media screen and (min-width: 1366px)": {
      width: 334,
    },
    "@media screen and (min-width: 1440px)": {
      width: 364,
    },
    "@media screen and (min-width: 1920px)": {
      width: 518,
    },
  },
  header: {
    display: "flex",
    minHeight: 64,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(43, 89, 195, 0.1)",
    padding: "13px 16px",
  },

  title: {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  actionBtn: {
    display: "inline-block",
  },
}));
interface Props {
  headerIcon?: JSX.Element;
  title: string;
  actionIcon?: JSX.Element;
  action?: JSX.Element;
}

const MonteraCard: FC<Props> = ({ headerIcon = {}, title = "", action, children }) => {
  const classes = useStyles();
  return (
    <Card className={classes.detailsCard}>
      <div className={classes.header}>
        <Grid container justify={"flex-start"}>
          {headerIcon}
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        {action}
      </div>
      <CardContent style={{ height: "85%" }}>{children}</CardContent>
    </Card>
  );
};
export default MonteraCard;
