import React, { useState, useEffect } from "react";
import { WarehousesReq, WarehousesState, Warehouse } from "src/providers/AnalyticsStore/types";
import AnalyticsList from "src/pages/Analytics/components/AnalyticsList";
import WarehouseFieldList from "./WarehouseFieldList";
import WarehouseTableDetails from "./WarehouseTableDetails";
import { requestGet } from "src/utils/crud";
import NoData from "../../../../components/NoData";
import AnalyticsLoading from "../../../../components/Loading";
import WarehouseListHeader from "./WarehouseListHeader";

const SoldByWarehouse: React.FC = () => {
  const [data, setData] = useState<WarehousesState>({
    warehouses: [],
    loading: true,
    success: false,
    TotalWithoutVat: 0,
  });

  const getWarehouses = async () => {
    return requestGet<WarehousesReq>(`Reports/Warehouses`)
      .then((res) => {
        setData((prev: WarehousesState) => ({
          ...prev,
          warehouses: [...res.data.warehouses],
          loading: false,
          success: res.data.success,
          TotalWithoutVat: res.data.TotalWithoutVat,
        }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  if (data.loading) {
    return <AnalyticsLoading loading={data.loading} />;
  }

  if (!data.loading && data.warehouses?.length < 1) {
    return <NoData />;
  }

  return (
    <>
      {data.warehouses && <WarehouseListHeader />}
      <AnalyticsList<Warehouse>
        listEntities={data.warehouses}
        entityFields={(ent) => <WarehouseFieldList data={ent} />}
        entityDetails={(ent) => <WarehouseTableDetails warehouseId={ent.warehouseId} />}
        total={data.TotalWithoutVat}
      />
    </>
  );
};

export default SoldByWarehouse;
