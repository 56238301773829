import React from "react";
import MicroFrontend from "./MicroFrontend";
import { msalInstance } from "../../App";

function NewsReal() {
  return (
    <div style={{ width: "100%" }}>
      <MicroFrontend
        env={process.env}
        host={process.env.REACT_APP_NEWS_APP_Domain}
        name="Blogs"
        msalInstance={msalInstance}
      />
    </div>
  );
}

export default NewsReal;
