import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ReferencesIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3332 12.7332L16.9999 10.0666L14.3332 7.3999"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.46652 15.9331V12.1997C8.46652 11.0215 9.42165 10.0664 10.5999 10.0664H16.9999"
      stroke="#141E30"
      strokeLinecap="round"
      fill="none"
      strokeLinejoin="round"
    />
    <path
      d="M4.2 15.9333C3.26902 15.9333 2.53096 15.9333 1.99936 15.9333C1.44708 15.9333 1 15.4856 1 14.9333V2C1 1.44772 1.44772 1 2 1H14.9333C15.4856 1 15.9333 1.44697 15.9333 1.99926C15.9333 2.49347 15.9333 3.20388 15.9333 4.2"
      stroke="#141E30"
      strokeLinecap="round"
      fill="none"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default ReferencesIcon;
