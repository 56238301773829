import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ArrowUpIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7998 9.59961L7.99981 4.79961L3.1998 9.59961"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default ArrowUpIcon;
