import i18next from "i18next";
import format from "date-fns/format";
import { OrdersFilterParams } from "src/providers/ServiceOrdersStore/types";
import { parseISO } from "date-fns/esm";

export interface ConfigType {
  key: keyof OrdersFilterParams;
  multi?: boolean;
  label?: () => string;
  format?: (value: any) => any;
}

const formats = {
  date: (item: Date) => format(parseISO(`${item}`), "dd.MM.yyyy"),
  displayText: (item: { displayText: string }) => item && item.displayText,
};

export const config: ConfigType[] = [
  {
    key: "warehouses",
    label: () => i18next.t("labels.row.warehouse", { ns: "orders" }),
    format: formats.displayText,
    multi: true,
  },
  {
    key: "seller",
    label: () => i18next.t("labels.row.seller", { ns: "orders" }),
    format: formats.displayText,
  },
  {
    key: "customerKey",
    label: () => i18next.t("labels.row.customer", { ns: "orders" }),
    format: formats.displayText,
  },
  {
    key: "deliveryAddressZipCode",
    label: () => i18next.t("list_filter.zip_label", { ns: "orders" }),
  },
  {
    key: "ids",
    label: () => i18next.t("list_filter.so_id_label", { ns: "orders" }),
    format: formats.displayText,
    multi: true,
  },
  {
    key: "externalIds",
    label: () => i18next.t("list_filter.so_external_id_label", { ns: "orders" }),
    multi: true,
  },
  {
    key: "billingIds",
    label: () => i18next.t("list_filter.billing_reference", { ns: "orders" }),
    multi: true,
  },
  {
    key: "referenceOthers",
    label: () => i18next.t("list_filter.so_ref", { ns: "orders" }),
    multi: true,
  },
  {
    key: "createdDateFrom",
    label: () => i18next.t("list_filter.created_date_from_label", { ns: "orders" }),
    format: formats.date,
  },
  {
    key: "createdDateTo",
    label: () => i18next.t("list_filter.created_date_to_label", { ns: "orders" }),
    format: formats.date,
  },
  {
    key: "deliveryDateFrom",
    label: () => i18next.t("list_filter.delivery_date_from_label", { ns: "orders" }),
    format: formats.date,
  },
  {
    key: "deliveryDateTo",
    label: () => i18next.t("list_filter.delivery_date_to_label", { ns: "orders" }),
    format: formats.date,
  },
  {
    key: "deliveryAddress",
    label: () => i18next.t("list_filter.delivery_address", { ns: "orders" }),
  },
  {
    key: "serviceTypes",
    label: () => "Service Type",
    format: formats.displayText,
    multi: true,
  },
];
