import { DetailsType } from "src/pages/ServiceDetails/type";
import { TFunction } from "i18next";
import { Service } from "src/types";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import { ServiceNavLinks } from "src/pages/ServiceDetails/type";
import { compact } from "lodash";

export const navsTabsLink: DetailsType[] = ["summary"];

export const serviceDetailsRouts = (data: ServiceNavLinks[]): ServiceNavLinks[] => {
  const res = navsTabsLink.map((ent, index) => ({
    path: ent,
    id: index,
    label: ent,
  }));

  return [...res, ...data];
};

export const dataList = (t: TFunction, elem: Partial<Service>) =>
  compact([
    {
      label: t("labels.row.customer"),
      value: elem?.customer?.displayText,
    },
    elem.assemblyZone &&
      elem.partner && {
        label: t("labels.row.assembly_zone"),
        value: elem.assemblyZone.displayText,
      },
    {
      label: t("labels.row.created"),
      value: !!elem.createdDate ? format(new Date(elem.createdDate), "dd.MM.yyyy") : "",
    },
    {
      label: t("labels.row.owner"),
      value: elem?.owner?.displayText ?? "",
    },
    {
      label: t("labels.row.service_type"),
      value: elem?.serviceType?.displayText,
    },
    {
      label: t("labels.row.date_range"),
      value: `${!!elem.startDate ? format(new Date(elem.startDate), "dd.MM.yyyy") : " "} - ${
        !!elem.deliveryDate ? format(new Date(elem.deliveryDate), "dd.MM.yyyy") : " "
      } `,
    },
    elem.capacity && {
      label: t("labels.row.capacity"),
      value: elem.capacity,
    },
    {
      label: t("labels.row.status"),
      value: !!elem.status?.displayText ? (
        <span
          style={{
            textAlign: "center",
            width: "fit-content",
            padding: 3,
            borderRadius: 5,
            backgroundColor: elem.statusColor,
          }}
        >
          {elem.status?.displayText}
        </span>
      ) : (
        <Typography variant="caption">none</Typography>
      ),
    },
  ]);
