import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomerDetailsProvider,
  useCustomerDetailsContext,
} from "src/providers/CustomerDetailsStore";
import { useCustomersStyles } from "src/pages/Customers/styles";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper/Paper";
import EditDetailsIcon from "src/assets/icons/Warehouse/EditDetailsIcon";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import DetailsWrapper from "src/components/DetailsWrapper";
import Summary from "./tabs/Summary/Summary";
import DeliveryAddresses from "./tabs/DeliveryAddresses";
import InvoiceAddresses from "./tabs/InvoiceAddresses";
import RelatedOrders from "./tabs/RelatedOrders";
import { CustomerDetailsType, CustomerNavLinks, DetailsType } from "./type";
import { dataList, navItems, navsTabsLinkBusiness, navsTabsLinkPerson } from "./data";
import PreloaderLine from "../../../components/Preloader/PreloaderLine";
import { useAppContext } from "src/providers/AppProvider";
import PageActions from "./components/PageActions";
import EditCustomer from "../EditCustomer";

const DETAILS_EDITABLE = false;

interface DetailsSwitcherType<T> {
  [k: string]: T;
}

export const DetailsSwitcher: DetailsSwitcherType<JSX.Element> = {
  summary: <Summary editable={DETAILS_EDITABLE} />,
  delivery_addresses: <DeliveryAddresses />,
  invoice_addresses: <InvoiceAddresses />,
  related_orders: <RelatedOrders />,
};

const CustomerDetails: FunctionComponent = () => {
  const { type } = useParams<CustomerDetailsType>();
  const tabs: DetailsType = !!type ? type : "summary";
  const {
    state: { featureFlags, user },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    selectedCustomer: { customer, loading },
    relatedOrders,
    deliveryAddresses,
    invoiceAddresses,
    callAPI: { setContextData },
  } = useCustomerDetailsContext();
  const history = useHistory();
  const { t } = useTranslation("customers");
  const classes = useCustomersStyles({});

  const routeHandler = (event: React.ChangeEvent<HTMLDivElement>) => {
    history.push(event.currentTarget.id);
  };

  const handleEditCustomerModal = useCallback(() => {
    toggleModal({ id: "editCustomerModal", status: true });
  }, []);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <>
      <PreloaderLine loading={loading} />
      <PageActions />
      <Paper className={classes.mainWrapper2}>
        <Grid
          className={classes.head}
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={4} container alignItems="center">
            <Typography variant={"h3"}>{customer.name}</Typography>
          </Grid>
          <Grid>
            {DETAILS_EDITABLE && isIntegrated && (
              <Button onClick={handleEditCustomerModal}>
                <EditDetailsIcon className={classes.svg} />
                {t("labels.details.edit_details")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="flex-start">
          {dataList(t, { ...customer, dkFlag: featureFlags.dk }).map(
            (ent) =>
              ent && (
                <Grid item xs key={uniqueId("customers")} className={classes.dataWrapper}>
                  <Typography variant="caption">{ent.label}</Typography>
                  <Typography variant="body2">{ent?.value}</Typography>
                </Grid>
              )
          )}
        </Grid>
      </Paper>
      <EditCustomer customer={customer} setContextData={setContextData} />
      {/* Sub Nav options for Customer = Person */}
      {!customer.isBusiness && (
        <DetailsWrapper<CustomerNavLinks>
          navItems={navItems({
            IATotal: invoiceAddresses.totalCount,
            DATotal: deliveryAddresses.totalCount,
            ROTotal: relatedOrders.totalCount,
          })}
          handleChange={routeHandler}
          selected={navsTabsLinkPerson.indexOf(tabs)}
        >
          {DetailsSwitcher[tabs]}
        </DetailsWrapper>
      )}
      {/* Sub Nav options for Customer = isBusiness */}
      {customer.isBusiness && (
        <DetailsWrapper<CustomerNavLinks>
          navItems={navItems({
            IATotal: invoiceAddresses.totalCount,
            DATotal: deliveryAddresses.totalCount,
            ROTotal: relatedOrders.totalCount,
          })}
          handleChange={routeHandler}
          selected={navsTabsLinkBusiness.indexOf(tabs)}
        >
          {DetailsSwitcher[tabs]}
        </DetailsWrapper>
      )}
    </>
  );
};

const CustomerDetailsPage = () => {
  return (
    <CustomerDetailsProvider>
      <CustomerDetails />
    </CustomerDetailsProvider>
  );
};

export default CustomerDetailsPage;
