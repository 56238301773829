import React from "react";
import { TextField, Grid, Typography, TextFieldProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  limit?: number;
  value?: any;
  label?: string;
}

const MonteraTextArea = (props: Props & TextFieldProps) => {
  const limit = !!props.limit ? props.limit : 1e10;
  const value = !!props.value ? props.value : "";
  const { t } = useTranslation("common");
  return (
    <>
      <TextField
        fullWidth
        rows="4"
        multiline
        variant="outlined"
        inputProps={{ maxLength: limit }}
        {...props}
      />
      {limit !== 1e10 && (
        <Grid container justify="flex-end">
          <Typography color={value.length >= Number(limit) ? "error" : "initial"} variant="caption">
            {limit - value.length} {t("labels.text_area.limit")}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default MonteraTextArea;
