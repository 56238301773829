import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const InvoiceAddressIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1H2.6C1.71634 1 1 1.71634 1 2.6V15.4C1 16.2837 1.71634 17 2.6 17H12.2C13.0837 17 13.8 16.2837 13.8 15.4V5.8L9 1Z"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path d="M9 1V5.8H13.8" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.6002 9.7998H4.2002"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.6002 13H4.2002"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M5.8002 6.59961H5.0002H4.2002"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
export default InvoiceAddressIcon;
