import React, { useState } from "react";
import createCtx from "src/utils/createCtx";
import { useEffect } from "react";
import { requestMetaGet } from "../../utils/crud/index";
import { FeatureFlagStore, Props } from "./types";
import { useAppContext } from "src/providers/AppProvider";

const [useCtx, Provider] = createCtx<FeatureFlagStore>();

const initialState = {};

function FeatureFlagProvider(props: Props): React.ReactElement {
  const {
    state: { user },
  } = useAppContext();
  const [featureFlagStore, setFeatureFlagStore] = useState<FeatureFlagStore>(initialState);

  useEffect(() => {
    !!user.id && fetchFeatureFlags();
  }, [user.id]);

  const fetchFeatureFlags = async () => {
    const res = await requestMetaGet<FeatureFlagStore>(
      "Configuration/feature-flags/partner-portal"
    );
    setFeatureFlagStore(res.data);
  };

  return <Provider value={featureFlagStore}>{props.children}</Provider>;
}

export { useCtx as useFeatureFlagContext, FeatureFlagProvider };
