import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const AddAddressIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 6.6L8 1L15 6.6V15.4C15 16.2837 14.3036 17 13.4444 17H2.55556C1.69645 17 1 16.2837 1 15.4V6.6Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12 15.125V14.125C12 13.0204 11.1046 12.125 10 12.125H6C4.89543 12.125 4 13.0204 4 14.125V15.125"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8 10.125C9.10457 10.125 10 9.22957 10 8.125C10 7.02043 9.10457 6.125 8 6.125C6.89543 6.125 6 7.02043 6 8.125C6 9.22957 6.89543 10.125 8 10.125Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default AddAddressIcon;
