import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    tableHeader: {
      background: "transparent",
    },
    tableHeaderCell: {
      background: "#fff",
    },
    nameColumn: {
      maxWidth: 200,
    },
    locationColumn: {
      maxWidth: 200,
      minWidth: 200,
    },
    categoryColumn: {
      maxWidth: 200,
      minWidth: 200,
    },
    descriptionColumn: {
      minWidth: 250,
    },
  };
});
