import React from "react";
import { Typography, List, ListItem, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";
import Preloader from "src/components/Preloader";
import { useStyles } from "src/pages/ServiceDetails/components/RelatedServices/styles";

const RelatedServices = () => {
  const {
    relatedServicesState: { relatedServices, loading: servicesLoading },
    serviceStatuses: { serviceStatuses, loading: statusesLoading },
  } = useServiceDetailsContext();
  const classes = useStyles();
  const findStatusColor = (statusKey: number) => {
    if (!serviceStatuses || serviceStatuses.length <= 0) return "";
    const match = serviceStatuses.find((listItem) => listItem.id === statusKey);
    return match?.color;
  };

  if (!relatedServices || servicesLoading || statusesLoading) {
    return <Preloader is />;
  }

  return (
    <div className={classes.contentWrap}>
      <List>
        {relatedServices &&
          relatedServices.map((service) => (
            <ListItem
              key={service.id}
              button
              className={classes.listItem}
              component={RouterLink}
              to={`/services/details/${service.id}/summary`}
            >
              <Grid container alignItems="center" wrap="nowrap" justify="space-between">
                <Grid item>
                  <Typography variant="caption" color="textSecondary" align="left">
                    {service.name}
                  </Typography>
                  {service.serviceType && (
                    <Typography variant="subtitle1">{service.serviceType.displayText}</Typography>
                  )}
                </Grid>
                <Grid
                  item
                  className={classes.statusWrap}
                  style={{
                    background: findStatusColor(service?.status?.key!),
                  }}
                >
                  <Typography variant="body2">{service.status?.displayText}</Typography>
                </Grid>
              </Grid>
            </ListItem>
          ))}
      </List>
    </div>
  );
};

export default RelatedServices;
