import React, { useCallback } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import SuccessIcon from "./icons/SuccessIcon";
import { useStyles } from "./styles";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";

const SuccessAddressCreateBusiness: React.FC = () => {
  const classes = useStyles();
  const {
    callAppAPI: { toggleModal },
  } = useAppContext();
  const { t } = useTranslation("customers");

  const handleModalClose = useCallback(() => {
    toggleModal({ id: "createCustomerModal", status: false });
  }, []);

  return (
    <Grid container justify="center">
      <Grid item>
        <div className={classes.titleWrapper}>
          <SuccessIcon className={classes.successIcon} />
          <Typography variant="h4">
            {t("labels.create_customer.addresses_form_success.title")}
          </Typography>
        </div>
        <div className={classes.centerBtn}>
          <Button onClick={handleModalClose} className={classes.submitBtn}>
            {t("labels.create_customer.members_form_success.ok")}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessAddressCreateBusiness;
