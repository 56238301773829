import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import { FullScreenExitIcon, FullScreenIcon } from "./icons";
import DownloadIcon from "./icons/DownloadIcon";

import { MonteraFile } from "./types";
import { useDownload } from "./hooks/useDownload";

type Props = {
  isFullscreen: boolean;
  onFullscreenToggle: () => void;
  renderAdditionalActions?: (file: MonteraFile) => React.Component;
  file: MonteraFile;
};

const CarouselActions = ({
  isFullscreen,
  onFullscreenToggle,
  renderAdditionalActions,
  file,
}: Props) => {
  const { isDownloading, download } = useDownload();
  const classes = useStyles();

  return (
    <div className={classes.actions}>
      {renderAdditionalActions && renderAdditionalActions(file)}
      <IconButton
        size="medium"
        className={classes.actionButton}
        disabled={!isDownloading}
        onClick={() => download(file!)}
      >
        <DownloadIcon height={26} width={26} />
      </IconButton>
      <IconButton size="medium" className={classes.actionButton} onClick={onFullscreenToggle}>
        {isFullscreen ? (
          <FullScreenExitIcon height={26} width={26} />
        ) : (
          <FullScreenIcon height={26} width={26} />
        )}
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: "20px",
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 4,

    display: "flex",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  actionButton: {
    color: "#fff",
  },
}));

export default CarouselActions;
