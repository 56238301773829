import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const WarehouseDetailsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09279 1.15527L15.0379 4.12782C15.543 4.37879 15.8625 4.89403 15.8628 5.45803V12.5401C15.8625 13.1041 15.543 13.6194 15.0379 13.8703L9.09279 16.8429C8.67413 17.0524 8.18124 17.0524 7.76258 16.8429L1.81749 13.8703C1.31289 13.6161 0.996104 13.0977 1.00004 12.5327V5.45803C1.00034 4.89403 1.31984 4.37879 1.82492 4.12782L7.77001 1.15527C8.18663 0.948243 8.67616 0.948243 9.09279 1.15527Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M1.23779 4.65552L8.43135 8.2523L15.6249 4.65552"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.43115 16.9915V8.2522"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.71582 2.67871L12.1472 6.39439"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default WarehouseDetailsIcon;
