import { downloadFile } from "src/utils/files";
import { useFlag } from "src/utils/hooks/useFlag";
import { getContent } from "../api";
import { MonteraFile } from "../types";

export const useDownload = () => {
  const [isDownloading, setIsDownloading, unsetIsDownloading] = useFlag(false);

  const download = async (currentFile: MonteraFile) => {
    setIsDownloading();
    try {
      const blob = await getContent(currentFile.fileServiceId);
      downloadFile(blob, currentFile.fileName);
    } finally {
      unsetIsDownloading();
    }
  };

  return { isDownloading, download };
};
