import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SODetailsProvider, useSODetailsContext } from "src/providers/SODetailsStore";
import { useWarehousesStyles } from "src/pages/Warehouses/styles";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper/Paper";
import EditDetailsIcon from "src/assets/icons/Warehouse/EditDetailsIcon";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import DetailsWrapper from "src/components/DetailsWrapper";
import RelatedOrdersPage from "src/pages/ServiceOrdersDetails/tabs/RelatedOrders";
import FilesPage from "./tabs/Files";
import CommentsPage from "./tabs/Comments";
import Services from "src/pages/ServiceOrdersDetails/tabs/Services";
import Summary from "src/pages/ServiceOrdersDetails/tabs/Summary";
import LineItems from "src/pages/ServiceOrdersDetails/tabs/LineItems";
import { DetailsType, SODetailsType, SONavLinks } from "src/pages/ServiceOrdersDetails/type";
import { dataList, serviceOrdersDetailsRouts } from "src/pages/ServiceOrdersDetails/data";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import { useAppContext } from "src/providers/AppProvider";
import EditFrom from "src/pages/Orders/modals/EditForm";
import EditStatusFrom from "src/pages/Orders/modals/EditStatus";
import { LineItemsProvider } from "src/providers/LineItemsStore";
import PageActions from "./components/PageActions";
import { FilesProvider } from "src/providers/FilesStore";
import Snackbar from "./components/Snackbar";
import { CommentsProvider, useCommentsContext } from "src/providers/CommentsStore";
import ServiceFileUploadDialog from "src/containers/FileUploadDislog/ServiceFileUploadDialog";
import { ServicesProvider } from "src/providers/ServicesStore";
import { ServiceOrdersProvider } from "src/providers/ServiceOrdersStore";
import { SellersProvider } from "src/providers/SellersStore";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";
import RelatedQuestionnaires from "./tabs/Questionnaire";
import { QuestionnaireProvider, useQuestionnaireContext } from "src/providers/QuestionnaireStore";

interface DetailsSwitcherType<T> {
  [k: string]: T;
}

const ServiceOrdersDetails: FunctionComponent = () => {
  const { type } = useParams<SODetailsType>();
  const tabs: DetailsType = !!type ? type : "summary";
  const {
    state: { modals, user },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const {
    serviceOrderDetails: { serviceOrder, loading },
    relatedServicesState: { totalCount },
    relatedServiceOrdersState,
    filesState,
  } = useSODetailsContext();
  const { relatedQuestionnaireState } = useQuestionnaireContext();
  const { comments } = useCommentsContext();
  const history = useHistory();
  const { t } = useTranslation("orders");
  const classes = useWarehousesStyles();

  const DetailsSwitcherList: DetailsSwitcherType<JSX.Element> = {
    summary: <Summary />,
    related_orders: <RelatedOrdersPage />,
    files: <FilesPage />,
    comments: <CommentsPage />,
    services: <Services />,
    line_items: <LineItems />,
    questionnaires: <RelatedQuestionnaires />,
  };

  const tabsArray = [
    {
      path: "related_orders",
      id: 1,
      label: "related_orders",
      count: relatedServiceOrdersState.totalCount,
    },
    {
      path: "files",
      id: 2,
      label: "files",
      count: filesState.totalCount,
    },
    {
      path: "services",
      id: 4,
      label: "services",
      count: totalCount || 0,
    },
    {
      path: "comments",
      id: 5,
      label: "comments",
      count: comments.totalCount || 0,
    },
    {
      path: "line_items",
      id: 6,
      label: "line_items",
      count: serviceOrder.lineItemCount || 0,
    },
    {
      path: "questionnaires",
      id: 7,
      label: "questionnaire",
      count: relatedQuestionnaireState.totalCount || 0,
    },
  ];
  const navItems = serviceOrdersDetailsRouts(tabsArray);
  const position = navItems.map((ent) => ent.path);
  const routeHandler = (event: React.ChangeEvent<HTMLDivElement>) => {
    history.push(event.currentTarget.id);
  };
  const handleModal = useCallback(() => {
    toggleModal({ id: "editSOStatusModal", status: true });
  }, []);
  const handleEditModal = useCallback(() => {
    toggleModal({ id: "serviceOrdersEditModal", status: true });
  }, []);

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName);
  }, [serviceOrder.statusMatchingName]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <>
      <PreloaderLine loading={loading} />
      <Snackbar status={serviceOrder.statusMatchingName} />
      <PageActions />
      <Paper className={classes.mainWrapper}>
        <Grid
          className={classes.head}
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={4} container alignItems="center">
            <Typography variant={"h3"}>{serviceOrder.name}</Typography>
          </Grid>
          <Grid>
            {isIntegrated && disabledByStatus && (
              <Button onClick={handleEditModal}>
                <EditDetailsIcon className={classes.svg} />
                {t("labels.btn.edit_details")}
              </Button>
            )}
            <Button
              onClick={handleModal}
              variant="contained"
              color="primary"
              size="large"
              style={{ padding: "16px 12px" }}
              disabled={!isIncompleteOrNewOrMissingInformation(serviceOrder?.statusMatchingName)}
            >
              {t("labels.btn.update_status")}
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          {dataList(t, serviceOrder).map((ent) => (
            <Grid item xs key={uniqueId("orders")} style={{ minWidth: ent.minWidth || "auto" }}>
              <Typography variant="caption">{ent.label}</Typography>
              <Typography variant="body2">{ent?.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
      {modals.serviceOrdersEditModal && <EditFrom />}
      <EditStatusFrom />
      <FilesProvider serviceOrder={serviceOrder} context="serviceOrder">
        <DetailsWrapper<SONavLinks>
          navItems={navItems}
          handleChange={routeHandler}
          selected={position.indexOf(tabs)}
        >
          {DetailsSwitcherList[tabs]}
        </DetailsWrapper>
        <ServiceFileUploadDialog context="serviceOrder" serviceOrder={serviceOrder} />
      </FilesProvider>
    </>
  );
};

const SODetailsPage = () => {
  return (
    <ServiceOrdersProvider>
      <SODetailsProvider>
        <QuestionnaireProvider>
          <LineItemsProvider>
            <ServicesProvider>
              <CommentsProvider context={"serviceOrder"}>
                <SellersProvider>
                  <ServiceOrdersDetails />
                </SellersProvider>
              </CommentsProvider>
            </ServicesProvider>
          </LineItemsProvider>
        </QuestionnaireProvider>
      </SODetailsProvider>
    </ServiceOrdersProvider>
  );
};

export default SODetailsPage;
