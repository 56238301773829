import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import React, { useEffect, useState } from "react";
import { TFunction } from "i18next";
import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { localStore } from "src/utils/functions";

type CountryType = {
  [key: string]: string
}

export interface Props {
  value?: string | undefined;
  name: string;
  label: TFunction | string;
  labelWidth?: number;
  onChangeHandler?: (value: string, country:CountryType) => void;
  required?: boolean;
  onErrorHandler?: (isError: boolean) => void;
  displayError?: boolean;
}
export interface StyleProps {
  label: TFunction | string;
  labelWidth?: number;
}
const PhoneInputField: React.FC<Props> = ({
  value,
  name,
  label,
  labelWidth = 158,
  required = true,
  onChangeHandler,
  onErrorHandler,
  displayError = true
}: Props) => {
  if (required) {
    label = label + " *";
  }
  const { t } = useTranslation("common");
  const styleProps: StyleProps = { label, labelWidth };
  const [activate, setActivate] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const classes = useStyles(styleProps);
  const { territory } = localStore.getObject("user");

  const PreferredCountries = ["dk", "no", "se"];

  useEffect(() => {
    displayError && onErrorHandler && onErrorHandler(error);
  }, [error]);

  const validateHandler = (value: string, country: any): boolean => {
    let maskLength: number = country.format.split(".").length;
    if (PreferredCountries.includes(country.iso2)) maskLength = maskLength - 1;
    else maskLength = maskLength - 2;
    if (activate && displayError) {
      if (required) {
        if (value.length < maskLength) {
          setError(true);
          return false;
        } else {
          setError(false);
          return true;
        }
      } else if (country.countryCode !== value && value.length < maskLength) {
        setError(true);
        return false;
      } else {
        setError(false);
        return true;
      }
    } else return true;
  };

  const onFocusIn = () => {
    setActivate(true);
  };

  return (
    <div className={classes.phoneInput}>
      <PhoneInput
        value={value}
        country={territory.alpha2}
        preferredCountries={PreferredCountries}
        masks={{ no: ".. .. .. ..", se: "...-...-...", dk: ".. .. .. .." }}
        countryCodeEditable={false}
        buttonClass={classes.buttonStyle}
        specialLabel={""}
        inputProps={{
          name: name,
          required: required,
        }}
        containerStyle={{
          textAlign: "left",
          width: "100%",
        }}
        inputClass={classes.phoneInputField}
        inputStyle={{ width: "100%" }}
        onChange={(value, country: CountryType, event) => {
          event.persist();
          if(onChangeHandler) {
            if(value === country.dialCode)
              onChangeHandler("", country);
            else 
              onChangeHandler(value, country);
          };
        }}
        onFocus={() => onFocusIn()}
        isValid={(value: string, country: object) => validateHandler(value, country)}
      />
      {error && (
        <Typography variant="subtitle1" color="error" className={classes.errorText}>
          {t("errors.invalid_phone_number")}
        </Typography>
      )}
    </div>
  );
};

export default React.memo(PhoneInputField);
