import React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import { useTranslation } from "react-i18next";
import { CustomerInfo, MainPropsLIType, PartnerInfo } from "src/providers/LineItemsStore/types";
import LICustomerIcon from "src/pages/ServiceOrdersDetails/components/icons/LICustomerIcon";
import LIIkeaBusinessIcon from "src/pages/ServiceOrdersDetails/components/icons/LIIkeaBusinessIcon";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { useStyles } from "src/pages/Warehouses/components/styles";
import Link from "@material-ui/core/Link/Link";
import { Link as RouterLink } from "react-router-dom";
import useCurrencyFormat from "src/utils/hooks/currency";

type Collection = "partnerSubtotal" | "customerSubtotal";
interface Props {
  data: MainPropsLIType;
  collection: Collection;
}

interface LineItemTitleProps {
  collection: Collection;
  partnerInfo: Partial<PartnerInfo>;
  customerInfo: Partial<CustomerInfo>;
}

export const LineItemTitle = ({ collection, partnerInfo, customerInfo }: LineItemTitleProps) => {
  const classes = useStyles();

  if (collection === "partnerSubtotal") {
    return <span className={classes.disabledLink}>{partnerInfo.partner?.name}</span>;
  }

  if (!customerInfo?.customer) return null;

  return (
    <Link
      className={classes.link}
      component={RouterLink}
      to={`/customers/details/${customerInfo.customer?.key}/summary`}
    >
      {customerInfo.customer?.name}
    </Link>
  );
};

const LineItemsServiceHead: React.FC<Props> = ({ data, collection = "partnerSubtotal" }) => {
  const { t } = useTranslation(`lineItems`);
  const { currencyFormat, prepareDigits } = useCurrencyFormat();
  const {
    LIData: { partnerInfo, customerInfo },
  } = useLineItemsContext();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid
        xs={8}
        item
        container
        alignItems="center"
        justify="flex-start"
        style={{
          paddingLeft: 12,
          marginRight: -12
        }}
      >
        {collection === "partnerSubtotal" ? <LIIkeaBusinessIcon /> : <LICustomerIcon />}

        <LineItemTitle
          collection={collection}
          customerInfo={customerInfo}
          partnerInfo={partnerInfo}
        />
      </Grid>
      <Grid xs item>
        <Typography variant="caption" color="textSecondary">
          {t("labels.qty")}
        </Typography>
        <Typography variant="subtitle1">{prepareDigits(data[collection]?.qty)}</Typography>
      </Grid>
      <Grid xs item>
        <Typography variant="caption" color="textSecondary">
          {t("labels.table.subtotal")}
        </Typography>
        <Typography variant="subtitle1">{currencyFormat(data[collection]?.subtotal)}</Typography>
      </Grid>
      <Grid xs item>
        <Typography variant="caption" color="textSecondary">
          {t("labels.table.subtotal_var")}
        </Typography>
        <Typography variant="subtitle1">{currencyFormat(data[collection]?.subtotalVat)}</Typography>
      </Grid>
    </Grid>
  );
};

export default LineItemsServiceHead;
