export type MonteraFile = {
  id: number;
  fileServiceId: number;
  fileName: string;
  description?: string;
  createdBy?: UserInfo;
  createdByName?: string;
  category?: FileCategory;
  relatedEntity: FileRelatedEntity;
  createdDate?: string;

  // questionnaire
  isValid?: boolean; // approved
  isSharedToPartner?: boolean;
  isSharedToContractor?: boolean;
};

export enum FILE_VIEW_TYPES {
  list = 1,
  carousel = 2,
}

export type FileRelatedEntity = {
  key: number;
  type: FileRelatedEntityType;
  displayText: string;
};

export enum FileRelatedEntityType {
  SO = 1,
  S = 2,
  WO = 3,
}

export interface FakeGalleryItem extends MonteraFile {
  original: string;
  thumbnail: string;
}

export interface CarouselItemData {
  original?: string;
  loadingOriginal: boolean;
  thumbnail?: string;
  loadingThumbnail: boolean;
  isImage: boolean;
  type: string;
}

export interface UserInfo {
  key: number;
  displayText: string;
}

export interface FileCategory {
  key: number;
  displayText: number;
}
