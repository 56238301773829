import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DescriptionIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.4444 6.55566H1" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 3H1" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 10.1113H1" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.4444 13.6665H1" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
export default DescriptionIcon;
