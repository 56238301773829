import React from "react";
import { Trans } from "react-i18next";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography";
import { LineItem } from "src/providers/AnalyticsStore/types";
import { UseTableRowProps } from "react-table";

const LineItemsTableFields = () => {
  return [
    {
      Header: () => <Trans ns={"analytics"} i18nKey="labels.reports.li_name" />,
      id: "lineItemName",
      accessor: (ent: Partial<LineItem>) => ent?.lineItemName,
      Cell: ({ row }: { row: UseTableRowProps<Partial<LineItem>> }) => (
        <Typography variant="body2">{row.original.lineItemName}</Typography>
      ),
    },
    {
      Header: () => <Trans ns={"analytics"} i18nKey="labels.reports.qty" />,
      id: "qty",
      accessor: (ent: Partial<LineItem>) => ent?.qty,
      Cell: ({ row }: { row: UseTableRowProps<Partial<LineItem>> }) => (
        <Typography variant="body2">
          {row.original.qty?.toLocaleString(navigator.language, {
            minimumFractionDigits: 1,
          })}
        </Typography>
      ),
    },
    {
      Header: () => <Trans ns={"analytics"} i18nKey="labels.reports.price_without_VAT" />,
      id: "priceWithoutVat",
      accessor: (ent: Partial<LineItem>) => ent?.priceWithoutVat,
      Cell: ({ row }: { row: UseTableRowProps<Partial<LineItem>> }) => (
        <Typography variant="body2">
          {`${row.original.priceWithoutVat?.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })} kr`}
        </Typography>
      ),
    },
    {
      Header: () => <Trans ns={"analytics"} i18nKey="labels.reports.total_without_vat" />,
      id: "subtotalWithoutVat",
      accessor: (ent: Partial<LineItem>) => ent?.subtotalWithoutVat,
      Cell: ({ row }: { row: UseTableRowProps<Partial<LineItem>> }) => (
        <Typography variant="body2">
          {`${row.original.subtotalWithoutVat?.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })} kr`}
        </Typography>
      ),
    },
    {
      Header: () => <Trans i18nKey="labels.reports.date_of_selling" ns={"analytics"} />,
      id: "dateOfSelling",
      accessor: (ent: Partial<LineItem>) => ent.dateOfSelling,
      Cell: ({ row }: { row: UseTableRowProps<Partial<LineItem>> }) => (
        <Typography variant="body2">
          {!!row.original.dateOfSelling
            ? format(new Date(row.original.dateOfSelling), "dd.MM.yyyy hh:mm")
            : `---`}
        </Typography>
      ),
    },
  ];
};

export default LineItemsTableFields;
