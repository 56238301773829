import React, { useState, useEffect } from "react";
import AnalyticsTable from "../../../../components/AnalyticsTable";
import { LineItem, LineItemsReq, LineItemsState } from "src/providers/AnalyticsStore/types";
import LineItemsTableFields from "./LineItemsTableFields";
import { requestGet } from "src/utils/crud";

const LineItemsTableDetails: React.FC<{ serviceId: number }> = React.memo(({ serviceId }) => {
  const [data, setData] = useState<LineItemsState>({
    lineItems: [],
    loading: false,
    success: false,
    TotalWithoutVat: 0,
  });

  const getLineItems = async (key: number | string) => {
    return requestGet<LineItemsReq>(`Reports/Service/${key}/lineItems`)
      .then((res) => {
        setData((prev: LineItemsState) => ({
          ...prev,
          lineItems: [...res.data.lineItems],
          loading: false,
          success: res.data.success,
          TotalWithoutVat: res.data.TotalWithoutVat,
        }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLineItems(serviceId);
  }, []);

  return (
    <AnalyticsTable<LineItem>
      data={data.lineItems}
      columnsData={LineItemsTableFields}
      total={data.TotalWithoutVat}
      customStyles={{ paddingLeft: "64px" }}
    />
  );
});

export default LineItemsTableDetails;
