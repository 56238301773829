import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, useFormContext } from "react-hook-form";
import { useServiceType } from "src/utils/hooks/useServiceType";
import { useSOContext } from "src/providers/ServiceOrdersStore";

interface Props {
  fieldName: string;
}

const ServiceTypeAutocomplete: React.FC<Props> = ({ fieldName }: Props) => {
  const {
    SOData: { responseObject },
  } = useSOContext();
  const [open, setOpen] = React.useState(false);
  const { data, loading, fetchData } = useServiceType(responseObject?.partner?.key);
  const { control, register } = useFormContext();

  const onOpen = () => {
    fetchData();
    setOpen(true);
  };

  React.useEffect(() => {
    register(`${fieldName}.serviceTypeId`);
  }, [open]);

  return (
    <Controller
      render={({ onChange, ref }) => (
        <Autocomplete
          fullWidth
          id="serviceType"
          open={open}
          onOpen={onOpen}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.displayText === value.displayText}
          getOptionLabel={(option) => option.displayText}
          onChange={(_, value) => {
            if (!!value) {
              onChange(value.key);
            }
          }}
          options={data}
          loading={loading}
          ref={ref}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              placeholder="Start typing"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={10} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      name={`${fieldName}.serviceTypeId`}
      control={control}
      defaultValue={""}
      rules={{ required: true }}
    />
  );
};

export default ServiceTypeAutocomplete;
