import React, { useState } from "react";
import { requestGet, requestPost, requestPut } from "src/utils/crud";
import { Props, Context } from "./types";
import createCtx from "../../utils/createCtx";
import { SellersState, ResponceWarehouseLookup } from "./types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { WarehousesDetailsType } from "../../pages/Warehouses/WarehouseDetails/type";
import { SellersReq, Seller } from "src/providers/SellersStore/types";
import { FetchProps } from "../../types";
import { LookupObject } from "src/types";

const [useCtx, Provider] = createCtx<Context>();
const defaultFetchProps: FetchProps = { cachingPolicy: "network" };

const initialSellersState: SellersState = {
  sellers: [],
  warehouses: [],
  sellersGroupedByWarehouses: [],
  currentPage: 1,
  pageCount: 0,
  pageSize: 10,
  sortBy: "createdDate",
  des: true,
  totalCount: 0,
  loading: false,
  success: false,
};

function SellersProvider(props: Props): React.ReactElement {
  const { warehousesID } = useParams<WarehousesDetailsType>();
  const [sellersStore, setSellers] = useState(initialSellersState);
  const { currentPage, pageSize, sortBy, des } = sellersStore;

  useEffect(() => {
    if (props.lazy) return;

    if (!!warehousesID) {
      getWarehousesSellers(warehousesID);
    } else {
      getSellers();
    }
  }, [currentPage, pageSize, sortBy, des]);

  const getWarehousesSellers = (
    key: number | string,
    size: number = sellersStore.pageSize,
    showDisabledSellers: boolean = true
  ) => {
    setSellersLoading(true);
    requestGet(`Warehouses/${key}/sellers${!showDisabledSellers ? "/lookups" : ""}`, {
      page: sellersStore.currentPage,
      pageSize: size,
      sortBy: sellersStore.sortBy,
      des: sellersStore.des,
    })
      .then((res) => {
        setSellers((prev) => ({
          ...prev,
          ...res.data,
          currentPage: prev.currentPage, //returned currentPage wrong number
          pageSize: prev.pageSize, //returned currentPage wrong number
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };
  const getGroupedWarehousesSellers = (warehouseKeys: LookupObject[], displayText?: string) => {
    setSellersLoading(true);
    let boofer = "";
    warehouseKeys.forEach((warehouseKey, idx) => (boofer += `keys[${idx}]=${warehouseKey.key}&`));
    requestGet<ResponceWarehouseLookup>("Warehouses/sellers/lookups?" + boofer, {
      displayText: displayText,
    })
      .then((res) => {
        setSellers((prev) => ({
          ...prev,
          sellersGroupedByWarehouses: res.data.warehouses,
        }));
      })
      .catch((err) => console.log(err));
  };
  const getSellers = ({ cachingPolicy } = defaultFetchProps) => {
    if (cachingPolicy === "cache" && sellersStore.success) {
      return;
    }

    setSellersLoading(true);
    requestGet<Partial<SellersReq>>(`Warehouses/Sellers`, {
      page: sellersStore.currentPage,
      pageSize: sellersStore.pageSize,
      sortBy: "name",
    })
      .then((res) => {
        setSellers((prev) => ({
          ...prev,
          ...res.data,
          warehouses: res.data?.warehouses
            ? res.data?.warehouses!.map((warehouse) => {
                let sellersArr = warehouse.sellers.map((seller) => {
                  return {
                    ...seller,
                    warehouseId: warehouse.warehouse.key,
                    warehouseName: warehouse.warehouse.name,
                  };
                });
                return { ...warehouse, sellers: sellersArr };
              })
            : [],
          currentPage: prev.currentPage, //returned currentPage wrong number
          pageSize: prev.pageSize, //returned currentPage wrong number
          loading: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  const setSellersLoading = (status: boolean) =>
    setSellers((prev: SellersState) => ({
      ...prev,
      loading: status,
    }));

  const setContextData = (key: string, data: any) => {
    setSellers((prev) => {
      return {
        ...prev,
        [key]: data,
      };
    });
  };

  const deactivateSellers = async (sellerKeys: number[]) =>
    requestPost(`Sellers/deactivate`, {
      sellerKeys,
    });

  const activateSellers = async (sellerKeys: number[]) =>
    requestPost(`Sellers/activate`, {
      sellerKeys,
    });

  const editSellerInfo = (seller: Seller) => {
    try {
      let sellerObj = {
        name: `${seller.firstName} ${seller.lastName}`,
        email: seller.email,
        phone: seller.phone,
      };
      const res = requestPut<Seller>(`Sellers/${seller.key}`, sellerObj);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const callAPI = {
    getWarehousesSellers,
    getGroupedWarehousesSellers,
    getSellers,
    setSellersLoading,
    setContextData,
    deactivateSellers,
    activateSellers,
    editSellerInfo,
  };

  return (
    <Provider
      value={{
        sellersStore,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useSellersContext, SellersProvider };
