import React, { useState, useEffect } from "react";
import { MenuItem, TextField, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useWDetailsContext } from "src/providers/WarehouseDetailsStore";

const sortValues = (t: TFunction) => [
  { 
    key: "name_desc", 
    displayText: t("labels.details.related_orders_sorting.name_desc") 
  },
  { 
    key: "name", 
    displayText: t("labels.details.related_orders_sorting.name_asc") 
  },
  {
    key: "customer.displayText_desc",
    displayText: t("labels.details.related_orders_sorting.customer_desc"),
  },
  { 
    key: "customer.displayText", 
    displayText: t("labels.details.related_orders_sorting.customer_asc") 
  },
  {
    key: "warehouse.displayText_desc",
    displayText: t("labels.details.related_orders_sorting.warehouse_desc"),
  },
  { 
    key: "warehouse.displayText", 
    displayText: t("labels.details.related_orders_sorting.warehouse_asc") 
  },
  {
    key: "childCount_desc",
    displayText: t("labels.details.related_orders_sorting.count_services_desc"),
  },
  { 
    key: "childCount", 
    displayText: t("labels.details.related_orders_sorting.count_services_asc") 
  },
  {
    key: "seller.displayText_desc",
    displayText: t("labels.details.related_orders_sorting.seller_desc"),
  },
  { 
    key: "seller.displayText", 
    displayText: t("labels.details.related_orders_sorting.seller_asc") 
  },
  {
    key: "createdDate_desc",
    displayText: t("labels.details.related_orders_sorting.created_date_desc"),
  },
  { 
    key: "createdDate", 
    displayText: t("labels.details.related_orders_sorting.created_date_asc") 
  },
  {
    key: "status_desc",
    displayText: t("labels.details.related_orders_sorting.status_desc"),
  },
  { 
    key: "status", 
    displayText: t("labels.details.related_orders_sorting.status_asc") 
  },
];

const RelatedOrdersSortDropdown: React.FC = React.memo(() => {
  const { t } = useTranslation(`orders`);
  const {
    relatedOrders: { sortBy, des },
    callAPI: { setContextOrdersData }
  } = useWDetailsContext();
  const classes = useStyles();
  const [value, setValue] = useState<string>(sortBy || "name");

  const handleSorting = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes("_desc")) {
      let val = event.target.value.split("_");
      setContextOrdersData("sortBy", val[0]);
      setContextOrdersData("des", true);
    } else {
      setContextOrdersData("sortBy", event.target.value);
      setContextOrdersData("des", false);
    }
  };

  useEffect(() => {
    if (des) {
      setValue(`${sortBy}_desc`);
    } else {
      setValue(sortBy);
    }
  }, [sortBy, des]);

  return (
    <TextField
      select
      style={{ width: 250 }}
      onChange={handleSorting}
      value={value}
      variant="outlined"
      margin="normal"
      InputLabelProps={{ shrink: true }}
      className={classes.WithoutBorder}
      InputProps={{ className: classes.WithoutBorder }}
    >
      {sortValues(t).map((el) => (
        <MenuItem key={el.key} value={el.key}>
          <Typography variant="button">{el.displayText}</Typography>
        </MenuItem>
      ))}
    </TextField>
  );
});

export default React.memo(RelatedOrdersSortDropdown);
