import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const RelatedServicesIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.44445 1H1V6.44445H6.44445V1Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.0001 1H9.55566V6.44445H15.0001V1Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.0001 9.55566H9.55566V15.0001H15.0001V9.55566Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.44445 9.55566H1V15.0001H6.44445V9.55566Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default RelatedServicesIcon;
