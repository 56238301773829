import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import i18n, {
  defaultLocale,
  defaultTerritoryLanguageKey,
  Languages,
  updateLocalization,
} from "src/utils/i18n";
import { Locales } from "src/providers/AppProvider/types";
import clsx from "clsx";
import ArrowIcon from "./icons/arrowIcon";
import { useStyles } from "./styles";
import CheckIcon from "./icons/checkIcon";
import { useAppContext } from "src/providers/AppProvider";
import { updateUserLocale } from "src/utils/crud/";

const LocaleSwitcher: React.FC = () => {
  const classes = useStyles();
  const { state, callAppAPI } = useAppContext();
  const storageLocales: Partial<Locales>[] = state.locales;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const currentLocale = state.user.locale?.iso2 || defaultLocale;
  const territoryLanguageKey = state.user?.territory?.key || defaultTerritoryLanguageKey;
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLngSelect = (id: number) => {
    const selectedLng = storageLocales.find((lng) => lng.id === id);
    if (selectedLng && selectedLng.iso2 !== i18n.language) {
      !!selectedLng && selectedLng.iso2 && updateLocalization(selectedLng.iso2);
      updateUserLocale(id).then(() => {
        callAppAPI.fetchingUserData();
      });
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="text"
        color="default"
        onMouseEnter={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleOpen(event)}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleOpen(event)}
      >
        <div className={classes.currentFlag}>
          <img alt="lang" src={`/images/flags/${currentLocale}.png`} style={{ width: 24 }} />
        </div>
        <div className={clsx(!!anchorEl ? classes.menuOpened : classes.menuClosed)}>
          <ArrowIcon color="secondary" />
        </div>
      </Button>
      <Menu
        variant="selectedMenu"
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {storageLocales &&
          storageLocales.map((lng): any | null => {
            if (lng.nameLabel?.translations.length && lng.iso2 && Languages.includes(lng.iso2)) {
              return (
                lng.nameLabel?.translations &&
                lng.nameLabel?.translations
                  .filter((el) => el.languageId === territoryLanguageKey)
                  .map((el) => {
                    return (
                      <MenuItem
                        key={lng.id}
                        onClick={() => lng.id && onLngSelect(lng.id)}
                        className={clsx(i18n.language === lng.iso2 && classes.selected)}
                      >
                        <ListItemIcon>
                          <img
                            alt="lang"
                            src={`/images/flags/${lng.iso2}.png`}
                            className={classes.flag}
                          />
                        </ListItemIcon>
                        <ListItemText primary={el.text} />
                        {i18n.language === lng.iso2 && (
                          <ListItemIcon className={classes.selectedIcon}>
                            <CheckIcon color="secondary" />
                          </ListItemIcon>
                        )}
                      </MenuItem>
                    );
                  })
              );
            } else return null;
          })}
        {/* {storageLocales &&
          storageLocales.map((lng): any | null => {
            if (lng.id && lng.iso2 && Languages.includes(lng.iso2)) {
              return (
                <MenuItem
                  key={lng.id}
                  onClick={onLngSelect(lng.id)}
                  className={clsx(i18n.language === lng.iso2 && classes.selected)}
                >
                  <ListItemIcon>
                    <img
                      alt="lang"
                      src={`/images/flags/${lng.iso2}.png`}
                      className={classes.flag}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lng.name} />
                  {i18n.language === lng.iso2 && (
                    <ListItemIcon className={classes.selectedIcon}>
                      <CheckIcon color="secondary" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            } else return null;
          })} */}
      </Menu>
    </div>
  );
};

export default LocaleSwitcher;

// const getLocalizationFlag = (ln: string): JSX.Element | null => {
//   switch (ln) {
//     case "en":
//       return <EnFlagIcon />;
//     case "da":
//       return <DaFlagIcon />;
//     case "fi":
//       return <FiFlagIcon />;
//     case "nb":
//       return <NbFlagIcon />;
//     case "sv":
//       return <SvFlagIcon />;
//     default:
//       return null;
//   }
// };
