import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import uniqueId from "lodash/fp/uniqueId";
import format from "date-fns/format";
import { TFunction } from "i18next";
import { Seller } from "src/providers/SellersStore/types";
import DropdownMenu from "src/components/DropdownMenu";
import CustomerIcon from "src/pages/Customers/components/icons/CustomerIcon";
import MenuIcon from "src/pages/Customers/components/icons/MenuIcon";
import { useAppContext } from "src/providers/AppProvider";
import Deactivate from "../../components/icons/Deactivate";

import EditIcon from "src/pages/Sellers/components/icons/EditIcon";
import { useSellersContext } from "src/providers/SellersStore";
import { useWDetailsContext } from "src/providers/WarehouseDetailsStore";
import ActivateIcon from "../../components/icons/ActivateIcon";
import { makeStyles } from "@material-ui/core";

export interface FieldListType {
  label: string;
  value: string | number | Date | boolean | undefined;
}

interface Actions {
  deactivate: (e: React.MouseEvent) => void;
  edit: (e: React.MouseEvent) => void;
}

const dataList = (t: TFunction, elem: Seller) => [
  {
    label: t("labels.table_fields.registered_by"),
    value: elem.name,
  },
  {
    label: t("labels.table_fields.email"),
    value: elem.email,
  },
  {
    label: t("labels.table_fields.phone"),
    value: elem.phone,
  },
  {
    label: t("labels.table_fields.registered"),
    value: !!elem.createdDate ? format(new Date(elem.createdDate), "dd.MM.yyyy") : "",
  },
  {
    label: t("labels.table_fields.active"),
    value: elem.active ? "Yes" : "No",
  },
];

const SellerFieldList: FC<{ seller: Seller }> = ({ seller }) => {
  const { t } = useTranslation("sellers");
  const {
    callAPI: { setContextData },
  } = useSellersContext();
  const {
    selectedWarehouse: { warehouse },
  } = useWDetailsContext();
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const isIntegration = user?.partner?.isIntegration;

  const createSellerDeactivateAction = (seller: Seller) => (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleModal({ id: "sellersDeactivateModal", status: true, ctx: { seller } });
  };

  const createSellerActivateAction = (seller: Seller) => (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleModal({ id: "sellersActivateModal", status: true, ctx: { seller } });
  };

  const createSellerEditAction = (seller: Seller) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setContextData("selectedSeller", { ...seller, warehouseName: warehouse.name });
    toggleModal({ id: "editSellerModal", status: true, ctx: { seller } });
  };

  const actionsList = (t: TFunction, actions: Actions) => [
    {
      label: t("labels.common.edit", { ns: "sellers" }),
      icon: <EditIcon />,
      attr: {
        onClick: actions.edit,
      },
    },
    {
      label: t(seller.active ? "toggle_active.deactivate" : "toggle_active.activate", {
        ns: "sellers",
      }),
      icon: seller.active ? <Deactivate /> : <ActivateIcon />,
      attr: {
        onClick: actions.deactivate,
      },
    },
  ];

  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center" className={classes.row}>
      <Grid item xs={4} container alignItems="center" justify="flex-start">
        <CustomerIcon />
        <Typography variant="body2">{seller.name}</Typography>
      </Grid>
      {dataList(t, seller).map((ent: FieldListType) => (
        <Grid item xs key={uniqueId("sellers")}>
          <Typography variant="caption">{ent.label}</Typography>
          <Typography variant="body2">{ent.value}</Typography>
        </Grid>
      ))}
      {!isIntegration && (
        <Grid item xs key={uniqueId("")}>
          <DropdownMenu
            menuIcon={<MenuIcon />}
            menuActions={actionsList(t, {
              deactivate: seller.active
                ? createSellerDeactivateAction(seller)
                : createSellerActivateAction(seller),
              edit: createSellerEditAction(seller),
            })}
          />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {
    height: theme.spacing(6),
  },
}));

export default SellerFieldList;
