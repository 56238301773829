import React, { useState, useEffect } from "react";
import { ServicesState, ServicesReq, Service } from "src/providers/AnalyticsStore/types";
import AnalyticsList from "src/pages/Analytics/components/AnalyticsList";
import Preloader from "src/components/Preloader";
import ServicesFieldList from "./ServicesFieldList";
import { requestGet } from "src/utils/crud";
import LineItemsTableDetails from "./LineItemsTableDetails";
import ServicesListHeader from "./ServicesListHeader";

const ServicesList: React.FC<{ id: number }> = ({ id }) => {
  const [data, setData] = useState<ServicesState>({
    services: [],
    loading: false,
    success: false,
    TotalWithoutVat: 0,
  });

  const getServices = async (key: number | string) => {
    return requestGet<ServicesReq>(`Reports/Seller/${key}/services`)
      .then((res) => {
        setData((prev: ServicesState) => ({
          ...prev,
          services: [...res.data.services],
          loading: false,
          success: res.data.success,
          TotalWithoutVat: res.data.TotalWithoutVat,
        }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getServices(id);
  }, []);

  if (data.loading || !data.services) {
    return <Preloader is />;
  }

  return (
    <>
      {data.services && <ServicesListHeader />}
      <AnalyticsList<Service>
        listEntities={data.services}
        entityFields={(ent) => <ServicesFieldList data={ent} />}
        entityDetails={(ent) => <LineItemsTableDetails serviceId={ent.serviceId} />}
        customStyles={{ backgroundColor: "rgba(6, 169, 198, 0.15)", paddingLeft: "48px" }}
        total={data.TotalWithoutVat}
      />
    </>
  );
};

export default React.memo(ServicesList);
