import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { usePageNavigationBarStyles } from "./styles";
import { Paper } from "@material-ui/core";
import MenuCloseIcon from "../../../../icons/MenuCloseIcon";
import { useAnalyticsContext } from "src/providers/AnalyticsStore";
import MenuOpenIcon from "src/pages/Analytics/icons/MenuOpenIcon";
import PageNavigationList from "../PageNavigationList";
import { useTranslation } from "react-i18next";

interface Props {
  children?: React.ReactElement;
}

const PageNavigationBar: React.FC<Props> = ({ children }: Props) => {
  const { t } = useTranslation("analytics");
  const classes = usePageNavigationBarStyles();
  const {
    analyticsData: { pageBarOpen, selectedMenuIndex, partnerManagerMenuListItems },
    callAPI,
  } = useAnalyticsContext();

  const handleDrawerOpen = () => {
    callAPI.setContextData("pageBarOpen", true);
  };

  const handleDrawerClose = () => {
    callAPI.setContextData("pageBarOpen", false);
  };
  return (
    <Paper className={classes.mainWrapper}>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: pageBarOpen,
          })}
          color="secondary"
          elevation={0}
        >
          <Toolbar>
            <Typography variant="subtitle1" noWrap>
              {t(partnerManagerMenuListItems.find((el) => el.key === selectedMenuIndex)?.transKey!)}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: pageBarOpen,
            [classes.drawerClose]: !pageBarOpen,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: pageBarOpen,
              [classes.drawerClose]: !pageBarOpen,
            }),
          }}
        >
          <div className={classes.toolbar}>
            {pageBarOpen ? (
              <div className={classes.drawerOpenedlWrapper}>
                <Typography variant="subtitle1">{t("labels.main.partner_manager")}</Typography>
                <IconButton onClick={handleDrawerClose}>
                  <MenuOpenIcon style={{ width: 16, height: 16 }} />
                </IconButton>
              </div>
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.openButton, {
                  [classes.hide]: pageBarOpen,
                })}
              >
                <MenuCloseIcon style={{ width: 16, height: 16 }} />
              </IconButton>
            )}
          </div>
          <PageNavigationList />
          <div className={clsx(classes.appBarTextWrapper, { [classes.hide]: pageBarOpen })}>
            <Typography variant="h6">{t("labels.main.partner_manager")}</Typography>
          </div>
        </Drawer>
        <div className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </div>
      </div>
    </Paper>
  );
};

export default PageNavigationBar;
