import React from "react";
import { useStyles } from "../../containers/MainWrapper/styles";
import IconButton from "@material-ui/core/IconButton";
import ChevronNavBtnIcon from "src/assets/icons/ChevronNavBtnIcon";
import ChevronNavBtnIconClosed from "src/assets/icons/ChevronNavBtnIconClosed";
import clsx from "clsx";

export interface ChevronNavBtnType {
  open: boolean;
  handleDrawerClose?: () => void;
  handleDrawerOpen?: () => void;
}

const ChevronNavBtn = React.memo(
  ({ open, handleDrawerOpen, handleDrawerClose }: ChevronNavBtnType) => {
    const classes = useStyles();

    if (open) {
      return (
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronNavBtnIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        </div>
      );
    }
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
      >
        <ChevronNavBtnIconClosed style={{ width: 16, height: 16 }} />
      </IconButton>
    );
  }
);

export default ChevronNavBtn;
