import { makeStyles, TextField } from '@material-ui/core'
import React, { ReactElement } from 'react'


const useStyles = makeStyles(() => ({
    root: {
        "& .MuiFormHelperText-root": {
            textAlign: "right"
        }
    }
}));

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | undefined;
    name: string;
    label: string
    [key:string]: any;
}

export default function TextArea({ onChange, value, name, ...arg}: Props): ReactElement {
    const classes = useStyles();
    const CHARACTER_LIMIT = 100000;
    
    return (
        <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            defaultValue={value?.substring(0, CHARACTER_LIMIT)}
            onChange={onChange}
            name={name}
            inputProps={{
                maxLength: CHARACTER_LIMIT
            }}
            className={classes.root}
            helperText={`${value?.length || 0}/${CHARACTER_LIMIT}`}
            {...arg}
        />

    )
}
