import React, { FC } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import uniqueId from "lodash/fp/uniqueId";
import Typography from "@material-ui/core/Typography/Typography";
import { useStyles } from "./styles";
import { Service } from "src/providers/AnalyticsStore/types";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const ServicesFieldList: FC<{ data: Service }> = ({ data }) => {
  const { rowFields, zeroPad, subTotal, styledLink } = useStyles();

  return (
    <Grid container direction="row" alignItems="center" spacing={2} className={rowFields}>
      <Grid item xs key={uniqueId("report")} className={zeroPad}>
        <Link
          className={styledLink}
          component={RouterLink}
          to={`/services/details/${data.serviceId}/summary`}
        >
          <Typography variant="body2">{data.serviceName}</Typography>
        </Link>
      </Grid>
      <Grid item xs key={uniqueId("report")} className={subTotal}>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {data.subtotalWithoutVat.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          }) || " --- "}
          kr
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ServicesFieldList;
