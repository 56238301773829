import { State } from "src/providers/AppProvider/types";

export const initialState: State = {
  user: {
    isStaffChatRole: false,
  },
  modals: {
    warehouseModal: false,
    warehouseEditModal: false,
    warehouseToggleActiveModal: false,
    createCustomerModal: false,
    editCustomerModal: false,
    editCustomerNotes: false,
    editSOStatusModal: false,
    createDeliveryAddressModal: false,
    createInvoiceAddressModal: false,
    editInvoiceAddressModal: false,
    editDeliveryAddressModal: false,
    editDeliveryAddressSO: false,
    editInvoiceAddressSO: false,
    editDescriptionSO: false,
    editSpecialInstrctionsSO: false,
    editPartnerInfoSO: false,
    editCustomerInfoSO: false,
    serviceOrdersEditModal: false,
    sellersModal: false,
    sellersDeactivateModal: false,
    sellersActivateModal: false,
    editSellerModal: false,
    createServiceModal: false,
    createServiceBulkModal: false,
    editServiceStatusModal: false,
    ordersFilesUpload: false,
    commentsFilesUpload: false,
    editNotesSO: false,
  },
  modalsCtx: {},
  locales: [
    {
      localeTag: "nb-NO",
      englishName: "Danish",
      id: 2055058958,
      iso2: "da",
      iso3: "dan",
      name: "Danish",
      currency: {
        code: "NOK",
        id: 578,
        name: "NorwegianKrone",
        nameLabel: null,
        nameLabelId: "NorwegianKrone",
        symbol: "kr.",
      },
    },
  ],
  loading: false,
  featureFlags: {
    en: false,
    dk: false,
    no: false,
  },
  authError: false,
};
