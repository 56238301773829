import React, { useCallback, useEffect, useState } from "react";
import { useStyles } from "src/pages/Orders/modals/EditForm/styles";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useAppContext } from "src/providers/AppProvider";
import { Trans, useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import { ServiceOrder } from "src/types";
import { OrdersStatusType, SODetailsReq } from "src/providers/SODetailsStore/types";
import { requestMetaGet, requestPut } from "src/utils/crud";
import { useForm } from "react-hook-form";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import Success from "./Success";
import MonteraTextArea from "src/components/MonteraTextArea";
import { useServiceOrderStatusesStyle } from "src/utils/hooks/useServiceOrderStatusStyle";

interface Props {
  statusId: string | number;
  statusReason: string;
}

const EditStatusFrom: React.FC = React.memo(() => {
  const {
    serviceOrderDetails: { serviceOrder },
    callAPI,
  } = useSODetailsContext();
  const {
    state: {
      user,
      modals: { editSOStatusModal },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const [, getColor] = useServiceOrderStatusesStyle();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { handleSubmit, register, watch } = useForm<ServiceOrder>({
    mode: "onBlur",
  });
  const [statuses, setServiceOrderStatuses] = useState<OrdersStatusType[]>([]);
  const [showAlert, setAlert] = useState(false);
  const [saveData, setSave] = useState<Props | undefined>(undefined);
  const {
    filesState: { totalCount },
    serviceOrderDetails,
  } = useSODetailsContext();

  const statusReasonVal = watch("statusReason");
  const classes = useStyles();

  const { t } = useTranslation("orders");
  const handleModal = useCallback(() => {
    toggleModal({ id: "editSOStatusModal", status: false });
    setSubmitted(false);
  }, []);

  const onSubmit = (data: Props) => {
    const newStatusEntity = statuses.find((item) => item.matchingText === "NEW");

    if (
      !showAlert &&
      newStatusEntity?.key === data.statusId &&
      Number(serviceOrderDetails?.serviceOrder?.lineItemCount) <= 0 &&
      Number(totalCount) <= 0
    ) {
      setAlert(true);
      setSave(data);
    } else {
      setAlert(false);
      if (!saveData) {
        sendData(data);
      } else {
        sendData(saveData);
      }
    }
  };

  const sendData = (data: Props) => {
    (async () => {
      await requestPut<SODetailsReq>(`ServiceOrders/${serviceOrder.id}/status`, data).then(
        (res) => {
          if (res.status === 200 || res.status === 204) {
            callAPI.setContextData("serviceOrder", res.data.serviceOrder!);
            setSubmitted(true);
          }
        }
      );
    })();
  };
  useEffect(() => {
    if (!!user?.territory?.key) {
      (async () => {
        await requestMetaGet<OrdersStatusType[]>(
          `lookups/PartnerPortal/serviceOrderStatuses/new?territoryKey=${user?.territory?.key}`
        ).then((response) => {
          setServiceOrderStatuses(
            response.data.map((res) => ({
              ...res,
              color: getColor(res.matchingText),
            }))
          );
        });
      })();
    }
  }, [user]);

  return (
    <Modal isOpen={editSOStatusModal} onClose={handleModal}>
      <Grid container justify="center" style={{ width: "100%" }}>
        <Grid item style={{ width: "100%" }}>
          {!submitted && (
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              {!showAlert ? (
                <>
                  <Typography variant="h3">{t("labels.status.update_status")}</Typography>
                  <div className={classes.fieldGroup}>
                    <Typography variant="h4" className={classes.fieldTitle}>
                      {t("labels.status.select_new_status")}
                    </Typography>
                  </div>
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                    name={"statusId"}
                    inputRef={register()}
                    InputLabelProps={{ shrink: true }}
                  >
                    <>
                      {statuses.map((el, idx) => {
                        return (
                          <option key={idx} value={el.key} style={{ backgroundColor: el.color }}>
                            {el.displayText}
                          </option>
                        );
                      })}
                    </>
                  </TextField>
                  <div style={{ marginTop: 8 }}>
                    <MonteraTextArea
                      label={t("labels.status.status_placeholder")}
                      limit={300}
                      value={statusReasonVal}
                      name="statusReason"
                      inputRef={register()}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Typography variant="h3">{t("labels.warning.title")}</Typography>
                  <div className={classes.fieldGroup}>
                    <Trans i18nKey="multiline">
                      <Typography variant="subtitle1" className={classes.fieldTitle}>
                        {t("labels.warning.body")}
                      </Typography>
                    </Trans>
                  </div>
                </>
              )}
              <div className={classes.btnGroup}>
                <Button className={classes.submitBtn} type="submit">
                  {t("labels.ok", { ns: "common" })}
                </Button>
                <Button onClick={handleModal} className={classes.cancelBtn}>
                  {t("labels.btn.cancel")}
                </Button>
              </div>
            </form>
          )}
          {submitted && <Success onClose={handleModal} />}
        </Grid>
      </Grid>
    </Modal>
  );
});

export default EditStatusFrom;
