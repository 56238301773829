import React from "react";
import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

export interface Props {
  is: boolean;
  size?: number | string;
  children?: React.ReactElement;
}

const Preloader = ({ is, size }: Props) => {
  if (!is) {
    return null;
  }
  return (
    <Grid container justify="center">
      <CircularProgress className="dataLoading" style={{ color: "#2B59C3" }} size={size} />
    </Grid>
  );
};

export default React.memo(Preloader);
