import React from "react";
import Hint from "src/components/Hint";
import DeliveryIcon from "../../components/icons/DeliveryIcon";
import { Typography, IconButton } from "@material-ui/core";

interface Props {
  title: string;
}

const DeliveryAddressTooltip: React.FC<Props> = ({ title }) => {
  return (
    <Hint title={<Typography>{title}</Typography>}>
      <IconButton>
        <DeliveryIcon />
      </IconButton>
    </Hint>
  );
};

export default DeliveryAddressTooltip;
