import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { Typography, TextField, InputAdornment, Button } from "@material-ui/core";
import WarehouseDetailsIcon from "./icons/WarehouseDetailsIcon";
import EmailIcon from "./icons/EmailIcon";
import { useAppContext } from "src/providers/AppProvider";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { OutlinedInput } from "@material-ui/core";
import ContactPersonIcon from "./icons/ContactPersonIcon";
import Modal from "src/components/Modal";
import { Grid } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import { emailPattern } from "src/utils/constants";
import { useForm, FormProvider, Controller } from "react-hook-form";
import Success from "./Success";
import { useSellersContext } from "src/providers/SellersStore";
import { AxiosResponse } from "axios";
import { Seller } from "src/providers/SellersStore/types";
import PhoneInputField from "src/components/PhoneInput/";

const EditSeller: React.FC = () => {
  const {
    sellersStore,
    callAPI: { getSellers, editSellerInfo },
  } = useSellersContext();
  const [status, setStatus] = useState<string>("");
  const methods = useForm<Seller>({
    mode: "onChange",
    defaultValues: { ...sellersStore.selectedSeller },
  });

  const {
    handleSubmit,
    register,
    errors,
    formState,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
  } = methods;
  const classes = useStyles();
  const {
    state: {
      modals: { editSellerModal },
    },
    callAppAPI: { toggleModal },
  } = useAppContext();
  const { t } = useTranslation("sellers");

  const handleModal = useCallback(() => {
    toggleModal({ id: "editSellerModal", status: false });
    getSellers();
  }, []);

  const onSubmit = async (data: Seller) => {
    let submitObj = { ...data, key: sellersStore.selectedSeller?.key! };
    const res = await editSellerInfo(submitObj);
    setStatus(`${((res as any) as AxiosResponse).status}`);
  };

  useEffect(() => {
    reset({
      firstName: sellersStore.selectedSeller?.name.split(" ")[0],
      lastName: sellersStore.selectedSeller?.name.split(" ")[1],
      email: sellersStore.selectedSeller?.email,
      phone: sellersStore.selectedSeller?.phone,
      warehouseName: sellersStore.selectedSeller?.warehouseName,
    });
  }, [sellersStore.selectedSeller]);

  const onErrorHandler = (isError: boolean) => {
    isError
      ? setError("phone", {
          type: "manual",
        })
      : clearErrors("phone");
  };

  if (status === "200") {
    return (
      <Modal isOpen={editSellerModal} onClose={handleModal}>
        <Success />
      </Modal>
    );
  }
  return (
    <Modal isOpen={editSellerModal} onClose={handleModal}>
      <Grid container justify="center">
        <Grid item>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h3"> {t("form.edit_title")}</Typography>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <WarehouseDetailsIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("form.warehouse_details")}
                  </Typography>
                </div>
                <TextField
                  disabled
                  fullWidth
                  name="warehouseName"
                  label={t("form.warehouse_name")}
                  variant="outlined"
                  inputRef={register}
                />
              </div>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <ContactPersonIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("form.seller")}
                  </Typography>
                </div>
                <TextField
                  required
                  fullWidth
                  error={!!errors.firstName}
                  name="firstName"
                  label={t("form.first_name")}
                  variant="outlined"
                  inputRef={register({
                    required: "Required",
                  })}
                />
                <TextField
                  required
                  fullWidth
                  error={!!errors.lastName}
                  name="lastName"
                  inputRef={register({
                    required: "Required",
                  })}
                  label={t("form.last_name")}
                  variant="outlined"
                />
                <FormControl
                  error={!!errors.email}
                  required
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                >
                  <InputLabel htmlFor="email">{t("form.email")}</InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    name="email"
                    fullWidth
                    inputRef={register({
                      required: "Required",
                      pattern: emailPattern,
                    })}
                    endAdornment={
                      <InputAdornment position="end">
                        <EmailIcon />
                      </InputAdornment>
                    }
                    labelWidth={140}
                  />
                  {!!errors.email && (
                    <FormHelperText id="email">{t("form.invalid_email")}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className={classes.fieldGroup}>
                <Controller
                  as={
                    <PhoneInputField
                      required={true}
                      label={t("form.phone")}
                      labelWidth={110}
                      name="phone"
                      onChangeHandler={(value) => setValue("phone", value)}
                      onErrorHandler={(isError) => onErrorHandler(isError)}
                    />
                  }
                  name="phone"
                  control={control}
                  inputRef={register({
                    required: "Required",
                  })}
                />
              </div>
              <div className={classes.btnGroup}>
                <Button type="submit" disabled={!formState.isValid} className={classes.submitBtn}>
                  {t("common.save")}
                </Button>
                <Button onClick={handleModal} className={classes.cancelBtn}>
                  {t("common.cancel")}
                </Button>
              </div>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default EditSeller;
