import React, { FC } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import uniqueId from "lodash/fp/uniqueId";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import { Seller } from "src/providers/SellersStore/types";
import { useAppContext } from "src/providers/AppProvider";
import { useSellersContext } from "src/providers/SellersStore";
import { useStyles } from "src/pages/Customers/components/styles";
import MenuIcon from "src/pages/Customers/components/icons/MenuIcon";
import EditIcon from "./icons/EditIcon";
import Deactivate from "src/pages/Warehouses/WarehouseDetails/components/icons/Deactivate";
import DropdownMenu from "src/components/DropdownMenu";
import UserIcon from "./icons/UserIcon";
import ActivateIcon from "./icons/ActivateIcon";

export interface FieldListType {
  id: number | string;
  label: string;
  value: string | number | Date | boolean | undefined;
}

interface Actions {
  deactivate: (e: React.MouseEvent) => void;
  activate: (e: React.MouseEvent) => void;
  edit: (e: React.MouseEvent) => void;
}

const fieldsList = (t: TFunction, elem: Seller): FieldListType[] => [
  {
    id: "Name",
    label: t("labels.table_fields.name"),
    value: elem.name,
  },
  {
    id: "Email",
    label: t("labels.table_fields.email"),
    value: elem.email,
  },
  {
    id: "Phone",
    label: t("labels.table_fields.phone"),
    value: elem.phone,
  },
  {
    id: "Active",
    label: t("labels.table_fields.active"),
    value: elem.active ? "Yes" : "No",
  },
];

const UserFieldList: FC<{ seller: Seller }> = ({ seller }) => {
  const classes = useStyles();
  const { t } = useTranslation("sellers");
  const {
    state: { user },
    callAppAPI: { toggleModal },
  } = useAppContext();

  const {
    callAPI: { setContextData },
  } = useSellersContext();

  const createSellerDeactivateAction = (seller: Seller) => (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleModal({ id: "sellersDeactivateModal", status: true, ctx: { seller } });
  };

  const createSellerActivateAction = (seller: Seller) => (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleModal({ id: "sellersActivateModal", status: true, ctx: { seller } });
  };

  const createSellerEditAction = (seller: Seller) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setContextData("selectedSeller", seller);
    toggleModal({ id: "editSellerModal", status: true, ctx: { seller } });
  };

  const isIntegration = user?.partner?.isIntegration;

  const actionsList = (t: TFunction, actions: Actions) => {
    const actionArray = [
      {
        label: t("labels.common.edit"),
        icon: <EditIcon />,
        attr: {
          onClick: actions.edit,
        },
      },
    ];
    if (seller.active) {
      actionArray.push({
        label: t("toggle_active.deactivate", { ns: "sellers" }),
        icon: <Deactivate />,
        attr: {
          onClick: actions.deactivate,
        },
      });
    } else {
      actionArray.push({
        label: t("toggle_active.activate", { ns: "sellers" }),
        icon: <ActivateIcon />,
        attr: {
          onClick: actions.activate,
        },
      });
    }
    return actionArray;
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={2} justify={"space-between"}>
      <Grid item xs={2} container alignItems="center" style={{ flexWrap: "nowrap" }}>
        <UserIcon />
        <Typography variant="body2" className={classes.link}>
          {seller.name}
        </Typography>
      </Grid>
      {fieldsList(t, seller).map((ent) => (
        <Grid item xs={ent.id === "Email" ? 3 : 2} key={uniqueId("serviceOrders")}>
          <Typography variant="caption">{ent.label}</Typography>
          <Typography variant="body2">{ent.value}</Typography>
        </Grid>
      ))}
      {!isIntegration && (
        <Grid item key={uniqueId("")}>
          <DropdownMenu
            menuIcon={<MenuIcon />}
            menuActions={actionsList(t, {
              deactivate: createSellerDeactivateAction(seller),
              activate: createSellerActivateAction(seller),
              edit: createSellerEditAction(seller),
            })}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default UserFieldList;
