import React from "react";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import accepts from "attr-accept";
import UploadingIcon from "src/assets/icons/UploadingIcon";
import { ALLOWED_FILE_FORMATS, FILE_FORMAT_ERRORS, MAX_FILE_SIZE } from "./constants";
import { useStyles } from "./styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { FileDescriptor, FileRejection } from "./interfaces";

interface Props {
  onDropPreview: (_files: FileDescriptor[]) => void;
  disabled: boolean;
  multiple: boolean;
  handleDragLeave?: () => void;
  dropZoneHeight?: number;
  dropZoneDescriptions?: JSX.Element;
}

export const MonteraDropZoneNoUpload: React.FC<Props> = ({
  onDropPreview,
  disabled,
  multiple,
  handleDragLeave,
  dropZoneDescriptions = defaultDescription(),
}) => {
  const classes = useStyles();

  const { t } = useTranslation("files");

  const validateRejectedReson = (descriptors: FileRejection[]) => {
    const result = descriptors.map((d) => {
      const errors: FILE_FORMAT_ERRORS[] = [];
      if (Number(d.file.size) > MAX_FILE_SIZE) {
        errors.push(FILE_FORMAT_ERRORS.MAX_FILE_SIZE);
      }
      if (!accepts(d.file, ALLOWED_FILE_FORMATS)) {
        errors.push(FILE_FORMAT_ERRORS.INVALID_FILE_FORMAT);
      }
      return {
        file: d.file,
        errors,
      };
    });

    onDropPreview(result);
  };

  const handleDropAccepted = (files: File[]) => {
    const result = files.map((file) => ({
      file,
      errors: [],
    }));
    onDropPreview(result);
  };

  return (
    <Dropzone
      onDropAccepted={handleDropAccepted}
      multiple={multiple}
      maxSize={MAX_FILE_SIZE}
      accept={ALLOWED_FILE_FORMATS}
      onDropRejected={validateRejectedReson}
      disabled={disabled}
      onDragLeave={handleDragLeave}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={clsx(classes.dropWrap, {
            [classes.dropWrapDisabled]: disabled,
            [classes.isDragActive]: isDragActive,
          })}
        >
          <input {...getInputProps()} />
          <UploadingIcon />
          <br />
          <div>{dropZoneDescriptions}</div>
          <br />
          <Typography variant="body2" color="textSecondary">
            {t("labels.supported")}
          </Typography>
        </div>
      )}
    </Dropzone>
  );
};

const defaultDescription = (): JSX.Element => (
  <>
    <Typography component="span">
      <Trans i18nKey="labels.main_text_1" ns={"files"} />
      &nbsp;
    </Typography>
    <Link component="button" type="button" variant="button">
      <Trans i18nKey="labels.main_text_2" ns={"files"} />
    </Link>
  </>
);
