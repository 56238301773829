import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { requestGet } from "src/utils/crud";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface CustomerType {
  key: number | string;
  displayText: string;
}

const AutoCompleteCustomers = () => {
  const [open, setOpen] = React.useState(false);
  const data = useFormContext();

  const { errors, register, setValue, watch } = data;
  const { t } = useTranslation("orders");
  const [options, setOptions] = React.useState<CustomerType[]>([]);
  const [value, setStateValue] = React.useState<CustomerType | null>(null);
  const loading = open && options.length === 0;
  const customerKey = watch("customerKey");

  React.useEffect(() => {
    if (loading) {
      (async () => {
        const res = await requestGet<{ customers: CustomerType[] }>("Customers/lookups");
        setOptions(res.data.customers);
      })();
    }
  }, [loading]);

  React.useEffect(() => {
    register({ name: "customerKey" });
    register({ name: "customerName" });
  }, [open]);

  React.useEffect(() => {
    if (customerKey) setStateValue({ key: customerKey, displayText: watch("customerName") });
    else setStateValue(null);
  }, [customerKey]);

  const onSelectHandler = (value: CustomerType | null) => {
    setValue("customerKey", value?.key);
    setValue("customerName", value?.displayText);
  };
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.displayText === value.displayText}
      getOptionLabel={(option) => option.displayText}
      onChange={(_, value) => onSelectHandler(value)}
      options={options}
      loading={loading}
      value={value}
      renderInput={(params) => (
        <TextField
          label={t("list_filter.customer_label")}
          {...params}
          variant="outlined"
          error={!!errors.displayText}
          name="topicName"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={10} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteCustomers;
