import React, { FunctionComponent, useCallback } from "react";
import { useWarehouseContext } from "src/providers/WarehousesStore";
import PaginationWrapper from "src/components/PaginationWrapper";

const Pagination: FunctionComponent = React.memo(() => {
  const {
    warehousesData: { totalCount, currentPage, pageSize, pageCount },
    callAPI,
  } = useWarehouseContext();

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      event?.preventDefault();
      callAPI.setContextData("currentPage", page + 1);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    callAPI.setContextData("pageSize", +event.target.value);
    callAPI.setContextData("currentPage", 1);
  }, []);

  const handleSpecificPageChange = useCallback((value: number) => {
    callAPI.setContextData("currentPage", value);
  }, []);

  return (
    <PaginationWrapper
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={totalCount}
      rowsPerPage={pageSize}
      page={currentPage - 1}
      currentpage={currentPage}
      pagecount={pageCount}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onSpecificPageChange={handleSpecificPageChange}
    />
  );
});

export default React.memo(Pagination);
