import React from "react";
import { Trans } from "react-i18next";
import { FileQuestionnaireItem } from "src/types";
import format from "date-fns/format";
import { Typography } from "@material-ui/core";

export const columns = () => [
  {
    Header: () => (
      <Typography align={"left"} variant="body2">
        <Trans ns={"orders"} i18nKey="labels.details.questionnaire.category" />
      </Typography>
    ),
    id: "category",
    style: { textAlign: "left" },
    accessor: (ent: Partial<FileQuestionnaireItem>) => ent.category?.displayText,
  },
  {
    Header: () => (
      <Typography align={"left"} variant="body2">
        <Trans ns={"orders"} i18nKey="labels.details.questionnaire.title" />
      </Typography>
    ),
    id: "title",
    style: { textAlign: "left" },
    accessor: (ent: Partial<FileQuestionnaireItem>) => ent.name,
  },
  {
    Header: () => (
      <Typography align={"left"} variant="body2">
        Actor
      </Typography>
    ),
    id: "createdByActor",
    style: { textAlign: "left" },
    accessor: (ent: Partial<FileQuestionnaireItem>) =>
      ent.createdByActor +
      `${ent.createdByOcuupationCategory ? " (" + ent.createdByOcuupationCategory + ")" : ""}`,
  },
  {
    Header: () => (
      <Typography align={"left"} variant="body2">
        <Trans i18nKey="labels.details.questionnaire.created_date" ns={"orders"} />
      </Typography>
    ),
    id: "created_date",
    style: { textAlign: "left" },
    accessor: (ent: Partial<FileQuestionnaireItem>) =>
      ent.createdDate ? format(new Date(ent.createdDate), "dd.MM.yyyy") : "",
  },
  {
    Header: () => (
      <Typography align={"left"} variant="body2">
        <Trans i18nKey="labels.details.questionnaire.approved" ns={"orders"} />
      </Typography>
    ),
    id: "approved",
    style: { textAlign: "left" },
    accessor: (ent: Partial<FileQuestionnaireItem>) => (ent.isValid ? "Yes" : "No"),
  },
];
