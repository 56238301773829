import React, { FC } from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { useObjectUrl } from "src/utils/hooks/useObjectUrl";

interface Props {
  className?: string;
  blob: Blob;
}

const MonteraImagePreview: FC<Props> = ({ className, blob }) => {
  const url = useObjectUrl(blob);

  const classes = useStyles();

  return <img className={clsx(classes.image, className)} src={url} alt="preview"></img>;
};

export default MonteraImagePreview;
