import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  editPageNum: {
    maxWidth: theme.spacing(5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  input: {
    fontSize: 14,
    height: theme.spacing(4),
    "& input": {
      padding: 0,
      textAlign: "center",
    },
  },
  inputError: {
    fontSize: 14,
    height: theme.spacing(4),
    padding: "6px 0 !important",
    border: "1px solid #FB3640",
    "& input + fieldset": {
      border: "0px",
    },
    "& input": {
      padding: 0,
      textAlign: "center",
    },
  },
  inputButton: {
    fontSize: 18,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  currentPage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    width: 40,
    height: 32,
    marginLeft: 4,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "rgba(20, 30, 48, 0.1)",
    },
  },
  error: {
    padding: "6px 8px",
    backgroundColor: "rgba(20, 30, 48, 0.7)",
    borderRadius: 4,
    position: "relative",
    bottom: 35,
    left: 124,
  },
  errorText: {
    color: "white",
  },
  btnWrapper: {
    minHeight: 52,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
