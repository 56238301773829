import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DeleteIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 11H10.5H22.5"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.75 11V9.5C12.75 8.67157 13.4216 8 14.25 8H17.25C18.0784 8 18.75 8.67157 18.75 9.5V11M21 11V21.5C21 22.3284 20.3284 23 19.5 23H12C11.1716 23 10.5 22.3284 10.5 21.5V11H21Z"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default DeleteIcon;
