import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "src/containers/FileUploadDislog/styles";
import FileInfoForm from "./components/FileInfoForm";
import ClearAllForm from "src/containers/FileUploadDislog/components/ClearAllForm";
import { useFilesContext } from "src/providers/FilesStore";
import { useAppContext } from "src/providers/AppProvider";
import ModalFormWrapper from "src/components/FormWrapper/ModalFormWrapper";
import CloseIcon from "./CloseIcon";

enum FILES_DIALOG_STATES {
  FILES_FORM = 0,
  CLEAR_ALL = 1,
}
const CommentsFileUploadDialog = () => {
  const classes = useStyles();

  const { fetchInitial, resetState } = useFilesContext();

  const {
    callAppAPI: { toggleModal },
    state: {
      modals: { commentsFilesUpload },
    },
  } = useAppContext();

  const [formState, setFormState] = useState(FILES_DIALOG_STATES.FILES_FORM);

  const handleOpen = () => {
    resetState();
    fetchInitial();
  };

  const onClearClick = () => {
    setFormState(FILES_DIALOG_STATES.CLEAR_ALL);
  };

  const onClearClose = () => {
    setFormState(FILES_DIALOG_STATES.FILES_FORM);
  };

  const onClose = () => {
    toggleModal({ id: "commentsFilesUpload", status: false });
  };

  const onDialogClose = () => {
    if (formState === FILES_DIALOG_STATES.FILES_FORM) {
      return onClose();
    }
    onClearClose();
  };

  useEffect(() => {
    if (commentsFilesUpload) {
      return handleOpen();
    }
  }, [commentsFilesUpload]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open={commentsFilesUpload}
      maxWidth="xl"
      onClose={onDialogClose}
    >
      <div>
        <IconButton onClick={onClose} className={classes.closeBtn}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      {formState === FILES_DIALOG_STATES.FILES_FORM && (
        <FileInfoForm FormWrapper={ModalFormWrapper} onClear={onClearClick} onCancel={onClose} />
      )}
      {formState === FILES_DIALOG_STATES.CLEAR_ALL && (
        <ClearAllForm
          FormWrapper={ModalFormWrapper}
          onCancel={onClearClose}
          onSubmit={onClearClose}
        />
      )}
    </Dialog>
  );
};

export default CommentsFileUploadDialog;
