import React, { FunctionComponent, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import AddIcon from "src/assets/icons/MainNavListIcons/AddIcon";
import { useHistory } from "react-router-dom";
import { useLineItemsContext } from "src/providers/LineItemsStore";
import { useServiceDetailsContext } from "src/providers/ServiceDetailsStore";
import { isIncompleteOrNewOrMissingInformation } from "src/utils/functions";
import { useAppContext } from "src/providers/AppProvider";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

const PageActions: FunctionComponent = React.memo(() => {
  const classes = useStyles();
  const {
    LIData: { lineItemsService },
  } = useLineItemsContext();
  const { t } = useTranslation("lineItems");
  const history = useHistory();
  const {
    state: { user },
  } = useAppContext();
  const {
    serviceDetails: { service },
  } = useServiceDetailsContext();

  const disabledByStatus = useMemo(() => {
    return isIncompleteOrNewOrMissingInformation(service?.status?.matchingText);
  }, [service?.status?.matchingText]);

  const handlerLink = useCallback(() => {
    if (
      !!lineItemsService.lineItems?.service?.serviceOrderKey &&
      !!lineItemsService.lineItems?.service?.key
    ) {
      history.push(
        `/line_items/${lineItemsService.lineItems?.service?.serviceOrderKey}/${lineItemsService.lineItems?.service?.key}`,
        { parentServiceType: "Services", serviceId: lineItemsService.lineItems?.service?.key }
      );
    }
  }, [lineItemsService?.lineItems?.service]);

  const isIntegrated = useMemo(() => !user?.partner?.isIntegration, [user?.partner?.isIntegration]);

  return (
    <Grid container className={classes.wrapper} direction="row">
      {isIntegrated && disabledByStatus && (
        <Button startIcon={<AddIcon />} className={classes.text} onClick={handlerLink}>
          {t("labels.btn.add_new_line_item")}
        </Button>
      )}
      <Divider />
    </Grid>
  );
});

export default PageActions;
