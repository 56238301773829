import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SellersProvider, useSellersContext } from "src/providers/SellersStore";
import { ServiceOrdersProvider, useSOContext } from "src/providers/ServiceOrdersStore";
import { useSellersStyles } from "./styles";
import Typography from "@material-ui/core/Typography/Typography";
import SellersList from "./components/List";
import Container from "@material-ui/core/Container/Container";
import Paper from "@material-ui/core/Paper/Paper";
import Pagination from "./components/Pagination";
import PreloaderLine from "src/components/Preloader/PreloaderLine";
import Preloader from "../../components/Preloader";
import PageActions from "./components/PageActions";
import DeactivateSellerModal from "./modals/DeactivateSeller";
import ActivateSellerModal from "src/pages/Sellers/modals/ActivateSeller";
import CreateSeller from "./components/CreateSeller";
import EditSeller from "./components/EditSeller";

const Sellers: FunctionComponent = () => {
  const {
    sellersStore,
    callAPI: { getSellers },
  } = useSellersContext();
  const { SOData } = useSOContext();
  const { t } = useTranslation("sellers");
  const classes = useSellersStyles();

  if (sellersStore.loading) {
    return <Preloader is />;
  }

  return (
    <Container style={{ margin: 0 }} maxWidth={"lg"}>
      <Typography variant="h3">{t("labels.main.title")}</Typography>
      <PageActions />
      <CreateSeller />
      <EditSeller />
      <Paper className={classes.offSet}>
        <PreloaderLine loading={SOData.loading || sellersStore.loading} />
        <SellersList />
        <Pagination />
      </Paper>
      <DeactivateSellerModal onDeactivate={getSellers} />
      <ActivateSellerModal onActivate={getSellers} />
    </Container>
  );
};

const SellersPage = () => (
  <SellersProvider>
    <ServiceOrdersProvider>
      <Sellers />
    </ServiceOrdersProvider>
  </SellersProvider>
);
export default SellersPage;
