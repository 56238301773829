import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormContext } from "react-hook-form";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSOContext } from "src/providers/ServiceOrdersStore";

export interface InvoiceAddressesListType {
  key: number;
  displayText: string;
  isDefault: boolean;
}

const AutoCompleteInvoiceAddress: FC = () => {
  const [open, setOpen] = React.useState(false);
  const { register, setValue, watch,trigger } = useFormContext();
 
  // useSOContext
  const { callSOAPI: { fetchInvoiceAddresses, setContextData }, SOData: { IAOptions }} = useSOContext();
  const customer = watch("customer");
  const invoiceAddress = watch("invoiceAddress");
  const { t } = useTranslation("orders");

  register("invoiceAddress", {
    required: true,
  });

  React.useEffect(() => {
    register("invoiceAddress", {
      required: true,
    });
    if(customer?.key) {
      fetchInvoiceAddresses(customer.key)
        .then(response => {
          const defaultValue = response.find((el) => el.isDefault) ||
            (response.length === 1 && response[0]) || null;
          if (defaultValue) {
            setValue("invoiceAddress", defaultValue);
            trigger("invoiceAddress");
          }
        });
    } else {
      setContextData("IAOptions", []);
      setValue("invoiceAddress", null);
      trigger("invoiceAddress");
    }
  }, [ customer ]);


  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={invoiceAddress || null}
      onChange={(_, newValue) => {
        setValue("invoiceAddress", newValue);
        trigger("invoiceAddress");
      }}
      getOptionSelected={(option, value) => {
        return option.displayText === value.displayText
      }}
      getOptionLabel={(option) => option.displayText}
      options={IAOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          name="invoiceAddress"
          required={true}
          label={t("labels.creation.field_desc_invoice_addresses")}
          variant="outlined"
        />
      )}
    />
  );
};

export default AutoCompleteInvoiceAddress;
