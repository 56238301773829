import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { InfoIcon } from "src/assets/icons";
import { useCommentsContext } from "src/providers/CommentsStore";
import SellersSelect from "./SellersAutoComplete";
import MessagePreview from "./MessagePreview";
import { useTranslation } from "react-i18next";
import { EmailType, WarehouseSeller } from "src/providers/CommentsStore/types";

const ShareByEmailDialog = () => {
  const {
    callAPI,
    comments: {
      shareCommentByEmail,
      shareEmails,
      shareWarehouseKey,
      sharingByEmail,
      sharingByEmailOpen,
    },
  } = useCommentsContext();

  const [emailsChecked, setEmailsChecked] = useState<Set<EmailType>>(new Set<EmailType>());
  const [customEmails, setCustomEmails] = useState("");
  const [warehouseSellers, setWarehouseSellers] = useState<WarehouseSeller[]>([]);
  const [isIncludeThread, setIsIncludeThread] = useState(false);

  const handleClose = () => {
    callAPI.setContextData("sharingByEmailOpen", false);
  };

  const handleAccept = () => {
    let warehouseEmail = "";
    if (emailsChecked.has(EmailType.Warehouse)) {
      const warehouse = shareEmails.find((x) => x.type === EmailType.Warehouse);
      warehouseEmail = warehouse?.email ?? "";
    }

    let warehouseSellerEmails = warehouseSellers.map((x) => x.email).join(";");

    const dto = {
      warehouseEmail,
      customEmails,
      warehouseSellerEmails,
      isIncludeThread,
    };
    callAPI.shareByEmail(dto);
  };

  const onIncludeThreadChange = (_e: any, checked: boolean) => {
    setIsIncludeThread(checked);
  };

  const handleWarehouseToggle = (type: EmailType) => {
    setEmailsChecked((prev) => {
      const newOne = new Set<EmailType>(prev);
      if (newOne.has(type)) {
        newOne.delete(type);
        return newOne;
      }
      newOne.add(type);
      return newOne;
    });
  };

  const onEmailAddressesChange: OutlinedInputProps["onChange"] = ({ target: { value } }) => {
    setCustomEmails(value);
  };

  useEffect(() => {
    setEmailsChecked(new Set<EmailType>());
    setCustomEmails("");
    setWarehouseSellers([]);
    setIsIncludeThread(false);
  }, [shareCommentByEmail]);

  const { t } = useTranslation("comments");

  const getTypeString = (type: EmailType) => {
    if (type === EmailType.Warehouse) {
      return `(${t("labels.email_types.warehouse")})`;
    }

    return "";
  };

  const classes = useStyles();

  return (
    <Dialog
      open={sharingByEmailOpen}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      {!!shareCommentByEmail && (
        <>
          <DialogTitle classes={{ root: classes.title }}>
            {t("labels.share_comment_by_email")}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div>
              <Typography>{t("labels.comment_will_be_shared")}:</Typography>
              <List>
                {shareEmails.map((x) => (
                  <ListItem
                    key={x.key}
                    className={classes.listItem}
                    dense
                    button
                    onClick={() => handleWarehouseToggle(x.type)}
                  >
                    <ListItemIcon classes={{ root: classes.listCheckbox }}>
                      <Checkbox color="primary" edge="start" checked={emailsChecked.has(x.type)} />
                    </ListItemIcon>
                    <ListItemText>
                      {x.displayText} {getTypeString(x.type)}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>

            <div>
              <Typography>{t("labels.share_to_warehouse_seller")}</Typography>
              {!!shareWarehouseKey && (
                <SellersSelect
                  warehouseId={shareWarehouseKey}
                  value={warehouseSellers}
                  onChange={setWarehouseSellers}
                />
              )}
            </div>

            <div>
              <Typography>{t("labels.share_to_multiple_email")}</Typography>

              <TextField
                variant="outlined"
                fullWidth
                label={t("labels.enter_emails")}
                value={customEmails}
                onChange={onEmailAddressesChange}
              />
              <Typography variant="caption">{t("labels.enter_emails_caption")}</Typography>
            </div>

            <MessagePreview message={shareCommentByEmail} />

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isIncludeThread}
                    onChange={onIncludeThreadChange}
                    color="primary"
                  />
                }
                label={t("labels.include_thread")}
              />
              <div className={classes.infoLabel}>
                <InfoIcon />
                <Typography variant="caption">{t("labels.thread_caption")}</Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button onClick={handleAccept} disabled={sharingByEmail} className={classes.submitBtn}>
              {t("labels.ok", { ns: "common" })}
            </Button>
            <Button autoFocus className={classes.cancelBtn} onClick={handleClose}>
              {t("labels.cancel", { ns: "common" })}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: 40,
  },
  title: {
    padding: "0 0 24px 0",
  },
  content: {
    padding: 0,
    overflow: "visible",

    "& > :not(first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  listItem: {
    borderBottom: "1px solid rgba(20, 30, 48, 0.1)",
  },
  listCheckbox: {
    minWidth: "unset",
  },
  infoLabel: {
    display: "flex",
    alignItems: "center",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  actions: {
    marginTop: theme.spacing(3),
    justifyContent: "flex-start",
    padding: 0,
  },
  submitBtn: {
    padding: theme.spacing(2),
    minWidth: 120,
    color: "#ffff",
    backgroundColor: theme.palette.functionalColors.dark,
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    minWidth: 120,
    color: theme.palette.primary.main,
  },
}));

export default ShareByEmailDialog;
