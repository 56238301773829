import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  alertsContainer: {
    display: "grid",
    gridAutoFlow: "row",
    gap: `${theme.spacing(0.5)}px`,
    marginTop: theme.spacing(1),
    textAlign: "left",
  },
  warningAlert: {
    background: "#FBEECA",
    color: "unset",
  },
  errorAlert: {
    background: "#FBCFBD",
    color: "unset",
  },
}));
