import { makeStyles } from "@material-ui/core";
const drawerWidth = 300;
const drawerWidthClosed = 73;
export const usePageNavigationBarStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    position: "relative",
  },
  root: {
    display: "flex",
    position: "static",
  },
  appBar: {
    position: "absolute",
    zIndex: 0,
    marginLeft: drawerWidthClosed,
    width: `calc(100% - ${drawerWidthClosed}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  openButton: {
    margin: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 1,
  },
  drawerPaper: {
    position: "relative",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpenedlWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 8px 0px 16px",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  appBarTextWrapper: {
    writingMode: "vertical-rl",
    margin: "0px auto",
    transform: "rotate(180deg)",
    marginBottom: 30,
  },
}));
