import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  rowWrap: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  containerHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  main: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    height: 570,
    flex: 1,
  },
  channels: {
    display: "flex",
    flexDirection: "column",
  },
}));
