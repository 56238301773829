import React, { useState, FC } from "react";

import { Grid } from "@material-ui/core";
import format from "date-fns/format";
import Avatar from "@material-ui/core/Avatar";

import ListItemText from "@material-ui/core/ListItemText";
import Content from "src/containers/Comments/components/ThreadsAndChat/Chat/Content";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import SharedIcon from "../../../../images/SharedIcon";
import { Messages } from "src/providers/CommentsStore/types";
import MessageFilesList from "../MessageFilesList";
import clsx from "clsx";
import SharedEmails from "./MassageSharedEmails";
import { Feature } from "../../../../../../components/FeatureFlag/index";
import { MessageListItemStyle } from "./styles";

interface Props {
  message: Messages;
  onRead: (data: string) => void;
}

const MessageListItem: FC<Props> = ({ message }) => {
  const { t } = useTranslation("comments");
  const listItemRef = React.createRef<HTMLLIElement>();
  const { time, root, readedMsg, sharedWrapper, sharedDivider } = MessageListItemStyle();
  const [selectedId, setId] = useState<string | number>("");

  const showSharing = (id: string) => {
    setId(id);
  };
  const hideSharing = () => {
    setId(0);
  };

  return (
    <li
      onMouseEnter={() => showSharing(`${message.key}`)}
      onMouseLeave={hideSharing}
      ref={listItemRef}
      className={clsx(root, message.isUnread ? readedMsg : "")}
    >
      {message.isSharedByEmail && (
        <Feature
          featureKey="showCommentSharedEmails"
          children={() => (
            <SharedEmails sharedEmailsArray={message?.sharedToEmails} translation={t} />
          )}
        />
      )}

      {message.isShared ? (
        <Grid container alignItems="center">
          {message.sharedUser && (
            <Avatar style={{ width: 32, height: 32 }}>
              {message.sharedUser.firstName.slice(0, 1).toUpperCase()}
            </Avatar>
          )}

          <ListItemText
            style={{ paddingLeft: 8 }}
            primary={
              message.sharedUser && (
                <div>
                  <Typography
                    component={"span"}
                  >{`${message.createdBy.firstName} ${message.createdBy.lastName}`}</Typography>
                  <span className={time}>
                    {format(new Date(message?.sharedDate!), "MM.dd.yyyy")}{" "}
                    {format(new Date(message?.sharedDate!), "HH:mm")}
                  </span>
                </div>
              )
            }
            disableTypography={true}
            secondary={
              <div className={sharedDivider}>
                {message.isShared && message.sharedUser && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 4,
                    }}
                  >
                    <Avatar style={{ width: 16, height: 16, fontSize: 12 }}>
                      {message?.createdBy?.firstName!.slice(0, 1).toUpperCase()}
                    </Avatar>
                    <Typography align="left" variant="subtitle2" style={{ marginLeft: 4 }}>
                      {message.createdBy.firstName} {message.createdBy.lastName}
                    </Typography>
                  </div>
                )}
                <Typography
                  align="left"
                  style={{
                    marginBottom: 4,
                    maxWidth: 500,
                    overflowWrap: "break-word",
                    whiteSpace: "pre-line",
                  }}
                >
                  {message.message}
                </Typography>

                <div className={sharedWrapper}>
                  <SharedIcon />
                  <Typography variant="caption">
                    Shared from {message.sharedChannel || ""} channel {"\u25CF"}{" "}
                    {message.sharedThread || ""} {"\u25CF"} Posted{" "}
                    {message.createdDate && format(new Date(message?.createdDate), "MM.dd.yyyy")} at{" "}
                    {message.createdDate && format(new Date(message?.createdDate), "HH:mm")}
                  </Typography>
                </div>
              </div>
            }
          />
          <Content msg={message} selectedId={selectedId} />
        </Grid>
      ) : (
        <Grid container alignItems="center">
          <Avatar style={{ width: 32, height: 32 }}>
            {message?.createdBy?.firstName!.slice(0, 1).toUpperCase()}
          </Avatar>
          <ListItemText
            style={{ paddingLeft: 8 }}
            primary={
              <div>
                <Typography
                  component={"span"}
                >{`${message.createdBy.firstName} ${message.createdBy.lastName}`}</Typography>
                <span className={time}>
                  {format(new Date(message?.createdDate!), "MM.dd.yyyy")}{" "}
                  {format(new Date(message?.createdDate!), "HH:mm")}
                </span>
              </div>
            }
            disableTypography={true}
            secondary={
              <div>
                <Typography
                  align="left"
                  className={message.isShared ? sharedDivider : ""}
                  style={{
                    marginBottom: 4,
                    maxWidth: 500,
                    overflowWrap: "break-word",
                    whiteSpace: "pre-line",
                  }}
                >
                  {message.message}
                </Typography>
                {message.files.length !== 0 && <MessageFilesList fileList={message.files} />}
              </div>
            }
          />
          <Content msg={message} selectedId={selectedId} />
        </Grid>
      )}
    </li>
  );
};

export default MessageListItem;
