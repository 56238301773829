import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const PartnerInfoIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4 4.2002H2.6C1.71634 4.2002 1 4.91654 1 5.8002V13.8002C1 14.6839 1.71634 15.4002 2.6 15.4002H15.4C16.2837 15.4002 17 14.6839 17 13.8002V5.8002C17 4.91654 16.2837 4.2002 15.4 4.2002Z"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.1998 15.4V2.6C12.1998 1.71634 11.4835 1 10.5998 1H7.3998C6.51615 1 5.7998 1.71634 5.7998 2.6V15.4"
      stroke="#141E30"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default PartnerInfoIcon;
