import React, { FC, useEffect, useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { requestGet } from "src/utils/crud";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { formatDate } from "src/components/DatePicker";
import { TOptions } from "i18next";
import { Customer, CustomerDetailsReq } from "src/types";

interface PropsStyles {
  marginTop?: number;
}
const useStyles = makeStyles((theme) => ({
  infoTitle: {
    marginTop: ({ marginTop = 0 }: PropsStyles) => theme.spacing(marginTop || 3),
    marginBottom: theme.spacing(1),
  },
  svg: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.375),
  },
  emailLink: {
    textDecoration: "none",
    color: theme.palette.primary.light,
  },
}));

export const PartnerInfoSummary: FC = React.memo(() => {
  const classes = useStyles({});
  const [data, setData] = useState<any>({});
  const {
    SOData: { creationSO },
  } = useSOContext();
  const { t } = useTranslation("orders");
  const methods = useFormContext();
  const sellerId = methods.watch("sellerId", creationSO.sellerId);
  const warehouse = methods.watch("warehouseName", creationSO.warehouseName);
  const startDate = methods.watch("startDate", creationSO.startDate);
  const deliveryDate = methods.watch("deliveryDate", creationSO.deliveryDate);

  useEffect(() => {
    if (!!sellerId) {
      (async () => {
        const res = await requestGet(`Sellers/${sellerId}`);
        setData(res.data);
      })();
    } else {
      setData({});
    }
  }, [sellerId]);
  return (
    <div>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.warehouse")}
      </Typography>
      <Typography variant="subtitle1" align={"left"}>
        {warehouse || "---"}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.seller")}
      </Typography>
      <Typography variant="subtitle1">{data?.seller?.name || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.seller_email")}
      </Typography>
      <Typography variant="subtitle1">{data?.seller?.email || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.seller_phone")}
      </Typography>
      <Typography variant="subtitle1">{data?.seller?.phone || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.seller_date_range")}
      </Typography>
      <Typography variant="subtitle1">
        {!!startDate && !!deliveryDate
          ? `${formatDate(startDate)} - ${formatDate(deliveryDate)}`
          : "---"}
      </Typography>
    </div>
  );
});

export const CustomerInfoSummary: FC = () => {
  const classes = useStyles({});
  const [data, setData] = useState<Partial<Customer> | null>(null);
  const { t } = useTranslation("orders");
  const methods = useFormContext();
  const {
    SOData: { creationSO },
  } = useSOContext();
  const customer = methods.watch("customer", creationSO.customer?.key);
  const invoiceTo = methods.watch("invoiceTo");
  const invoiceToDescription = methods.watch("invoiceToDescription");
  const deliveryAddress = methods.watch("deliveryAddress", creationSO.deliveryAddress?.displayText);
  const invoiceAddress = methods.watch("invoiceAddress", creationSO.invoiceAddress?.displayText);
  useEffect(() => {
    if (!!customer) {
      (async () => {
        const res = await requestGet<CustomerDetailsReq>(`Customers/${customer?.key}`);
        setData(res.data.customer);
        // (res.data.customer);
      })();
    } else {
      setData(null);
    }
  }, [customer]);

  const getinvoiceToLabel = (id: number): TOptions | string | null => {
    switch (id) {
      case 1:
        return t("form.to_partner");
      case 2:
        return t("form.to_customer");
      case 3:
        return `${t("form.other_info")}: ${invoiceToDescription || ""}`;
      default:
        return t("form.to_partner");
    }
  };

  return (
    <div>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.customer")}
      </Typography>
      <Typography variant="subtitle1" align={"left"}>
        {customer?.displayText || "---"}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.customer_address")}
      </Typography>
      <Typography variant="subtitle1">{data?.address || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.customer_email")}
      </Typography>
      <Typography variant="subtitle1">{data?.email || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.customer_phone")}
      </Typography>
      <Typography variant="subtitle1">{data?.mobile || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.delivery_address")}
      </Typography>
      <Typography variant="subtitle1" align={"left"}>
        {deliveryAddress?.displayText || "---"}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.invoice_address")}
      </Typography>
      <Typography variant="subtitle1" align={"left"}>
        {invoiceAddress?.displayText || "---"}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.invoice_to")}
      </Typography>
      <Typography variant="subtitle1" align={"left"}>
        {getinvoiceToLabel(Number(invoiceTo)) || "---"}
      </Typography>
    </div>
  );
};

export const ProjectInfoSummary: FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation("orders");
  const {
    SOData: { creationSO },
  } = useSOContext();
  const methods = useFormContext();
  const optionalName = methods.watch("optionalName", creationSO.optionalName);
  const description = methods.watch("description", creationSO.description);
  const externalId = methods.watch("externalId", creationSO.externalId);
  const billingReference = methods.watch("billingReference", creationSO.billingReference);
  const reference = methods.watch("reference", creationSO.reference);

  return (
    <div>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.service_order_name")}
      </Typography>
      <Typography variant="subtitle1" align={"left"}>
        {optionalName || "---"}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.externalId")}
      </Typography>
      <Typography variant="subtitle1">{externalId || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.billing_references")}
      </Typography>
      <Typography variant="subtitle1">{billingReference || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.references")}
      </Typography>
      <Typography variant="subtitle1">{reference || "---"}</Typography>
      <Typography variant="subtitle2" color="textSecondary" className={classes.infoTitle}>
        {t("labels.creation.description")}
      </Typography>
      <Typography variant="subtitle1" align={"left"}>
        {description || "---"}
      </Typography>
    </div>
  );
};
