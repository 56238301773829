import React from "react";
import { usePartnerManagerStyles } from "./styles";
import { Paper } from "@material-ui/core";
import PageNavigationBar from "./components/PageNavigation";
import { AnalyticsProvider } from "src/providers/AnalyticsStore";
import { useAnalyticsContext } from "src/providers/AnalyticsStore";
import SoldByWarehouse from "./components/SoldByWarehouse/";
import SoldByWarehouseSeller from "./components/SoldByWarehouseSeller/";

const AnalyticsPartnerManager: React.FC = () => {
  const classes = usePartnerManagerStyles();
  const {
    analyticsData: { selectedMenuIndex },
  } = useAnalyticsContext();

  return (
    <Paper className={classes.paper}>
      <PageNavigationBar>
        <div>
          {selectedMenuIndex === 1 && <SoldByWarehouse />}
          {selectedMenuIndex === 2 && <SoldByWarehouseSeller />}
        </div>
      </PageNavigationBar>
    </Paper>
  );
};

const AnalyticsPartnerManagerPage = () => (
  <AnalyticsProvider>
    <AnalyticsPartnerManager />
  </AnalyticsProvider>
);

export default AnalyticsPartnerManagerPage;
