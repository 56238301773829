import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import BlockIcon from "@material-ui/icons/Block";
import { theme } from "src/utils/styleTheme/mainStyles";
import { useFormContext } from "react-hook-form";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import { StepsList } from "src/pages/Orders/CreationSO/index";
import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    "& > .MuiDivider-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.functionalColors.dark,
  },
}));

const PageActions: FC<{
  handleSteps: (isInc?: boolean) => void;
  activeStep: string | number;
}> = React.memo(({ handleSteps, activeStep }) => {
  const data = useFormContext();
  const { callSOAPI } = useSOContext();
  const history = useHistory();
  const { getValues, trigger, formState } = data;
  const classes = useStyles();
  const { t } = useTranslation("orders");
  const handleNext = async () => {
    await trigger().then((isValid) => {
      if (isValid) {
        callSOAPI.setCreationSO(getValues());
        handleSteps();
      }
    });
  };

  const save = async () => {
    await callSOAPI.sendSO(getValues())
      .then(({ data }) => {
        callSOAPI.setContextDataBulk({
          responseObject: data,
          success: true,
        });
      });
  };

  const cancel = () => {
    history.goBack();
  };

  return (
    <Grid container className={classes.wrapper} direction="row" justify={"space-between"}>
      <Grid>
        <Button
          size={"large"}
          onClick={() => handleSteps(false)}
          style={{ color: theme.palette.functionalColors.dark }}
          startIcon={<KeyboardArrowLeftIcon color={"action"} />}
        >
          {t("labels.btn.back")}
        </Button>
        <Button
          size={"large"}
          style={{ color: theme.palette.functionalColors.dark }}
          startIcon={<BlockIcon color={"action"} />}
          onClick={cancel}
        >
          {t("labels.btn.cancel")}
        </Button>
      </Grid>
      {StepsList().length - 1 === activeStep ? (
        <Button
          disabled={!formState.isValid}
          variant="contained"
          color={"primary"}
          size={"large"}
          onClick={save}
          endIcon={<KeyboardArrowRightIcon color={"secondary"} />}
        >
          {t("labels.btn.save")}
        </Button>
      ) : (
        <Button
          disabled={!formState.isValid}
          variant="contained"
          color={"primary"}
          size={"large"}
          onClick={handleNext}
          endIcon={<KeyboardArrowRightIcon color={"secondary"} />}
        >
          {t("labels.btn.next")}
        </Button>
      )}
    </Grid>
  );
});

export default PageActions;
