import React, { useEffect } from "react";
import { IconButton, TextField } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import ServiceTypeAutocomplete from "./ServiceType";
import DatePicker from "src/components/DatePicker";
import BucketIcon from "./../../icons/BucketIcon";
import NumberFormatCustom, { OnChange } from "src/components/NumberFormat";
import { useTranslation } from "react-i18next";
import addMonths from "date-fns/addMonths";

interface Props {
  index: number;
  item: any;
  removeRow: (index: number) => void;
}

const ServiceFieldsRow: React.FC<Props> = ({ index, item, removeRow }) => {
  const { control, watch, setValue, register, trigger } = useFormContext();
  const fieldsName = `services[${index}]`;

  const onCapacityChange: OnChange = ({ target: { floatValue } }) => {
    let newValue: number | null = null;
    if (floatValue) {
      newValue = floatValue;
    }
    setValue(`${fieldsName}.capacity`, newValue);
  };

  const { t } = useTranslation("orders");

  const startDate = watch(`${fieldsName}.startDate`);

  useEffect(() => {
    if (!!startDate) {
      register(`${fieldsName}.deliveryDate`);
      setValue(
        `${fieldsName}.deliveryDate`,
        new Date(addMonths(new Date(startDate), 3)).toISOString()
      );
      trigger(`${fieldsName}.deliveryDate`);
    }
  }, [startDate]);

  return (
    <>
      <ServiceTypeAutocomplete fieldName={fieldsName} />
      <Controller
        as={
          <DatePicker
            required
            fullWidth
            maxDate={watch(`${fieldsName}.deliveryDate`) || undefined}
          />
        }
        name={`${fieldsName}.startDate`}
        control={control}
        defaultValue={item?.startDate}
        rules={{ required: true }}
      />
      <Controller
        as={
          <DatePicker required fullWidth minDate={watch(`${fieldsName}.startDate`) || undefined} />
        }
        name={`${fieldsName}.deliveryDate`}
        control={control}
        defaultValue={item?.deliveryDate}
        rules={{ required: true }}
      />
      <Controller
        render={({ value }) => (
          <TextField
            variant="outlined"
            fullWidth={true}
            label={t("labels.creation.service.capacity")}
            value={value}
            onChange={onCapacityChange as any}
            name="capacity"
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                decimalScale: 0,
              },
            }}
          />
        )}
        name={`${fieldsName}.capacity`}
        control={control}
      />
      <IconButton onClick={() => removeRow(index)}>
        <BucketIcon />
      </IconButton>
    </>
  );
};

export default ServiceFieldsRow;
