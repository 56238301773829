import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  listItem: {
    backgroundColor: "rgba(20, 30, 48, 0.05);",
    borderRadius: 4,
  },
  container: {},
  preview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageClassName: {
    maxHeight: 750,
  },
  fileTypesSwitch: {
    margin: `${theme.spacing(2)}px 0px`,
    "&> .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.light,
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));
