import React, { FunctionComponent, useCallback } from "react";
import { useSOContext } from "src/providers/ServiceOrdersStore";
import PaginationWrapper from "src/components/PaginationWrapper";

const Pagination: FunctionComponent = React.memo(() => {
  const {
    SOData: { totalCount, currentPage, pageSize, pageCount },
    callSOAPI,
  } = useSOContext();

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      event?.preventDefault();
      callSOAPI.setContextData("currentPage", page + 1);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    callSOAPI.setContextData("pageSize", +event.target.value);
    callSOAPI.setContextData("currentPage", 1);
  }, []);

  const handleSpecificPageChange = useCallback((newPage: number) => {
    callSOAPI.setContextData("currentPage", newPage);
  }, []);

  return (
    <PaginationWrapper
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={totalCount}
      rowsPerPage={pageSize}
      page={currentPage - 1}
      currentpage={currentPage}
      pagecount={pageCount}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onSpecificPageChange={handleSpecificPageChange}
    />
  );
});

export default React.memo(Pagination);
