import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStyles } from "./styles";
import { AxiosResponse } from "axios";
import { Typography, TextField, Button, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { Customer, InvoiceAddress } from "src/types";
import { requestGet, requestPost } from "src/utils/crud";
import SelectInput from "src/components/SelectInput";
import { useAppContext } from "src/providers/AppProvider";
import { useTranslation } from "react-i18next";
import Modal from "src/components/Modal";
import SuccessNotification from "../SuccessNotification";
import { useSODetailsContext } from "src/providers/SODetailsStore";
import InvoiceAddressIcon from "./icons/InvoiceAddressIcon";

const initialStateInvoice = {
  address: "",
  state: "",
  city: "",
  zipCode: "",
  country: {
    key: undefined,
    displayText: "",
  },
};

interface Props {
  onSubmit?: (data: Partial<InvoiceAddress>) => void;
}

const CreateInvoiceAddress: React.FC<Props> = ({ onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation("customers");
  const [invoiceValues, setInvoiceValues] = useState<Partial<InvoiceAddress>>(initialStateInvoice);
  // Set data as Main Address
  const [invoiceAsMain, setInvoiceAsMain] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    state: {
      modals: { createInvoiceAddressModal },
    },
    callAppAPI,
  } = useAppContext();
  const {
    serviceOrderDetails: { customerInfo },
  } = useSODetailsContext();

  const handleModalClose = useCallback(() => {
    callAppAPI.toggleModal({ id: "createInvoiceAddressModal", status: false });
    setInvoiceAsMain(false);
    setSubmitted(false);
    openInvoiceEditModal();
  }, []);

  const openInvoiceEditModal = useCallback(() => {
    callAppAPI.toggleModal({ id: "editInvoiceAddressSO", status: true });
  }, []);

  const handleInvoiceDropdownChange = (value: number) => {
    setInvoiceValues({
      ...invoiceValues,
      country: { key: value },
    });
  };

  const handleInvoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceAsMain(false);
    setInvoiceValues({ ...invoiceValues, [event.target.name]: event.target.value });
  };

  const getCustomerData = useCallback(async () => {
    return await requestGet<{ customer: Customer }>(`customers/${customerInfo?.customer.key}`);
  }, [customerInfo?.customer.key]);

  const handleInvoiceAsMain = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceAsMain(event.target.checked);
    if (event.target.checked) {
      getCustomerData().then(({ data }) => {
        setInvoiceValues({
          address: data.customer.address as string,
          city: data.customer?.city?.displayText,
          zipCode: data.customer.zipCode?.displayText,
          state: data.customer.province?.displayText,
          country: {
            key: data.customer.country?.key,
            displayText: data.customer.country?.displayText,
          },
        });
      });
    }
  };

  const createInvoiceAddress = async () => {
    try {
      const res = await requestPost(`InvoiceAddresses?hasResponse=true`, invoiceValues);
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const assignInvoiceAddress = async (data: { key: number }) => {
    try {
      const res = await requestPost(
        `Customers/${customerInfo?.customer?.key}/invoiceAddresses`,
        data
      );
      return res;
    } catch (err) {
      return console.log(err);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let invoiceAssignedRes;
    submitButtonRef.current?.setAttribute("disabled", "disabled");

    const invoiceCreatedRes = await createInvoiceAddress();
    if ((invoiceCreatedRes as AxiosResponse).status === 201) {
      const invoiceAddressData = { key: (invoiceCreatedRes as AxiosResponse).data.key };
      onSubmit && onSubmit((invoiceCreatedRes as AxiosResponse).data);
      invoiceAssignedRes = await assignInvoiceAddress(invoiceAddressData);
    }
    if (invoiceAssignedRes && invoiceAssignedRes.status === 204) {
      setSubmitted(true);
    }
    submitButtonRef.current?.removeAttribute("disabled");
  };

  useEffect(() => {
    if (
      invoiceValues &&
      invoiceValues.address &&
      invoiceValues.zipCode &&
      invoiceValues.city &&
      invoiceValues.country &&
      invoiceValues.country.key
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [invoiceValues]);

  useEffect(() => {
    if (!createInvoiceAddressModal) {
      setInvoiceValues(initialStateInvoice);
    }
  }, [createInvoiceAddressModal]);

  return (
    <Modal isOpen={createInvoiceAddressModal} onClose={handleModalClose}>
      <Grid container justify="center">
        <Grid item>
          {!submitted && (
            <form onSubmit={handleSubmit}>
              <Typography variant="h3">{t("labels.details.create_invoice_address")}</Typography>
              <div className={classes.fieldGroup}>
                <div className={classes.titleWrapper}>
                  <InvoiceAddressIcon />
                  <Typography variant="h4" className={classes.fieldTitle}>
                    {t("labels.create_customer.addresses_form.invoice_address")}
                  </Typography>
                </div>
                <TextField
                  required
                  fullWidth
                  name="address"
                  label={t("labels.create_customer.addresses_form.address")}
                  value={invoiceValues.address}
                  onChange={handleInvoiceChange}
                  variant="outlined"
                  helperText={t("labels.create_customer.addresses_form.address_helper")}
                  FormHelperTextProps={{
                    classes: { root: classes.addressCaption },
                  }}
                />

                <div className={classes.fieldWrapper}>
                  <TextField
                    required
                    fullWidth
                    name="zipCode"
                    label={t("labels.create_customer.addresses_form.zip")}
                    value={invoiceValues.zipCode}
                    onChange={handleInvoiceChange}
                    variant="outlined"
                  />
                  <TextField
                    required
                    fullWidth
                    name="city"
                    label={t("labels.create_customer.addresses_form.city")}
                    value={invoiceValues.city}
                    onChange={handleInvoiceChange}
                    variant="outlined"
                    className={classes.cityField}
                  />
                </div>
                <TextField
                  fullWidth
                  name="state"
                  label={t("labels.create_customer.addresses_form.state")}
                  value={invoiceValues.state}
                  onChange={handleInvoiceChange}
                  variant="outlined"
                />
                <SelectInput
                  required
                  onSelect={handleInvoiceDropdownChange}
                  label={t("labels.create_customer.addresses_form.country")}
                  name="country"
                  value={invoiceValues.country?.key!}
                  url="lookups/countries"
                  metaApi
                />
                <FormControlLabel
                  disabled={!customerInfo}
                  control={
                    <Checkbox
                      onChange={handleInvoiceAsMain}
                      checked={invoiceAsMain}
                      color="primary"
                    />
                  }
                  label={t("labels.create_customer.addresses_form.invoice_as_main")}
                />
              </div>
              <div className={classes.btnGroup}>
                <Button
                  disabled={submitDisabled}
                  className={classes.submitBtn}
                  type="submit"
                  ref={submitButtonRef}
                >
                  {t("labels.common.save")}
                </Button>
                <Button onClick={handleModalClose} className={classes.cancelBtn}>
                  {t("labels.common.cancel")}
                </Button>
              </div>
            </form>
          )}
          {submitted && (
            <SuccessNotification
              message={t("labels.details.invoice_address_success")}
              onClose={handleModalClose}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateInvoiceAddress;
