import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SortedIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M5 10.1428L7.85714 13L10.7143 10.1428"
        stroke="#141E30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.8573 3V13" stroke="#141E30" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </SvgIcon>
);
export default SortedIcon;
